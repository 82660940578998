import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { RootState } from '../../store/store';
import { popNotification } from '../../store/notification/notification.action';
interface ShowAlertProps {
  type: string | null;
  message: string | null;
}
const Notification = () => {
  const notifications = useSelector((state: RootState) => {
    return state.notificationState.notifications;
  });
  const dispatch = useDispatch();
  const showAlert = ({ type, message }: ShowAlertProps) => {
    if (type === 'success') {
      toast.success(message);
    } else if (type === 'error') {
      toast.error(message);
    } else {
      toast(message);
    }
    dispatch(popNotification());
  };

  useEffect(() => {
    const notification = notifications[0];
    if (notification) {
      showAlert(notification);
    }
  }, [notifications]);

  return (
    <>
      <ToastContainer
        position={'top-center'}
        hideProgressBar={true}
        rtl={false}
        theme={"colored"}
        limit={5}
        closeButton={false}
        autoClose={2000}
        transition={Zoom}
      />
    </>
  );
};

// @ts-ignore
export default Notification;
