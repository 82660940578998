import { useState } from "react";
import { LockIcon } from "../../../../../../assets/svg/lock-icon";
import { CancelBtn, Form, FormItem, FormType, SubmitBtn } from "../../../../../../components/form";
import { Button } from "../../../../../../components/ui";
import { useModal } from "../../../../../../context/ModalConext";
import { Api } from "../../../../../../shared/api/api";


export const UpdatePassword = ({cell}) => {

    const { openModal, closeModal } = useModal();
    const userId = cell.data._id;

    const ModalContent = () => {

        const [ isSubmitting, setIsSubmitting ] = useState(false)

        const submit = async (formData) => {
            setIsSubmitting(true)
            await Api.callAPI({
                url: `/api/v1/users/${userId}/updatePassword`,
                method: 'post',
                body: {
                    _id: userId,
                    newPassword: formData.password,
                },
                options: {
                    successMessage: 'Password updated successfully.',
                    errorMessage: 'Unexpected error occurred, please try again.',
                }
            });
            setIsSubmitting(false)
            closeModal();
        }

        
        return <div className="p-6">
            <h1 className="text-lg font-bold">Updating {cell.data.EmailAddress}</h1>
            <Form onSubmit={submit} className="mt-4">
                <FormItem 
                    formDef={{
                        name: 'password',
                        label: 'New Password',
                        type: FormType.PASSWORD,
                        placeholder: 'New Password',
                        required: true,
                        minLength: 6,
                        maxLength: 16,
                        className: 'mb-4',
                        //Should include 1 uppercase, 1 lowercase, 1 number, 1 special character.
                        pattern: '(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$',
                        errors: {
                            pattern: 'Password must include 1 uppercase, 1 lowercase, 1 number, 1 special character'
                        }
                    }}
                />
                <FormItem 
                    formDef={{
                        name: 'passwordMatch',
                        label: 'Confirm Password',
                        type: FormType.PASSWORD,
                        placeholder: 'Confirm New Password',
                        matchInput: 'password',
                        minLength: 6,
                        maxLength: 16,
                        required: true,
                        errors: {
                            matchInput: 'Passwords do not match.'
                        }
                    }}
                />
                <div className="mt-4 w-full flex items-end justify-end">
                    <CancelBtn ghost className="mr-2 rounded px-4 py-1" onClick={closeModal}>Cancel</CancelBtn>
                    <SubmitBtn className="rounded px-4 py-1" loading={isSubmitting}>Update Password</SubmitBtn>
                </div>
            </Form>
        </div>
    }

    return <Button 
            ghost 
            className="w-full h-full flex items-center justify-center border-none"
            onClick={() =>openModal({
                content: <ModalContent />,
            })}> 
            <LockIcon />
        </Button>
}