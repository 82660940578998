/**
 * This component represents an individual item in the react-select-components drop-down
 */
import React, { useRef } from 'react';

import { useKey } from '../hooks/use-key';
import { KEY } from '../lib/constants';
import { Option } from '../lib/interfaces';
import DefaultItemRenderer from './default-item';
import { useSelectContext } from '../hooks/use-select-context';

interface ISelectItemProps {
  itemRenderer: any;
  option: Option;
  checked?: boolean;
  tabIndex?: number;
  disabled?: boolean;
  onSelectionChanged: (checked: boolean) => void;
  onClick: any;
}

const SelectItem = ({
  itemRenderer: ItemRenderer = DefaultItemRenderer,
  option,
  checked,
  tabIndex,
  disabled,
  onSelectionChanged,
  onClick,
}: ISelectItemProps) => {
  const itemRef: any = useRef();
  const { isCheckboxVisible } = useSelectContext();
  const onOptionCheck = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    toggleChecked();
    e.preventDefault();
  };

  const toggleChecked = () => {
    if (!disabled) {
      onSelectionChanged(!checked);
    }
  };

  const handleClick = (e: any) => {
    toggleChecked();
    onClick(e);
  };

  useKey([KEY.ENTER, KEY.SPACE], onOptionCheck, { target: itemRef });

  return (
    <label
      className={`select-item ${checked ? 'selected' : ''}`}
      role="option"
      aria-selected={checked}
      tabIndex={tabIndex}
      onClick={(e) => {
        if (!isCheckboxVisible) {
          e.stopPropagation();

          handleClick(e);
        }
      }}
      ref={itemRef}
    >
      <ItemRenderer
        option={option}
        onClick={handleClick}
        checked={checked}
        disabled={disabled}
      />
    </label>
  );
};

export default SelectItem;
