import * as React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Client } from '../module/client/client';
import { Login as ClientLogin } from '../module/client/login/login';

import { Dashboard } from '../module/client/dashboard/dashboard';

import ProductSearch from '../module/client/product/search/product-search';
import ProductViewAllItems from '../module/client/product/view-all-items/product-view-all-items';
import SelectCompany from '../module/client/select-company/select-company';
import Cart from '../module/client/customer/cart/cart';
import ChangePassword from '../module/client/customer/change-password/change-password';
import Locations from '../module/client/customer/locations/locations';
import Device from '../module/client/customer/devices/device';
import { OrderDetail } from '../module/client/customer/order-detail/order-detail';
import OrderTracking from '../module/client/customer/order-tracking/order-tracking';
import { Profile } from '../module/client/customer/profile/profile';
import Reports from '../module/client/customer/reports/reports';
import { ClientAddUpdateUsers } from '../module/client/customer/user/add-update-user/add-update-user';
import UserList from '../module/client/customer/user/list/user-list';
import RecycleToner from '../module/client/recycle-toner/recycle-toner';
import ServiceRequest from '../module/client/service/request/service-request';
import ServiceHistory from '../module/client/service/request-history/service-history';
import OrderHistory from '../module/client/customer/order-history/order-history';
import MoveDeviceRequest from '../module/client/customer/devices/move-device-request/move-device-request';
import { Maintenance as ClientMaintenance } from '../module/client/maintenance/maintenance';
import ThankYouPage from '../module/client/customer/thankyou/thankyou';
import OrderCheckout from '../module/client/customer/checkout/checkout';
import DeviceDetail from '../module/client/customer/devices/device-detail/device-detail';
import LocationDetail from '../module/client/customer/locations/location-detail/location-detail';
import ServiceRequestDetail from '../module/client/service/request-detail/request-detail';
import ForgotPassword from '../module/client/forgot-password/forgot-password';
import { Admin } from '../module/admin/admin';
import { Customer as AdminCustomer } from '../module/admin/customer/customer';
import { Login as AdminLogin } from '../module/admin/login/login';

import { Maintenance as AdminMaintenance } from '../module/admin/maintenance/maintenance';
import { AddUpdateUsers as AdminAddUpdateUsers } from '../module/admin/customer/users/add-update-users/add-update-users';
import { UserList as AdminUserList } from '../module/admin/customer/users/list/list';
import { CustomerUserList as CustomerUserList } from '../module/admin/customer/users/list/customer-user-list';
import SearchCustomer from '../module/admin/customer/search-customer/search-customer';
import { AccessLogs } from '../module/admin/access-logs/access-logs';
import Banner from '../module/admin/banner/banner';
import GlobalSettings from '../module/admin/global-settings/global-settings';
import { NotFound as AdminNotFound } from '../module/admin/not-found/not-found';
import { SuperAdminUserList } from '../module/admin/admin-users/list/list';
import { AddUpdateSuperAdminUsers } from '../module/admin/admin-users/add-update-super-admin-users/add-update-super-admin-users';
import { Demo } from '../module/client/demo/demo';
import { FormDemo } from '../module/client/demo/formDemo';
import storage from '../utils/storage/storage';
import { TENANT, TenantKey } from '../constant/constant';
import { AddDevice } from '../module/client/customer/devices/add-device/add-device';
import { AddLocation } from '../module/client/customer/locations/add-location/add-location';
import { CanHavePermission } from '../shared/can-have-permission/can-have-permission';
import { LinkCustomer } from '../module/admin/linkCustomer/linkCustomer';
import { ResetForgotPassword } from '../module/client/reset-forgot-password/reset-forgot-password';
import { MeterByDevice } from '../module/client/customer/reports/meter-by-device/meter-by-device';
import { ActiveDevice } from '../module/client/customer/reports/active-device/active-device';
import { RetiredDevice } from '../module/client/customer/reports/retired-device/retired-device';
import { NeverReportedDevice } from '../module/client/customer/reports/never-reported-device/never-reported-device';
import { StoppedDevice } from '../module/client/customer/reports/stopped-device/stopped-device';
import { ClientNotFound } from "../module/client/not-found/not-found";
import SSOLogout from '../module/client/sso/sso-logout';
import SSOLoginError from '../module/client/sso/sso-error';
import { Crm } from './Crm';
import { AuthMigrationInfo } from '../module/client/login/auth-migration-info';
import { CanHavePermissionAdmin } from '../shared/can-have-permission/can-have-permission-admin';
import { AuthLoading } from '../module/client/login/AuthLoading';
import { AdminDashboard } from '../module/admin/dashboard';
import { PopupDisplay } from '../module/admin/popup';


export const MainRouting = () => {
  // eslint-disable-next-line no-undef
  const importCssVariable = (name: string) => {
    import(`../style/${name}.scss`);
  };


  const importAdminCss = () => {
     const name = 'admin';
   // eslint-disable-next-line no-undef
   import(`../../src/${name}.scss`)
      .then(() => {
        // SCSS file for the current tenant has been loaded dynamically
      })
      .catch(() => {
        
      });
  };
  React.useEffect(() => {
    const tenantNameList = Object.values(TENANT);
    const possibleTenants = [...tenantNameList, 'localhost', '127.0.0.1'];
    const location = window.location.origin;
    let currentTenant: any = possibleTenants.find((tenant) =>
      location.includes(tenant),
    );
    if (currentTenant === 'localhost' || currentTenant === '127.0.0.1') {
      currentTenant = TENANT.FLEXPRINT;
    }

    if(window.location.pathname.indexOf('admin') > -1) {
      importAdminCss();
    }
    importCssVariable(currentTenant);
    storage.setItem(TenantKey, currentTenant);
  }, []);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Client />,
      errorElement: <ClientNotFound />,
      children: [
        {
          path: '/',
          element: <Dashboard />,
        },
        {
          path: 'login',
          element: <ClientLogin />,
        },
        {
          path: 'authentication',
          element: <AuthLoading />,
        },
        {
          path: 'auth-migration',
          element: <AuthMigrationInfo />
        },
        {
          path: 'sso-logout',
          element: <SSOLogout />
        },
        {
          path: 'sso-login-error',
          element: <SSOLoginError />
        },
        {
          path: 'demo',
          element: <Demo />,
        },
        {
          path: 'formDemo',
          element: <FormDemo />,
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: 'dashboard',
          element: <Dashboard />,
        },
        {
          path: 'select-company',
          element: <SelectCompany />,
        },
        {
          path: 'recycle-toner',
          element: <RecycleToner />,
        },
        {
          path: 'setUserPassword/:token',
          element: <ResetForgotPassword />,
        },
        {
          path: 'maintenance',
          element: <ClientMaintenance />,
        },
        {
          path: 'customer/cart',
          element: (
            <CanHavePermission isHide={false} permissions={['CAN_PLACE_ORDER']}>
              <Cart />
            </CanHavePermission>
          )
        },
        {
          path: 'customer/change-password',
          element: <ChangePassword />,
        },
        {
          path: 'customer/devices',
          element: <Device />,
        },
        {
          path: 'customer/device-detail/:equipmentId',
          element: <DeviceDetail />,
        },
        {
          path: 'customer/devices/move-device-request',
          element: <MoveDeviceRequest />,
        },
        {
          path: 'customer/devices/add-device',
          element: <AddDevice />,
        },
        {
          path: 'customer/locations',
          element: <Locations />,
        },
        {
          path: 'customer/locations/add-location',
          element: <AddLocation />,
        },
        {
          path: 'customer/locations/:locationId',
          element: <LocationDetail />,
        },
        {
          path: 'customer/order-history',
          element: (
            <CanHavePermission isHide={false} permissions={['CAN_VIEW_PLACED_ORDER']}>
              <OrderHistory />
            </CanHavePermission>
          ),
        },
        {
          path: 'customer/profile',
          element: <Profile />,
        },
        {
          path: 'customer/reports',
          element: <Reports />,
          children: [
            { path: 'meter-by-device', element: <MeterByDevice /> },
            { path: 'active-device', element: <ActiveDevice /> },
            { path: 'retired-device', element: <RetiredDevice /> },
            { path: 'never-reported-device', element: <NeverReportedDevice /> },
            { path: 'stopped-device', element: <StoppedDevice /> },
          ],
        },
        {
          path: 'customer/order-detail/:SOID',
          element: (
            <CanHavePermission isHide={false} permissions={['CAN_VIEW_PLACED_ORDER']}>
              <OrderDetail />
            </CanHavePermission>
          ),
        },
        {
          path: 'customer/order-tracking/:trackingNumber',
          element: (
            <CanHavePermission isHide={false} permissions={['CAN_VIEW_PLACED_ORDER']}>
              <OrderTracking />
            </CanHavePermission>
          ),
        },
        {
          path: 'customer/user/list/:customerId',
          element: (
            <CanHavePermission isHide={false} permissions={['ALL']}>
              <UserList />
            </CanHavePermission>
          ),
        },
        {
          path: 'customer/thankyou/:orderId',
          element: (
            <CanHavePermission isHide={false} permissions={['CAN_PLACE_ORDER']}>
              <ThankYouPage />
            </CanHavePermission>
          )
        },
        {
          path: 'customer/checkout',
          element: (
            <CanHavePermission isHide={false} permissions={['CAN_PLACE_ORDER']}>
              <OrderCheckout />
            </CanHavePermission>
          ),
        },
        {
          path: 'customer/user/add',
          element: (
            <CanHavePermission isHide={false} permissions={['ALL']}>
              <ClientAddUpdateUsers />
            </CanHavePermission>
          ),
        },
        {
          path: 'customer/user/edit/:userId',
          element: (
            <CanHavePermission isHide={false} permissions={['ALL']}>
              <ClientAddUpdateUsers />
            </CanHavePermission>
          ),
        },
        {
          path: 'product/search',
          element: (
            <CanHavePermission isHide={false} permissions={['CAN_PLACE_ORDER']}>
              <ProductSearch />
            </CanHavePermission>
          )
        },
        {
          path: 'product/search/:equipmentID',
          element: (
            <CanHavePermission isHide={false} permissions={['CAN_PLACE_ORDER']}>
              <ProductSearch />
            </CanHavePermission>
          )
        },
        {
          path: 'product/view-all-items',
          element: (
            <CanHavePermission isHide={false} permissions={['CAN_PLACE_ORDER']}>
              <ProductViewAllItems />
            </CanHavePermission>
          )
        },
        {
          path: 'service/request',
          element: (
            <CanHavePermission isHide={false} permissions={['CAN_PLACE_SERVICE_REQUEST']}>
              <ServiceRequest />
            </CanHavePermission>
          ),
        },
        {
          path: 'service/request/:equipmentId',
          element: (
            <CanHavePermission isHide={false} permissions={['CAN_PLACE_SERVICE_REQUEST']}>
              <ServiceRequest />
            </CanHavePermission>
          ),
        },
        {
          path: 'service/service-request-detail/:serviceRequestId',
          element: (
            <CanHavePermission isHide={false} permissions={['CAN_VIEW_SERVICE_REQUEST']}>
              <ServiceRequestDetail />
            </CanHavePermission>
          ),
        },
        //Thank you page is no longer in use, but will remain for legacy reasons
        {
          path: 'service/service-request-thankyou/:serviceRequestId',
          element: (
            <CanHavePermission isHide={false} permissions={['CAN_PLACE_SERVICE_REQUEST']}>
              <ServiceRequestDetail />
            </CanHavePermission>
          ),
        },
        {
          path: 'service/request/:equipmentId/:serviceRequestId',
          element: (
            <CanHavePermission isHide={false} permissions={['CAN_PLACE_SERVICE_REQUEST']}>
              <ServiceRequest />
            </CanHavePermission>
          ),
        },
        {
          path: 'service/request-history',
          element: (
            <CanHavePermission isHide={false} permissions={['CAN_VIEW_SERVICE_REQUEST']}>
              <ServiceHistory />
            </CanHavePermission>
          ),
        },
        {
          path: 'crm',
          element: <Crm />
        },        
        {
          path: 'crm/*',
          element: <Crm />
        },
        {
          path: 'pageNotFound',
          element: <ClientNotFound/>
        },
        {
          path: '*',
          element: (<CanHavePermission
            isHide={false}
            permissions={['NOT_FOUND']}
          >
            <ClientNotFound />
          </CanHavePermission>)
        }
      ],
    },
    {
      path: '/admin',
      element: <Admin />,
      errorElement: <AdminNotFound />,
      children: [
        {
          path: 'login',
          element: <AdminLogin />,
        },
        {
          path: 'customer',
          element: (
              <AdminCustomer />
          ),
        },
        {
          path: 'dashboard',
          element: (<CanHavePermissionAdmin
            isHide={false}
            permissions={['CAN_MANAGE_SUPER_ADMIN_USER']}
          >
            <AdminDashboard />
          </CanHavePermissionAdmin>),
        },
        {
          path: 'popup',
          element: (<CanHavePermissionAdmin
            isHide={false}
            permissions={['CAN_MANAGE_SUPER_ADMIN_USER']}
          >
            <PopupDisplay />
          </CanHavePermissionAdmin>),
        },
        {
          path: 'maintenance',
          element: <AdminMaintenance />,
        },
        {
          path: 'customers/:customerId/users/add',
          element: <AdminAddUpdateUsers />,
        },

        {
          path: 'customers/:customerId/users/:userId/edit',
          element: <AdminAddUpdateUsers />,
        },
        {
          path: 'users',
          element: (
            <CanHavePermissionAdmin
              isHide={false}
              permissions={['CAN_MANAGE_COMPANY']}
            >
              <AdminUserList />
            </CanHavePermissionAdmin>
          ),
        },

        {
          path: 'users/add',
          element: <AdminAddUpdateUsers />,
        },
        {
          path: 'users/:userId/edit',
          element: <AdminAddUpdateUsers />,
        },

        {
          path: 'customer/search',
          element: (<CanHavePermissionAdmin
              isHide={false}
              permissions={['CAN_MANAGE_COMPANY']}
            >
              <SearchCustomer />
            </CanHavePermissionAdmin>),
        },
        {
          path: 'access-logs',
          element: (<CanHavePermissionAdmin
            isHide={false}
            permissions={['CAN_VIEW_ACCESS_LOGS']}
          >
            <AccessLogs />
          </CanHavePermissionAdmin>),
        },

        {
          path: 'customerUser/:customerId',
          element: <CustomerUserList />,
        },
        {
          path: 'banner',
          element: (<CanHavePermissionAdmin
            isHide={false}
            permissions={['CAN_MANAGE_SUPER_ADMIN_USER']}
          >
            <Banner />
          </CanHavePermissionAdmin>),
        },

        {
          path: 'global-settings',
          element: (<CanHavePermissionAdmin
            isHide={false}
            permissions={['CAN_MANAGE_SUPER_ADMIN_USER']}
          >
            <GlobalSettings />
          </CanHavePermissionAdmin>),
        },
        {
          path: 'adminUser/list/:id',
          element: (
            <CanHavePermissionAdmin
              isHide={false}
              permissions={['CAN_MANAGE_SUPER_ADMIN_USER']}
            >
              <SuperAdminUserList />
            </CanHavePermissionAdmin>
          ),
        },
        {
          path: 'adminUser/add',
          element: (<CanHavePermissionAdmin
            isHide={false}
            permissions={['CAN_MANAGE_SUPER_ADMIN_USER']}
          >
            <AddUpdateSuperAdminUsers />
          </CanHavePermissionAdmin>),
        },
        {
          path: 'adminUser/edit/:userId',
          element: (<CanHavePermissionAdmin
            isHide={false}
            permissions={['CAN_MANAGE_SUPER_ADMIN_USER']}
          >
            <AddUpdateSuperAdminUsers />
          </CanHavePermissionAdmin>),
        },
        {
          path: 'link-customer/:addCustomerId',
          element: <LinkCustomer />,
        },
        {
          path: 'link-customer/edit/:id',
          element:  <LinkCustomer />,
        },
        {
          path: 'pageNotFound',
          element: <AdminNotFound/>
        },
        {
          path: '*',
          element: (<CanHavePermissionAdmin
            isHide={false}
            permissions={['NOT_FOUND']}
          >
            <AdminNotFound />
          </CanHavePermissionAdmin>)
        }
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};
