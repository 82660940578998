import {
  Form,
  FormItems,
  FormType,
  SubmitBtn,
} from '../../../../../../components/form';
import { Api } from '../../../../../../shared/api/api';

export const DeviceLocationUpdate = ({
  customerInfo,
  deviceDetails,
  equipmentId,
  customerAddress,
  onDeviceLocationUpdate,
  closeBtns = '',
  className = '',
}: any) => {
  const onDeviceLocationChange = async (value) => {
    const location = value.Location[0];
    const reqBody = {
      email: customerInfo.EmailAddress,
      userInfo: {
        CustomerId: customerInfo.CustomerId,
        EmailAddress: customerInfo.EmailAddress,
        FirstName: customerInfo.FirstName,
        LastName: customerInfo.LastName,
        UserPhone: customerInfo.UserPhone,
        BranchID: customerInfo.BranchID,
        BranchNumber: customerInfo.BranchNumber,
      },
      oldDeviceData: deviceDetails,
      newLocationData: {
        ...deviceDetails,
        Address: location.Address,
        City: location.City,
        State: location.State,
        Zip: location.Zip,
        Country: location.Country,
        CustomerName: location.CustomerName,
        CustomerNumber: location.CustomerNumber,
      },
    };

    await Api.callAPI({
      url: `/api/v1/equipments/${equipmentId}/location`,
      method: 'put',
      body: reqBody,
      options: {
        successMessage:
          'Your change request has been submitted to our team for review.',
        errorMessage: 'Error while updating equipment location.',
      },
    });
    onDeviceLocationUpdate({
      ...deviceDetails,
      Address: location.Address,
      City: location.City,
      State: location.State,
      Zip: location.Zip,
      Country: location.Country,
      CustomerName: location.CustomerName,
      CustomerNumber: location.CustomerNumber,
    });
  };

  return (
    <>
      <Form onSubmit={onDeviceLocationChange}>
        <div className={className}>
          {closeBtns ? (<div className='w-full block'>
            <div className='px-6 pt-6 pb-4'>
            <FormItems
              items={[
                {
                  name: 'Location',
                  label: 'Search Location',
                  className:"elipsisText",
                  selectAllValue:'All Locations',
                  type: FormType.SELECT,
                  isDisplayElipsis: true,
                  selectMultiSelect: false,
                  selectShowCheckboxes: false,
                  options: customerAddress.map((item) => ({
                    label: item.SearchBy,
                    value: item,
                  })),
                  required: true,
                  ClearSelectedIcon: <></>,
                  errors: {
                    required: 'Please provide device location',
                  },
                },
              ]}
              className='w-full'
            />
            </div>
            <div className="flex justify-end py-4 px-6 border-t border-gray-200 ">
              {closeBtns}
              <SubmitBtn className={`commonBtn`}>Submit</SubmitBtn>
            </div>
          </div>) : (
          <div className='xl:flex w-full '>

            <FormItems
              items={[
                {
                  name: 'Location',
                  label: 'Search Location',
                  selectAllValue:'All Locations',
                  className:"elipsisText",
                  type: FormType.SELECT,
                  isDisplayElipsis: true,
                  selectMultiSelect: false,
                  selectShowCheckboxes: false,
                  options: customerAddress.map((item) => ({
                    label: item.SearchBy,
                    value: item,
                  })),
                  required: true,
                  ClearSelectedIcon: <></>,
                  errors: {
                    required: 'Please provide device location',
                  },
                },
              ]}
              className='xl:w-5/6'
            />

            <div className="xl:w-1/6 mt-6 xl:ml-2 xl:mb-0 mb-4">
              <SubmitBtn className={`commonBtn`}>Submit</SubmitBtn>
            </div>
          </div>
          )}
        </div>
      </Form>
    </>
  );
};
