import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  getDefaultGridOptions,
  getQueryParams,
  getLocalStorageConfig,
  setGridColumns,
  setLocalStorageConfig,
  removeLocalStorageConfig,
  FilterStatePostFix,
  ColStatePostFix,
  SortStatePostFix,
  getDefaultGridOptionsForClientGrid,
} from './table-helper';
import { AgGridReact } from 'ag-grid-react';
import * as XLSX from 'xlsx';
// eslint-disable-next-line import/named
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { APPLICATION_CONTEXT } from '../../utils/utils';
import NoRowsFound from './no-rows-found/not-rows-found';
import { ComponentLoading } from '../component-loader/component-loading';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../store/notification/notification.action';
import moment from 'moment';
import { DATE_FORMAT } from '../../constant/constant';

export type APITableService = (queryData: {}) => Promise<any> | undefined;
export interface IAPIDataProps {
  gridDataService?: APITableService;
  columns: Array<any>;
  gridName: string;
  gridOptions?: GridOptions;
  rowData?: Array<any> | null;
  setExportBtnVisible?: any;
  gridProps?: any;
  isStoreFilterSortPreferance?: boolean;
  isLoading?: boolean;
  exportCSVQueryParams?: object;
  gridBtnClassName?: string;
  onGridFilterChanged?: any;
}
const ApiTable = (
  {
    columns,
    gridDataService,
    gridName,
    gridOptions: gridParamOptions = {},
    rowData = null,
    setExportBtnVisible = null,
    gridProps = {},
    isStoreFilterSortPreferance = true,
    isLoading = false,
    exportCSVQueryParams = {},
    gridBtnClassName = 'grid-export-btn',
    onGridFilterChanged = () => {},
  }: IAPIDataProps,
  ref: any,
) => {
  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const [columnApi, setColumnApi] = useState<ColumnApi | undefined>();
  const [downloadingSheet, setDownloadingSheet] = useState<boolean>();
  const [columnData] = useState({ colData: columns });
  const { isClientApp, customerInfo } = useContext<any>(APPLICATION_CONTEXT);
  const [isShow, setShow] = useState(true);
  const [apiTableProps, setApiTableProps] = useState({});
  const [queryParams, setQueryParams] = useState({});
  const [columnsDataArr, setColumnDataArr] = useState(columns);
  const dispatch = useDispatch();
  let options: any;
  if (rowData) {
    options = getDefaultGridOptionsForClientGrid();
  } else {
    options = getDefaultGridOptions();
  }
  const gridOptions: any = {
    ...options,
    ...gridParamOptions,
  };

  const gridApiRef = useRef({});
  const rowDataRef = useRef([]);
  const storageKey = isClientApp ? customerInfo?.CustomerId : 'Admin';

  const getFilterKey = () => {
    return `${gridName}${FilterStatePostFix}`;
  };

  const getColStateKey = () => {
    return `${gridName}${ColStatePostFix}`;
  };
  const getSortKey = () => {
    return `${gridName}${SortStatePostFix}`;
  };

  const handleExportBtnVisibility = (notRowFound: any) => {
    const element = document.getElementsByClassName(gridBtnClassName);
    if (element && element.length) {
      if (notRowFound) {
        element[0].classList.remove('hidden');
      } else {
        element[0].classList.add('hidden');
      }
    }
  };
  const getRows = async ({
    gridApiData = gridApi,
    endRow,
    sortModel,
    filterModel,
    successCallback,
    failCallback,
  }: any) => {
    let notRowFound = false;
    try {
      gridApiData.showLoadingOverlay();
      setQueryParams({});
      const queryParam = getQueryParams({
        endRow,
        sortModel,
        filterModel,
        paginationPageSize: gridOptions?.paginationPageSize,
      });
      if (isStoreFilterSortPreferance) {
        setLocalStorageConfig({
          configKey: getFilterKey(),
          data: queryParam.gridFilterModal,
          storageKey: storageKey,
        });
        setLocalStorageConfig({
          configKey: getSortKey(),
          data: sortModel,
          storageKey: storageKey,
        });
      }

      let response;
      if (gridDataService) {
        response = await gridDataService(queryParam);
      }
      setQueryParams(queryParam);
      rowDataRef.current = response?.data;
      if (!response?.data?.length) {
        notRowFound = true;
        gridApiData.showNoRowsOverlay();
        successCallback([], 0);
      } else {
        notRowFound = false;
        const totalRows = response?.totalRows || response?.data.length;
        successCallback(response?.data, totalRows);
      }
      if (setExportBtnVisible) {
        setExportBtnVisible(!notRowFound);
      }
      handleExportBtnVisibility(!notRowFound);
    } catch (e) {
      gridApiData.showNoRowsOverlay();
      failCallback();
    } finally {
      if (!notRowFound) {
        gridApiData.hideOverlay();
      }
    }
  };

  const resetGridFilter = () => {
    removeLocalStorageConfig({
      configKey: getFilterKey(),
      storageKey: storageKey,
    });

    gridApi?.setFilterModel(null);
  };

  const refreshGridData = () => {
    if (rowData) {
      gridApi?.refreshCells();
    } else {
      setShow(false);
      setTimeout(() => {
        setShow(true);
      }, 100);
    }
  };

  const resetGridData = () => {
    setColumnDataArr([...columns]);
    removeLocalStorageConfig({
      configKey: getFilterKey(),
      storageKey: storageKey,
    });
    removeLocalStorageConfig({
      configKey: getColStateKey(),
      storageKey: storageKey,
    });
    removeLocalStorageConfig({
      configKey: getSortKey(),
      storageKey: storageKey,
    });
    // setTimeout(() => {
    //   columnApi?.resetColumnState();
    // }, 500);
    if (rowData) {
      gridApi?.setFilterModel(null);
    }

    refreshGridData();
    // setTimeout(() => {
    //   gridApi.setFilterModel(null);
    //   if (rowData === null) {
    //     gridApi.setDatasource({
    //       getRows: async (data) => {
    //         await getRows({ gridApiData: gridApi, ...data });
    //       },
    //     });
    //   }
    // }, 1000);
  };

  const onGridReady = (params: any) => {
    gridApiRef.current = params.api;
    setGridApi(params.api);
    setColumnApi(params.columnApi);

    // const filterModal = getLocalStorageConfig({
    //   configKey: getFilterKey(),
    //   storageKey: storageKey,
    // });
    // params.api.setFilterModel(filterModal);
    // const updateData = (data) => params.api.setRowData(data);

    const filterModal = isStoreFilterSortPreferance
      ? getLocalStorageConfig({
          configKey: getFilterKey(),
          storageKey: storageKey,
        })
      : null;
    params.api.setFilterModel(filterModal);
    if (rowData === null) {
      params.api.setDatasource({
        getRows: async (data: any) => {
          await getRows({ gridApiData: params.api, ...data });
        },
      });
    }
  };

  const createSheet = async (data: any, filename: any) => {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, `${filename.replace(/grid/gi, 'data')}.xlsx`);
  };
  const downloadSheet = async () => {
    if (!downloadingSheet) {
      dispatch(showNotification({ type: 'success', message: rowData?.length > 5000 ? 'Exporting data, up to 5000 rows...' : 'Exporting data...' }));
      try {
        setDownloadingSheet(true);
        const colState: any = columnApi?.getColumnState();
        const columnConfig = [];
        colState.forEach((column: any) => {
          if (column.colId !== 'isActionColumn') {
            columnConfig.push({
              ...column,
              ...columnsDataArr.find(
                (columnDef) =>
                  (columnDef.title || columnDef.field) === column.colId,
              ),
            });
          }
        });
        let gridData = [];
        if (rowData) {
          gridApi?.forEachNodeAfterFilter((node) => {
            gridData.push(node.data);
          });
        } else {
          if (gridDataService) {
            const response = await gridDataService({
              ...queryParams,
              ...exportCSVQueryParams,
            });
            gridData = response.records || response.data || response;
          }
        }

        const filterData: any = [];
        gridData.forEach((record: any) => {
          const returnValue: any = {};

          // eslint-disable-next-line consistent-return
          columnData.colData.forEach((columnDef) => {
            
            if (!columnDef.isActionColumn) {
              const isShowCSVField = columnDef?.isShowCSVfield !== false;
              if (isShowCSVField) {
                if (columnDef.renderCSVColumn) {
                  returnValue[columnDef.headerName] =
                    columnDef.renderCSVColumn(record);
                } else if (columnDef.field === 'CreatedDate' && typeof record[columnDef.field] === 'number') {
                  returnValue[columnDef.headerName] = moment(record[columnDef.field]).format(DATE_FORMAT);
                }else {
                  returnValue[columnDef.headerName] = record[columnDef.field];
                }
              }
            }
          });

          filterData.push(returnValue);
        });
        
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await createSheet(filterData, gridName);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        dispatch(showNotification({ type: 'success', message: 'Export complete, your download will start shortly.' }));
        setDownloadingSheet(false);
      }
    }
  };

  const storeColumnState = ({ onResize = false }) => {
    const state: any = columnApi?.getColumnState();
    const columnArr: any = [];
    state.map((colData: any) => {
      const columnStateData = columns.find(
        ({ field }) => field === colData.colId,
      );

      columnArr.push({
        ...columnStateData,
        ...colData,
      });
    });
    if (!onResize) {
      setColumnDataArr(columnArr);
    }
    if (isStoreFilterSortPreferance) {
      setLocalStorageConfig({
        configKey: getColStateKey(),
        data: columnArr,
        storageKey: storageKey,
      });
    }
  };

  const setExportBtnVisibiliy = () => {
    let gridData = [];
    if (rowData) {
      gridApi?.forEachNodeAfterFilter((node) => {
        gridData.push(node.data);
      });
    }

    const isRowDataAvailable = !!gridData?.length;
    if (rowData) {
      if (isRowDataAvailable) {
        gridApi?.hideOverlay();
      } else {
        gridApi?.showNoRowsOverlay();
      }
    }

    if (setExportBtnVisible) {
      setExportBtnVisible(isRowDataAvailable);
    }
    handleExportBtnVisibility(isRowDataAvailable);
  };

  useImperativeHandle(ref, () => ({
    resetGrid() {
      resetGridData();
    },
    refreshGrid() {
      refreshGridData();
    },
    async exportCSV() {
      await downloadSheet();
    },
    resetFilter() {
      resetGridFilter();
    },
    showGridLoader() {
      gridApi?.showLoadingOverlay();
    },
    hideGridLoader() {
      gridApi?.hideOverlay();
    },
    getDisplayedRowCount() {
      return gridApi?.getDisplayedRowCount()
    }
  }));
  useEffect(() => {
    setColumnDataArr([...columns]);
  }, [columns]);

  useEffect(() => {
    let apiTablePropsOptions = {};
    if (rowData) {
      handleExportBtnVisibility(false);
    }
    if (rowData && !isLoading) {
      Object.assign(apiTablePropsOptions, {
        rowData: rowData,
      });
      const isRowDataAvailable = !!rowData?.length;
      handleExportBtnVisibility(isRowDataAvailable);
    }
    setApiTableProps({ ...apiTablePropsOptions });
  }, [rowData, isLoading]);

  return (
    <div id={'gridMain'} className="fixedHeightGrid fch100">
      <div className="ag-theme-alpine h-full w-full">
        {isShow ? (
          <AgGridReact
            onColumnResized={() => {
              storeColumnState({ onResize: true });
            }}
            onColumnMoved={() => {
              storeColumnState({});
            }}
            onFilterChanged={() => {
              if (rowData) {
                if (isStoreFilterSortPreferance) {
                  setLocalStorageConfig({
                    configKey: getFilterKey(),
                    data: gridApi?.getFilterModel(),
                    storageKey: storageKey,
                  });
                }
              }
              setExportBtnVisibiliy();
              onGridFilterChanged();
            }}
            suppressDragLeaveHidesColumns={true}
            onSortChanged={storeColumnState}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            suppressMenuHide={true}
            columnDefs={setGridColumns({
              columns: columnsDataArr,
              gridName,
              storageKey,
            })}
            ref={ref}
            loadingOverlayComponent={() =>
              ComponentLoading({ isShowLoader: true })
            }
            noRowsOverlayComponent={NoRowsFound}
            {...apiTableProps}
            {...gridProps}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default forwardRef(ApiTable);