import { BaseReportGrid } from '../base-report-grid/base-report-grid';
import { ClientGridName } from '../../../../../constant/constant';
import { Api } from '../../../../../shared/api/api';

export const ActiveDevice = () => {
  const getReportData = async (searchObj) => {
    try {
      return await Api.callAPI({
        url: `/api/v1/reports/FMAudit/active-device/${searchObj.CustomerID}`,
        method: 'PUT',
        body: searchObj,
      });
    } catch (e) {}
  };

  return (
    <>
      <h2 className="mb-6 text-gray-950 text-lg font-semibold">
        Active Devices On FMAudit
      </h2>
      <BaseReportGrid
        gridName={ClientGridName.ActiveOn}
        ApiDataFunction={getReportData}
        newFields={[
          {
            headerName: 'FirstAudit',
            field: 'FirstAudit',
          },
          {
            headerName: 'LastAudit',
            field: 'LastAudit',
          },
          {
            headerName: 'IP',
            field: 'IPAddress',
            width: 100,
          },
          {
            headerName: 'Last Mono',
            field: 'LastMono',
          },
          {
            headerName: 'Last Color',
            field: 'LastColor',
          },
        ]}
      />
    </>
  );
};
