




import React from 'react';
import './recycle-toner.scss'


const RecycleToner = () => {
  return (
    <>
      <h1 className='mb-4 text-2xl font-medium'>Recycle Toner</h1>
      <iframe className="w-[auto] min-w-[50vw] h-full min-h-[75vh] iframe-clean" src="https://elabel.arsreclabel.com/labels/flexprintindex.aspx">
      </iframe>
    </>
  );
};

export default RecycleToner;
