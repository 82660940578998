import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Api } from '../../../shared/api/api';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../store/loading/loading.action';
import { showNotification } from '../../../store/notification/notification.action';
import { CheckBox } from '../../../components/ui';

const Banner = () => {
  const dispatch = useDispatch();
  const [bannerText, setBannerText] = useState('');
  const [showBanner, setShowBanner] = useState(false);
  const [maintenanceText, setMaintenanceText] = useState('');
  const [showMaintenance, setShowMaintenance] = useState(false);

  const getBannerData = async () => {
    dispatch(showLoader());
    try {
      const response = await Api.callAPI({
        url: `/api/v1/banner`,
        method: 'GET',
      });
      if (response && response.data[0].BannerData) {
        setBannerText(response.data[0].BannerData);
        setShowBanner(response.data[0].IsActive);
      }
    } catch (error) {}
    dispatch(hideLoader());
  };

  const getMaintenanceData = async () => {
    dispatch(showLoader());
    try {
      const response = await Api.callAPI({
        url: `/api/v1/banner/maintenance`,
        method: 'GET',
      });
      if (response && response.data.BannerData) {
        setMaintenanceText(response.data.BannerData);
        setShowMaintenance(response.data.IsActive);
      }
    } catch (error) {}
    dispatch(hideLoader());
  };

  useEffect(() => {
    getBannerData().then();
    getMaintenanceData().then();
  }, []);

  const checkBannerTextIsEmpty = (text) => {
    let tempText = text;
    tempText = tempText.replace(/<[^>]*>/g, '');
    return !!!tempText.trim();
  };

  const submitBanner = async () => {
    dispatch(showLoader());

    if (checkBannerTextIsEmpty(bannerText)) {
      if (confirm(`Are you sure you want to disable banner with no content?`)) {
        try {
          await Api.callAPI({
            url: `/api/v1/banner`,
            method: 'POST',
            body: {
              BannerData: null,
              IsActive: false,
              type: 1,
            },
          });
          setShowBanner(false);
          dispatch(
            showNotification({
              message: `Banner disable successfully.`,
              type: 'success',
            }),
          );
        } catch (error) {
          dispatch(
            showNotification({
              message: `Error in disable banner.`,
              type: 'success',
            }),
          );
        }
      }
    } else {
      if (bannerText.length > 3500) {
        dispatch(
          showNotification({
            message: 'Error: banner character limit exceeded',
            type: 'error',
          }),
        );
      } else {
        if (
          confirm(
            `Are you sure you want to ${
              showBanner ? 'enable' : 'disable'
            } banner?`,
          )
        ) {
          try {
            let banner = bannerText;
            banner = banner.replace(`href="http://mailto:`, `href="mailto:`);
            banner = banner.replace(`href="http://tel:`, `href="tel:`);
            await Api.callAPI({
              url: `/api/v1/banner`,
              method: 'POST',
              body: {
                BannerData: banner,
                IsActive: showBanner,
                type: 1,
              },
            });
            dispatch(
              showNotification({
                message: `Banner ${
                  showBanner ? 'enable' : 'disable'
                } successfully.`,
                type: 'success',
              }),
            );
          } catch (error) {
            dispatch(
              showNotification({
                message: `Error in ${
                  showBanner ? 'enable' : 'disable'
                } banner.`,
                type: 'success',
              }),
            );
          }
        }
      }
    }
    dispatch(hideLoader());
  };

  const submitMaintenance = async () => {
    dispatch(showLoader());

    if (checkBannerTextIsEmpty(maintenanceText)) {
      if (
        confirm(`Are you sure you want to disable maintenance with no content?`)
      ) {
        try {
          await Api.callAPI({
            url: `/api/v1/banner`,
            method: 'POST',
            body: {
              BannerData: null,
              IsActive: false,
              type: 2,
            },
          });
          setShowMaintenance(false);
          dispatch(
            showNotification({
              message: `Maintenance disable successfully.`,
              type: 'success',
            }),
          );
        } catch (error) {
          dispatch(
            showNotification({
              message: `Error in disable maintenance.`,
              type: 'success',
            }),
          );
        }
      }
    } else {
      if (maintenanceText.length > 3500) {
        dispatch(
          showNotification({
            message: 'Error: maintenance character limit exceeded',
            type: 'error',
          }),
        );
      } else {
        if (
          confirm(
            `Are you sure you want to ${
              showMaintenance ? 'enable' : 'disable'
            } maintenance?`,
          )
        ) {
          try {
            let banner = maintenanceText;
            banner = banner.replace(`href="http://mailto:`, `href="mailto:`);
            banner = banner.replace(`href="http://tel:`, `href="tel:`);
            await Api.callAPI({
              url: `/api/v1/banner`,
              method: 'POST',
              body: {
                BannerData: banner,
                IsActive: showMaintenance,
                type: 2,
              },
            });
            dispatch(
              showNotification({
                message: `Maintenance ${
                  showMaintenance ? 'enable' : 'disable'
                } successfully.`,
                type: 'success',
              }),
            );
          } catch (error) {
            dispatch(
              showNotification({
                message: `Error in ${
                  showMaintenance ? 'enable' : 'disable'
                } maintenance.`,
                type: 'success',
              }),
            );
          }
        }
      }
    }
    dispatch(hideLoader());
  };

  return (
    <>
      <div className="">
        <h2 className="font-semibold text-gray-600">Admin Banner</h2>
        <div>
          <div className="bg-gray-100 mt-4 rounded-lg p-6 mb-6">
            <h3 className="font-semibold mb-4">Banner</h3>
            <ReactQuill
              value={bannerText}
              onChange={(e) => {
                setBannerText(e);
              }}
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  [{ color: [] }, { background: [] }],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ align: [] }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  ['link'],
                ],
              }}
            />
            <div className="mt-4">
              <CheckBox
                name={'publishToggle'}
                label={'Show banner above pages'}
                type={'checkbox'}
                checked={showBanner}
                onClick={(e: any) => {
                  setShowBanner(e?.target?.checked);
                }}
              />
            </div>
            <div className="flex sm:justify-end sm:mt-0 mt-4">
              <button
                className="borderedBtn linkText mr-4"
                onClick={() => {
                  setBannerText('');
                }}
              >
                Cancel
              </button>
              <button className="primaryBtn bgColor" onClick={submitBanner}>
                Save Banner
              </button>
            </div>
          </div>
          <div className="bg-gray-100 mt-4 rounded-lg p-6 mb-6">
            <h3 className="font-semibold mb-4">Maintenance Page</h3>
            <ReactQuill
              value={maintenanceText}

              onChange={(e) => {
                setMaintenanceText(e);
              }}
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  [{ color: [] }, { background: [] }],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ align: [] }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  ['link'],
                ],
              }}
            />
            <div className="mt-4">
              <CheckBox
                name={'publishToggle2'}
                label={'Enable maintenance page'}
                type={'checkbox'}
                checked={showMaintenance}
                onClick={(e: any) => {
                  setShowMaintenance(e?.target?.checked);
                }}
              />
            </div>

            <div className="flex sm:justify-end sm:mt-0 mt-4">
              <button
                className="borderedBtn linkText mr-4"
                onClick={() => {
                  setMaintenanceText('');
                }}
              >
                Cancel
              </button>
              <button
                className="primaryBtn bgColor"
                onClick={submitMaintenance}
              >
                Save Maintenance Page
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
