import { TreeView, TreeViewData } from '../../../../components/ui/TreeView';

export const LogSelection = ({ onChange, selected }: any) => {
  //TODO: Make an API call to get this data
  const TreeData: TreeViewData[] = [
    {
      name: 'Eautomate Authentication',
      value: 91,
      children: [
        {
          name: 'Eautomate Authentication',
          value: { Api: '/api/v1/users/eAutomate/login', Method: 'POST' },
        },
      ],
    },
    {
      name: 'Customer User Authentication',
      value: 92,
      children: [
        {
          name: 'User Authentication',
          value: { Api: '/api/v1/users/login', Method: 'POST' },
        },
        {
          name: 'Reset Forgot Password',
          value: { Api: '/api/v1/users/forgotPassword', Method: 'POST' },
        },
        {
          name: 'Reset Password Request',
          value: { Api: '/api/v1/users/resetPassword/:token', Method: 'GET' },
        },
        {
          name: 'Set user Password',
          value: {
            Api: '/api/v1/users/setUserPassword/:token',
            Method: 'POST',
          },
        },
        {
          name: 'User Change Password',
          value: { Api: '/api/v1/users/:id/changePassword', Method: 'POST' },
        },
      ],
    },
    {
      name: 'Cart Products',
      value: 93,
      children: [
        {
          name: 'Post Cart Products',
          value: { Api: '/api/v1/carts/', Method: 'POST' },
        },
        {
          name: 'Get Cart Products',
          value: { Api: '/api/v1/carts/list/:customerId', Method: 'GET' },
        },
        {
          name: 'Delete Cart Products',
          value: { Api: '/api/v1/carts/:itemId/:customerId', Method: 'DELETE' },
        },
        {
          name: 'Update Cart Products',
          value: { Api: '/api/v1/carts/:id', Method: 'PUT' },
        },
        {
          name: 'Get Cart Products Count',
          value: { Api: '/api/v1/carts/list/count/:customerId', Method: 'GET' },
        },
      ],
    },
    {
      name: 'Customers',
      value: 94,
      children: [
        {
          name: 'Get Customers',
          value: { Api: '/api/v1/customers/', Method: 'GET' },
        },
        {
          name: 'Get Customers Details By ID',
          value: { Api: '/api/v1/customers/:Id', Method: 'GET' },
        },
        {
          name: 'Post Customer',
          value: { Api: '/api/v1/customers/', Method: 'POST' },
        },
        {
          name: 'Delete Customer',
          value: { Api: '/api/v1/customers/:Id', Method: 'DELETE' },
        },
        {
          name: 'Update Customer',
          value: { Api: '/api/v1/customers/:Id', Method: 'PUT' },
        },
        {
          name: 'Get Customers By Name',
          value: { Api: '/api/v1/customers/search/:name', Method: 'GET' },
        },
        {
          name: 'Get User Customer List From Id',
          value: {
            Api: '/api/v1/customers/getUserCustomerListFromId',
            Method: 'POST',
          },
        },
        {
          name: 'Get Customer Users',
          value: { Api: '/api/v1/customers/:customerId/users', Method: 'GET' },
        },
        {
          name: 'Get Customers By Ids',
          value: { Api: '/api/v1/customers/getCustomersByIds', Method: 'GET' },
        },
        {
          name: 'Check Customer Prefix',
          value: {
            Api: '/api/v1/customers/checkCustomer/prefix',
            Method: 'POST',
          },
        },
      ],
    },
    {
      name: 'Customer Shipping Address',
      value: 95,
      children: [
        {
          name: 'Get Customer Shipping Address',
          value: {
            Api: '/api/v1/customers/:customerId/address',
            Method: 'GET',
          },
        },
      ],
    },
    {
      name: 'Customer Users',
      value: 96,
      children: [
        {
          name: 'Get Customer Users',
          value: { Api: '/api/v1/users/customer/:customerId', Method: 'GET' },
        },
        {
          name: 'Post Customer User',
          value: { Api: '/api/v1/users/', Method: 'POST' },
        },
        {
          name: 'Delete Customer User',
          value: { Api: '/api/v1/users/:id', Method: 'DELETE' },
        },
        {
          name: 'Update Customer User',
          value: { Api: '/api/v1/users/:id', Method: 'PUT' },
        },
        {
          name: 'Get Customer User By Id',
          value: { Api: '/api/v1/users/:id', Method: 'GET' },
        },
        {
          name: 'Customer User Change Password',
          value: { Api: '/api/v1/users/:id/updatePassword', Method: 'POST' },
        },
        {
          name: 'Get All Users',
          value: { Api: '/api/v1/users/list ', Method: 'GET' },
        },
        {
          name: 'Set Selected Customers',
          value: {
            Api: '/api/v1/users/:userId/customer/:customerId',
            Method: 'PUT',
          },
        },
      ],
    },
    {
      name: 'Equipment',
      value: 97,
      children: [
        {
          name: 'Get Equipments By CustomerID',
          value: {
            Api: '/api/v1/equipments/customer/:customerId',
            Method: 'PUT',
          },
        },
        {
          name: 'Get Equipment Detail By Equipment ID',
          value: { Api: '/api/v1/equipments/:id', Method: 'GET' },
        },
        {
          name: 'Get Device Details By Equipment ID',
          value: { Api: '/api/v1/equipments/device/:id', Method: 'PUT' },
        },
        {
          name: 'Get Devices By Location And Customer ID',
          value: {
            Api: '/api/v1/equipments/location/:locationId/:customerId',
            Method: 'GET',
          },
        },
        {
          name: 'Update Equipment Location',
          value: {
            Api: '/api/v1/equipments/:equipmentId/location',
            Method: 'PUT',
          },
        },
        {
          name: 'Update Equipment Location Remark',
          value: {
            Api: '/api/v1/equipments/:equipmentId/locationRemark',
            Method: 'PUT',
          },
        },
        {
          name: 'Update Cost Center',
          value: {
            Api: '/api/v1/equipments/:equipmentId/CostCenter',
            Method: 'PUT',
          },
        },
        {
          name: 'Update Equipment',
          value: { Api: '/api/v1/equipments/:equipmentId', Method: 'PUT' },
        },
        {
          name: 'Add Device',
          value: { Api: '/api/v1/equipments/', Method: 'POST' },
        },
        {
          name: 'Retire Device By Customer',
          value: {
            Api: '/api/v1/equipments/:equipmentId/retireDevice',
            Method: 'POST',
          },
        },
        {
          name: 'Report Device By Customer',
          value: {
            Api: '/api/v1/equipments/:equipmentId/reportDevice',
            Method: 'POST',
          },
        },
        {
          name: 'Get Equipment Detail For ZenDesk',
          value: { Api: '/api/v1/equipments/:id/zenDesk', Method: 'GET' },
        },
        {
          name: 'Update Equipment Detail For ZenDesk',
          value: {
            Api: '/api/v1/equipments/:id/updateEquipmentDetails',
            Method: 'PUT',
          },
        },
        {
          name: 'Insert Equipment Detail Note For ZenDesk',
          value: {
            Api: '/api/v1/equipments/insertEquipmentDetailsNote',
            Method: 'POST',
          },
        },
        {
          name: 'Get Device Details',
          value: { Api: '/api/v1/equipments/', Method: 'PUT' },
        },
        {
          name: 'Get Toner Level By Device',
          value: {
            Api: '/api/v1/equipments/:equipmentId/tonerLevel',
            Method: 'GET',
          },
        },
        {
          name: 'Get Last Toner Level Order By Device',
          value: {
            Api: '/api/v1/equipments/:equipmentId/lastOrderToner',
            Method: 'PUT',
          },
        },
        {
          name: 'Config Reports Upload',
          value: {
            Api: '/api/v1/equipments/configReports/upload',
            Method: 'POST',
          },
        },
      ],
    },
    {
      name: 'Order Items',
      value: 98,
      children: [
        {
          name: 'Get OrderItems By OrderId',
          value: { Api: '/api/v1/orders/:id/items', Method: 'GET' },
        },
        {
          name: 'Get Device Order By Customer And Equipment ID',
          value: { Api: '/api/v1/orders/device', Method: 'PUT' },
        },
        {
          name: 'Get Orders By Customer And Location ID',
          value: { Api: '/api/v1/orders/location/:locationId', Method: 'GET' },
        },
        {
          name: 'Get All Order History',
          value: { Api: '/api/v1/orders/history', Method: 'PUT' },
        },
        {
          name: 'Get Order Detail By ID',
          value: { Api: '/api/v1/orders/:id', Method: 'PUT' },
        },
        {
          name: 'Send Emails To NonWeb Orders',
          value: {
            Api: '/api/v1/orders/nonWebOrders/sendEmails',
            Method: 'GET',
          },
        },
      ],
    },
    // {
    //   name: "Search Product, value: 99, children: [
    //   {
    //     name:Search Product,
    //     value: {Api: "/api/v1/products/search, Method: "POST"},
    //     displayName: Search Product
    //   },
    //   {
    //     name: "Get All Items,
    //     value: {Api: "/api/v1/products/getAllItems, Method: "POST"},
    //     displayName:Get All Items
    //   },
    //   {
    //     name: "Frequently purchased Item history,
    //     value: {Api: "/api/v1/products/orderHistory, Method: "POST"},
    //     displayName: Frequently purchased Item history
    //   }
    // ]
    // },
    // {
    //   name: "Placed Order, value: 100, children: [
    //   {
    //     name: "Place Sales Order,
    //     value: {Api: "/api/v1/orders/, Method: "POST"},
    //     displayName: Place Sales Order
    //   }
    // ]
    // },
    // {
    //   name: "Service Request, value:101, children: [
    //   {
    //     name: "Service Request Call,
    //     value: {Api: "/api/v1/services/requestCall, Method: "POST"},
    //     displayName: Service Request Call
    //   },
    //   {
    //     name: "Get Service Request Call By ID,
    //     value: {Api: "/api/v1/services/requestCall/:id/:customerId, Method: "GET"},
    //     displayName: Get Service Request Call By ID
    //   },
    //   {
    //     name: "Get Service Request Call History,
    //     value: {Api: "/api/v1/services/requestCalls, Method: "PUT"},
    //     displayName: Get Service Request Call History
    //   },
    //   {
    //     name: "Get Service Request By Customer And Equipment,
    //     value: {Api: "/api/v1/services/requestCalls/device, Method: "PUT"},
    //     displayName: Get Service Request By Customer And Equipment
    //   },
    //   {
    //     name: "Get Service Request By Location And Customer,
    //     value: {Api: "/api/v1/services/requestCalls/location/:locationId/:customerId, Method: "GET"},
    //     displayName: Get Service Request By Location And Customer
    //   },
    //   {
    //     name: "Get Service Request By Customer And Work Order Number,
    //     value: {Api: "/api/v1/services/requestCalls/workOrderRelated/:workOrderNumber/:customerId, Method: "GET"},
    //     displayName: Get Service Request By Customer And Work Order Number
    //   },
    //   {
    //     name: "Check New Valid Service Request,
    //     value: {Api: "/api/v1/services/checkNewRequest/:equipmentId/:customerId, Method: "GET"},
    //     displayName: Check New Valid Service Request
    //   },
    //   {
    //     name: "Get Service Request Problem,
    //     value: {Api: "/api/v1/services/problems, Method: "GET"},
    //     displayName: Get Service Request Problem
    //   },
    //   {
    //     name: "Service Request WithOut Device,
    //     value: {Api: "/api/v1/services/requestWithoutDevice, Method: "POST"},
    //     displayName: Service Request WithOut Device
    //   }
    // ]
    // },
    // {
    //   name: "Warehouses, value: 102, children: [
    //   {
    //     name: "Get Warehouses,
    //     value: {Api: "/api/v1/warehouses, Method: "GET"},
    //     displayName: Get Warehouses
    //   }
    // ]
    // },
    // {
    //   name: "Current Login Users , value: 103, children: [
    //   {
    //     name: "Get Current Login User,
    //     value: {Api: "/api/v1/users/currentLogin/user/:id, Method: "GET"},
    //     displayName: Get Current Login User
    //   }
    // ]
    // },
    // {
    //   name: "Update User Profile  , value: 104, children: [
    //   {
    //     name: "Update User Profile,
    //     value: {Api: "/api/v1/users/:id/profile , Method: "PUT"},
    //     displayName: Update User Profile
    //   }
    // ]
    // },
    // {
    //   name: "Feedback Mail , value: 105 , children: [
    //   {
    //     name: "Feedback,
    //     value: {Api: "/api/v1/users/feedback, Method: "POST"},
    //     displayName: Feedback
    //   },
    //   {
    //     name: "Login Feedback,
    //     value: {Api: "/api/v1/users/loginFeedback, Method: "POST"},
    //     displayName: Login Feedback
    //   }
    // ]
    // },
    // {
    //   name: "Access-Logs, value: 106, children: [
    //   {
    //     name: "Access-Logs,
    //     value: {Api: "/api/v1/accesslogs, Method: "GET"},
    //     displayName: Access-Logs
    //   }
    // ]
    // },
    // {
    //   name: "Super Admin User, value: 107, children: [
    //   {
    //     name: "Add Super Admin User,
    //     value: {Api: "/api/v1/superadmin/users/, Method:"POST"},
    //     displayName: Add Super Admin User
    //   },
    //   {
    //     name: "Delete Super Admin User,
    //     value: {Api: "/api/v1/superadmin/users/:id, Method:"DELETE"},
    //     displayName: Delete Super Admin User
    //   },
    //   {
    //     name: "Get Super Admin User List,
    //     value: {Api: "/api/v1/superadmin/users/, Method:"GET"},
    //     displayName: Get Super Admin User List
    //   },
    //   {
    //     name: "Get Super Admin User,
    //     value: {Api: "/api/v1/superadmin/users/:id, Method:"GET"},
    //     displayName: Get Super Admin User
    //   },
    //   {
    //     name: "Update Super Admin User,
    //     value: {Api: "/api/v1/superadmin/users/:id, Method:"PUT"},
    //     displayName: Update Super Admin User
    //   }
    // ]
    // },
    // {
    //   name: "Location, value: 108, children: [
    //   {
    //     name: "Get Locations,
    //     value: {Api: "/api/v1/location/, Method:"PUT"},
    //     displayName: Get Locations
    //   },
    //   {
    //     name: "Get Location Detail,
    //     value: {Api: "/api/v1/location/:id, Method:"GET"},
    //     displayName: Get Location Detail
    //   },
    //   {
    //     name: "Update Location,
    //     value: {Api: "/api/v1/location/:LocationID/updateLocation, Method:"PUT"},
    //     displayName: Update Location
    //   },
    //   {
    //     name: "Add Location,
    //     value: {Api: "/api/v1/location/, Method:"POST"},
    //     displayName: Add Location
    //   },
    //   {
    //     name: "Remove Location,
    //     value: {Api: "/api/v1/location/:LocationID/deleteLocation, Method:"POST"},
    //     displayName: Remove Location
    //   }
    // ]
    // },
    // {
    //   name: "Reports, value: 109, children: [
    //   {
    //     name: "Get Reports,
    //     value: {Api: "/api/v1/reports/:customerId, Method:"PUT"},
    //     displayName: Get Reports
    //   },
    //   {
    //     name: "Get FMAudits Reports,
    //     value: {Api: "/api/v1/reports/FMAudit/active-device/:customerId, Method:"PUT"},
    //     displayName: Get FMAudits Reports
    //   },
    //   {
    //     name: "Get Retired Device FMAudits Reports,
    //     value: {Api: "/api/v1/reports/FMAudit/retired-device/:customerId, Method:"PUT"},
    //     displayName: Get Retired Device FMAudits Reports
    //   },
    //   {
    //     name: "Get Never Reported Device FMAudits Reports,
    //     value: {Api: "/api/v1/reports/FMAudit/never-reported-device/:customerId, Method:"PUT"},
    //     displayName: Get Never Reported Device FMAudits Reports
    //   },
    //   {
    //     name: "Get Stopped Reporting Device FMAudits Reports,
    //     value: {Api: "/api/v1/reports/FMAudit/stopped-device/:customerId, Method:"PUT"},
    //     displayName: Get Stopped Reporting Device FMAudits Reports
    //   },
    //   {
    //     name: "Send Resolution Device FMAudits Reports,
    //     value: {Api: "/api/v1/reports/FMAudit/resolutionDevice, Method:"POST"},
    //     displayName: Send Resolution Device FMAudits Reports
    //   }
    // ]
    // },
    // {
    //   name: "Quotes, value: 110, children: [
    //   {
    //     name: "Get Quotes,
    //     value: {Api: "/api/v1/quotes/:customerId, Method:"GET"},
    //     displayName: Get Quotes
    //   }
    // ]
    // },
    // {
    //   name: "Make, value: 111, children: [
    //   {
    //     name: "Get Makes,
    //     value: {Api: "/api/v1/makes/:customerId, Method:"GET"},
    //     displayName: Get Makes
    //   }
    // ]
    // },
    // {
    //   name: "Models, value: 112, children: [
    //   {
    //     name: "Get Models By Make,
    //     value: {Api: "/api/v1/models/:makeId/:customerId, Method:"GET"},
    //     displayName: Get Models By Make
    //   }
    // ]
    // }
  ];

  return (
    <TreeView
      label="Api Selection"
      data={TreeData}
      openChildrenOnSearch={true}
      hideNoFocus={true}
      onClick={(value) => onChange(value)}
      showTreeOnSearch={false}
      placeholder={selected?.name || 'Select API Route'}
      selected={selected?.name || null}
    />
  );
};
