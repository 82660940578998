export const EditIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.22217 13.7777H1.72217C1.72217 14.0539 1.94603 14.2777 2.22217 14.2777V13.7777ZM13.4421 3.80176L13.7957 3.44821L13.7957 3.4482L13.4421 3.80176ZM12.1981 2.55774L11.8446 2.9113V2.9113L12.1981 2.55774ZM10.5778 2.55774L10.2243 2.20419V2.20419L10.5778 2.55774ZM2.55774 10.5778L2.9113 10.9314L2.9113 10.9314L2.55774 10.5778ZM5.42206 13.4421L5.06851 13.0886L5.42206 13.4421ZM11.9608 6.90336L12.3144 7.25692V7.25692L11.9608 6.90336ZM13.4421 5.42206L13.0886 5.06851L13.0886 5.06851L13.4421 5.42206ZM1.72217 11.388V13.7777H2.72217V11.388H1.72217ZM2.22217 14.2777H4.61191V13.2777H2.22217V14.2777ZM13.7957 3.4482L12.5517 2.20419L11.8446 2.9113L13.0886 4.15531L13.7957 3.4482ZM10.2243 2.20419L8.74297 3.68549L9.45008 4.3926L10.9314 2.9113L10.2243 2.20419ZM8.74297 3.68549L2.20419 10.2243L2.9113 10.9314L9.45008 4.3926L8.74297 3.68549ZM5.77561 13.7957L12.3144 7.25692L11.6073 6.54981L5.06851 13.0886L5.77561 13.7957ZM12.3144 7.25692L13.7957 5.77561L13.0886 5.06851L11.6073 6.54981L12.3144 7.25692ZM8.74297 4.3926L11.6073 7.25692L12.3144 6.54981L9.45008 3.68549L8.74297 4.3926ZM13.0886 4.15531C13.3408 4.40748 13.3408 4.81634 13.0886 5.06851L13.7957 5.77561C14.4384 5.13292 14.4384 4.0909 13.7957 3.44821L13.0886 4.15531ZM4.61191 14.2777C5.04838 14.2777 5.46698 14.1043 5.77561 13.7957L5.06851 13.0886C4.94741 13.2097 4.78317 13.2777 4.61191 13.2777V14.2777ZM2.72217 11.388C2.72217 11.2167 2.7902 11.0525 2.9113 10.9314L2.20419 10.2243C1.89556 10.5329 1.72217 10.9515 1.72217 11.388H2.72217ZM12.5517 2.20419C11.909 1.56149 10.867 1.5615 10.2243 2.20419L10.9314 2.9113C11.1836 2.65912 11.5924 2.65913 11.8446 2.9113L12.5517 2.20419Z"
        fill="#888888"
      />
    </svg>
  );
};
