import React from 'react';
import './component-loading.scss';
import { useSelector } from 'react-redux';
import FlexPrintImage from '../../assets/images/flextg_loader.gif';
import { TENANT } from '../../constant/constant';
import { RootState } from '../../store/store';

export const ComponentLoading = ({ isShowLoader = false, whiteBackground = false }) => {
  const tenantName: any = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );

  const FlotechLoader = () => {
    return (
      <div className="spinner triangles">
        <div className="tri tri-invert"></div>
        <div className="tri tri-invert"></div>
        <div className="tri"></div>
        <div className="tri tri-invert"></div>
        <div className="tri tri-invert"></div>
        <div className="tri"></div>
        <div className="tri tri-invert"></div>
        <div className="tri"></div>
        <div className="tri tri-invert"></div>
      </div>
    );
  };

  const FlexPrintLoader = () => {
    return (
      <div className="spinner">
        <img src={FlexPrintImage} />
      </div>
    );
  };

  const MarimonLoader = () => {
    return (
      <div className="spinner triangles">
        <img src={FlexPrintImage} />
      </div>
    );
  };
  const SandboxLoader = () => {
    return (
      <div className="spinner">
        <img src={FlexPrintImage} />
      </div>
    );
  };
  return (
    <>
      {isShowLoader ? (
        <div className={`component-loading ${whiteBackground && 'component-background'}`}>
          {tenantName === TENANT.FLOTECH ? <FlotechLoader /> : ''}
          {tenantName === TENANT.FLEXPRINT ? <FlexPrintLoader /> : ''}
          {tenantName === TENANT.MARIMON ? <MarimonLoader /> : ''}
          {tenantName === TENANT.SANDBOX ? <SandboxLoader /> : ''}
        </div>
      ) : (
        ''
      )}
    </>
  );
};
