import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { showMessage, trimStringsFromObjects } from "../utils/utils";
import { AdminStorageKey, ClientStorageKey, TenantKey } from "../constant/constant";

interface Pending {
    url: string;
    abort: AbortController;
}

interface APIResponse {
    loading: boolean;
    error: any;
    data: any;
}

interface RequestOptions extends RequestInit {
    params?: any;
    method?: "POST" | "GET" | "PUT" | "DELETE";
    isLambda?: boolean;
}

interface FlexLayer extends APIResponse {
    flexLayer: (url: string, data?: any, options?: RequestOptions) => Promise<APIResponse>;
    abortAll: () => void;
}

interface ResponseWithError extends Response {
    error?: any;
}


//TODO: possibly use env variable per app to determine flexlayer url
let ENDPOINT; // TODO: clean up and strip into environment variables passed via amplify
let LAMBDA_ENDPOINT;
let hostName = window.location.hostname;

switch (true) {
  case hostName.includes('localhost'):
    ENDPOINT = 'http://localhost:9001';
    LAMBDA_ENDPOINT = 'https://cahg8qi9s5.execute-api.us-west-2.amazonaws.com/dev';
    break;
  case hostName.includes('dev'):
    ENDPOINT = 'https://flexlayerdev.flextg.com';
    LAMBDA_ENDPOINT = 'https://cahg8qi9s5.execute-api.us-west-2.amazonaws.com/dev';
    break;
  case hostName.includes('test'):
    ENDPOINT = 'https://flexlayerstaging.flextg.com';
    LAMBDA_ENDPOINT = 'https://flexlayerstaging.flextg.com';
    break;
  case hostName.includes('my.') || hostName.includes('portal.'):
    ENDPOINT = 'https://flexlayer.flextg.com';
    LAMBDA_ENDPOINT = 'https://flexlayer.flextg.com';
    break;
  default:
    ENDPOINT = 'https://flexlayerdev.flextg.com';
    break;
}

// Default headers, unsure why this is needed atm (it exists in the old API function)
const defaultHeaders = {
    portal: 'fcp',
}


export const useFlexLayer = (url?: string, data?: any, options?: RequestOptions): FlexLayer => {

    const { getAccessTokenSilently, user } = useAuth0();

    const [state, setState] = useState({
        loading: true,
        error: false,
        data: null
    });

    const [pending, setPending] = useState<Pending[]>([]);

    // Determine if the user is an admin or a client, and get the localstorage info
    const isAdmin = window.location.pathname.split('/')[1] === 'admin';
    const userInfo = isAdmin ? 
        JSON.parse(localStorage.getItem(ClientStorageKey.customerInfo)) : 
        JSON.parse(localStorage.getItem(AdminStorageKey.userInfo));

    //Old Auth way to get tokens
    const accessToken = isAdmin ? localStorage.getItem(AdminStorageKey.ACCESS_TOKEN) : localStorage.getItem(ClientStorageKey.ACCESS_TOKEN);
    const refreshToken = isAdmin ? localStorage.getItem(AdminStorageKey.REFRESH_TOKEN) : localStorage.getItem(ClientStorageKey.REFRESH_TOKEN);

    // Default query params
    const defaultQueryParams = {
        __dc: Math.random(), // Cache buster, unsure why we're doing this
        email: isAdmin ? userInfo?.name : userInfo?.EmailAddress,
        portalName: localStorage.getItem(TenantKey),
    }

    const abortAll = () => {
        pending.forEach((item) => item.abort.abort());
        setPending([]);
    } 

    // Function to update the old token
    const updateToken = async (tokenData: { accessToken?: string, customerInfo?: any, refreshToken?: string }) => {
        if(!tokenData || !tokenData?.accessToken || !tokenData?.refreshToken || !tokenData?.customerInfo) return;
        if(isAdmin) {
            localStorage.setItem(AdminStorageKey.ACCESS_TOKEN, tokenData.accessToken);
            localStorage.setItem(AdminStorageKey.REFRESH_TOKEN, tokenData.refreshToken);
            localStorage.setItem(AdminStorageKey.userInfo, JSON.stringify(tokenData.customerInfo));
        } else {
            localStorage.setItem(ClientStorageKey.ACCESS_TOKEN, tokenData.accessToken);
            localStorage.setItem(ClientStorageKey.REFRESH_TOKEN, tokenData.refreshToken);
            localStorage.setItem(ClientStorageKey.customerInfo, JSON.stringify(tokenData.customerInfo));
        }
    }


    const fetchData = async (sendUrl: string, sendData?: any, sendOptions?: RequestOptions) => {
        // Check if the request is already pending
        const pendingRequest = pending.find((item) => item.url === sendUrl);
        // If the request is already pending, abort it
        if (pendingRequest) pendingRequest.abort.abort();

        // Add the request to the pending list
        // const abortController = new AbortController();
        // setPending([...pending, { url: sendUrl, abort: abortController }]);

        //Linkname is used to determine the environment, and is passed to the backend
        //TODO: clean up and strip into environment variables passed via amplify
        let linkname = window.location.origin.split('//')[1];
        if(linkname.includes('amplifyapp')) linkname = 'mydev.flextg.com';

        // https://flexprint-logs-rapidops-production.s3.us-west-2.amazonaws.com/logos/flex-email-logo.png
        // #AD1F23

        const trimmedSendData = sendData ? trimStringsFromObjects(sendData) : null;
        const token = user ? await getAccessTokenSilently() : null; // JWT Token

        const method = sendOptions?.method || (sendData ? 'POST' : 'GET');
        delete sendOptions?.method;

        const fetchOpts = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? `Bearer ${token}` : null, // Swap between old JWT and new Auth0 token
                'authZero': token ? 'true' : null, // If authzero token is present, use authzero
                'refreshtoken': refreshToken ? `Bearer ${refreshToken}` : null, // Old refresh token
                'Accesstoken': accessToken ? `Bearer ${accessToken}` : null, // Old access token
                linkname, // Environment
                ...defaultHeaders
            },
            body: trimmedSendData ? JSON.stringify(trimmedSendData) : null,
            // signal: abortController.signal,
            params: {
                ...defaultQueryParams,
                ...sendOptions?.params
            },
            ...sendOptions
        }

        try {
            const response: ResponseWithError = await fetch(
                `${sendOptions?.isLambda ? 
                    `${LAMBDA_ENDPOINT}/v1` : 
                    `${ENDPOINT}/api/v1`}${sendUrl}`,
                fetchOpts
            );

            if(response.error) {
                throw response.error;
            }

            // Update the token if it exists
            const resData = await response.json();
            
            // Sometimes flexlayer has nested data, so we need to check for that
            let innerData = resData?.data?.data || resData?.data || resData;
            //Hacky way to fix the totalRows issue
            if(resData?.data?.totalRows) innerData = resData.data;

            updateToken(innerData);

            if(response.status !== 200 && response.status !== 201) throw innerData;

            setPending(pending.filter((item) => item.url !== sendUrl));
            return { loading: false, error: false, data: innerData };
        } catch (error) {
            //TODO: Handle JWT Errors
            
            if(error?.error?.Name === 'JsonWebTokenValidationError') {
                window.location.href = '/login';
            }
            showMessage({ type: 'error', message: error.error?.Message || error.Message || error });
            setPending(pending.filter((item) => item.url !== sendUrl));
            return { loading: false, error: error, data: null };
        }
    };

    const loadInitialData = async () => {
        const res = await fetchData(url, data, options);
        setState({ ...res});
    }

    useEffect(() => {
        if(url) loadInitialData();

        return () => {
            abortAll();
        }
    }, []);

    const flexLayer = async (sendUrl: string, sendData?: any, sendOptions?: RequestOptions) => {
        const res = await fetchData(sendUrl, sendData, sendOptions);
        return res;
    }

    return { ...state, flexLayer, abortAll};

}
    
