import { TrashIcon } from "../../../../../../assets/svg/trash-icon";
import { Button } from "../../../../../../components/ui";
import { useModal } from "../../../../../../context/ModalConext";
import { Api } from "../../../../../../shared/api/api";


export const DeleteUser = ({cell}) => {

    const { openModal, closeModal } = useModal();
    const userId = cell.data._id;


    const submitDelete = async () => {
        Api.callAPI({
            url: `/api/v1/users/${userId}`,
            method: 'delete',
            options: {
                successMessage: 'User deleted successfully.',
                errorMessage: 'Unexpected error occurred, please try again.',
            }
        });

        closeModal();
        cell.api.applyTransaction({remove: [cell.data]});

    }

    const ModalContent = () => {
        return <div className="p-6">
            <h1 className="text-lg font-bold flex text-red-500">Delete {cell.data.EmailAddress}</h1>
            <div className="mt-4">Are you sure you'd like to delete <span className="font-bold italic">{cell.data.EmailAddress}</span></div>
            <div className="mt-4 w-full flex items-end justify-end">
                <Button className="px-2 py-1 mr-2" ghost onClick={closeModal}>Cancel</Button>
                <Button className="px-2 py-1 rounded" onClick={submitDelete}>
                    Delete User
                </Button>
            </div>
        </div>
    }

    return <Button
            ghost
            className="w-full h-full flex items-center justify-center border-none"
            onClick={() =>openModal({
                content: <ModalContent />,
            })}>
            <TrashIcon />
        </Button>
}
