import { isNull, isUndefined, omit } from 'lodash';
import moment from 'moment';
import storage from '../../utils/storage/storage';

// import moment from 'moment';

export const ColStatePostFix = '_Grid_ColumnState';
export const FilterStatePostFix = '_Grid_FilterModel';
export const SortStatePostFix = '_Grid_SortMode';

export const FilterType = {
  AgTextColumnFilter: 'agTextColumnFilter',
  AgDateColumnFilter: 'agDateColumnFilter',
};

const renderCell = ({ column, cellEvent }: any) => {
  const cellData = cellEvent?.node?.data;
  if (cellData) {
    if (column.cellRenderer) {
      return column.cellRenderer(cellEvent);
    }
    if (column?.isDate) {
      return cellData[column.field]
        ? moment(cellData[column.field]).format('MM/DD/YYYY').toString()
        : '-';
    }
    return isNull(cellData[column.field]) || isUndefined(cellData[column.field])
      ? '-'
      : cellData[column.field].toString();
  }
  return '-';
};

const onCellClicked = async ({ column, cellEvent }: any) => {
  const cellData = cellEvent?.node?.data;
  if (cellData) {
    if (column.onCellClicked) {
      return column.onCellClicked(cellEvent);
    }
  }
};

export const setColumnFilterOptions = (columns: any) => {
  const columnDefs: any = [];
  columns.forEach((column: any) => {
    let filterEnabled = !isUndefined(column.filter) ? column.filter : true;
    let sortable = !isUndefined(column.sortable) ? column.sortable : true;
    let resizable = !isUndefined(column.resizable) ? column.resizable : true;
    let width = !isUndefined(column.width) ? column.width : undefined;
    let suppressMovable = !isUndefined(column.suppressMovable)
      ? column.suppressMovable
      : false;
    let autoHeight = !isUndefined(column.autoHeight)
      ? column.autoHeight
      : false;
    if (column.isActionColumn) {
      sortable = false;
      filterEnabled = false;
      resizable = false;
      width = 130;
    }
    // const obj = omit(column, ['isDate']);

    const columnConfig = {
      ...omit(column, [
        'isActionColumn',
        'isDate',
        'title',
        'onEdit',
        'onDelete',
        'isShowCSVfield',
        'renderCSVColumn',
      ]),
      filter: filterEnabled ? FilterType.AgTextColumnFilter : '',
      resizable,
      width,
      // order: column?.order || undefined,
      suppressMovable: suppressMovable,
      // comparator: () => 0,
      filterParams: filterEnabled
        ? {
            // buttons: ['reset', 'apply'],
            // suppressAndOrCondition: true,
            // filterOptions: ['contains'],
            // debounceMs: 200,
          }
        : undefined,
      sortable,
      // filterFramework: undefined,
      autoHeight: autoHeight,
      field: column.field || column.title,
      // eslint-disable-next-line max-len
      cellRenderer: column.component
        ? column.component
        : (cellEvent: any) => renderCell({ column, cellEvent }),
      onCellClicked: (cellClickEvent: any) =>
        onCellClicked({
          // eslint-disable-next-line max-len
          column: column,
          cellEvent: cellClickEvent,
        }),
      pivot: undefined,
      rowGroup: undefined,
    };
    if (column?.filterParams) {
      columnConfig.filterParams = column.filterParams;
    }
    if (filterEnabled && column.filter === FilterType.AgDateColumnFilter) {
      columnConfig.filter = FilterType.AgDateColumnFilter;
      columnConfig.filterParams = {
        filterOptions: ['inRange'],
        defaultOption: 'inRange',
        applyButton: true,
        maxNumConditions: 1,
      };
    }

    columnDefs.push(columnConfig);
  });
  return columnDefs;
};

export const getDefaultGridOptions = () => {
  return {
    rowHeight: 50,
    headerHeight: 44,
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
      suppressMovable: false,
    },
    enableCellTextSelection: true,
    pagination: true,
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    rowModelType: 'infinite',
    cacheBlockSize: 15,
    paginationPageSize: 15,
    maxBlocksInCache: 1,
  };
};

export const getDefaultGridOptionsForClientGrid = () => {
  return {
    rowHeight: 50,
    headerHeight: 44,
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
      suppressMovable: false,
    },
    enableCellTextSelection: true,
    pagination: true,
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    paginationPageSize: 20,
  };
};

const getFilterObject = ({ filterModel }: any) => {
  const filterObject: any = {};

  const filterParamsKeys = Object.keys(filterModel);
  if (filterParamsKeys.length) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of filterParamsKeys) {
      if (filterModel[key]) {
        if (filterModel[key].filterType === 'date') {
          const dateFrom = moment(filterModel[key].dateFrom).format(
            'YYYY-MM-DD',
          );
          const dateTo = moment(filterModel[key].dateTo).format('YYYY-MM-DD');

          filterObject[`Start${key}`] = filterModel[key].dateFrom
            ? dateFrom
            : null;
          filterObject[`End${key}`] = filterModel[key].dateTo ? dateTo : null;
          // filterObject[key] = filterModel[key];
        } else {
          filterObject[key] = filterModel[key].filter;
        }
      }
    }
  }
  return { apiFilterObj: filterObject, gridFilterModal: filterModel };
};
export const getQueryParams = ({
  endRow,
  sortModel,
  filterModel,
  paginationPageSize = null,
}: any) => {
  const searchQuery = {
    sortBy: sortModel.length ? sortModel[0].colId : undefined,
    sortOrder: sortModel.length ? sortModel[0].sort : undefined,
    sortDirection: sortModel.length ? sortModel[0].sort : undefined,
    pageNo: Math.ceil(endRow / (paginationPageSize ? paginationPageSize : 15)),
    rows: paginationPageSize ? paginationPageSize : 15,
  };

  const { apiFilterObj, gridFilterModal } = getFilterObject({
    filterModel,
  });
  return { ...searchQuery, ...apiFilterObj, gridFilterModal: gridFilterModal };
};

export const setLocalStorageConfig = ({ configKey, data, storageKey }: any) => {
  const storedColumnsData: any = storage.getJSONItem(`${storageKey}`)
    ? storage.getJSONItem(`${storageKey}`)
    : {};

  storedColumnsData[configKey] = data;

  storage.setJSONItem(`${storageKey}`, storedColumnsData);
};

export const getLocalStorageConfig = ({ configKey, storageKey }: any) => {
  const storedColumnsData: any = storage.getJSONItem(`${storageKey}`)
    ? storage.getJSONItem(`${storageKey}`)
    : null;
  if (!storedColumnsData) {
    return null;
  }
  if (storedColumnsData && storedColumnsData[configKey]) {
    return storedColumnsData[configKey];
  }
  return null;
};

export const removeLocalStorageConfig = ({ configKey, storageKey }: any) => {
  const storedColumnsData: any = storage.getJSONItem(`${storageKey}`)
    ? storage.getJSONItem(`${storageKey}`)
    : null;

  if (storedColumnsData && storedColumnsData[configKey]) {
    delete storedColumnsData[configKey];
    storage.setJSONItem(storageKey, storedColumnsData);
  }
};
export const setGridColumns = ({ columns, gridName, storageKey }: any) => {
  const storageColumn = getLocalStorageConfig({
    configKey: `${gridName}${ColStatePostFix}`,
    storageKey: storageKey,
  });
  let gridColumns = [];

  if(storageColumn) {
    gridColumns = storageColumn.filter((item) => {
      const match = columns.find((col) => col.field === item.field);
      if(match) {
        return item;
      }
    });
    columns.map((item, index) => {
      const match = storageColumn.find((col) => col.field === item.field);
      if(!match) {
        gridColumns.splice(index, 0, item);
        return item;
      }
    });
    // gridColumns.push(...columnNotInStorage);
  } else {
    gridColumns = columns;
  }
  
  const columnArr: any = [];
  gridColumns.map((gridColumnData: any) => {
    const columnData = columns.find(
      ({ field: colField }: any) => colField === gridColumnData?.field,
    );
    columnArr.push({
      ...columnData,
      ...gridColumnData,
    });
  });

  return setColumnFilterOptions(columnArr);
};
