import {
  useEffect,
  useReducer,
  useId,
  createContext,
  useContext,
  Dispatch,
} from 'react';
import {
  FormDefinition,
  FormDefinitionErrors,
  FormRef,
  FormType,
  InputMask,
} from './types/FormTypes';
import { DropDownOptions } from '../ui/DropDown';

interface FormProps {
  className?: string;
  onSubmit?: (formState: any) => void;
  // formDefinition: Array<FormDefinition>;
  cols?: number;
  onChange?: (values: any) => void;
  flexColumn?: boolean;
  isSubmitting?: boolean;
  errors?: FormDefinitionErrors;
  children?: React.ReactNode;
  onCancel?: () => void;
  formRef?: FormRef;
  defaultValues?: any;
  onError?: (errors: any) => void;
}

const DEFAULT_ERROR_MESSAGES = {
  required: '{{label}} is required',
  pattern: 'Please enter valid {{label}}',
  minLength: 'You need to enter at least {{minLength}} characters',
  maxLength: 'You have entered more than {{maxLength}} characters',
  min: 'The Minimum value is {{min}}',
  max: 'The Maximum value is {{max}}',
  condition: 'Not all conditions have been met.',
  option: 'Please choose an option',
  minDate: 'Please choose a date after {{minDate}}',
  maxDate: 'Please choose a date before {{maxDate}}',
  invalidDate: 'Please enter a valid date',
  mask: 'Please enter a valid {{mask}}',
  matchInput: 'The values do not match',
  requiredInputs: 'Please fill out all required fields',
  uploadMaxSize: 'Your file must not exceed {{uploadMaxSize}}',
  customValidation: 'Invalid',
};

enum ErrorType {
  REQUIRED = 'required',
  PATTERN = 'pattern',
  MIN_LENGTH = 'minLength',
  MAX_LENGTH = 'maxLength',
  MIN = 'min',
  MAX = 'max',
  CONDITION = 'condition',
  OPTION = 'option',
  MIN_DATE = 'minDate',
  MAX_DATE = 'maxDate',
  INVALID_DATE = 'invalidDate',
  MASK = 'mask',
  MATCH_INPUT = 'matchInput',
  REQUIRED_INPUTS = 'requiredInputs',
  UPLOAD_MAX_SIZE = 'uploadMaxSize',
  Custom = 'customValidation',
}

interface FormContextFunctions {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputValidation: (formDef: FormDefinition) => void;
  handleSelection: (values: any, name: string) => void;
  handleDropDown: (values: any, name: string) => void;
  handleSubmit: (formData: any) => void;
  formState: any;
  formDefinition: FormDefinition[];
  dispatch: Dispatch<any>;
  isSubmitDisabled: boolean;
  isSubmitting: boolean;
  handleRequiredInputs: (formDef: FormDefinition) => boolean;
  dispatchFormDef: Dispatch<any>;
  clearInputs: (inputs: string[]) => void;
  clearForm: () => void;
}

const FormContext = createContext<FormContextFunctions | undefined>(undefined);

export const Form = ({
  onSubmit,
  onChange,
  isSubmitting,
  errors = DEFAULT_ERROR_MESSAGES,
  children,
  className,
  onCancel,
  formRef,
  defaultValues,
  onError,
}: FormProps) => {
  const [formState, dispatch] = useReducer(
    (currentFormState, newFormState) => ({
      ...currentFormState,
      ...newFormState,
    }),
    {},
  );
  const [formDefinition, dispatchFormDef] = useReducer(
    (currentFormDefinition: any, newFormDefinition: object) => ({
      ...currentFormDefinition,
      ...newFormDefinition,
    }),
    [],
  );

  const isSubmitDisabled =
    Object.keys(formState).some((key) => formState[key].error) || isSubmitting;
  const formKey = useId();

  //Merge default error messages with custom error messages
  errors = { ...DEFAULT_ERROR_MESSAGES, ...errors };

  useEffect(() => {
    return onChange && onChange(formState);
  }, [formState]);

  useEffect(() => {
    // apply default values to formState
    if (defaultValues) {
      Object.keys(defaultValues).forEach((key) => {
        dispatch({ [key]: { value: defaultValues[key] } });
      });
    }
  }, [defaultValues]);

  const renderErrorMessage = (
    formDef: FormDefinition,
    errorType: ErrorType,
  ) => {
    //Swap varibles in string to values
    let errorString = formDef.errors?.[errorType] || errors[errorType];
    if (errorString) {
      if (typeof errorString === 'string') {
        const matches = errorString.match(/{{(.*?)}}/g);
        if (matches) {
          matches.forEach((match) => {
            const variable = match.replace('{{', '').replace('}}', '');
            if (errorType == ErrorType.UPLOAD_MAX_SIZE) {
              errorString = errorString.replace(
                match,
                `${formDef.uploadMaxSize / 1000000}MB`,
              );
              return;
            }
            errorString = errorString.replace(match, formDef[variable]);
          });
        }
      }
    }
    return errorString;
  };

  const handleMatchInput = (formDef: FormDefinition) => {
    if (!formDef.matchInput) return true;
    const { name, matchInput } = formDef;
    const matchInputValue = formState[matchInput]?.value;
    const value = formState[name]?.value;
    const isValid = value === matchInputValue && !!value && !!matchInputValue;

    //remove error from both inputs if they match
    if (isValid)
      dispatch({
        [name]: { ...formState[name], error: null },
        [matchInput]: { ...formState[matchInput], error: null },
      });
    return isValid;
  };

  const handleRequiredInputs = (formDef: FormDefinition) => {
    const { requiredInputs } = formDef;
    if (!requiredInputs) return true;
    const isValid = requiredInputs.every((requiredInput) => {
      const requiredInputValue = formState[requiredInput]?.value;
      return requiredInputValue;
    });
    return isValid;
  };

  const handleInputMaskPatterns = (mask, value) => {
    if (!mask) return true;

    let maskTest = null;
    switch (mask) {
      case InputMask.PHONE:
        //Mask should have a value of +1 (###) ###-####
        maskTest = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;
        //or ###-###-####
        if (!maskTest.test(value)) maskTest = /^\d{3}-\d{3}-\d{4}$/;
        break;
      case InputMask.IP:
      case InputMask.IPV4:
        maskTest = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
        break;
      case InputMask.CURRENCY:
        maskTest = /^\d{1,3}(?:\.\d{1,2})?$/;
        break;
      case InputMask.DATE:
        maskTest = /^\d{2}\/\d{2}\/\d{4}$/;
        break;
      case InputMask.TIME:
        maskTest = /^\d{2}:\d{2}:\d{2}$/;
        break;
      case InputMask.ZIP:
        maskTest = /^\d{5}(?:-\d{4})?$/;
        break;
      case InputMask.CREDIT_CARD:
        maskTest = /^\d{4}-\d{4}-\d{4}-\d{4}$/;
        break;
      case InputMask.SSN:
        maskTest = /^\d{3}-\d{2}-\d{4}$/;
        break;
      default:
        return true;
    }

    const isValid = maskTest.test(value);
    return isValid;
  };

  const handlePatternValidation = (formDef: FormDefinition) => {
    const { name, pattern } = formDef;
    const patternRegx = new RegExp(pattern);
    const value = formState[name]?.value;
    return pattern && patternRegx.test(value);
  };

  const handleInputValidation = (formDef: FormDefinition) => {
    const {
      type,
      name,
      maxLength,
      minLength,
      min,
      max,
      required,
      minDate,
      maxDate,
      mask,
    } = formDef;
    const value = formState[name]?.value;
    const files = formState[name]?.files;
    const isFilled =
      formState[name]?.value?.length > 0
        ? formState[name]?.value?.toString().trim()
        : false || !!formState[name]?.value?.toString();

    //Length of input validation
    let VALIDATION_ERROR = null;
    let error = null;

    //valide fields that have this field required as a dependency
    if (formDef.matchInput) {
      handleInputValidation(formDefinition[formDef.matchInput]);
    }
    // handle pattern  validation
    if (formDef?.pattern && !handlePatternValidation(formDef)) {
      VALIDATION_ERROR = ErrorType.PATTERN;
    }

    if (
      formDef?.CustomValidation &&
      !formDef?.CustomValidation(formDef, formState)
    ) {
      VALIDATION_ERROR = ErrorType.Custom;
    }

    if (!isFilled && !required) {
      return false;
    }

    if (!isFilled && required) {
      error = { [name]: renderErrorMessage(formDef, ErrorType.REQUIRED) };
      dispatch({
        [name]: {
          ...formState[name],
          error: renderErrorMessage(formDef, ErrorType.REQUIRED),
        },
      });
      return error;
    }

    if (files && files.length > 0) {
      let fileArray: File[] = Array.from(files);
      let maxSize = formDef.uploadMaxSize || 10000000;
      fileArray.forEach((file) => {
        if (file.size > maxSize) VALIDATION_ERROR = ErrorType.UPLOAD_MAX_SIZE;
      });
    }

    if (mask) {
      let isValid = handleInputMaskPatterns(mask, value);
      if (!isValid) VALIDATION_ERROR = ErrorType.MASK;
    }

    if (
      type === FormType.SELECT &&
      formDef.required &&
      !formState[name]?.value?.length
    )
      VALIDATION_ERROR = ErrorType.REQUIRED;

    if (
      type === FormType.EMAIL &&
      !/^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/.test(
        value,
      )
    )
      VALIDATION_ERROR = ErrorType.PATTERN;

    if (minLength && value?.length < minLength)
      VALIDATION_ERROR = ErrorType.MIN_LENGTH;
    if (maxLength && value?.length > maxLength)
      VALIDATION_ERROR = ErrorType.MAX_LENGTH;
    if ((!!min || min === 0) && value < min) VALIDATION_ERROR = ErrorType.MIN;
    if (max && value > max) VALIDATION_ERROR = ErrorType.MAX;

    if (value && type == 'date') {
      const date = new Date(value);
      if (date.toString() === 'Invalid Date')
        VALIDATION_ERROR = ErrorType.INVALID_DATE;

      if (minDate) {
        const minDateObj = new Date(minDate);
        if (date < minDateObj) VALIDATION_ERROR = ErrorType.MIN_DATE;
      }
      if (maxDate) {
        const maxDateObj = new Date(maxDate);
        if (date > maxDateObj) VALIDATION_ERROR = ErrorType.MAX_DATE;
      }
    }

    //Check if input matches another input
    if (formDef.matchInput && !handleMatchInput(formDef))
      VALIDATION_ERROR = ErrorType.MATCH_INPUT;

    //Check if all required inputs are filled
    if (formDef.requiredInputs && !handleRequiredInputs(formDef))
      VALIDATION_ERROR = ErrorType.REQUIRED_INPUTS;

    //check if condition is met
    if (typeof formDef.condition === 'boolean' && !formDef.condition)
      VALIDATION_ERROR = ErrorType.CONDITION;

    if (VALIDATION_ERROR) {
      error = { [name]: renderErrorMessage(formDef, VALIDATION_ERROR) };
      dispatch({
        [name]: {
          ...formState[name],
          error: renderErrorMessage(formDef, VALIDATION_ERROR),
        },
      });
      return error;
    }

    //Remove Error Msg
    if (formState[name] && formState[name].error)
      dispatch({ [name]: { ...formState[name], error: null } });
    return null;
  };



  const handleSubmit = (event: any | null) => {
    if (!!event) event.preventDefault();

    let isInvalid = false;
    let validationErrors = null;
    const newFormState: any = {};

    Object.keys(formDefinition).forEach((key) => {
      let formDef = formDefinition[key];
      if (formDef.type === FormType.SUBMIT) return;
      let error = handleInputValidation(formDefinition[key]);
      const isRadioOrCheckBox =
        formDef.type === FormType.CHECKBOX || formDef.type === FormType.RADIO;

      //If checkbox or raido doesn't have radio options, and it's not required, default value to false
      if (isRadioOrCheckBox && !formDef.radioOptions && !formDef.required) {
        newFormState[formDef.name] = false;
      }

      //If checkbox or radio has radio options, is checked, set value to the value of the selected radio option
      if (
        isRadioOrCheckBox &&
        formState[formDef.name] &&
        formDef.radioOptions
      ) {
        newFormState[formDef.name] = formState[formDef.name].value;
      }

      isInvalid = isInvalid || !!error;
      if (error) validationErrors = { ...validationErrors, ...error };
    });

    //delete errors from each object in formState
    Object.keys(formState).forEach((key) => {
      //Clean up returned JSON to only include keys and values
      if (!formState[key]) return;
      if (formState[key].selected)
        return (newFormState[key] = formState[key].selected);
      if (formState[key].files) return (newFormState[key] = formState[key]);
      if (!formState[key].value) return;
      if (!!formState[key] && formDefinition[key]?.type == FormType.RADIO)
        return (newFormState[key] = formState[key].value[0]);
      if (!!formState[key] && formDefinition[key]?.type == FormType.SELECT)
        return (newFormState[key] = formState[key].value.map(
          (value) => value.value,
        ));
      return (newFormState[key] = formState[key].value);
    });

    if (!!validationErrors) newFormState.errors = validationErrors;
    if (isInvalid) return onError && onError(validationErrors);
  
    return onSubmit && onSubmit(newFormState);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = event.target;
    if (files) return dispatch({ [name]: { value: value, files: files } });
    return dispatch({ [name]: { value: value } });
  };

  const handleSelection = (
    values: Array<DropDownOptions> | any,
    name: string,
  ) => {
    //have values only be the values of the returned DropDownOptions
    let cleanValues = !!values.map
      ? values.map((value) => value.value)
      : values;
    //if cleanValues is an empty array, remove name from formState
    if (cleanValues.length === 0) {
      const newFormState = formState;
      delete newFormState[name];
      return dispatch(newFormState);
    }

    dispatch({ [name]: { value: cleanValues, error: null } });
  };

  const handleDropDown = (values: Array<DropDownOptions>, name: string) => {
    dispatch({ [name]: { value: values, error: null } });
  };

  const clearForm = () => {
    //clear current added fields in formState and restore default values
    const newFormState = formState;

    Object.keys(formDefinition).forEach((key) => {
      //If value isn't set, don't clear it
      if (!formState[key]) return;

      const formDef = formDefinition[key];
      if (formDef.defaultValue) {
        return (newFormState[formDef.name] = {
          value: formDef.defaultValue,
          error: null,
        });
      } else {
        delete newFormState[formDef.name];
      }
    });

    dispatch(newFormState);

    return onCancel && onCancel();
  };

  const clearInputs = (inputs: string[]) => {
    const newFormState = formState;
    //delete keys from formState
    inputs.forEach((input) => delete newFormState[input]);
    dispatch(newFormState);
  };

  const clearInputsRestoreDefaults = (inputs: string[]) => {
    const newFormState = formState;
    //delete keys from formState
    inputs.forEach((input) => {
      if (newFormState[input].defaultValue)
        return (newFormState[input] = {
          value: newFormState[input].defaultValue,
        });
      delete newFormState[input];
    });
    dispatch(newFormState);
  };

  //Add functions to ref object
  useEffect(() => {
    if (!formRef) return;
    formRef.submit = (event) => handleSubmit(event);
    formRef.clearInputs = (inputs: string[]) => clearInputs(inputs);
    formRef.clearInputsRestoreDefaults = (inputs: string[]) =>
      clearInputsRestoreDefaults(inputs);
    formRef.clear = clearForm;
    formRef.isSubmitDisabled = isSubmitDisabled;
    formRef.values = formState;
    formRef.removeFormDef = (inputs: string[]) => {
      const newFormDef = formDefinition;
      inputs.forEach((input) => delete newFormDef[input]);
      dispatchFormDef(newFormDef);
    };
  }, [formState, formDefinition]);

  return (
    <form
      onSubmit={handleSubmit}
      key={formKey}
      className={className}
      ref={formRef}
    >
      <FormContext.Provider
        value={{
          handleChange,
          handleInputValidation,
          handleDropDown,
          formDefinition,
          formState,
          dispatch,
          handleSelection,
          handleSubmit,
          isSubmitDisabled,
          isSubmitting,
          handleRequiredInputs,
          dispatchFormDef,
          clearInputs,
          clearForm,
        }}
      >
        {children}
      </FormContext.Provider>
    </form>
  );
};

export function useForm() {
  const context = useContext<FormContextFunctions | undefined>(FormContext);
  if (context === undefined) {
    throw new Error(
      'useForm must be used within a FormProvider, inside the <Form> Component',
    );
  }
  return context;
}
