export const TENANT = Object.freeze({
  FLOTECH: 'flotech',
  MARIMON: 'marimon',
  FLEXPRINT: 'flextg',
  SANDBOX: 'sandbox',
});

export const CAPTCHA_KEY = '6LdQMeQoAAAAABT_tZoOsE4BlCPFO3167kTNEthf';

export const TENANT_NAME: any = Object.freeze({
  [TENANT.FLOTECH]: 'Flo-Tech',
  [TENANT.MARIMON]: 'Marimon',
  [TENANT.FLEXPRINT]: 'FTG',
  [TENANT.SANDBOX]: 'Sandbox',
});

export const AdminStorageKey = {
  Admin: 'Admin',
  userInfo: 'userInfo',
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  username: 'username',
  passwordAdmin: 'passwordAdmin',
  rememberAdmin: 'rememberAdmin',
};
export const ClientStorageKey = {
  isSelectedCustomer: 'isSelectedCustomer',
  customerInfo: 'customerInfo',
  email: 'email',
  password: 'password',
  remember: 'remember',
  DashboardGridSettingsLayout: 'DashboardGridSettingsLayout',
  ACCESS_TOKEN: 'client_accessToken',
  REFRESH_TOKEN: 'client_refreshToken',
  RequestFilters: 'requests-filters',
  DeviceFilters: 'devices-filters',
  OrderFilter: 'order-filters',
  LocationFilters: 'locations-filters',
  LocationDetailsGridLayout: 'LocationDetailsGridLayout',
  DevicesDetailsGridSettingLayout: 'DevicesDetailsGridSettingLayout',
};

export const AdminGridName = {
  Customers: 'Customers',
  SearchCustomer: 'SearchCustomer',
  CustomerUsers: 'CustomerUsers',
  Users: 'Users',
  AdminUser: 'AdminUser',
  AccessLogs: 'AccessLogs',
};

export const TenantKey = 'TenantName';
export const ClientGridName = {
  DashboardServiceRequest: 'DashboardServiceRequest',
  DashboardOrders: 'DashboardOrders',
  DashboardLocation: 'DashboardLocation',
  Device: 'Device',
  LocationDevice: 'LocationDevice',
  Location: 'Location',
  ServiceRequest: 'ServiceRequest',
  TaskDetails: 'TaskDetails',
  Devices: 'devices',
  LocationOrders: 'LocationOrders',
  LocationServicesDetails: 'LocationServicesDetails',
  DeviceServicesRequest: 'DeviceServicesRequest',
  DeviceOrder: 'DeviceOrder',
  UserList: 'UserList',
  MeterByReports: 'MeterByReports',
  ActiveOn: 'ActiveOn',
  RetiredDevice: 'RetiredDevice',
  NeverReported: 'NeverReported',
  StoppedReporting: 'StoppedReporting',
  ServiceRequestHistory: 'ServiceRequestHistory',
};
export const DATE_FORMAT = 'YYYY-MM-DD hh:mm A';

export const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const FLOAT_VALUE = '^-?\\d+(\\.\\d+)?$';

export const PAGE_COUNT = '^[0-9]{1,10}$';
export const EMAIL_REGEX =
  "^[a-zA-Z0-9_\\w-'!#$%&*+-/=?^_`{|}~]([a-zA-Z0-9._+\\w-'!#$%&*+-/=?^_`{|}~]|)*@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$";
export const SUPER_ADMIN_USER_PERMISSIONS = [
  'ALL',
  'CAN_VIEW_ACCESS_LOGS',
  'CAN_MANAGE_SUPER_ADMIN_USER',
  'CAN_MANAGE_COMPANY',
];
export const PASSWORD_REGEX =
  '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[ \\\\|\\/!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~]).{6,16}$';

export const PASSWORD_REGEX_ERROR_MESSAGE = 'Password must contain one uppercase, one lowercase,' +
  ' one number and one special character.';
export const PENDING_TITLE = `We are preparing your order`;
export const PENDING_SUBTITLE = `Your order will be shipped soon.`;
export const EXPIRED_TITLE = `Tracking info is not available`;
export const EXPIRED_SUBTITLE = `Please contact our customer service to help resolve this issue.`;
export const NON_EXISTENT_TITLE = `Your delivery status is not available`;
export const NON_EXISTENT_SUB_TITLE = `Please contact our customer service to help resolve this issue.`;
export const EAST_BRANCHES = ['C4-Indianapolis', 'FLO-Middletown', 'FGO-Sandusky', 'LT-Concord', 'MIL-Cincinnati'];

export const SECRET_PASSWORD = '73mUyTb82hrTQRFzhsGg'

export const CART_MAX_LIMIT = 9999;
export const CART_MIN_LIMIT = 0;