import { cloneDeep, isArray, isNumber, isObject } from 'lodash';
import CART_ACTION from './cart.action.types';

const initialState = {
  cartItems: [],
  cartItemCount: 0,
};

export default function cartReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: any = cloneDeep(initialState),
  action: any,
) {
  switch (action.type) {
    case CART_ACTION.ADD_CART_ITEMS: {
      let newCartItems = [];
      if (isArray(action?.payload)) {
        newCartItems = action?.payload;
      }
      return {
        ...state,
        cartItems: newCartItems,
      };
    }
    case CART_ACTION.ADD_CART_ITEM: {
      const cartItemsClone = cloneDeep(state.cartItems);

      if (isObject(action?.payload)) {
        cartItemsClone.push(action.payload);
      }
      return {
        ...state,
        cartItems: cartItemsClone,
      };
    }

    case CART_ACTION.UPDATE_CART_ITEM_COUNT: {
      let cartItemCount = 0;
      if (isNumber(action?.payload)) {
        cartItemCount = action.payload;
      }
      return {
        ...state,
        cartItemCount: cartItemCount,
      };
    }

    default:
      return state;
  }
}
