

import React from 'react';


export const Maintenance = () => {
  return (
    <>
      <h1 className="mb-4 text-2xl font-medium">Maintenance</h1>
    </>
  );
};


