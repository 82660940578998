import React, { Ref, useEffect, useRef, useState } from 'react';
import ApiTable from '../../../../../shared/api-table/api-table';
import { UserListColumns } from './user-list-fields';
import { ClientGridName } from '../../../../../constant/constant';
import { Api } from '../../../../../shared/api/api';
import { SubHeader } from '../../../../admin/layout/footer/sub-header/sub-header';
import { Button } from '../../../../../components/ui';
import { ExcelIcon } from '../../../../../assets/svg/excel-icon';
import { AddUserIcon } from '../../../../../assets/svg/add-user-icon';
import { ResetIcon } from '../../../../../assets/svg/reset-icon';
import { useUser } from '../../../../../context/UserContext';


const UserList = () => {
  const { customerInfo } = useUser();

  const [rowData, setRowData] = useState<Array<any> | null>(null);
  const [isApiCallProgress, setApiCallProgress] = useState(true);
  const ref: Ref<any> = useRef(null);


  const getUserList = async () => {
    try {
      setApiCallProgress(true);
      const response: any = await Api.callAPI({
        url: `/api/v1/users/customer/${customerInfo.CustomerId}?CustomerId=${customerInfo.CustomerId}`,
        method: 'get',
        options: {
          isLoading: false,
          showErrorMessage: false,
        },
      });
      setRowData(response?.data || []);
    } catch (e) { } finally {
      setApiCallProgress(false);
    }
  };

  const resetGrid = () => {
    if (ref.current) {
      ref.current.resetGrid();
    }
  };

  //init row data
  useEffect(() => { getUserList() }, [])


  return (
    <>
      <SubHeader
        leftSideElement={
          <>
            <h1 className='text-2xl font-medium'>Users</h1>
          </>
        }
      />
      {rowData ? (
        <>
          <div className="flex items-center justify-end mb-4">
            <Button
              ghost
              className={`flex items-center borderedBtn sm:ml-4 linkText hover:text-black mr-4`}
              onClick={resetGrid}
            >
              <ResetIcon />
              <div className={`ml-2 text-sm`}>Reset</div>
            </Button>
            <Button
              ghost
              className={`mr-4`}
              onClick={() => ref.current && ref.current.exportCSV()}
            >
              <ExcelIcon />
              <div className="ml-2 text-sm">Export CSV</div>
            </Button>
            <Button
              to={`/customer/user/add`}
              className={`flex items-center borderedBtn linkText  hover:text-black px-4 py-1`}
              ghost>
              <AddUserIcon /> <span className="ml-2 text-sm">Add User</span>
            </Button>
          </div>
          <ApiTable
            ref={ref}
            columns={UserListColumns}
            gridName={ClientGridName.UserList}
            rowData={rowData ? rowData : []}
            isLoading={isApiCallProgress}
          />
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default UserList;
