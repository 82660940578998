import '../style.css';

import React from 'react';

import { ReactSelectProvider } from '../hooks/use-select-context';
import { SelectProps } from '../lib/interfaces';
import Dropdown from './dropdown';
import { generateRandomString } from '../../../../utils/utils';

const ReactSelectMain = (props: SelectProps) => {
  let selectProps: any = props?.id
    ? props
    : {
        ...props,
        id: generateRandomString(20),
      };
  return (
    <ReactSelectProvider props={selectProps}>
      <div className={`rmsc ${props.className || 'multi-select'} ${props.error && ' border-red-400 border rounded'}`}>
        <Dropdown />
      </div>
    </ReactSelectProvider>
  );
};

export default ReactSelectMain;
