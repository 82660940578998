import { RedDeleteIcon } from "../../../../assets/svg/red-delete-icon";
import { Tenant } from "../../../../types/Tenant";
import { useTheme } from "../../../../context/ThemeContext";
import { useMessages } from "../../../../context/MessagesContext";
import { withoutHeaderUrls } from "../../utils/constants";

export const Banner = () => {

    const { hideBanner, banner } = useMessages();
    const { tenant } = useTheme();

    //white background pages
    const isWithoutAuth = withoutHeaderUrls.some((url) => window.location.href.includes(url));
    const background = isWithoutAuth ? 'bg-white text-gray-900' : 'bg-gray-950 text-white';

    return <div className={`w-full ${background} max-h-[15vh] relative px-8 p-1 applyHtml ${tenant !== Tenant.FLOTECH ? '[&_a]:text-flexprint' : '[&_a]:text-flotech'}`}>
        <div className="max-h-[15vh] overflow-auto w-full">
            <div className={``} dangerouslySetInnerHTML={{ __html: banner}}></div>
        </div>
        <div className="absolute top-2 right-3 cursor-pointer font-bold w-5 h-5" onClick={hideBanner}>
            <RedDeleteIcon />
        </div>
    </div>
}
