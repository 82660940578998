import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { useModal } from '../../../../../context/ModalConext';
import { Api } from '../../../../../shared/api/api';
import { ComponentLoading } from '../../../../../shared/component-loader/component-loading';
import {
  Form,
  FormItems,
  FormType,
  SubmitBtn,
} from '../../../../../components/form';
import { useUser } from '../../../../../context/UserContext';

interface LocationUpdateDialogProps {
  refreshGrid: () => void;
}
export const LocationUpdateRemark = forwardRef(
  ({ refreshGrid }: LocationUpdateDialogProps, ref) => {
    const { openModal, closeModal } = useModal();

    const { customerInfo } = useUser();

    const ModalContent = ({ equipmentId }) => {
      const [deviceDetails, setDeviceDetails] = useState(null);
      const [isLoading, setLoading] = useState(true);
      const getEquipmentDetail = async () => {
        try {
          const searchObj = {
            CustomerID: customerInfo?.CustomerId,
            email: customerInfo?.EmailAddress,
            EquipmentID: equipmentId,
            LocationID: '',
          };
          const response = await Api.callAPI({
            url: `/api/v1/equipments/device/${equipmentId}`,
            method: 'put',
            body: searchObj,
            options: {
              isLoading: false,
              showErrorMessage: true,
            },
          });
          setDeviceDetails(response?.data);
        } catch (e) {
          // navigate('/customer/devices');
        }
      };

      useEffect(() => {
        (async () => {
          setLoading(true);
          await getEquipmentDetail();
          setLoading(false);
        })();
      }, []);
      const onSubmitLocationRemark = async (value) => {
        try {
          const reqBody = {
            email: customerInfo.EmailAddress,
            userInfo: {
              CustomerId: customerInfo.CustomerId,
              EmailAddress: customerInfo.EmailAddress,
              FirstName: customerInfo.FirstName,
              LastName: customerInfo.LastName,
              UserPhone: customerInfo.UserPhone,
              BranchID: customerInfo.BranchID,
              BranchNumber: customerInfo.BranchNumber,
            },
            oldDeviceData: deviceDetails,
            newLocationData: {
              ...deviceDetails,
              Location: value.Remark,
            },
            newLocation: value.Remark,
          };

          await Api.callAPI({
            url: `/api/v1/equipments/${equipmentId}/locationRemark`,
            method: 'put',
            body: reqBody,
            options: {
              successMessage: 'Location remarks updated!',
              errorMessage: 'Error while updating equipment location remark.',
            },
          });
          if (refreshGrid) {
            refreshGrid();
          }
          closeModal();
        } catch (e) {}
      };
      return (
        <>
          {isLoading ? (
            <ComponentLoading isShowLoader={true} />
          ) : (
            <div>
              <div>
                <Form onSubmit={onSubmitLocationRemark}>
                  <div className='p-6'>
                  <FormItems
                    items={[
                      {
                        name: 'Remark',
                        label: 'Remarks',
                        defaultValue: deviceDetails.Location,
                        type: FormType.TEXT,
                        maxLength: customerInfo?.LongerLocationRemarks ? 80 : 24,
                        required: true,
                        errors: {
                          required: 'Please provide valid location remarks.',
                        },
                      },
                    ]}
                  />
                  </div>
                  <div className="flex justify-end py-4 px-6 border-t border-gray-200 ">
                    <button
                      className="borderedBtn linkText mr-4"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      Cancel
                    </button>
                    <SubmitBtn className={`primaryBtn bgColor`}>
                      Submit
                    </SubmitBtn>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </>
      );
    };

    useImperativeHandle(ref, () => ({
      openLocationRemarkModal(equipmentId) {
        openModal({
          content: <ModalContent equipmentId={equipmentId} />,
          options: {
            title: 'Edit Location Remarks',
            //   footer: <ModalFooter />,
          },
        });
      },
    }));
    return <></>;
  },
);
