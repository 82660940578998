export const MeterByDeviceColumn = ({ ShowRegionCodeInGrid, monthColumns }) => {
  const filterParams = {
    filterOptions: ['contains'],
    defaultOption: 'contains',
    maxNumConditions: 1,
    debounceMs: 200,
  };
  const regionCode: any = ShowRegionCodeInGrid
    ? [
        {
          headerName: 'Region',
          field: 'Region',
          width: 120,
          filterParams: filterParams,
        },
      ]
    : [];

  const value = [
    ...regionCode,

    {
      headerName: 'EquipmentNumber',
      field: 'EquipmentNumber',
      width: 170,
      filterParams: filterParams,
    },
    {
      headerName: 'SerialNumber',
      field: 'SerialNumber',
      width: 170,
      filterParams: filterParams,
    },
    {
      headerName: 'Location',
      field: 'Location',
      width: 150,
      filterParams: filterParams,
    },
    ...monthColumns,
    {
      headerName: 'Total meter',
      field: 'GrandTotal',
      width: 150,
      filterParams: filterParams,
    },
    {
      headerName: 'Average meter',
      field: 'AvgMeterCount',
      width: 150,
      filterParams: filterParams,
    },
    {
      headerName: 'Peak meter',
      field: 'PeakMeterCount',
      width: 150,
      filterParams: filterParams,
    },
  ];

  return value;
};
