import { useRef, useState } from "react"
import { AddDeviceDemo } from "./AddDevice"
import { Themes, useTheme } from "../../../context/ThemeContext";
import { CancelBtn, Form, FormItem, FormItems, FormRef, FormType, InputMask, SubmitBtn } from "../../../components/form";
import { Button, CheckBox } from "../../../components/ui";
import { Tenant } from "../../../types/Tenant";
import { Card } from "../../../components/ui/Card";
import { ModalDemo } from "./ModalDemo";


export const FormDemo = () => {

    const [ isLoading, setIsLoading ] = useState(false);
    const [isSameAddress, setIsSameAddress] = useState(true);
    const formRef: FormRef = useRef(null);

    const { mockTenant, updateTheme, theme, mainTheme } = useTheme();
    const [ selectBoxOptions, setSelectBoxOptions ] = useState({
        showSearch: false,
        multiSelect: false,
        selectAll: false,
        filterSelectAll: false,
        checkboxes: false,
        chips: false,
    })

    const mockApiCall = async (data: any) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(data);
            }, 1000);
        });
    }

    const handleSubmit = async (formData) => {
        setIsLoading(true);
        await mockApiCall(formData);
        setIsLoading(false);
    }


    return <div className={`p-10 flex items-center justify-center flex-col ${mainTheme.primary} ${mainTheme.text}`}>
        <Card className="w-[50vw] flex">
            <Button className="mr-3 w-full" onClick={() => mockTenant(Tenant.FLOTECH)}>Mock FloTech</Button>
            <Button className="mr-3 w-full" onClick={() => mockTenant(Tenant.MARIMON)}>Mock Marimon</Button>
            <Button className="w-full" onClick={() => updateTheme(theme === Themes.LIGHT ? Themes.DARK : Themes.LIGHT)}>{
                theme === Themes.LIGHT ? 'Dark Theme' : 'Light Theme'
            }</Button>
        </Card>
        <Card className="w-[50vw] mt-4">
            <div className="text-2xl mb-2">Change Password Example</div>
            <div className="">
                <Form
                    onSubmit={handleSubmit}
                    isSubmitting={isLoading}>
                    <div className="p-3">
                        <FormItems items={[
                                {
                                    name: "password",
                                    label: "Password",
                                    type: FormType.PASSWORD,
                                    placeholder: "New Password",
                                    required: true,
                                },
                                {
                                    name: "confirmPassword",
                                    label: "Confirm Password",
                                    type: FormType.PASSWORD,
                                    placeholder: "Confirm Password",
                                    matchInput: 'password',
                                    errors: {
                                        matchInput: 'Passwords do not match',
                                    },
                                    required: true,
                                },
                            ]} />
                            <div className="flex">
                                <CancelBtn className="w-full mr-2" color={mainTheme.secondary}>Cancel</CancelBtn>
                                <SubmitBtn className="w-full">Change Password</SubmitBtn>
                            </div>
                        </div>
                </Form>
            </div>
        </Card>

        <Card className="w-[50vw] mt-10">
            <div className="text-2xl mb-2">Mutiple FormItem Example</div>
            <div className="">
                <Form
                    defaultValues={{
                        firstName: 'test',
                        lastName: 'test1',
                    }}
                    onSubmit={handleSubmit}
                    isSubmitting={isLoading}>
                    <div className="p-3">
                        <div className="flex">
                            <div className="mr-3 w-full -r  pr-3">
                                <FormItems items={[
                                    {
                                        name: "firstName",
                                        label: "First Name",
                                        type: FormType.TEXT,
                                        placeholder: "First Name",
                                        defaultValue: 'Test',
                                        required: true,
                                    },
                                    {
                                        name: "lastName",
                                        label: "Last Name",
                                        type: FormType.TEXT,
                                        placeholder: "Last Name",
                                        required: true,
                                    },
                                    {
                                        name: "phoneNumber",
                                        label: "Phone Number",
                                        type: FormType.TEXT,
                                        placeholder: "Phone Number",
                                        required: true,
                                        mask: InputMask.PHONE,
                                    },
                                ]} />
                            </div>
                            <div className="w-full">
                                <FormItems items={[
                                    {
                                        name: 'SSN',
                                        label: 'SSN',
                                        type: FormType.TEXT,
                                        placeholder: 'SSN',
                                        mask: InputMask.SSN,
                                        required: true,
                                        onChange: () => {},
                                    },
                                    {
                                        name: 'DOB',
                                        label: 'DOB',
                                        type: FormType.DATE,
                                        placeholder: 'DOB',
                                        required: true,
                                        minDate: '01/01/1900',
                                        maxDate: '01/01/2006',
                                    },
                                ]} />
                            </div>
                        </div>
                        <FormItem
                            formDef={{
                                name: 'acknowledgement',
                                label: 'Acknowledgement',
                                type: FormType.CHECKBOX,
                                required: true,
                                defaultValue: true,
                                helperText: 'I have read and agree to the Terms and Conditions',
                            }}
                        />
                        <div className="flex mt-2">
                            <SubmitBtn className="w-full">Submit Form</SubmitBtn>
                        </div>
                        </div>
                </Form>
            </div>
        </Card>

        <Card className="w-[50vw] mt-10">
            <div className="text-2xl mb-2">Selection Example</div>
            <div className="">
                <div className="p-3">
                    <div className="text-sm italic text-stone-600 mb-3">
                        Choose Options for the Select below, they will enable/disable the options below
                    </div>
                    <CheckBox
                        name={'Search'}
                        label={'Search'}
                        onChange={() => setSelectBoxOptions({...selectBoxOptions, showSearch: !selectBoxOptions.showSearch})}
                        checked={selectBoxOptions.showSearch}
                        />
                    <CheckBox
                        name={'Select All'}
                        label={'Select All'}
                        onChange={() => setSelectBoxOptions({...selectBoxOptions, selectAll: !selectBoxOptions.selectAll})}
                        checked={selectBoxOptions.selectAll}
                        />
                    <CheckBox
                        name={'Filter Select All'}
                        label={'Filter Select All'}
                        onChange={() => setSelectBoxOptions({...selectBoxOptions, filterSelectAll: !selectBoxOptions.filterSelectAll})}
                        checked={selectBoxOptions.filterSelectAll}
                        />
                    <CheckBox
                        name={'Multi Select'}
                        label={'Multi Select'}
                        onChange={() => setSelectBoxOptions({...selectBoxOptions, multiSelect: !selectBoxOptions.multiSelect})}
                        checked={selectBoxOptions.multiSelect}
                        />
                    <CheckBox
                        name={'Checkboxes'}
                        label={'Checkboxes'}
                        onChange={() => setSelectBoxOptions({...selectBoxOptions, checkboxes: !selectBoxOptions.checkboxes})}
                        checked={selectBoxOptions.checkboxes}
                        />
                    <CheckBox
                        name={'Chips'}
                        label={'Chips'}
                        onChange={() => setSelectBoxOptions({...selectBoxOptions, chips: !selectBoxOptions.chips})}
                        checked={selectBoxOptions.chips}
                        />
                    <Form onSubmit={handleSubmit} isSubmitting={isLoading}>
                            <FormItem
                                formDef={{
                                    name: 'location',
                                    placeholder: 'Select a Location',
                                    label: 'Location',
                                    type: FormType.SELECT,
                                    options: [
                                        {
                                            label: 'Location 1',
                                            value: 'location1',
                                        },
                                        {
                                            label: 'Location 2',
                                            value: 'location2',

                                        },
                                    ],
                                    required: true,
                                    selectMultiSelect: selectBoxOptions.multiSelect,
                                    selectShowCheckboxes: selectBoxOptions.checkboxes,
                                    selectShowChip: selectBoxOptions.chips,
                                    selectHideSearch: selectBoxOptions.showSearch,
                                    selectShowSelectAll: selectBoxOptions.selectAll,
                                    selectShowSelectAllFilter: selectBoxOptions.filterSelectAll,
                                }}
                            />
                            <div className="flex mt-2">
                                <SubmitBtn className="w-full">Submit Form</SubmitBtn>
                            </div>
                    </Form>
                </div>
            </div>
        </Card>
        <AddDeviceDemo />

        <Card className="w-[50vw] mt-4">
            <div className="text-2xl mb-2">Conditional Selection Example</div>
            <div className="">
                <Form
                    formRef={formRef}
                    onSubmit={handleSubmit}
                    isSubmitting={isLoading}>
                    <div className="p-3">
                            <FormItems
                                items={[
                                    {
                                        name: 'addressIsSame',
                                        label: 'Address is the same',
                                        type: FormType.CHECKBOX,
                                        defaultValue: true,
                                        helperText: 'Shipping address is the same as billing address',
                                        onChange: (e: boolean) => {
                                            setIsSameAddress(e);
                                            formRef.clearInputs(['address']);
                                        },
                                    },
                                    {
                                        name: 'address',
                                        placeholder: 'Select an Address',
                                        label: 'Shipping Address',
                                        type: FormType.SELECT,
                                        options: [
                                            {
                                                label: 'Location 1',
                                                value: 'location1',
                                            },
                                            {
                                                label: 'Location 2',
                                                value: 'location2',

                                            },
                                        ],
                                        required: !isSameAddress,
                                        disabled: isSameAddress,
                                        isMenuTop: true,
                                        selectMultiSelect: true,
                                    }
                                ]}
                            />

                            <FormItems
                                items={[
                                    {
                                        name: 'saveAddress',
                                        label: 'Save For Future',
                                        type: FormType.CHECKBOX,
                                        defaultValue: true,
                                        helperText: 'Save this address for future use',
                                    },
                                ]}
                            />
                            <div className="flex">
                                <CancelBtn className="w-full mr-2" color={mainTheme.secondary}>Cancel</CancelBtn>
                                <SubmitBtn className="w-full">Submit</SubmitBtn>
                            </div>
                        </div>
                </Form>
            </div>
        </Card>
        <ModalDemo />
    </div>
}
