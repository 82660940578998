import { useEffect } from "react";

export const Crm = () => {

    const hostName = window.location.hostname;
    let crmUrl = '';

    switch (true) {
        case hostName.includes('localhost'):
            crmUrl = 'http://crmdev.flextg.com';
            break;
        case hostName.includes('dev'):
            crmUrl = 'http://crmdev.flextg.com';
            break;
        case hostName.includes('test'):
            crmUrl = 'http://crmtest.flextg.com';
            break;
        case hostName.includes('my.') || hostName.includes('portal.'):
            crmUrl = 'https://crmdash.flextg.com';
            break;
        default:
            crmUrl = 'http://crmdev.flextg.com';
            break;
      }

      useEffect(() => {
        window.location.href = crmUrl;
      }, []);

    return <></>;
}