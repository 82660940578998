import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { Api } from '../../../../shared/api/api';
import {
  colorCoder,
  getTime,
  isCheckDollerProcingNonZero,
} from '../../../../utils/utils';
import { TENANT } from "../../../../constant/constant";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { Chip } from '../../../../components/ui/Chip';
import { useUser } from '../../../../context/UserContext';

const ThankYouPage = () => {
  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );
  const { customerInfo } = useUser();
  let { orderId } = useParams();
  const [OrderData, setOrderData] = useState([]);
  const getOrderItems = async () => {
    const bodyObj = {
      LocationID:
        customerInfo?.RestrictUserByLocationID &&
        customerInfo?.RestrictUserByLocationID.length
          ? customerInfo?.RestrictUserByLocationID.toString()
          : null,
      EmailAddress: customerInfo?.EmailAddress,
      CustomerId: customerInfo?.CustomerId,
      UserId: customerInfo?.UserId,
    };

    try {
      const response = await Api.callAPI({
        url: `/api/v1/orders/${orderId}/items`,
        method: 'PUT',

        body: bodyObj,
        options: {
          isLoading: true,
          isShowApiErrorMessage: false,
        },
      });
      const orderItemsList = response?.data;
      orderItemsList.map((Order: any) => {
        Order.CreatedDate = getTime(Order.CreatedDate);
        const orderItems = Order.OrderItems;
        orderItems.map((item: any) => {
          const price = parseFloat(item.Price);
          if(tenantName === TENANT.MARIMON) {
            item.tonerClass = item && item.ItemDescription && colorCoder(item.ItemDescription) || null;
          } else {
            item.tonerClass = item && item.CatDescription && colorCoder(item.CatDescription) || null;
          }
          item.totalPriceOfOneProduct = price * item.Quantity;
        });
      });
      setOrderData(orderItemsList);

      return response;
    } catch (e) {}
  };

  useEffect(() => {
    getOrderItems().then();
  }, []);
  const IsPriceHeaderShow = (Order: any) => {
    if(customerInfo?.HideZeroDollarPricing) {
      let flag = false;
      Order?.OrderItems?.map((item: any) => {
        if(parseInt(item?.Price) > 0) {
          flag = true;
        }
      });
      return flag;
    } else {
      return true
    }
  }
  const IsTotalHeaderShow = (Order: any) => {
    if(customerInfo?.HideZeroDollarPricing) {
      let flag = false;
      Order?.OrderItems?.map((item: any) => {
        if(parseInt(item?.totalPriceOfOneProduct) > 0) {
          flag = true;
        }
      });
      return flag;
    } else {
      return true
    }
  }
  return (
    <>
      <div>
        <div className={'thanks-page-inner'}>
          {OrderData.map((Order: any, index: number) => {
            return (
              <div key={'index' + index}>
                {index === 0 ? (
                  <div className=" border-b border-gray-200 pb-4 mb-4"><h1 className="text-2xl font-medium">Order Confirmation</h1></div>
                ) : (
                  ''
                )}

                <div className="mb-8">
                  <div className="orderPlacedBar rounded-md bg-gray-100 py-2 px-4 mb-4">
                    <div className="sm:flex justify-between">
                      <div className="flex items-center">
                        <div>
                          <div className="text-xl font-medium">
                            Order {Order.SOCode}
                          </div>
                        </div>
                        <div className={`cursor-default ml-6`}>
                           <Chip status={Order.Status} />
                        </div>
                      </div>
                      <div className="sm:text-right sm:mt-0 mt-4">
                        <div className=" font-medium">Order Placed</div>
                        <div className="text-xl text-gray-900 font-medium">
                          {Order.CreatedDate}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="productBar">
                    <div className="addressBox sm:flex px-4">
                      <div className="shippingDetail">
                        <h5 className="font-medium">Shipping Details</h5>
                        <div className="mt-4">
                          {Order.ShipToATTN}
                          <br />
                          {Order.ShipToStreet}
                          <br />
                          {Order.ShipToCity}, {Order.ShipToState},{' '}
                          {Order.ShipToZip}
                          <br />
                          {Order.ShipToCountry}
                        </div>
                      </div>
                      <div className="copanyInformation sm:ml-16 sm:mt-0 mt-4">
                        <h5 className="font-medium">Company Information</h5>
                        <div className="mt-4">
                          {customerInfo.CustomerName}
                          <br />
                          {customerInfo.CustomerAddress}
                          <br />
                          {customerInfo.CustomerCity},{' '}
                          {customerInfo.CustomerState},{' '}
                          {customerInfo.CustomerZip}
                          <br />
                          {customerInfo.CustomerCountry}
                        </div>
                      </div>
                    </div>
                    <div className='border border-gray-200  mt-6'>
                      <div className="productListing">
                        <div className="md:flex hidden border-b border-gray-200 bg-gray-100 py-3 px-4">
                          <div className="xl:w-9/12 md:w-2/5 w-full">Product</div>
                          <div className="xl:w-1/12 md:w-1/5 w-1/3 text-center">
                            {IsPriceHeaderShow(Order) ? (
                              <>Price</>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="xl:w-1/12 md:w-1/5 w-1/3 text-center">
                            Quantity
                          </div>
                          <div className="xl:w-1/12 md:w-1/5  w-1/3 text-right">
                            {IsTotalHeaderShow(Order) ? (
                              <>Total</>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>

                          {Order.OrderItems.map((cartItem: any, OrderItemsIndex: number) => {
                            return (
                              <div className="border-b border-gray-200 py-4 px-4">
                                <div
                                  key={'OrderItemsIndex' + OrderItemsIndex}
                                  className="flex flex-wrap"
                                >
                                  <div className="xl:w-9/12 md:w-2/5 w-full  md:mb-0 mb-4">
                                    <div className="md:hidden block text-gray-500">
                                      Product
                                    </div>
                                    <div className={'flex items-center'}>
                                      <div className="w-4 mr-2 ">
                                        {tenantName === TENANT.MARIMON &&
                                        cartItem &&
                                        cartItem.ItemDescription &&
                                        colorCoder(cartItem?.ItemDescription) ? (
                                          <div
                                            className="w-4 h-4 rounded-full ItemDescription mt-1"
                                            style={{
                                              backgroundColor: colorCoder(
                                                cartItem?.ItemDescription,
                                              ),
                                            }}
                                          ></div>
                                        ) : (
                                          ''
                                        )}
                                        {tenantName !== TENANT.MARIMON &&
                                        cartItem &&
                                        cartItem.CatDescription &&
                                        colorCoder(cartItem.CatDescription) ? (
                                          <div
                                            className="w-4 h-4 rounded-full ItemDescription mt-1"
                                            style={{
                                              backgroundColor: colorCoder(
                                                cartItem?.CatDescription,
                                              ),
                                            }}
                                          ></div>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                      <h4 className="font-medium break-all pr-6">
                                        {cartItem.ItemDescription}
                                      </h4>
                                    </div>

                                    <div className="ml-6 mt-2 sm:flex text-gray-500">
                                      <div>{cartItem.Item}</div>
                                      {cartItem?.EquipmentNumber ? (
                                        <div className="mr-8">
                                          EQ# {cartItem.EquipmentNumber}{' '}
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                  <div className="xl:w-1/12 md:w-1/5  w-1/3 md:text-center">
                                    {isCheckDollerProcingNonZero(
                                      cartItem.Price.toFixed(2),
                                    ) ? (
                                      <>
                                        <div className="md:hidden block text-gray-500">
                                          Price
                                        </div>
                                        <span>
                                          ${cartItem.Price.toFixed(2)}
                                        </span>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                  <div className="xl:w-1/12 md:w-1/5 w-1/3 text-center">
                                    <div className="md:hidden block text-gray-500">
                                      Quantity
                                    </div>
                                    {cartItem.Quantity}
                                  </div>
                                  <div className="xl:w-1/12 md:w-1/5 w-1/3 text-right">
                                    {isCheckDollerProcingNonZero(
                                      cartItem.totalPriceOfOneProduct.toFixed(
                                        2,
                                      ),
                                    ) ? (
                                      <>
                                        <div className="md:hidden block text-gray-500">
                                          Total
                                        </div>
                                        <span className="font-medium">
                                          $
                                          {cartItem.totalPriceOfOneProduct.toFixed(
                                            2,
                                          )}
                                        </span>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                      </div>
                      {Order.OrderCost >= 0 ? (
                        <>
                          {Order.Freight ? (
                            <div className="flex text-right sub-total border-b border-gray-200 pb-4 my-4 px-4">
                              <div className="md:w-10/12 w-3/5">
                                <span className="font-medium">
                                  Shipping & Handling :
                                </span>
                              </div>
                              <div className="md:w-2/12 w-2/5">
                                <span className="pl-8 font-medium">
                                  ${Order.Freight.toFixed(2)}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                          {Order.Tax ? (
                            <div className="flex text-right tex-total border-b border-gray-200 pb-4 mb-4 px-4">
                              <div className="md:w-10/12 w-3/5">
                                <span className="font-medium">Tax : </span>{' '}
                              </div>
                              <div className="md:w-2/12 w-2/5">
                                <span className="pl-8 font-medium">
                                  ${Order.Tax.toFixed(2)}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                          {isCheckDollerProcingNonZero(
                            Order.OrderCost.toFixed(2),
                          ) ? (
                            <div className="flex text-right order-total pb-4 px-4">
                              <div className="md:w-10/12 w-3/5">
                                <span className="font-bold">Order Total :</span>
                              </div>
                              <div className="md:w-2/12 w-2/5">
                                <span className="pl-8 font-bold">
                                  ${Order.OrderCost.toFixed(2)}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-end">
          <Link
            to={'/product/search'}
            className="primaryBtn bgColor hover"
          >
            Continue Shopping
          </Link>
        </div>
      </div>
    </>
  );
};

export default ThankYouPage;
