const NotFoundIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="79"
      height="100"
      viewBox="0 0 79 100"
      fill="none"
    >
      <rect
        x="15.7891"
        width="63.1579"
        height="84.2105"
        rx="6"
        fill="#E5E5E5"
      />
      <rect
        opacity="0.2"
        x="23.6846"
        y="10.5264"
        width="20"
        height="2"
        rx="1"
        fill="black"
      />
      <rect
        opacity="0.2"
        x="23.6846"
        y="17.5264"
        width="36"
        height="2"
        rx="1"
        fill="black"
      />
      <rect
        opacity="0.2"
        x="23.6846"
        y="24.5264"
        width="36"
        height="2"
        rx="1"
        fill="black"
      />
      <rect
        opacity="0.2"
        x="23.6846"
        y="31.5264"
        width="36"
        height="2"
        rx="1"
        fill="black"
      />
      <rect
        opacity="0.2"
        x="23.6846"
        y="38.5264"
        width="36"
        height="2"
        rx="1"
        fill="black"
      />
      <rect
        opacity="0.2"
        x="23.6846"
        y="45.5264"
        width="36"
        height="2"
        rx="1"
        fill="black"
      />
      <rect
        opacity="0.2"
        x="23.6846"
        y="52.5264"
        width="36"
        height="2"
        rx="1"
        fill="black"
      />
      <rect
        x="0.5"
        y="16.2891"
        width="62.1579"
        height="83.2105"
        rx="5.5"
        fill="white"
        stroke="#808080"
      />
      <rect
        x="7.89453"
        y="26.3154"
        width="26.3158"
        height="2.63158"
        rx="1.31579"
        fill="#E5E5E5"
      />
      <rect
        x="7.89453"
        y="35.5264"
        width="47.3684"
        height="2.63158"
        rx="1.31579"
        fill="#E5E5E5"
      />
      <rect
        x="7.89453"
        y="44.7373"
        width="47.3684"
        height="2.63158"
        rx="1.31579"
        fill="#E5E5E5"
      />
      <rect
        x="7.89453"
        y="53.9473"
        width="47.3684"
        height="2.63158"
        rx="1.31579"
        fill="#E5E5E5"
      />
      <path
        d="M30.1168 86.4035C36.5763 86.4035 41.8127 81.1671 41.8127 74.7076C41.8127 68.2482 36.5763 63.0117 30.1168 63.0117C23.6573 63.0117 18.4209 68.2482 18.4209 74.7076C18.4209 81.1671 23.6573 86.4035 30.1168 86.4035Z"
        stroke="#0F0F0F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.7362 90.7899L38.1572 84.2109"
        stroke="#0F0F0F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.5 73C28.5 72.1727 29.1727 71.5 30 71.5H30.75C31.5773 71.5 32.25 72.1727 32.25 73V73.0844C32.25 73.5953 31.9898 74.0711 31.5609 74.3453L30.5719 74.9805C30.2815 75.1672 30.0426 75.4238 29.8772 75.7269C29.7117 76.0299 29.625 76.3696 29.625 76.7148V76.75C29.625 77.1648 29.9602 77.5 30.375 77.5C30.7898 77.5 31.125 77.1648 31.125 76.75V76.7172C31.125 76.525 31.2234 76.3469 31.3828 76.2437L32.3719 75.6086C33.2297 75.0555 33.75 74.1062 33.75 73.0844V73C33.75 71.343 32.407 70 30.75 70H30C28.343 70 27 71.343 27 73C27 73.4148 27.3352 73.75 27.75 73.75C28.1648 73.75 28.5 73.4148 28.5 73ZM30.375 80.5C30.6236 80.5 30.8621 80.4012 31.0379 80.2254C31.2137 80.0496 31.3125 79.8111 31.3125 79.5625C31.3125 79.3139 31.2137 79.0754 31.0379 78.8996C30.8621 78.7238 30.6236 78.625 30.375 78.625C30.1264 78.625 29.8879 78.7238 29.7121 78.8996C29.5363 79.0754 29.4375 79.3139 29.4375 79.5625C29.4375 79.8111 29.5363 80.0496 29.7121 80.2254C29.8879 80.4012 30.1264 80.5 30.375 80.5Z"
        fill="#666666"
      />
      <path
        d="M28.5 73C28.5 72.1727 29.1727 71.5 30 71.5H30.75C31.5773 71.5 32.25 72.1727 32.25 73V73.0844C32.25 73.5953 31.9898 74.0711 31.5609 74.3453L30.5719 74.9805C30.2815 75.1672 30.0426 75.4238 29.8772 75.7269C29.7117 76.0299 29.625 76.3696 29.625 76.7148V76.75C29.625 77.1648 29.9602 77.5 30.375 77.5C30.7898 77.5 31.125 77.1648 31.125 76.75V76.7172C31.125 76.525 31.2234 76.3469 31.3828 76.2437L32.3719 75.6086C33.2297 75.0555 33.75 74.1062 33.75 73.0844V73C33.75 71.343 32.407 70 30.75 70H30C28.343 70 27 71.343 27 73C27 73.4148 27.3352 73.75 27.75 73.75C28.1648 73.75 28.5 73.4148 28.5 73ZM30.375 80.5C30.6236 80.5 30.8621 80.4012 31.0379 80.2254C31.2137 80.0496 31.3125 79.8111 31.3125 79.5625C31.3125 79.3139 31.2137 79.0754 31.0379 78.8996C30.8621 78.7238 30.6236 78.625 30.375 78.625C30.1264 78.625 29.8879 78.7238 29.7121 78.8996C29.5363 79.0754 29.4375 79.3139 29.4375 79.5625C29.4375 79.8111 29.5363 80.0496 29.7121 80.2254C29.8879 80.4012 30.1264 80.5 30.375 80.5Z"
        stroke="#666666"
      />
      <ellipse cx="5.63184" cy="5.63086" rx="3" ry="3" fill="#C4393F" />
      <rect
        x="50"
        y="7.89453"
        width="5.26316"
        height="13.1579"
        rx="2.63158"
        fill="#C4393F"
      />
      <rect
        x="39.4736"
        y="10.5264"
        width="5.26316"
        height="13.1579"
        rx="2.63158"
        fill="#C4393F"
      />
    </svg>
  );
};
export default NotFoundIcon;
