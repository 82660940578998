import { useModal } from '../../../../../../context/ModalConext';
import {
  Form,
  FormItems,
  FormType,
  SubmitBtn,
} from '../../../../../../components/form';
import { Api } from '../../../../../../shared/api/api';
import { useUser } from '../../../../../../context/UserContext';

export const RemovePopModal = ({ locationData }: any) => {
  const { openModal, closeModal } = useModal();
  const { customerInfo } = useUser();
  const onSubmitRemoveHandler = async (comment: any) => {
    const locationDetailObj = {
      oldLocationData: { LocationID: '' },
      userInfo: {},
      Comments: '',
    };
    locationDetailObj.oldLocationData = locationData;
    locationDetailObj.userInfo = {
      CustomerId: customerInfo.CustomerId,
      EmailAddress: customerInfo.EmailAddress,
      FirstName: customerInfo.FirstName,
      LastName: customerInfo.LastName,
      UserPhone: customerInfo.UserPhone,
      BranchID: customerInfo.BranchID,
      BranchNumber: customerInfo.BranchNumber,
    };

    locationDetailObj.Comments = comment?.Comments;

    await Api.callAPI({
      url: `/api/v1/location/${locationDetailObj?.oldLocationData?.LocationID}/deleteLocation`,
      method: 'post',
      body: locationDetailObj,
      options: {
        successMessage:
          'Your change request has been submitted to our team for review.',
        errorMessage: 'Error while delete location.',
      },
    });
    closeModal();
  };

  const ModalContent = () => {
    return (
      <div className="flex flex-col gap-2">
        <div>
          <Form onSubmit={onSubmitRemoveHandler}>
            <FormItems
              className="p-6"
              items={[
                {
                  name: 'Comments',
                  label: 'Comments',
                  rows: 5,
                  type: FormType.TEXTAREA,
                  className:"text-sm",
                  placeholder: 'Write your message here..',
                  required: true,
                  errors: {
                    required: 'Please enter note.',
                  },
                  maxLength: 200
                },
              ]}
            />
            <div className="flex justify-end py-4 px-6 border-t border-gray-200">
              <button onClick={() => closeModal()} className={`borderedBtn mr-2 linkText hover:text-black`}>
                Cancel
              </button>
              <SubmitBtn className={`commonBtn`}>Submit</SubmitBtn>
            </div>
          </Form>
        </div>
      </div>
    );
  };

  //   const ModalFooter = () => {
  //     return (
  //       <div className="flex gap-2">
  //         <Button onClick={closeModal}>Cancel</Button>
  //         <Button>Save</Button>
  //       </div>
  //     );
  //   };

  return (
    <button
      className="borderedBtn linkText mr-4 hover:text-black"
      onClick={() =>
        openModal({
          content: <ModalContent />,
          options: {
            title: 'Remove this Location',
            //   footer: <ModalFooter />,
          },
        })
      }
    >
      Remove
    </button>
  );
};
