//@ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import {
  Form,
  FormItem,
  FormItems,
  FormRef,
  FormType,
  SubmitBtn,
} from '../../../components/form';
import { Api } from '../../../shared/api/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import _ from 'lodash';
import { hideLoader } from '../../../store/loading/loading.action';
import { Tenant } from '../../../types/Tenant';
import storage from '../../../utils/storage/storage';
import { AdminStorageKey } from '../../../constant/constant';

export const LinkCustomer = () => {
  let customerObj = {
    _id: '',
    SearchType: '',
    HideZeroDollarPricing: '',
    HideEquipmentOrders: '',
    ExemptFromFreightOnTransactionalOrders: '',
    ShowContractExpirationDate: '',
    AllowRestrictRegion: '',
    AllowReportView: '',
    ShowRegionCodeInGrid: '',
    CanViewGLCode: '',
    CanAutoFillAttentionField: '',
    CanPrependPONumberAttentionField: '',
    AllowServiceRequestNumber: '',
    WarehouseName: '',
    Prefix: '',
    WarehouseID: Number,
    TriageW2Calls: '',
    TriageOOACalls: '',
    AllowHidingUsersWebOrders: '',
    Note: '',
    WarnCustomerOfPotentialDuplicateOrders: '',
    ServiceRequestContactDefaultPhone: '',
    ServiceRequestContactDefaultEmail: '',
    ServiceRequestContactDefaultName: '',
    WarnCustomerOfPotentialDuplicateOrders: Boolean,
    Id: '',
    PrimarySymptom: [],
    QuotesID: [],
    SendEmailsWhenFCPOrdersAreCanceled: false,
  };
  const [init, setInit] = useState(false);
  const dispatch = useDispatch();
  const { id, addCustomerId } = useParams();
  const isEdit = !!id;
  const navigate = useNavigate();
  const [defaultWareHouse, setDefaultWareHouse] = useState([]);
  const [isQuotes, setIsQuotes] = useState(false);
  const [QuotesData, setQuotesData] = useState(null);
  const [editCustomerName, setEditCustomerName] = useState(null);
  const [defaultValues, setDefaultValue] = useState({});
  const customer: any = storage.getJSONItem(AdminStorageKey.userInfo);
  const ref: FormRef = useRef(null);

  const searchTypeOptions = [
    { label: 'Transactional', value: 'TR' },
    { label: 'Covered On Contract', value: 'COC' },
    {
      label: 'Combined (Contract & Transactional)',
      value: 'COC-TR',
    },
  ];
  const [itemAvailability, setItemAvailability] = useState({
    label: 'Covered On Contract',
    value: 'COC',
  });

  const [requiredFieldsDefaultSettings, setRequiredFieldsDefaultSettings] =
    useState({
      cc: {
        checked: false,
        disable: false,
      },
      department: {
        checked: false,
        disable: false,
      },
      floor: {
        checked: false,
        disable: false,
      },
      orderPhone: {
        checked: false,
        disable: false,
      },
      poNumber: {
        checked: false,
        disable: false,
      },
    });

  const checkoutRequireFields = [
    {
      label: 'PONumber',
      name: 'poNumber',
      type: FormType.CHECKBOX,
      onChange: (value: any) => {
        requiredFieldsDefaultSettings.poNumber.checked = false;
        requiredFieldsDefaultSettings.poNumber.disable = value;
        setRequiredFieldsDefaultSettings(requiredFieldsDefaultSettings);
      },
    },
    {
      label: 'Shipping Address',
      name: 'address',
      type: FormType.CHECKBOX,
      value: true,
      disabled: true,
    },
    {
      label: 'Attention',
      value: true,
      type: FormType.CHECKBOX,
      name: 'attention',
      disabled: true,
    },
    {
      label: 'Phone',
      type: FormType.CHECKBOX,
      name: 'orderPhone',
      onChange: (value: any) => {
        requiredFieldsDefaultSettings.orderPhone.checked = false;
        requiredFieldsDefaultSettings.orderPhone.disable = value;
        setRequiredFieldsDefaultSettings(requiredFieldsDefaultSettings);
      },
    },
    {
      label: 'Email',
      type: FormType.CHECKBOX,
      name: 'orderEmail',
      value: true,
      disabled: true,
    },
    {
      label: 'CC email',
      type: FormType.CHECKBOX,
      name: 'cc',
      onChange: (value: any) => {
        requiredFieldsDefaultSettings.cc.checked = false;
        requiredFieldsDefaultSettings.cc.disable = value;
        setRequiredFieldsDefaultSettings(requiredFieldsDefaultSettings);
      },
    },
    {
      label: 'Department',
      type: FormType.CHECKBOX,
      name: 'department',
      onChange: (value: any) => {
        requiredFieldsDefaultSettings.department.checked = false;
        requiredFieldsDefaultSettings.department.disable = value;
        setRequiredFieldsDefaultSettings(requiredFieldsDefaultSettings);
      },
    },
    {
      label: 'Floor',
      type: FormType.CHECKBOX,
      name: 'floor',
      onChange: (value: any) => {
        requiredFieldsDefaultSettings.floor.checked = false;
        requiredFieldsDefaultSettings.floor.disable = value;
        setRequiredFieldsDefaultSettings(requiredFieldsDefaultSettings);
      },
    },
  ];
  const checkoutHideFields = [
    {
      name: 'PONumberHide',
      label: 'PONumber',
      type: FormType.CHECKBOX,
      disabled: requiredFieldsDefaultSettings?.poNumber?.disable,
      value: requiredFieldsDefaultSettings?.poNumber?.checked,
    },
    {
      name: 'PhoneHide',
      label: 'Phone',
      type: FormType.CHECKBOX,
      disabled: requiredFieldsDefaultSettings?.orderPhone?.disable,
      value: requiredFieldsDefaultSettings?.orderPhone?.checked,
    },
    {
      name: 'CCemailHide',
      label: 'CC email',
      type: FormType.CHECKBOX,
      disabled: requiredFieldsDefaultSettings?.cc?.disable,
      value: requiredFieldsDefaultSettings?.cc?.checked,
    },
    {
      name: 'DepartmentHide',
      label: 'Department',
      type: FormType.CHECKBOX,
      disabled: requiredFieldsDefaultSettings?.department?.disable,
      value: requiredFieldsDefaultSettings?.department?.checked,
    },
    {
      name: 'FloorHide',
      label: 'Floor',
      type: FormType.CHECKBOX,
      disabled: requiredFieldsDefaultSettings?.floor?.disable,
      value: requiredFieldsDefaultSettings?.floor?.checked,
    },
  ];
  const serviceRequestRequireFields = [
    {
      type: FormType.CHECKBOX,
      label: 'Primary Symptom',
      name: 'primarySymptom',
      value: true,
      disabled: true,
    },
    {
      type: FormType.CHECKBOX,
      label: 'Description',
      name: 'description',
      value: true,
      disabled: true,
    },
    {
      type: FormType.CHECKBOX,
      label: 'Comments/Special Requirements',
      name: 'comments',
    },
    {
      type: FormType.CHECKBOX,
      label: 'Name',
      name: 'name',
      value: true,
      disabled: true,
    },
    {
      type: FormType.CHECKBOX,
      label: 'Phone',
      name: 'phone',
      value: true,
      disabled: true,
    },
    {
      type: FormType.CHECKBOX,
      label: 'Email',
      name: 'email',
      value: true,
      disabled: true,
    },
  ];
  const defaultEmailOptions = [
    { label: 'Logged in User', value: 'loggedInUser' },
    {
      label: 'Location Contact',
      value: 'locationContact',
    },
  ];
  const defaultNameOptions = [
    {
      label: 'Logged in User',
      value: 'loggedInUser',
    },
    {
      label: 'Location Contact',
      value: 'locationContact',
    },
  ];
  const defaultPhoneOptions = [
    { label: 'Logged in User', value: 'loggedInUser' },
    {
      label: 'Location Contact',
      value: 'locationContact',
    },
  ];

  const [problemsData, setProblemData] = useState(null);

  const [isServicesRequestAllow, setIsServicesRequestAllow] = useState(false);
  const [isShowExtendNotes, setIsShowExtendNotes] = useState(null);

  const tenantName = useSelector(
    (states: RootState) => states.tenantState.tenantName,
  );
  const getDefaultWareHouse = (response: any) => {
    let wareHouseObj =
      tenantName === Tenant.FLOTECH
        ? {
            WarehouseID: 803,
            Warehouse: 'FPI Drop Ship',
            DESCRIPTION: 'FPI Drop Ship',
          }
        : tenantName === Tenant.FLEXPRINT || tenantName === Tenant.SANDBOX
        ? {
            WarehouseID: 803,
            Warehouse: 'FPI Drop Ship',
            DESCRIPTION: 'FPI Drop Ship',
          }
        : {
            WarehouseID: 131,
            Warehouse: 'Houston',
            DESCRIPTION: 'Houston Main',
          };

    const dataForWareHouse = response?.data
      ?.map((wareHouse: any) => {
        if (wareHouse.WarehouseID === wareHouseObj.WarehouseID) {
          return {
            label: wareHouse?.Warehouse,
            value: wareHouse?.WarehouseID,
          };
        }
      })
      .filter(Boolean);
    setDefaultWareHouse(dataForWareHouse);

    return dataForWareHouse;
  };
  const getWareHouseData = async () => {
    try {
      const response: any = await Api.callAPI({
        url: `/api/v1/warehouses`,
        method: 'get',
        options: {
          isLoading: true,
          successMessage: false,
          errorMessage: 'Error while getting warehouse.',
        },
      });
      let warehouseData = [];
      _.each(response.data, (warehouse) => {
        if (
          tenantName === 'flotech' &&
          (warehouse.WarehouseID === 1312 || warehouse.WarehouseID === 803)
        ) {
          warehouseData.push(warehouse);
        } else if (
          (tenantName === 'flexprint' || tenantName === 'sandbox') &&
          warehouse.WarehouseID === 803
        ) {
          warehouseData.push(warehouse);
        } else if (tenantName === 'marimon' && warehouse.WarehouseID === 131) {
          warehouseData.push(warehouse);
        }
      });
      getDefaultWareHouse(response);

      return response;
    } catch (e) {}
  };

  const getGlobalSettings = async () => {
    try {
      const response: any = await Api.callAPI({
        url: '/api/v1/globalSettings',
        options: {
          showErrorMessage: false,
        },
        method: 'GET',
      });
      if (response?.data?.length) {
        const globalSetting = response.data[0];
        let enableCommunicationFromCustomersToZendesk =
          globalSetting.EnableCommunicationFromCustomersToZendesk ===
            undefined ||
          globalSetting.EnableCommunicationFromCustomersToZendesk === null
            ? true
            : globalSetting.EnableCommunicationFromCustomersToZendesk;
        if (enableCommunicationFromCustomersToZendesk) {
          setIsShowExtendNotes(true);
        }
      }
    } catch (e) {}
  };

  const getQuotes = async () => {
    try {
      const response: any = await Api.callAPI({
        url: `/api/v1/quotes/${addCustomerId || id}`,
        method: 'get',
        options: {
          successMessage: false,
          errorMessage: 'Error while getting quotes.',
          isLoading: true,
        },
      });
      const defaultValueForQuotes = [];
      const modifiedQuotesData = response.data.map((QuotesObj: any) => {
        defaultValueForQuotes.push(QuotesObj?.QuoteID);

        return {
          name: `QuoteID-${QuotesObj?.QuoteID}`,
          value: QuotesObj?.QuoteID,
          label: QuotesObj?.QuoteNumber,
        };
      });

      setQuotesData(modifiedQuotesData);
      return response?.data;
    } catch (e) {}
  };

  const getProblems = async () => {
    try {
      const response: any = await Api.callAPI({
        url: `/api/v1/services/problems`,
        method: 'get',
        options: {
          isLoading: true,
          successMessage: false,
          errorMessage: 'Error while getting problems.',
        },
      });
      const modifiedProblemData =
        response &&
        response.data.map((problemsObj: any) => {
          return {
            value: problemsObj.ProblemCodeID,
            label: problemsObj.Description,
          };
        });
      setProblemData(modifiedProblemData);

      return response?.data;
    } catch (e) {
    } finally {
      dispatch(hideLoader());
    }
  };
  const getCustomerById = async () => {
    try {
      return await Api.callAPI({
        url: `/api/v1/customers/${addCustomerId || id}`,
        options: {
          showErrorMessage: false,
          isLoading: true,
        },
        method: 'get',
      });
    } catch (e) {}
  };
  const addCustomer = async (customerData: any) => {
    try {
      const response: any = await Api.callAPI({
        url: '/api/v1/customers/',
        method: 'post',
        body: customerData,
        options: {
          isLoading: true,
          showErrorMessage: false,
          successMessage: 'Customer add successfully.',
        },
      });
      setTimeout(() => {
        if (response) {
          navigate('/admin/customer');
        }
      }, [100]);
      return response;
    } catch (e) {}
  };
  const onChangeHandlerWareHouse = (warehouseObj: any) => {
    // wareHouseObj = JSON.parse(warehouseObj);
    customerObj.WarehouseID = warehouseObj?.value;
    customerObj.WarehouseName = warehouseObj?.label;
    if (customerObj.WarehouseID === 803 || customerObj.WarehouseID === 131) {
      _.forEach(checkoutRequireFields, (obj) => {
        if (obj.key === 'department' || obj.key === 'floor') {
          obj.isRequired = false;
          obj.isRequiredByDefault = false;
        }
      });
    } else {
      _.forEach(checkoutRequireFields, (obj) => {
        if (obj.key === 'department' || obj.key === 'floor') {
          obj.isRequired = true;
          obj.isRequiredByDefault = true;
          const index = _.findIndex(checkoutHideFields, { key: obj.key });
          checkoutHideFields[index].isDisable = true;
          checkoutHideFields[index].hide = false;
        }
      });
    }
  };
  const updateCustomer = async (customerData: any) => {
    try {
      const response = await Api.callAPI({
        url: `/api/v1/customers/${id}`,
        body: customerData,
        method: 'put',
        options: {
          isLoading: true,
          successMessage: 'Customer update successfully.',
        },
      });
      setTimeout(() => {
        if (response) {
          navigate('/admin/customer');
        }
      }, [100]);
    } catch (e) {
    } finally {
    }
  };

  const requestPayloadForAddEdit = (customerData: any) => {
    return {
      fieldMeta: {
        address: true,
        attention: true,
        cc: customerData?.cc ? customerData?.cc : false,
        comments: customerData?.comments ? customerData?.comments : false,
        department: customerData?.department ? customerData?.department : false,
        description: true,
        email: true,
        floor: customerData?.floor ? customerData?.floor : false,
        name: true,
        orderEmail: true,
        orderPhone: customerData?.orderPhone ? customerData?.orderPhone : false,
        phone: true,
        poNumber: customerData?.poNumber ? customerData?.poNumber : false,
        primarySymptom: true,
        extendedNotes: customerData?.extendedNotes
          ? customerData?.extendedNotes
          : false,
      },
      hidefieldMeta: {
        cc:
          customerData?.CCemailHide && !customerData?.cc
            ? customerData?.CCemailHide
            : false,
        department:
          customerData?.DepartmentHide && !customerData?.department
            ? customerData?.DepartmentHide
            : false,
        floor: customerData?.FloorHide ? customerData?.FloorHide : false,
        orderPhone:
          customerData?.PhoneHide && !customerData?.orderPhone
            ? customerData?.PhoneHide
            : false,
        poNumber:
          customerData?.PONumberHide && !customerData?.poNumber
            ? customerData?.PONumberHide
            : false,
      },
      Id: addCustomerId || id,

      WarehouseID: defaultWareHouse ? defaultWareHouse[0].value : null,
      WarehouseName: defaultWareHouse ? defaultWareHouse[0].label : null,

      HideZeroDollarPricing: customerData?.HideZeroDollarPricing,
      SearchType:
        customerData?.SearchType && customerData?.SearchType?.length
          ? customerData?.SearchType[0]
          : '',

      QuotesID: customerData?.QuotesID || [],

      AllowServiceRequestNumber: customerData?.AllowServiceRequestNumber,
      CanViewGLCode: customerData?.CanViewGLCode,

      Prefix: customerData?.Prefix || '',

      HideEquipmentOrders: customerData?.HideEquipmentOrders,
      ExemptFromFreightOnTransactionalOrders:
        customerData?.ExemptFromFreightOnTransactionalOrders,
      CanAutoFillAttentionField: customerData?.CanAutoFillAttentionField,
      CanPrependPONumberAttentionField:
        customerData?.CanPrependPONumberAttentionField,
      ShowContractExpirationDate: customerData?.ShowContractExpirationDate,
      AllowRestrictRegion: customerData?.AllowRestrictRegion,
      ShowRegionCodeInGrid: customerData?.ShowRegionCodeInGrid,
      AllowReportView: customerData?.AllowReportView,
      TriageW2Calls: customerData?.TriageW2Calls,
      TriageOOACalls: customerData?.TriageOOACalls,
      AllowHidingUsersWebOrders: customerData?.AllowHidingUsersWebOrders,
      Note: customerData?.Note || null,
      PrimarySymptom: customerData?.PrimarySymptom,
      WarnCustomerOfPotentialDuplicateOrders:
        customerData?.WarnCustomerOfPotentialDuplicateOrders,
      ServiceRequestContactDefaultName:
        customerData?.ServiceRequestContactDefaultName &&
        customerData?.ServiceRequestContactDefaultName.length
          ? customerData?.ServiceRequestContactDefaultName[0]
          : '',
      ServiceRequestContactDefaultEmail:
        customerData?.ServiceRequestContactDefaultEmail &&
        customerData?.ServiceRequestContactDefaultEmail.length
          ? customerData?.ServiceRequestContactDefaultEmail[0]
          : '',
      ServiceRequestContactDefaultPhone:
        customerData?.ServiceRequestContactDefaultPhone &&
        customerData?.ServiceRequestContactDefaultPhone.length
          ? customerData?.ServiceRequestContactDefaultPhone[0]
          : '',
      SendEmailsWhenFCPOrdersAreCanceled:
        customerData?.SendEmailsWhenFCPOrdersAreCanceled,
      Number: customerData?.Number,
      Name: customerData?.Name,
      City: customerData?.City,
      State: customerData?.State,
      Zip: customerData?.Zip,
      Address: customerData?.Address,
      Active: customerData?.Active,
      Country: customerData?.Country,
      Phone1: customerData?.Phone1,
      Email: customerData?.Email,
      ATTN: customerData?.ATTN,
      ShippingMethod: customerData?.ShippingMethod,
      LongerLocationRemarks: customerData?.LongerLocationRemarks,
    };
  };

  const onSubmitHandler = async (customerData: any) => {
    if (id) {
      const bodyObj = requestPayloadForAddEdit(customerData);
      await updateCustomer(bodyObj);
    } else {
      const payload = requestPayloadForAddEdit(customerData);
      const bodyObj = {
        customerData: payload,
      };
      await addCustomer(bodyObj);
    }
  };
  const qutoOptionManageVisibility = ({ isHide }) => {
    const ele = document.getElementsByClassName('options-group-QuotesID');
    if (ele && ele.length) {
      if (isHide) {
        ele[0].classList.add('hidden');
      } else {
        ele[0].classList.remove('hidden');
      }
    }
  };

  const onCancelHandler = () => {
    navigate('/admin/customer');
  };

  useEffect(() => {
    if (addCustomerId) {
      (async () => {
        const customerDataRes = await getCustomerById();
        setEditCustomerName(customerDataRes?.data?.CustomerData?.CustomerName);
      })();

      const obj = {
        WarehouseName: defaultWareHouse,
        SearchType: [itemAvailability],
        ServiceRequestContactDefaultEmail: [defaultEmailOptions[0]],
        ServiceRequestContactDefaultName: [defaultNameOptions[0]],
        ServiceRequestContactDefaultPhone: [defaultPhoneOptions[0]],
        poNumber: false,
        address: false,
        attention: false,
        orderPhone: false,
        floor: false,
        department: false,
        cc: false,
        orderEmail: false,
        HideEquipmentOrders : true,
        HideZeroDollarPricing: true,
        CanAutoFillAttentionField: true,
        TriageOOACalls: true,
        WarnCustomerOfPotentialDuplicateOrders: true,
        SendEmailsWhenFCPOrdersAreCanceled: true,
        address: true,
        attention: true,
        orderEmail: true,
        primarySymptom: true,
        description: true,
        phone: true,
        email: true,
        extendedNotes: false,
        PONumberHide: false,
        AllowReportView: true,
        PhoneHide: false,
        CCemailHide: false,
        DepartmentHide: false,
        FloorHide: false,
        LongerLocationRemarks: false,
      };

      if (QuotesData && QuotesData?.length) {
        obj.QuotesID = QuotesData.map(({ value }) => value);
      }
      setDefaultValue({ ...obj });
      setInit(true);
    }
  }, [defaultWareHouse, itemAvailability, QuotesData]);

  useEffect(() => {
    (async () => {
      await getGlobalSettings();
      const warhouseResponse = await getWareHouseData();
      const quotesData = await getQuotes();

      const deafultWarHouse = getDefaultWareHouse(warhouseResponse);

      const problemsList = await getProblems();
      if (id) {
        const customerDataRes = await getCustomerById();
        setEditCustomerName(customerDataRes?.data?.CustomerData?.CustomerName);
        const {
          CustomerData: customerData,
          HideField,
          RequiredField,
        } = customerDataRes?.data;
        const defaultPrimarySymptom = [];

        if (customerData?.PrimarySymptom?.length) {
          customerData?.PrimarySymptom?.map((pId: any) => {
            const problem = problemsList.find(
              ({ ProblemCodeID }) => ProblemCodeID === pId,
            );
            if (problem) {
              defaultPrimarySymptom.push({
                value: problem?.ProblemCodeID,
                label: problem?.Description,
              });
            }
          });
        }
        const obj = {
          WarehouseName: deafultWarHouse,
          SearchType: customerData
            ? searchTypeOptions.filter(
                ({ value }) => value === customerData?.SearchType,
              )
            : '',
          ServiceRequestContactDefaultEmail: customerData
            ? defaultEmailOptions?.filter(
                ({ value }) =>
                  value === customerData?.ServiceRequestContactDefaultEmail,
              )
            : [defaultEmailOptions[0]],
          ServiceRequestContactDefaultName: customerData
            ? defaultNameOptions?.filter(
                ({ value }) =>
                  value === customerData?.ServiceRequestContactDefaultName,
              )
            : [defaultNameOptions[0]],
          ServiceRequestContactDefaultPhone: customerData
            ? defaultPhoneOptions?.filter(
                ({ value }) =>
                  value === customerData?.ServiceRequestContactDefaultPhone,
              )
            : [defaultPhoneOptions[0]],
          HideZeroDollarPricing: customerData?.HideZeroDollarPricing,
          HideEquipmentOrders: customerData?.HideEquipmentOrders,
          CanAutoFillAttentionField: customerData?.CanAutoFillAttentionField,
          TriageOOACalls: customerData?.TriageOOACalls,
          WarnCustomerOfPotentialDuplicateOrders:
            customerData?.WarnCustomerOfPotentialDuplicateOrders,
          SendEmailsWhenFCPOrdersAreCanceled:
            customerData?.SendEmailsWhenFCPOrdersAreCanceled,
          AllowReportView: customerData?.AllowReportView,
          Note: customerData?.Note,
          PrimarySymptom: defaultPrimarySymptom,
          ExemptFromFreightOnTransactionalOrders:
            customerData?.ExemptFromFreightOnTransactionalOrders,
          AllowRestrictRegion: customerData?.AllowRestrictRegion,
          ShowRegionCodeInGrid: customerData?.ShowRegionCodeInGrid,
          ShowContractExpirationDate: customerData?.ShowContractExpirationDate,
          CanViewGLCode: customerData?.CanViewGLCode,
          CanAutoFillAttentionField: customerData?.CanAutoFillAttentionField,
          CanPrependPONumberAttentionField:
            customerData?.CanPrependPONumberAttentionField,
          AllowServiceRequestNumber: customerData?.AllowServiceRequestNumber,
          Prefix: customerData?.Prefix,
          TriageW2Calls: customerData?.TriageW2Calls,
          TriageOOACalls: customerData?.TriageOOACalls,
          AllowHidingUsersWebOrders: customerData?.AllowHidingUsersWebOrders,
          PONumberHide: HideField?.poNumber,
          PhoneHide: HideField?.orderPhone,
          CCemailHide: HideField?.cc,
          DepartmentHide: HideField?.department,
          FloorHide: HideField?.floor,
          extendedNotes: RequiredField.extendedNotes,
          poNumber: RequiredField.poNumber,
          address: RequiredField.address,
          attention: RequiredField.attention,
          orderPhone: RequiredField.orderPhone,
          floor: RequiredField.floor,
          department: RequiredField.department,
          cc: RequiredField.cc,
          orderEmail: RequiredField.orderEmail,
          comments: RequiredField.comments,
          Number: customerData?.Number,
          Name: customerData?.Name,
          City: customerData?.City,
          State: customerData?.State,
          Zip: customerData?.Zip,
          Address: customerData?.Address,
          Active: customerData?.Active,
          Country: customerData?.Country,
          Phone1: customerData?.Phone1,
          Email: customerData?.Email,
          ATTN: customerData?.ATTN,
          LongerLocationRemarks: customerData?.LongerLocationRemarks || false,
          ShippingMethod: customerData?.ShippingMethod,
        };
        if (customerData?.QuotesID && customerData?.QuotesID.length) {
          obj.QuotesID = customerData?.QuotesID.map((v: any) => v);
        }
        setRequiredFieldsDefaultSettings({
          ...requiredFieldsDefaultSettings,
          poNumber: {
            checked: HideField?.poNumber,
            disable: RequiredField.poNumber,
          },
          orderPhone: {
            checked: HideField?.orderPhone,
            disable: RequiredField.orderPhone,
          },
          cc: {
            checked: HideField?.cc,
            disable: RequiredField.cc,
          },
          department: {
            checked: HideField?.department,
            disable: RequiredField.department,
          },
          floor: {
            checked: HideField?.floor,
            disable: RequiredField.floor,
          },
        });
        if (customerData?.AllowServiceRequestNumber) {
          setIsServicesRequestAllow(true);
        }
        if (
          customerData?.SearchType === 'TR' ||
          customerData?.SearchType === 'COC-TR'
        ) {
          setIsQuotes(true);
        }

        setDefaultValue(obj);
        setInit(true);
        setTimeout(() => {
          if (
            customerData?.QuotesID &&
            quotesData?.length === customerData?.QuotesID?.length
          ) {
            qutoOptionManageVisibility({ isHide: true });
          } else {
            qutoOptionManageVisibility({ isHide: false });
          }
        }, 100);
      }
    })();
  }, []);

  const changeFormValue = (changedValue: any) => {
    if (Object.keys(changedValue).length) {
      let obj = {};
      Object.keys(changedValue).map((key) => {
        obj[key] = changedValue[key]?.value;
      });
      obj.PONumberHide = obj.poNumber ? false : obj.PONumberHide;
      obj.PhoneHide = obj.orderPhone ? false : obj.PhoneHide;
      obj.CCemailHide = obj.cc ? false : obj.CCemailHide;
      obj.DepartmentHide = obj.department ? false : obj.DepartmentHide;
      obj.FloorHide = obj.floor ? false : obj.FloorHide;
      if (JSON.stringify(obj) !== JSON.stringify(defaultValues)) {
        setDefaultValue(obj);
      }
    }
  };

  return (
    <>
      {defaultWareHouse && problemsData && init ? (
        <div className="h-full w-full">
          <h2 className="font-semibold text-gray-900 mb-8">
            {id
              ? `Edit Customer - ${editCustomerName}`
              : `Add Customer - ${editCustomerName}`}
          </h2>
          <div>
            <div className="addCustomerForm">
              <Form
                formRef={ref}
                defaultValues={defaultValues}
                onSubmit={onSubmitHandler}
                onChange={changeFormValue}
              >
                <div className="sm:w-1/2 mb-6 labelStyle">
                  <div className="">
                    <FormItems
                      items={[
                        {
                          name: 'WarehouseName',
                          label: 'Default Warehouse',
                          type: FormType.SELECT,
                          selectShowCheckboxes: false,
                          selectHideSearch: true,
                          ClearSelectedIcon: null,
                          required: true,
                          options: defaultWareHouse,
                          onChange: (op) => {
                            if (op && op[0]) {
                              onChangeHandlerWareHouse(op[0]);
                            }
                          },
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="sm:w-1/2 mb-6 labelStyle">
                  <FormItems
                    items={[
                      {
                        name: 'SearchType',
                        label: 'Item Availability',
                        selectShowCheckboxes: false,
                        selectHideSearch: true,
                        ClearSelectedIcon: null,
                        type: FormType.SELECT,
                        required: true,
                        // value: itemAvailability,
                        options: searchTypeOptions,
                        onChange: (e) => {
                          setDefaultValue({ ...defaultValues, SearchType: e });
                          setItemAvailability(e[0]);
                          if (e[0].value === 'TR' || e[0].value === 'COC-TR') {
                            setIsQuotes(true);
                            setTimeout(() => {
                              if (
                                defaultValues?.QuotesID &&
                                QuotesData?.length ===
                                  defaultValues?.QuotesID?.length
                              ) {
                                qutoOptionManageVisibility({ isHide: true });
                              } else {
                                qutoOptionManageVisibility({ isHide: false });
                              }
                            }, 10);
                          } else {
                            setIsQuotes(false);
                            setTimeout(() => {
                              qutoOptionManageVisibility({ isHide: true });
                            }, 10);
                          }
                          ref?.removeFormDef(['QuotesID']);
                        },
                      },
                    ]}
                  />

                  <div
                    className={`mb-6 quotesBar ${
                      (isQuotes && QuotesData?.length) ||
                      (defaultValues?.SearchType[0].value === 'TR' &&
                        isEdit &&
                        defaultValues?.SearchType[0].value === 'COC-TR' &&
                        isQuotes)
                        ? ''
                        : 'hidden'
                    }`}
                  >
                    <label className="font-medium mb-1 block text-gray-900 text-sm">
                      Quotes <span className="text-red-400">*</span>{' '}
                    </label>
                    <div>
                      <FormItem
                        formDef={{
                          name: 'QuotesID',
                          label: 'Select All Quotes',
                          required:
                            (isQuotes && QuotesData?.length) ||
                            (defaultValues?.SearchType[0].value === 'TR' &&
                              isEdit &&
                              defaultValues?.SearchType[0].value === 'COC-TR' &&
                              isQuotes)
                              ? true
                              : false,
                          optionsMultiSelect: true,
                          selectAll: true,
                          selectAllLabel: 'Select All Quotes',
                          type: FormType.CHECKBOX,
                          onChange: (value, type) => {
                            if (type === 'selectAll') {
                              if (value?.length) {
                                qutoOptionManageVisibility({ isHide: true });
                              } else {
                                qutoOptionManageVisibility({ isHide: false });
                              }
                            }
                          },
                          radioOptions: QuotesData,
                          errors: {
                            required: 'Please select atleast one quote.',
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:my-12 sm:pt-14 sm:pb-8 sm:border-y border-gray-300">
                  <div className="grid lg:grid-cols-4 lg:gap-8 md:grid-cols-3 sm:grid-cols-2 gap-4 mb-6">
                    <div className="sm:border-r border-gray-300 sm:pr-6 sm:border-b-0 border-b-2 sm:pb-0 pb-4">
                      {/* Pricing */}
                      <div>
                        <label className="font-medium mb-1 block text-gray-900 text-sm">
                          Pricing
                        </label>
                        <FormItem
                        className='customInput'
                          formDef={{
                            name: 'HideZeroDollarPricing',
                            label: 'Hide $0 pricing',
                            type: FormType.CHECKBOX,
                            // onChange: () => {
                            //   setIsPricing(!isPricing);
                            // },
                          }}
                        />
                      </div>
                    </div>
                    <div className="sm:border-r border-gray-300 sm:pr-6 sm:border-b-0 border-b-2 sm:pb-0 pb-4">
                      {/* Hide All Equipment Orders */}
                      <div>
                        <label className="font-medium mb-1 block text-gray-900 text-sm">
                          Hide All Equipment Orders
                        </label>
                        <FormItem
                        className='customInput'
                          formDef={{
                            name: 'HideEquipmentOrders',
                            label: 'Hide all equipment orders',
                            type: FormType.CHECKBOX,
                          }}
                        />
                      </div>
                    </div>
                    {/* Shipping & Handling */}
                    {customer.Tokens.indexOf('CAN_MANAGE_SUPER_ADMIN_USER') >
                    -1 ? (
                      <div className="sm:border-r border-gray-300 sm:pr-6 sm:border-b-0 border-b-2 sm:pb-0 pb-4">
                        <div>
                          <label className="font-medium mb-1 block text-gray-900 text-sm">
                            Shipping & Handling
                          </label>
                          <FormItem
                            className='customInput'
                            formDef={{
                              name: 'ExemptFromFreightOnTransactionalOrders',
                              label:
                                'Exempt from freight on transactional orders',
                              type: FormType.CHECKBOX,
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                  {/* Longer Location Remarks */}
                  {customer.Tokens.indexOf('CAN_MANAGE_SUPER_ADMIN_USER') >
                    -1 ? (
                      <div className="sm:border-r border-gray-300 sm:pr-6 sm:border-b-0 border-b-2 sm:pb-0 pb-4">
                        <div>
                          <label className="font-medium mb-1 block text-gray-900 text-sm">
                            Allow extended location remarks 
                          </label>
                          <FormItem
                            className='customInput'
                            formDef={{
                              name: 'LongerLocationRemarks',
                              label:
                                'Up to 80 characters for Supply Routing Labels',
                              type: FormType.CHECKBOX,
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className='sm:border-r border-gray-300 lg:border-none lg:pr-0 sm:pr-6 sm:border-b-0 border-b-2 sm:pb-0 pb-4'>
                      {/* Region Codes */}
                      <div>
                        <label className="font-medium mb-1 block text-gray-900 text-sm">
                          Region Codes
                        </label>
                        <div>
                          <FormItem
                          className='customInput'
                            formDef={{
                              name: 'AllowRestrictRegion',
                              label: 'Allow restricting users by region',
                              type: FormType.CHECKBOX,
                            }}
                          />
                        </div>
                        <div>
                          <FormItem
                          className='customInput'
                            formDef={{
                              name: 'ShowRegionCodeInGrid',
                              label:
                                'Show region code on location grid &reports',
                              type: FormType.CHECKBOX,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sm:border-r border-gray-300 sm:pr-6 sm:border-b-0 border-b-2 sm:pb-0 pb-4">
                      {/* View Reports */}
                      <div>
                        <label className="font-medium mb-1 block text-gray-900 text-sm">
                          View reports
                        </label>
                        <FormItem
                        className='customInput'
                          formDef={{
                            name: 'AllowReportView',
                            label: 'Can view reports',
                            type: FormType.CHECKBOX,
                          }}
                        />
                      </div>
                    </div>
                    <div className="sm:border-r border-gray-300 sm:pr-6 sm:border-b-0 border-b-2 sm:pb-0 pb-4">
                      {/* Show Device's Lease Expiration Date */}
                      {tenantName !== 'marimon' && (
                        <div>
                          <label className="font-medium mb-1 block text-gray-900 text-sm">
                            Show Device's Lease Expiration Date
                          </label>
                          <FormItem
                          className='customInput'
                            formDef={{
                              name: 'ShowContractExpirationDate',
                              label: "Show device's lease expiration date",
                              type: FormType.CHECKBOX,
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="sm:border-r border-gray-300 sm:pr-6 sm:border-b-0 border-b-2 sm:pb-0 pb-4">
                      {/* Cost Center/ GL Code */}
                      <div>
                        <label className="font-medium mb-1 block text-gray-900 text-sm">
                          Cost Center/ GL Code
                        </label>
                        <FormItem
                        className='customInput'
                          formDef={{
                            name: 'CanViewGLCode',
                            label: 'Can view cost center / GL code',
                            type: FormType.CHECKBOX,
                          }}
                        />
                      </div>
                    </div>
                    <div className='sm:border-r border-gray-300 lg:border-none lg:pr-0 sm:pr-6 sm:border-b-0 border-b-2 sm:pb-0 pb-4'>
                      {/* Attention line */}
                      <div>
                        <label className="font-medium mb-1 block text-gray-900 text-sm">
                          Attention line
                        </label>
                        <div className="">
                          <FormItem
                          className='customInput'
                            formDef={{
                              name: 'CanAutoFillAttentionField',
                              label:
                                'Auto fill attn with location remark and equipment number',
                              type: FormType.CHECKBOX,
                              // value: isCanAutoFillAttention,
                              onChange: () => {
                                // setIsCanAutoFillAttention(!isCanAutoFillAttention);
                              },
                            }}
                          />
                        </div>
                        <div>
                          <FormItem
                          className='customInput'
                            formDef={{
                              name: 'CanPrependPONumberAttentionField',
                              label: 'Prepend PO#/Cost Center to attn line',
                              type: FormType.CHECKBOX,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sm:border-r border-gray-300 sm:pr-6 sm:border-b-0 border-b-2 sm:pb-0 pb-4">
                      {/* Service request number */}
                      <div>
                        <label className="font-medium mb-1 block text-gray-900 text-sm">
                          Service request number
                        </label>
                        <div className="">
                          <FormItem
                          className='customInput'
                            formDef={{
                              name: 'AllowServiceRequestNumber',
                              label:
                                'Allow customer to enter their service request number',
                              type: FormType.CHECKBOX,
                              value: isServicesRequestAllow,
                              onChange: () => {
                                ref?.clearInputs(['Prefix']);
                                ref?.removeFormDef(['Prefix']);
                                setIsServicesRequestAllow(
                                  !isServicesRequestAllow,
                                );
                              },
                            }}
                          />
                        </div>
                        <div
                          className={`mt-2 font-medium ${
                            isServicesRequestAllow ||
                            (defaultValues?.AllowServiceRequestNumber &&
                              isEdit &&
                              isServicesRequestAllow)
                              ? ''
                              : 'hidden'
                          }`}
                        >
                          <FormItem
                            className='customInput'
                            formDef={{
                              name: 'Prefix',
                              label: 'Customer prefix',
                              type: FormType.TEXT,
                              required:
                                isServicesRequestAllow ||
                                (defaultValues?.AllowServiceRequestNumber &&
                                  isEdit &&
                                  isServicesRequestAllow),
                              minLength: 3,
                              maxLength: 3,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sm:border-r border-gray-300 sm:pr-6 sm:border-b-0 border-b-2 sm:pb-0 pb-4">
                      {/* Send service calls to the Help Desk for triage */}
                      {tenantName !== 'marimon' && (
                        <div>
                          <label className="font-medium mb-1 block text-gray-900 text-sm">
                            Send service calls to the Help Desk for triage
                          </label>
                          <div className="">
                            <FormItem
                            className='customInput'
                              formDef={{
                                name: 'TriageW2Calls',
                                label: 'Triage W2 Calls',
                                type: FormType.CHECKBOX,
                              }}
                            />
                          </div>
                          <div>
                            <FormItem
                            className='customInput'
                              formDef={{
                                name: 'TriageOOACalls',
                                label: 'Triage OOA Calls',
                                type: FormType.CHECKBOX,
                                // value: isTriageOOACalls,
                                onChange: () => {
                                  // setIsTriageOOACalls(!isTriageOOACalls);
                                },
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="sm:border-r border-gray-300 sm:pr-6 sm:border-b-0 border-b-2 sm:pb-0 pb-4">
                      {/*  Allow hiding auto toner, phone, and other users' web orders */}
                      <div>
                        <label className="font-medium mb-1 block text-gray-900 text-sm">
                          Allow hiding auto toner, phone, and other users' web
                          orders
                        </label>
                        <div className="">
                          <FormItem
                          className='customInput'
                            formDef={{
                              name: 'AllowHidingUsersWebOrders',
                              label: 'Allow hiding orders by user',
                              type: FormType.CHECKBOX,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='sm:border-r border-gray-300 lg:border-none lg:pr-0 sm:pr-6 sm:border-b-0 border-b-2 sm:pb-0 pb-4'>
                      {/* Available Primary Symptoms (leave blank for all) */}
                      <div>
                        <label className="font-medium mb-1 block text-gray-900 text-sm">
                          Available Primary Symptoms (leave blank for all)
                        </label>
                        <div className="autoHeightDropdown">
                          <FormItems
                          className='customInput'
                            items={[
                              {
                                name: 'PrimarySymptom',
                                label: '',
                                type: FormType.SELECT,
                                ClearSelectedIcon: null,
                                options: problemsData,
                                optionsMultiSelect: true,
                                selectAllLabel: 'Select All',
                                selectShowSelectAll: true,
                                selectShowChip: true,
                                selectMultiSelect: true,
                                selectShowSelectAllFilter: true,
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sm:border-r border-gray-300 sm:pr-6 sm:border-b-0 border-b-2 sm:pb-0 pb-4">
                      {/* Note */}
                      {tenantName !== 'marimon' && (
                        <div>
                          <div className="">
                            <FormItem
                              formDef={{
                                name: 'Note',
                                label:
                                  ' Service Note (will be added to all calls, visible to customer)',
                                type: FormType.TEXTAREA,
                                maxLength: 80,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="sm:border-r border-gray-300 sm:pr-6 sm:border-b-0 border-b-2 sm:pb-0 pb-4">
                      {/* Warn customer of potential duplicate orders */}
                      <div>
                        <label className="font-medium mb-1 block text-gray-900 text-sm">
                          Warn customer of potential duplicate orders
                        </label>
                        <div className="">
                          <FormItem
                          className='customInput'
                            formDef={{
                              name: 'WarnCustomerOfPotentialDuplicateOrders',
                              label: 'Enable',
                              type: FormType.CHECKBOX,
                              // value: !isDuplicateOrder,
                              onChange: () => {
                                // setIsDuplicateOrder(!isDuplicateOrder);
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sm:border-r border-gray-300 sm:pr-6 sm:border-b-0 border-b-2 sm:pb-0 pb-4">
                      <label className="font-medium mb-1 block text-gray-900 text-sm">
                        Send emails when FCP orders are canceled
                      </label>
                      <div className="">
                        <FormItem
                        className='customInput'
                          formDef={{
                            name: 'SendEmailsWhenFCPOrdersAreCanceled',
                            label: 'Enable',
                            type: FormType.CHECKBOX,
                            // value: !isFCPSendEmail,
                            onChange: () => {
                              // setIsFCPSendEmail(!isFCPSendEmail);
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-8 border-b border-gray-300 sm:pb-8 pb-4">
                  <div className="mb-3">
                    <label className="font-medium mb-1 block text-gray-900 text-sm">
                      Service Request Contact Defaults
                    </label>
                  </div>
                  <div className="grid sm:grid-cols-3 sm:gap-6 labelStyle">
                    {/* Service Request Contact Defaults */}

                    {
                      <div>
                        <FormItems
                          items={[
                            {
                              name: 'ServiceRequestContactDefaultName',
                              label: 'Default Name',
                              type: FormType.SELECT,
                              ClearSelectedIcon: null,
                              selectShowCheckboxes: false,
                              selectHideSearch: true,
                              required: true,
                              options: defaultNameOptions,
                            },
                          ]}
                        />
                      </div>
                    }
                    <FormItems
                      items={[
                        {
                          name: 'ServiceRequestContactDefaultPhone',
                          label: 'Default Phone',
                          type: FormType.SELECT,
                          required: true,
                          ClearSelectedIcon: null,
                          selectShowCheckboxes: false,
                          selectHideSearch: true,
                          options: defaultPhoneOptions,
                        },
                      ]}
                    />
                    <FormItems
                      items={[
                        {
                          name: 'ServiceRequestContactDefaultEmail',
                          label: 'Default Email',
                          type: FormType.SELECT,
                          required: true,
                          ClearSelectedIcon: null,
                          selectShowCheckboxes: false,
                          selectHideSearch: true,
                          options: defaultEmailOptions,
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="grid sm:grid-cols-3 sm:gap-6 gap-2">
                  <div>
                    {/* Checkout Page Require Field */}
                    <div>
                      <label className="font-medium mb-1 block text-gray-900 text-sm">
                        Checkout Page Require Field
                      </label>
                      <FormItems items={checkoutRequireFields} />
                    </div>
                  </div>
                  <div>
                    {/* Service Request Require Field  */}
                    <div>
                      <label className="font-medium mb-1 block text-gray-900 text-sm">
                        Service Request Require Field
                      </label>
                      <FormItems
                        items={
                          isShowExtendNotes
                            ? [
                                ...serviceRequestRequireFields,
                                {
                                  name: 'extendedNotes',
                                  label: 'Extended Notes',
                                  type: FormType.CHECKBOX,
                                },
                              ]
                            : [...serviceRequestRequireFields]
                        }
                      />
                    </div>
                  </div>
                  <div>
                    {/* Checkout Page Hide Field */}
                    <div>
                      <label className="font-medium mb-1 block text-gray-900 text-sm">
                        Checkout Page Hide Field
                      </label>
                      <FormItems items={checkoutHideFields} />
                    </div>
                  </div>
                </div>

                <div className="flex justify-end py-6 border-t border-gray-200  mt-6">
                  <button
                    type="button"
                    className={`borderedBtn mr-2 linkText hover:text-black`}
                    onClick={() => {
                      onCancelHandler();
                    }}
                  >
                    Cancel
                  </button>
                  <SubmitBtn className="commonBtn">
                    {id ? 'Update' : 'Create'}
                  </SubmitBtn>
                </div>
              </Form>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};
