import React, { useEffect, useRef, useState } from 'react';
import {
  CancelBtn,
  Form,
  FormItem,
  FormItems,
  FormRef,
  FormType,
  InputMask,
  SubmitBtn,
} from '../../../../../components/form';
import { CustomerConfig } from './customer-config';
import { Api } from '../../../../../shared/api/api';
import { useNavigate, useParams } from 'react-router-dom';
import { SubHeader } from '../../../../admin/layout/footer/sub-header/sub-header';
import { ComponentLoading } from '../../../../../shared/component-loader/component-loading';
import { useUser } from '../../../../../context/UserContext';

export const ClientAddUpdateUsers = () => {

  const { customerInfo } = useUser();

  const form: FormRef = useRef();

  const [ addresses, setAddresses ] = useState([]);
  const [ regions, setRegions ] = useState([]);
  const [ customerDetails, setCustomerDetails] = useState(null);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const { userId } = useParams();
  const navigate = useNavigate();

  const thisConfig = customerDetails?.CustomerUserConfigurations.find((config => config.CustomerId === parseInt(customerInfo.CustomerId)));

  const roles = [
    { name: "ALL", label: "Add/edit/delete users", value: "ALL"},
    { name: "CAN_PLACE_ORDER", label: "Order supplies", value: "CAN_PLACE_ORDER"},
    { name: "CAN_VIEW_PLACED_ORDER", label: "View supply orders", value: "CAN_VIEW_PLACED_ORDER"},
    { name: "CAN_PLACE_SERVICE_REQUEST", label: "Request service", value: "CAN_PLACE_SERVICE_REQUEST"},
    { name: "CAN_VIEW_SERVICE_REQUEST", label: "View service requests", value: "CAN_VIEW_SERVICE_REQUEST"},
    { name: "CAN_ADD_REMOVE_DEVICE_LOCATION", label: "Add/edit devices & locations", value: "CAN_ADD_REMOVE_DEVICE_LOCATION"},
    { name: "CAN_EDIT_DEVICE_LOCATION", label: "Edit a device's location", value: "CAN_EDIT_DEVICE_LOCATION"},
  ];

  if(customerInfo.AllowReportView) roles.push({ name: "CAN_VIEW_REPORT", label: "View reports", value: "CAN_VIEW_REPORT"});
  if(customerInfo.CustomerCanViewGLCode) roles.push({ name: "CAN_EDIT_COST_CENTER", label: "Edit GL/cost center", value: "CAN_EDIT_COST_CENTER"});

  //Prepopulae customer details
  const getCustomerDetails = async () => {
    setIsLoading(true)
    try {
      const [ details, customerAddresses, customerRegions ] = await Promise.all([
        userId ? Api.callAPI({
          url: `/api/v1/users/${userId}`,
          method: 'get',
        }) : null,
        Api.callAPI({
          url: `/api/v1/customers/${customerInfo.CustomerId}/address`,
          method: 'get',
        }),
        customerInfo.AllowRestrictRegion ? Api.callAPI({
          url: `/api/v1/customers/${customerInfo.CustomerId}/regions`,
          method: 'get',
        }) : {data: []},
      ]);

      setCustomerDetails(details?.data);
      setAddresses(customerAddresses?.data);
      setRegions(customerRegions?.data);
    } catch (e) {

    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCustomerDetails();
  }, [])

  const saveData = async (data) => {
    try {
      await Api.callAPI({
        url: userId ? `/api/v1/users/${userId}?CustomerId=${thisConfig.CustomerId}` : `/api/v1/users`,
        method: userId ? 'put' : 'post',
        body: data,
        options: {
          successMessage: 'User saved successfully.',
          errorMessage: 'Unexpected error occured, please try again.',
        },
      });
      navigate(`/customer/user/list/${userId}`);
    } catch (e) {

    } finally {
      setIsSaving(false);
    }
  }

  //Possibly rip out customer config creation and make it a separate component
  const handleSubmit = (values) => {
    setIsSaving(true);

    let CustomerUserConfigurations = [];
    const {
      Tokens, RestrictUserByLocationID, HideOtherUserOrders, PlaceOrderAddressID, SendMeTrackingWhenAvailable,
       LetMeKnowIfOrdersGetCanceled, ServiceRequestAddressID, SendCallUpdates, Regions } = values;

    CustomerUserConfigurations.push({
      Tokens, RestrictUserByLocationID, HideOtherUserOrders, PlaceOrderAddressID, SendMeTrackingWhenAvailable,
      LetMeKnowIfOrdersGetCanceled, ServiceRequestAddressID, SendCallUpdates, Regions,
      UserId: userId,
      _id: thisConfig?._id,
      CustomerId: parseInt(customerInfo.CustomerId)
    })

    //Remove deconstructed values from values
    delete values.Tokens;
    delete values.RestrictUserByLocationID;
    delete values.HideOtherUserOrders;
    delete values.PlaceOrderAddressID;
    delete values.SendMeTrackingWhenAvailable;
    delete values.LetMeKnowIfOrdersGetCanceled;
    delete values.ServiceRequestAddressID;
    delete values.SendCallUpdates;
    delete values.Regions;

    //Apply value keys
    values.CustomerUserConfigurations = CustomerUserConfigurations;
    values.AllowAllCustomers = customerDetails?.AllowAllCustomers;
    values.Customers = customerDetails?.Customers || [customerInfo.CustomerId];
    values.Active = customerDetails ? customerDetails.Active : true;
    values.CreatedBy = customerDetails?.CreatedBy || 1;
    values.CreatedDate = customerDetails?.CreatedDate || Math.floor(new Date().getTime() / 1000);

    if(userId) values._id = userId;

    delete values.selectedCustomer;
    delete values.SingleCustomer;


    saveData(values);
  }

  return (
    <>
      <SubHeader
        leftSideElement={<h1 className='text-2xl font-medium'>{ userId ? 'Edit User' : 'Add User' }</h1>}
      />
      { isLoading ? <ComponentLoading isShowLoader={true}/> : <Form
        onCancel={() => {}}
        onSubmit={handleSubmit}
        formRef={form}
      >
        <div className="">
          <div className="flex md:flex-row flex-col">
            <div className="mr-3 w-full border-r border-stone-200 pr-8 md:w-[40%]">
            <h2 className="font-bold text-lg">Details</h2>
              <FormItems
                items={[
                  {
                    name: 'EmailAddress',
                    label: 'User Email',
                    type: FormType.EMAIL,
                    placeholder: 'User Email',
                    disabled: !!customerDetails?.EmailAddress,
                    defaultValue: customerDetails?.EmailAddress,
                    required: true,
                    className: 'mb-4'
                  },
                  {
                    name: 'FirstName',
                    label: 'First Name',
                    type: FormType.TEXT,
                    placeholder: 'First Name',
                    defaultValue: customerDetails?.FirstName,
                    required: true,
                    className: 'mb-4'
                  },
                  {
                    name: 'LastName',
                    label: 'Last Name',
                    defaultValue: customerDetails?.LastName,
                    type: FormType.TEXT,
                    placeholder: 'Last Name',
                    required: true,
                    className: 'mb-4'
                  },
                ]}
              />
            <div className="flex w-full mb-4">
              <div className="w-full">
              <FormItem
                formDef={{
                  name: 'PhoneNumber',
                  label: 'Phone Number',
                  defaultValue: customerDetails?.PhoneNumber,
                  type: FormType.TEXT,
                  placeholder: 'Phone Number',
                  mask: InputMask.PHONE,
                }}
              />
              </div>
              <div className="w-full ml-4 max-w-[33%]">
                <FormItem
                  formDef={{
                    name: 'Extension',
                    label: 'Ext.',
                    maxLength: 10,
                    defaultValue: customerDetails?.Extension,
                    type: FormType.TEXT,
                    placeholder: 'Ext.',
                  }}
                />
              </div>
            </div>
            </div>
            <div className="md:w-[60%]">
              <CustomerConfig
                  customer={customerInfo}
                  defaultValues={thisConfig}
                  addresses={addresses}
                  roles={roles}
                  regions={regions}/>
            </div>
          </div>
          <div className="w-full flex items-end justify-end mt-4">
            <CancelBtn className="px-4 py-1 rounded mr-2" ghost onClick={() => {
              navigate(`/customer/user/list/${customerInfo?.CustomerId}`);
            }}>Cancel</CancelBtn>
            <SubmitBtn className="px-4 py-1 rounded" loading={isSaving}>{ customerDetails ? 'Save User' : 'Add User'}</SubmitBtn>
          </div>
        </div>
      </Form> }
    </>
  );
};
