import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  CancelBtn,
  Form,
  FormItem,
  FormItems,
  FormRef,
  FormType,
  InputMask,
  SubmitBtn,
} from '../../../../../components/form';
import { SelectCustomers } from './select-customers';
import { CustomerConfig } from './customer-config';
import { Api } from '../../../../../shared/api/api';
import { useNavigate, useParams } from 'react-router-dom';
import { isArray } from 'lodash';
import { SubHeader } from '../../../layout/footer/sub-header/sub-header';
import { APPLICATION_CONTEXT } from '../../../../../utils/utils';
import { CustomerTokens, CustomerTokenSelection } from '../../../../../types/Customer';

export const AddUpdateUsers = () => {

  const form: FormRef = useRef();

  //@ts-ignore -- unsure why this isn't typed correctly
  const { userInfo } = useContext(APPLICATION_CONTEXT);
  const [ selectedCustomers, setSelectedCustomers ] = useState([]);
  const [ singleCustomer, setSingleCustomer ] = useState(null);
  const [ customerDetails, setCustomerDetails] = useState(null);
  const [ customers, setCustomers ] = useState([]);
  const [ isSaving, setIsSaving ] = useState(false);
  const { userId, customerId } = useParams();
  const navigate = useNavigate();

  const defaultSelected = customerDetails?.Customers;
  const isSelectAll = customerDetails && selectedCustomers.filter(({value}) => value == -1).length > 0 || (selectedCustomers.length == customers.length && customers.length > 0);

  const roles: CustomerTokenSelection[] = [
    { name: CustomerTokens.All, label: "Add/edit/delete users", value: CustomerTokens.All},
    { name: CustomerTokens.PlaceOrder, label: "Order supplies", value: CustomerTokens.PlaceOrder},
    { name: CustomerTokens.ViewPlacedOrder, label: "View supply orders", value: CustomerTokens.ViewPlacedOrder},
    { name: CustomerTokens.PlaceServiceRequest, label: "Request service", value: CustomerTokens.PlaceServiceRequest},
    { name: CustomerTokens.ViewServiceRequest, label: "View service requests", value: CustomerTokens.ViewServiceRequest},
    { name: CustomerTokens.RemoveDeviceLocations, label: "Add/edit devices & locations", value: CustomerTokens.RemoveDeviceLocations},
    { name: CustomerTokens.ModifyDeviceLocation, label: "Edit a device's location", value: CustomerTokens.ModifyDeviceLocation},
  ];

  //Prepopulae customer details
  const getCustomerDetails = async () => {
    let url = '/api/v1/customers';
    if(userId) url = `/api/v1/users/${userId}`;
    if(customerId) url = `/api/v1/customers/getCustomersByIds?customers=${customerId}`;

    try {
      const res = await Api.callAPI({
        url: url,
        method: 'get',
      });
      if(!!customerId) setSelectedCustomers(res?.data && res.data.map(customer => ({ label: customer.CustomerName, value: customer.Id })));
      //With a customer id provided, the backend returns an array of a single customer 
      if(!!customerId) return setCustomerDetails(res?.data[0]);
      setCustomerDetails(res?.data ? res.data : res);
    } catch (e) {}
  };

  useEffect(() => {
    getCustomerDetails();
  }, [])

  const saveData = async (data) => {
    try {
      await Api.callAPI({
        url: userId ? `/api/v1/users/${userId}` : `/api/v1/users`,
        method: userId ? 'put' : 'post',
        body: data,
        options: {
          successMessage: 'User saved successfully.',
          errorMessage: 'Unexpected error occured, please try again.',
        },
      });
      navigate('/admin/Users');
    } catch (e) {

    } finally {
      setIsSaving(false);
    }
  }

  //Possibly rip out customer config creation and make it a separate component
  const handleSubmit = (values) => {
    setIsSaving(true);
    let CustomerUserConfigurations = [];

    //Convert FormData to CustomerUserConfigurations
    Object.keys(values).map(key => {
      let [customerConfigId, field] = key.split(" ");
      if(!field) return;
      if(customerConfigId !== '-1' && !selectedCustomers.find(customer => customer.value == customerConfigId)) return delete values[key];

      //find customer config and update it in array
      let customerConfig = CustomerUserConfigurations.find(config => config.CustomerId === parseInt(customerConfigId));

      if(!customerConfig) {
        customerConfig = {
          CustomerId: parseInt(customerConfigId),
          [field]: values[key]
        }
        CustomerUserConfigurations.push(customerConfig);
      } else {
        customerConfig[field] = values[key];
      }

      delete values[key];
    })

    //Add custom values to CustomerUserConfigurations
    CustomerUserConfigurations.map(config => {
      let defaultValues = customerDetails.CustomerUserConfigurations?.find(details => details.CustomerId === parseInt(config.CustomerId));

      config.disableTokens = config.Tokens.length == roles.length;
      config.noTokenSelected = config.Tokens.length == 0;
      config.UserId = userId;
      config.CanViewGLCode = !!config.CanViewGLCode;
      config.AllowReportView = config.Tokens.includes("CAN_VIEW_REPORT");
      config.AllowRestrictRegion = config.AllowRestrictRegion || false;
      config.SendCallUpdates = config.SendCallUpdates || false;

      if(!defaultValues) return;
      config._id = defaultValues._id;
    })

    //Apply value keys
    values.CustomerUserConfigurations = CustomerUserConfigurations;
    values.AllowAllCustomers = isSelectAll;
    values.Customers = isSelectAll ? [-1] : customerId ? [parseInt(customerId)] : values.selectedCustomer.map(val => parseInt(val));
    values.Active = customerDetails ? customerDetails.Active : true;
    values.CreatedBy = customerDetails.CreatedBy || 1;
    values.CreatedDate = customerDetails?.CreatedDate || Math.floor(new Date().getTime() / 1000);

    if(userId) values._id = userId;

    delete values.selectedCustomer;
    delete values.SingleCustomer;


    saveData(values);
  }


  const handleErrors = (errors) => {
    Object.keys(errors).map(key => {
      let [customerConfigId, field] = key.split(" ");
      if(!field) return;
      setSingleCustomer(customerConfigId);
    });
  }

  const updateCustomers = (values) => {
    setSingleCustomer(null);
    setSelectedCustomers(values);
  }

  if(!customerDetails) return null;

  return (
    <>
      <SubHeader
        leftSideElement={
          <>
            <h2 className="font-semibold text-gray-600">Users</h2>
          </>
        }
      />
      <Form
        onCancel={() => {}}
        onSubmit={handleSubmit}
        formRef={form}
        onError={handleErrors}
      >
        <div className="lg:p-3">
          <div className="flex flex-col lg:flex-row">
            <div className="lg:mr-3 w-full lg:border-r border-stone-200 lg:pr-6 lg:w-1/2 lg:mb-0 mb-8 lg:pb-0 pb-8 lg:border-b-0 border-b ">
            <h2 className="font-bold text-lg mb-4">Details</h2>
              <FormItems
                items={[
                  {
                    name: 'EmailAddress',
                    label: 'User Email',
                    type: FormType.EMAIL,
                    placeholder: 'User Email',
                    disabled: !!customerDetails.EmailAddress,
                    defaultValue: customerDetails.EmailAddress,
                    className: 'mb-4',
                    required: true,
                  },
                  {
                    name: 'FirstName',
                    label: 'First Name',
                    type: FormType.TEXT,
                    placeholder: 'First Name',
                    defaultValue: customerDetails.FirstName,
                    className: 'mb-4',
                    required: true,
                  },
                  {
                    name: 'LastName',
                    label: 'Last Name',
                    defaultValue: customerDetails.LastName,
                    type: FormType.TEXT,
                    placeholder: 'Last Name',
                    className: 'mb-4',
                    required: true,
                  },
                ]}
              />
            <div className="sm:flex w-full mb-4">
              <div className="w-full sm:mb-0 mb-4">
              <FormItem
                formDef={{
                  name: 'PhoneNumber',
                  label: 'Phone Number',
                  defaultValue: customerDetails.PhoneNumber,
                  type: FormType.TEXT,
                  placeholder: 'Phone Number',
                  mask: InputMask.PHONE,
                }}
              />
              </div>
              <div className="w-full sm:ml-4 sm:max-w-[33%]">
                <FormItem
                  formDef={{
                    name: 'Extension',
                    label: 'Ext.',
                    maxLength: 10,
                    defaultValue: customerDetails.Extension,
                    type: FormType.TEXT,
                    placeholder: 'Ext.',
                  }}
                />
              </div>
            </div>
              { userInfo?.Tokens?.includes("CAN_MANAGE_SUPER_ADMIN_USER") && <FormItem
                formDef={{
                  name: 'CanAccessCRM',
                  label: 'Can Access CRM.',
                  type: FormType.CHECKBOX,
                  placeholder: 'Ext.',
                  defaultValue: customerDetails?.CanAccessCRM,
                }}
              /> }
            </div>
            <div className="lg:w-1/2 lg:pl-6">
            <h2 className="font-bold text-lg mb-4">Customer Configurations</h2>
            <div className='customeSelectStyle'>
              { !customerId && <SelectCustomers
                defaultSelected={defaultSelected}
                setSelectedCustomers={updateCustomers}
                setCustomers={setCustomers}
                customers={customers}
              /> }
            </div>
            <div className="my-2">
              { (!isSelectAll && selectedCustomers.length > 0) && <FormItem
                key={Math.random() * 23} //Force Rerender to update options
                formDef={{
                  name: 'SingleCustomer',
                  label: 'Select Customer to Configure',
                  type: FormType.SELECT,
                  options: selectedCustomers,
                  disabled: isSelectAll,
                  value: singleCustomer ? [selectedCustomers.find(({value}) => value == singleCustomer)] : null,
                  optionsMultiSelect: false,
                  defaultValue: !!customerId ? selectedCustomers : null,
                  selectShowCheckboxes: false,
                  onChange: (value) => {
                    value = (isArray(value) && value.length > 0) ? value[0].value : value;
                    setSingleCustomer(value);
                  }
                }}
              /> }
            </div>
            {isSelectAll ?
              <CustomerConfig show={true} roles={roles} customer={null} defaultValues={customerDetails?.CustomerUserConfigurations?.find(c => c.CustomerId === -1)}/> :
              selectedCustomers.map(customer => {
              let isShown = singleCustomer === customer?.value;
              let defaultValues = customerDetails.CustomerUserConfigurations?.find(details => details.CustomerId === parseInt(customer?.value));
              let thisCustomer = customerId ? customerDetails : customers.find(({Id}) => parseInt(Id) === parseInt(customer?.value));
              return <CustomerConfig
                  key={customer.value}
                  show={isShown}
                  customer={thisCustomer}
                  defaultValues={defaultValues}
                  roles={roles}/>
              })}
            </div>
          </div>
          <div className="w-full flex items-end justify-end mt-4">
            <CancelBtn className="px-4 py-1 rounded mr-2" ghost onClick={() => {
              navigate('/admin/Users')
            }}>Cancel</CancelBtn>
            <SubmitBtn className="px-4 py-1 rounded" disabled={isSaving} loading={isSaving}>Save</SubmitBtn>
          </div>
        </div>
      </Form>
    </>
  );
};
