import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import {
//   hideLoader,
//   showLoader,
// } from '../../../../store/loading/loading.action';
import { Api } from '../../../../shared/api/api';
import { ReactSelectCompo } from '../../../../components/ui/react-select/react-select';
import * as _ from 'lodash';
import {
  Form,
  FormItems,
  FormRef,
  FormType,
  SubmitBtn,
} from '../../../../components/form';
import DisplayProduct from '../display-product';
import { showNotification } from '../../../../store/notification/notification.action';
import FeedbackForm from '../../feedback-form/feedback-form';
import { TENANT } from '../../../../constant/constant';
import { ComponentLoading } from '../../../../shared/component-loader/component-loading';
import { useUser } from '../../../../context/UserContext';

const ProductSearch = () => {
  const feedBackFormModalRef = useRef(null);
  const getHelpNowFormModalRef = useRef(null);
  const searchNonContractItemsForm: FormRef = useRef(null);
  let { equipmentID } = useParams();
  const dispatch = useDispatch();
  const tenant = (
    localStorage.getItem('TenantName') || 'flexprint'
  ).toLowerCase();
  const { customerInfo } = useUser();
  const [recordTotalLength, setRecordTotalLength] = useState(0);
  const [isRequestServiceBtn, setIsRequestServiceBtn] = useState(true);
  const [showAllItems, setShowAllItems] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const [noResultFound, setNoResultFound] = useState(false);
  const [searchValues, setSearchValues] = useState(null);
  const [productList, setProductList] = useState([]);
  const [filteredEquipmentData, setFilteredEquipmentData] = useState([]);
  const [productSearchData, setProductSearchData] = useState({
    CustomerId: customerInfo.CustomerId,
    EquipmentName: null,
    ModelID: null,
    ItemName: null,
    WarehouseId: customerInfo.WarehouseID,
    MfgID: null,
    SearchBy: null,
  });
  const [equipmentObject, setEquipmentObject] = useState<any>({});
  const [getCartItemsSearchQuery, setGetCartItemsSearchQuery] = useState({});
  if (sessionStorage.getItem('UpdateUser')) {
    sessionStorage.clear();
    window.location.reload();
  }
  const setSearchValue = (type: string) => {
    if (type === 'equipmentName') {
      setIsRequestServiceBtn(true);
      return productSearchData.EquipmentName;
    } else if (type === 'productName') {
      setIsRequestServiceBtn(false);
      return productSearchData.ItemName;
    } else {
      setIsRequestServiceBtn(false);
      return false;
    }
  };
  const resetAllForms = (searchType: string) => {
    if (searchType === 'equipmentName') {
      setProductSearchData({
        ...productSearchData,
        MfgID: null,
        SearchBy: 'equipmentName',
      });
      // this.proNameForm.resetForm();
    } else if (searchType === 'productName') {
      setProductSearchData({
        ...productSearchData,
        MfgID: null,
        SearchBy: 'productName',
      });
    } else if (searchType === 'all') {
      setProductSearchData({
        ...productSearchData,
        MfgID: null,
        SearchBy: 'all',
      });
      // this.proNameForm.resetForm();
    } else {
      // this.proNameForm.resetForm();
    }
  };
  const searchProductValidation = (searchType: string) => {
    if (searchType === 'equipmentName') {
      setProductSearchData({
        ...productSearchData,
        ModelID: null,
        ItemName: null,
        SearchBy: 'equipmentName',
      });
      return true;
    } else if (searchType === 'productName') {
      setProductSearchData({
        ...productSearchData,
        ModelID: null,
        EquipmentName: null,
        SearchBy: 'productName',
      });
      setEquipmentObject(null);
      return true;
    } else if (searchType === 'all') {
      setProductSearchData({
        ...productSearchData,
        ModelID: null,
        EquipmentName: null,
        ItemName: null,
        SearchBy: 'all',
      });
      setEquipmentObject(null);
      return true;
    }
  };
  const searchProduct = async (form: any) => {
    if (searchProductValidation(productSearchData.SearchBy) && form.valid) {
      // dispatch(showLoader());
      setTimeout(() => {
        resetAllForms(productSearchData.SearchBy);
      }, 0);
      const searchValue = setSearchValue(productSearchData.SearchBy);
      setSearchResult(false);
      setShowAllItems(false);
      setNoResultFound(false);
      try {
        const response = await Api.callAPI({
          url: `/api/v1/products/search`,
          method: `post`,
          body: { ...productSearchData },
        });
        if (response && response.data && response.data.length) {
          setSearchValues(searchValue);
          setRecordTotalLength(response.data.length);
          setSearchResult(true);
          setNoResultFound(false);
          const productLists = response.data;
          if (tenant === 'marimon') {
            _.each(productLists, (product) => {
              if (
                product.ItemDescription.toString().toLowerCase().match('cyan')
              ) {
                product.tonerClass = 'cyan';
              } else if (
                product.ItemDescription.toString().toLowerCase().match('black')
              ) {
                product.tonerClass = 'black';
              } else if (
                product.ItemDescription.toString()
                  .toLowerCase()
                  .match('magenta')
              ) {
                product.tonerClass = 'magenta';
              } else if (
                product.ItemDescription.toString().toLowerCase().match('yellow')
              ) {
                product.tonerClass = 'yellow';
              }
            });
          } else {
            _.each(productLists, (product) => {
              if (
                product.CatDescription.toString()
                  .toLowerCase()
                  .match('toner cyan')
              ) {
                product.tonerClass = 'cyan';
              } else if (
                product.CatDescription.toString()
                  .toLowerCase()
                  .match('toner black')
              ) {
                product.tonerClass = 'black';
              } else if (
                product.CatDescription.toString()
                  .toLowerCase()
                  .match('toner magenta')
              ) {
                product.tonerClass = 'magenta';
              } else if (
                product.CatDescription.toString()
                  .toLowerCase()
                  .match('toner yellow')
              ) {
                product.tonerClass = 'yellow';
              }
            });
            _.each(productLists, (product) => {
              if (
                product.CatDescription.toString()
                  .toLowerCase()
                  .match('ink cyan')
              ) {
                product.inkClass = 'cyan';
              } else if (
                product.CatDescription.toString()
                  .toLowerCase()
                  .match('ink black')
              ) {
                product.inkClass = 'black';
              } else if (
                product.CatDescription.toString()
                  .toLowerCase()
                  .match('ink magenta')
              ) {
                product.inkClass = 'magenta';
              } else if (
                product.CatDescription.toString()
                  .toLowerCase()
                  .match('ink yellow')
              ) {
                product.inkClass = 'yellow';
              }
            });
          }
          _.each(productLists, (product) => {
            product.availableQty = [];
            for (let i = 1; i <= 10; i++) {
              product.availableQty.push(i);
            }
            product.Quantity = product.Quantity || 1;
          });
          setProductList(productLists);
        } else {
          setSearchResult(false);
          setNoResultFound(true);
        }
        // dispatch(hideLoader());
      } catch (error) {
        dispatch(
          showNotification({
            message: 'Error while getting search products.',
            type: 'error',
          }),
        );
        // dispatch(hideLoader());
      }
    }
  };

  const setMultiSelectValue = (object: any) => {
    if (object && Object.keys(object).length) {
      return [
        {
          label: object.EquipmentNumber,
          value: object.EquipmentID,
        },
      ];
    } else {
      return [];
    }
  };

  const getEquipmentsByCustomer = async () => {
    // dispatch(showLoader());
    const locationID =
      customerInfo.RestrictUserByLocationID &&
      customerInfo.RestrictUserByLocationID.length
        ? customerInfo.RestrictUserByLocationID.toString()
        : null;
    try {
      const response = await Api.callAPI({
        url: `/api/v1/equipments/customer/${customerInfo.CustomerId}`,
        method: `PUT`,
        body: { LocationID: locationID },
      });
      const equipmentData = response.data;
      if (
        !equipmentID &&
        !customerInfo.AllowRestrictRegion &&
        customerInfo.RestrictUserByLocationID &&
        customerInfo.RestrictUserByLocationID.length === 1 &&
        equipmentData.length === 1
      ) {
        setEquipmentObject(equipmentData[0]);
        setProductSearchData({
          ...productSearchData,
          EquipmentName: equipmentData[0].EquipmentNumber,
          SearchBy: 'equipmentName',
        });
        await searchProduct({ valid: true });
      }
      
      if (equipmentID) {
        const filterEquipments = equipmentData?.find((equipment: any) => equipment.EquipmentID === parseInt(equipmentID))
        setEquipmentObject(filterEquipments);
        setProductSearchData({
          ...productSearchData,
          EquipmentName: filterEquipments?.EquipmentNumber,
          SearchBy: 'equipmentName',
        });
        setMultiSelectValue(
          _.find(equipmentData, { EquipmentNumber: filterEquipments?.EquipmentNumber }),
        );
        await searchProduct({ valid: true });
      }
      const filterResponse = response.data.map((data) => {
        return {
          label: data.EquipmentNumber,
          value: data.EquipmentID,
          optionalData: data,
        };
      });
      setFilteredEquipmentData(filterResponse);
      // dispatch(hideLoader());
    } catch (error) {
      dispatch(
        showNotification({
          message: 'Error while getting equipments.',
          type: 'error',
        }),
      );
      // dispatch(hideLoader());
    }
  };

  const openFeedBackForm = () => {
    if (feedBackFormModalRef.current) {
      feedBackFormModalRef.current.openFeedBackForm();
    }
  };

  const openGetHelpForm = () => {
    if (getHelpNowFormModalRef.current) {
      getHelpNowFormModalRef.current.openFeedBackForm();
    }
  };

  useEffect(() => {
    (async () => {
      setGetCartItemsSearchQuery({
        ...getCartItemsSearchQuery,
        EmailAddress: customerInfo.EmailAddress,
        CustomerId: customerInfo.CustomerId,
      });
      await getEquipmentsByCustomer();
      // setProductSearchData({
      //   ...productSearchData,
      //   CustomerId: customerInfo.CustomerId,
      //   WarehouseId: customerInfo.WarehouseID,
      // });
    })()

  }, []);

  const customFilter = (filterOptions, filter) => {
    if (!filter) {
      return filterOptions;
    }

    const re = new RegExp(filter, 'i');
    return filterOptions.filter(
      ({ optionalData }) =>
        (optionalData && optionalData?.EquipmentNumber.match(re)) ||
        (optionalData && optionalData?.SerialNumber.match(re)) ||
        (optionalData && optionalData?.Model.match(re)) ||
        (optionalData && optionalData?.Description.match(re)) ||
        (optionalData && optionalData?.Location.match(re)),
    );
  };

  const ItemRender = ({ option, onClick, disabled }) => {
    return (
      <div
        className={`w-full border-b-[1px] p-1 ${disabled ? 'disabled' : ''} ${
          disabled ? 'disabled' : ''
        }`}
      >
        {option.optionalData ? (
          <div
            className="text-xs w-full"
            onClick={(e) => {
              e.stopPropagation();
              if (!disabled) {
                onClick();
              }
            }}
            tabIndex={-1}
          >
                <div className="font-bold">Equipment Number: <span className="font-normal">{option.optionalData.EquipmentNumber}</span></div>
                <div className="font-bold">Serial Number: <span className="font-normal">{option.optionalData.SerialNumber}</span></div>
                <div className="font-bold">Model: <span className="font-normal">{option.optionalData.Model}</span></div>
                <div className="font-bold">Location: <span className="break-all font-normal">{option.optionalData.Location}</span></div>
                <div className="font-bold">Description: <span className="break-all font-normal">{option.optionalData.Description}</span></div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  const getFrequentlyBroughtItems = async () => {
    // dispatch(showLoader());
    const frequentlyPurchasedItemObj = {
      CustomerId: customerInfo.CustomerId,
      WarehouseID: customerInfo.WarehouseID,
      EmailAddress: customerInfo.EmailAddress,
    };
    try {
      const response = await Api.callAPI({
        url: `/api/v1/products/orderHistory`,
        method: `post`,
        body: frequentlyPurchasedItemObj,
      });
      if (response && response.data && response.data.length) {
        setRecordTotalLength(response.data.length);
        setSearchResult(true);
        setNoResultFound(false);
        setSearchValues('Frequently Purchased Items');
        _.each(response.data, (product) => {
          product.availableQty = [];
          for (let i = 1; i <= 10; i++) {
            product.availableQty.push(i);
          }
          product.Quantity = product.Quantity || 1;
        });
        setProductList(response.data);
      } else {
        setSearchResult(false);
        setNoResultFound(true);
      }
    } catch (error) {
      dispatch(
        showNotification({
          message: 'Error while getting frequently purchased items',
          type: 'error',
        }),
      );
    }
    // dispatch(hideLoader());
  };

  useEffect(() => {
    searchProduct({ valid: true }).then();
  }, [
    productSearchData.SearchBy,
    productSearchData.EquipmentName,
    productSearchData.ItemName,
  ]);

  return (
    <>
      <FeedbackForm ref={feedBackFormModalRef} from={'customerCareFeedback'} title={'Customer Care'} />
      <FeedbackForm
        ref={getHelpNowFormModalRef}
        from={'customerCareFeedback'}
        title={'Customer Care'}
      />
      <div className=" border-b border-gray-200 pb-4 mb-4">
        <h1 className="text-2xl font-medium">Order Products</h1>
      </div>

      { (!!noResultFound || !!filteredEquipmentData.length) ? <div className="md:flex md:mb-0 mb-2 w-full gap-6 h-full">
        <div className="md:w-1/3 h-full">
          <div className="bg-gray-100 rounded-lg lg:p-6 p-4 h-full flex flex-col justify-between 55555555555555555555555555555555555555555">
            <div>
              {tenant !== TENANT.FLEXPRINT &&
              customerInfo.SearchType !== 'COC-TR' ? (
                <div className="mb-4 selectCustomBox">
                  <Form
                    onSubmit={(event: any) => {
                      if (event && event.productName) {
                        setProductSearchData({
                          ...productSearchData,
                          EquipmentName: null,
                          ItemName: event.productName,
                          SearchBy: 'productName',
                        });
                      }
                    }}
                    formRef={searchNonContractItemsForm}
                  >
                    <FormItems
                      items={[
                        {
                          name: 'productName',
                          label: 'Product Name',
                          type: FormType.TEXT,
                          placeholder: 'Product Name',
                          required: false,
                          value: productSearchData.ItemName,
                          defaultValue: productSearchData.ItemName,
                        },
                      ]}
                    />
                    <div className="text-right mt-4">
                      <SubmitBtn className="commonBtn">Search</SubmitBtn>
                    </div>
                  </Form>
                </div>
              ) : (
                ''
              )}
              {filteredEquipmentData && filteredEquipmentData.length ? (
                <div className="mb-4 selectCustomBox">
                  <label className="text-sm text-gray-600 mb-2">
                    Device Search
                  </label>
                  <ReactSelectCompo
                    name={'DeviceSearch'}
                    label={'DeviceSearch'}
                    labelledBy={'DeviceSearch'}
                    hasFilterSelectAll={false}
                    hasSelectAll={false}
                    isCheckboxVisible={false}
                    isSingleSelect={true}
                    lazyLoadOptions={{
                      perPageData: 40,
                    }}
                    options={filteredEquipmentData}
                    onChange={(event: any) => {
                      if (event && event.length) {
                        setProductSearchData({
                          ...productSearchData,
                          EquipmentName: event[0].label,
                          ItemName: null,
                          SearchBy: 'equipmentName',
                        });
                        setEquipmentObject(event[0].optionalData);
                        if (
                          searchNonContractItemsForm.values &&
                          searchNonContractItemsForm.values.productName
                        ) {
                          searchNonContractItemsForm.clearInputs([
                            'productName',
                          ]);
                        }
                      } else {
                        setEquipmentObject({});
                      }
                    }}
                    value={setMultiSelectValue(equipmentObject)}
                    ItemRenderer={ItemRender}
                    filterOptions={customFilter}
                  />
                </div>
              ) : (
                ''
              )}
              {customerInfo.SearchType === 'COC-TR' ? (
                <Form
                  onSubmit={(event: any) => {
                    if (event && event.productName) {
                      setProductSearchData({
                        ...productSearchData,
                        EquipmentName: null,
                        ItemName: event.productName,
                        SearchBy: 'productName',
                      });
                    }
                  }}
                  formRef={searchNonContractItemsForm}
                >
                  <FormItems
                    items={[
                      {
                        name: 'productName',
                        label: 'Search Non-Contract Items',
                        type: FormType.TEXT,
                        placeholder: 'Product Name',
                        required: false,
                        value: productSearchData.ItemName,
                        defaultValue: productSearchData.ItemName,
                      },
                    ]}
                  />
                  <div className="text-right mt-4">
                    <SubmitBtn className="commonBtn">Search</SubmitBtn>
                  </div>
                </Form>
              ) : (
                ''
              )}
            </div>
            <div className="text-center pt-6 border-t border-gray-300 mt-6">
              <div className=" flex flex-wrap justify-center text-sm text-gray-700">
                <label className="mr-2">Can't find an item?</label>
                {customerInfo.SearchType === 'TR' ||
                customerInfo.SearchType === 'COC-TR' ? (
                  <Link to={'/product/view-all-items'} className="linkText">
                    View All Items
                  </Link>
                ) : (
                  ''
                )}
              </div>

              <label className='text-sm'>
                <button
                  className="linkText mr-2"
                  onClick={() => {
                    openFeedBackForm();
                  }}
                >
                  Click here
                </button>
                <span className="text-gray-600">to contact customer care</span>
              </label>

              {customerInfo.CustomerId === '57448' ? (
                <label>
                  <button onClick={getFrequentlyBroughtItems}>
                    Frequently Purchased Items
                  </button>
                </label>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>

        {searchResult ? (
          <div className="md:w-2/3  md:mt-0 mt-6">
            <div>
              <div className={'search-result'}>
                <h3 className="mb-10 text-base text-gray-600 font-semibold">
                  Showing {showAllItems ? 'all items' : ''}{' '}
                  {recordTotalLength === 0 ? recordTotalLength : 1} -{' '}
                  {recordTotalLength} of {recordTotalLength}{' '}
                  {showAllItems ? '' : 'results for '}
                  <span className="text-gray-900">{searchValues}</span>
                </h3>
                <DisplayProduct
                  products={productList}
                ></DisplayProduct>
                {isRequestServiceBtn ? (
                  <div className="text-center md:w-9/12 w-full md:px-0 px-4 m-auto">
                    <p className="mb-4 text-gray-600 mt-10">
                      Were you looking for a maintenance kit, imaging kit, or
                      other service item that's not listed here? Place a service
                      request using the button below
                    </p>
                    <Link
                      to={'/service/request/' + equipmentObject?.EquipmentID}
                      className="borderedBtn linkText mt-4 inline-block"
                    >
                      Request Service
                    </Link>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        ) : (
          
            (searchResult !== null && !noResultFound) && 
            <div className="md:w-2/3  md:mt-0 mt-6">
              <ComponentLoading isShowLoader={true}/>
            </div>  
          
        )}

        {noResultFound ? (
          <div className="md:w-2/3 md:mt-0 mt-6 md:mb-0 mb-8">
            <div className="NoResultData">
              <div className="border-b border-gray-400 pb-6 mb-6 text-center">
                <div className="xl:w-9/12 xl:px-0 px-6 m-auto">
                  <h2 className="text-3xl mb-6">
                    Can't find what you were looking for?
                  </h2>
                  <p>
                    We didn't find any supplies that matched your search, but
                    we're here to help
                  </p>
                  <button
                    className="borderedBtn linkText mt-4 inline-block"
                    onClick={() => {
                      openGetHelpForm();
                    }}
                  >
                    Get Help Now
                  </button>
                  {customerInfo.SearchType === 'TR' ? (
                    <span>&nbsp;&nbsp;OR&nbsp;&nbsp;</span>
                  ) : (
                    ''
                  )}
                  {customerInfo.SearchType === 'TR' ? (
                    <Link
                      className="borderedBtn linkText mt-4 inline-block"
                      to={'/product/view-all-items'}
                    >
                      View All Items
                    </Link>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {isRequestServiceBtn ? (
                <div className="text-center  w-9/12 m-auto">
                  <p>
                    Were you looking for a maintenance kit, imaging kit, or
                    other service item that's not listed here? Place a service
                    request using the button below
                  </p>
                  <Link
                    to={'/service/request/' + equipmentObject.EquipmentID}
                    className="borderedBtn linkText mt-4 inline-block"
                  >
                    Request Service
                  </Link>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          ''
        )}
      </div> : <div className="flex justify-center items-center h-full">
        <ComponentLoading isShowLoader={true}/>
        </div>}
    </>
  );
};

export default ProductSearch;
