import { useSelector } from 'react-redux';
import { TickMarkIcon } from '../../assets/svg/tick-white-icon';
import React, { useEffect, useState } from 'react';
import { RootState } from '../../store/store';

export const ProgressStatusBar = ({ steps = [] }) => {
  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );

  const [stepValues, setStepValues] = useState(steps);

  useEffect(() => {
    setStepValues(steps);
  }, [steps]);

  return (
    <>
      <ol
        className={`flex items-center w-full mb-4 sm:mb-5 theme-${tenantName} `}
      >
        {stepValues.map(({ name, isActive }, index) => {
          return (
              <li
                className={`${
                  isActive ? 'activeBorder' : ' before:border-gray-300'
                } relative flex  items-center before:content-[''] before:w-full after:h-1 ${
                  index === 0
                    ? ''
                    : 'before:border-b w-full before:border-4'
                } before:inline-block`}
                key={'progress-status-bar' + index}
              >
                <div>
                  <div
                    className={`

                  ${isActive ? ' activeBgColor' : 'bg-gray-300'}
                  flex items-center justify-center w-10 h-10  rounded-full lg:h-12 lg:w-12 shrink-0`}
                  >
                    <TickMarkIcon />
                  </div>
                  <div
                    className={` ${
                      isActive ? 'font-medium' : ''
                    } absolute top-14 right-0 text-sm statusTitle `}
                  >
                    {name}
                  </div>
                </div>
              </li>
          );
        })}
      </ol>
    </>
  );
};
