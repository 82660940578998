import React, { useEffect, useState } from 'react';
import {
  isCheckDollerProcingNonZero,
  showMessage,
} from '../../../../utils/utils';
import { NumberInput } from '../../../../components/ui/NumberInput';
import { Link, useNavigate } from 'react-router-dom';
import { RedTrashIcon } from '../../../../assets/svg/red-trash-icon';
import { CART_MAX_LIMIT, CART_MIN_LIMIT } from '../../../../constant/constant';
import useClickOutside from '../../../../hooks/use-click-outside/use-click-outside';
import { Button } from '../../../../components/ui';
import { useUser } from '../../../../context/UserContext';
import { useCart } from '../../context/CartContext';

export const CartHeaderDialog = ({
  setCarDialog,
  sideBarClass,
  setSideBarClass,
  windowHeight,
}: any) => {
  const [windowH, setWindowH] = useState(windowHeight);

  const { customerInfo } = useUser();
  const { items, loading, attachColorClass, modifyQuantity, removeItem } = useCart();
  const totalCost = items.reduce((acc, item) => acc + item.Quantity * +item.Price, 0);
  const cartItems = items.map(attachColorClass);

  const navigate = useNavigate();

  const [cartSideBarHeight, setCartSideBarHeight] = useState(null);
  const [
    isViewCartAndCheckoutButtonDisable,
    setIsViewCartAndCheckoutButtonDisable,
  ] = useState(false);


  const NotItemsFoundCart = () => {
    return (
      <>
        {!cartItems?.length && !loading ? (
          <>
            <div className={'w-full h-full flex items-center justify-center'}>
              <div className={'text-center'}>
                <img
                  className="m-auto"
                  src={require('../../../../assets/images/img.png')}
                />
                <div className="mt-4">Your shopping cart is empty.</div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  const cartDialoRef = useClickOutside(() => {
    setSideBarClass('closeMenu');
    setTimeout(() => {
      setCarDialog(false);
    }, 1000);
  });

  useEffect(() => {
    const setCartSideBarDynamicHeight = () => {
      const calculatedSidebarHeight =
        windowH -
        document.getElementById('header')?.clientHeight +
        window.pageYOffset;
      setCartSideBarHeight(calculatedSidebarHeight);
    };
    window.addEventListener(
      'orientationchange',
      function () {
        setWindowH(window.innerHeight);
        setTimeout(() => {
          setCartSideBarDynamicHeight();
        }, 500);
      },
      false,
    );
    window.addEventListener('resize', function () {
      setWindowH(window.innerHeight);
      setTimeout(() => {
        setCartSideBarDynamicHeight();
      }, 500);
    });

    setCartSideBarDynamicHeight();
  }, []);

  return (
    <>
      <div
        ref={cartDialoRef}
        style={{
          height: `${
            cartSideBarHeight
              ? `${cartSideBarHeight}px`
              : `
           calc(100vh - ${
             document.getElementById('header')?.clientHeight
           }px + ${window.pageYOffset})

          `
          }`,
        }}
        className={`top-0 bottom-0 right-0 z-10 absolute cartSidebar w-400 shadow-lg bg-white ${sideBarClass}`}
      >
        <div className="flex flex-col h-full sm:pb-0 pb-4 ">
          {cartItems?.length ? (
            <>
              <div className="p-4 border-b border-gray-200 mb-4 flex items-center justify-between">
                <h2 className="text-base text-gray-900">
                  <span className="font-semibold">
                    Shopping Cart ({cartItems?.length})
                  </span>
                </h2>
                <Link
                  to={'/customer/cart'}
                  className="linkText text-xs cursor-pointer"
                >
                  View All
                </Link>
              </div>

              <div className="p-4 flex flex-col h-full overflow-y-auto cartItemBox [&>*:last-child]:border-0">
                {cartItems.map((cItem) => {

                  const {
                    ItemDescription,
                    EquipmentNumber,
                    Price,
                    Quantity,
                    tonerClass,
                  } = cItem;
                    return (
                      <div
                        className="cartItem mb-4 pb-4 border-b border-gray-200"
                        key={cItem._id}
                      >
                        <div className="flex justify-between">
                          <div className="flex mr-4">
                            <div className="flex mt-1">
                              <div className="w-4 mr-2 ">
                                {tonerClass ? (
                                  <div
                                    className="w-4 h-4 rounded-full ItemDescription mt-1"
                                    style={{
                                      backgroundColor: tonerClass,
                                    }}
                                  ></div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                            <div className="proTitle text-sm font-semibold text-gray-800 break-all">
                              {ItemDescription}
                            </div>
                          </div>
                            <button
                              id={`remove${cItem._id}`}
                              className="cursor-pointer"
                              title="Remove Cart Icon"
                              onClick={async () => {
                                await removeItem(cItem);
                              }}
                            >
                              {' '}
                              <RedTrashIcon />
                            </button>
                        </div>
                        {EquipmentNumber ? (
                          <div className="ml-6 text-sm mt-2 text-gray-600">
                            EQ# {EquipmentNumber}
                          </div>
                        ) : (
                          ''
                        )}

                        <div className="flex items-center justify-between mt-4">
                          <div className="">
                              <button
                                className="text-2xl text-gray-900"
                                onClick={() => modifyQuantity(cItem, cItem.Quantity - 1)}
                              >
                                -
                              </button>
                            <NumberInput
                              value={Quantity}
                              decimalScale={0}
                              allowNegative={false}
                              className={'form-control no-spinners mx-2 border border-gray-400 text-gray-900 rounded-md w-10 text-center'}
                            onBlur={(e) => {
                              if(!e.target.value || parseFloat(e.target.value) === 0) {
                                removeItem(cItem);
                              } else {
                                modifyQuantity(cItem, e.target.value);
                              }
                            }}
                            allowedDecimalSeparators={false}
                            isAllowed={(values) => {
                              const { floatValue, value } = values;
                              return value === '' || (floatValue >= CART_MIN_LIMIT  && floatValue <= CART_MAX_LIMIT);
                            }}
                          />
                            <button
                              id={`increment${cItem._id}`}
                              className="text-2xl text-gray-900"
                              onClick={() => {
                                  if (cItem.Quantity < CART_MAX_LIMIT) {
                                    if(isViewCartAndCheckoutButtonDisable) {
                                      setIsViewCartAndCheckoutButtonDisable(false);
                                    }
                                    modifyQuantity(cItem, cItem.Quantity + 1);
                                  }
                              }}
                            >
                              +
                            </button>
                        </div>

                          {!(
                            customerInfo?.HideZeroDollarPricing &&
                            !isCheckDollerProcingNonZero(cItem.Price)
                          ) ? (
                            <div className="text-base font-semibold text-gray-800">
                              {' '}
                              ${Price}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    );
                  },
                )}
              </div>

            <div className="bg-white p-4 border-t border-gray-200 w-full">
              <div className="mb-4 flex justify-between">
                <div className=" text-gray-800">
                  {cartItems?.length} { cartItems?.length > 1 ? 'Items' : 'Item' }
                </div>
                {!(
                  customerInfo?.HideZeroDollarPricing &&
                  !isCheckDollerProcingNonZero(totalCost.toFixed(2))
                ) ? (
                  <div className=" text-gray-800 font-semibold">
                    Total : {totalCost.toFixed(2)}
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="flex justify-end">
                <button
                  onClick={() => {
                    if (!loading) {
                      navigate('/customer/cart');
                    }
                  }}
                  id='continueShopping'
                  className={`borderedBtn hover:text-black`}
                  // disabled={isViewCartAndCheckoutButtonDisable}
                >
                  <div className={`text-sm`}>View Cart</div>
                </button>
                <Button
                  id='checkout'
                  loading={loading}
                  className="px-3 py-1 rounded ml-3"
                  onClick={() => {
                    if(isViewCartAndCheckoutButtonDisable) {
                      showMessage({
                        message: 'Please enter valid quantity',
                        type: 'error',
                      });
                    } else {
                      navigate('/customer/checkout'); 
                    }
                  }}
                >
                  Checkout
                </Button>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
        <NotItemsFoundCart />
      </div>
    </div>
    </>
  );
};
