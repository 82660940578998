import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Layout } from './layout';
import { FaviconTitleManager } from './favicon-title-manager/favicon-title-manager';
import ScrollToTop from '../../shared/scrollToTop/scrollToTop';
import { Auth0Provider } from '@auth0/auth0-react';
import { UserProvider } from '../../context/UserContext';
import { MessagesProvider } from '../../context/MessagesContext';
import { withoutHeaderUrls } from './utils/constants';
import { CustomerProvider } from './context/CustomerContext';
import { CartProvider } from './context/CartContext';

const hostName = window.location.hostname;

//defaulted to dev env
let auth0Settings = {
  domain: 'flexprintinc.us.auth0.com',
  clientId: 'EJ4uKr61H8JCkPwRZ0tKPkRQjfRKQIkQ',
  audience: 'https://flexlayerstaging.flextg.com'
}

//TODO: use ENV Variables
switch (true) {
  case hostName.includes('localhost'):
  case hostName.includes('dev'):
  case hostName.includes('test'):
    auth0Settings =  { 
      audience:'https://flexlayerstaging.flextg.com',
      domain: 'flexprintinc.us.auth0.com',
      clientId: 'EJ4uKr61H8JCkPwRZ0tKPkRQjfRKQIkQ'
    }
    break;
  case hostName.includes('my.') || hostName.includes('portal.'):
    auth0Settings =  { 
      audience:'https://flexlayer.flextg.com',
      domain: 'flexprint.us.auth0.com',
      clientId: 'u9Mur1kJjnKTgVZmCfpcJnyhtxN5ftcJ'
    }
    break;
  default:
    break;
}

export const Client = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const withoutLayout = withoutHeaderUrls.some((url) => pathname.includes(url));
  const [params] = useSearchParams();
  const navigateToAuthentication = !!params.get('code');
  const navigateToMigration = params.get('error_description') === 'MIGRATION_PASSWORD_RESET_REQUIRED';



  // error_description=MIGRATION_PASSWORD_RESET_REQUIRED&state=Wjd%2BbEY2SmFRYVhjeW42dHBhTnFvaFl2MFdmUlZwTlh1R0d4ZnBZZGhDaQ%3D%3D

  //This is to check for the auth0 cookie (passed from auth0 on idp initiated login, and the code passed from auth0 on SP initiated login
  //tbh I really like this approach, there has to be something better no?
  useEffect(() => {
    if( window.location.pathname === '/' && navigateToMigration ) {
      return navigate('/auth-migration');
    }
    if( window.location.pathname === '/' && !params.get('connection') ) {
      navigate('/login');
    }
    if (navigateToAuthentication) {
      navigate('/authentication');
    }
  }, [])


  const renderComponent = () => {
    return (
      <Auth0Provider
        domain={auth0Settings.domain}
        clientId={auth0Settings.clientId}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: auth0Settings.audience,
        }}
      >
        <UserProvider>
          <MessagesProvider>

                <FaviconTitleManager />
                <ScrollToTop />
                <CustomerProvider>                  
                { withoutLayout ? 
                  <Outlet /> :   
                      <CartProvider>
                        <Layout>
                              <Outlet />
                        </Layout> 
                      </CartProvider>
                }
                </CustomerProvider>

            </MessagesProvider>
          </UserProvider>
        </Auth0Provider>
    );
  };

  return <>{renderComponent()}</>;
};
