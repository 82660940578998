import React, { useEffect, useMemo, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import './dashboard.scss';
import { OpenRequest } from './dashboard-widget/open-request/open-request';
import { OpenOrders } from './dashboard-widget/open-orders/open-orders';
import { Locations } from './dashboard-widget/locations/locations';
import storage from '../../../utils/storage/storage';
import { ClientStorageKey } from '../../../constant/constant';
import {
  generateRandomString,
} from '../../../utils/utils';
import { ResetIcon } from '../../../assets/svg/reset-icon';
import { useUser } from '../../../context/UserContext';
import { CustomerTokens } from '../../../types/Customer';

export const Dashboard = () => {
  const { customerInfo, hasPermission } = useUser();
  const [defaultLayout, setDefaultLayout] = useState(null);
  const [resetRefreshToken, setRefreshToken] = useState(null);
  const [layout, setLayout] = useState(null);
  type LayoutType = typeof layout[number];

  useEffect(() => {
    setDefaultLayout([
      {
        i: 'openRequestWidget',
        title: 'Open Requests',
        viewAllRouterLink: '/service/request-history',
        plusRouterLink: '/service/request',
        accessToken: ['CAN_PLACE_SERVICE_REQUEST'],
        x: 0,
        y: 0,
        w: 1,
        h: 3,
        minH: 3,
        maxH: 3,
        isHidden: !hasPermission(CustomerTokens.ViewServiceRequest),
      },
      {
        i: 'openOrdertWidget',
        title: 'Open Orders',
        viewAllRouterLink: '/customer/order-history',
        plusRouterLink: '/product/search',
        accessToken: ['CAN_PLACE_ORDER'],
        x:
          !hasPermission(CustomerTokens.ViewServiceRequest)
            ? 0
            : 1,
        y: 0,
        w: 1,
        h: 3,
        minH: 3,
        maxH: 3,
        isHidden: !hasPermission(CustomerTokens.ViewPlacedOrder),
      },
      {
        i: 'location',
        title: 'Locations',
        viewAllRouterLink: '/customer/locations',
        plusRouterLink: null,
        accessToken: null,
        x:
          (hasPermission(CustomerTokens.ViewServiceRequest) && !hasPermission(CustomerTokens.ViewPlacedOrder)) ||
          (!hasPermission(CustomerTokens.ViewServiceRequest) && hasPermission(CustomerTokens.ViewPlacedOrder))
            ? 1
            : 0,
        y:
          !hasPermission(CustomerTokens.ViewServiceRequest) &&
          !hasPermission(CustomerTokens.ViewPlacedOrder)
            ? 0
            : hasPermission(CustomerTokens.ViewServiceRequest) && hasPermission(CustomerTokens.ViewPlacedOrder)
            ? 3
            : 0,
        w:
          (hasPermission(CustomerTokens.ViewServiceRequest) && !hasPermission(CustomerTokens.ViewPlacedOrder)) ||
          (!hasPermission(CustomerTokens.ViewServiceRequest) && hasPermission(CustomerTokens.ViewPlacedOrder))
            ? 1
            : 2,
        h: 3,
        minH: 3,
        maxH: 3,
        isHidden: false,
      },
    ]);
  }, [customerInfo, resetRefreshToken]);

  useEffect(() => {
    if (defaultLayout !== null) {
      const layoutSettingFromStotage: any = storage.getJSONItem(
        ClientStorageKey.DashboardGridSettingsLayout,
      );
      if (layoutSettingFromStotage) {
        const layoutArr: Array<LayoutType> = [];
        layoutSettingFromStotage.map((props: LayoutType) => {
          const layoutObj = defaultLayout.find(({ i }: any) => i === props.i);
          layoutArr.push({
            ...layoutObj,
            ...props,
            isHidden: layoutObj?.isHidden,
          });
        });

        const filterLayout = layoutArr.filter(
          ({ isHidden }) => isHidden === false,
        );

        setLayout([...filterLayout]);
      } else {
        const filterLayout = defaultLayout.filter(
          ({ isHidden }) => isHidden === false,
        );
        setLayout([...filterLayout]);
      }
    }
  }, [defaultLayout]);

  const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), []);

  const expandContractGrid = (gridName: string) => {
    const changeLayout = [...layout];
    const layoutIndex = changeLayout.findIndex((prop) => prop.i === gridName);
    changeLayout[layoutIndex].w = changeLayout[layoutIndex].w === 1 ? 2 : 1;
    if (changeLayout[layoutIndex].w === 2) {
      const sideIndex = changeLayout.findIndex(
        (prop) => prop.y === changeLayout[layoutIndex].y && prop.i !== gridName,
      );
      if (sideIndex >= 0) {
        changeLayout[sideIndex].y = changeLayout[sideIndex].y + 3;
        changeLayout[sideIndex].x = 0;
        const anotherSideIndex = changeLayout.findIndex(
          (prop) =>
            prop.y === changeLayout[sideIndex].y &&
            prop.i !== changeLayout[sideIndex].i,
        );
        if (anotherSideIndex >= 0) {
          changeLayout[anotherSideIndex].y =
            changeLayout[anotherSideIndex].y + 3;
          changeLayout[anotherSideIndex].x = 0;
        }
      }
    }
    setLayout([...changeLayout]);
    storage.setJSONItem(
      ClientStorageKey.DashboardGridSettingsLayout,
      changeLayout,
    );
  };

  return (
    <>
      <div className={'dashboard-main'} key={customerInfo?.CustomerName}>
        <div className={'flex items-center justify-between md:mb-8 mb-4 mx-2'}>
          <div>
            <div className={'md:text-3xl sm:text-2xl text-lg'}>
              Welcome,{' '}
              <span className="font-bold">
                {customerInfo?.FirstName || ''} {customerInfo?.LastName || ''}{' '}
              </span>
            </div>
          </div>
          <div className="md:flex hidden">
            <button
              className={`primaryBtn bgColor`}
              onClick={() => {
                storage.removeItem(
                  ClientStorageKey.DashboardGridSettingsLayout,
                );
                setRefreshToken(generateRandomString(5));
              }}
            >
              {' '}
              <div className="sm:hidden flex">
                <ResetIcon />
              </div>
              <div className="sm:flex hidden">Reset Dashboard</div>
            </button>
          </div>
        </div>
        <div>
          {layout !== null ? (
            <ResponsiveGridLayout
              cols={{ lg: 2, md: 2, sm: 1, xs: 1, xxs: 1 }}
              draggableHandle=".drag-handle"
              //isBounded={true}
              isResizable={false}
              useCSSTransforms={true}
              onDragStop={(changedLayout) => {
                const changeLayout = [
                  ...layout.map((prop) => {
                    const filteredData = changedLayout?.find(
                      (l: any) => l.i === prop.i,
                    );
                    return {
                      ...prop,
                      w: filteredData.w,
                      x: filteredData.x,
                      y: filteredData.y,
                      h: filteredData.h,
                    };
                  }),
                ];
                setLayout([...changeLayout]);
                storage.setJSONItem(
                  ClientStorageKey.DashboardGridSettingsLayout,
                  changeLayout,
                );
              }}
            >
              {layout.map((prop) => {
                return !prop.isHidden ? (
                  <div className="" key={prop.i} data-grid={{ ...prop }}>
                    {prop.i === 'openRequestWidget' ? (
                      <OpenRequest
                        title={prop.title}
                        viewAllRouterLink={prop.viewAllRouterLink}
                        plusRouterLink={prop.plusRouterLink}
                        accessToken={prop.accessToken}
                        expandContractGrid={() => expandContractGrid(prop.i)}
                        isExpand={prop.w === 2}
                      />
                    ) : (
                      ''
                    )}
                    {prop.i === 'openOrdertWidget' ? (
                      <OpenOrders
                        title={prop.title}
                        viewAllRouterLink={prop.viewAllRouterLink}
                        plusRouterLink={prop.plusRouterLink}
                        accessToken={prop.accessToken}
                        expandContractGrid={() => expandContractGrid(prop.i)}
                        isExpand={prop.w === 2}
                      />
                    ) : (
                      ''
                    )}
                    {prop.i === 'location' ? (
                      <Locations
                        title={prop.title}
                        viewAllRouterLink={prop.viewAllRouterLink}
                        plusRouterLink={prop.plusRouterLink}
                        accessToken={prop.accessToken}
                        expandContractGrid={() => expandContractGrid(prop.i)}
                        isExpand={prop.w === 2}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                );
              })}
            </ResponsiveGridLayout>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};
