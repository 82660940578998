import { Link, useNavigate } from 'react-router-dom';
import React from 'react';

export const LocationsTableField = () => {
  const navigate = useNavigate();
  const filterParams = {
    filterOptions: ['contains'],
    defaultOption: 'contains',
    maxNumConditions: 1,
    debounceMs: 200,
  };
  return [
    {
      headerName: 'Name', // LocationName
      field: 'CustomerName',
      width: 250,
      filterParams: filterParams,
      cellRenderer: (options: any) => {
        return (
          <Link
            className="service-request-view linkText"
            to={`/customer/locations/${options.data.CustomerID}`}
          >
            {options.value}
          </Link>
        );
      },
      onCellClicked: (options: any) => {
        navigate('/customer/locations/' + options.data.CustomerID);
      },
    },
    {
      headerName: 'Devices',
      field: 'TotalEquipments',
      filterParams: filterParams,

      width: 120,
    },
    {
      headerName: 'Open Requests',
      field: 'TotalServiceRequests',
      filterParams: filterParams,

      width: 170,
    },
    {
      headerName: 'Open Orders',
      field: 'TotalOrders',
      filterParams: filterParams,
      width: 150,
    },
  ];
};
