// eslint-disable-next-line import/named
import axios, { Method } from 'axios';
import { getStore } from '../../store/store';
import { hideLoader, showLoader } from '../../store/loading/loading.action';
import { showMessage, trimStringsFromObjects } from '../../utils/utils';
import {
  AdminStorageKey,
  ClientStorageKey,
  TenantKey,
} from '../../constant/constant';
import storage from '../../utils/storage/storage';
import { cloneDeep, get } from 'lodash';

let apiOrigin; // TODO: clean up and strip into environment variables passed via amplify
let hostName = window.location.hostname;

switch (true) {
  case hostName.includes('localhost'):
    apiOrigin = 'http://localhost:9001';
    break;
  case hostName.includes('dev'):
    apiOrigin = 'https://flexlayerdev.flextg.com';
    break;
  case hostName.includes('test'):
    apiOrigin = 'https://flexlayerstaging.flextg.com';
    break;
  case hostName.includes('my.') || hostName.includes('portal.'):
    apiOrigin = 'https://flexlayer.flextg.com';
    break;
  default:
    apiOrigin = 'https://flexlayerdev.flextg.com';
    break;
}

const isAdmin = window.location.pathname.split('/')[1] === 'admin';

export interface IAPIOptions {
  url: string;
  method?: Method;
  queryParams?: any;
  body?: any;
  headers?: any;
  isLoading?: boolean;
  successMessage?: string;
  errorMessage?: string;
  isShowApiErrorMessage?: boolean;
}
const { dispatch } = getStore();
// const tenantName = useSelector(
//   (state: RootState) => state.tenantState.tenantName,
// );

const defaultHeaders = () => {
  let defaultHeader = {
    portal: 'fcp',
  };
  return defaultHeader;
};

const getUserInfo = () => {
  if (isAdmin) {
    if (storage.getItem(AdminStorageKey.userInfo)) {
      return storage.getJSONItem(AdminStorageKey.userInfo);
    }
  } else {
    if (storage.getItem(ClientStorageKey.customerInfo)) {
      return storage.getJSONItem(ClientStorageKey.customerInfo);
    }
  }
};

const storeTokenAndUserInfo = (response: any) => {
  response = response?.data || response;
  if(!response || !response?.accessToken || !response?.userInfo || !response?.refreshToken) return;
  if (isAdmin) {
    storage.setJSONItem(AdminStorageKey.userInfo, response.userInfo);
    storage.setItem(AdminStorageKey.ACCESS_TOKEN, response.accessToken);
    storage.setItem(AdminStorageKey.REFRESH_TOKEN, response.refreshToken);
  } else {
    storage.setJSONItem(ClientStorageKey.customerInfo, response.userInfo);
    storage.setItem(ClientStorageKey.ACCESS_TOKEN, response.accessToken);
    storage.setItem(ClientStorageKey.REFRESH_TOKEN, response.refreshToken);
  }
};
const defaultQueryParams = () => {
  const userInfo: any = getUserInfo();
  let queryParams: any = {
    __dc: Math.random(),
    email: isAdmin ? userInfo?.name : userInfo?.EmailAddress,
    //TODO: Set portalName queryParams for access logs
  };
  const portalName = storage.getItem(TenantKey);
  if (isAdmin && portalName) {
    queryParams.portalName = portalName;
  }

  return queryParams;
};
const defaultInstance = () => {
  return axios.create({
    // withCredentials: true,
  });
};

const showLoading = () => {
  dispatch(showLoader());
};
const checkLoginUrls = (Url: string) => {
  return (
    Url.indexOf('/login') > -1 ||
    Url.indexOf('/auth') > -1 ||
    Url.indexOf('/set-password') > -1 ||
    Url.indexOf('forgot-password') > -1
  );
};
const getAccessTokenFromLocalStorage = () => {
  const STORAGE_KEY = isAdmin ? AdminStorageKey : ClientStorageKey;
  return storage.getItem(STORAGE_KEY.ACCESS_TOKEN)
    ? 'Bearer ' + storage.getItem(STORAGE_KEY.ACCESS_TOKEN)
    : '';
};
const getRefreshTokenFromLocalStorage = () => {
  const STORAGE_KEY = isAdmin ? AdminStorageKey : ClientStorageKey;
  return storage.getItem(STORAGE_KEY.REFRESH_TOKEN)
    ? 'Bearer ' + storage.getItem(STORAGE_KEY.REFRESH_TOKEN)
    : '';
};

const getResponseData = (response: any) => {
  return { ...response, data: response?.data?.data || response?.data?.Data || response.data};
};

const logout = () => {
  const STORAGE_KEY = isAdmin ? AdminStorageKey : ClientStorageKey;
  const storageValues = Object.values(STORAGE_KEY);
  storageValues.map((val) => {
    storage.removeItem(val);
  });
};

const getResponseErrorName = (exception: any) => {
  const error = exception?.response?.data || exception?.response?.error?.Message;
  return (
    get(error, 'Error.error.error.Name') ||
    get(error, 'error.Error.error.Name') ||
    get(error, 'Error.error.Name') ||
    get(error, 'error.error.Name') ||
    get(error, 'error.Name')
  );
};
const ApiCall = async ({
  method = 'GET',
  apiUrl,
  body = null,
  queryParams = null,
  headers = null,
}: any) => {
  const axiosInstance = defaultInstance();
  try {
    const accessToken = getAccessTokenFromLocalStorage();
    //linkname should be the base url with subdomain, without http or https
    let linkname = window.location.origin.split('//')[1];
    if(linkname.includes('amplifyapp')) linkname = 'mydev.flextg.com';


    if(accessToken) headers.Accesstoken = accessToken;


    return await axiosInstance.request({
      method: method || 'GET',
      url: apiUrl,
      data: body,
      params: trimStringsFromObjects(queryParams),
      headers: {
        ...headers,
        linkname: linkname,
        'Access-Control-Allow-Origin': '*',
      },
    });
  } catch (e) {
    throw e;
  }
};
const redirectLogin = () => {
  window.location.href = isAdmin ? '/admin/login' : '/login';
};
const redirectMaintanance = () => {
  window.location.href = isAdmin ? '/admin/maintenance' : '/maintenance';
};
const generateTokenByRefreshToken = async (apiData: any) => {
  let apiUrl;
  const { headers } = apiData;
  try {
    const headersObj: any = headers;
    const url = isAdmin
      ? '/api/v1/users/generateToken/refreshToken'
      : '/api/v1/users/generateToken/refreshToken';
    if (!checkLoginUrls(url)) {
      headersObj.refreshToken = getRefreshTokenFromLocalStorage();
    }
    apiUrl = `${apiOrigin}${url}`;
    const res = await ApiCall({
      method: 'PUT',
      apiUrl: apiUrl,
      headers: headersObj,
      queryParams: {},
    });

    const response = getResponseData(res);
    storeTokenAndUserInfo(response);
    return response;
  } catch (e) {
    logout();
    redirectLogin();
    /* Not show error while refresh token expired */
    // throw e;
  }
};

// eslint-disable-next-line
const handleError = ({
  url,
  exception,
  errorMessage,
  userInfo,
  isShowApiErrorMessage,
}: any) => {
  const error = exception?.response?.data?.error || exception?.response?.data;
  if (error?.Code === '5005') {
    redirectMaintanance();
    return error;
  } else if (
    !isAdmin &&
    error?.Code === '1003' &&
    url?.indexOf('check-login') === -1 &&
    !userInfo?.isSSO
  ) {
    redirectLogin();
    return error;
  } else if (error?.Code === '5015') {
    redirectLogin();
    return error;
  } else {
    if (
      url === '/checkLogin' ||
      url === '/api/v1/users/forgotPassword' ||
      url === '/api/v1/users/login' ||
      url === '/api/v1/users/checkLogin' ||
      url === '/api/v1/users/admin/checkLogin'
    ) {
      return error;
    }
    if (error && isShowApiErrorMessage) {
      if (error?.Message.indexOf('{') > -1) {
        const msg = error?.Message;
        errorMessage = error?.Message.substring(0, msg.indexOf('.') + 1);
      } else {
        errorMessage = error?.Message;
      }
    }
    if (errorMessage) {
      showMessage({ type: 'error', message: errorMessage });
    }
  }
  return error;
};

export const Api = async (apiData: IAPIOptions): Promise<any> => {
  // //if pending request is already there then return
  // if (pendingRequests[apiData.url] && !apiData.url.includes('checkLogin')) {
  //   return;
  // }

  let response: any;
  let apiUrl: any;
  let userInfo: any;

  let defaultHeader = defaultHeaders();

  let defaultQueryParam = defaultQueryParams();
  const {
    url,
    method = 'GET',
    body,
    isLoading = false,
    successMessage,
    errorMessage,
    isShowApiErrorMessage = true,
  } = apiData;
  apiData.headers = {};
  apiUrl = `${apiOrigin}${url}`;
  try {
    if (isLoading) {
      showLoading();
    }

    userInfo = !isAdmin ? getUserInfo() : null;
    Object.assign(apiData.headers, defaultHeader);
    const queryParams: any = apiData?.queryParams;
    Object.assign(queryParams, defaultQueryParam);

    if (!isAdmin && userInfo) {
      Object.assign(body, { email: userInfo?.EmailAddress });
    }
    if (!checkLoginUrls(url)) {
      Object.assign(apiData.headers, {
        accessToken: getAccessTokenFromLocalStorage(),
      });
    }

    const newQueryParams = cloneDeep(apiData.queryParams);
    for (let param in newQueryParams) {
      if (newQueryParams.hasOwnProperty(param)) {
        newQueryParams[param] = encodeURIComponent(newQueryParams[param]);
      }
    }

    response = await ApiCall({
      method,
      apiUrl,
      body: trimStringsFromObjects(body),
      queryParams: newQueryParams,
      headers: apiData.headers,
    });

    if (successMessage) {
      showMessage({ type: 'success', message: successMessage });
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return getResponseData(response);
  } catch (exception) {
    if (getResponseErrorName(exception) === 'TokenExpiredError') {
      try {
        const callApiParams: any = apiData;
        await generateTokenByRefreshToken(callApiParams);
        return await Api.callAPI(callApiParams);
      } catch (e) {
        const error = handleError({
          url,
          exception,
          errorMessage,
          userInfo,
          isShowApiErrorMessage,
        });
        throw error;
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const error = handleError({
        url,
        exception,
        errorMessage,
        userInfo,
        isShowApiErrorMessage,
      });
      throw error;
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  } finally {
    if (isLoading) {
      dispatch(hideLoader());
    }
  }
};

interface CallAPIProps {
  url: string;
  body?: object;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'get' | 'post' | 'delete' | 'put';
  queryParams?: object;
  options?: object;
  isAdmin?: boolean;
}
Api.callAPI = ({
  url,
  body = {},
  method,
  queryParams = {},
  options = {},
}: CallAPIProps) => Api({ url, method: method, queryParams, body, ...options });
