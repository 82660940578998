import React, { useRef, useState } from 'react';
import { CancelBtn, Form, FormItem, FormRef, FormType, SubmitBtn } from '../../../../components/form';
import { useUser } from '../../../../context/UserContext';
import { useFlexLayer } from '../../../../hooks/useFlexlayer';


const ChangePassword = () => {

  const { customerInfo } = useUser();
  let userId = customerInfo?.UserId;
  const formRef: FormRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { flexLayer } = useFlexLayer();

  const handleSubmit = async (formData) => {
    setIsSubmitting(true);
    await flexLayer(`/users/${userId}/changePassword`, {
      _id: userId,
      oldPassword: formData.oldPassword,
      newPassword: formData.password,
    });
    if(!!formRef) formRef.clear();
    setIsSubmitting(false);
  }

  return (
    <>
      <h1 className='mb-4 text-2xl font-medium'>Change Your Password</h1>
      <Form onSubmit={handleSubmit} className="max-w-[500px]" formRef={formRef}>
        <FormItem
          formDef={{
              name: 'oldPassword',
              label: 'Old Password',
              type: FormType.PASSWORD,
              placeholder: 'Old Password',
              required: true,
              className: 'mb-4',
          }}
        />
        <FormItem
          formDef={{
              name: 'password',
              label: 'New Password',
              type: FormType.PASSWORD,
              placeholder: 'New Password',
              required: true,
              minLength: 6,
              maxLength: 16,
              className: 'mb-4',
              //Should include 1 uppercase, 1 lowercase, 1 number, 1 special character.
              pattern: '(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$',
              errors: {
                pattern: 'Password must include 1 uppercase, 1 lowercase, 1 number, 1 special character'
            }
          }}
        />
        <FormItem
            formDef={{
                name: 'passwordMatch',
                label: 'Confirm Password',
                type: FormType.PASSWORD,
                placeholder: 'Confirm New Password',
                matchInput: 'password',
                minLength: 6,
                maxLength: 16,
                required: true,
                className: 'mb-4',
                errors: {
                    matchInput: 'Passwords do not match.'
                }
            }}
        />
        <div className="mt-4 w-full flex items-end justify-end">
            <CancelBtn ghost className="mr-2 rounded px-4 py-1">Cancel</CancelBtn>
            <SubmitBtn className="rounded px-4 py-1" loading={isSubmitting}>Update Password</SubmitBtn>
        </div>
      </Form>
    </>
  );
};

export default ChangePassword;
