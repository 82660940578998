import { EditIcon } from "../../../../../assets/svg/edit-icon";
import { Button } from "../../../../../components/ui"
import { DeleteUser } from "./delete-user/delete-user";
import { UpdatePassword } from "./update-password/update-password";
// import { Api } from "../../../../../shared/api/api";

// const deleteUser = async ({ userId }: any) => {
//     try {
//         await Api.callAPI({
//             url: `/api/customerusers/user/${userId}`,
//             method: 'delete',
//             options: {
//                 isLoading: true,
//                 showErrorMessage: false,
//             },
//         });
//     } catch (e) { }
// }

const filterParams = {
    filterOptions: ['contains'],
    defaultOption: 'contains',
    maxNumConditions: 1,
    debounceMs: 200,
  };

export const UserListColumns = [
    {
        headerName: 'First Name',
        field: 'FirstName',
        width: 150,
        filter: filterParams,
        sortable: false,
    },
    {
        headerName: 'Last Name',
        field: 'LastName',
        width: 150,
        filter: filterParams,
        sortable: false,
    },
    {
        headerName: 'Email',
        field: 'EmailAddress',
        width: 200,
        filter: filterParams,
        sortable: false,
    },
    {
        headerName: 'Phone Number',
        field: 'PhoneNumber',
        width: 200,
        filter: filterParams,
        sortable: false,
    },
    {
        headerName: 'Edit',
        field: 'edit',
        width: 100,
        filter: false,
        sortable: false,
        cellRenderer: (cell) => {
            const userid = cell.data._id;
            return <div className="flex h-[40px] items-center justify-center"><Button to={`/customer/user/edit/${userid}`} ghost className="h-full w-full flex items-center justify-center border-none">
                <EditIcon />
            </Button></div>
        }
    },
    {
        headerName: 'Delete',
        field: 'delete',
        width: 100,
        filter: false,
        sortable: false,
        cellRenderer: (cell) => <div className="flex h-[40px] items-center justify-center"><DeleteUser cell={cell}/></div>
    },
    {
        headerName: 'Password',
        field: 'password',
        width: 100,
        filter: false,
        sortable: false,
        cellRenderer: (cell) => <div className="flex h-[40px] items-center justify-center"><UpdatePassword cell={cell}/></div>
    },
      
]