// eslint-disable-next-line import/no-extraneous-dependencies
import ReactPaginate from 'react-paginate';
import { useEffect, useState } from 'react';

export const Pagination = ({ pageCount, handlePageClick, pageNumber }) => {
  const [selectedPage, setSelectedPage] = useState(1);

  useEffect(() => {
    const page = pageNumber - 1;

    setSelectedPage(page < 0 ? 0 : page);
  }, [selectedPage, pageNumber]);
  return (
    <>
      {Math.ceil(pageCount) > 1 && handlePageClick ? (
        <>
          <ReactPaginate
            onPageChange={handlePageClick}
            marginPagesDisplayed={1}
            pageRangeDisplayed={5}
            forcePage={selectedPage}
            pageCount={pageCount}
            previousLabel="« Previous"
            nextLabel="Next »"
            pageClassName="page-item rounded border border-gray-300 text-gray-900 text-sm"
            pageLinkClassName="page-link px-2 py-0.5"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination flex justify-end gap-4 items-center"
            activeClassName="active bgColor text-white border borderColor"
            disabledClassName=" text-gray-400"
            renderOnZeroPageCount={null}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
