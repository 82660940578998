import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import storage from '../../../../../utils/storage/storage';
import { ClientStorageKey } from '../../../../../constant/constant';
import {
  generateRandomString,
} from '../../../../../utils/utils';
import { Api } from '../../../../../shared/api/api';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { LocationOrder } from './location-widget/location-order/location-order';
import { LocationServicesDetails } from './location-widget/location-services-details/location-services-details';
import { LocationDevices } from './location-widget/location-device/location-device';
import { PushpinIcon } from '../../../../../assets/svg/pushpin-icon';
import { LocationContactIcon } from '../../../../../assets/svg/location-contact-icon';
import { BuildingIcon } from '../../../../../assets/svg/building-icon';
import { ResetIcon } from '../../../../../assets/svg/reset-icon';
import { RemovePopModal } from './removePopModal/removePopModal';
import { EditPopModal } from './editPopModal.tsx/editPopModal';
import { ComponentLoading } from '../../../../../shared/component-loader/component-loading';
import { useUser } from '../../../../../context/UserContext';
import { CustomerTokens } from '../../../../../types/Customer';

const LocationDetail = () => {
  const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), []);
  let { locationId } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [isAddRemoveLocation, setAddRemoveLocations] = useState(false);

  const { customerInfo, hasPermission } = useUser();
  const Tokens = customerInfo?.Tokens;

  const [locationData, setLocationData] = useState<any>(null);
  const [defaultLayout, setDefaultLayout] = useState(null);
  const [resetRefreshToken, setRefreshToken] = useState(null);
  const [layout, setLayout] = useState(null);
  const [phone, setPhone] = useState<any>(null);
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  useEffect(() => {
    setDefaultLayout([
      {
        i: 'Devices',
        x: 0,
        y: 0,
        w:
          !hasPermission(CustomerTokens.ViewServiceRequest) && !hasPermission(CustomerTokens.ViewPlacedOrder)
            ? 2
            : 1,
        h: 3,
        minH: 3,
        maxH: 6,
        title: 'Devices',
        viewAllRouterLink: null,
        plusRouterLink: null,
        isHidden: false,
      },
      {
        i: 'Service Requests',
        x: 1,
        y: 0,
        w: 1,
        h: 3,
        minH: 3,
        maxH: 6,
        title: 'Service Requests',
        viewAllRouterLink: null,
        plusRouterLink: null,
        isHidden: !hasPermission(CustomerTokens.ViewServiceRequest),
      },
      {
        i: 'Order',
        x:
          hasPermission(CustomerTokens.ViewServiceRequest)
            ? 0
            : 1,
        y:
          hasPermission(CustomerTokens.ViewServiceRequest)
            ? 3
            : 0,
        w:
          hasPermission(CustomerTokens.ViewServiceRequest)
            ? 2
            : 1,
        h: 3,
        maxH: 6,
        minH: 3,
        title: 'Order',
        viewAllRouterLink: null,
        plusRouterLink: null,
        isHidden: !hasPermission(CustomerTokens.ViewPlacedOrder),
      },
    ]);
  }, [customerInfo, resetRefreshToken]);

  const expandContractGrid = (gridName: string) => {
    const changeLayout = [...layout];
    const layoutIndex = changeLayout.findIndex((prop) => prop.i === gridName);
    changeLayout[layoutIndex].w = changeLayout[layoutIndex].w === 1 ? 2 : 1;
    if (changeLayout[layoutIndex].w === 2) {
      const sideIndex = changeLayout.findIndex(
        (prop) => prop.y === changeLayout[layoutIndex].y && prop.i !== gridName,
      );
      if (sideIndex >= 0) {
        changeLayout[sideIndex].y = changeLayout[sideIndex].y + 3;
        changeLayout[sideIndex].x = 0;
        const anotherSideIndex = changeLayout.findIndex(
          (prop) =>
            prop.y === changeLayout[sideIndex].y &&
            prop.i !== changeLayout[sideIndex].i,
        );
        if (anotherSideIndex >= 0) {
          changeLayout[anotherSideIndex].y =
            changeLayout[anotherSideIndex].y + 3;
          changeLayout[anotherSideIndex].x = 0;
        }
      }
    }
    setLayout([...changeLayout]);
    storage.setJSONItem(
      ClientStorageKey.LocationDetailsGridLayout,
      changeLayout,
    );
  };

  type LayoutType = typeof layout[number];

  useEffect(() => {
    if (defaultLayout !== null) {
      const layoutSettingFromStotage: any = storage.getJSONItem(
        ClientStorageKey.LocationDetailsGridLayout,
      );
      if (layoutSettingFromStotage) {
        const layoutArr: Array<LayoutType> = [];
        layoutSettingFromStotage.map((props: LayoutType) => {
          const layoutObj = defaultLayout.find(({ i }: any) => i === props.i);
          layoutArr.push({
            ...layoutObj,
            ...props,
            isHidden: layoutObj?.isHidden,
          });
        });
        const filterLayout = layoutArr.filter(
          ({ isHidden }) => isHidden === false,
        );

        setLayout([...filterLayout]);
      } else {
        const filterLayout = defaultLayout.filter(
          ({ isHidden }) => isHidden === false,
        );
        setLayout([...filterLayout]);
      }
    }
  }, [defaultLayout]);

  useEffect(() => {
    if (Tokens) {
      setAddRemoveLocations(
        Tokens.indexOf(CustomerTokens.RemoveDeviceLocations) > -1,
      );
    }
  }, [Tokens]);

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    const obj = {
      location: query.get('location'),
    };
    storage.setJSONItem(ClientStorageKey.LocationFilters, obj);
    // window.location.href = '/customer/locations';
    history.back();
  };

  useEffect(() => {
    window.history.pushState({}, '');
    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  const getLocationDetails = async () => {
    try {
      const searchObj = {
        LocationID: locationId,
        CustomerID: customerInfo?.CustomerId,
        EmailAddress: customerInfo?.EmailAddress,
        HideOtherUserOrders: customerInfo?.HideOtherUserOrders,
        UserId: customerInfo?.UserId,
      };
      const response = await Api.callAPI({
        url: `/api/v1/location`,
        method: 'put',
        body: searchObj,
        options: {
          isLoading: false,
          showErrorMessage: true,
        },
      });
      setLocationData(response.data[0]);
      setPhone(response.data[0].Phone1);
    } catch (e) {
      navigate('/customer/locations');
    }
  };
  useEffect(() => {
    getLocationDetails().then();
  }, []);

  return (
    <>
      <div>
        {!locationData ? (
          <ComponentLoading isShowLoader={true} />
        ) : (
          <div>
            <div>
              <div className="flex justify-between items-center mb-8">
                <div className="flex lg:items-center pr-4">
                  <h1 className="lg:text-2xl md:text-xl sm:text-lg text-base font-medium">
                    Location Details - {locationData.CustomerName}
                  </h1>
                </div>
                {isAddRemoveLocation ? (
                  <>
                    <div className="md:flex hidden justify-end">
                      {locationData && (
                        <RemovePopModal locationData={locationData} />
                      )}
                      {locationData && (
                        <EditPopModal locationData={locationData} />
                      )}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>

              <>
                <div className="sm:flex lg:flex-nowrap flex-wrap lg:gap-8 gap-0 mb-10">
                  <div className="lg:w-1/3 sm:w-1/2 sm:mb-0 mb-4 md:pr-4 ">
                    <div className="flex items-center lg:mb-4 mb-2">
                      <PushpinIcon />
                      <h4 className="ml-2 text-base font-semibold">
                        Location Information
                      </h4>
                    </div>
                    <div className="ml-7 text-sm">
                      <div className="mb-1">
                        <span className="text-gray-600">Name: </span>
                        <span className="text-gray-800 ml-1">
                          {locationData.CustomerName}
                        </span>
                      </div>

                      <div className="mb-1">
                        <span className="text-gray-600">Devices: </span>
                        <span className="text-gray-800 ml-1">
                          {locationData.TotalEquipments}
                        </span>
                      </div>

                      <div className="mb-1">
                        <span className="text-gray-600">Open Orders: </span>
                        <span className="text-gray-800 ml-1">
                          {locationData.TotalOrders}
                        </span>
                      </div>

                      <div className="mb-1">
                        <span className="text-gray-600">Open Requests:</span>
                        <span className="text-gray-800 ml-1">
                          {locationData.TotalServiceRequests}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="lg:w-1/3 sm:w-1/2 lg:px-0 am:px-2 px-0 sm:mb-0 mb-4">
                    <div className="flex items-center lg:mb-4 mb-2">
                      <BuildingIcon />
                      <h4 className="ml-2 text-base font-semibold">
                        Location Address
                      </h4>
                    </div>
                    <div className="ml-7 text-sm">
                      {locationData.Address}
                      <br />
                      {locationData.City}, {locationData.State},{' '}
                      {locationData.Zip}
                    </div>
                  </div>
                  <div className="lg:w-1/3 sm:w-full lg:mt-0 sm:mt-6">
                    {(locationData &&
                      locationData.Attn &&
                      locationData.Attn.length > 0) ||
                    (locationData &&
                      locationData.Email &&
                      locationData.Email.length &&
                      locationData.Email.length > 0) ||
                    (phone && phone.length > 0) ? (
                      <>
                        <div>
                          <div className="flex items-center mb-4">
                            <LocationContactIcon />
                            <h4 className="ml-2 text-base font-semibold">
                              Location Contact
                            </h4>
                          </div>

                          <div className="ml-7 text-sm">
                            {locationData &&
                            locationData.Attn &&
                            locationData.Attn.length &&
                            locationData.Attn.length > 0 ? (
                              <div className="mb-1">
                                <span className="text-gray-600">Name:</span>
                                <span className="text-gray-800 ml-1">
                                  {locationData.Attn}
                                </span>
                              </div>
                            ) : (
                              ''
                            )}

                            {phone && phone.length > 0 ? (
                              <div className="mb-1">
                                <span className="text-gray-600">Phone:</span>
                                <span className="text-gray-800 ml-1">
                                  {phone}
                                </span>
                              </div>
                            ) : (
                              ''
                            )}

                            {locationData &&
                            locationData.Email &&
                            locationData.Email.length &&
                            locationData.Email.length > 0 ? (
                              <div className="mb-1">
                                <span className="text-gray-600">Email :</span>
                                <span className="text-gray-800 ml-1">
                                  {locationData.Email}
                                </span>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </>
            </div>
            <div className="md:flex hidden justify-end border-t border-gray-200 pt-4 mb-4">
              <button
                className={`primaryBtn bgColor`}
                onClick={() => {
                  storage.removeItem(
                    ClientStorageKey.LocationDetailsGridLayout,
                  );
                  setRefreshToken(generateRandomString(5));
                }}
              >
                {' '}
                <div className="sm:hidden flex">
                  <ResetIcon />
                </div>
                <div className="sm:flex hidden">Reset</div>
              </button>
            </div>
            <div className="dashboard-main">
              {layout !== null ? (
                <ResponsiveGridLayout
                  cols={{ lg: 2, md: 2, sm: 1, xs: 1, xxs: 1 }}
                  draggableHandle=".drag-handle"
                  //isBounded={true}
                  isResizable={false}
                  useCSSTransforms={true}
                  onDragStop={(changedLayout) => {
                    const changeLayout = [
                      ...layout.map((prop) => {
                        const filteredData = changedLayout.find(
                          (l: any) => l.i === prop.i,
                        );
                        return {
                          ...prop,
                          w: filteredData.w,
                          x: filteredData.x,
                          y: filteredData.y,
                          h: filteredData.h,
                        };
                      }),
                    ];
                    setLayout([...changeLayout]);
                    storage.setJSONItem(
                      ClientStorageKey.LocationDetailsGridLayout,
                      changeLayout,
                    );
                  }}
                >
                  {layout.map((prop) => {
                    return !prop.isHidden ? (
                      <div className="" key={prop.i} data-grid={{ ...prop }}>
                        {prop.i === 'Devices' ? (
                          <LocationDevices
                            title={prop.title}
                            viewAllRouterLink={prop.viewAllRouterLink}
                            plusRouterLink={prop.plusRouterLink}
                            accessToken={prop.accessToken}
                            expandContractGrid={() =>
                              expandContractGrid(prop.i)
                            }
                            isExpand={prop.w === 2}
                          />
                        ) : (
                          ''
                        )}
                        {prop.i === 'Service Requests' ? (
                          <LocationServicesDetails
                            title={prop.title}
                            viewAllRouterLink={prop.viewAllRouterLink}
                            plusRouterLink={prop.plusRouterLink}
                            accessToken={prop.accessToken}
                            expandContractGrid={() =>
                              expandContractGrid(prop.i)
                            }
                            isExpand={prop.w === 2}
                          />
                        ) : (
                          ''
                        )}
                        {prop.i === 'Order' ? (
                          <LocationOrder
                            title={prop.title}
                            viewAllRouterLink={prop.viewAllRouterLink}
                            plusRouterLink={prop.plusRouterLink}
                            accessToken={prop.accessToken}
                            expandContractGrid={() =>
                              expandContractGrid(prop.i)
                            }
                            isExpand={prop.w === 2}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    );
                  })}
                </ResponsiveGridLayout>
              ) : (
                ''
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LocationDetail;
