import React from 'react';
// import { SadfaceIcon } from '../../../assets/svg/sad-face-icon';
import NotFoundIcon from '../../../assets/svg/not-found-icon';

const NoRowsFound = () => {
  return (
    <div className={'flex flex-col justify-center items-center h-full'}>
      {/*<SadfaceIcon />*/}
      <NotFoundIcon />
      <div className="font-18 font-medium">Oops, Sorry no data found</div>
    </div>
  );
};
export default NoRowsFound;
