import NOTIFICATION_ACTIONS from './notification.action.types';
import { cloneDeep } from 'lodash'; // Import Lodash

const initialState = {
  notifications: [],

};

export default function notificationReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: any = cloneDeep(initialState),
  action: any,
) {
  switch (action.type) {
    case NOTIFICATION_ACTIONS.SHOW_NOTIFICATION: {
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    }
    case NOTIFICATION_ACTIONS.POP_NOTIFICATION: {
      const notificationArray = state.notifications;
      notificationArray.pop();
      return {
        ...state,
        notifications: [...notificationArray],
      };
    }
    case NOTIFICATION_ACTIONS.REMOVE_ALL_NOTIFICATION: {
      return {
        ...state,
        notifications: [],
      };
    }

    default:
      return state;
  }
}
