import React, { useRef, useEffect, useState } from 'react';
import { CancelBtn, Form, FormItem, FormItems, FormRef, FormType, InputMask, SubmitBtn } from '../../../../components/form';
import { Api } from '../../../../shared/api/api';
import { SubHeader } from '../../../admin/layout/footer/sub-header/sub-header';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../../context/UserContext';
// import { CustomerConfig } from '../user/add-update-user/customer-config';

// const roles = [
//   { name: "ALL", label: "User can add/edit/remove other Users.", value: "ALL"},
//   { name: "CAN_PLACE_ORDER", label: "User can place sales orders.", value: "CAN_PLACE_ORDER"},
//   { name: "CAN_VIEW_PLACED_ORDER", label: "User can view placed sales orders.", value: "CAN_VIEW_PLACED_ORDER"},
//   { name: "CAN_PLACE_SERVICE_REQUEST", label: "User can place service requests.", value: "CAN_PLACE_SERVICE_REQUEST"},
//   { name: "CAN_VIEW_SERVICE_REQUEST", label: "User can view service requests.", value: "CAN_VIEW_SERVICE_REQUEST"},
//   { name: "CAN_VIEW_REPORT", label: "User can view reports.", value: "CAN_VIEW_REPORT"},
//   { name: "CAN_EDIT_DEVICE_LOCATION", label: "User can edit device location.", value: "CAN_EDIT_DEVICE_LOCATION"},
//   { name: "CAN_EDIT_COST_CENTER", label: "User can edit cost center.", value: "CAN_EDIT_COST_CENTER"},
//   { name: "CAN_ADD_REMOVE_DEVICE_LOCATION", label: "User can add/remove devices and locations.", value: "CAN_ADD_REMOVE_DEVICE_LOCATION"}
// ];

export const Profile = () => {

  const { customerInfo } = useUser();

  const navigate = useNavigate();
  const form: FormRef = useRef();

  const [ isSaving, setIsSaving ] = useState(false);

  const [ customerDetails, setCustomerDetails] = useState(null);

  const thisConfig = customerDetails?.CustomerUserConfigurations.find((config => config.CustomerId === parseInt(customerInfo.CustomerId))) || customerDetails?.CustomerUserConfigurations[0];

  //Prepopulae customer details
  const getCustomerDetails = async () => {
    try {
      const [ details ] = await Promise.all([
        Api.callAPI({
          url: `/api/v1/users/${customerInfo.UserId}`,
          method: 'get',
        }),
        // Api.callAPI({
        //   url: `/flexprint/api/v1/customers/${customerInfo.AllowAllCustomers ? -1 : customerInfo.CustomerId}/address`,
        //   method: 'get',
        // }),
        // customerInfo.AllowRestrictRegion ? Api.callAPI({
        //   url: `/flexprint/api/v1/customers/${customerInfo.CustomerId}/regions`,
        //   method: 'get',
        // }) : {data: []},
      ]);
      setCustomerDetails(details?.data);
    } catch (e) {}
  };

  useEffect(() => {
    getCustomerDetails();
  }, [])

  const saveData = async (data) => {
    try {
      await Api.callAPI({
        url: `/api/v1/users/${customerInfo?.UserId}?CustomerId=-1`,
        method: 'put',
        body: data,
        options: {
          successMessage: 'Your Profile has been updated successfully.',
          errorMessage: 'Unexpected error occured, please try again.',
        },
      });
      navigate('/dashboard');
    } catch (e) {

    } finally {
      setIsSaving(false);
    }
  }

  //Possibly rip out customer config creation and make it a separate component
  const handleSubmit = (values) => {
    setIsSaving(true);

    let CustomerUserConfigurations = [thisConfig];
    // const {
    //   RestrictUserByLocationID, PlaceOrderAddressID, SendMeTrackingWhenAvailable,
    //   LetMeKnowIfOrdersGetCanceled, ServiceRequestAddressID, SendCallUpdates } = customerInfo;
    // const { Tokens } = values;

    // CustomerUserConfigurations.push({
    //   Tokens, RestrictUserByLocationID, HideOtherUserOrders: values.HideOtherUserOrders, PlaceOrderAddressID, SendMeTrackingWhenAvailable,
    //   LetMeKnowIfOrdersGetCanceled, ServiceRequestAddressID, SendCallUpdates,
    //   UserId: customerInfo.UserId,
    //   CustomerId: customerInfo.CustomerId,
    // })

    //Remove deconstructed values from values
    // delete values.Tokens;
    // delete values.RestrictUserByLocationID;
    // delete values.HideOtherUserOrders;
    // delete values.PlaceOrderAddressID;
    // delete values.SendMeTrackingWhenAvailable;
    // delete values.LetMeKnowIfOrdersGetCanceled;
    // delete values.ServiceRequestAddressID;
    // delete values.SendCallUpdates;

    //Apply value keys
    values.CustomerUserConfigurations = CustomerUserConfigurations;
    values.AllowAllCustomers = customerInfo?.AllowAllCustomers;
    values.Customers = customerInfo?.Customers || [customerInfo.CustomerId];
    values.Active = true;
    values.CreatedBy = customerInfo?.CreatedBy || "1";
    values.CreatedDate = customerInfo?.CreatedDate || Math.floor(new Date().getTime() / 1000);

    values._id = customerInfo?.UserId;

    delete values.selectedCustomer;
    delete values.SingleCustomer;


    saveData(values);
  }

  return (
    <>
      <SubHeader
        leftSideElement={<h1 className="mb-4 text-2xl font-medium">Your Profile</h1>}
      />
      <Form
        onCancel={() => {}}
        onSubmit={handleSubmit}
        formRef={form}
      >
        <div className="">
          <div className="flex">
          <div className="mr-3 w-full lg:border-r lg:border-stone-200 lg:pr-6 lg:w-1/2">
            <h2 className="font-bold text-lg">Details</h2>
              <FormItems
                items={[
                  {
                    name: 'EmailAddress',
                    label: 'User Email',
                    type: FormType.EMAIL,
                    placeholder: 'User Email',
                    disabled: !!customerInfo?.EmailAddress,
                    defaultValue: customerInfo?.EmailAddress,
                    required: true,
                    className: 'mb-4',

                  },
                  {
                    name: 'FirstName',
                    label: 'First Name',
                    type: FormType.TEXT,
                    placeholder: 'First Name',
                    defaultValue: customerInfo?.FirstName,
                    required: true,
                    className: 'mb-4'
                  },
                  {
                    name: 'LastName',
                    label: 'Last Name',
                    defaultValue: customerInfo?.LastName,
                    type: FormType.TEXT,
                    placeholder: 'Last Name',
                    required: true,
                    className: 'mb-4'
                  },
                ]}
              />
            <div className="flex w-full mb-4">
              <div className="w-full">
              <FormItem
                formDef={{
                  name: 'PhoneNumber',
                  label: 'Phone Number',
                  defaultValue: customerInfo?.UserPhone,
                  type: FormType.TEXT,
                  placeholder: 'Phone Number',
                  mask: InputMask.PHONE,
                }}
              />
              </div>
              <div className="w-full ml-4 max-w-[33%]">
                <FormItem
                  formDef={{
                    name: 'Extension',
                    label: 'Ext.',
                    maxLength: 10,
                    defaultValue: customerInfo?.UserPhoneExtension,
                    type: FormType.TEXT,
                    placeholder: 'Ext.',
                  }}
                />
              </div>
            </div>
            </div>
            {/* To be readded and changed later  */}
            <div className="lg:w-1/2 lg:pl-6">
              {/* <CustomerConfig
                  customer={customerDetails}
                  defaultValues={thisConfig}
                  addresses={addresses}
                  roles={roles}
                  regions={regions}/> */}
            </div>
          </div>
          <div className="w-full flex items-end justify-end">
            <CancelBtn className="px-4 py-1 rounded mr-2" ghost onClick={() => {
            }}>Cancel</CancelBtn>
            <SubmitBtn className="px-4 py-1 rounded" disabled={isSaving}>Save Profile</SubmitBtn>
          </div>
        </div>
      </Form>
    </>
  );
};
