import React, { useRef} from 'react';
import {colorCoder, isCheckDollerProcingNonZero} from "../../../utils/utils";
import { useSelector } from "react-redux";
import AddCartProductExistDialog from "./add-cart-product-exist-dialog";
import {NumberInput} from "../../../components/ui/NumberInput";
import {Button} from "../../../components/ui";
import { RootState } from "../../../store/store";
import { TENANT, CART_MAX_LIMIT, CART_MIN_LIMIT } from "../../../constant/constant";
import { useCart } from '../context/CartContext';
import { CartItem } from '../../../types/Product';



const DisplayProduct = ({products}: { products: CartItem[] }) => {
  const { addToCart } = useCart();
  const tenantName = useSelector(
    (states: RootState) => states.tenantState.tenantName,
  );
  const openPopUpModalRef = useRef(null);


  const [ productsWithAmount, setProductsWithAmount ] = React.useState<CartItem[]>(products);

  const updateProductAmount = (product: CartItem, quantity: number) => {
    const updatedProducts = productsWithAmount.map((p) => {
      if(p.ItemID === product.ItemID) {
        return {
          ...p,
          Quantity: quantity
        }
      }
      return p;
    });
    setProductsWithAmount(updatedProducts);
  }



  return (
    <>
      <AddCartProductExistDialog ref={openPopUpModalRef} />
      {productsWithAmount.map((product: CartItem) => (
        <div className="cartItem" key={'products-' + product.ItemID}>
          <div className="2xl:flex items-center mb-4 pb-4 border-b border-gray-200">
            <div className="2xl:w-3/5 proTitle text-sm text-gray-800 2xl:pr-4">
              <div className="flex">
                <div className="w-4 mr-2 ">
                  {tenantName === TENANT.MARIMON &&
                  product &&
                  product.ItemDescription &&
                  colorCoder(product?.ItemDescription) ? (
                    <div
                      className="w-4 h-4 rounded-full ItemDescription mt-1"
                      style={{
                        backgroundColor: colorCoder(product?.ItemDescription),
                      }}
                    ></div>
                  ) : (
                    ''
                  )}
                  {tenantName !== TENANT.MARIMON &&
                  product &&
                  product.CatDescription &&
                  colorCoder(product.CatDescription) ? (
                    <div
                      className="w-4 h-4 rounded-full ItemDescription mt-1"
                      style={{
                        backgroundColor: colorCoder(product?.CatDescription),
                      }}
                    ></div>
                  ) : (
                    ''
                  )}
                </div>

                <div>
                  <h4 className="break-all text-sm font-semibold text-gray-900">
                    {product.ItemDescription}
                  </h4>
                  <div className="break-all mt-1 mb-4">
                    <div className="text-sm text-gray-600">{product.Item}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="2xl:w-2/5 md:mt-0 mt-4 flex items-center sm:flex-nowrap flex-wrap 2xl:justify-end ">
              <div className="mr-6">
                <div>
                  {isCheckDollerProcingNonZero(product.Price) ? (
                    <span className="text-gray-900 text-base font-semibold">
                      ${product.Price}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className="flex items-center mr-6">
                <button
                  onClick={() => {
                    updateProductAmount(product, product.Quantity - 1)
                  }}
                  role={'button'}
                  className="text-2xl text-gray-900 mr-2"
                >
                  -
                </button>
                <div className="w-20 qtyStapper mt-0.5">
                  <NumberInput
                    value={product.Quantity}
                    decimalScale={0}
                    allowNegative={false}
                    className={`form-control no-spinners border border-gray-400 text-gray-900 rounded-md text-center`}
                    onChange={(e: any) => {
                      if(!e.floatValue) return;
                      updateProductAmount(product, e.floatValue)
                    }}
                    isAllowed={(values) => {
                      const { floatValue, value } = values;
                      return value === '' || (floatValue >= CART_MIN_LIMIT && floatValue <= CART_MAX_LIMIT);
                    }}
                    allowedDecimalSeparators={false}
                  />
                </div>
                <button
                  onClick={() => {
                    updateProductAmount(product, product.Quantity + 1)
                  }}
                  role="button"
                  className="text-2xl text-gray-900 ml-2"
                >
                  +
                </button>
              </div>

              <div className="sm:flex justify-end sm:mt-0 mt-3">
                <Button
                  className="px-4 py-1 rounded"
                  onClick={() => addToCart(product)}
                  >
                  { 'Add to Cart' }
                </Button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default DisplayProduct;
