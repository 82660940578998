import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../store/store';
import { PASSWORD_REGEX } from '../../../constant/constant';

import { Api } from '../../../shared/api/api';

import { Form, FormItems, FormType, SubmitBtn } from '../../../components/form';
import React, { useEffect, useState } from 'react';
import { ComponentLoading } from '../../../shared/component-loader/component-loading';
import { tenantLogo } from '../../../utils/utils';

export const ResetForgotPassword = () => {
  let { token } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );

  const validateToken = async () => {
    try {
      const res = await Api.callAPI({
        url: `/api/v1/users/resetPassword/${token}`,
        method: 'get',
        options: {
          isLoading: false,
          showErrorMessage: true,
          errorMessage: 'Invalid or expired reset token.',
        },
      });
      setUser(res);
      if (res?.status === 'error') {
        navigate('/login');
      }
    } catch (e) {
      navigate('/login');
    }
  };

  const resetPassword = async (bodyObj) => {
    try {
      await Api.callAPI({
        url: `/api/v1/users/setUserPassword/${bodyObj.token}`,
        body: bodyObj,
        method: 'post',

        options: {
          isLoading: true,
          showErrorMessage: false,
          successMessage:
            'Success! You will now be automatically redirected to the login page.',
        },
      });

      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (e) {}
  };
  const handleSubmit = async (value) => {
    try {
      const bodyObj = {
        password: value.password,
        token: user.data.PasswordResetCode,
      };
      await resetPassword(bodyObj);
    } catch (e) {}
  };

  useEffect(() => {
    validateToken().then();
  }, []);

  return (
    <>
      {user ? (
        <div className="bg-gray-100 fch100 flex justify-center items-center py-8 px-4  grayBox">
          <div className="w-96 m-auto bg-white shadow p-8 pt-12 my-8 ">
            <Form onSubmit={handleSubmit}>
              <div>
                <img
                  className="m-auto"
                  alt={'Order Ahead Logo'}
                  src={tenantLogo(tenantName)}
                />
                <h1 className="text-center my-8 font-bold text-gray-700">
                  SET PASSWORD
                </h1>
                <FormItems
                  items={[
                    {
                      name: 'password',
                      label: 'Password',
                      type: FormType.PASSWORD,
                      placeholder: 'New Password',
                      className:'mb-4',
                      minLength: 6,
                      maxLength: 16,
                      required: true,
                      pattern: PASSWORD_REGEX,
                      errors: {
                        required: 'Please provide password.',
                        minLength: 'Minimum length should be 6.',
                        pattern:
                          'Password must contain one uppercase, one lowercase,' +
                          ' one number and one special character.',
                      },
                    },
                    {
                      name: 'confirmPassword',
                      label: 'Confirm Password',
                      type: FormType.PASSWORD,
                      placeholder: 'Confirm Password',
                      className:'mb-5',
                      matchInput: 'password',
                      minLength: 6,
                      maxLength: 16,
                      errors: {
                        required: ' Please provide confirm password.',
                        matchInput: 'Passwords do not match',
                      },
                      required: true,
                    },
                  ]}
                />
              </div>
              <SubmitBtn className="rounded p-2 primaryBtn w-full">
                Submit
              </SubmitBtn>
            </Form>
          </div>
        </div>
      ) : (
        <ComponentLoading isShowLoader={true} />
      )}
    </>
  );
};
