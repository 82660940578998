import { FormItem } from "./FormItem";
import { FormDefinition } from "./types/FormTypes";

interface FormItemsProps {
    className?: string;
    items: FormDefinition[];
}

export const FormItems = ({items, className}: FormItemsProps) => {
    return <div className={`${className} mb-3`}>
        { items.map((item) => <FormItem formDef={item} key={item.name}/>) }
    </div>
}