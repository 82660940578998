import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import tenantReducer from './tenant/tenent.reducer';
import loadingReducer from './loading/loading.reducer';
import notificationReducer from './notification/notification.reducer';
import cartReducer from './cart/cart.reducer';

let localStoreVar: any = null;

export const getStaticReducer = () => ({
  notificationState: notificationReducer,
  tenantState: tenantReducer,
  loadingState: loadingReducer,
  cartState: cartReducer,
});

export const configureStore = () => {
  localStoreVar = createStore(
    combineReducers(getStaticReducer()),
    composeWithDevTools(applyMiddleware(thunk)),
  );
  return localStoreVar;
};

export const getStore = () => {
  if (localStoreVar === null) {
    return configureStore();
  }
  return localStoreVar;
};

const root = combineReducers(getStaticReducer());
export type RootState = ReturnType<typeof root>;
