import React, { Ref, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../../../shared/api/api';
import ApiTable from '../../../../shared/api-table/api-table-admin';
import { AdminGridName } from '../../../../constant/constant';
import { SuperadminTableFields } from './superadmin-table-fields';
import { SubHeader } from '../../layout/footer/sub-header/sub-header';
import { ResetIcon } from '../../../../assets/svg/reset-icon';
import { AddIcon } from '../../../../assets/svg/add-icon';

export const SuperAdminUserList = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<any>(false);
  const ref: Ref<any> = useRef(null);

  const getSuperAdminUser = async () => {
    try {
      const response: any = await Api.callAPI({
        url: `/api/v1/superadmin/users?userId=${id}`,
        method: 'get',
        options: {
          isLoading: true,
          showErrorMessage: false,
        },
      });
      setRowData([...response?.data]);
    } catch (e) {}
  };
  useEffect(() => {
    (async () => {
      await getSuperAdminUser();
    })();
  }, []);

  const removeSuperUser = async ({ userId }: any) => {
    try {
      await Api.callAPI({
        url: `/api/v1/superAdmin/users/${userId}`,
        method: 'delete',
        options: {
          isLoading: true,
          showErrorMessage: false,
        },
      });
    } catch (e) {}
  };

  const onActionClick = async (cellClickEvent: any, type: any) => {
    const { data } = cellClickEvent;

    if (type === 'edit') {
      navigate(`/admin/adminUser/edit/${data._id}`);
    }

    if (type === 'trash') {
      const confirm = window.confirm(
        'Are you sure you want to delete this admin user?',
      );
      if (confirm) {
        try {
          await removeSuperUser({ userId: data?._id });
          await getSuperAdminUser();
        } catch (e) {
        } finally {
        }
      }
    }
  };

  const reset = () => {
    if (ref.current) {
      ref.current.resetGrid();
    }
  };

  const onClickHandlerAddAdminUser = () => {
    navigate('/admin/adminUser/add');
  };

  const columns = SuperadminTableFields({ onActionClick: onActionClick });
  return (
    <>
      <>
        <SubHeader
          leftSideElement={
            <>
              <h2 className='font-semibold text-gray-600'>Users</h2>
            </>
          }
          righttSideElement={
            <>
              <button
                className={`flex items-center borderedBtn mr-4 linkText  hover:text-black`}
                onClick={reset}
              >
                <ResetIcon />
                <div className={`ml-2 text-sm`}>Reset</div>
              </button>

              <button
                className={`flex items-center borderedBtn mr-4 linkText text-black hover:text-black`}
                onClick={onClickHandlerAddAdminUser}
              >
                <AddIcon />
                <div className={`ml-2 text-sm`}>Add Admin User</div>
              </button>
            </>
          }
        />

        {rowData ? (
          <ApiTable
            ref={ref}
            columns={columns}
            gridName={AdminGridName.AdminUser}
            rowData={rowData}
          />
        ) : (
          ''
        )}
      </>
    </>
  );
};
