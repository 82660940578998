import { RedDeleteIcon } from '../../assets/svg/red-delete-icon';
import { useModal } from '../../context/ModalConext';
import { useTheme } from '../../context/ThemeContext';

export const Modal = () => {
  const { content, closeModal, options } = useModal();
  const { mainTheme } = useTheme();

  return (
    <div
      // className="fixed p-4 inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none bg-gray-900/70"
      className={`fixed inset-0 z-50 px-6 w-full model py-10 flex ${options?.className || ''}`}
      onClick={() => {
        if (options?.isBackDropClose) {
          closeModal();
        }
      }}
    >

      {/* add this class " orderInfoModal " here for oder info poupup modal */}
      <div
        className={`rounded-lg overflow-hidden   ${
          options?.modelWidthClass ? options.modelWidthClass : 'md:w-400'
        } m-auto w-full ${mainTheme.primary}`}
        onClick={(e) => e.stopPropagation()}
      >
        {options?.title && (
          <div className="flex items-center justify-between px-4 py-2 text-base text-white bg-gray-900 modalHeader">
            <h2 className="">{options.title}</h2>
            <div className="whiteIcon cursor-pointer" onClick={closeModal}>
              <RedDeleteIcon />
            </div>
          </div>
        )}
        <div className="bg-white modalMainContent">{content}</div>
        {options?.footer && <div>{options.footer}</div>}
      </div>
    </div>
  );
};
