import { CheckBox } from '../../ui';
import { useForm } from '../Form';
import { FormDefinition } from '../types/FormTypes';
import { useEffect, useState } from 'react';

export const FormCheckBox = ({ formDef }: { formDef: FormDefinition }) => {
  const { formState, handleInputValidation, handleSelection } = useForm();
  const checkBoxState = formState[formDef.name];
  const [value, setValue] = useState<any>([]);

  useEffect(() => {
    setValue(formDef?.value);
  }, [formDef?.value]);

  return (
    <CheckBox
      className={formDef.className}
      label={formDef.label}
      name={formDef.name}
      value={checkBoxState?.value || null}
      onChange={(val, type) => {
        formDef.onChange?.(val, type);
        return handleSelection(val, formDef.name);
      }}
      options={formDef.radioOptions}
      onBlur={() => handleInputValidation(formDef)}
      multiSelect={formDef.optionsMultiSelect}
      selected={value || checkBoxState?.value || []}
      helperText={formDef.helperText}
      error={checkBoxState?.error}
      defaultValue={formDef.defaultValue}
      selectAll={formDef?.selectAll || false}
      selectAllLabel={formDef?.selectAllLabel || null}
      disabled={formDef?.disabled}
    />
  );
};
