import { InputDropDownOptions } from '../../ui/Input';
import { RadioButtonOptions } from '../../ui/RadioButton';
import { Option } from '../../ui/react-select';
import { AsyncOptionMapping } from '../../ui/react-select/react-select';
import { ReactElement } from 'react';

export interface FormDefinition {
  name: string; // Name of the input
  label: string | React.ReactNode; // Will be used as the label for the input
  type: FormType; // Type of input
  placeholder?: string; // Placeholder text
  required?: boolean; // Is the input required
  disabled?: boolean; // Is the input disabled
  readOnly?: boolean; // Is the input read only
  autoComplete?: boolean; // Is the input auto complete
  autoFocus?: boolean; // Is the input auto focused
  min?: number; // Min value for number input
  max?: number; // Max value for number input
  minLength?: number; // Min length for text input
  maxLength?: number; // Max length for text input
  pattern?: string; // Will throw an error if the input does not match the pattern
  value?: string | number | boolean | Array<string>; // Value of the input
  defaultValue?: string | number | boolean | Array<string>; // Default value of the input
  onChange?: (
    event: React.ChangeEvent<HTMLButtonElement> | any,
    type?: any | null,
  ) => void; // On change event, can be used to override default onChange
  onInput?: (event: React.FormEvent<HTMLInputElement>) => void; // On input event, can be used to override default onInput
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void; // On blur event, can be used to override default onBlur
  onFocus?: (event: React.FormEvent<HTMLInputElement>) => void; // On focus event, can be used to override default onFocus
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void; // On click event, can be used to override default onClick
  onDoubleClick?: (event: React.FormEvent<HTMLInputElement>) => void; // On double click event, can be used to override default onDoubleClick
  options?: Array<InputDropDownOptions | Option>; // Options for the dropdown
  optionsMultiSelect?: boolean; // Is the checkbox or Raido multi select
  radioOptions?: Array<RadioButtonOptions>; // Options for radio buttons or checkboxes
  radioOptionsSelectAll?: boolean; // Should the radio buttons or checkboxes have a select all option
  displayRow?: boolean; // Should the radio buttons or checkboxes be in a row
  helperText?: string; // Helper text for radio buttons or checkboxes
  errors?: FormDefinitionErrors; // Error messages for feilds, passing will override default error messages
  icon?: React.ReactNode; // Icon for Inputs
  iconPosition?: 0 | 1; // 0 = left, 1 = right
  minDate?: string; // Min date for date input
  maxDate?: string; // Max date for date input
  mask?: InputMask; // Mask for input
  matchInput?: string; // Name of input to match
  requiredInputs?: Array<string>; // Array of input names that are required if this input has a value
  fullWidth?: boolean; // Should the input take up the full width of the container
  condition?: boolean; // Condition for input to be valid
  defaultSelected?: Array<string>; // Default selected option for dropdown
  selectHideSearch?: boolean; // Should the dropdown have a search bar
  selectMultiSelect?: boolean; // Should the dropdown be multi select

  selectShowCheckboxes?: boolean; // Should the dropdown show checkboxes
  selectShowSelectAll?: boolean; // Should the dropdown show select all
  selectShowSelectAllFilter?: boolean; // Should the dropdown show select all filter
  selectIsCreatable?: boolean; // Should the dropdown be creatable
  selectIsClearable?: boolean; // Should the dropdown be clearable
  selectShowChip?: boolean; // Should the dropdown show chips
  selectFilterOptions?: (
    options: Option[],
    filter: string,
  ) => Promise<Option[]> | Option[]; // Filter options for dropdown
  selectValueRenderer?: (
    selected: Option[],
    options: Option[],
  ) => React.ReactNode; // Value renderer for dropdown
  uploadMutiple?: boolean; // Should the upload allow multiple files
  uploadAccept?: string; // File types to accept
  uploadMaxSize?: number; // Max file size
  component?: () => React.JSX.Element; // Custom component for drop down
  isMenuTop?: boolean; // Should the dropdown menu be on top
  getAsyncOptions?: (inputValue: string) => Promise<Option[]>; // Async options for dropdown
  optionKeyMapObject?: AsyncOptionMapping; // Key map object for dropdown
  debounceDuration?: number; // Debounce duration for dropdown
  className?: string; // Class name for
  ClearSelectedIcon?: ReactElement;
  selectAll?: boolean;
  selectAllLabel?: string;
  CustomValidation?: (formDef, formState) => boolean;
  isDisplayElipsis?: boolean;
  rows?: number;
  selectAllValue?: string;
}

export enum FormType {
  TEXT = 'text',
  NUMBER = 'number',
  PASSWORD = 'password',
  EMAIL = 'email',
  SELECT = 'select',
  DATE = 'date',
  TIME = 'time',
  DATETIME_LOCAL = 'datetime-local',
  MONTH = 'month',
  WEEK = 'week',
  TEL = 'tel',
  URL = 'url',
  TEXTAREA = 'textarea',
  SEARCH = 'search',
  COLOR = 'color',
  FILE = 'file',
  RANGE = 'range',
  HIDDEN = 'hidden',
  IMAGE = 'image',
  BUTTON = 'button',
  RESET = 'reset',
  SUBMIT = 'submit',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}

export interface FormDefinitionErrors {
  required?: string;
  pattern?: string;
  minLength?: string;
  maxLength?: string;
  min?: string;
  max?: string;
  condition?: string;
  option?: string;
  minDate?: string;
  maxDate?: string;
  invalidDate?: string;
  mask?: string;
  matchInput?: string;
  requiredInputs?: string;
  uploadMaxSize?: string;
  customValidation?: string;
}

export enum InputMask {
  PHONE = 'Phone Number',
  SSN = 'Social Security Number',
  ZIP = 'Zip Code',
  CURRENCY = 'Dollar Amount',
  PERCENT = 'Percentage',
  DATE = 'Date',
  TIME = 'Time',
  DATE_TIME = 'date-time',
  CREDIT_CARD = 'Credit Card Number',
  EMAIL = 'Email Address',
  NUMBER = 'Number',
  IP = 'IP Address',
  IPV4 = 'IPV4 Address',
  IPV6 = 'ipv6 Address',
  MAC = 'Mac Address',
  HEX = 'Hex code',
  RGB = 'RGB code',
  RGBA = 'RGBA code',
}

export interface FormRef extends React.RefObject<HTMLFormElement> {
  submit?: (event: any | null) => void;
  clearInputs?: (fields: string[]) => void;
  clearInputsRestoreDefaults?: (fields: string[]) => void;
  clear?: () => void;
  isSubmitDisabled?: boolean;
  values?: any;
  removeFormDef?: (inputs: string[]) => void;
}
