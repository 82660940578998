import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useModal } from '../../../../context/ModalConext';
import {
  hideLoader,
  showLoader,
} from '../../../../store/loading/loading.action';

import {
  CAPTCHA_KEY,
  DATE_FORMAT,
  EMAIL_REGEX,
} from '../../../../constant/constant';
// eslint-disable-next-line
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Form,
  FormItem,
  FormItems,
  FormRef,
  FormType,
  SubmitBtn,
} from '../../../../components/form';
import moment from 'moment';
import { Api } from '../../../../shared/api/api';
import { showNotification } from '../../../../store/notification/notification.action';


const LoginFeedBackForm = ({}, ref) => {
  const dispatch = useDispatch();
  const formRef: FormRef = useRef(null);
  const capchaErrorRef = useRef(null);

  const { openModal, closeModal } = useModal();
  let capchaValue = null;
  const handleSubmit = async (values: any) => {
    if (capchaValue) {
      dispatch(showLoader());
      const { email, message, name } = values;

      try {
        const feedbackData = {
          EmailAddress: email,
          UserName: name.trim(),
          Feedback: message.trim(),
          Date: moment(new Date().toISOString()).format(DATE_FORMAT),
          SiteUrl: window.location.href,
          About: 'Login',
        };

        await Api.callAPI({
          url: '/api/v1/users/loginFeedback',
          method: 'post',
          body: feedbackData,
          options: {
            isLoading: true,
            errorMessage: 'Error while sending feedback.',
          },
        });
        dispatch(
          showNotification({
            message: 'Feedback has been sent successfully.',
            type: 'success',
          }),
        );
        closeModal();
      } catch (e) {}
      dispatch(hideLoader());
    } else {
      if (capchaErrorRef.current) {
        capchaErrorRef.current.innerText = 'Please select captcha.';
      }
    }
  };
  const FeedbackFormModalContent = () => {
    return (
      <Form
        formRef={formRef}
        onSubmit={handleSubmit}
        defaultValues={{
          about: [{ label: 'login', value: 'login' }],
        }}
      >
        <div className="p-6">
          <FormItems
            items={[
              {
                name: 'email',
                label: 'Email',
                type: FormType.TEXT,
                placeholder: 'Email',
                required: true,
                pattern: EMAIL_REGEX,
                className: 'mb-4',
                errors: {
                  required: 'Please provide email.',
                  pattern: 'Please provide valid email.',
                },
              },
              {
                name: 'name',
                label: 'Name',
                type: FormType.TEXT,
                placeholder: 'Name',
                required: true,
                className: 'mb-4',
                errors: {
                  required: 'Please provide name.',
                },
              },
            ]}
          />
          <FormItem
            formDef={{
              name: 'about',
              label: 'About',
              type: FormType.SELECT,
              selectShowCheckboxes: false,
              disabled: true,
              selectHideSearch: true,
              isMenuTop: false,
              required: true,
              selectMultiSelect: false,
              ClearSelectedIcon: null,
              options: [{ label: 'login', value: 'login' }],
              className: 'mb-4',
              errors: {
                required: 'Please select about',
              },
            }}
          ></FormItem>

          <FormItems
            items={[
              {
                name: 'message',
                label: 'Feedback',
                type: FormType.TEXTAREA,
                placeholder: 'Write your message here...',
                required: true,
                maxLength: 200,
                rows: 5,
                className: 'mb-4',
                errors: {
                  required: 'Please enter feedback message.',
                },
              },
            ]}
          />
        </div>
        <div className="px-6 pb-6">
          <ReCAPTCHA
            sitekey={CAPTCHA_KEY}
            onChange={(val) => {
              if (val) {
                capchaValue = val;
                if (capchaErrorRef.current) {
                  capchaErrorRef.current.innerText = '';
                }
              }
            }}
          />
          <div className={'text-xs text-red-500'} ref={capchaErrorRef}></div>
        </div>

        <div className="flex justify-end py-4 px-6 border-t border-gray-200">
          <button onClick={() => closeModal()} className={`borderedBtn mr-2 linkText hover:text-black`}>
            Cancel
          </button>

          <SubmitBtn
            // onClick={(e: any) => formRef.submit(e)}
            className="rounded px-4"
            // disabled={!isRecapchaValidate}
          >
            Send
          </SubmitBtn>
        </div>
      </Form>
    );
  };

  const openLoginFeedBackModal = () => {
    openModal({
      content: (
        <>
          <FeedbackFormModalContent />
        </>
      ),
      options: {
        title: 'Feedback',
        footer: <></>,
        isBackDropClose: false,
      },
    });
  };
  useImperativeHandle(ref, () => ({
    openLoginFeedBackForm() {
      openLoginFeedBackModal();
    },
  }));
  return <></>;
};

export default forwardRef(LoginFeedBackForm);
