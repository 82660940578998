import React, { useEffect, useState } from "react";
import { Form, FormItem, FormItems, FormType, SubmitBtn } from "../../../components/form";
import { Api } from "../../../shared/api/api";
import { FLOAT_VALUE } from "../../../constant/constant";

const GlobalSettings = () => {
  const [defaultValue, setDefaultValue] = useState(null);

  const setValueCancelHandler = () => {
    const defaultValueClone = defaultValue;
    setDefaultValue(null);
    setTimeout(() => {
      setDefaultValue({
        ...defaultValueClone,
        FreightCharges: '0',
      });
    }, 100);
  };

  const getAPICall = async () => {
    try {
      const response = await Api.callAPI({
        url: '/api/v1/globalSettings',
        method: 'GET',
        options: {
          showErrorMessage: false,
        },
      });

      setDefaultValue(response.data[0]);
    } catch (e) {}
  };

  const showAlert = async (value: any) => {
    try {
      if (confirm(`Are you sure you want to update global settings?`)) {
        const data = {
          freightCharges: value?.FreightCharges,
          enableCommunicationFromCustomersToZendesk:
            value?.EnableCommunicationFromCustomersToZendesk
              ? value?.EnableCommunicationFromCustomersToZendesk
              : 'false',
        };

        await Api.callAPI({
          url: '/api/v1/globalSettings',
          body: data,
          method: 'POST',
          options: {
            successMessage: 'Global settings updated successfully.',
            showErrorMessage: 'Error in updating global settings.',
          },
        });
      }
    } catch (e) {}
  };

  useEffect(() => {
    getAPICall().then();
  }, []);

  return (
    <>
      {defaultValue && (
        <div>
          <h2 className="font-semibold text-gray-600">Global Settings</h2>
          <div className="bg-gray-100 mt-4 rounded-lg p-6">
            {defaultValue ? (
              <Form
                defaultValues={{
                  FreightCharges:
                    defaultValue?.FreightCharges !== null
                      ? defaultValue?.FreightCharges
                      : null,
                  EnableCommunicationFromCustomersToZendesk:
                    defaultValue?.EnableCommunicationFromCustomersToZendesk,
                }}
                onSubmit={showAlert}
              >
                <FormItems
                  items={[
                    {
                      name: 'FreightCharges',
                      label: 'Freight charge on transactional orders',
                      type: FormType.TEXT,
                      maxLength: 15,
                      required: true,
                      pattern: FLOAT_VALUE,
                      errors: {
                        required:
                          'Please provide freight charge on transactional orders.',
                        pattern: 'Please provide valid freight charge on transactional orders.'
                      },
                    },
                  ]}
                />
                <div className="mt-3">
                  <FormItem
                    formDef={{
                      name: 'EnableCommunicationFromCustomersToZendesk',
                      label:
                        'Enable communications from customers to Zendesk (add note, cancel request, and extended notes)',
                      type: FormType.CHECKBOX,
                    }}
                  />
                </div>
                <div className="flex justify-end pt-4 border-t mt-6 border-gray-200">
                  <button
                    type="button"
                    className={`borderedBtn mr-2 linkText hover:text-black`}
                    onClick={() => {
                      setValueCancelHandler();
                    }}
                  >
                    Cancel
                  </button>
                  <SubmitBtn className="commonBtn">Submit</SubmitBtn>
                </div>
              </Form>
            ) : (
              ''
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default GlobalSettings;
