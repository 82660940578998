export const PrinterIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_782_7089)">
        <path
          d="M14.1667 14.1667H15.8333C16.2754 14.1667 16.6993 13.9911 17.0118 13.6785C17.3244 13.366 17.5 12.942 17.5 12.5V9.16667C17.5 8.72464 17.3244 8.30072 17.0118 7.98816C16.6993 7.6756 16.2754 7.5 15.8333 7.5H4.16667C3.72464 7.5 3.30072 7.6756 2.98816 7.98816C2.67559 8.30072 2.5 8.72464 2.5 9.16667V12.5C2.5 12.942 2.67559 13.366 2.98816 13.6785C3.30072 13.9911 3.72464 14.1667 4.16667 14.1667H5.83333"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.1668 7.5V4.16667C14.1668 3.72464 13.9912 3.30072 13.6787 2.98816C13.3661 2.67559 12.9422 2.5 12.5002 2.5H7.50016C7.05814 2.5 6.63421 2.67559 6.32165 2.98816C6.00909 3.30072 5.8335 3.72464 5.8335 4.16667V7.5"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.8335 12.5007C5.8335 12.0586 6.00909 11.6347 6.32165 11.3221C6.63421 11.0096 7.05814 10.834 7.50016 10.834H12.5002C12.9422 10.834 13.3661 11.0096 13.6787 11.3221C13.9912 11.6347 14.1668 12.0586 14.1668 12.5007V15.834C14.1668 16.276 13.9912 16.6999 13.6787 17.0125C13.3661 17.3251 12.9422 17.5007 12.5002 17.5007H7.50016C7.05814 17.5007 6.63421 17.3251 6.32165 17.0125C6.00909 16.6999 5.8335 16.276 5.8335 15.834V12.5007Z"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_782_7089">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
