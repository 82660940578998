import { Link } from 'react-router-dom';
import {
  formattingTimeIntoMMDDYY
} from "../../../../utils/utils";
import { EditIcon } from '../../../../assets/svg/edit-icon';
import { FilterType } from '../../../../shared/api-table/table-helper';
import { LinkOutIcon } from '../../../../assets/svg/link-out';
import { Chip } from '../../../../components/ui/Chip';
import { useUser } from '../../../../context/UserContext';
import { CustomerTokens } from '../../../../types/Customer';

export const DeviceTableField = ({
  selectedLocationIds = [],
  openLocationModal,
  openLocationRemarkModal,
}: any) => {
  const { hasPermission } = useUser();
  const filterParams = {
    filterOptions: ['contains'],
    debounceMs: 500,
    defaultOption: 'contains',
    maxNumConditions: 1,
  };
  let columns = [
    {
      headerName: 'Equipment #',
      field: 'EquipmentNumber',
      width: 150,
      filterParams: filterParams,
      filter: FilterType.AgTextColumnFilter,
      cellRenderer: (options: any) => {
        return options && options.data && options.data.EquipmentID ? (
          <Link
            className="service-request-view linkText"
            to={`/customer/device-detail/${
              options && options.data && options.data.EquipmentID
            }?location=${
              selectedLocationIds && selectedLocationIds.length
                ? (selectedLocationIds.join()).toString().trim()
                : ''
            }`}
          >
            {options && options.data && options.data.EquipmentNumber}
          </Link>
        ) : (
          '-'
        );
      },
    },
    {
      headerName: 'Serial Number',
      field: 'SerialNumber',
      filterParams: filterParams,
      width: 200,
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.SerialNumber) {
          // tslint:disable-next-line:max-line-length
          return (
            <div className="service-request-view">
              {options.data.SerialNumber}
            </div>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Model',
      field: 'Model',
      filterParams: filterParams,
      width: 200,
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.Model) {
          // tslint:disable-next-line:max-line-length
          return (
            <div className="service-request-view">{options.data.Model}</div>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Location',
      field: 'Location',
      filterParams: filterParams,
      width: 200,
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.Location) {
          // tslint:disable-next-line:max-line-length
          return (
            <div className="service-request-view flex  items-center cursor-pointer">
              {hasPermission(CustomerTokens.ModifyDeviceLocation) ? (<div className={'mr-2'}>
                <EditIcon />
              </div>) : ('')}

              <div>{options.data.Location}</div>
            </div>
          );
        } else {
          return (
            <div className="service-request-view flex  items-center cursor-pointer">
              {hasPermission(CustomerTokens.ModifyDeviceLocation) ? (<div className={'mr-2'}>
                <EditIcon />
              </div>) : ('')}

              <div>-</div>
            </div>
          );
        }
      },
      onCellClicked: (cell) => {
        if(hasPermission(CustomerTokens.ModifyDeviceLocation)) {
          openLocationModal(cell?.data?.EquipmentID)
        }
      },
    },
    {
      headerName: 'Location Remarks',
      field: 'LocationRemarks',
      filterParams: filterParams,
      width: 200,
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.LocationRemarks) {
          // tslint:disable-next-line:max-line-length
          return (
            <div className="service-request-view flex  items-center cursor-pointer">
              {hasPermission(CustomerTokens.ModifyDeviceLocation) ? (
                <div className={'mr-2'}>
                <EditIcon/>
              </div>) : ('')}

              <div>{options.data.LocationRemarks}</div>
            </div>
          );
        } else {
          return (
            <div className="service-request-view flex  items-center cursor-pointer">
              {hasPermission(CustomerTokens.ModifyDeviceLocation) ? (
                <div className={'mr-2'}>
                  <EditIcon/>
                </div>) : ('')}

              <div>-</div>
            </div>
          );
        }
      },
      onCellClicked: (cell) => {
        if(hasPermission(CustomerTokens.ModifyDeviceLocation)) {
          openLocationRemarkModal(cell?.data?.EquipmentID);
        }
      },
    },
    {
      headerName: 'Address',
      field: 'ARCustomerAddress',
      filterParams: filterParams,
      width: 140,
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.ARCustomerAddress) {
          // tslint:disable-next-line:max-line-length
          return (
            <div className="service-request-view">
              {options.data.ARCustomerAddress}
            </div>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'City',
      field: 'City',
      filterParams: filterParams,
      width: 140,
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.City) {
          // tslint:disable-next-line:max-line-length
          return (
            <div className="service-request-view">{options.data.City}</div>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'State',
      field: 'State',
      filterParams: filterParams,
      width: 100,
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.State) {
          // tslint:disable-next-line:max-line-length
          return (
            <div className="service-request-view">{options.data.State}</div>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Covered on Contract',
      field: 'CoveredOnContract',
      filterParams: filterParams,
      width: 140,
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.CoveredOnContract) {
          // tslint:disable-next-line:max-line-length
          return (
            <div className="service-request-view">
              {options.data.CoveredOnContract}
            </div>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Request Service',
      field: '0',
      filter: null,
      sortable: false,
      filterParams: filterParams,
      minWidth: 200,
      width: 160,
      isShowCSVfield: false,
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.EquipmentID) {
          return (
            <Link
              className="borderedBtn linkText hover:text-black"
              to={`/service/request/${options.data.EquipmentID}`}
            >
              Request Service
            </Link>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Order supplies',
      field: '1',
      filter: null,
      sortable: false,
      filterParams: filterParams,
      width: 160,
      minWidth: 200,
      isShowCSVfield: false,
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.EquipmentNumber) {
          return (
            <Link
              className="borderedBtn linkText hover:text-black"
              to={`/product/search/${
                options && options.data && options.data.EquipmentID
              }`}
            >
              Order supplies
            </Link>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'IP Address',
      field: 'IPAddress',
      filterParams: filterParams,
      width: 160,
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.IPAddress) {
          if (!options.data.IPAddress.includes('.')) {
            return options.data.IPAddress;
          } else {
            return (
              <a
                className="service-request-view linkText flex items-center justify-start"
                target="_blank"
                href={`http://${options.data.IPAddress}`}
              >
                {options.data.IPAddress} <span className="ml-2"><LinkOutIcon /></span>
              </a>
            );
          }
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Black (%)',
      width: 300,
      filterParams: filterParams,
      field: 'levelBlack',
      cellRenderer: (options: any) => {
        if (
          options &&
          options.data &&
          (options.data.levelBlack || options.data.levelBlack === 0)
        ) {
          return (
            <>
              <div className="row supply-level">
                <span style={{ width: 28 }}>{options.data.levelBlack}%</span>
                &nbsp;&nbsp;&nbsp;
                <div
                  style={{
                    width: '80%',
                    boxShadow: 'none',
                    height: 6,
                    backgroundColor: '#ddd',
                    marginTop: '-8px',
                  }}
                >
                  <div
                    style={{
                      width: `${options.data.levelBlack}%`,
                      height: 6,
                      background: 'black',
                    }}
                  />
                </div>
              </div>

              <br />
            </>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Cyan (%)',
      width: 300,
      filterParams: filterParams,
      field: 'levelCyan',
      cellRenderer: (options: any) => {
        if (
          options &&
          options.data &&
          (options.data.levelCyan || options.data.levelCyan === 0)
        ) {
          return (
            <>
              <>
                <div className="row supply-level">
                  <span style={{ width: 28 }}>{options.data.levelCyan}%</span>
                  &nbsp;&nbsp;&nbsp;
                  <div
                    style={{
                      width: '80%',
                      boxShadow: 'none',
                      height: 6,
                      backgroundColor: '#ddd',
                      marginTop: '-8px',
                    }}
                  >
                    <div
                      style={{
                        width: `${options.data.levelCyan}%`,
                        height: 6,
                        background: 'cyan',
                      }}
                    />
                  </div>
                </div>
                <br />
              </>
            </>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Magenta (%)',
      width: 300,
      filterParams: filterParams,
      field: 'levelMagenta',
      cellRenderer: (options: any) => {
        if (
          options &&
          options.data &&
          (options.data.levelMagenta || options.data.levelMagenta === 0)
        ) {
          return (
            <>
              <div className="row supply-level">
                <span style={{ width: 28 }}>{options.data.levelMagenta}%</span>
                &nbsp;&nbsp;&nbsp;
                <div
                  style={{
                    width: '80%',
                    boxShadow: 'none',
                    height: 6,
                    backgroundColor: '#ddd',
                    marginTop: '-8px',
                  }}
                >
                  <div
                    style={{
                      width: `${options.data.levelMagenta}%`,
                      height: 6,
                      background: 'magenta',
                    }}
                  />
                </div>
              </div>
              <br />
            </>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Yellow (%)',
      width: 300,
      filterParams: filterParams,
      field: 'levelYellow',
      cellRenderer: (options: any) => {
        if (
          options &&
          options.data &&
          (options.data.levelYellow || options.data.levelYellow === 0)
        ) {
          return (
            <>
              <div className="row supply-level">
                <span style={{ width: 28 }}>{options.data.levelYellow}%</span>
                &nbsp;&nbsp;&nbsp;
                <div
                  style={{
                    width: '80%',
                    boxShadow: 'none',
                    height: 6,
                    backgroundColor: '#ddd',
                    marginTop: '-8px',
                  }}
                >
                  <div
                    style={{
                      width: `${options.data.levelYellow}%`,
                      height: 6,
                      background: 'yellow',
                    }}
                  />
                </div>
              </div>
              <br />
            </>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Last Order (Black)',
      width: 150,
      renderCSVColumn: (record: any) => {
        return record?.LastOrderDateBlack
          ? formattingTimeIntoMMDDYY(record?.LastOrderDateBlack).toString()
          : '';
      },
      filterParams: {
        filterOptions: ['inRange'],
        defaultOption: 'inRange',
        maxNumConditions: 1,
        debounceMs: 500,
        browserDatePicker: true,
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          const dateAsString = cellValue;
          if (dateAsString == null) {
            return -1;
          }
          const dateParts = dateAsString.split('/');
          const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0]),
          );
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      },
      filter: FilterType.AgDateColumnFilter,
      field: 'LastOrderDateBlack',
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.LastOrderDateBlack) {
          return (
            <Link
              className={`service-request-view ${hasPermission(CustomerTokens.ViewPlacedOrder) ? 'linkText' : ''}`}
              to={hasPermission(CustomerTokens.ViewPlacedOrder) ? `/customer/order-detail/${options.data.SOIDBlack}` : ''}
            >
              {formattingTimeIntoMMDDYY(options.data.LastOrderDateBlack)}
            </Link>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Order Status (Black)',
      field: 'OrderStatusBlack',
      filter: null,
      sortable: false,
      filterParams: filterParams,
      width: 160,
      cellRenderer: (options: any) => {
        return <Chip status={options.data.OrderStatusBlack} />;
      },
    },
    {
      headerName: 'Tracking (Black)',
      width: 150,
      filterParams: filterParams,
      field: 'TrackingNumbersBlack',
      filter: FilterType.AgTextColumnFilter,
      cellRenderer: (options: any) => {
        let str: any = [];
        if (options && options.data && options.data.TrackingNumbersBlack) {
          options.data.TrackingNumbersBlack.toString()
            .split(/[\s,]+/)
            .map((trackNumber: string) => {
              str.push(
                <Link
                  className={`${hasPermission(CustomerTokens.ViewPlacedOrder) ? 'linkText' : ''} service-request-view tracking-${trackNumber}`}
                  to={hasPermission(CustomerTokens.ViewPlacedOrder) ? `/customer/order-tracking/${trackNumber}` : ''}
                >
                  {trackNumber}
                </Link>,
              );
            });
          return str;
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Last Replace (Black)',
      width: 150,
      renderCSVColumn: (record: any) => {
        return record?.LastReplaceBlack
          ? formattingTimeIntoMMDDYY(record?.LastReplaceBlack).toString()
          : '';
      },
      filterParams: {
        filterOptions: ['inRange'],
        debounceMs: 500,
        browserDatePicker: true,
        maxNumConditions: 1,

        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          const dateAsString = cellValue;
          if (dateAsString == null) {
            return -1;
          }
          const dateParts = dateAsString.split('/');
          const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0]),
          );
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      },
      filter: FilterType.AgDateColumnFilter,
      field: 'LastReplaceBlack',
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.LastReplaceBlack) {
          // tslint:disable-next-line:max-line-length
          return (
            <div className="service-request-view">
              {formattingTimeIntoMMDDYY(options.data.LastReplaceBlack)}
            </div>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Last Order (Cyan)',
      width: 150,
      renderCSVColumn: (record: any) => {
        return record?.LastOrderDateCyan
          ? formattingTimeIntoMMDDYY(record?.LastOrderDateCyan).toString()
          : '';
      },
      filterParams: {
        filterOptions: ['inRange'],
        debounceMs: 500,
        browserDatePicker: true,
        maxNumConditions: 1,
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          const dateAsString = cellValue;
          if (dateAsString == null) {
            return -1;
          }
          const dateParts = dateAsString.split('/');
          const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0]),
          );
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      },
      filter: FilterType.AgDateColumnFilter,
      field: 'LastOrderDateCyan',
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.LastOrderDateCyan) {
          // tslint:disable-next-line:max-line-length
          return (
            <Link
              className="service-request-view ${customerInfo?.Tokens?.indexOf('CAN_VIEW_PLACED_ORDER') > -1 ? 'linkText' : ''}"
              to={hasPermission(CustomerTokens.ViewPlacedOrder) ? `/customer/order-detail/${options.data.SOIDCyan}` : ''}

            >
              {formattingTimeIntoMMDDYY(options.data.LastOrderDateCyan)}
            </Link>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Order Status (Cyan)',
      field: 'OrderStatusCyan',
      filter: null,
      sortable: true,
      filterParams: filterParams,
      width: 160,
      cellRenderer: (options: any) => {
        return <Chip status={options.data.OrderStatusCyan} />;
      },
    },
    {
      headerName: 'Tracking (Cyan)',
      width: 150,
      filterParams: filterParams,
      field: 'TrackingNumbersCyan',
      filter: FilterType.AgTextColumnFilter,
      cellRenderer: (options: any) => {
        let str: any = [];
        if (options && options.data && options.data.TrackingNumbersCyan) {
          options.data.TrackingNumbersCyan.toString()
            .split(/[\s,]+/)
            .map((trackNumber: any) => {
              str.push(
                <Link
                  className={`service-request-view tracking-${trackNumber} ${hasPermission(CustomerTokens.ViewPlacedOrder) ? 'linkText' : ''}`}
                  to={hasPermission(CustomerTokens.ViewPlacedOrder) ? `/customer/order-tracking/${trackNumber}` : ''}
                >
                  {trackNumber}
                </Link>,
              );
            });
          return str;
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Last Replace (Cyan)',
      width: 150,
      renderCSVColumn: (record: any) => {
        return record?.LastReplaceCyan
          ? formattingTimeIntoMMDDYY(record?.LastReplaceCyan).toString()
          : '';
      },
      filterParams: {
        filterOptions: ['inRange'],
        debounceMs: 500,
        browserDatePicker: true,
        maxNumConditions: 1,
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          const dateAsString = cellValue;
          if (dateAsString == null) {
            return -1;
          }
          const dateParts = dateAsString.split('/');
          const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0]),
          );
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      },
      filter: FilterType.AgDateColumnFilter,
      field: 'LastReplaceCyan',
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.LastReplaceCyan) {
          // tslint:disable-next-line:max-line-length
          return (
            <Link className="service-request-view"
                  to={''}
            >
              {formattingTimeIntoMMDDYY(options.data.LastReplaceCyan)}
            </Link>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Last Order (Magenta)',
      width: 150,
      renderCSVColumn: (record: any) => {
        return record?.LastOrderDateMagenta
          ? formattingTimeIntoMMDDYY(record?.LastOrderDateMagenta).toString()
          : '';
      },
      filterParams: {
        filterOptions: ['inRange'],
        debounceMs: 500,
        browserDatePicker: true,
        maxNumConditions: 1,
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          const dateAsString = cellValue;
          if (dateAsString == null) {
            return -1;
          }
          const dateParts = dateAsString.split('/');
          const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0]),
          );
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      },
      filter: FilterType.AgDateColumnFilter,
      field: 'LastOrderDateMagenta',
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.LastOrderDateMagenta) {
          // tslint:disable-next-line:max-line-length
          return (
            <Link
              className={`service-request-view ${hasPermission(CustomerTokens.ViewPlacedOrder) ? 'linkText' : ''}`}
              to={hasPermission(CustomerTokens.ViewPlacedOrder) ? `/customer/order-detail/${options.data.SOIDMagenta}` : ''}
            >
              {formattingTimeIntoMMDDYY(options.data.LastOrderDateMagenta)}
            </Link>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Order Status (Magenta)',
      field: 'OrderStatusMagenta',
      filter: null,
      sortable: false,
      filterParams: filterParams,
      width: 160,
      cellRenderer: (options: any) => {
        return <Chip status={options.data.OrderStatusMagenta} />;
      },
    },
    {
      headerName: 'Tracking (Magenta)',
      width: 150,
      filterParams: filterParams,
      field: 'TrackingNumbersMagenta',
      filter: FilterType.AgTextColumnFilter,
      cellRenderer: (options: any) => {
        let str: any = [];
        if (options && options.data && options.data.TrackingNumbersMagenta) {
          options.data.TrackingNumbersMagenta.toString()
            .split(/[\s,]+/)
            .map((trackNumber: any) => {
              str.push(
                <Link
                  className={`service-request-view tracking-${trackNumber} ${hasPermission(CustomerTokens.ViewPlacedOrder) ? 'linkText' : ''}`}
                  to={hasPermission(CustomerTokens.ViewPlacedOrder) ? `/customer/order-tracking/${trackNumber}` : ''}
                >
                  {trackNumber}
                </Link>,
              );
            });
          return str;
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Last Replace (Magenta)',
      width: 150,
      renderCSVColumn: (record: any) => {
        return record?.LastReplaceMagenta
          ? formattingTimeIntoMMDDYY(record?.LastReplaceMagenta).toString()
          : '';
      },
      filterParams: {
        filterOptions: ['inRange'],
        debounceMs: 500,
        browserDatePicker: true,
        maxNumConditions: 1,
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          const dateAsString = cellValue;
          if (dateAsString == null) {
            return -1;
          }
          const dateParts = dateAsString.split('/');
          const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0]),
          );
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      },
      filter: FilterType.AgDateColumnFilter,
      field: 'LastReplaceMagenta',
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.LastReplaceMagenta) {
          // tslint:disable-next-line:max-line-length
          return (
            <Link
              className={`service-request-view ${hasPermission(CustomerTokens.ViewPlacedOrder) ? 'linkText' : ''}`}
              to={''}
            >
              {formattingTimeIntoMMDDYY(options.data.LastReplaceMagenta)}
            </Link>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Last Order (Yellow)',
      width: 150,
      renderCSVColumn: (record: any) => {
        return record?.LastOrderDateYellow
          ? formattingTimeIntoMMDDYY(record?.LastOrderDateYellow).toString()
          : '';
      },
      filterParams: {
        filterOptions: ['inRange'],
        debounceMs: 500,
        browserDatePicker: true,
        maxNumConditions: 1,
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          const dateAsString = cellValue;
          if (dateAsString == null) {
            return -1;
          }
          const dateParts = dateAsString.split('/');
          const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0]),
          );
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      },
      filter: FilterType.AgDateColumnFilter,
      field: 'LastOrderDateYellow',
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.LastOrderDateYellow) {
          // tslint:disable-next-line:max-line-length
          return (
            <Link
              className={`service-request-view ${hasPermission(CustomerTokens.ViewPlacedOrder) ? 'linkText' : ''}`}
              to={hasPermission(CustomerTokens.ViewPlacedOrder) ? `/customer/order-detail/${options.data.SOIDYellow}` : ''}
            >
              {formattingTimeIntoMMDDYY(options.data.LastOrderDateYellow)}
            </Link>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Order Status (Yellow)',
      filter: null,
      field: 'OrderStatusYellow',
      filterParams: filterParams,
      width: 160,
      cellRenderer: (options: any) => {
        return <Chip status={options.data.OrderStatusYellow} />;
      },
    },
    {
      headerName: 'Tracking (Yellow)',
      width: 150,
      filterParams: filterParams,
      field: 'TrackingNumbersYellow',
      filter: FilterType.AgTextColumnFilter,
      cellRenderer: (options: any) => {
        let str: any = [];
        if (options && options.data && options.data.TrackingNumbersYellow) {
          options.data.TrackingNumbersYellow.toString()
            .split(/[\s,]+/)
            .map((trackNumber: any) => {
              str.push(
                <Link
                  className={` ${hasPermission(CustomerTokens.ViewPlacedOrder) ? 'linkText' : ''} service-request-view tracking-${trackNumber}`}
                  to={hasPermission(CustomerTokens.ViewPlacedOrder) ? `/customer/order-tracking/${trackNumber}` : ''}
                >
                  {trackNumber}
                </Link>,
              );
            });
          return str;
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Last Replace (Yellow)',
      width: 150,
      renderCSVColumn: (record: any) => {
        return record?.LastReplaceYellow
          ? formattingTimeIntoMMDDYY(record?.LastReplaceYellow).toString()
          : '';
      },
      filterParams: {
        filterOptions: ['inRange'],
        debounceMs: 500,
        browserDatePicker: true,
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          const dateAsString = cellValue;
          if (dateAsString == null) {
            return -1;
          }
          const dateParts = dateAsString.split('/');
          const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0]),
          );
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      },
      filter: FilterType.AgDateColumnFilter,
      field: 'LastReplaceYellow',
      cellRenderer: (options: any) => {
        if (options && options.data && options.data.LastReplaceYellow) {
          return (
            <div className="service-request-view">
              {formattingTimeIntoMMDDYY(options.data.LastReplaceYellow)}
            </div>
          );
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Supplies Last Report',
      width: 150,
      filter: null,
      sortable: false,
      field: '2',
      renderCSVColumn: (record: any) => {
        const allColorAuditDate: any = [];
        if (record?.AuditdateBlack) {
          allColorAuditDate.push(new Date(record.AuditdateBlack));
        }
        if (record?.AuditdateCyan) {
          allColorAuditDate.push(new Date(record.AuditdateCyan));
        }
        if (record?.AuditdateYellow) {
          allColorAuditDate.push(new Date(record.AuditdateYellow));
        }
        if (record?.AuditdateMagenta) {
          allColorAuditDate.push(new Date(record.AuditdateMagenta));
        }
        if (allColorAuditDate.length) {
          return formattingTimeIntoMMDDYY(
            new Date(Math.max.apply(null, allColorAuditDate)),
          ).toString();
        } else {
          return ' ';
        }
      },
      cellRenderer: (options: any) => {
        const allColorAuditDate: any = [];
        if (options && options.data && options.data.AuditdateBlack) {
          allColorAuditDate.push(new Date(options.data.AuditdateBlack));
        }
        if (options && options.data && options.data.AuditdateCyan) {
          allColorAuditDate.push(new Date(options.data.AuditdateCyan));
        }
        if (options && options.data && options.data.AuditdateYellow) {
          allColorAuditDate.push(new Date(options.data.AuditdateYellow));
        }
        if (options && options.data && options.data.AuditdateMagenta) {
          allColorAuditDate.push(new Date(options.data.AuditdateMagenta));
        }
        if (allColorAuditDate.length) {
          return (
            <div className="service-request-view">
              {formattingTimeIntoMMDDYY(
                new Date(Math.max.apply(null, allColorAuditDate)),
              )}
            </div>
          );
        } else {
          return '-';
        }
      },
    },
  ];
  if(!hasPermission(CustomerTokens.PlaceServiceRequest)) {
    columns.forEach((column, index) => {
      if(column.headerName === 'Request Service') {
        columns.splice(index, 1)
      }
    })
  }
  if(!hasPermission(CustomerTokens.PlaceOrder)) {
    columns.forEach((column, index) => {
      if(column.headerName === 'Order supplies') {
        columns.splice(index, 1)
      }
    })
  }
  return columns;
};
