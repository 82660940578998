import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import storage from '../../../../../utils/storage/storage';
import moment from 'moment';
import { ClientStorageKey } from '../../../../../constant/constant';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { ServiceRequest } from './device-widget/services-request/services.request';
import { Order } from './device-widget/order/order';
import {
  formattingTimeIntoMMDDYY,
  generateRandomString,
} from '../../../../../utils/utils';
import { Api } from '../../../../../shared/api/api';
import { EditIcon } from '../../../../../assets/svg/edit-icon';
import { TrashIcon } from '../../../../../assets/svg/trash-icon';
import { DeviceReportingModal } from './Modals/device-reporting-model';
import { AddMeterReadingModalWrap } from './Modals/add-meter-reading-modal';
import { DeviceLocationUpdate } from './Modals/device-location-update-wrapper';
import {
  Form,
  FormItems,
  FormType,
  SubmitBtn,
} from '../../../../../components/form';
import { RetireDeviceModal } from './Modals/retire-this-devcie-modal';
import { EditDeviceModal } from './Modals/edit-device-modal';
import { AddWhiteIcon } from '../../../../../assets/svg/add-white-icon';
import { PushpinIcon } from '../../../../../assets/svg/pushpin-icon';
import { LocationContactIcon } from '../../../../../assets/svg/location-contact-icon';
import { PrinterIcon } from '../../../../../assets/svg/printer-icon';
import { SupplyIcon } from '../../../../../assets/svg/supply-icon';
import { MeterIcon } from '../../../../../assets/svg/meter-icon';
import { DeviceLocationIcon } from '../../../../../assets/svg/device-location-icon';
import { ComponentLoading } from '../../../../../shared/component-loader/component-loading';
import { Chip } from '../../../../../components/ui/Chip';
import { LinkOutIcon } from '../../../../../assets/svg/link-out';
import { useUser } from '../../../../../context/UserContext';
import { CustomerTokens } from '../../../../../types/Customer';

const supplyTonerColor = ['black', 'cyan', 'magenta', 'yellow'];

const DeviceDetail = () => {
  let { equipmentId } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const { customerInfo, hasPermission } = useUser();
  const navigate = useNavigate();
  const [defaultLayout, setDefaultLayout] = useState(null);
  const [resetRefreshToken, setRefreshToken] = useState(null);

  const [deviceDetails, setDeviceDetails] = useState(null);
  const [layout, setLayout] = useState<any>(defaultLayout);
  const [meterDetails, setMeterDetails] = useState(null);
  const [tonnerDetails, setTonnerDetails] = useState(null);
  const [showSupplyLevel, setshowSupplyLevel] = useState(true);
  const [lastOrderTonerLevelData, setlastOrderTonerLevelData] = useState(null);
  const [showSearchdeviceLocation, setShowSearchDeviceLocation] =
    useState(false);
  const [customerAddress, setCustomerAddress] = useState([]);
  const [totalMeterCount, setTotalMeterCount] = useState(null);
  const [showLocationRemarkUpdate, setShowLocationRemarkUpdate] =
    useState(false);
  const [showcostCenterEdit, setShowCostCenterEdit] = useState(false);
  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    const obj = {
      location: query.get('location'),
    };
    storage.setJSONItem(ClientStorageKey.DeviceFilters, obj);
    // window.location.href = '/customer/devices';
    history.back();
  };

  type LayoutType = typeof layout[number];

  useEffect(() => {
    setDefaultLayout([
      {
        i: 'Service Requests',
        x: 0,
        y: 0,
        w: hasPermission(CustomerTokens.ViewPlacedOrder) ? 1 : 2,
        h: 3,
        minH: 3,
        maxH: 6,
        title: 'Service Requests',
        accessToken: [CustomerTokens.PlaceServiceRequest],
        viewAllRouterLink: null,
        plusRouterLink: `/service/request/${equipmentId}`,
        isHidden: !hasPermission(CustomerTokens.ViewServiceRequest),
      },
      {
        i: 'Orders',
        x: 1,
        y: 0,
        w:
        hasPermission(CustomerTokens.ViewServiceRequest)
            ? 1
            : 2,
        h: 3,
        maxH: 6,
        minH: 3,
        title: 'Orders',
        viewAllRouterLink: null,
        accessToken: [CustomerTokens.PlaceOrder],
        plusRouterLink: `/product/search/${equipmentId}`,
        isHidden: !hasPermission(CustomerTokens.ViewPlacedOrder),
      },
    ]);
  }, [customerInfo, resetRefreshToken]);

  useEffect(() => {
    if (defaultLayout !== null) {
      const layoutSettingFromStotage: any = storage.getJSONItem(
        ClientStorageKey.DevicesDetailsGridSettingLayout,
      );
      if (layoutSettingFromStotage) {
        const layoutArr: Array<LayoutType> = [];
        layoutSettingFromStotage.map((props: LayoutType) => {
          const layoutObj = defaultLayout.find(({ i }: any) => i === props.i);
          layoutArr.push({
            ...layoutObj,
            ...props,
            isHidden: layoutObj?.isHidden,
          });
        });
        const filterLayout = layoutArr.filter(
          ({ isHidden }) => isHidden === false,
        );

        setLayout([...filterLayout]);
      } else {
        const filterLayout = defaultLayout.filter(
          ({ isHidden }) => isHidden === false,
        );
        setLayout([...filterLayout]);
      }
    }
  }, [defaultLayout]);

  const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), []);

  const reset = () => {
    storage.removeItem(ClientStorageKey.DevicesDetailsGridSettingLayout);
    setRefreshToken(generateRandomString(5));
  };

  useEffect(() => {
    window.history.pushState({}, '');
    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  const expandContractGrid = (gridName: string) => {
    const changeLayout = [...layout];
    const layoutIndex = changeLayout.findIndex((prop) => prop.i === gridName);
    changeLayout[layoutIndex].w = changeLayout[layoutIndex].w === 1 ? 2 : 1;
    if (changeLayout[layoutIndex].w === 2) {
      const sideIndex = changeLayout.findIndex(
        (prop) => prop.y === changeLayout[layoutIndex].y && prop.i !== gridName,
      );
      if (sideIndex >= 0) {
        changeLayout[sideIndex].y = changeLayout[sideIndex].y + 3;
        changeLayout[sideIndex].x = 0;
        const anotherSideIndex = changeLayout.findIndex(
          (prop) =>
            prop.y === changeLayout[sideIndex].y &&
            prop.i !== changeLayout[sideIndex].i,
        );
        if (anotherSideIndex >= 0) {
          changeLayout[anotherSideIndex].y =
            changeLayout[anotherSideIndex].y + 3;
          changeLayout[anotherSideIndex].x = 0;
        }
      }
    }
    setLayout([...changeLayout]);
    storage.setJSONItem(
      ClientStorageKey.DevicesDetailsGridSettingLayout,
      changeLayout,
    );
  };

  const getLastOrdertonerLevelByDevice = async (tonerLevelData) => {
    try {
      const reqBody = {
        ...tonerLevelData,
        UserId: customerInfo.UserId,
        CustomerId: customerInfo.CustomerId,
        EquipmentId: equipmentId,
        EmailAddress: customerInfo.EmailAddress,
        AllUserOrderView: customerInfo.AllUserOrderView,
        LocationID:
          customerInfo.RestrictUserByLocationID &&
          customerInfo.RestrictUserByLocationID.length
            ? customerInfo.RestrictUserByLocationID
            : null,
        AllowHidingUsersWebOrders: customerInfo.AllowHidingUsersWebOrders,
      };
      const response = await Api.callAPI({
        url: `/api/v1/equipments/${equipmentId}/lastOrderToner`,
        method: 'PUT',
        body: reqBody,
        options: {
          isLoading: false,
          showErrorMessage: true,
        },
      });
      setlastOrderTonerLevelData(response.data);
    } catch (e) {
      navigate('/customer/devices');
    }
  };

  const getTonnerDetails = async () => {
    try {
      const response = await Api.callAPI({
        url: `/api/v1/equipments/${equipmentId}/tonerLevel`,
        method: 'get',
        options: {
          isLoading: false,
          showErrorMessage: true,
        },
      });
      const tonerLevelData = response.data;
      let totalCountOfTonerLevel = null;
      supplyTonerColor.forEach((tonerColor) => {
        if (tonerLevelData[tonerColor] || tonerLevelData[tonerColor] === 0) {
          totalCountOfTonerLevel += tonerLevelData[tonerColor];
        }
      });

      if (tonerLevelData.LastReportedDate) {
        tonerLevelData.LastReportedDate = moment(
          tonerLevelData.LastReportedDate,
        ).format('L');
      }
      setTonnerDetails(tonerLevelData);
      if (!totalCountOfTonerLevel && totalCountOfTonerLevel !== 0) {
        setshowSupplyLevel(false);
      } else {
        await getLastOrdertonerLevelByDevice(tonerLevelData);
      }
    } catch (e) {
      navigate('/customer/devices');
    }
  };
  const getEquipmentDetail = async () => {
    try {
      const searchObj = {
        CustomerID: customerInfo?.CustomerId,
        email: customerInfo?.EmailAddress,
        EquipmentID: equipmentId,
        LocationID: query.get('location'),
      };
      const response = await Api.callAPI({
        url: `/api/v1/equipments/device/${equipmentId}`,
        method: 'put',
        body: searchObj,
        options: {
          isLoading: false,
          showErrorMessage: true,
        },
      });
      setDeviceDetails(response?.data);
    } catch (e) {
      navigate('/customer/devices');
    }
  };

  const getMeterDetails = async () => {
    try {
      const response = await Api.callAPI({
        url: `/api/v1/equipments/meter-data/${equipmentId}`,
        method: 'get',
        options: {
          isLoading: false,
          showErrorMessage: true,
        },
      });
      {
        /*   TO confirm how to calculate meter data */
      }

      const metersData = response?.data;
      setMeterDetails(response?.data);

      // response.map
      if (metersData && metersData.length > 1) {
        let totalCount = 0;
        metersData.map((item) => {
          totalCount += Number(item.Display);
        });
        setTotalMeterCount(totalCount);
      }
    } catch (e) {
      navigate('/customer/devices');
    }
  };

  const getCustomerAddress = async () => {
    try {
      const response = await Api.callAPI({
        url: `/api/v1/customers/${customerInfo?.CustomerId}/address`,
        method: 'get',
        options: {
          isLoading: false,
          showErrorMessage: true,
        },
      });

      setCustomerAddress(response?.data);
    } catch (e) {
      navigate('/customer/devices');
    }
  };

  useEffect(() => {
    (async () => {
      await getCustomerAddress();
      await getTonnerDetails();
      await getEquipmentDetail();
      await getMeterDetails();
    })();
  }, []);

  const onDeviceLocationUpdate = (deviceDetail: any) => {
    setDeviceDetails(deviceDetail);
    setShowSearchDeviceLocation(false);
  };

  const onMeterDataUpdate = async () => {
    await getMeterDetails();
  };

  const onSubmitLocationRemark = async (value) => {
    const reqBody = {
      email: customerInfo.EmailAddress,
      userInfo: {
        CustomerId: customerInfo.CustomerId,
        EmailAddress: customerInfo.EmailAddress,
        FirstName: customerInfo.FirstName,
        LastName: customerInfo.LastName,
        UserPhone: customerInfo.UserPhone,
        BranchID: customerInfo.BranchID,
        BranchNumber: customerInfo.BranchNumber,
      },
      oldDeviceData: deviceDetails,
      newLocationData: {
        ...deviceDetails,
        Location: value.Remark,
      },
      newLocation: value.Remark,
    };

    await Api.callAPI({
      url: `/api/v1/equipments/${equipmentId}/locationRemark`,
      method: 'put',
      body: reqBody,
      options: {
        successMessage: 'Location remarks updated!',
        errorMessage: 'Error while updating equipment location remark.',
        isLoading: true,
      },
    });
    onDeviceLocationUpdate({ ...deviceDetails, Location: value.Remark });
    setShowLocationRemarkUpdate(false);
  };

  const removeLocationDetails = async () => {
    try {
      const deleteConfirmation = window.confirm(
        'Are you sure you want to remove the location remarks for this device?',
      );
      if (deleteConfirmation) {
        const reqBody = {
          email: customerInfo.EmailAddress,
          userInfo: {
            CustomerId: customerInfo.CustomerId,
            EmailAddress: customerInfo.EmailAddress,
            FirstName: customerInfo.FirstName,
            LastName: customerInfo.LastName,
            UserPhone: customerInfo.UserPhone,
            BranchID: customerInfo.BranchID,
            BranchNumber: customerInfo.BranchNumber,
          },
          oldDeviceData: deviceDetails,
          newLocationData: {
            ...deviceDetails,
            Location: '',
          },
          newLocation: '',
        };

        await Api.callAPI({
          url: `/api/v1/equipments/${equipmentId}/locationRemark`,
          method: 'put',
          body: reqBody,
          options: {
            successMessage: 'Location remarks updated!',
            errorMessage: 'Error while updating equipment location remark.',
          },
        });
        onDeviceLocationUpdate({ ...deviceDetails, Location: null });
      }
    } catch {
      navigate('/customer/devices');
    }
  };
  const onSubmitCostCenter = async (value) => {
    const reqBody = {
      email: customerInfo.EmailAddress,
      userInfo: {
        CustomerId: customerInfo.CustomerId,
        EmailAddress: customerInfo.EmailAddress,
        FirstName: customerInfo.FirstName,
        LastName: customerInfo.LastName,
        UserPhone: customerInfo.UserPhone,
        BranchID: customerInfo.BranchID,
        BranchNumber: customerInfo.BranchNumber,
      },
      oldDeviceData: deviceDetails,
      newLocation: deviceDetails.Location,
      newLocationData: {
        ...deviceDetails,
        CostCenter: value?.CostCenter,
      },
    };

    await Api.callAPI({
      url: `/api/v1/equipments/${equipmentId}/CostCenter`,
      method: 'put',
      body: reqBody,
      options: {
        successMessage:
          'Your change request has been submitted to our team for review.',
        errorMessage: 'Error while updating equipment.',
        isLoading: true,
      },
    });
    onDeviceLocationUpdate({ ...deviceDetails, CostCenter: value?.CostCenter });

    setShowCostCenterEdit(false);
  };
  const LocationRemarkForm = () => {
    return (
      <>
        <div>
          <div>
            <Form onSubmit={onSubmitLocationRemark}>
              <div className="flex w-full">
                <FormItems
                  items={[
                    {
                      name: 'Remark',
                      label: 'Remarks',
                      defaultValue: deviceDetails.Location,
                      type: FormType.TEXT,
                      maxLength: customerInfo?.LongerLocationRemarks ? 80 : 24,
                      required: true,
                      errors: {
                        required: 'Please provide valid location remarks.',
                      },
                    },
                  ]}
                  className="w-full"
                />
                <div className="mt-5 ml-2">
                  <SubmitBtn className={`commonBtn`}>Submit</SubmitBtn>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </>
    );
  };

  // const getProgressColor = (tonnerColor) => {
  //   if(tonnerColor)
  // }
  const CostCenterForm = () => {
    return (
      <>
        <div>
          <div>
            <Form onSubmit={onSubmitCostCenter}>
              <div className={"flex w-full"}>
                <FormItems
                  items={[
                    {
                      name: 'CostCenter',
                      label: 'Cost Center/GL Code',
                      defaultValue: deviceDetails?.CostCenter,
                      placeholder: 'Cost Center/GL Code',
                      type: FormType.TEXT,
                      required: true,
                      errors: {
                        required: 'Please provide valid cost center/GL code.',
                      },
                    },
                  ]}
                  className="w-full"
                />
                <div className="mt-5 ml-2 ">
                  <SubmitBtn className={`commonBtn`}>Submit</SubmitBtn>
                </div>
              </div>

            </Form>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {!deviceDetails ? (
        <ComponentLoading isShowLoader={true} />
      ) : (
        <>
          <div className="flex justify-between items-center mb-8">
            <div className="flex lg:items-center pr-4">
              <h1 className="lg:text-2xl md:text-xl sm:text-lg text-base font-medium">
                Device Details - {deviceDetails?.EquipmentNumber}
              </h1>
            </div>

            <div className="md:flex hidden justify-end">
              {hasPermission(CustomerTokens.RemoveDeviceLocations) ? (
                <RetireDeviceModal
                  deviceDetails={deviceDetails}
                  equipmentId={equipmentId}
                />
              ) : (
                ''
              )}
              {hasPermission(CustomerTokens.ModifyDeviceLocation) ? (
                <EditDeviceModal
                  deviceDetails={deviceDetails}
                  equipmentId={equipmentId}
                  customerAddress={customerAddress}
                />
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="sm:flex lg:flex-nowrap flex-wrap lg:gap-8 gap-0 mb-10">
            <div className="lg:w-1/3 sm:w-1/2 sm:mb-0 mb-4 md:pr-4">
              <div className="flex items-center lg:mb-4 mb-2">
                <PrinterIcon />
                <h4 className="ml-2 text-base font-semibold">
                  Device Information
                </h4>
              </div>
              <div className="ml-7 text-sm">
                {deviceDetails?.EquipmentNumber ? (
                  <div className="mb-1">
                    <span className="text-gray-600">Equipment Number:</span>
                    <span className="text-gray-800 ml-1">
                      {deviceDetails?.EquipmentNumber}
                    </span>
                  </div>
                ) : (
                  ''
                )}
                {deviceDetails?.SerialNumber ? (
                  <div className="mb-1">
                    <span className="text-gray-600">Serial Number:</span>
                    <span className="text-gray-800 ml-1">
                      {deviceDetails?.SerialNumber}
                    </span>
                  </div>
                ) : (
                  ''
                )}

                {deviceDetails?.Model ? (
                  <div className="mb-1">
                    <span className="text-gray-600">Model:</span>
                    <span className="text-gray-800 ml-1">
                      {deviceDetails?.Model}
                    </span>
                  </div>
                ) : (
                  ''
                )}

                {deviceDetails?.Description ? (
                  <div className="mb-1">
                    <span className="text-gray-600">Descriptions:</span>
                    <span className="text-gray-800 ml-1">
                      {deviceDetails?.Description}
                    </span>
                  </div>
                ) : (
                  ''
                )}
                {deviceDetails?.IPAddress ? (
                  <div className="mb-1">
                    <span className="text-gray-600 flex items-center justify-start">
                      IP Address:
                      <a
                        href={'http://' + deviceDetails?.IPAddress}
                        target={'_blank'}
                        className="linkText flex items-center justify-start ml-2"
                      >
                        {deviceDetails?.IPAddress}{' '}
                        <span className="ml-2">
                          <LinkOutIcon />
                        </span>
                      </a>
                    </span>
                  </div>
                ) : (
                  ''
                )}
                {deviceDetails?.MACAddress ? (
                  <div className="mb-1">
                    <span className="text-gray-600">MAC Address:</span>
                    <span className="text-gray-800 ml-1">
                      {deviceDetails?.MACAddress}
                    </span>
                  </div>
                ) : (
                  ''
                )}
                {deviceDetails?.CoveredOnContract ? (
                  <div className="mb-1">
                    <span className="text-gray-600">Covered on Contract:</span>
                    <span className="text-gray-800 ml-1">
                      {deviceDetails?.CoveredOnContract}
                    </span>
                  </div>
                ) : (
                  ''
                )}
                {deviceDetails?.ContractExpDate ? (
                  <div className="mb-1">
                    <span className="text-gray-600">
                      Lease Expiration Date:
                    </span>
                    <span className="text-gray-800 ml-1">
                      {formattingTimeIntoMMDDYY(
                        deviceDetails?.ContractExpDate,
                      ).toString()}
                    </span>
                  </div>
                ) : (
                  ''
                )}
                {deviceDetails?.ReportedRecently ? (
                  <div className="mb-1">
                    <span className="text-gray-600">
                      Reported in Last Week:
                    </span>
                    <span className="text-gray-800 ml-1">
                      {deviceDetails?.ReportedRecently}
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>

            <>
              <div className="lg:w-1/3 sm:w-full lg:mt-0 sm:mt-6">
                <div className="flex items-center lg:mb-4 mb-2">
                  <SupplyIcon />
                  <h4 className="ml-2 text-base font-semibold">
                    Supply Levels
                  </h4>
                </div>

                {showSupplyLevel ? (
                  <div className="ml-7 text-sm">
                    <div className="mb-6">
                      <span className="text-gray-600">
                        Last Reported Date -
                      </span>
                      <span className="text-gray-800 ml-1">
                        {formattingTimeIntoMMDDYY(
                          tonnerDetails?.LastReportedDate,
                        ).toString() || 'N/A'}
                      </span>
                    </div>
                    <div className="">
                      {lastOrderTonerLevelData?.map(
                        (order: any, lastOrderTonerLevelDataIndex: number) => (
                          <div
                            key={
                              'lastOrderTonerLevelData' +
                              lastOrderTonerLevelDataIndex
                            }
                          >
                            {tonnerDetails[order.TonerColor] ||
                            tonnerDetails[order.TonerColor] === 0 ? (
                              <>
                                <div className="mb-4 flex items-center w-full">
                                  <div className="text-gray-600 text-sm w-20">
                                    {order.TonerColor.charAt(0).toUpperCase() +
                                      order.TonerColor.slice(1)}
                                  </div>
                                  <div className="flex items-center w-full">
                                    <div
                                      className="mr-4 h-3 rounded-lg overflow-hidden"
                                      style={{
                                        width: '80%',
                                        boxShadow: 'none',
                                        backgroundColor: '#ddd',
                                      }}
                                    >
                                      <div
                                        className="h-3"
                                        style={{
                                          width: `${
                                            tonnerDetails[order.TonerColor]
                                          }%`,
                                          background: order.TonerColor,
                                        }}
                                      />
                                    </div>

                                    <div className="=mt-1">
                                      {tonnerDetails[order.TonerColor]}%
                                    </div>
                                  </div>
                                </div>
                                <div className="flex mb-4 lg:hidden">
                                  <h3 className="mr-2 font-semibold">
                                    Last Order :
                                  </h3>
                                  <div className="mr-4">
                                    {order.SOID ? (
                                      <div>
                                        <Link
                                          to={
                                            hasPermission(CustomerTokens.ViewPlacedOrder)
                                              ? `/customer/order-detail/${order.SOID}`
                                              : ''
                                          }
                                        >
                                          {order.CreateDate}
                                        </Link>
                                      </div>
                                    ) : (
                                      <div>
                                        <strong>N/A</strong>
                                      </div>
                                    )}
                                  </div>

                                  {order.SOID ? (
                                    <div>
                                      <div>
                                        <span>
                                          <Chip status={order.Status} />
                                        </span>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </>
                            ) : null}
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="ml-7">
                    <div className="w-64 text-sm">
                      This device isn't reporting supply levels, want to see if
                      it can?
                    </div>
                    <span>
                      <DeviceReportingModal
                        deviceDetails={deviceDetails}
                        equipmentId={equipmentId}
                      />
                    </span>
                  </div>
                )}
              </div>

              {showSupplyLevel ? (
                <div className="lg:w-1/3 sm:w-full lg:mt-0 sm:mt-6 lg:block hidden">
                  <div className="flex items-center lg:mb-4 mb-2">
                    <LocationContactIcon />
                    <h4 className="ml-2 text-base font-semibold">
                      Last Orders
                    </h4>
                  </div>
                  <div className="ml-7 text-sm">
                    <div className="h-5 mb-6">&nbsp;</div>
                    {lastOrderTonerLevelData?.map(
                      (order: any, lastOrderTonerLevelDataIndex: number) => (
                        <div
                          key={
                            'lastOrderTonerLevelData' +
                            lastOrderTonerLevelDataIndex
                          }
                        >
                          {tonnerDetails[order.TonerColor] ||
                          tonnerDetails[order.TonerColor] === 0 ? (
                            <div className="flex mb-4">
                              <div className="mr-4">
                                {order.SOID ? (
                                  <div>
                                    <Link
                                      to={
                                        hasPermission(CustomerTokens.ViewPlacedOrder)
                                          ? `/customer/order-detail/${order.SOID}`
                                          : ''
                                      }
                                      className={` ${
                                        hasPermission(CustomerTokens.ViewPlacedOrder)
                                          ? 'linkText cursor-pointer'
                                          : ''
                                      }`}
                                    >
                                      {order.CreateDate}
                                    </Link>
                                  </div>
                                ) : (
                                  <div>
                                    <strong>N/A</strong>
                                  </div>
                                )}
                              </div>

                              {order.SOID ? (
                                <div>
                                  <div>
                                    <span>
                                      <Chip status={order.Status} />
                                    </span>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      ),
                    )}
                  </div>
                </div>
              ) : null}
            </>
          </div>

          <div className="sm:flex xl:flex-nowrap flex-wrap xl:gap-8 gap-0 mb-10">
            {meterDetails && meterDetails.length ? (
              <>
                <div className="lg:w-1/3 sm:w-1/2 sm:mb-0 mb-4 sm:pr-4">
                  <div
                    className="flex items-center lg:mb-4 mb-2"
                    title="Add new meter"
                  >
                    <MeterIcon />
                    <div className="flex justify-between w-full">
                      <h4 className="ml-2 text-base font-semibold">Meters</h4>
                      <AddMeterReadingModalWrap
                        meterDetails={meterDetails}
                        equipmentId={equipmentId}
                        onMeterDataUpdate={onMeterDataUpdate}
                      />
                    </div>
                  </div>
                  <div className="ml-7 text-sm">
                    {meterDetails?.map(
                      (item: any, meterDetailsIndex: number) => (
                        <div key={'meterDetails' + meterDetailsIndex}>
                          <div className="mb-1">
                            <span className="text-gray-600">
                              {item?.MeterType}
                            </span>
                            <span className="text-gray-800 ml-1">
                              {Math.round(item?.Display)}
                            </span>
                          </div>
                          <div className="mb-1">
                            <span className="text-gray-600">
                              Last Reading Date
                            </span>
                            <span className="text-gray-800 ml-1">
                              {formattingTimeIntoMMDDYY(
                                item?.LastReadingDate,
                              ).toString()}
                            </span>
                          </div>
                        </div>
                      ),
                    )}
                    {totalMeterCount ? (
                      <div className="mb-1">
                        <span className="text-gray-600">Total Count</span>
                        <span className="text-gray-800 ml-1">
                          {Math.round(totalMeterCount)}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}

            <div className="lg:w-1/3 sm:w-1/2 sm:mb-0 mb-4 sm:pr-4">
              <div className="flex items-center lg:mb-4 mb-2">
                <DeviceLocationIcon />
                <div className="flex justify-between w-full">
                  <h4 className="ml-2 text-base font-semibold">
                    Device Location
                  </h4>
                  {!showSearchdeviceLocation && hasPermission(CustomerTokens.ModifyDeviceLocation) ? (
                    <button
                      type="button"
                      className="fillBtnLink"
                      title={'Edit Device Location'}
                      onClick={() =>
                        setShowSearchDeviceLocation(!showSearchdeviceLocation)
                      }
                    >
                      <EditIcon />
                    </button>
                  ) : null}
                </div>
              </div>
              <div className="ml-7 text-sm">
                <div>
                  {showSearchdeviceLocation ? (
                    <DeviceLocationUpdate
                      customerInfo={customerInfo}
                      deviceDetails={deviceDetails}
                      equipmentId={equipmentId}
                      customerAddress={customerAddress}
                      onDeviceLocationUpdate={onDeviceLocationUpdate}
                    />
                  ) : null}
                </div>
                <div>
                  <p>
                    {deviceDetails?.CustomerName}
                    <br />
                    {deviceDetails?.Address}
                    <br />
                    {deviceDetails?.City}, {deviceDetails?.State},{' '}
                    {deviceDetails?.Zip}
                    <br />
                    {deviceDetails?.Country}
                  </p>
                </div>
              </div>
            </div>

            <div className="lg:w-1/3 sm:w-1/2 sm:mb-0 mb-4 sm:pr-4">
              <div className="flex items-center lg:mb-4 mb-2">
                <PushpinIcon />
                <div className="flex justify-between w-full">
                  <h4 className="ml-2 text-base font-semibold">
                    Location Remark
                  </h4>
                  {!showLocationRemarkUpdate && hasPermission(CustomerTokens.ModifyDeviceLocation) &&
                  customerInfo?.RestrictUserByLocationID?.length !== 1 ? (
                    <>
                      {deviceDetails?.Location ? (
                        <>
                          <div>
                            <button
                              title={'Edit Location Remarks'}
                              className="fillBtnLink"
                              onClick={() =>
                                setShowLocationRemarkUpdate(
                                  !showLocationRemarkUpdate,
                                )
                              }
                            >
                              <EditIcon />
                            </button>
                            <button
                              title={'Remove Location Remarks'}
                              className="strokeBtnLink ml-4"
                              onClick={() => removeLocationDetails()}
                            >
                              <TrashIcon />
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <button
                              title={'Add Location Remarks'}
                              onClick={() =>
                                setShowLocationRemarkUpdate(
                                  !showLocationRemarkUpdate,
                                )
                              }
                            >
                              <div className="bgColor">
                                <AddWhiteIcon />
                              </div>
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="ml-7 text-sm">
                {showLocationRemarkUpdate ? <LocationRemarkForm /> : null}
                {deviceDetails?.Location}
              </div>
            </div>

            {customerInfo?.CustomerCanViewGLCode ? (
              <div className="lg:w-1/3 sm:w-1/2 sm:mb-0 mb-4 sm:pr-4">
                <div className="flex items-center lg:mb-4 mb-2">
                  <PushpinIcon />
                  <div className="flex justify-between w-full">
                    <h4 className="ml-2 text-base font-semibold">
                      Cost Center/GL Code
                    </h4>
                    {!showcostCenterEdit && hasPermission(CustomerTokens.EditCostCenter) ? (
                      <>
                        {!deviceDetails?.CostCenter ? (
                          <button onClick={() => setShowCostCenterEdit(true)}>
                            <div
                              className="bgColor"
                              title="Add cost center/GL code"
                            >
                              <AddWhiteIcon />
                            </div>
                          </button>
                        ) : (
                          <button onClick={() => setShowCostCenterEdit(true)}>
                            <div
                              className="fillBtnLink"
                              title="Edit cost center/GL code"
                            >
                              <EditIcon />
                            </div>
                          </button>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="ml-7 text-sm">
                  {showcostCenterEdit && hasPermission(CustomerTokens.EditCostCenter) ? (
                    <>
                      <CostCenterForm />
                    </>
                  ) : null}
                  {deviceDetails?.CostCenter}
                </div>
              </div>
            ) : null}
          </div>

          <div>
            <div className="dashboard-main">
              {(hasPermission(CustomerTokens.ViewPlacedOrder) || hasPermission(CustomerTokens.ViewServiceRequest)) && (
                <div className="md:flex hidden justify-end border-t border-gray-200 pt-4 mb-4">
                  <button
                    className={`primaryBtn bgColor`}
                    onClick={() => {
                      reset();
                    }}
                  >
                    <div className="sm:flex hidden">Reset</div>
                  </button>
                </div>
              )}
              {layout !== null ? (
                <ResponsiveGridLayout
                  cols={{ lg: 2, md: 2, sm: 1, xs: 1, xxs: 1 }}
                  draggableHandle=".drag-handle"
                  //isBounded={true}
                  isResizable={false}
                  useCSSTransforms={true}
                  onDragStop={(changedLayout) => {
                    const changeLayout = [
                      ...layout.map((prop) => {
                        const filteredData = changedLayout.find(
                          (l: any) => l.i === prop.i,
                        );
                        return {
                          ...prop,
                          w: filteredData.w,
                          x: filteredData.x,
                          y: filteredData.y,
                          h: filteredData.h,
                        };
                      }),
                    ];
                    setLayout([...changeLayout]);
                    storage.setJSONItem(
                      ClientStorageKey.DevicesDetailsGridSettingLayout,
                      changeLayout,
                    );
                  }}
                >
                  {layout.map((prop) => {
                    return !prop.isHidden ? (
                      <div className="" key={prop.i} data-grid={{ ...prop }}>
                        {prop.i === 'Service Requests' ? (
                          <ServiceRequest
                            title={prop.title}
                            viewAllRouterLink={prop.viewAllRouterLink}
                            plusRouterLink={prop.plusRouterLink}
                            accessToken={prop.accessToken}
                            expandContractGrid={() =>
                              expandContractGrid(prop.i)
                            }
                            isExpand={prop.w === 2}
                          />
                        ) : (
                          ''
                        )}
                        {prop.i === 'Orders' ? (
                          <Order
                            title={prop.title}
                            viewAllRouterLink={prop.viewAllRouterLink}
                            plusRouterLink={prop.plusRouterLink}
                            accessToken={prop.accessToken}
                            expandContractGrid={() =>
                              expandContractGrid(prop.i)
                            }
                            isExpand={prop.w === 2}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    );
                  })}
                </ResponsiveGridLayout>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="flex sm:justify-end justify-center mt-6">
            {hasPermission(CustomerTokens.PlaceServiceRequest) ? (
              <button
                className="borderedBtn linkText hover:text-black mr-4 "
                onClick={() => navigate(`/service/request/${equipmentId}`)}
              >
                Request Service
              </button>
            ) : (
              ''
            )}

            {hasPermission(CustomerTokens.PlaceOrder) ? (
              <button
                className="primaryBtn bgColor"
                onClick={() => navigate(`/product/search/${equipmentId}`)}
              >
                Order Supplies
              </button>
            ) : (
              ''
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DeviceDetail;
