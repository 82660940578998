import React, { useEffect, useState } from 'react';

import { Option, SelectProps } from '../lib/interfaces';

const defaultStrings: any = {
  allItemsAreSelected: 'All items are selected.',
  clearSearch: 'Clear Search',
  clearSelected: 'Clear Selected',
  noOptions: 'No options',
  search: 'Search',
  selectAll: 'Select All',
  selectAllFiltered: 'Select All (Filtered)',
  selectSomeItems: 'Select...',
  create: 'Create',
};

const defaultProps: Partial<SelectProps> = {
  value: [],
  hasSelectAll: true,
  className: 'react-select-components',
  debounceDuration: 200,
  options: [] as Option[],
  isDisplayElipsis: false,
  onChange: () => {},
  selectAllValue: 'Select All',
};

interface ReactSelectContextProps extends SelectProps {
  t: (key: string) => string;
  setOptions?: any;
}

interface ReactSelectProviderProps {
  props: SelectProps;
  children?: any;
}

const ReactSelectContext = React.createContext<ReactSelectContextProps>(
  {} as ReactSelectContextProps,
);

export const ReactSelectProvider = ({
  props,
  children,
}: ReactSelectProviderProps) => {
  const [options, setOptions] = useState(props.options);
  const t = (key: any) => props.overrideStrings?.[key] || defaultStrings[key];

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  return (
    <ReactSelectContext.Provider
      value={{ t, ...defaultProps, ...props, options, setOptions }}
    >
      {children}
    </ReactSelectContext.Provider>
  );
};

export const useSelectContext = () => React.useContext(ReactSelectContext);
