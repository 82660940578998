export const HeaderCartIcon = () => {
  return (<svg
    width="22"
    height="22"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 10H31L27.1 24H13.9"
      stroke="#ad1f23"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.5 31C25.3284 31 26 30.3284 26 29.5C26 28.6716 25.3284 28 24.5 28C23.6716 28 23 28.6716 23 29.5C23 30.3284 23.6716 31 24.5 31Z"
      fill="#ad1f23"
      stroke="#ad1f23"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 31C16.3284 31 17 30.3284 17 29.5C17 28.6716 16.3284 28 15.5 28C14.6716 28 14 28.6716 14 29.5C14 30.3284 14.6716 31 15.5 31Z"
      fill="#ad1f23"
      stroke="#ad1f23"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 1H5.5C6.6 1 7.7 1.90002 8 2.90002L13.9 24"
      stroke="#ad1f23"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  );
};
