export const withOutAuthUrls = [
    '/login',
    '/auth',
    '/forgot-password',
    '/setUserPassword',
    '/sso-logout',
    '/sso-login-error',
    '/crm',
    '/authentication',
    '/auth-migration'
  ];

export const withoutHeaderUrls = [
    ...withOutAuthUrls,
    '/select-company',
];