import { Button, ButtonProps } from "../../ui/Button";
import { useForm } from "../Form";

interface SubmitProps extends ButtonProps {
    className?: string;
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SubmitBtn = ({children, className, onClick, ...rest} : SubmitProps) => {

    const { handleSubmit, isSubmitDisabled, isSubmitting } = useForm();

    //handle on click and submit
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(onClick) onClick(event);
        handleSubmit(event);
    }

    return <Button
        role="submit"
        className={className}
        //fullWidth
        disabled={isSubmitDisabled}
        loading={isSubmitting}
        onClick={handleClick}
        {...rest}
        >{children}</Button>;
}