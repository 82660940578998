import { useRef, useState } from 'react';
import ApiTable from '../../../../../shared/api-table/api-table';
import { ClientGridName } from '../../../../../constant/constant';
import { TaskDetailTableField } from './task-detail-table-field';
import { ResetIcon } from '../../../../../assets/svg/reset-icon';

const TaskDetails = ({ requestDetailsData, setDefaultValue }) => {
  const ref = useRef(null);
  const [showData, setShowData] = useState(false);

  const columns = TaskDetailTableField({
    setDefaultValue,
    showData,
    setShowData,
  });
  return (
    <>
      <div className="flex mb-4 items-center justify-between">
        <h4 className="text-xl font-semibold">Task</h4>
        <button
          className={`flex items-center borderedBtn sm:ml-4 linkText hover:text-black`}
        >
          <ResetIcon />
          <div
            className={`ml-2 text-sm`}
            onClick={() => {
              if (ref.current) {
                ref.current.resetGrid();
              }
            }}
          >
            Reset Grid
          </div>
        </button>
      </div>
      {requestDetailsData ? (
        <ApiTable
          ref={ref}
          gridName={ClientGridName.TaskDetails}
          columns={columns}
          rowData={requestDetailsData}
          gridOptions={{
            pagination: false,
          }}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default TaskDetails;
