interface ILocalStorage {
  setItem: (key: string, value: string) => void;
  getItem: (key: string) => string | null;
  removeItem: (key: string) => void;
}

class LocalStorageClass {
  localStorage: ILocalStorage;

  constructor() {
    try {
      // eslint-disable-next-line no-undef
      this.localStorage = window.localStorage;
    } catch (e) {
      this.localStorage = {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getItem: (key) => null,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setItem: (key, value) => {},
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        removeItem: (key) => {},
      };
    }
  }

  setItem(key: string, value: string) {
    this.localStorage.setItem(key, value);
  }

  getItem(key: string) {
    return this.localStorage.getItem(key);
  }

  removeItem(key: string) {
    this.localStorage.removeItem(key);
  }

  setJSONItem(key: string, value: object) {
    const updateValue = JSON.stringify(value);
    this.localStorage.setItem(key, updateValue);
  }

  getJSONItem(key: string) {
    let returnValue = {};
    try {
      const value = this.localStorage.getItem(key);
      returnValue = value ? JSON.parse(value) : null;
    } catch (e) {
      // do nothing
    }
    return returnValue;
  }
}

const storage = new LocalStorageClass();
export default storage;
