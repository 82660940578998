export const ReportColumns = ({ ShowRegionCodeInGrid, newColumns }) => {
  const filterParams = {
    filterOptions: ['contains'],
    defaultOption: 'contains',
    maxNumConditions: 1,
    debounceMs: 200,
  };

  const regionCode: any = ShowRegionCodeInGrid
    ? [
        {
          headerName: 'Region',
          field: 'Region',
          width: 120,
          filterParams: filterParams,
        },
      ]
    : [];

  let gridColumns =
    newColumns && newColumns.length
      ? newColumns.map((op: any) => {
          return {
            ...op,
            filterParams,
          };
        })
      : [];

  let orderColumns = [];
  const index = gridColumns.findIndex((op) => op?.order !== undefined);
  if (index > -1) {
    const orderColumn = gridColumns[index];
    orderColumns.push(orderColumn);
    gridColumns.splice(index, 1);
  }
  return [
    ...orderColumns,
    ...regionCode,
    ...[
      {
        headerName: 'Equipment #',
        field: 'EquipmentNumber',
        filterParams: filterParams,
      },
      {
        headerName: 'Serial Number',
        field: 'SerialNumber',
        filterParams: filterParams,
      },
      {
        headerName: 'Manufacturer',
        field: 'Make',
        filterParams: filterParams,
      },
      {
        headerName: 'Model',
        field: 'Model',
        filterParams: filterParams,
      },
      {
        headerName: 'Location Name',
        field: 'LocationName',
        filterParams: filterParams,
      },
      {
        headerName: 'Street',
        field: 'Address',
        filterParams: filterParams,
      },
      {
        headerName: 'State',
        field: 'State',
        filterParams: filterParams,
      },
      {
        headerName: 'City',
        field: 'City',
        filterParams: filterParams,
      },
    ],
    ...gridColumns,
  ];
};
