import React from "react";
import Image404 from '../../../assets/images/image404.png';
export const ClientNotFound = () => {
  return (
    <div className=' h-screen flex items-center'>
      <div className='flex justify-center items-center w-full'>
        <div className={'justify-center'}>
          <h2 className='text-6xl font-normal uppercase'>
            <div className={`font-bold linkText`}>404 ERROR</div>
            PAGE NOT FOUND
          </h2>
          <div className='text-lg text-gray-600'>We can’t seem to find the page you’re looking for.</div>
        </div>
        <div>
          <img src={Image404}  alt={''}/>
        </div>
      </div>
    </div>
  );

};
