import { Chip } from "../../../../../components/ui/Chip";
import { getTime } from "../../../../../utils/utils";
import React from "react";

export const TaskDetailTableField = ({
  setDefaultValue,
  setShowData,
  showData,
}: any) => {
  const filterParams = {
    filterOptions: ['contains'],
    defaultOption: 'contains',
    maxNumConditions: 1,
    debounceMs: 200,
  };
  return [
    {
      headerName: 'Task #',
      field: 'CallNumber',
      filterParams: filterParams,
      cellRenderer: (options: any) => {
        return <p className="linkText">{options.data.CallNumber}</p>;
      },
      onCellClicked: ({ data }: any) => {
        setDefaultValue(data);
        setShowData(!showData);
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
      },
      suppressSizeToFit: true,
    },
    {
      headerName: 'Request Placed',
      filter: null,
      sortable: false,
      field: 'Date',
      suppressSizeToFit: true,
      cellRenderer: (cell: any) => {
        if (cell && cell.data) {
          const Date = getTime(cell.data.Date);
          return <div>{Date}</div>;
        } else {
          return '';
        }
      },
    },
    {
      headerName: 'Problem Description',
      field: 'ProblemDescription',
      filterParams: filterParams,
      autoHeight: 1,
      suppressSizeToFit: true,
      cellRenderer: (option: any) => {
        let data = '';
        if (option.data.ProblemDescription[1] !== undefined) {
          data = option.data.ProblemDescription[1];
        } else {
          data = '';
        }
        return (
            <div className={`${data.length ? 'lineHeight22' : 'lineHeight48'}`}>
              {option.data.ProblemDescription[0] || ''}
              {data.length ? <br /> : <></>}
              {data}
            </div>
        );
      },
    },
    {
      headerName: 'Task Notes',
      suppressSizeToFit: true,
      field: 'Notes',
      filterParams: filterParams,
    },
    {
      headerName: 'Status',
      field: 'Status',
      filter: null,
      sortable: false,
      suppressSizeToFit: true,
      cellRenderer: (cell: any) => {
        if (!cell.data) {
          return '';
        }
        return <Chip status={cell.data.Status} />;
      },
    },
  ];
};
