import React, { ReactNode, useEffect, useState } from 'react';
import Header from './header/header';
import Footer from './footer/footer';
import { CartHeaderDialog } from './cart-header-dialog/cart-header-dialog';
import { useUser } from '../../../context/UserContext';
import { ComponentLoading } from '../../../shared/component-loader/component-loading';
import { PopupProvider } from '../context/PopupContext';

interface LayoutProps {
  children: ReactNode;
}
export const Layout = ({ children }: LayoutProps) => {
  const { customerInfo } = useUser();
  const [cartDialogOpen, setCarDialog] = useState(false);
  const [sideBarClass, setSideBarClass] = useState('');

  useEffect(() => {
    if (cartDialogOpen) {
      document.body.classList.add('overflow-hidden');
      document.body.classList.add('blackOutBg');

      setTimeout(() => {
        setSideBarClass('openCartMenu');
      }, 10);
    } else {
      document.body.classList.remove('overflow-hidden');
      document.body.classList.remove('blackOutBg');
    }
  }, [cartDialogOpen]);


  if(!customerInfo.EmailAddress) {
    return <div className="w-screen flex items-center justify-center min-h-[75vh]">
      <ComponentLoading isShowLoader={true}/>
    </div>;
  }

  return (
    <>
      <div className="mainContent fch100">
        <Header setCarDialog={setCarDialog} />
        <div className="mainContainer fch100 xl:px-28 lg:px-12 px-4 lg:py-8 py-4 relative">
          <div>
            {cartDialogOpen ? (
              <CartHeaderDialog
                windowHeight={window.innerHeight}
                cartDialogOpen={cartDialogOpen}
                setCarDialog={setCarDialog}
                sideBarClass={sideBarClass}
                setSideBarClass={setSideBarClass}
              />
            ) : (
              ''
            )}
          </div>
          {customerInfo.EmailAddress ? (
            <PopupProvider>
              {children}
            </PopupProvider>
          ) : (
            ''
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};
