import { Link } from "react-router-dom";
import { useFlexLayer } from "../../../hooks/useFlexlayer"
import { Popup } from "../../client/layout/popup/types/popup";

interface AdminDashboardData {
    userCount: number,
    customerCount: number,
    ordersPlacedThisWeek: number,
    activeOrders: number,
    allFeedBack: Popup[],
    serviceRequestPastWeek: number,
}

export const AdminDashboard = () => {

    const { loading, error, data }: {
        loading: boolean,
        error: any,
        data: AdminDashboardData
    } = useFlexLayer('/adminDash');

    if(loading) return <div>Loading...</div>
    if(error) return <div>Error</div>

    const options = new Array(10).fill(null);




    return <div>
        <h1 className="text-2xl font-bold mb-6">Admin Dashboard</h1>
        <div className="grid lg:grid-cols-4 gap-4">
            <div className="shadow rounded p-4">
                <h2 className="text-xl font-bold">Users</h2>
                <p>{ data.userCount }</p>
            </div>
            <div className="shadow rounded p-4">
                <h2 className="text-xl font-bold">Customers</h2>
                <p>{ data.customerCount }</p>
            </div>
            <div className="shadow rounded p-4">
                <h2 className="text-xl font-bold">Orders This Week</h2>
                <p>{ data.ordersPlacedThisWeek }</p>
            </div>
            <div className="shadow rounded p-4">
                <h2 className="text-xl font-bold">Requests This Week</h2>
                <p>{ data.serviceRequestPastWeek }</p>
            </div>
        </div>
        <div className="grid lg:grid-cols-2 gap-4 mt-4">
            <div className="shadow rounded p-4">
                <h2 className="text-xl font-bold flex justify-between items-center mb-4">
                    <span>Feedback</span>
                    <span className="italic text-xs">Amount received: { data.allFeedBack.length }</span>
                </h2>
                
                <div className="grid grid-cols-10 w-full gap-1">
                    { options.map((_, i) => {
                        const feedBackCount = data.allFeedBack.filter(feedback => feedback.PopupData.number === (i + 1)).length;
                        const feedbackPercentageVsTotal = feedBackCount / data.allFeedBack.length * 100;
                        return <div key={i} className={`
                            ${i + 1 <= 5 ? 'bg-red-400' : '' }
                            ${i + 1 > 5 && i + 1 < 8 ? 'bg-yellow-400' : '' }
                            ${i + 1 > 7 ? 'bg-green-400' : '' }
                            shadow flex flex-col gap-2 items-center justify-center rounded p-2`}>
                        <p className="text-lg font-bold">{i + 1}</p>
                        <p>{feedBackCount}</p>
                        <p>{feedbackPercentageVsTotal}%</p>
                    </div>
                    })}
                </div>

                <div className="flex flex-col gap-1">
                    <div className="flex justify-between items-center">
                        <h2 className="text-lg font-bold my-4">Latest Comments</h2>
                        <Link to={'/admin/popup'} className="p-2 linkText">View All</Link>
                    </div>
                    { data.allFeedBack.map((feedback, i) => {
                        if(i > 4) return null;
                        return <div key={i} className={`
                        shadow p-2 flex justify-start items-center gap-2`}>
                            <p
                                className={`p-2 rounded text-white font-bold
                                    ${ feedback.PopupData.number <= 5 ? 'bg-red-400' : '' }
                                    ${ feedback.PopupData.number > 5 && feedback.PopupData.number < 8 ? 'bg-yellow-400' : '' }
                                    ${ feedback.PopupData.number > 7 ? 'bg-green-400' : '' }`}
                            >{feedback.PopupData.number}</p>
                            <p>{feedback.PopupData.feedback}</p>
                        </div>
                    })}
                </div>

            </div>
            <div className="shadow rounded p-4">
                <h2 className="text-xl font-bold">Something else?</h2>
                <p>zzzz</p>
            </div>
        </div>
    </div>
}