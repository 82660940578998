import React, { useContext, useRef, useState } from 'react';
// import { Api } from '../../../shared/api/api';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { AdminStorageKey, TENANT_NAME } from '../../../constant/constant';

import storage from '../../../utils/storage/storage';
import { useNavigate } from 'react-router-dom';
import { hideLoader, showLoader } from '../../../store/loading/loading.action';
import {
  APPLICATION_CONTEXT,
  tenantLogo
} from '../../../utils/utils';
import { Form, FormItems, FormRef, FormType } from '../../../components/form';
import { Api } from '../../../shared/api/api';
import { Button } from '../../../components/ui';

export const Login = () => {
  const { setUserInfo, setTokens }: any = useContext<any>(APPLICATION_CONTEXT);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showErrorMsg, setShowErrorMsg] = useState(null);
  const ref: FormRef = useRef(null);

  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );

  const defaultValues = {
    userName: null,
    password: null,
  };

  const logOut = () => {
    setUserInfo(null);
  };

  const login = async ({ body }: any) => {
    try {
      setShowErrorMsg(null);
      dispatch(showLoader());
      setSubmitDisabled(true);
      const res = await Api.callAPI({
        url: '/api/v1/users/eAutomate/login',
        body: body,
        method: 'post',
        options: {
          showErrorMessage: false,
        },
      });

      const { accessToken, userInfo, refreshToken } = res?.data;
      storage.setItem(AdminStorageKey.ACCESS_TOKEN, accessToken);
      storage.setItem(AdminStorageKey.REFRESH_TOKEN, refreshToken);
      if (userInfo) {
        storage.setJSONItem(AdminStorageKey.userInfo, userInfo);
        setUserInfo(userInfo);
        setTokens(userInfo?.Tokens || []);
        if (userInfo?.Tokens.indexOf('ALL') > -1) {
          navigate('/admin/customer');
        } else if (userInfo?.Tokens.indexOf('CAN_MANAGE_COMPANY') > -1) {
          navigate('/admin/customer');
        } else if (userInfo?.Tokens.indexOf('CAN_VIEW_ACCESS_LOGS') > -1) {
          navigate('/admin/access-logs');
        } else if (
          userInfo?.Tokens.indexOf('CAN_MANAGE_SUPER_ADMIN_USER') > -1
        ) {
          navigate('/adminUser/list/' + userInfo?._id);
        } else {
          logOut();
        }
      } else {
        navigate('/admin/login');
      }
    } catch (err: any) {
      setShowErrorMsg(err?.Message || err?.error?.Message);
    } finally {
      dispatch(hideLoader());
      setSubmitDisabled(false);
    }
  };

  const handleSubmit = async (data: any) => {
    const { userName, password } = data;
    const body = {
      Email: userName,
      Password: password,
    };

    await login({ body: body });
  };

  return (
    <>
      <div className="bg-gray-100 fch100 flex justify-center items-center py-8 px-4  grayBox">
        <div className="sm:w-400 w-full m-auto bg-white shadow p-8 pt-12 my-8 ">
          <Form
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            formRef={ref}
          >
            <div>
              <img
                className="m-auto"
                alt={'Order Ahead Logo'}
                src={tenantLogo(tenantName)}
              />
              <h1 className="text-center my-8 font-bold text-gray-700">
                Welcome To {TENANT_NAME[tenantName]}
              </h1>
              <FormItems
                items={[
                  {
                    name: 'userName',
                    label: 'Username',
                    type: FormType.TEXT,
                    placeholder: 'Username',
                    required: true,
                    className: 'mb-4',
                  },
                  {
                    name: 'password',
                    label: 'Password',
                    type: FormType.PASSWORD,
                    placeholder: 'Password',
                    required: true,
                    className: 'mb-4',
                  },
                ]}
              />
              <div className="">
                {showErrorMsg ? (
                  <div className="form-msg ng-hide mt-2">
                    <div className="rounded border border-red-400 bg-red-200 text-red-900 text-xs py-0.5 text-center">
                      {showErrorMsg}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </Form>

          <Button
            onClick={(e: any) => ref.submit(e)}
            className="py-1 w-full rounded mt-2"
            disabled={isSubmitDisabled}
          >
            Sign in
          </Button>
        </div>
      </div>
    </>
  );
};
