export const ArrowLeftIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4997 13.3332L6.21272 8.9426C5.70644 8.42408 5.70644 7.5756 6.21272 7.05708L10.4997 2.6665"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
