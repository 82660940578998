import { Api } from '../../../../../shared/api/api';
import { useState } from 'react';
import {
  FormItem,
  FormType,
} from '../../../../../components/form';

export const SelectCustomers = ({
  setSelectedCustomers,
  defaultSelected,
  setCustomers,
  customers,
}) => {

  const [defaultSelectedCustomers, setDefaultSelecteCustomers] = useState(null);
  const getCustomerList = async () => {
    try {
      const res = await Api.callAPI({
        url: '/api/v1/customers',
        method: 'get',
      });

      const defaultSelectdCustomers = [];

      if (defaultSelected && defaultSelected.length) {
        defaultSelected.map((selectedId) => {
          const customer = res?.data.find(({ Id }) => Id == selectedId);
          if (customer) {
            defaultSelectdCustomers.push({
              label: customer?.Name,
              value: customer?.Id,
            });
          } else if (selectedId == -1) {
            let allcustomers = res?.data?.map(({ Id, Name }) => ({
              label: Name,
              value: Id,
              }));
            defaultSelectdCustomers.push(...allcustomers);
          }
        });
        setSelectedCustomers(defaultSelectdCustomers)
        setDefaultSelecteCustomers(defaultSelectdCustomers);
      }
      setCustomers(res?.data);
      const obj: any = {
        data: res?.data,
      };
      return obj;
    } catch (e) {}
  };

  return (
    <>
        <FormItem
          formDef={{
            name: 'selectedCustomer',
            label: 'Select Customer',
            required: true,
            type: FormType.SELECT,
            isMenuTop: false,
            selectMultiSelect: true,
            selectShowSelectAll: true,
            selectShowSelectAllFilter: true,
            defaultValue: defaultSelectedCustomers,
            selectAllValue: 'All Customers',
            getAsyncOptions: getCustomerList,
            onChange: (value) => {
              if(value.length == customers.length) {
                setSelectedCustomers([{ label: 'All Customers', value: -1 }])
              } else {
                setSelectedCustomers(value)
              }
            },
            selectShowCheckboxes: true,
            optionKeyMapObject: {
              labelKey: 'Name',
              valueKey: 'Id',
            },
          }}
        />
    </>
  );
};
