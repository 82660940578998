import { useRef, useState } from 'react';
import { useTheme } from '../../context/ThemeContext';

interface TextAreaProps
  extends Omit<
    React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    'defaultValue'
  > {
  className?: string;
  label?: string | React.ReactNode;
  name: string;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  defaultValue?: string | number;
  maxLength?: number;
  onChange?: (e: any) => void;
  error: string;
  fullWidth?: boolean;
  disabled?: boolean;
  color?: string;
}

export const TextArea = ({
  color,
  className,
  label,
  name,
  placeholder,
  required,
  rows,
  defaultValue = '',
  error,
  maxLength,
  onChange,
  fullWidth,
  disabled,
  ...rest
}: TextAreaProps) => {
  const ref = useRef(null);
  const { mainTheme } = useTheme();
  const [inputState, setInputState] = useState(defaultValue.toString() || '');
  const fullWidthClass = fullWidth ? 'w-full' : '';
  const disabledClass = disabled ? mainTheme.disabled : '';
  const background = color ? color : mainTheme.primary;

  const renderBottomText = () => {
    if (error) return <span className="text-xs text-red-500">{error}</span>;
      if (maxLength)
      return (
        <span className="text-xs text-gray-500">
          {maxLength - (ref?.current?.value?.length | 0)} characters remaining
        </span>
      );
  };

  const handleOnChange = (e: any) => {
    setInputState(e.target.value);
    if (onChange) onChange(e);
  };

  return (
    <div className={`${fullWidthClass} flex flex-col ${className}`}>
      {label && (
        <label htmlFor={label.toString()} className="text-sm text-gray-500">
          {label} {required && <span className="text-red-400">*</span>}
        </label>
      )}
      <textarea
        ref={ref}
        id={label.toString()}
        className={`${disabledClass} text-base p-2 border rounded ${
          error && 'border-red-500'
        } ${background}`}
        placeholder={placeholder}
        name={name}
        rows={rows}
        onChange={handleOnChange}
        maxLength={maxLength}
        value={inputState}
        disabled={disabled}
        {...rest}
      />
      {renderBottomText()}
    </div>
  );
};
