import { useEffect, useId, useState } from 'react';
import { RadioButtonOptions } from './RadioButton';

interface CheckboxProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'defaultValue' | 'onChange' | 'value'
  > {
  label?: string | React.ReactNode;
  className?: string;
  helperText?: string;
  border?: boolean;
  fullWidth?: boolean;
  options?: RadioButtonOptions[];
  multiSelect?: boolean;
  name?: string;
  value?: string | boolean;
  onChange?: (selected: RadioButtonOptions[] | any, type: any) => void;
  error?: string;
  selectAll?: boolean;
  selected?: Array<string | number | boolean>;
  defaultValue?: boolean | string | Array<string> | number;
  row?: boolean;
  selectAllLabel?: string;
}

const getCurrentSelected = (
  selected: Array<string | number | boolean>,
  options: RadioButtonOptions[],
) => {
  if (options && selected && selected.length > 0) {
    return options.filter(
      (o: RadioButtonOptions) => selected && selected.includes(o?.value),
    );
  }

  return [];
};

export const CheckBox = ({
  defaultValue,
  border,
  label,
  className,
  helperText,
  fullWidth,
  options,
  multiSelect,
  name,
  value,
  onChange,
  selectAll,
  selectAllLabel,
  selected,
  row,
  ...rest
}: CheckboxProps) => {
  const currentSelected = getCurrentSelected(selected, options);
  const id = useId();
  options = options || [
    { name: name, value: value || true, helperText: helperText, label: label },
  ];

  const [selectedOptions, setSelectedOptions] =
    useState<RadioButtonOptions[]>(currentSelected);

  useEffect(() => {
    if (selected) {
      if (
        typeof selected === 'string' ||
        typeof selected === 'number' ||
        typeof selected === 'boolean'
      ) {
        setSelectedOptions(
          options.filter((o: RadioButtonOptions) => o?.value === selected),
        );
      }
      if (Array.isArray(selected) && selected.length > 0) {
        setSelectedOptions(
          options.filter((o: RadioButtonOptions) => selected.includes(o?.value)),
        );
      }
      if (Array.isArray(selected) && selected.length == 0) {
        setSelectedOptions([]);
      }
    } else {
      setSelectedOptions([]);
    }
  }, [selected]);

  const borderClass = border
    ? 'border border-gray-300 rounded items-center justify-start px-2'
    : '';
  const fullWidthClass = fullWidth ? 'w-full' : 'w-fit';

  const handleChange = (option: RadioButtonOptions, e: any) => {
      e.stopPropagation();
      let isSingleCheckbox = options.length === 1 || (value && !multiSelect);
      const isSelected = selectedOptions.find(selectedOption => selectedOption?.value === option?.value);
      let newOptionArray = isSelected ? selectedOptions.filter(selectedOption => selectedOption?.value !== option?.value) : [...selectedOptions, option];
      if(!multiSelect && newOptionArray.length > 1) newOptionArray = [option];
      setSelectedOptions(newOptionArray);
      return !!onChange && onChange(isSingleCheckbox ? newOptionArray[0]?.value || false : newOptionArray, null);
  }

  const setSelectAll = (e: any) => {
    e.stopPropagation();
    if (selectedOptions.length === options.length) {
      setSelectedOptions([]);
      return onChange && onChange([], 'selectAll');
    } else {
      setSelectedOptions(options);
      return onChange && onChange(options, 'selectAll');
    }
  };

  return (
    <div>
      <div
        className={`flex  ${fullWidthClass} ${borderClass} ${
          row ? '' : 'flex-col'
        }`}
      >
        {/* If Multiselect, add a select all option */}
        {selectAll && (
          <div className={`flex items-start min-h-5 mb-2`} onClick={(e) => setSelectAll(e)}>
            <input
              aria-describedby="helper-radio-text"
              type="checkbox"
              id={id + 'helper-radio'}
              className={`w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 cursor-pointer`}
              onKeyDown={(e) => e.key === 'Enter' && setSelectAll(e)}
              checked={selectedOptions.length === options.length}
              onChange={setSelectAll}
              {...rest}
            />
            <div className={`ml-2 text-sm `}>
              <label
                htmlFor="helper-radio"
                className="font-medium text-gray-700"
              >
                {' '}
                {selectAllLabel || 'Select All'}
              </label>
            </div>
          </div>
        )}
        <div className={`child-option-list options-group-${name} ${className}`}>
          {options.map((option) => {
            return (
              <div
                key={id + option.name}
                className={`flex items-center ${row ? 'mr-3' : ''}`}
              >
                <input
                  id={id + option.name}
                  aria-describedby={id + option.name}
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 cursor-pointer"
                  onChange={(e) => handleChange(option, e)}
                  onKeyDown={(e) => e.key === 'Enter' && handleChange(option, e)}
                  checked={
                    !!selectedOptions.find(
                      (selectedOption) => selectedOption?.value === option?.value,
                    )
                  }
                  {...rest}
                />
                <div className="ml-2 text-sm">
                  <label
                    htmlFor={id + option.name}
                    className="font-medium text-gray-700"
                  >
                    {option.label}
                  </label>
                  {option.helperText && (
                    <p className="text-gray-500">{option.helperText}</p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {rest.error && <p className="text-red-500 text-xs">{rest.error}</p>}
    </div>
  );
};
