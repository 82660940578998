export const BuildingIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.833496 18.332H19.1668"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4834 18.3417V14.625"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4998 9.07422C15.4832 9.07422 14.6665 9.8909 14.6665 10.9076V12.7992C14.6665 13.8159 15.4832 14.6325 16.4998 14.6325C17.5165 14.6325 18.3332 13.8159 18.3332 12.7992V10.9076C18.3332 9.8909 17.5165 9.07422 16.4998 9.07422Z"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75 18.3328V5.0245C1.75 3.3495 2.58338 2.50781 4.24171 2.50781H9.43336C11.0917 2.50781 11.9167 3.3495 11.9167 5.0245V18.3328"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.8335 6.875H8.95851"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.8335 10H8.95851"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.875 18.332V15.207"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
