import { createContext, useContext, useEffect, useReducer } from 'react';
import { Modal } from '../components/ui/Modal';

interface ModalContextInterface {
  isOpen: boolean;
  content: React.ReactNode;
  options?: ModalOptions | null;
}

interface OpenModalInterface {
  content: React.ReactNode;
  options?: ModalOptions | null;
 
}

interface ModalContextFunctions extends ModalContextInterface {
  closeModal: () => void;
  openModal: ({ content, options, }: OpenModalInterface) => void;
 
}

const initalModalContext: ModalContextInterface = {
  isOpen: false,
  content: null,
  options: {
    title: null,
    footer: null,
    onOpen: null,
    isBackDropClose: true,
    className: '',

  },
};

interface ModalOptions {
  title?: React.ReactNode;
  footer?: React.ReactNode;
  modelWidthClass?: string;
  onOpen?: () => void;
  isBackDropClose?: Boolean;
  className?: string;
}

const ModalContext = createContext<ModalContextFunctions | undefined>(
  undefined,
);

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(
    (
      currentThemeState: ModalContextInterface,
      update: Partial<ModalContextInterface>,
    ) => {
      return { ...currentThemeState, ...update };
    },
    initalModalContext,
  );

  const openModal = ({ content, options }: OpenModalInterface) => {
    document.body.classList.add('modelOpen');
    dispatch({ isOpen: true, content: content, options: options });
    if (!!options?.onOpen) options.onOpen();
  };

  const closeModal = () => {
    document.body.classList.remove('modelOpen');
    dispatch({ isOpen: false, content: null, options: null });
  };
  useEffect(() => {
    document.body.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    });
  }, []);

  return (
    <ModalContext.Provider
      value={{
        ...state,
        openModal,
        closeModal,
      }}
    >
      {state.isOpen && <Modal />}
      {children}
    </ModalContext.Provider>
  );
};

export function useModal() {
  const context = useContext<ModalContextFunctions | undefined>(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
}
