import { createContext, useContext, useEffect, useReducer } from 'react';
import { Tenant } from '../types/Tenant';
import { setTenant } from '../store/tenant/tenant.action';
import { useDispatch } from 'react-redux';


export enum Themes {
    LIGHT = 0,
    DARK = 1,
    HOLDAY = 2, // Possible Holiday Theme for Christmas, Halloween, etc.
}

export const TeantThemesConstant = {
    [Tenant.FLEXPRINT]: {
        name: 'FTG',
        logo: '',
        favicon: '',
        primary: `bg-flexprint text-white`,
        secondary: '',
    },
    [Tenant.FLOTECH]: {
        name: Tenant.FLOTECH,
        logo: '',
        favicon: '',
        primary: `bg-flotech text-white`,
        secondary: '',
    },
    [Tenant.MARIMON]: {
        name: Tenant.MARIMON,
        logo: '',
        favicon: '',
        primary: `bg-marimon text-white`,
        secondary: '',
    },
    [Tenant.SANDBOX]: {
        name: Tenant.SANDBOX,
        logo: '',
        favicon: '',
        primary: `bg-sandbox text-white`,
        secondary: '',
    },
}

export const ThemesConstant = {
    [Themes.LIGHT]: {
        primary: 'bg-white text-stone-900',
        secondary: 'bg-stone-100 text-stone-900',
        warning: 'bg-yellow-400',
        success: 'bg-green-400',
        info: 'bg-blue-400',
        error: 'bg-red-400',
        text: 'text-stone-800',
        disabled: 'bg-stone-300 text-stone-400',
    },
    [Themes.DARK]: {
        primary: 'bg-stone-900 text-white',
        secondary: 'bg-stone-800 text-white',
        warning: 'bg-yellow-400',
        success: 'bg-green-400',
        info: 'bg-blue-400',
        error: 'bg-red-400',
        text: 'text-white',
        disabled: 'bg-stone-200 text-stone-400',
    },
}

export interface Theme {
    primary: string;
    secondary: string;
    warning: string;
    success: string;
    info: string;
    error: string;
    text: string;
    disabled: string;
}

interface TenantTheme extends Partial<Theme> {
    name: string;
    logo: string;
    favicon: string;
    primary: string;
}

interface ThemeContextInterface {
  theme: Themes;
  tenant: Tenant;
}

interface ThemeContextFunctions extends ThemeContextInterface {
  updateTheme: (theme: Themes) => void;
  mockTenant: (tenant: Tenant) => void;
  tenantTheme: TenantTheme;
  mainTheme: Theme;
}

const initalThemeContext: ThemeContextInterface = {
  theme: Themes.LIGHT,
  tenant: Tenant.FLEXPRINT,
};

const ThemeContext = createContext<ThemeContextFunctions | undefined>(undefined);

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const reduxDispatch = useDispatch()
  const [ state, dispatch ] = useReducer((currentThemeState: ThemeContextInterface, update: Partial<ThemeContextInterface>) => {
    return { ...currentThemeState, ...update };
  }, initalThemeContext);

    //Check which tenant is being used
    useEffect(() => {
        const possibleTenants = [ Tenant.MARIMON, Tenant.FLEXPRINT, Tenant.SANDBOX, Tenant.FLOTECH ];
        const location = window.location.href;
        let currentTenant: any = possibleTenants.find(tenant => location.includes(tenant));
         if(!currentTenant) currentTenant = Tenant.FLEXPRINT; //Will default to flexprint for Development & FTG URL
        // if(!currentTenant) currentTenant = Tenant.FLOTECH; //Will default to flexprint for Development & FTG URL
        // if(!currentTenant) currentTenant = Tenant.MARIMON; //Will default to flexprint for Development & FTG URL
        reduxDispatch(setTenant(currentTenant));
        dispatch({ tenant: currentTenant });
     
    }, [])

    const updateTheme = ( theme : Themes ) => dispatch({ theme: theme });
    const mockTenant = ( tenant : Tenant ) => dispatch({ tenant: tenant });

    const tenantTheme: TenantTheme = TeantThemesConstant[state.tenant];
    const mainTheme = ThemesConstant[state.theme];

  return <ThemeContext.Provider
    value={{
      ...state,
      updateTheme,
      tenantTheme,
      mainTheme,
      mockTenant,
    }}>
        { children }
    </ThemeContext.Provider>;

};

export function useTheme() {
  const context = useContext<ThemeContextFunctions | undefined>(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}
