import { createContext, useContext, useEffect, useReducer } from 'react';
import { useFlexLayer } from '../hooks/useFlexlayer';
import { Banner } from '../module/client/layout/header/banner';


interface MessagesContextInterface {
    banner: string | null;
    maintenance: string | null;
    hiddenBanner: boolean;
}

interface MessagesContextFunctions extends MessagesContextInterface {
    hideBanner: () => void;
}

const initalMessagesContext: MessagesContextInterface = {
    banner: null,
    maintenance: null,
    hiddenBanner: false,
};

const MessagesContext = createContext<MessagesContextFunctions | undefined>(
  undefined,
);

export const MessagesProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer((MessagesState: MessagesContextInterface, update: Partial<MessagesContextInterface>) => {
        return { ...MessagesState, ...update };
    }, initalMessagesContext);

    const { flexLayer } = useFlexLayer();

    useEffect(() => {
        Promise.all([
            flexLayer('/banner'),
            flexLayer('/banner/maintenance'),
        ]).then(([banner, maintenance]) => {
            let parsedBanner = banner?.data?.[0];
            dispatch({ banner: parsedBanner?.BannerData, maintenance: maintenance.data, hiddenBanner: !parsedBanner?.IsActive });
        });
    }, []);

  return (
    <MessagesContext.Provider
      value={{
        ...state,
        hideBanner: () => dispatch({ hiddenBanner: true }),
      }}
    >
        {!state.hiddenBanner && <Banner/>}
        {children}
    </MessagesContext.Provider>
  );
};

export function useMessages() {
  const context = useContext<MessagesContextFunctions | undefined>(MessagesContext);
  if (context === undefined) {
    throw new Error('useMessages must be used within a MessagesProvider');
  }
  return context;
}
