import React from 'react';
import { SettingIcon } from '../../../assets/svg/setting-icon';
import { TrashIcon } from '../../../assets/svg/trash-icon';
import { FilterType } from "../../../shared/api-table/table-helper";
import { UsersIcon } from '../../../assets/svg/users-icon';

export const CustomerTableField = ({ onActionClick }: any) => {
  const filterParams = {
    filterOptions: ['contains'],
    defaultOption: 'contains',
    maxNumConditions: 1,
    debounceMs: 200,
  };
  return [
    {
      headerName: 'Customer Id',
      field: 'Id',
      width: 140,
      sortable: false,
      filterParams: filterParams,
      filter: FilterType.AgTextColumnFilter,
    },
    {
      headerName: 'Customer Number',
      field: 'CustomerNumber',
      width: 140,
      sortable: false,
      filterParams: filterParams,
      filter: FilterType.AgTextColumnFilter,
    },
    {
      headerName: 'Customer Name',
      field: 'Name',
      width: 380,
      sortable: false,
      filterParams: filterParams,
      filter: FilterType.AgTextColumnFilter,
    },
    {
      headerName: 'Warehouse',
      field: 'WarehouseName',
      width: 140,
      sortable: false,
      filterParams: filterParams,
      filter: FilterType.AgTextColumnFilter,
    },
    {
      headerName: 'Address',
      field: 'Address',
      width: 280,
      sortable: false,
      filterParams: filterParams,
      filter: FilterType.AgTextColumnFilter,
    },
    {
      headerName: 'City',
      field: 'City',
      width: 150,
      sortable: false,
      filterParams: filterParams,
      filter: FilterType.AgTextColumnFilter,
    },
    {
      headerName: 'State',
      field: 'State',
      width: 100,
      sortable: false,
      filterParams: filterParams,
      filter: FilterType.AgTextColumnFilter,
    },
    {
      headerName: 'Zip',
      field: 'Zip',
      width: 100,
      sortable: false,
      filterParams: filterParams,
      filter: FilterType.AgTextColumnFilter,
    },
    {
      field: 'Action',
      headerName: 'Action',
      isActionColumn: true,
      filter: null,
      cellRenderer: (cellEventData: any) => {
        return (
          <>
            <div className="flex items-center mt-3">
              <button
                title="Manage User"
                className={
                  'manageUser mr-3 block iconbtn text-gray-400 hover:text-flexprint'
                }
                onClick={() => {
                  onActionClick(cellEventData, 'manageUser');
                }}
              >
                <UsersIcon />
              </button>
              <button
                title="Edit"
                className={'edit mr-3 block iconbtn'}
                onClick={() => {
                  onActionClick(cellEventData, 'edit');
                }}
              >
                <SettingIcon />
              </button>
              <button
                title="Delete"
                className={'trash mr-3 block iconbtn'}
                onClick={() => {
                  onActionClick(cellEventData, 'trash');
                }}
              >
                <TrashIcon />
              </button>
            </div>
          </>
        );
      },
    },
  ];
};
