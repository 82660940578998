import { useRef } from 'react';
import { CancelBtn, Form, FormItem, FormItems, FormType, InputMask, SubmitBtn } from "../../../components/form";
import { useTheme } from '../../../context/ThemeContext';
import { Button } from "../../../components/ui";
import { Card } from '../../../components/ui/Card';

export const AddDeviceDemo = () => {
  const { mainTheme } = useTheme();
  const isLoading = false;
  const form: any = useRef(null);

  const handleSubmit = () => {};

  const handleRefSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    form.submit(event); //Submit Form From any Button
  };

  const clearInputsOnClick = () => {
    form.clearInputs(['location', 'model']); // Clear Inputs
  };

  return (
    <Card className="w-[50vw] mt-10">
      <div className="text-2xl mb-2">Add Device Example</div>
      <div className="">
        <Form
          onCancel={() => {}}
          onSubmit={handleSubmit}
          isSubmitting={isLoading}
          formRef={form}
        >
          <div className="p-3">
            <div className="flex">
              <div className="mr-3 w-full border-r border-stone-200 pr-3">
                <FormItems
                  items={[
                    {
                      name: 'equipmentNumber',
                      label: 'Equipment Number',
                      type: FormType.TEXT,
                      placeholder: 'Equipment Number',
                      maxLength: 20,
                    },
                    {
                      name: 'manufacturer',
                      label: 'Manufacturer',
                      type: FormType.TEXT,
                      placeholder: 'Manufacturer',
                      required: false,
                    },
                    {
                      name: 'model',
                      label: 'Model',
                      type: FormType.TEXT,
                      placeholder: 'Model',
                      required: true,
                      requiredInputs: ['manufacturer'],
                    },
                    {
                      name: 'serialNumber',
                      label: 'Serial Number',
                      type: FormType.TEXT,
                      placeholder: 'Serial Number',
                      required: true,
                      requiredInputs: ['manufacturer', 'model'],
                    },
                    {
                      name: 'location',
                      label: 'Location',
                      type: FormType.SELECT,
                      placeholder: 'Location',
                      required: true,
                      defaultSelected: ['location1', 'location2'],
                      options: [
                        { label: 'Location 1', value: 'location1' },
                        { label: 'Location 2', value: 'location2' },
                        { label: 'Location 3', value: 'location3' },
                        { label: 'Location 4', value: 'location4' },
                        { label: 'Location 5', value: 'location5' },
                        { label: 'Location 6', value: 'location6' },
                        {
                          label: 'Location 7',
                          value: 'location7',
                          disabled: true,
                        },
                      ],
                      selectShowChip: true,
                      selectMultiSelect: true,
                    },
                    {
                      name: 'locationRemarks',
                      label: 'Location Remarks',
                      type: FormType.TEXT,
                      placeholder: 'Location Remarks',
                    },
                  ]}
                />
              </div>
              <div className="w-full">
                <FormItems
                  items={[
                    {
                      name: 'ipAddress',
                      label: 'IP Address',
                      type: FormType.TEXT,
                      placeholder: 'IP Address',
                      defaultValue: '127.0.0.1',
                      mask: InputMask.IPV4,
                    },
                    {
                      name: 'macAddress',
                      label: 'MAC Address',
                      type: FormType.TEXT,
                      placeholder: 'MAC Address',
                      mask: InputMask.MAC,
                    },
                    {
                      name: 'pageCountBW',
                      label: 'Page Count (B/W)',
                      min: 10,
                      max: 10000,
                      defaultValue: 100,
                      type: FormType.NUMBER,
                      placeholder: 'Page Count (B/W)',
                    },
                    {
                      name: 'pageCountColor',
                      label: 'Page Count (Color)',
                      min: 10,
                      max: 10000,
                      defaultValue: 100,
                      type: FormType.NUMBER,
                      placeholder: 'Page Count (Color)',
                    },
                    {
                      name: 'costCenter',
                      label: 'Cost Center',
                      defaultValue: 'N/A',
                      type: FormType.TEXT,
                      placeholder: 'Cost Center',
                    },
                  ]}
                />
              </div>
            </div>
            <div>
              Request Toner
              <div className="text-sm italic text-stone-500">
                For networked devices, toner shipments may be delayed until
                supplies are needed
              </div>
              <FormItem
                formDef={{
                  name: 'requestToner',
                  label: 'Request Toner',
                  type: FormType.CHECKBOX,
                  placeholder: 'Request Toner',
                  radioOptions: [
                    { label: 'Cyan', name: 'cyan', value: 'cyan' },
                    { label: 'Magenta', name: 'magenta', value: 'magenta' },
                    { label: 'Yellow', name: 'yellow', value: 'yellow' },
                    { label: 'Black', name: 'black', value: 'black' },
                  ],
                  defaultValue: ['cyan', 'magenta'],
                  optionsMultiSelect: true,
                }}
              />
              <div className="mt-3">
                <FormItem
                  formDef={{
                    name: 'comments',
                    label: 'Comments',
                    type: FormType.TEXTAREA,
                    placeholder: 'Additonal Comments',
                    maxLength: 100,
                  }}
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="text-sm italic text-stone-500">
                Does this device have a configuration report?
              </div>
              {/*<FormItem formDef={{*/}
              {/*    name: 'configReport',*/}
              {/*    label: 'Configuration Report',*/}
              {/*    type: FormType.FILE,*/}
              {/*    placeholder: 'Configuration Report',*/}
              {/*    uploadAccept: '.json', // Onlu allow js files*/}
              {/*    uploadMutiple: false, // Only allow one file*/}
              {/*    uploadMaxSize: 1000000, // 1MB*/}
              {/*    required: true,*/}
              {/*}}/>*/}
            </div>
            <div className="mt-4">
              <div className="text-sm italic text-stone-500">
                Would you like to receive email updates?
              </div>
              <FormItem
                formDef={{
                  name: 'emailUpdates',
                  label: 'Email Updates',
                  type: FormType.RADIO,
                  placeholder: 'Email Updates',
                  radioOptions: [
                    { label: 'Yes', name: 'yes', value: 'yes' },
                    { label: 'No', name: 'no', value: 'no' },
                  ],
                  // defaultValue: 'yes',
                  displayRow: true,
                  required: true,
                }}
              />
            </div>
            <div className="flex mt-2">
              <CancelBtn className="w-full mr-3" color={mainTheme.secondary}>
                Cancel
              </CancelBtn>
              <SubmitBtn className="w-full">Add Device</SubmitBtn>
            </div>
          </div>
        </Form>
        <div className="flex mt-4 w-full p-3">
          <Button className="mr-3" onClick={handleRefSubmit}>
            Test Submit
          </Button>
          <Button className="mr-3" onClick={clearInputsOnClick}>
            Test clear inputs
          </Button>
        </div>
      </div>
    </Card>
  );
};
