import { cloneDeep } from 'lodash';
import TENANT_ACTIONS from './tenent.action-types';

const initialState = {
  tenantName: null,
};

export default function tenantReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = cloneDeep(initialState),
  action: any,
) {
  switch (action.type) {
    case TENANT_ACTIONS.SET_TENANT: {
      return {
        ...state,
        tenantName: action.payload,
      };
    }

    default:
      return state;
  }
}
