/**
 * This component represents the entire panel which gets dropped down when the
 * user selects the component.  It encapsulates the search filter, the
 * Select-all item, and the list of options.
 */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useKey } from '../hooks/use-key';
import { useSelectContext } from '../hooks/use-select-context';
import { KEY } from '../lib/constants';
import { debounce } from '../lib/debounce';
import { filterOptions } from '../lib/simple-match-utils';
import { Cross } from './cross';
import SelectItem from './select-item';
import SelectList from './select-list';
import { SearchIcon } from './searchIcon';

enum FocusType {
  SEARCH = 0,
  NONE = -1,
}

const SelectPanel = () => {
  const {
    t,
    onChange,
    options,
    setOptions,
    value,
    filterOptions: customFilterOptions,
    ItemRenderer,
    disabled,
    disableSearch,
    hasSelectAll,
    hasFilterSelectAll,
    ClearIcon,
    debounceDuration,
    isCreatable,
    isSingleSelect,
    onCreateOption,
  }: any = useSelectContext();

  const listRef = useRef<any>();
  const searchInputRef = useRef<any>();
  const [searchText, setSearchText] = useState('');
  const SelectListId = `Select-list-ul-${Math.floor(Math.random() * 20000)}`;
  const [allOptins] = useState(options);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchTextForFilter, setSearchTextForFilter] = useState('');
  const [focusIndex, setFocusIndex] = useState(0);
  const debouncedSearch = useCallback(
    debounce((query: any) => setSearchTextForFilter(query), debounceDuration),
    [],
  );

  const skipIndex = useMemo(() => {
    let start = 0;

    if (!disableSearch) start += 1; // if search is enabled then +1 to skipIndex
    if (hasSelectAll) start += 1; // if select-all is enabled then +1 to skipIndex

    return start;
  }, [disableSearch, hasSelectAll]);

  // const selectAllOption = {
  //   label: searchText ? t('selectAllFiltered') : t('selectAll'),
  //   value: '',
  // };

  const selectAllValues = (checked: any) => {
    const filteredValues = filteredOptions
      .filter((o: any) => !o.disabled)
      .map((o: any) => o.value);

    if (checked) {
      const selectedValues = value.map((o: any) => o.value);
      const finalSelectedValues = [...selectedValues, ...filteredValues];

      return (customFilterOptions ? filteredOptions : options).filter(
        (o: any) => finalSelectedValues.includes(o.value),
      );
    }

    return value.filter((o: any) => !filteredValues.includes(o.value));
  };

  const selectAllOptions = (checked: any) => {
    const filteredValues = allOptins
      .filter((o: any) => !o.disabled)
      .map((o: any) => o.value);

    if (checked) {
      const selectedValues = value.map((o: any) => o.value);
      const finalSelectedValues = [...selectedValues, ...filteredValues];

      return (customFilterOptions ? filteredOptions : options).filter(
        (o: any) => finalSelectedValues.includes(o.value),
      );
    }

    return value.filter((o: any) => !filteredValues.includes(o.value));
  };

  const selectAllChangedFiltred = (checked: boolean) => {
    const newOptions = selectAllValues(checked);
    onChange(newOptions);
  };

  const selectAllChanges = (checked: boolean) => {
    const newOptions = selectAllOptions(checked);
    onChange(newOptions);
  };

  const handleSearchChange = (e: any) => {
    debouncedSearch(e.target.value);
    setSearchText(e.target.value);
    setFocusIndex(FocusType.SEARCH);
  };

  const handleClear = () => {
    setSearchTextForFilter('');
    setSearchText('');
    searchInputRef?.current?.focus();
  };

  const handleItemClicked = (index: number) => setFocusIndex(index);

  // Arrow Key Navigation
  const handleKeyDown = (e: any) => {
    switch (e.code) {
      case KEY.ARROW_UP:
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        updateFocus(-1);
        break;
      case KEY.ARROW_DOWN:
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        updateFocus(1);
        break;
      default:
        return;
    }
    e.stopPropagation();
    e.preventDefault();
  };

  useKey([KEY.ARROW_DOWN, KEY.ARROW_UP], handleKeyDown, {
    target: listRef,
  });

  const handleSearchFocus = () => {
    setFocusIndex(FocusType.SEARCH);
  };

  const handleOnCreateOption = async () => {
    let newOption = { label: searchText, value: searchText, __isNew__: true };

    // if custom `onCreateOption` is given then this will call this
    if (onCreateOption) {
      newOption = await onCreateOption(searchText);
    }

    // adds created value to existing options
    setOptions([newOption, ...options]);
    handleClear();

    onChange([...value, newOption]);
  };

  const getFilteredOptions = async () =>
    customFilterOptions
      ? customFilterOptions(options, searchTextForFilter?.trim())
      : filterOptions(options, searchTextForFilter?.trim());

  const updateFocus = (offset: number) => {
    let newFocus = focusIndex + offset;
    newFocus = Math.max(0, newFocus);
    newFocus = Math.min(newFocus, options.length + Math.max(skipIndex - 1, 0));
    setFocusIndex(newFocus);
  };

  useEffect(() => {
    listRef?.current?.querySelector(`[tabIndex='${focusIndex}']`)?.focus();
  }, [focusIndex]);

  const [isAllOptionSelected, hasSelectableOptions] = useMemo(() => {
    const filteredOptionsList = filteredOptions.filter((o: any) => !o.disabled);
    return [
      filteredOptionsList.every(
        (o: any) => value.findIndex((v: any) => v.value === o.value) !== -1,
      ),
      filteredOptionsList.length !== 0,
    ];
    // eslint-disable-next-line
  }, [filteredOptions, value]);

  const [isAllSelected] = useMemo(() => {
    const filteredOptionsList = allOptins.filter((o: any) => !o.disabled);
    return [
      filteredOptionsList.every(
        (o: any) => value.findIndex((v: any) => v.value === o.value) !== -1,
      ),
      filteredOptionsList.length !== 0,
    ];
    // eslint-disable-next-line
  }, [allOptins, value]);

  useEffect(() => {
    getFilteredOptions().then(setFilteredOptions);
  }, [searchTextForFilter, options]);

  const creationRef: any = useRef();
  useKey([KEY.ENTER], handleOnCreateOption, { target: creationRef });

  const showCreatable =
    isCreatable &&
    searchText &&
    !filteredOptions.some((e: any) => e?.value === searchText);

  return (
    <div className="select-panel" role="listbox" ref={listRef}>
      {!isSingleSelect && hasSelectAll && hasSelectableOptions && (
        <SelectItem
          tabIndex={skipIndex === 1 ? 0 : 1}
          checked={isAllSelected}
          option={{
            label: t('selectAll'),
            value: '',
          }}
          onSelectionChanged={selectAllChanges}
          onClick={() => handleItemClicked(1)}
          itemRenderer={ItemRenderer}
          disabled={disabled}
        />
      )}

      {!disableSearch && (
        <div className="search">
          <button
            type="button"
            className="search-icon"
            // onClick={handleClear}
            aria-label={t('clearSearch')}
          >
            <SearchIcon />
          </button>
          <input
            placeholder={t('search')}
            type="text"
            aria-describedby={t('search')}
            onChange={handleSearchChange}
            onFocus={handleSearchFocus}
            value={searchText}
            ref={searchInputRef}
            tabIndex={0}
          />
          <button
            type="button"
            className="search-clear-button"
            hidden={!searchText}
            onClick={handleClear}
            aria-label={t('clearSearch')}
          >
            {ClearIcon || <Cross />}
          </button>
        </div>
      )}

      {!isSingleSelect &&
        hasFilterSelectAll &&
        hasSelectableOptions &&
        searchText && (
          <SelectItem
            tabIndex={skipIndex === 1 ? 0 : 1}
            checked={isAllOptionSelected}
            option={{
              label: isAllOptionSelected
                ? 'UnSelect all filtered results'
                : t('selectAllFiltered'),
              value: '',
            }}
            onSelectionChanged={selectAllChangedFiltred}
            onClick={() => handleItemClicked(1)}
            itemRenderer={ItemRenderer}
            disabled={disabled}
          />
        )}
      <ul className="options" id={SelectListId}>
        {filteredOptions.length ? (
          <SelectList
            SelectListId={SelectListId}
            skipIndex={skipIndex}
            options={filteredOptions}
            onClick={(_e: any, index: number) => handleItemClicked(index)}
          />
        ) : showCreatable ? (
          <li
            onClick={handleOnCreateOption}
            className="select-item creatable"
            tabIndex={1}
            ref={creationRef}
          >
            {`${t('create')} "${searchText}"`}
          </li>
        ) : (
          <li className="no-options">{t('noOptions')}</li>
        )}
      </ul>
    </div>
  );
};

export default SelectPanel;
