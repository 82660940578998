import { Link } from 'react-router-dom';
import { FilterType } from '../../../../shared/api-table/table-helper';

export const SearchCustomerTableField = () => {
  const filterParams = {
    filterOptions: ['contains'],
    defaultOption: 'contains',
    maxNumConditions: 1,
    debounceMs: 200,
  };
  return [
    {
      headerName: 'Customer Id',
      field: 'Id',
      width: 140,
      sortable: false,
      filterParams: filterParams,
      filter: FilterType.AgTextColumnFilter,
    },
    {
      headerName: 'Customer Number',
      field: 'Number',
      width: 140,
      sortable: false,
      filterParams: filterParams,
      filter: FilterType.AgTextColumnFilter,
    },
    {
      headerName: 'Customer Name',
      field: 'Name',
      width: 380,
      sortable: false,
      filterParams: filterParams,
      filter: FilterType.AgTextColumnFilter,
    },

    {
      headerName: 'City',
      field: 'City',
      width: 150,
      sortable: false,
      filterParams: filterParams,
      filter: FilterType.AgTextColumnFilter,
    },
    {
      headerName: 'State',
      field: 'State',
      width: 100,
      sortable: false,
      filterParams: filterParams,
      filter: FilterType.AgTextColumnFilter,
    },
    {
      headerName: 'Zip',
      field: 'Zip',
      width: 100,
      sortable: false,
      filterParams: filterParams,
      filter: FilterType.AgTextColumnFilter,
    },
    {
      headerName: 'Address',
      field: 'Address',
      width: 100,
      sortable: false,
      filterParams: filterParams,
      filter: FilterType.AgTextColumnFilter,
    },
    {
      field: 'AddToPortal',
      headerName: '',
      isActionColumn: true,
      sortable: false,
      filter: false,
      cellRenderer: ({ data }) => {
        return (
            <div>
              <Link
                className="linkText"
                to={`/admin/link-customer/${data?.Id}`}
              >
                Add to Portal
              </Link>
            </div>
        );
      },
    },
  ];
};
