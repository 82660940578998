import { Api } from '../../../../../shared/api/api';
import React, { useEffect, useRef, useState } from 'react';
import ApiTable from '../../../../../shared/api-table/api-table';
import { ClientGridName } from '../../../../../constant/constant';
import { MeterByDeviceColumn } from './meter-by-device-column';
import { ComponentLoading } from '../../../../../shared/component-loader/component-loading';
import { filter } from 'lodash';
import { ReactSelectCompo } from '../../../../../components/ui/react-select/react-select';
import { ResetIcon } from '../../../../../assets/svg/reset-icon';
import { ExcelIcon } from '../../../../../assets/svg/excel-icon';
import { useUser } from '../../../../../context/UserContext';

export const MeterByDevice = () => {
  const { customerInfo } = useUser();
  const ref = useRef(null);
  const [isApiCallProgress, setIsApiCallProgress] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [monthColumns, setMonthColumns] = useState(null);
  const [columns, setColumns] = useState([]);
  const [locationIds, setLocationIds] = useState(null);
  const [isExportBtnVisible, setExportBtnVisible] = useState(false);
  const [locationDropdownOption, setLocationDropdownOption] =
    useState<any>(null);
  const [selectedValue, setSelectedValue] = useState([]);
  const [isDropDownRender, setDropDownRender] = useState(false);
  const getReports = async (searchObj) => {
    try {
      const res: any = await Api.callAPI({
        url: `/api/v1/reports/${searchObj.CustomerID}`,
        method: 'PUT',
        body: searchObj,
      });
      setMonthColumns(res?.data?.ColumnName);
      if (res?.data?.ReportsData && res?.data?.ReportsData.length) {
        setExportBtnVisible(true);
      } else {
        setExportBtnVisible(false);
      }
      setRowData(res?.data?.ReportsData || []);
      setRowCount(res?.data?.ReportsData?.length || 0);
    } catch (e) {
    } finally {
      setIsApiCallProgress(false);
    }
  };

  useEffect(() => {
    if (!isApiCallProgress) {
      const filterParams = {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        maxNumConditions: 1,
        debounceMs: 200,
      };
      const mcolumns =
        monthColumns && monthColumns.length
          ? monthColumns.map((column) => {
              return {
                headerName: column.Month,
                field: column.Month,
                width: 125,
                filterParams: filterParams,
              };
            })
          : [];

      setColumns(
        MeterByDeviceColumn({
          ShowRegionCodeInGrid: customerInfo?.ShowRegionCodeInGrid,
          monthColumns: mcolumns,
        }),
      );
    }
  }, [isApiCallProgress, monthColumns]);
  const exportCSV = () => {
    if (ref.current) {
      ref.current.exportCSV();
    }
  };

  const resetGrid = () => {
    if (ref.current) {
      ref.current.resetGrid();
    }
  };

  const getShippingAddress = async () => {
    let locationArrIds: any = [];
    let locationArr = [];

    try {
      const response = await Api.callAPI({
        url: `/api/v1/customers/${customerInfo?.CustomerId}/address`,
        method: 'get',
        body: {
          Active: true,
        },
        options: {
          isLoading: false,
        },
      });

      if (
        customerInfo?.RestrictUserByLocationID &&
        customerInfo?.RestrictUserByLocationID?.length
      ) {
        setLocationIds(customerInfo?.RestrictUserByLocationID.toString());
        customerInfo?.RestrictUserByLocationID.forEach((location: any) => {
          const locations = filter(response.data, { CustomerID: location });
          locationArrIds.push(locations[0].CustomerID);
          locationArr.push(locations[0]);
        });
        const locationLabelValueArr: any = locationArr.map((data: any) => {
          return {
            value: data.CustomerID,
            label: data.SearchBy,
          };
        });
        setSelectedValue([{value: 'All Locations', label: 'All Locations'}])
        setLocationDropdownOption([{value: 'All Locations', label: 'All Locations'}, ...locationLabelValueArr]);
      } else {
        setLocationIds(null);
        locationArr = response?.data;
      }
      const locationLabelValueArr: any = locationArr.map((data: any) => {
        return {
          value: data.CustomerID,
          label: `${data.Address}, ${data.City}, ${data.State}, ${data.Zip}, ${data.Country}`,
        };
      });
      setSelectedValue([{value: 'All Locations', label: 'All Locations'}])
      setLocationDropdownOption([{value: 'All Locations', label: 'All Locations'}, ...locationLabelValueArr]);
    } catch (e) {
    } finally {
      setDropDownRender(true);
    }
  };

  useEffect(() => {
    getShippingAddress().then();
  }, []);

  useEffect(() => {
    if (isDropDownRender) {
      const searchObj = {
        CustomerID: customerInfo?.CustomerId,
        LocationID: locationIds,
      };
      getReports(searchObj).then();
    }
  }, [locationIds, isDropDownRender]);
  return (
    <>
      <h2 className="mb-6 text-gray-950 text-lg font-semibold">
        Meters By Device (12 months)
      </h2>
      {locationDropdownOption ? (
        <div className="sm:w-80 elipsisSelectBox">
          <ReactSelectCompo
            options={locationDropdownOption}
            value={selectedValue}
            name={'select location '}
            label={'Search Location'}
            selectAllValue='All Locations'
            onChange={(option: any) => {
              setSelectedValue(option);
              if (option && option?.length) {
                if(option[0].value === 'All Locations') {
                  setLocationIds(null);
                } else {
                  setLocationIds(option[0]?.value);
                }
              } else {
                setLocationIds(null);
              }
            }}
            labelledBy={'location'}
            isSingleSelect={true}
            isCheckboxVisible={false}
            ClearSelectedIcon={null}
            // isOpen={true}
          />
        </div>
      ) : (
        ''
      )}

      {isApiCallProgress ? (
        <ComponentLoading isShowLoader={true} />
      ) : (
        <>
          <div className="sm:flex items-center justify-between my-4 w-full">
            <div>
              <span className="md:text-lg text-base font-semibold">
               {rowCount || 0} { rowCount > 1 ? 'Devices' : 'Device' }
              </span>
            </div>
            <div className="flex items-center sm:mt-0 mt-2">
              <button
                className={`flex items-center borderedBtn sm:ml-4 linkText hover:text-black`}
                onClick={resetGrid}
              >
                <ResetIcon />
                <div className={`ml-2 text-sm`}>Reset</div>
              </button>
              {isExportBtnVisible ? (
                <>
                  <button
                    className={`borderedBtn flex items-center ml-4 linkText hover:text-black`}
                    onClick={exportCSV}
                  >
                    <ExcelIcon />
                    <div className="ml-2 text-sm">Export CSV</div>
                  </button>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
          {columns?.length ? (
            <ApiTable
              ref={ref}
              columns={columns}
              gridName={ClientGridName.MeterByReports}
              rowData={rowData ? rowData : []}
              setExportBtnVisible={setExportBtnVisible}
              gridOptions={{
                pagination: false,
              }}
              onGridFilterChanged = {() => {
                setRowCount(ref?.current?.getDisplayedRowCount())
              }}
            />
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
};
