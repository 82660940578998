import React, { useContext, useEffect, useState } from 'react';
import {
  Form,
  FormItem,
  FormItems,
  FormType,
  SubmitBtn,
} from '../../../../components/form';
import { useNavigate, useParams } from 'react-router-dom';
import { APPLICATION_CONTEXT } from '../../../../utils/utils';
import { Api } from '../../../../shared/api/api';

export const AddUpdateSuperAdminUsers = () => {
  let { userId } = useParams();
  const navigate = useNavigate();

  const [defaultValue, setDefaultValue] = useState(null);

  const { userInfo }: any = useContext(APPLICATION_CONTEXT);
  const tokens = [];

  if (userInfo?.Tokens) {
    if (userInfo.Tokens.indexOf('CAN_VIEW_ACCESS_LOGS') > -1) {
      tokens.push({
        name: 'CAN_VIEW_ACCESS_LOGS',
        label: 'Can view access logs.',
        value: 'CAN_VIEW_ACCESS_LOGS',
      });
    }
    if (userInfo.Tokens.indexOf('CAN_MANAGE_SUPER_ADMIN_USER') > -1) {
      tokens.push({
        name: 'CAN_MANAGE_SUPER_ADMIN_USER',
        label: 'Can manage super admin.',
        value: 'CAN_MANAGE_SUPER_ADMIN_USER',
      });
    }
    if (userInfo.Tokens.indexOf('CAN_MANAGE_COMPANY') > -1) {
      tokens.push({
        name: 'CAN_MANAGE_COMPANY',
        label: 'Can manage company.',
        value: 'CAN_MANAGE_COMPANY',
      });
    }
  }

  const onCancelHandler = () => {
    navigate(`/admin/adminUser/list/${userInfo._id}`);
  };

  const addUser = async (value) => {
    const allTokens = [
      'CAN_VIEW_ACCESS_LOGS',
      'CAN_MANAGE_SUPER_ADMIN_USER',
      'CAN_MANAGE_COMPANY',
    ];
    const { Tokens } = value;
    if (JSON.stringify(allTokens) === JSON.stringify(Tokens)) {
      Tokens.push('ALL');
    }
    try {
      const bodyObj = {
        ...value,
        Active: value?.Active === 'y',
      };

      const response = await Api.callAPI({
        url: `/api/v1/superAdmin/users`,
        method: 'post',
        body: bodyObj,
        options: {
          successMessage: 'Admin user has been added successfully.',
          errorMessage: 'Error while adding admin user.',
        },
      });

      if (response.CreatedDate) {
        navigate(`/admin/adminUser/list/${userInfo._id}`);
      }
    } catch (e) {}
  };

  const updateUser = async (value) => {
    const allTokens = [
      'CAN_VIEW_ACCESS_LOGS',
      'CAN_MANAGE_SUPER_ADMIN_USER',
      'CAN_MANAGE_COMPANY',
    ];
    const { Tokens } = value;
    if (JSON.stringify(allTokens) === JSON.stringify(Tokens)) {
      Tokens.push('ALL');
    }
    try {
      const bodyObj = {
        ...value,
        Active: value?.Active === 'y',
      };
      const response = await Api.callAPI({
        url: `/api/v1/superAdmin/users/${userId}`,
        method: 'put',
        body: bodyObj,
        options: {
          isLoading: true,
          successMessage: 'Admin user has been updated successfully.',
          errorMessage: 'Error while updating admin user.',
        },
      });

      if (response) {
        navigate(`/admin/adminUser/list/${userInfo._id}`);
      }
    } catch (e) {}
  };
  const getAdminUsers = async () => {
    try {
      if (userId) {
        const response = await Api.callAPI({
          url: `/api/v1/superAdmin/users/${userId}`,
          method: 'get',
        });

        setDefaultValue({
          ...response?.data,
          Active: response?.data?.Active ? 'y' : 'n',
        });
      }
    } catch (e) {}
  };

  useEffect(() => {
    getAdminUsers().then();
    if (userId === undefined) {
      setDefaultValue(true);
    }
  }, []);

  return (
    <>
      <h2 className="font-semibold text-gray-600">Add Admin User</h2>
      {defaultValue && (
        <div className='bg-gray-100 mt-4 rounded-lg p-6'>

          <Form
            onSubmit={userId ? updateUser : addUser}
            defaultValues={{
              UserName: userId ? defaultValue?.UserName : '',
              Active: userId ? defaultValue?.Active : '',
              Tokens: userId ? defaultValue?.Tokens : '',
            }}
          >


          <div className='sm:flex gap-6'>
            <div className='sm:w-1/2'>
              <FormItems
                items={[
                  {
                    name: 'UserName',
                    label: 'User Name',
                    type: FormType.TEXT,
                    placeholder: 'User name',
                    required: true,
                    errors: {
                      required: 'Please provide username.',
                    },
                  },
                ]}
              />
              <div>
                <label htmlFor={'Active'} className="text-sm text-gray-500">
                  Active <span className="text-red-400">*</span>
                </label>
                <FormItem
                  formDef={{
                    name: 'Active',
                    label: 'Active',
                    type: FormType.RADIO,
                    radioOptions: [
                      { label: 'Yes', name: 'yes', value: 'y' },
                      { label: 'No', name: 'no', value: 'n' },
                    ],
                    displayRow: true,
                    required: true,
                  }}
                />
              </div>
            </div>
            <div className='sm:w-1/2 sm:mt-0 mt-6'>
              {tokens?.length ? (
                <div>
                  <h4 className='font-semibold text-base mb-4'>Permissions</h4>
                  <FormItem
                    formDef={{
                      name: 'Tokens',
                      label: 'Apply all',
                      required: true,
                      optionsMultiSelect: true,
                      selectAll: true,
                      selectAllLabel: 'Apply all',
                      type: FormType.CHECKBOX,
                      radioOptions: tokens,
                    }}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

            <div className="flex justify-end pt-4 mt-4 border-t border-gray-200">
              <button  onClick={() => onCancelHandler()} className={`borderedBtn mr-2 linkText hover:text-black`}>
                Cancel
              </button>
              <SubmitBtn className={`commonBtn`}>
                {userId ? 'Update' : 'Add'}
              </SubmitBtn>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};
