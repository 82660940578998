import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../../../../../../context/UserContext";
import { CustomerTokens } from "../../../../../../../types/Customer";

export const LocationDevicesTableFields = () => {
  const { hasPermission } = useUser();
  const navigate = useNavigate();

  const filterParams = {
    filterOptions: ['contains'],
    defaultOption: 'contains',
    maxNumConditions: 1,
    debounceMs: 200,
  };

  let columns = [
    {
      headerName: 'Equipment Number',
      field: 'EquipmentNumber',
      filterParams: filterParams,
      width: 200,
      cellRenderer: (options: any) => (
        <Link
          className="linkText"
          to={`/customer/device-detail/${options.data.EquipmentID}`}
        >
          {options.data.EquipmentNumber}
        </Link>
      ),
      onCellClicked: (options: any) => {
        navigate('/customer/device-detail/' + options.data.EquipmentID);
      },
    },
    {
      headerName: 'Serial Number',
      field: 'SerialNumber',
      filterParams: filterParams,
      width: 200,
    },
    {
      headerName: 'Model',
      field: 'Model',
      filterParams: filterParams,
      width: 200,
    },
    {
      headerName: 'Location Remark',
      field: 'Location',
      filterParams: filterParams,
      width: 200,
    },
    {
      headerName: 'City',
      field: 'City',
      filterParams: filterParams,
      width: 100,
    },
    {
      headerName: 'State',
      field: 'State',
      filterParams: filterParams,
      width: 100,
    },
    {
      headerName: 'Request Service',
      field: 'request-service-btn',
      filter: null,
      isShowCSVfield: false,
      width: 175,
      minWidth: 200,
      cellRenderer: (options: any) => (
        <Link
          className="service-request-view borderedBtn linkText hover:text-black"
          to={`/service/request/${options.data.EquipmentID}`}
        >
          Request Service
        </Link>
      ),
      onCellClicked: (options: any) => {
        navigate('/service/request/' + options.data.EquipmentID);
      },
    },
    {
      headerName: 'Order supplies',
      filter: null,
      field: 'order-supplies-btn',
      isShowCSVfield: false,
      width: 170,
      minWidth: 200,
      cellRenderer: (options: any) => (
        <Link
          className="service-request-view borderedBtn linkText hover:text-black"
          to={`/product/search/${options.data.EquipmentID}`}
        >
          Order supplies
        </Link>
      ),
      onCellClicked: (options: any) => {
        navigate('/product/search/' + options.data.EquipmentID);
      },
    },
  ]

  if(!hasPermission(CustomerTokens.PlaceServiceRequest)) {
    columns.forEach((column, index) => {
      if(column.headerName === 'Request Service') {
        columns.splice(index, 1)
      }
    })
  }
  if(!hasPermission(CustomerTokens.PlaceOrder)) {
    columns.forEach((column, index) => {
      if(column.headerName === 'Order supplies') {
        columns.splice(index, 1)
      }
    })
  }


  return columns;
};
