




import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactSelectCompo } from '../../../../components/ui/react-select/react-select';
import { Api } from '../../../../shared/api/api';
import { RequestDevice } from './request-device';
import { ComponentLoading } from '../../../../shared/component-loader/component-loading';
import { useUser } from '../../../../context/UserContext';


const ServiceRequest = () => {

  const { customerInfo } = useUser();
  const { equipmentId, serviceRequestId } =  useParams();
  const navigate = useNavigate();
  const [ devices, setDevices ] = useState([]);
  const [ problems, setProblems ] = useState([]);
  const [ openRequests, setOpenRequests ] = useState([]);
  const [ selectedDevice, setSelectedDevice ] = useState(null);
  const [ oldServiceRequest, setOldServiceRequest ] = useState(null);

  const { ServiceRequestContactDefaultEmail: defaultEmail, ServiceRequestContactDefaultName: defaultName, ServiceRequestContactDefaultPhone: defaultPhone } = customerInfo;

  // Admin Setting to change default contact details
  const hasLocationContactDetail = 
    defaultEmail == 'locationContact' || 
    defaultName == 'locationContact' ||
    defaultPhone == 'locationContact';

  const locationId =
  customerInfo.RestrictUserByLocationID &&
  customerInfo.RestrictUserByLocationID.length
    ? customerInfo.RestrictUserByLocationID.toString()
    : null;

  const deviceLabelValueArr = devices.map((data) => {
    let hasRequest = openRequests.find((request) => request.EquipmentNumber === data.EquipmentNumber);
    return {
      value: data.EquipmentID,
      label: data.EquipmentNumber,
      disabled: hasRequest,
      ...data,
    };
  });

  const customFilter = (filterOptions, filter) => {
    if (!filter) {
      return filterOptions;
    }
    return filterOptions.filter(
      ({ SerialNumber, EquipmentNumber, Model, Description, Location }) =>
        (EquipmentNumber?.toLowerCase().includes(filter.toLowerCase())) ||
        (SerialNumber?.toLowerCase().includes(filter.toLowerCase())) ||
        (Model?.toLowerCase().includes(filter.toLowerCase())) ||
        (Description?.toLowerCase().includes(filter.toLowerCase())) ||
        (Location?.toLowerCase().includes(filter.toLowerCase())),
    );
  };

  const initServiceRequest = async () => {
    //Reset Devices
    setDevices([])

    try {

      let [ apiDevices, apiProblems, apiOpenRequests, serviceReq ] = await Promise.all([
        Api.callAPI({
          url: `/api/v1/equipments/customer/${customerInfo.CustomerId}`,
          method: 'PUT',
          body: {
            LocationID: locationId,
          },
          options: {
            isLoading: false,
            showErrorMessage: false,
          },
        }),
        Api.callAPI({
          url: `/api/v1/services/problems`,
          method: 'GET',
          options: {
            isLoading: false,
            showErrorMessage: false,
          },
        }),
        Api.callAPI({
          url: `/api/v1/services/requestCalls`,
          method: 'PUT',
          body: {
            LocationId: locationId,
            CustomerId: customerInfo.CustomerId,
            onlyMyCallsRequest: false,
            showServiceRequestsForDashboard: true,
          },
          options: {
            isLoading: false,
            showErrorMessage: false,
          },
        }),
        serviceRequestId && Api.callAPI({
          url: `/api/v1/services/requestCall/${serviceRequestId}/${customerInfo.CustomerId}`,
          body: { LocationID: locationId },
          method: 'PUT',
          options: {
            showErrorMessage: false,
          },
        })
      ]);

      //Filter based on Admin Portal, if none set in admin, show all
      let filterSymptoms = customerInfo?.PrimarySymptom?.length > 0 ?
        customerInfo.PrimarySymptom.map(id => id = apiProblems?.data?.find((problem) => problem.ProblemCodeID === id)) :
        apiProblems?.data;

      if(!!equipmentId) {
        const foundDevice = apiDevices?.data?.find((device) => device.EquipmentID === parseInt(equipmentId));
        setSelectedDevice(foundDevice)
      }

      setDevices(apiDevices?.data || []);
      setProblems(filterSymptoms);
      setOpenRequests(apiOpenRequests?.data || []);
      setOldServiceRequest(serviceReq?.data || null);
    } catch (e) {
    }

    
  }

  useEffect(() => {
    initServiceRequest()
  }, [])

  const NoDeviceOption = () => {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="text-2xl font-medium">No Device Found</div>
        <div className="text-sm">Please contact your account manager</div>
      </div>
    )
  }

  return (
    <div>
      <div className=" border-b border-gray-200 pb-4 mb-4">
        <h1 className='text-2xl font-medium'>
          {equipmentId && serviceRequestId ? `Reopen Service Request ${!!selectedDevice?.EquipmentNumber ? `for ${selectedDevice?.EquipmentNumber}` : ''}` :  '' }
          {(selectedDevice && !serviceRequestId) ? `New Service Request For ${selectedDevice?.EquipmentNumber || 'Unmarked Device'}` : !serviceRequestId && 'Service Request' }
        </h1>
      </div>
      <div className="flex lg:flex-row flex-col">
        { !devices.length ? <ComponentLoading isShowLoader={true}/> : <>
      { (deviceLabelValueArr.length > 0 && !selectedDevice) &&
        <div className="bg-gray-100 rounded-lg lg:p-6 p-4 h-full flex flex-col justify-between lg:w-[30vw] pr-6">
          <label className="text-sm text-gray-600 mb-2">
            Equipment Search
          </label>
           <ReactSelectCompo
            name="EquipmentID"
            label="Equipment ID"
            lazyLoadOptions={{
              perPageData: 40,
            }}
            options={[
              ...deviceLabelValueArr,
              { value: 'noDeviceFound', label: 'No Device Found', notFound: true },
            ]}
            value={selectedDevice}
            ItemRenderer={(option) => {
              if(!!option.notFound) return <NoDeviceOption />
              return <div className={`border-b-[1px] text-xs p-2 ${!!option.option.disabled && 'bg-stone-200'} relative`} onClick={() => !option.disabled ? setSelectedDevice(option.option) : navigate(`/service/service-request-detail/${option.disabled.WorkOrderID}`)}>
                <div className="font-bold">Equipment Number: <span className="font-normal">{option.option.EquipmentNumber}</span></div>
                <div className="font-bold">Serial Number: <span className="font-normal">{option.option.SerialNumber}</span></div>
                <div className="font-bold">Model: <span className="font-normal">{option.option.Model}</span></div>
                <div className="font-bold">Location: <span className="break-all font-normal">{option.option.Location}</span></div>
                <div className="font-bold">Description: <span className="break-all font-normal">{option.option.Description}</span></div>
                {!!option.disabled && <Link
                  className="italic font-bold p-2 mt-2 inline-block rounded bg-stone-600 text-white"
                  to={`/service/service-request-detail/${option.disabled.WorkOrderID}`}>View Open Request</Link>}
              </div>
            }}
            labelledBy='EquipmentID'
            className='rmsc-slim w-full'
            isSingleSelect={true}
            isCheckboxVisible={false}
            hasSelectAll={false}
            filterOptions={customFilter}
          />
          <div className="text-center pt-6 border-t border-gray-300 mt-6">
            <div>Can't find your device? <span className="linkText cursor-pointer"
              onClick={() => setSelectedDevice({
                EquipmentID: null,
              })}>Click Here</span></div>
          </div>
        </div> }
        { selectedDevice && <RequestDevice
          hasLocationContactDetail={hasLocationContactDetail}
          oldRequest={oldServiceRequest && oldServiceRequest.ServiceRequestInfoDisplay}
          problems={problems}
          customer={customerInfo}
          device={selectedDevice}
          clearSelectedDevice={() => setSelectedDevice(false)}/>}
        </>}
        </div>
    </div>
  );
};

export default ServiceRequest;
