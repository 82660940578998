import React, { forwardRef, useImperativeHandle } from 'react';
import { useModal } from '../../../../../../context/ModalConext';
import {
  Form,
  FormItems,
  FormType,
  SubmitBtn,
} from '../../../../../../components/form';
import { Api } from '../../../../../../shared/api/api';
import { Button } from '../../../../../../components/ui';
import {
  PASSWORD_REGEX,
  // eslint-disable-next-line import/named
  PASSWORD_REGEX_ERROR_MESSAGE,
} from '../../../../../../constant/constant';

const ChangePasswordDialog = ({}, ref) => {
  const { openModal, closeModal } = useModal();

  const handleSubmit = async (values, userDataCellData) => {
    try {
      const {
        cellData: { data: userInfo },
      } = userDataCellData;
      const changePasswordObj = {
        newPassword: values?.Password,
        _id: userInfo._id,
      };
      await Api.callAPI({
        url: `/api/v1/users/${changePasswordObj._id}/updatePassword`,
        method: 'post',
        body: changePasswordObj,
        options: {
          isLoading: true,
          isShowApiErrorMessage: false,
          successMessage: 'Your password has been changed successfully.',
          errorMessage: 'Error while changing password.',
        },
      });
      closeModal();
    } catch (e) {}
  };
  const ChangePasswordModal = (cellData) => {
    return (
      <Form onSubmit={(formData) => handleSubmit(formData, cellData)}>
        <div>
          <div className='p-6 pb-0'>
          <FormItems
            items={[
              {
                name: 'Password',
                label: 'Password',
                type: FormType.PASSWORD,
                placeholder: 'Password',
                required: true,
                minLength: 6,
                className: 'mb-6',
                pattern: PASSWORD_REGEX,
                errors: {
                  required: 'Please provide password.',
                  minLength: 'Minimum length should be 6.',
                  pattern: PASSWORD_REGEX_ERROR_MESSAGE
                },
              },
              {
                name: 'confirmPassword',
                label: 'Confirm Password',
                type: FormType.PASSWORD,
                placeholder: 'Confirm Password',
                matchInput: 'Password',
                className: 'mb-6',
                errors: {
                  required: 'Please provide confirm password.',
                  matchInput: 'Your password did not match.',
                },
                required: true,
              },
            ]}
          />
          </div>
          <div className="flex justify-end py-4 px-6 border-t border-gray-200 ">
            <Button
              className='borderedBtn mr-4'
              onClick={() => {
                closeModal();
              }}
              ghost
            >
              Cancel
            </Button>
            <SubmitBtn className="commonBtn">Change Password </SubmitBtn>
          </div>
        </div>
      </Form>
    );
  };
  const openChangePasswordDialog = (cellData) => {
    openModal({
      content: (
        <>
          <ChangePasswordModal cellData={cellData} />
        </>
      ),
      options: {
        title: 'Change Password',
        footer: <></>,
      },
    });
  };
  useImperativeHandle(ref, () => ({
    openChangePasswordDialog(cellData) {
      openChangePasswordDialog(cellData);
    },
  }));
  return <></>;
};

export default forwardRef(ChangePasswordDialog);
