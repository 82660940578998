import { Link, useNavigate } from "react-router-dom";
import { Chip } from "../../../../../components/ui/Chip";

export const OpenRequestTableField = () => {
  const navigate = useNavigate();
  const filterParams = {
    filterOptions: ['contains'],
    defaultOption: 'contains',
    maxNumConditions: 1,
    debounceMs: 200,
  };
  return [
    {
      headerName: 'Request #',
      field: 'WorkOrderNumber',
      width: 130,
      filterParams: filterParams,
      cellRenderer: (cell: any) => {
        return cell && cell.data ? (
          <Link
            className="service-request-view linkText"
            to={`/service/service-request-detail/${cell.data.WorkOrderID}`}
          >
            {cell.data.WorkOrderNumber}
          </Link>
        ) : (
          ''
        );
      },
    },
    {
      headerName: 'Equipment #',
      field: 'EquipmentNumber',
      filterParams: filterParams,
      width: 170,
      cellRenderer: (options: any) => {
        return (
          <Link
            className="service-request-view linkText"
            to={`/customer/device-detail/${options.data.EquipmentID}`}
          >
            {options.data.EquipmentNumber}
          </Link>
        );
      },
      onCellClicked: (options: any) => {
        navigate(`/customer/device-detail/${options.data.EquipmentID}`);
      },
    },
    {
      headerName: 'Location',
      filterParams: filterParams,
      field: 'CustomerName',
      width: 160,
      sortable: true,
    },
    {
      headerName: 'Request Placed',
      field: 'Date',
      width: 230,
      filterParams: filterParams,
    },
    {
      headerName: 'Serial Number',
      field: 'SerialNumber',
      filterParams: filterParams,
      width: 160,
    },
    {
      headerName: 'Location Remarks',
      field: 'LocationRemarks',
      filter: null,
      sortable: false,
      filterParams: filterParams,
      width: 200,
    },
    {
      headerName: 'Problem Description',
      filterParams: filterParams,
      field: 'ProblemDescription',
      filter: null,
      sortable: false,
      autoHeight: true,
      valueGetter: (params: any) => {
        if (!params?.data?.ProblemDescription) {
          return null;
        }

        return params?.data?.ProblemDescription.join('\n');
      },
      cellRenderer: (cell: any) => {
        if (!cell.data.ProblemDescription) return null;
        return (
          <div
            className={`${
              cell.data.ProblemDescription.length > 0
                ? 'lineHeight22'
                : 'lineHeight48 overflow-auto'
            }`}
          >
            {cell.data.ProblemDescription.map((problem: any) => {
              return <div key={problem}>{problem}</div>;
            })}
          </div>
        );
      },
    },
    {
      headerName: 'Status',
      width: 160,
      filterParams: filterParams,
      field: 'Status',
      cellRenderer: (options: any) => <Chip status={options.value}/>,
    },
    {
      headerName: 'Request Type',
      width: 130,
      filterParams: filterParams,
      field: 'CallType',
      filter: null,
      sortable: false,
    },
    {
      filter: null,
      sortable: false,
      headerName: 'Latest Update',
      width: 130,
      filterParams: filterParams,
      field: 'RepairRemarks',
    },
  ];
};
