import React, { useEffect, useRef, useState } from 'react';
import {
  CancelBtn,
  Form,
  FormItem,
  FormItems,
  FormRef,
  FormType,
  InputMask,
  SubmitBtn,
} from '../../../../../components/form';
import { Api } from '../../../../../shared/api/api';
import { DeviceSelectionIcon } from '../../../../../assets/svg/device-selection-icon';
import { DeliveryTruckIcon } from '../../../../../assets/svg/delivery-truck-icon';
import { useNavigate } from 'react-router-dom';
import { LocationIcon } from '../../../../../assets/svg/location-icon';
import { ComponentLoading } from '../../../../../shared/component-loader/component-loading';
import { useUser } from '../../../../../context/UserContext';

const MoveDevice = () => {
  const { customerInfo } = useUser();
  const navigate = useNavigate();
  const formRef: FormRef = useRef(null);
  const [locations, setLocations] = useState<any>([]);
  const [devices, setDevices] = useState([]);
  const [showOtherLocation, setShowOtherLocation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState('');
  const [pickUpDate, setPickUpDate] = useState(new Date().toISOString());

  const locationLabelValueArr = locations.map((data: any) => {
    return {
      value: data.CustomerID,
      label: `${data.CustomerName} - ${data.Address} ${data.City} ${data.State} ${data.Zip}`,
    };
  });

  const deviceLabelValueArr = devices.map((data: any) => {
    return {
      value: `${data.EquipmentID} - ${data.SerialNumber}`,
      label: `${data.EquipmentNumber} - ${data.SerialNumber}`,
    };
  });

  const getEquipmentFromLocation = async (selectedValue: any) => {
    //Reset Devices
    formRef.clearInputs(['DeviceSelection']);
    setDevices([]);

    let selected = selectedValue[0]?.value;
    if (!selected) return;
    try {
      const res = await Api.callAPI({
        url: `/api/v1/equipments/location/${selected}/${customerInfo.CustomerId}`,
        method: 'GET',
        options: {
          isLoading: false,
          showErrorMessage: false,
        },
      });
      setDevices(res.data);
    } catch (e) {}
  };

  const getLocations = async () => {
    try {
      const dataQuery = {
        CustomerID: customerInfo.CustomerId,
        EmailAddress: customerInfo.EmailAddress,
        UserId: customerInfo.UserId,
        HideOtherUserOrders: customerInfo.HideOtherUserOrders,
        LocationID: null,
      };

      const response = await Api.callAPI({
        url: `/api/v1/location`,
        method: 'put',
        body: dataQuery,
        options: {
          isLoading: false,
          showErrorMessage: false,
        },
      });
      if (response?.data && response?.data.length) {
        setLocations([...response?.data]);
      }
    } catch (e) {}
  };

  useEffect(() => {
    getLocations();
  }, []);

  const onSubmit = async (data: any) => {

    setIsSubmitting(true);
    //Combine pickup location into single string
    let pickUpLocation = locations.find(
      (loc: any) => loc.CustomerID === data.AddedUserInfo[0],
    );
    data.pickUpLocation = `${pickUpLocation.CustomerName} - ${pickUpLocation.Address} ${pickUpLocation.City} ${pickUpLocation.State} ${pickUpLocation.Zip}`;

    //Combine delivery location into single string
    if (data.deliveryLocation[0] === 'other') {
      data.deliveryLocation = `${data.deliveryLocationName} - ${data.deliveryLocationAddress} ${data.deliveryLocationCity} ${data.deliveryLocationState} ${data.deliveryLocationZip}`;
      delete data.deliveryLocationName;
      delete data.deliveryLocationAddress;
      delete data.deliveryLocationCity;
      delete data.deliveryLocationState;
      delete data.deliveryLocationZip;
    } else {
      let thisLocation = locations.find(
        (loc: any) => loc.CustomerID === data.deliveryLocation[0],
      );
      data.deliveryLocation = `${thisLocation.CustomerName} - ${thisLocation.Address} ${thisLocation.City} ${thisLocation.State} ${thisLocation.Zip}`;
    }

    //Add ext to phone number in pickup and delivery
    data.pickUpOnSiteContactPhone = `${data.pickUpOnSiteContactPhone}${
      data.pickUpOnSiteContactPhoneEXT
        ? '/' + data.pickUpOnSiteContactPhoneEXT
        : ''
    }`;
    data.deliveryOnSiteContactPhone = `${data.deliveryOnSiteContactPhone}${
      data.deliveryOnSiteContactPhoneEXT
        ? '/' + data.deliveryOnSiteContactPhoneEXT
        : ''
    }`;
    delete data.pickUpOnSiteContactPhoneEXT;
    delete data.deliveryOnSiteContactPhoneEXT;

    //Convert dates to yyyy-mm-dd
    data.pickUpDate = new Date(data.pickUpDate).toISOString().split('T')[0];
    data.deliveryDate = new Date(data.deliveryDate).toISOString().split('T')[0];

    //Add customer info
    data.AddedUserInfo = customerInfo;
    
    //Remove device serial number from device selection
    data.DeviceSelection = data.DeviceSelection.map((device: any) => {
      return parseInt(device.split(' ')[0]);
    });
    //Add device info
    data.DeviceSelection = devices.filter((device: any) =>
      data.DeviceSelection.includes(device.EquipmentID),
    );


    //TODO: Handle errors & change to toast message
    const res = await Api.callAPI({
      url: `/api/v1/equipments/moveDevice`,
      method: 'post',
      body: data,
      options: {
        isLoading: false,
        successMessage: 'Device move request submitted successfully.',
        errorMessage: 'Error while submitting device move request.',
      },
    });

    setIsSubmitting(false);

    if (res.status === 200) {
      setSubmitted(res.data.message);
      navigate(`/customer/devices`);
    }
  };

  if (!!submitted)
    return (
      <div className="flex items-center justify-center w-full h-full">
        {submitted}
      </div>
    );

  return (
    locationLabelValueArr.length !== 0 ? <div>
      <h1 className="mb-4 text-2xl font-medium">Move Device Request</h1>
      <Form formRef={formRef} onSubmit={onSubmit}>
        <div>
          <h1 className="mb-4 text-xl font-medium flex items-center md:-ml-7">
            <LocationIcon /> <span className="ml-2">Pickup Details</span>
          </h1>
          <FormItem
              key={locationLabelValueArr[0]?.value}
              formDef={{
                name: 'AddedUserInfo',
                label: 'Pick Up Location',
                type: FormType.SELECT,
                required: true,
                options: locationLabelValueArr,
                selectShowCheckboxes: false,
                optionKeyMapObject: {
                  labelKey: 'CustomerName',
                  valueKey: 'CustomerID',
                },
                onChange: (e) => getEquipmentFromLocation(e),
                className: 'rmsc-slim mb-4',
              }}
            />
          <div className="ml-6">
          <h3 className="mt-6 mb-4 flex items-center">
            <span className="">Pickup Contact Info</span>
          </h3>
          <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4">
            <FormItems
              items={[
                {
                  name: 'pickUpOnSiteContactName',
                  label: 'On Site Contact Name',
                  type: FormType.TEXT,
                  required: true,
                },
              ]}
            />
            <FormItem
              formDef={{
                name: 'pickUpOnSiteContactEmail',
                label: 'On Site Contact Email',
                type: FormType.EMAIL,
                required: true,
              }}
            />
            <div className="flex w-full">
              <div className="w-full">
                <FormItem
                  formDef={{
                    name: 'pickUpOnSiteContactPhone',
                    label: 'On Site Contact Phone',
                    type: FormType.TEXT,
                    mask: InputMask.PHONE,
                    required: true,
                  }}
                />
              </div>
              <div className="w-full ml-4 max-w-[33%]">
                <FormItem
                  formDef={{
                    name: 'pickUpOnSiteContactPhoneEXT',
                    label: 'Ext. (Optional)',
                    maxLength: 10,
                    type: FormType.TEXT,
                  }}
                />
              </div>
            </div>
            </div>

          <h3 className="mb-4 mt-6 flex items-center">
            <span className="">Pickup Requirements</span>
          </h3>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
            <FormItem
              formDef={{
                name: 'pickUpDate',
                label: 'Requested Pick Up Date',
                type: FormType.DATE,
                required: true,
                minDate: new Date().toISOString(),
                maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 5)).toISOString(),
                errors: {
                  maxDate: 'Your pick up date must be within 5 years',
                  minDate: 'Your pick up date must be later than today',
                },
                onChange: (e) =>
                  setPickUpDate(
                    new Date(e.target.value)?.toISOString()?.split('T')[0],
                  ),
              }}
            />
            <FormItem
              formDef={{
                name: 'pickUpLocationHour',
                label: 'Location Hours',
                type: FormType.TEXT,
                required: true,
              }}
              />
            <div></div>
            <div className="mb-2">
              <div className="mb-1 text-sm text-gray-500">
                Is the Certificate of Insurance Required? <span className="text-red-300">*</span>
              </div>
              <FormItem
                formDef={{
                  name: 'pickUpCertificateOfInsurance',
                  label: 'Certificate of Insurance Required',
                  type: FormType.RADIO,
                  radioOptions: [
                    { label: 'Yes', name: 'certYes', value: 'Yes' },
                    { label: 'No', name: 'certNo', value: 'No' },
                  ],
                  required: true,
                  displayRow: true,
                  errors: { required: 'A selection is required' },
                }}
              />
            </div>
            <div className="mb-2">
              <div className="mb-1 text-sm text-gray-500">
                Are there Stairs on Location? <span className="text-red-300">*</span>
              </div>
              <FormItem
                formDef={{
                  name: 'pickUpStairsOnSite',
                  label: 'Stairs on site',
                  type: FormType.RADIO,
                  radioOptions: [
                    { label: 'Yes', name: 'stairsYes', value: 'Yes' },
                    { label: 'No', name: 'stairsNo', value: 'No' },
                  ],
                  required: true,
                  displayRow: true,
                  errors: { required: 'A selection is required' },
                }}
              />
            </div>
            <div>
              <div className="mb-1 text-sm text-gray-500">
                Is Lift Gate Required? <span className="text-red-300">*</span>
              </div>
              <FormItem
                formDef={{
                  name: 'pickUpLiftGate',
                  label: 'Lift Gate',
                  type: FormType.RADIO,
                  radioOptions: [
                    { label: 'Yes', name: 'liftYes', value: 'Yes' },
                    { label: 'No', name: 'liftNo', value: 'No' },
                  ],
                  required: true,
                  displayRow: true,
                  errors: { required: 'A selection is required' },
                }}
              />
            </div>
          </div>
          </div>
          <div>
            <h1 className="mt-8 mb-2 text-xl font-medium flex items-center md:-ml-7 border-t pt-6">
              <DeviceSelectionIcon />{' '}
              <span className="ml-2">Choose Device</span>
            </h1>
            {deviceLabelValueArr.length > 0 ? (
              <div>
                <FormItem
                  formDef={{
                    name: 'DeviceSelection',
                    label: 'Device Selection',
                    type: FormType.SELECT,
                    required: true,
                    options: deviceLabelValueArr,
                    selectShowSelectAll: true,
                    selectMultiSelect: true,
                    optionKeyMapObject: {
                      labelKey: 'EquipmentNumber',
                      valueKey: 'EquipmentID',
                    },
                    className: 'rmsc-slim',
                  }}
                />
              </div>
            ) : (
              <div className="italic text-sm">
                You must select a pick up location before you can choose a device
              </div>
            )}
            <h1 className="mt-8 mb-2 text-xl font-medium flex items-center md:-ml-7 border-t pt-6">
              <DeliveryTruckIcon />{' '}
              <span className="ml-2">Drop Off Details</span>
            </h1>
            <FormItem
                key={locationLabelValueArr[0]?.label}
                formDef={{
                  name: 'deliveryLocation',
                  label: 'Delivery Location',
                  type: FormType.SELECT,
                  required: true,
                  options: [
                    { label: 'Ship to another Location', value: 'other' },
                    ...locationLabelValueArr,
                  ],
                  selectShowCheckboxes: false,
                  optionKeyMapObject: {
                    labelKey: 'CustomerName',
                    valueKey: 'CustomerID',
                  },
                  onChange: (e) => {
                    setShowOtherLocation(e[0]?.value === 'other');
                    formRef.removeFormDef([
                      'deliveryLocationName',
                      'deliveryLocationAddress',
                      'deliveryLocationCity',
                      'deliveryLocationState',
                      'deliveryLocationZip',
                    ]);
                    formRef.clearInputs([
                      'deliveryLocationName',
                      'deliveryLocationAddress',
                      'deliveryLocationCity',
                      'deliveryLocationState',
                      'deliveryLocationZip',
                    ]);
                  },
                  className: 'rmsc-slim',
                }}
              />
              <div className="ml-6">
              {showOtherLocation && (
                  <>
                      <h3 className="mb-4 mt-6 flex items-center">
                        <span className="">Drop Off Location</span>
                      </h3>
                      <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                    <FormItems
                      items={[
                        {
                          name: 'deliveryLocationName',
                          label: 'Location Name',
                          type: FormType.TEXT,
                          required: true,
                        },
                      ]}
                    />
                    <FormItems
                      items={[
                        {
                          name: 'deliveryLocationAddress',
                          label: 'Address',
                          type: FormType.TEXT,
                          required: true,
                        },
                      ]}
                    />
                    <FormItems
                      items={[
                        {
                          name: 'deliveryLocationCity',
                          label: 'City',
                          type: FormType.TEXT,
                          required: true,
                        },
                      ]}
                    />
                    <div className="flex w-full">
                      <div className="w-full">
                        <FormItem
                          formDef={{
                            name: 'deliveryLocationState',
                            label: 'State',
                            type: FormType.TEXT,
                            required: true,
                          }}
                        />
                      </div>
                      <div className="w-full ml-4 max-w-[33%]">
                        <FormItem
                          formDef={{
                            name: 'deliveryLocationZip',
                            label: 'Zip',
                            type: FormType.TEXT,
                            required: true,
                          }}
                        />
                      </div>
                    </div>
                    </div>
                  </>
                )}
              <h3 className="mb-4 mt-6 flex items-center">
                <span className="">Drop Off Contact Info</span>
              </h3>
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                <FormItems
                  items={[
                    {
                      name: 'deliveryOnSiteContactName',
                      label: 'On Site Contact Name',
                      type: FormType.TEXT,
                      required: true,
                    },
                  ]}
                />
                <FormItem
                  formDef={{
                    name: 'deliveryOnSiteContactEmail',
                    label: 'On Site Contact Email',
                    type: FormType.EMAIL,
                    required: true,
                  }}
                />
                <div className="flex w-full">
                  <div className="w-full">
                    <FormItem
                      formDef={{
                        name: 'deliveryOnSiteContactPhone',
                        label: 'On Site Contact Phone',
                        type: FormType.TEXT,
                        mask: InputMask.PHONE,
                        required: true,
                      }}
                    />
                  </div>
                  <div className="w-full ml-4 max-w-[33%]">
                    <FormItem
                      formDef={{
                        name: 'deliveryOnSiteContactPhoneEXT',
                        label: 'Ext. (Optional)',
                        maxLength: 10,
                        type: FormType.TEXT,
                      }}
                    />
                  </div>
                </div>
                <FormItem
                  formDef={{
                    name: 'deliveryNewMeterContact',
                    label: 'New Meter Contact',
                    type: FormType.TEXT,
                    required: true,
                  }}
                />
                </div>
                <h3 className="mb-4 mt-6 flex items-center">
                <span className="">Drop Off Requirements</span>
              </h3>
              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                <FormItem
                  formDef={{
                    name: 'deliveryDate',
                    label: 'Requested Delivery Date',
                    type: FormType.DATE,
                    required: true,
                    minDate: pickUpDate,
                    maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 5)).toISOString(),
                    errors: {
                      maxDate: 'Your delivery date must be within 5 years',
                      minDate:
                        'Your delivery date must be later than your desired pick up date',
                    },
                  }}
                />
                <FormItem
                  formDef={{
                    name: 'deliveryLocationHour',
                    label: 'Location Hours',
                    type: FormType.TEXT,
                    required: true,
                  }}
                />
                <div></div>
                <div className="mb-2">
                  <div className="mb-1 text-sm text-gray-500">
                    Is the Certificate of Insurance Required? <span className="text-red-300">*</span>
                  </div>
                  <FormItem
                    formDef={{
                      name: 'deliveryCertificateOfInsurance',
                      label: 'Certificate of Insurance Required',
                      type: FormType.RADIO,
                      radioOptions: [
                        { label: 'Yes', name: 'deliveryCertYes', value: 'Yes' },
                        { label: 'No', name: 'deliveryCertNo', value: 'No' },
                      ],
                      required: true,
                      displayRow: true,
                      errors: { required: 'A selection is required' },
                    }}
                  />
                </div>
                <div className="mb-2">
                  <div className="mb-1 text-sm text-gray-500">
                    Are there Stairs on Location? <span className="text-red-300">*</span>
                  </div>
                  <FormItem
                    formDef={{
                      name: 'deliveryStairsOnSite',
                      label: 'Stairs on site',
                      type: FormType.RADIO,
                      radioOptions: [
                        { label: 'Yes', name: 'deliveryStairsYes', value: 'Yes' },
                        { label: 'No', name: 'deliveryStairsNo', value: 'No' },
                      ],
                      required: true,
                      displayRow: true,
                      errors: { required: 'A selection is required' },
                    }}
                  />
                </div>
                <div>
                  <div className="mb-1 text-sm text-gray-500">
                    Is Lift Gate Required? <span className="text-red-300">*</span>
                  </div>
                  <FormItem
                    formDef={{
                      name: 'deliveryLiftGate',
                      label: 'Lift Gate',
                      type: FormType.RADIO,
                      radioOptions: [
                        { label: 'Yes', name: 'deliveryLiftYes', value: 'Yes' },
                        { label: 'No', name: 'deliveryLiftNo', value: 'No' },
                      ],
                      required: true,
                      displayRow: true,
                      errors: { required: 'A selection is required' },
                    }}
                  />
                </div>
                <div>
                  <div className="mb-1 text-sm text-gray-500">
                    Is Network Setup Required? <span className="text-red-300">*</span>
                  </div>
                  <FormItem
                    formDef={{
                      name: 'deliveryNetworkSetup',
                      label: 'Network Setup',
                      type: FormType.RADIO,
                      radioOptions: [
                        {
                          label: 'Yes',
                          name: 'deliveryNetworkYes',
                          value: 'Yes',
                        },
                        { label: 'No', name: 'deliveryNetworkNo', value: 'No' },
                      ],
                      required: true,
                      displayRow: true,
                      errors: { required: 'A selection is required' },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-[1px] bg-stone-200 w-full my-6"></div>
        <div className="flex justify-end">
          <CancelBtn
            className="rounded p-1 px-4 mr-4"
            ghost
            onClick={() => navigate(`/customer/devices`)}
          >
            Cancel
          </CancelBtn>
          <SubmitBtn className="rounded p-1 px-4" loading={isSubmitting}>
            Submit Device Move Request
          </SubmitBtn>
        </div>
      </Form>
    </div> : <ComponentLoading isShowLoader={true}/>
  );
};

export default MoveDevice;
