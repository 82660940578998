export const PushpinIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_782_9435)">
        <path
          d="M7.5 9.16699C7.5 9.83003 7.76339 10.4659 8.23223 10.9348C8.70107 11.4036 9.33696 11.667 10 11.667C10.663 11.667 11.2989 11.4036 11.7678 10.9348C12.2366 10.4659 12.5 9.83003 12.5 9.16699C12.5 8.50395 12.2366 7.86807 11.7678 7.39922C11.2989 6.93038 10.663 6.66699 10 6.66699C9.33696 6.66699 8.70107 6.93038 8.23223 7.39922C7.76339 7.86807 7.5 8.50395 7.5 9.16699Z"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.7143 13.8806L11.1785 17.4164C10.866 17.7286 10.4423 17.904 10.0006 17.904C9.55885 17.904 9.13518 17.7286 8.82267 17.4164L5.286 13.8806C4.35368 12.9482 3.71878 11.7603 3.46157 10.4671C3.20437 9.17394 3.33641 7.83352 3.841 6.61536C4.3456 5.39721 5.20008 4.35604 6.2964 3.62351C7.39272 2.89098 8.68164 2.5 10.0002 2.5C11.3187 2.5 12.6076 2.89098 13.7039 3.62351C14.8003 4.35604 15.6547 5.39721 16.1593 6.61536C16.6639 7.83352 16.796 9.17394 16.5388 10.4671C16.2816 11.7603 15.6466 12.9482 14.7143 13.8806Z"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_782_9435">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
