import React, { useEffect, useState } from 'react';

import { useSelectContext } from '../hooks/use-select-context';

export const DropdownHeader = ({
  expanded,
  setExpanded,
  dropdownHeaderDivId,
}: any) => {
  const {
    t,
    value,
    options,
    valueRenderer,
    onChange,
    isSingleSelect,
    isDisplayElipsis,
    onClose,
    selectAllValue,
  }: any = useSelectContext();

  const noneSelected: any = value.length === 0;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const allSelected = value.length === options.length && options.length > 1;
  const customText = valueRenderer && valueRenderer(value, options);
  const [lWidth, setLWidth] = useState(null);
  const onRemoveOption = (e: any, val: any) => {
    e.stopPropagation();
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const filterOpions = value.filter(({ value }: any) => value !== val);
    onChange(filterOpions);
    if (!expanded) {
      onClose(filterOpions);
    }
    setExpanded(expanded);
  };

  const setLabelWidth = () => {
    if (isDisplayElipsis) {
      setTimeout(() => {
        if (document.getElementById(dropdownHeaderDivId)) {
          const width =
            document.getElementById(dropdownHeaderDivId).offsetWidth;
          setLWidth(width);
        }
      }, 100);
    }
  };

  useEffect(() => {
    setLabelWidth();
    window.addEventListener('resize', () => {
      setLabelWidth();
    });
  }, [dropdownHeaderDivId]);
  const getSelectedText = () => {

    return (
      <div className={'dropdown-selected-label-main'}>
        { !allSelected ? value.map((s: any) => {
          return (
            <span
              key={s.label}
              className={`${
                isSingleSelect
                  ? 'dropdown-single-selected-label'
                  : 'dropdown-selected-label'
              }`}
            >
              <span
                className={'label'}
                title={s.label}
                style={
                  isDisplayElipsis
                    ? {
                        width: `${lWidth}px`,
                      }
                    : {}
                }
              >
                {s.label}
              </span>
              {!isSingleSelect ? (
                <span
                  className={
                    'flex items-center justify-center px-2 w-fit cursor-pointer'
                  }
                  onClick={(e) => onRemoveOption(e, s.value)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3 h-3"
                    fill="currentColor"
                    viewBox="0 0 448 512"
                  >
                    <path d="M163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3C140.6 6.8 151.7 0 163.8 0zM32 128H416L394.8 467c-1.6 25.3-22.6 45-47.9 45H101.1c-25.3 0-46.3-19.7-47.9-45L32 128zM143 239c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
                  </svg>
                </span>
              ) : (
                ''
              )}
            </span>
          );
        }) : <span className="text-gay-800">{selectAllValue ? selectAllValue : 'All'}</span> }
      </div>
    );
  };

  return noneSelected ? (
    <span className="gray">{customText || t('selectSomeItems')}</span>
  ) : (
    <span>{getSelectedText()}</span>
  );
};
