import React from 'react'
import './index.scss';

export default function SSOLogout() {
    return (
        <div className="message-container">
            <i className="fa fa-check-circle" aria-hidden="true"></i>
            <div className="message">
                To log back in, visit the Supplies Page on the Store Portal.
            </div>
        </div>

    )
}
