import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AdminStorageKey } from '../../../../constant/constant';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import useClickOutside from '../../../../hooks/use-click-outside/use-click-outside';
import { CloseIcon } from '../../../../assets/svg/close-icon';

import {
  APPLICATION_CONTEXT,
  RemoveLocalStorageKeys,
} from '../../../../utils/utils';
import { UserIcon } from '../../../../assets/svg/user-icon';
import { CanHavePermissionAdmin } from '../../../../shared/can-have-permission/can-have-permission-admin';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const headerRef = useClickOutside(() => setIsOpenMenu(false));
  const dropdownRef = useClickOutside(() => {
    setIsOpen(false);
  });

  const { userInfo }: any = useContext(APPLICATION_CONTEXT);




  const navigate = useNavigate();
  const location = useLocation();
  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );

  const isActiveClass = (path: any) => {
    if (path === '/admin/customer') {
      if (location.pathname.includes('/admin/link-customer/edit/')) {
        return true;
      }
      if (location.pathname.includes('/admin/link-customer/')) {
        return true;
      }
      if (location.pathname.includes('/admin/customerUser/')) {
        return false;
      }
    }
    if (path === '/admin/Users') {
      if (location.pathname.includes('/admin/users/') && location.pathname.includes('/edit')) {
        return true;
      }
      if (location.pathname.includes('/admin/users/add')) {
        return true;
      }
      if (location.pathname.includes('/admin/customerUser/')) {
        return true;
      }
    }
    if (path.includes('/admin/adminUser/list/')) {
      if (location.pathname.includes('/admin/adminUser/edit/')) {
        return true;
      }
      if (location.pathname.includes('/admin/adminUser/add')) {
        return true;
      }
    }
    return location.pathname.indexOf(path) > -1;
  };

  const menuList = [
    {
      id: 7,
      name: 'Dashboard',
      route: '/admin/dashboard',
      accessToken: ['CAN_MANAGE_SUPER_ADMIN_USER'],
    },
    {
      id: 1,
      name: 'Customers',
      route: '/admin/customer',
      accessToken: ['CAN_MANAGE_COMPANY'],
    },
    {
      id: 2,
      name: 'Users',
      route: '/admin/Users',
      accessToken: ['CAN_MANAGE_COMPANY'],
    },
    {
      id: 3,
      name: 'Logs',
      route: '/admin/access-logs',
      accessToken: ['CAN_VIEW_ACCESS_LOGS'],
    },
    {
      id: 4,
      name: 'Admin Users',
      route: `/admin/adminUser/list/${userInfo?._id}`,
      accessToken: ['CAN_MANAGE_SUPER_ADMIN_USER'],
    },
    {
      id: 5,
      name: 'Banner',
      route: '/admin/banner',
      accessToken: ['CAN_MANAGE_SUPER_ADMIN_USER'],
    },
    {
      id: 6,
      name: 'Global Settings',
      route: '/admin/global-settings',
      accessToken: ['CAN_MANAGE_SUPER_ADMIN_USER'],
    },
  ];

  useEffect(() => {
    setIsOpenMenu(false);
  }, [location?.pathname]);

  useEffect(() => {
    if (isOpenMenu) {
      document.body.classList.add('openMenuBody');
    } else {
      document.body.classList.remove('openMenuBody');
    }
  }, [isOpenMenu]);

  const logOut = async () => {
    RemoveLocalStorageKeys(AdminStorageKey, [
      AdminStorageKey.username,
      AdminStorageKey.rememberAdmin,
      AdminStorageKey.passwordAdmin,
    ]);
    navigate('/admin/login');
  };

  return (
    <>
      <header className="bg-gray-950 text-gray-400 gridSpace">
        <div className="md:py-4 py-2 md:px-8 px-4 w-full flex justify-between items-center">
          <div className="flex items-center justify-center">
            <Link to={'/admin/customer'} className="col-span-2 logo flex items-center just mr-10">
              {tenantName && <img
                  src={require(`../../../../assets/images/${tenantName}-header-logo.png`)}
              />}
              <span className="ml-2 sm:text-3xl text-2xl font-bold text-red-500">Admin</span>
            </Link>
            <div className="col-span-8">
              <div
                  ref={headerRef}
                  className={`lg:relative lg:z-0 z-40 fixed overflow-y-auto top-0 h-full bg-gray-950 mainMenu lg:w-full w-60 lg:p-0 p-4 ${
                    isOpenMenu ? 'openMenu' : ''
                  } `}
                >
                  <div className="lg:hidden flex justify-between items-center mb-8">
                      <Link to={'/admin/customer'} className="logo flex items-center mr-10">
                        {tenantName && <img
                            src={require(`../../../../assets/images/${tenantName}-header-logo.png`)}
                        />}
                        <span className="ml-2 sm:text-3xl text-2xl font-bold text-red-500">Admin</span>
                      </Link>
                    <div
                      className="pl-2 pt-1 cursor-pointer"
                      onClick={() => {
                        setIsOpenMenu(!isOpenMenu);
                      }}
                    >
                      <CloseIcon />
                    </div>
                  </div>

                  <ul className="lg:flex xl:gap-x-10 gap-x-4 justify-self-end ">
                    {menuList.map((menuItem) => {
                      return (
                        <CanHavePermissionAdmin
                          key={menuItem.id}
                          permissions={
                            menuItem?.accessToken ? menuItem?.accessToken : []
                          }
                        >
                          <li key={menuItem.id} className="cursor-pointer lg:mb-0 mb-2">
                            <Link
                              className={` hover:text-white ${
                                isActiveClass(menuItem.route)
                                  ? `text-white`
                                  : 'text-gray-400'
                              }`}
                              to={menuItem.route}
                            >
                              {menuItem.name}
                            </Link>
                          </li>
                        </CanHavePermissionAdmin>
                      );
                    })}
                  </ul>
                
              </div>
            </div>
          </div>
          <div className="lg:col-span-2 flex items-center justify-self-end">
              <div
                className={`hamburger mr-4 cursor-pointer lg:hidden block  ${
                  isOpenMenu ? 'is-active' : ''
                }`}
                onClick={() => setIsOpenMenu(!isOpenMenu)}
                id="hamburger-1"
              >
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </div>
            <div ref={dropdownRef} className="relative">
              <button
                className="flex items-center"
                onClick={() => setIsOpen(!isOpen)}
              >
                <div className="flex justify-center items-center">
                  <div className='mr-2'><UserIcon /></div>
                  <div className="text-sm text-white mr-2 sm:inline-block hidden">
                    {userInfo?.name}
                  </div>
                </div>
                <div className="downArrow"></div>
              </button>
              {isOpen ? (
                <ul className="absolute bg-gray-950 text-gray-400 rounded-b-md overflow-hidden shadow-md right-0 sm:top-10 top-9 z-10 w-40">
                  <li
                    className={`px-4 py-2 hover:text-white cursor-pointer`}
                    onClick={logOut}
                  >
                    Logout
                  </li>
                </ul>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
