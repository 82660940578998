export const UserIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 19.2998C19.3137 19.2998 22 16.6135 22 13.2998C22 9.9861 19.3137 7.2998 16 7.2998C12.6863 7.2998 10 9.9861 10 13.2998C10 16.6135 12.6863 19.2998 16 19.2998Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M7 28C7.3 23.8 11.2 20.2002 16 20.2002C20.8 20.2002 24.6 23.8 25 28"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
