import { Ref, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Api } from '../../../../../../../shared/api/api';
import { WidgetHeader } from '../../../../../grid-widget-header/widget-header';
import ApiTable from '../../../../../../../shared/api-table/api-table';
import { ClientGridName } from '../../../../../../../constant/constant';
import { OrderTableFields } from './order-table-fields';
import { useUser } from '../../../../../../../context/UserContext';
import { CustomerTokens } from '../../../../../../../types/Customer';

export const Order = ({
  title,
  viewAllRouterLink = null,
  plusRouterLink = null,
  accessToken = [],
  expandContractGrid,
  isExpand
}: any) => {
  const gridBtnClassName = 'grid-export-btn-order-grid';
  const ref: Ref<any> = useRef(null);
  let { equipmentId } = useParams();
  const gridRef: Ref<any> = useRef(null);
  const headerRef: Ref<any> = useRef(null);
  const { customerInfo, hasPermission } = useUser();
  const [isApiCallProgress, setApiCallProgress] = useState(true);
  const [rowData, setRowData] = useState<any>(false);

  const getLocationOrderData = async () => {
    try {
      setApiCallProgress(true);
      const searchQueryForOrderHistory: any = {
        CustomerId: {},
        EquipmentId: '',
        LocationID: '',
        AllowHidingUsersWebOrders: Boolean,
        UserId: '',
        EmailAddress: '',
      };

      searchQueryForOrderHistory.CustomerId = customerInfo.CustomerId;
      searchQueryForOrderHistory.EquipmentId = equipmentId;
      searchQueryForOrderHistory.LocationID =
        customerInfo.RestrictUserByLocationID &&
        customerInfo.RestrictUserByLocationID.length
          ? customerInfo.RestrictUserByLocationID.toString()
          : null;
      searchQueryForOrderHistory.EmailAddress = customerInfo.EmailAddress;
      searchQueryForOrderHistory.AllowHidingUsersWebOrders = hasPermission(CustomerTokens.ViewServiceRequest);

      searchQueryForOrderHistory.UserId = customerInfo.UserId;

      const res = await Api.callAPI({
        url: `/api/v1/orders/device`,
        method: 'PUT',
        body: searchQueryForOrderHistory,
        options: {
          successMessage: false,
          errorMessage: 'Error while getting order by EquipmentId.',
        },
      });
      if (hasPermission(CustomerTokens.ViewPlacedOrder)) {
        setRowData(res?.data || []);
      }
    } catch (e) {
    } finally {
      setApiCallProgress(false);
    }
  };

  const columns = OrderTableFields();

  const resetGrid = () => {
    if (ref.current) {
      ref.current.resetGrid();
    }
  };

  const exportCSV = async () => {
    if (ref.current) {
      await ref.current.exportCSV();
    }
  };

  useEffect(() => {
    let dheight = 0;
    if (headerRef.current) {
      dheight += parseInt(headerRef.current.clientHeight);
    }

    if (gridRef.current) {
      gridRef.current.style.height = `calc(100% -  ${dheight}px)`;
    }
  }, [headerRef?.current]);

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        (async () => {
          await getLocationOrderData();
        })();
      }, 10);
    }
  }, []);

  return (
    <>
      <div className={'w-full h-full pb-4'}>
        <div ref={headerRef}>
          <WidgetHeader
            viewAllRouterLink={viewAllRouterLink}
            plusRouterLink={plusRouterLink}
            title={title}
            accessToken={accessToken}
            resetGrid={resetGrid}
            exportCSV={exportCSV}
            gridBtnClassName={gridBtnClassName}
            expandContractGrid={expandContractGrid}
            isExpand={isExpand}
          />
        </div>
        <div ref={gridRef}>
          <ApiTable
            ref={ref}
            columns={columns}
            gridName={ClientGridName.DeviceOrder}
            rowData={rowData ? rowData : []}
            isLoading={isApiCallProgress}
            gridBtnClassName={gridBtnClassName}
            gridOptions={{
              pagination: false,
            }}
          />
        </div>
      </div>
    </>
  );
};
