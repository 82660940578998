import { ReactSelectCompo } from '../../../../components/ui/react-select/react-select';
// import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../../context/UserContext';
import { useCustomer } from '../../context/CustomerContext';

export const CustomerSelectionDropdown = () => {
  const { customerInfo, selectCustomer } = useUser();
  const { customers, loading } = useCustomer();

  const options = customers.map((customer: any) => {
    return {
      label: customer.CustomerName,
      value: customer.Id,
    };
  });

  
  if(loading) return null;
  return (
        <ReactSelectCompo
          name={'customerSelection'}
          className={'md:w-80 w-56 text-gray-200'}
          label={''}
          isSingleSelect={true}
          isCheckboxVisible={false}
          options={options}
          onChange={async (value) => {
            await selectCustomer(value[0].value);
          }}
          ClearSelectedIcon={null}
          value={[
            {
              label: customerInfo?.CustomerName,
              value: customerInfo?.CustomerId,
            },
          ]}
          labelledBy={'customer-selection'}
          overrideStrings={{
            noOptions: 'No results found'
          }}
        />
  );
};
