import { Option } from '../../ui/react-select';
import { ReactSelectCompo } from '../../ui/react-select/react-select';
import { useForm } from '../Form';
import { FormDefinition } from '../types/FormTypes';
import { Cross } from '../../ui/react-select/select-panel/cross';

export const FormSelect = ({ formDef }: { formDef: FormDefinition }) => {
  const { formState, handleDropDown } = useForm();

  let {
    label,
    required,
    component,
    name,
    selectHideSearch,
    selectFilterOptions,
    className,
    selectIsCreatable,
    selectMultiSelect,
    selectShowCheckboxes,
    selectShowSelectAll,
    selectValueRenderer,
    selectShowSelectAllFilter,
    getAsyncOptions,
    debounceDuration,
    options,
    optionKeyMapObject,
    isMenuTop,
    onChange,
    isDisplayElipsis,
    value,
    selectAllValue,
    ClearSelectedIcon = <Cross />,
    selectIsClearable
  } = formDef;

  const handleDropDownChange = (values) => {
    if (onChange) onChange(values);
    handleDropDown(values, name);
  };

  //This converts single string into an object 
  if(typeof value === 'string' && !!value) {
    value = [{ label: value, value }] as Array<any>;
  }

  return (
    <div className="mb-3 -mt-[2px]">
      {label && (
        <label htmlFor={label.toString()} className="text-sm text-gray-500 w-full">
          {label} {required && <span className="text-red-400">*</span>}
        </label>
      )}
      <ReactSelectCompo
        options={options as Option[]}
        ItemRenderer={component}
        onChange={(values) => handleDropDownChange(values)}
        labelledBy={name}
        value={value || formState[name]?.value || []}
        error={formState[name] && formState[name].error}
        isSingleSelect={!selectMultiSelect}
        isCheckboxVisible={selectShowCheckboxes}
        disabled={formDef.disabled}
        hasSelectAll={selectShowSelectAll}
        hasFilterSelectAll={selectShowSelectAllFilter}
        disableSearch={selectHideSearch}
        isCreatable={selectIsCreatable}
        filterOptions={selectFilterOptions}
        valueRenderer={selectValueRenderer}
        label={label}
        name={name}
        isDisplayElipsis={isDisplayElipsis}
        optionKeyMapObject={optionKeyMapObject}
        isMenuTop={isMenuTop}
        getAsyncOptions={getAsyncOptions}
        debounceDuration={debounceDuration}
        className={`${className}`}
        ClearSelectedIcon={ClearSelectedIcon}
        selectIsClearable={selectIsClearable}
        selectAllValue={selectAllValue}
      />
      {formState[name]?.error && (
        <span className="text-xs text-red-500">{formState[name].error}</span>
      )}
    </div>
  );
};
