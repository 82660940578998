import React, { ReactNode } from 'react';

interface SubHeaderProps {
  leftSideElement?: ReactNode | null;
  righttSideElement?: ReactNode | null;
}
export const SubHeader = ({
  leftSideElement,
  righttSideElement,
}: SubHeaderProps) => {
  return (
    <div className={'w-full bg-white gridSpace'}>
      <div className={'md:flex justify-between mb-4'}>
        <div className='md:mb-0 mb-4'>{leftSideElement}</div>
        <div className={'flex flex-wrap'}>{righttSideElement}</div>
      </div>
    </div>
  );
};
