import { Link } from "react-router-dom";
import { getTime } from "../../../../../../../utils/utils";
import { Chip } from "../../../../../../../components/ui/Chip";
import { useUser } from "../../../../../../../context/UserContext";
import { CustomerTokens } from "../../../../../../../types/Customer";

export const ServiceRequestTableFields = () => {
  const { hasPermission } = useUser();

  const filterParams = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true,
    debounceMs: 200,
  };

  return [
    {
      headerName: 'Request #',
      field: 'WorkOrderNumber',
      width: 130,
      sortable: false,
      filterParams: filterParams,
      cellRenderer: (cell: any) => {
        return cell && cell.data ? (
          <Link
            className={`service-request-view ${
              hasPermission(CustomerTokens.ViewServiceRequest)
                ? 'linkText'
                : ''
            }`}
            to={
              hasPermission(CustomerTokens.ViewServiceRequest)
                ? `/service/service-request-detail/${cell.data.WorkOrderID}`
                : ''
            }
          >
            {cell.data.WorkOrderNumber}
          </Link>
        ) : (
          ''
        );
      },
    },
    {
      headerName: 'Request Placed',
      field: 'Date',
      filter: null,
      width: 230,
      sortable: false,
      renderCSVColumn: (cell: any) => {
        return cell.Date ? getTime(cell.Date) : '';
      },

      cellRenderer: (cell: any) => {
        if (cell && cell.data) {
          const Date = getTime(cell.data.Date);
          return <div>{Date}</div>;
        } else {
          return '';
        }
      },
    },
    {
      headerName: 'Equipment Number',
      field: 'EquipmentNumber',
      filterParams: filterParams,
      width: 170,
      sortable: false,
      cellRenderer: (options: any) => {
        return (
          <Link
            className="service-request-view linkText"
            to={`/customer/device-detail/${options.data.EquipmentID}`}
          >
            {options.data.EquipmentNumber}
          </Link>
        );
      },
    },
    {
      headerName: 'Serial Number',
      field: 'SerialNumber',
      filterParams: filterParams,
      width: 160,
      sortable: false,
    },
    {
      headerName: 'Location Remarks',
      field: 'LocationRemarks',
      filter: null,
      sortable: false,
      width: 200,
    },
    {
      headerName: 'Problem Description',
      field: 'ProblemDescription',
      autoHeight: 1,
      filter: null,
      sortable: false,
      renderCSVColumn: (cell: any) => {
        let data = '';
        if (cell.ProblemDescription[1] !== undefined) {
          data = cell.ProblemDescription[1];
        } else {
          data = '';
        }
        return `${cell.ProblemDescription[0] || ''},${data}`;
      },
      valueGetter: (params: any) => {
        if (!params?.data?.ProblemDescription) {
          return null;
        }

        return params?.data?.ProblemDescription.join('\n');
      },
      cellRenderer: (cell: any) => {
        if (!cell.data.ProblemDescription) return null;
        return (
          <div
            className={`${
              cell.data.ProblemDescription.length > 0
                ? 'lineHeight22'
                : 'lineHeight48 overflow-auto'
            }`}
          >
            {cell.data.ProblemDescription.map((problem) => {
              return <div key={problem}>{problem}</div>;
            })}
          </div>
        );
      },
    },
    {
      headerName: 'Status',
      width: 160,
      filter: null,
      sortable: false,
      field: 'Status',
      cellRenderer: (cell: any) => {
        if (!cell.data.Status) {
          return '';
        }

        return <Chip status={cell.data.Status}/>;
      },
    },
    {
      headerName: 'Location',
      field: 'CustomerName',
      width: 160,
      filter: null,
      sortable: false,
    },
    {
      headerName: 'Request Type',
      width: 130,
      field: 'CallType',
      filter: null,
      sortable: false,
    },
    {
      headerName: 'Latest Update',
      width: 130,
      field: 'RepairRemarks',
      filter: null,
      sortable: false,
    },
  ];
};
