export const HomeIcon = () => {
  return (
    <svg
      width="34"
      height="19"
      viewBox="0 0 38 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2 15.4C19.8627 15.4 20.4 14.8627 20.4 14.2C20.4 13.5373 19.8627 13 19.2 13C18.5373 13 18 13.5373 18 14.2C18 14.8627 18.5373 15.4 19.2 15.4Z"
        stroke="#d5d5d5"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 19.7998C1 9.8398 9.04 1.7998 19 1.7998C28.96 1.7998 37 9.8398 37 19.7998"
        stroke="#d5d5d5"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 19.7998H35.8"
        stroke="#d5d5d5"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.5996 19.7998H36.9996"
        stroke="#d5d5d5"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 4.1998V1.7998"
        stroke="#d5d5d5"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6807 6.23994L27.8807 4.07959"
        stroke="#d5d5d5"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 12.3201L34.1601 11"
        stroke="#d5d5d5"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3191 6.23994L10.1191 4.19971"
        stroke="#d5d5d5"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.9999 12.4998L3.83984 11.1797"
        stroke="#d5d5d5"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 14L24 10"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
