import { createContext, useContext, useEffect, useState } from 'react';
import { CustomerList } from '../../../types/Customer';
import { useUser } from '../../../context/UserContext';
import { useFlexLayer } from '../../../hooks/useFlexlayer';
import { Device } from '../../../types/Devices';
import { Location } from '../../../types/Location';

interface CustomerContextFunctions {
    customers: CustomerList[];
    rehydrate: () => void;
    loading: boolean;
    selectedCustomer: CustomerList;
    devices: Device[];
    getDevices: () => void;
    getLocations: () => void;
    locations: Location[];
    locationsLoading: boolean;
}

const CustomerContext = createContext<CustomerContextFunctions | undefined>(
  undefined,
);

export const CustomerProvider = ({ children }: { children: React.ReactNode }) => {

    const { isAuthenticated, customerInfo } = useUser();
    const { flexLayer } = useFlexLayer();

    const [ customers, setCustomers ] = useState<CustomerList[]>([]);
    const [ loading, setLoading ] = useState(isAuthenticated);
    const [ devices, setDevices ] = useState<Device[]>([]);
    const [ locations, setLocations ] = useState<Location[]>([])
    const [ locationsLoading, setLocationsLoading ] = useState(false);

    const selectedCustomer = customers.find((customer) => customer.CustomerID === +customerInfo.CustomerId) || null as CustomerList;

    const getCustomers = async () => {
        setLoading(true);
        const res = await flexLayer('/customers/getUserCustomerListFromId', customerInfo.Customers);
        setCustomers(res.data);
        setLoading(false);
    }

    const getDevices = async () => {
        setLoading(true);
        const res = await flexLayer(`/equipments/customer/${customerInfo.CustomerId}`, { LocationID: customerInfo.RestrictUserByLocationID.toString() }, { method: 'PUT' });
        setDevices(res.data);
        setLoading(false);
    }

    const getLocations = async () => {
        setLoading(true);
        setLocationsLoading(true);
        const data = {
            CustomerID: customerInfo.CustomerId,
            EmailAddress: customerInfo.EmailAddress,
            UserId: customerInfo.UserId,
            HideOtherUserOrders: customerInfo.HideOtherUserOrders,
            LocationID: customerInfo.RestrictUserByLocationID.toString()
        }
        const res = await flexLayer(`/location`, data, { method: 'PUT' });
        setLocations(res.data);
        setLocationsLoading(false);
        setLoading(false);
    }

    useEffect(() => {
      setDevices([]);
      setLocations([]);
        if(isAuthenticated && selectedCustomer) {
            getDevices();
            getLocations();
        }
    }, [selectedCustomer]);

    useEffect(() => {
        if(isAuthenticated) getCustomers();
    }, [isAuthenticated]);

  return (
    <CustomerContext.Provider
      value={{
        rehydrate: getCustomers,
        getDevices,
        customers,
        loading,
        selectedCustomer,
        devices,
        getLocations,
        locations,
        locationsLoading
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export function useCustomer() {
  const context = useContext<CustomerContextFunctions | undefined>(CustomerContext);
  if (context === undefined) {
    throw new Error('useCustomer must be used within a CustomerProvider');
  }
  return context;
}
