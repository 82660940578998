import React from 'react';

import { Option } from '../lib/interfaces';
import { useSelectContext } from '../hooks/use-select-context';
// import { useMultiSelect } from '../hooks/use-react-select-components';

interface IDefaultItemRendererProps {
  checked: boolean;
  option: Option;
  disabled?: boolean;
  onClick: any;
}

const DefaultItemRenderer = ({
  checked,
  option,
  onClick,
  disabled,
}: IDefaultItemRendererProps) => {
  const { isCheckboxVisible = true } = useSelectContext();
  
  return (
    <div className={`item-renderer ${disabled ? 'disabled' : ''}`}>
      {isCheckboxVisible ? (
        <>
          <input
            type="checkbox"
            onChange={onClick}
            checked={checked}
            tabIndex={-1}
            disabled={disabled}
          />
          <span>{option.label}</span>
        </>
      ) : (
        <span
          onClick={onClick}
          className={`fullwidthOptionItem ${checked ? 'checked' : ''} ${
            disabled ? 'disabled' : ''
          }`}
        >
          {option.label}
        </span>
      )}
    </div>
  );
};
export default DefaultItemRenderer;
