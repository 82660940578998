import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useModal } from '../../../../../context/ModalConext';
import {
  Form,
  FormItems,
  FormType,
  SubmitBtn,
} from '../../../../../components/form';
import { Api } from '../../../../../shared/api/api';
import { useUser } from '../../../../../context/UserContext';

const ResolutionModal = ({ entity = 'NeverReported', formValues }, ref) => {
  const { customerInfo: customer } = useUser();
  const { openModal, closeModal } = useModal();
  const form = useRef(null);

  const retiredDeviceFields: any =
    entity === 'RetiredDevice'
      ? [
          {
            name: 'locationRemark',
            label: 'locationRemark',
            type: FormType.TEXT,
            required: true,
            maxLength: customer?.LongerLocationRemarks ? 80 : 24,
            errors: {
              required: 'Please provide location remark.',
            },
          },
        ]
      : [];

  const [defaultValues, setDefaultValues] = useState(null);

  useEffect(() => {
    setDefaultValues(formValues);
  }, [formValues]);

  const NeverReportedResolutionType = [
    'Update IP',
    'Device is Local',
    'Retire Device',
    'Request Help',
    'Serial Number Correction',
  ];
  const StoppedReportingResolutionType = [
    'Update IP',
    'Device is Local',
    'Retire Device',
    'Request Help',
    'Serial Number Correction',
  ];
  const RetiredDeviceResolutionType = ['Reactivate', 'Remove from FMAudit'];
  const handleSubmit = async (values: any) => {
    try {
      const resolutionDeviceObj = {
        DeviceData: defaultValues,
        ResolutionType: values?.ResolutionType[0],
        Comments: values?.Comments,
        Entity: entity,
        LocationRemark: values?.locationRemark ? values?.locationRemark : '',
        userInfo: {
          CustomerID: customer?.CustomerId,
          EmailAddress: customer?.EmailAddress,
          FirstName: customer?.FirstName,
          LastName: customer?.LastName,
          UserPhone: customer.UserPhone,
          BranchID: customer.BranchID,
          BranchNumber: customer.BranchNumber,
        },
      };

      await Api.callAPI({
        url: `/api/v1/reports/FMAudit/resolutionDevice`,
        method: 'POST',
        body: resolutionDeviceObj,
        options: {
          successMessage: false,
          errorMessage: 'Error while getting resolution FMAudit reports.',
        },
      });
      closeModal();
    } catch (e) {}
  };

  const options = [];

  if (entity === 'NeverReported') {
    options.push(
      ...NeverReportedResolutionType.map((value) => {
        return {
          label: value,
          value: value,
        };
      }),
    );
  } else if (entity === 'StoppedReporting') {
    options.push(
      ...StoppedReportingResolutionType.map((value) => {
        return {
          label: value,
          value: value,
        };
      }),
    );
  } else if (entity === 'RetiredDevice') {
    options.push(
      ...RetiredDeviceResolutionType.map((value) => {
        return {
          label: value,
          value: value,
        };
      }),
    );
  }

  const ResolutionForm = () => {
    return (
      <Form
        formRef={form}
        onSubmit={handleSubmit}
        defaultValues={{
          DeviceID: defaultValues?.EquipmentNumber,
          SerialNumber: defaultValues?.SerialNumber,
        }}
      >
        <div className="p-6">
          <FormItems
            items={[
              {
                name: 'DeviceID',
                label: 'Device ID',
                disabled: true,
                type: FormType.TEXT,
                className: 'mb-4',
              },
              {
                name: 'SerialNumber',
                label: 'Serial Number',
                disabled: true,
                type: FormType.TEXT,
                className: 'mb-4',
              },
              {
                name: 'ResolutionType',
                label: 'Resolution Type',
                type: FormType.SELECT,
                selectShowCheckboxes: false,
                selectHideSearch: true,
                isMenuTop: false,
                required: true,
                selectMultiSelect: false,
                ClearSelectedIcon: null,
                options: options,
                className: 'popupSelectBox',
                errors: {
                  required: 'Please select resolution type',
                },
              },
              ...retiredDeviceFields,
              {
                name: 'Comments',
                label: 'Comments',
                type: FormType.TEXTAREA,
                required: true,
                errors: {
                  required: 'Please provide comments.',
                },
                maxLength: 200,
              },
            ]}
          />
        </div>
        <div className="flex justify-end py-4 px-6 border-t border-gray-200">
          <button onClick={() => closeModal()} className={`borderedBtn mr-2 linkText hover:text-black`}>
            Cancel
          </button>
          <SubmitBtn className={`commonBtn`}>Send</SubmitBtn>
        </div>
      </Form>
    );
  };

  const openResolutionFormModal = () => {
    openModal({
      content: (
        <>
          <ResolutionForm />
        </>
      ),
      options: {
        title: 'Resolution',
        footer: <></>,
      },
    });
  };

  useImperativeHandle(ref, () => ({
    openResolutionForm() {
      openResolutionFormModal();
    },
  }));
  return <></>;
};

export default forwardRef(ResolutionModal);
