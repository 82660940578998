import React, { Ref, useRef, useState } from 'react';
import ApiTable from '../../../../shared/api-table/api-table';
import {
  ClientGridName,
} from '../../../../constant/constant';
import { LocationTableField } from './location-table.field';
import { ResetIcon } from '../../../../assets/svg/reset-icon';
import { ExcelIcon } from '../../../../assets/svg/excel-icon';
import { ReactSelectCompo } from '../../../../components/ui/react-select/react-select';
// import {
//   hideLoader,
//   showLoader,
// } from '../../../../store/loading/loading.action';
// import { useDispatch } from 'react-redux';
import { Button } from '../../../../components/ui';
import { LocationIcon } from '../../../../assets/svg/location-icon';
import { useUser } from '../../../../context/UserContext';
import { CustomerTokens } from '../../../../types/Customer';
import { useCustomer } from '../../context/CustomerContext';
// import { ReactSelectCompo } from '../../../../components/ui/react-select/react-select';

const Locations = () => {
  const { hasPermission, customerAddresses } = useUser();
  const { locations, locationsLoading } = useCustomer();
  const ref: Ref<any> = useRef(null);
  const [selectLocations, setSelectedLocations] = useState<any>(null);

  const isControlPressed = (event: any) => {
    if (!event.metaKey && event.ctrlKey) {
      event.metaKey = event.ctrlKey;
    }
    return event.metaKey;
  };

  const gridLocations = !!selectLocations ? selectLocations.map((data: any) => {
    return locations.find((location: any) => location.CustomerID === data.value);
  }) : locations;

  const filteredAddress = customerAddresses.map((data) => {
    return {
      value: data.CustomerID,
      label: data.SearchBy
    };
  });
  
  const columns = LocationTableField({
    isControlPressed: isControlPressed,
    selectedLocation: selectLocations,
  });

  const reset = () => {
    if (ref.current) {
      ref.current.resetGrid();
    }
  };
  const exportCSV = () => {
    if (ref.current) {
      ref.current.exportCSV();
    }
  }

  return (
    <>
      <div>
        <h1 className="mb-4 text-2xl font-medium">
          Your Locations
        </h1>
        <div className="flex w-full items-center pb-4 border-b border-gray-200">
          <div className="sm:w-96 w-full locationSelect">
            {filteredAddress.length ? (
              <ReactSelectCompo
                value={selectLocations}
                options={filteredAddress}
                optionKeyMapObject={{
                  labelKey: 'SearchBy',
                  valueKey: 'CustomerID',
                }}
                name={'select location '}
                label={'Search Location'}
                selectAllValue='All Locations'
                hasFilterSelectAll={true}
                labelledBy={'location'}
                onChange={(option: any) => {
                  if (option && option?.length) {
                    setSelectedLocations(option);
                  } else {
                    setSelectedLocations(null);
                  }
                }}
                overrideStrings={{selectSomeItems:"Search Location"}}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="sm:flex items-center justify-between my-4 w-full">
          <div>
            <span className="md:text-lg text-base font-semibold">
              {locations.length} { locations.length > 1 ? 'Locations' : 'Location' }
            </span>
          </div>
          <div className="flex items-center sm:mt-0 mt-2 locationGridButton">
            <button
              className={`flex items-center borderedBtn sm:ml-4 linkText hover:text-black`}
              onClick={reset}
            >
              <ResetIcon />
              <div className={`ml-2 text-sm`}>Reset</div>
            </button>

            <button
              className={`borderedBtn flex items-center ml-4 linkText hover:text-black`}
              onClick={exportCSV}
            >
              <ExcelIcon />
              <div className="ml-2 text-sm">Export CSV</div>
            </button>
            {hasPermission(CustomerTokens.RemoveDeviceLocations) ? (
              <Button
                ghost
                to={'/customer/locations/add-location'}
                className={`borderedBtn ml-4 linkText hover:text-black`}
              >
                <LocationIcon />
                <div className={`ml-2 text-sm`}>Add Location</div>
              </Button>
            ) : ('')}
          </div>
        </div>
      </div>
      {/* {rowData ? ( */}
          <ApiTable
            ref={ref}
            columns={columns}
            gridName={ClientGridName.Location}
            rowData={gridLocations}
            isLoading={locationsLoading}
            gridOptions={{
              pagination: false,
            }}
          />
      {/* ) : (
        ''
      )} */}
    </>
  );
};

export default Locations;
