import { useTheme } from "../../../context/ThemeContext";
import { Tenant } from "../../../types/Tenant";

export const AuthMigrationInfo = () => {
    const { tenant } = useTheme();
    return <div className={`w-screen h-screen bg-gray-800 flex items-center justify-center text-white applyHtml ${tenant !== Tenant.FLOTECH ? '[&_a]:text-flexprint' : '[&_a]:text-flotech'}`}>
    <div className="rounded p-8 bg-gray-700">
        <div className="logo mb-4">
            {tenant ? (
              <img
                src={require(`../../../assets/images/${tenant}-header-logo.png`)}
              />
            ) : (
              ''
            )}
        </div>
        <h2 className="text-base font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight mb-4">Password Reset Required (Check Your Email)</h2>
        <p className="text-base trailing-3 sm:truncate sm:text-sm sm:tracking-tight">Your authentication was just successfully migrated to our new authentication system (that was easy)!</p>
        <p className="text-base sm:truncate sm:text-sm sm:tracking-tight">As a final step, you just need to <strong>reset your password</strong>. Check your email for a password reset link.</p>
    </div>
</div>
}
