import LOADING_ACTIONS from './loading.action.types';
import { cloneDeep } from 'lodash';
import { generateRandomString } from '../../utils/utils'; // Import Lodash

const initialState = {
  showLoaderStack: [],
};

export default function loadingReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: any = cloneDeep(initialState),
  action: any,
) {
  switch (action.type) {
    case LOADING_ACTIONS.SHOW_LOADER: {
      const arr = cloneDeep(state.showLoaderStack);
      arr.push(generateRandomString(5));
      return {
        ...state,
        showLoaderStack: arr,
      };
    }
    case LOADING_ACTIONS.HIDE_LOADER: {
      const arr = cloneDeep(state.showLoaderStack);
      arr.pop();
      return {
        ...state,
        showLoaderStack: arr,
      };
    }

    default:
      return state;
  }
}
