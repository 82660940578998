import React, { Ref, useEffect, useRef, useState } from 'react';
import { Api } from '../../../../../shared/api/api';
import ApiTable from '../../../../../shared/api-table/api-table-admin';
import { AdminGridName } from '../../../../../constant/constant';
import { UsersListByCustomerIDField } from './users-list-field';
import { useNavigate, useParams } from 'react-router-dom';
import { SubHeader } from '../../../layout/footer/sub-header/sub-header';
import { ResetIcon } from '../../../../../assets/svg/reset-icon';
import { AddUserIcon } from '../../../../../assets/svg/add-user-icon';
import ChangePasswordDialog from './change-password-dialog/change-password-dialog';
import { Button } from "../../../../../components/ui";
import { ExcelIcon } from '../../../../../assets/svg/excel-icon';

export const CustomerUserList = () => {
  const [rowData, setRowData] = useState<any>([]);
  const [isApiCallProgress, setApiCallProgress] = useState(true);
  const [customerDetails, setCustomerDetails] = useState<any>(null);
  const [isExportBtnVisible, setExportBtnVisible] = useState(false);

  const ref: Ref<any> = useRef(null);
  const navigation = useNavigate();
  const params = useParams();
  const changePasswordModalRef = useRef(null);

  const getCustomerDetails = async () => {
    try {
      const response = await Api.callAPI({
        url: `/api/v1/customers/customerDetails/${params.customerId}?`,
        method: 'get',
        options: {
          isLoading: true,
          showErrorMessage: false,
        },
      });
      if (response?.data) {
        setCustomerDetails({ ...response?.data });
      }
    } catch (e) {}
  };

  const getUserList = async () => {
    try {
      setApiCallProgress(true);
      const response: any = await Api.callAPI({
        url: `/api/v1/customers/${params.customerId}/users`,
        method: 'get',
        options: {
          isLoading: true,
          showErrorMessage: false,
        },
      });

      if(response && response?.data.length) {
        setRowData([...response?.data]);
        setExportBtnVisible(true);
      } else {
        setRowData([])
        setExportBtnVisible(false);
      }
    } catch (e) {
    } finally {
      setApiCallProgress(false)
    }
  };

  const removeUser = async ({ userId }: any) => {
    try {
      await Api.callAPI({
        url: `/api/v1/users/${userId}`,
        queryParams: { CustomerId: params.customerId },
        method: 'delete',
        options: {
          isLoading: true,
          showErrorMessage: false,
        },
      });
    } catch (e) {}
  };

  useEffect(() => {
    (async () => {
      try {
        await getCustomerDetails();
        await getUserList();
      } catch (e) {}
    })();
  }, []);
  const openChangePasswordModal = (cellClickEvent) => {
    if (changePasswordModalRef.current) {
      changePasswordModalRef.current.openChangePasswordDialog(cellClickEvent);
    }
  };
  const onActionClick = async (cellClickEvent: any, type: any) => {
    const { data } = cellClickEvent;

    if (type === 'edit') {
      navigation(`/admin/users/${data?._id}/edit`);
    }

    if (type === 'trash') {
      const confirm = window.confirm(
        'Are you sure you want to delete this user ?',
      );
      if (confirm) {
        try {
          await removeUser({ userId: data?._id });
          await getUserList();
        } catch (e) {
        } finally {
        }
      }
    }
    if (type === 'changePassword') {
      openChangePasswordModal(cellClickEvent);
    }
  };
  const reset = () => {
    if (ref.current) {
      ref.current.resetGrid();
    }
  };

  const columns = UsersListByCustomerIDField({
    onActionClick: onActionClick,
  });

  const exportCSV = () => {
    if (ref.current) {
      ref.current.exportCSV();
    }
  };

  return (
    <>
      <ChangePasswordDialog ref={changePasswordModalRef} />
      <SubHeader
        leftSideElement={
          <>
            {customerDetails ? (
              <h2 className="font-bold">
                {customerDetails?.CustomerName}
                {'  '}
                <span className="font-normal">
                  {customerDetails?.Address}, {customerDetails?.City},
                  {customerDetails?.State}, {customerDetails?.Zip}.
                </span>
              </h2>
            ) : (
              ''
            )}
          </>
        }
        righttSideElement={
          <>
            <button
              className={`flex items-center borderedBtn mr-4 linkText  hover:text-black`}
              onClick={reset}
            >
              <ResetIcon />
              <div className={`ml-2 text-sm`}>Reset</div>
            </button>
            <Button
              className={`flex items-center borderedBtn mr-4 linkText  hover:text-black`}
              ghost
              to={`/admin/customers/${params.customerId}/users/add`}
            >
              <AddUserIcon />
              <div className={`ml-2 text-sm`}>Add User</div>
            </Button>
            {isExportBtnVisible ? (
              <button
                className={`btn borderedBtn flex items-center linkText hover:text-black`}
                onClick={exportCSV}
              >
                <ExcelIcon />
                <div className="ml-2 text-sm">Export CSV</div>
              </button>
            ) : (
              ''
            )}
          </>
        }
      />
      <ApiTable
        ref={ref}
        columns={columns}
        gridName={AdminGridName.CustomerUsers}
        isLoading={isApiCallProgress}
        rowData={rowData && rowData.length ? rowData : []}
        setExportBtnVisible={setExportBtnVisible}
      />
    </>
  );
};
