import { Button } from "../../../components/ui"
import { useModal } from "../../../context/ModalConext"

export interface LogApiResponse {
    _id: string
    Api: string
    Method: string
    Type: string
    ResponseTime: string
    InputParameters: unknown
    Output: any
    User: string
    ApplicationUser: string
    Application: string
    CreatedDate: number
}

enum LogTableColumns {
    Api = 'Api',
    Method = 'Method',
    Type = 'Type',
    ResponseTime = 'ResponseTime',
    InputParameters = 'InputParameters',
    Output = 'Output',
    User = 'User',
    ApplicationUser = 'ApplicationUser',
    Application = 'Application',
    CreatedDate = 'CreatedDate',
}

interface LogApiTableColumns {
    headerName: string
    field: LogTableColumns
    cellRenderer?: (params: any) => any
          filter?: boolean | null;
}

export const LogTableField = (): LogApiTableColumns[] => {

    const { openModal } = useModal();

    const ModalContent = ({ text }) => {
        return <div className="md:max-h-[70vh] p-5 md:max-w-[65vw] overflow-auto">
            <div className="w-full overflow-auto h-full">
                <pre className="text-xs">{text}</pre>
            </div>
        </div>;
    }

    return [
        {
            headerName: 'Access Log Time',
            field: LogTableColumns.CreatedDate,
            filter: null,
            cellRenderer: (params: any) => {
                return new Date(params.value).toLocaleString();
            }
        },
        {
            headerName: 'User ID',
            filter: null,
            field: LogTableColumns.User
        },
        {
            headerName: 'Application',
            filter: null,
            field: LogTableColumns.Application
        },
        {
            headerName: 'API',
            filter: null,
            field: LogTableColumns.Api
        },
        {
            headerName: 'Method',
            filter: null,
            field: LogTableColumns.Method
        },
        {
            headerName: 'Type',
            filter: null,
            field: LogTableColumns.Type
        },
        {
            headerName: 'Response Time',
            filter: null,
            field: LogTableColumns.ResponseTime
        },
        {
            headerName: 'Input Parameters',
            filter: null,
            field: LogTableColumns.InputParameters,
            cellRenderer: (params: any) => {
                return <Button
                    className="px-2 h-fit rounded" 
                    onClick={() => 
                        openModal({content: <ModalContent text={JSON.stringify(params.value, null, '\t')}/>, options: { title: 'Input Paramaters'}})}>
                View</Button>
            }
        },
        {
            headerName: 'Output',
            filter: null,
            field: LogTableColumns.Output,
            cellRenderer: (params: any) => {
                return <Button 
                    className="px-2 h-fit rounded" 
                    onClick={() => openModal({content: <ModalContent text={JSON.stringify(params.value, null, '\t')}/>, options: { title: 'Output Paramters'}})}>
                View</Button>
            }
        },
        {
            headerName: 'Application User',
            filter: null,
            field: LogTableColumns.ApplicationUser
        },
    ];

}