export const DeliveryTruckIcon = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0001 11.666H10.8335C11.7501 11.666 12.5001 10.916 12.5001 9.99935V1.66602H5.00012C3.75012 1.66602 2.65846 2.35767 2.0918 3.37434"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.6665 14.166C1.6665 15.5493 2.78317 16.666 4.1665 16.666H4.99984C4.99984 15.7493 5.74984 14.9993 6.6665 14.9993C7.58317 14.9993 8.33317 15.7493 8.33317 16.666H11.6665C11.6665 15.7493 12.4165 14.9993 13.3332 14.9993C14.2498 14.9993 14.9998 15.7493 14.9998 16.666H15.8332C17.2165 16.666 18.3332 15.5493 18.3332 14.166V11.666H15.8332C15.3748 11.666 14.9998 11.291 14.9998 10.8327V8.33268C14.9998 7.87435 15.3748 7.49935 15.8332 7.49935H16.9082L15.4832 5.00769C15.1832 4.49102 14.6332 4.16602 14.0332 4.16602H12.4998V9.99935C12.4998 10.916 11.7498 11.666 10.8332 11.666H9.99984"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66667 18.3333C7.58714 18.3333 8.33333 17.5871 8.33333 16.6667C8.33333 15.7462 7.58714 15 6.66667 15C5.74619 15 5 15.7462 5 16.6667C5 17.5871 5.74619 18.3333 6.66667 18.3333Z"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3332 18.3333C14.2536 18.3333 14.9998 17.5871 14.9998 16.6667C14.9998 15.7462 14.2536 15 13.3332 15C12.4127 15 11.6665 15.7462 11.6665 16.6667C11.6665 17.5871 12.4127 18.3333 13.3332 18.3333Z"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3333 10V11.6667H15.8333C15.375 11.6667 15 11.2917 15 10.8333V8.33333C15 7.875 15.375 7.5 15.8333 7.5H16.9083L18.3333 10Z"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.6665 6.66602H6.6665"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.6665 9.16602H4.99984"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.6665 11.666H3.33317"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };