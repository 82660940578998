import React, { useEffect, useRef, useState} from 'react';
import { ReactSelectCompo } from '../../../../components/ui/react-select/react-select';
import { Api } from '../../../../shared/api/api';
import _ from 'lodash';
import DisplayProduct from '../display-product';
import { Link } from 'react-router-dom';
import FeedbackForm from "../../feedback-form/feedback-form";
import { useUser } from '../../../../context/UserContext';
import { CartItem } from '../../../../types/Product';

const ProductViewAllItems = () => {
  const getHelpNowFormModalRef = useRef(null);
  const filterOptions = [
    {
      label: 'No Grouping',
      value: 'No Grouping',
    },
    {
      label: 'Item Category',
      value: 'Item Category',
    },
  ];
  const { customerInfo } = useUser();
  const tenant = (
    localStorage.getItem('TenantName') || 'flexprint'
  ).toLowerCase();
  const [searchResult, setSearchResult] = useState(false);
  const [noResultFound, setNoResultFound] = useState(false);
  const [productCategoriesData, setProductCategoriesData] = useState([]);
  const [productData, setProductData] = useState<CartItem[]>([]);
  const productSearchData = {
    CustomerId: customerInfo.CustomerId,
    EquipmentName: null,
    ModelID: null,
    ItemName: null,
    WarehouseId: customerInfo.WarehouseID,
    MfgID: null,
  };
  const [filterSelect, setFilterSelect] = useState([
    {
      label: 'No Grouping',
      value: 'No Grouping',
    },
  ]);
  const openGetHelpForm = () => {
    if (getHelpNowFormModalRef.current) {
      getHelpNowFormModalRef.current.openFeedBackForm();
    }
  };

  const getAllItems = async () => {
    const response = await Api.callAPI({
      url: `/api/v1/products/getAllItems`,
      method: 'post',
      body: productSearchData,
    });
    if (response && response.data && response.data.length) {
      const productList = response.data;
      setSearchResult(true);
      setNoResultFound(false);
      if (tenant === 'marimon') {
        _.each(productList, (product) => {
          if (product.ItemDescription.toString().toLowerCase().match('cyan')) {
            product.tonerClass = 'cyan';
          } else if (
            product.ItemDescription.toString().toLowerCase().match('black')
          ) {
            product.tonerClass = 'black';
          } else if (
            product.ItemDescription.toString().toLowerCase().match('magenta')
          ) {
            product.tonerClass = 'magenta';
          } else if (
            product.ItemDescription.toString().toLowerCase().match('yellow')
          ) {
            product.tonerClass = 'yellow';
          }
        });
      } else {
        _.each(productList, (product) => {
          if (
            product.CatDescription.toString().toLowerCase().match('toner cyan')
          ) {
            product.tonerClass = 'cyan';
          } else if (
            product.CatDescription.toString().toLowerCase().match('toner black')
          ) {
            product.tonerClass = 'black';
          } else if (
            product.CatDescription.toString()
              .toLowerCase()
              .match('toner magenta')
          ) {
            product.tonerClass = 'magenta';
          } else if (
            product.CatDescription.toString()
              .toLowerCase()
              .match('toner yellow')
          ) {
            product.tonerClass = 'yellow';
          }
        });
        _.each(productList, (product) => {
          if (
            product.CatDescription.toString().toLowerCase().match('ink cyan')
          ) {
            product.inkClass = 'cyan';
          } else if (
            product.CatDescription.toString().toLowerCase().match('ink black')
          ) {
            product.inkClass = 'black';
          } else if (
            product.CatDescription.toString().toLowerCase().match('ink magenta')
          ) {
            product.inkClass = 'magenta';
          } else if (
            product.CatDescription.toString().toLowerCase().match('ink yellow')
          ) {
            product.inkClass = 'yellow';
          }
        });
      }

      _.each(productList, (product) => {
        product.availableQty = [];
        for (let i = 1; i <= 10; i++) {
          product.availableQty.push(i);
        }
        product.Quantity = product.Quantity || 1;
      });
      const productCategoriesList = _.cloneDeep(
        _.uniq(_.map(productList, 'CatDescription')),
      );
      const productCategoriesData1 = productCategoriesData;
      for (const category of productCategoriesList) {
        const categoryProducts = _.filter(_.cloneDeep(productList), {
          CatDescription: category,
        });
        productCategoriesData1.push({
          Category: category,
          Products: categoryProducts,
        });
      }
      setProductCategoriesData(productCategoriesData1);
      const productData1 =
        _.cloneDeep(productList).sort((a, b) => a.Item.localeCompare(b.Item)) ||
        [];
      setProductData([...productData1]);
    } else {
      setProductCategoriesData([]);
      setProductData([]);
      setSearchResult(false);
      setNoResultFound(true);
    }
  };
  useEffect(() => {
    getAllItems().then();
  }, []);

  return (
    <>
      <FeedbackForm
        ref={getHelpNowFormModalRef}
        from={'customerCareFeedback'}
        title={'Customer Care'}
      />
      {searchResult ? (
        <h1 className="mb-4 text-2xl font-medium">View All Items </h1>
      ) : (
        ''
      )}
      <div className="md:flex w-full gap-6 h-full">
        <div className="md:w-1/3 h-full">
          <div className="bg-gray-100 rounded-lg p-6">
            <label className="text-sm text-gray-600 mb-2">Sort By</label>
            <ReactSelectCompo
              name={'ViewAllItems'}
              label={''}
              labelledBy={'ViewAllItems'}
              options={filterOptions}
              value={filterSelect}
              optionKeyMapObject={{
                labelKey: 'label',
                valueKey: 'value',
              }}
              disableSearch={true}
              hasFilterSelectAll={false}
              hasSelectAll={false}
              isSingleSelect={true}
              isCheckboxVisible={false}
              ClearSelectedIcon={null}
              onChange={(event: any) => {
                setFilterSelect(event);
              }}
              closeOnChangedValue={true}
            ></ReactSelectCompo>
          </div>
        </div>
        <div className="md:w-2/3 md:mt-0 mt-6">
          <div>
            {searchResult &&
            filterSelect[0].value === filterOptions[1].value ? (
              <div className={'search-result'}>
                {productCategoriesData.map(
                  (categoryProducts: any, index: number) => (
                    <div className={'search-result-list'} key={'categoryProducts' + index}>
                      <h3 className='text-lg font-semibold mb-4'>{categoryProducts.Category}</h3>
                      <DisplayProduct
                        products={categoryProducts.Products}
                      ></DisplayProduct>
                    </div>
                  ),
                )}
              </div>
            ) : (
              ''
            )}
            {searchResult &&
            filterSelect[0].value === filterOptions[0].value ? (
              <div>

                  <DisplayProduct
                    products={productData}
                  ></DisplayProduct>

              </div>
            ) : (
              ''
            )}
          </div>
          <div>
            {noResultFound ? (
              <div>
                <div className="text-center">
                  <h2 className='text-3xl mb-6'>Can't find what you were looking for?</h2>
                  <p className='mb-4'>
                    We didn't find any supplies that matched your search, but
                    we're here to help
                  </p>
                  <Link to={'/product/search'} className="borderedBtn linkText mr-4 inline-block">
                    Back To Search
                  </Link>
                  <button
                    className="borderedBtn linkText"
                    onClick={() => {
                      openGetHelpForm();
                    }}
                  >
                    Get Help Now
                  </button>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductViewAllItems;
