import React, { Ref, useEffect, useRef } from 'react';
import ApiTable from '../../../../../shared/api-table/api-table';
import { ClientGridName } from '../../../../../constant/constant';

import { LocationsTableField } from './locations-table-field';
import { WidgetHeader } from '../../../grid-widget-header/widget-header';
import { useCustomer } from '../../../context/CustomerContext';

export const Locations = ({
  viewAllRouterLink,
  plusRouterLink,
  title = null,
  expandContractGrid,
  isExpand
}: any) => {
  const { locations, locationsLoading } = useCustomer();
  const ref: Ref<any> = useRef(null);

  const headerRef: Ref<any> = useRef(null);

  const gridRef: Ref<any> = useRef(null);

  const resetGrid = () => {
    if (ref.current) {
      ref.current.resetGrid();
    }
  }

  useEffect(() => {
    let dheight = 0;
    if (headerRef.current) {
      dheight += parseInt(headerRef.current.clientHeight);
    }

    if (gridRef.current) {
      gridRef.current.style.height = `calc(100% -  ${dheight}px)`;
    }
  }, [headerRef?.current]);

  const columns = LocationsTableField();
  return (
    <>
      <div className={'w-full h-full pb-4'}>
        <div ref={headerRef}>
          <WidgetHeader
            viewAllRouterLink={viewAllRouterLink}
            plusRouterLink={plusRouterLink}
            title={title}
            resetGrid={resetGrid}
            isShowExportCsvBtn={false}
            expandContractGrid={expandContractGrid}
            isExpand={isExpand}
          />
        </div>
        <div ref={gridRef}>
          <ApiTable
            ref={ref}
            isLoading={locationsLoading}
            columns={columns}
            gridName={ClientGridName.DashboardLocation}
            rowData={locations}
            gridOptions={{
              pagination: false,
            }}
          />
        </div>
      </div>
    </>
  );
};
