import NOTIFICATION_ACTIONS from './notification.action.types';

interface ShowNotificationProps {
  message?: string;
  type?: string | null;
}
export const showNotification = ({ message, type }: ShowNotificationProps) => ({
  type: NOTIFICATION_ACTIONS.SHOW_NOTIFICATION,
  payload: { message, type },
});

export const popNotification = () => ({
  type: NOTIFICATION_ACTIONS.POP_NOTIFICATION,
  payload: {},
});

export const removeAllNotification = () => ({
  type: NOTIFICATION_ACTIONS.REMOVE_ALL_NOTIFICATION,
  payload: {},
});
