import React, { Ref, useRef } from 'react';
import { Api } from '../../../../../shared/api/api';
import ApiTable from '../../../../../shared/api-table/api-table-admin';
import { AdminGridName } from '../../../../../constant/constant';
import { UsersListField } from './users-list-field';
import { useNavigate } from 'react-router-dom';

import { SubHeader } from '../../../layout/footer/sub-header/sub-header';
import { ResetIcon } from '../../../../../assets/svg/reset-icon';
import { AddUserIcon } from '../../../../../assets/svg/add-user-icon';
import { Button } from '../../../../../components/ui';

export const UserList = () => {
  const ref: Ref<any> = useRef(null);
  const navigation = useNavigate();

  const getUserList = async (queryParam: any) => {
    const res = await Api.callAPI({
      url: '/api/v1/users/list',
      method: 'get',
      queryParams: queryParam,

      options: {
        isLoading: false,
        showErrorMessage: false,
      },
    });
    return res?.data
  };

  const removeUser = async ({ userId }: any) => {
    try {
      await Api.callAPI({
        url: `/api/v1/users/${userId}`,
        method: 'delete',
        options: {
          isLoading: true,
          showErrorMessage: false,
        },
      });
    } catch (e) {}
  };

  const onActionClick = async (cellClickEvent: any, type: any) => {
    const { data } = cellClickEvent;

    if (type === 'edit') {
      navigation(`/admin/users/${data?._id}/edit`);
    }

    if (type === 'trash') {
      const confirm = window.confirm(
        'Are you sure you want to delete this user ?',
      );
      if (confirm) {
        try {
          await removeUser({ userId: data?._id });
          if (ref.current) {
            ref.current.refreshGrid();
          }
        } catch (e) {
        } finally {
        }
      }
    }
  };
  const reset = () => {
    if (ref.current) {
      ref.current.resetGrid();
    }
  };

  const columns = UsersListField({ onActionClick: onActionClick });
  return (
        <>
          <SubHeader
            leftSideElement={
              <>
                <h2 className="font-semibold text-gray-600">Users</h2>
              </>
            }
            righttSideElement={
              <>
                <button
                  className={`flex items-center borderedBtn mr-4 linkText  hover:text-black`}
                  onClick={reset}
                >
                  <ResetIcon />
                  <div className={`ml-2 text-sm`}>Reset</div>
                </button>
                <Button
                  className={`flex items-center borderedBtn mr-4 linkText  hover:text-black`}
                  ghost
                  to={'/admin/users/add'}
                >
                  <AddUserIcon />
                  <div className={`ml-2 text-sm`}>Add User</div>
                </Button>
              </>
            }
          />

        <ApiTable
          ref={ref}
          columns={columns}
          gridName={AdminGridName.Users}
          gridDataService={getUserList}
        />
      </>
  );
};
