import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { RootState } from '../../../store/store';
import { tenantLogo } from "../../../utils/utils";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Form,
  FormItems, FormRef,
  FormType,
} from "../../../components/form";

import LoginFeedBackForm from './login-feedback-form/login-feedback-form';
import { useUser } from '../../../context/UserContext';
import { useFlexLayer } from '../../../hooks/useFlexlayer';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '../../../components/ui';

export const Login = () => {
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const { flexLayer } = useFlexLayer();
  const [ showErrorMsg, setShowErrorMsg ] = useState(null);
  const [ hasEmail, setHasEmail ] = useState(false);
  const ref: FormRef = useRef(null);
  const feedbackformModalRef = useRef(null);
  const { loginWithRedirect } = useAuth0();
  const [ params ] = useSearchParams();

  const { login, customerInfo, loading, checkLogin } = useUser();
  const navigate = useNavigate();


  const checkEmail = async (value) => {
    setSubmitDisabled(true);
    let { email } = value;

    const { error, data } = await flexLayer(`/auth0/check-email/${email}`, null, { 
      isLambda: false,
      method: "POST"
    });
    if(error) setShowErrorMsg('Uh oh, we hit a snag! Please try again later.');
    // if user is auth0 user, redirect to auth0 login
    if(data?.isAuth0User) return loginWithRedirect({
      authorizationParams: {
        login_hint: email
      }
    });
    // if user is not auth0 user, show password input
    setSubmitDisabled(false);
    if(!error) setHasEmail(true);

  }


  useEffect(() => {
    let sessionId = params.get('sessionId');
    //Old genesco logic
    if(sessionId) {
      checkLogin();
    }

    if(loading) return;
    if (!customerInfo.EmailAddress) return;
    if(customerInfo.Customers.length > 1 || customerInfo.Customers.includes(-1)) {
      navigate('/select-company')
    } else {
      navigate('/dashboard')
    }
  }, [customerInfo]);

  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );

  const handleSubmit = async (value) => {
    if(!hasEmail) return checkEmail(value);
    setSubmitDisabled(true);
    const res = await login(value as { email: string, password: string });
    if(res.error) {
      setShowErrorMsg(res.error);
      setSubmitDisabled(false);
      return;
    }
    setSubmitDisabled(false);
  };

  const openFeedbackFormModal = () => {
    if (feedbackformModalRef.current) {
      feedbackformModalRef.current.openLoginFeedBackForm();
    }
  }

  if(loading || !!params.get('code')) return null;
  return (
    <div className="h-full flex flex-row justify-center items-center w-screen">
      <LoginFeedBackForm ref={feedbackformModalRef} />
      <div className=" fch100 flex flex-col justify-center items-center h-full min-h-[75vh]">
        <div className="sm:w-400 w-full m-auto bg-white shadow-lg p-8 pt-12 my-8 ">
          <Form
            onChange={() => setShowErrorMsg(null)}
            onSubmit={handleSubmit}
            formRef={ref}
          >
            <div>
              <img
                className="m-auto mb-8"
                alt={'Order Ahead Logo'}
                src={tenantLogo(tenantName)}
              />
              <FormItems
                items={[
                  {
                    name: 'email',
                    label: 'Email',
                    type: FormType.EMAIL,
                    placeholder: 'Email',
                    required: true,
                    className: `${hasEmail ? 'hidden' : ''} mb-4`,
                    errors: {
                      required: 'Please enter email.',
                      pattern: 'Please enter valid email.',
                    },
                  },
                  {
                    name: 'password',
                    label: 'Password',
                    type: FormType.PASSWORD,
                    placeholder: 'Password',
                    required: hasEmail,
                    className: `${!hasEmail ? 'hidden' : ''} mb-4`,
                    errors: {
                      required: 'Please enter password.',
                    },
                  },
                ]}
              />
              { hasEmail && <div className="flex justify-between">
                  <div role="button" className="linkText text-sm cursor-pointer" onClick={() => setHasEmail(false)}>
                     Back to Email
                  </div>
                  <Link
                    to="/forgot-password"
                    className="linkText text-sm font-bold"
                  >
                    Forgot password?
                  </Link>
              </div> }
              <div className="">
                {showErrorMsg ? (
                  <div className="form-msg ng-hide mt-2">
                    <div className="rounded border border-red-400 bg-red-200 text-red-900 text-xs py-0.5 text-center">
                      {showErrorMsg}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <Button
              onClick={(e) => ref.submit(e)}
              className="rounded p-2 w-full my-6"
              disabled={isSubmitDisabled}
              loading={isSubmitDisabled}
            >
              Login
            </Button>
          </Form>
          <div className="text-center text-gray-600 text-sm">
            Need to order supplies or request service without a login?{' '}
            <a href="https://www.flextg.com/tools/">
              <span className="linkText">Click here</span>
            </a>
          </div>
        </div>
        <button
          className="cursor-pointer text-sm text-gray-600"
          onClick={() => {
            openFeedbackFormModal();
          }}
        >
          Send Feedback
        </button>
      </div>
    </div>
  );
};
