import React, { Ref, useEffect, useRef, useState } from 'react';
import { Api } from '../../../shared/api/api';
import ApiTable from '../../../shared/api-table/api-table-admin';
import { AdminGridName } from '../../../constant/constant';
import { CustomerTableField } from './customer-table-field';
import { useNavigate } from 'react-router-dom';
import { SubHeader } from '../layout/footer/sub-header/sub-header';
import { ResetIcon } from '../../../assets/svg/reset-icon';
import { ExcelIcon } from '../../../assets/svg/excel-icon';
import { AddIcon } from '../../../assets/svg/add-icon';

export const Customer = () => {
  const [rowData, setRowData] = useState<any>(null);
  const navigation = useNavigate();
  const ref: Ref<any> = useRef(null);
  const [isExportBtnVisible, setExportBtnVisible] = useState(false);
  // const { tokens }: any =
  //   useContext<ClientApplicationContextType>(APPLICATION_CONTEXT);

  const getCompanies = async (queryParam: any) => {
    try {
      const response: any = await Api.callAPI({
        url: '/api/v1/customers',
        method: 'get',
        queryParams: queryParam,

        options: {
          isLoading: true,
          showErrorMessage: false,
        },
      });
      setRowData([...response.data]);
      setExportBtnVisible(true);
    } catch (e) {}
  };

  const removeCustomer = async ({ id }: any) => {
    try {
      await Api.callAPI({
        url: `/api/v1/customers/${id}`,
        method: 'delete',
        options: {
          isLoading: true,
          showErrorMessage: false,
        },
      });
    } catch (e) {}
  };
  useEffect(() => {
    // if (doHavePermisions({ tokens: tokens, permissions: accessToken })) {
    (async () => {
      try {
        await getCompanies({});
      } catch (e) {}
    })();
    // }
  }, []);

  const onActionClick = async (cellClickEvent: any, type: any) => {
    const { data } = cellClickEvent;
    if (type === 'manageUser') {
      navigation(`/admin/customerUser/${data?.Id}`, {
        state: data,
      });
    }
    if (type === 'edit') {
      navigation(`/admin/link-customer/edit/${data?.Id}`, {
        state: data,
      });
    }

    if (type === 'trash') {
      const confirm = window.confirm(
        'Are you sure you want to delete this customer?',
      );
      if (confirm) {
        try {
          await removeCustomer({ id: data?._id });
          await getCompanies({});
        } catch (e) {
        } finally {
        }
      }
    }
  };

  const onLinkHandler = () => {
    navigation('/admin/customer/search');
  };

  const reset = () => {
    if (ref.current) {
      ref.current.resetGrid();
    }
  };

  const exportCSV = () => {
    if (ref.current) {
      ref.current.exportCSV();
    }
  };

  const columns = CustomerTableField({ onActionClick: onActionClick });

  return (
    <>
      <SubHeader
        leftSideElement={
          <>
            <h2 className="font-semibold text-gray-600">Customers</h2>
          </>
        }
        righttSideElement={
          <>
            <button
              className={`flex items-center borderedBtn mr-4 linkText hover:text-black order-1`}
              onClick={reset}
            >
              <ResetIcon />
              <div className={`ml-2 text-sm`}>Reset</div>
            </button>
            <button
              className={`flex items-center text-black borderedBtn mr-4 linkText hover:text-black sm:mt-0 mt-4 sm:order-1 order-2`}
              onClick={onLinkHandler}
            >
              <AddIcon />
              <div className={`ml-2 text-sm`}>Add Customer To Portal</div>
            </button>
            {isExportBtnVisible ? (
              <button
                className={`btn borderedBtn flex items-center linkText hover:text-black order-1 `}
                onClick={exportCSV}
              >
                <ExcelIcon />
                <div className="ml-2 text-sm">Export CSV</div>
              </button>
            ) : (
              ''
            )}
          </>
        }
      />
      {rowData ? (
        <ApiTable
          ref={ref}
          columns={columns}
          gridName={AdminGridName.Customers}
          rowData={rowData}
          setExportBtnVisible={setExportBtnVisible}
        />
      ) : (
        ''
      )}
    </>
  );
};
