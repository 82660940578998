import { useModal } from '../../../../../../context/ModalConext';
import {
  Form,
  FormItems,
  FormType,
  SubmitBtn,
} from '../../../../../../components/form';
import { Api } from '../../../../../../shared/api/api';
import { useUser } from '../../../../../../context/UserContext';

export const EditDeviceModal = ({
  deviceDetails,
  equipmentId,
  customerAddress,
}: any) => {
  const { openModal, closeModal } = useModal();
  const { customerInfo } = useUser();
  const onSubmitEditDeviceModal = async (values) => {
    const location = values.Location[0];
    const reqBody = {
      Comments: values?.Comments,
      EquipmentData: {
        ...deviceDetails,
        Location: values.LocationRemarks,
      },
      LocationAddress: location,
      UserInfo: {
        CustomerId: customerInfo.CustomerId,
        EmailAddress: customerInfo.EmailAddress,
        CustomerName: customerInfo.CustomerName,
        FirstName: customerInfo.FirstName,
        LastName: customerInfo.LastName,
        UserPhone: customerInfo.UserPhone,
        BranchID: customerInfo.BranchID,
        BranchNumber: customerInfo.BranchNumber,
      },
    };

    await Api.callAPI({
      url: `/api/v1/equipments/${equipmentId}`,
      method: 'put',
      body: reqBody,
      options: {
        successMessage:
          'Your change request has been submitted to our team for review.',
        errorMessage: 'Error while reporting device.',
      },
    });
    closeModal();
  };

  return (
    <button
      className="primaryBtn bgColor"
      onClick={() =>
        openModal({
          content: (
            <div>
              <div>
                <Form onSubmit={onSubmitEditDeviceModal}>
                  <FormItems
                    className="p-6"
                    items={[
                      {
                        name: 'EquipmentNumber',
                        label: 'Equipment Number',
                        type: FormType.TEXT,
                        disabled: true,
                        defaultValue: deviceDetails?.EquipmentNumber,
                        className: 'mb-4 text-sm',
                      },
                      {
                        name: 'SerialNumber',
                        label: 'Serial Number',
                        type: FormType.TEXT,
                        disabled: true,
                        defaultValue: deviceDetails?.SerialNumber,
                        className: 'mb-4 text-sm',
                      },
                      {
                        name: 'Location',
                        label: 'Location',
                        type: FormType.SELECT,
                        selectMultiSelect: false,
                        selectShowCheckboxes: false,
                        options: customerAddress.map((item) => ({
                          label: item.SearchBy,
                          value: item,
                        })),
                        className: 'text-sm elipsisTextFixedWidth',
                        required: true,
                        errors: {
                          required: 'Please provide device location.',
                        },
                      },
                      {
                        name: 'LocationRemarks',
                        label: 'Location Remarks',
                        defaultValue: deviceDetails.Location,
                        type: FormType.TEXT,
                        className: 'mb-4 text-sm',
                        maxLength: customerInfo?.LongerLocationRemarks ? 80 : 24,
                      },
                      {
                        name: 'Comments',
                        label: 'Comments',
                        rows: 5,
                        type: FormType.TEXTAREA,
                        placeholder: 'Write your message here..',
                        required: true,
                        className: 'mb-4 text-sm',
                        errors: {
                          required: 'Please enter Comments.',
                        },
                        maxLength: 200,
                      },
                    ]}
                  />
                  <div className="flex justify-end py-4 px-6 border-t border-gray-200">
                    <button
                      onClick={() => closeModal()}
                      className={`borderedBtn mr-2 linkText hover:text-black`}
                    >
                      Cancel
                    </button>
                    <SubmitBtn className={`commonBtn`}>Submit</SubmitBtn>
                  </div>
                </Form>
              </div>
            </div>
          ),
          options: {
            title: 'Edit Device Details',
            className: 'visibleOptionModal',
          },
        })
      }
    >
      Edit
    </button>
  );
};
