import React, { ReactNode, useContext } from 'react';
import Header from './header/header';
import Footer from './footer/footer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { APPLICATION_CONTEXT } from '../../../utils/utils';
// import { APPLICATION_CONTEXT } from '../../../utils/utils';
interface LayoutProps {
  children: ReactNode;
}
export const Layout = ({ children }: LayoutProps) => {
  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );
  const { userInfo }: any = useContext(APPLICATION_CONTEXT);

  return (
    <>
      <div className="mainContent fch100">
        {userInfo ? (
          <>
            <Header />
            <div className={`${tenantName} mainLayout md:p-8 p-4 fch100 overflow-y-auto`}>{children}</div>
            <Footer />
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
