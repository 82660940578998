import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

import { useNavigate } from 'react-router-dom';
import { Form, FormItems, FormType, SubmitBtn } from '../../../components/form';
import { showNotification } from '../../../store/notification/notification.action';
import { ArrowLeftIcon } from '../../../assets/svg/arrow-left-icon';
import { tenantLogo } from '../../../utils/utils';
import { useFlexLayer } from '../../../hooks/useFlexlayer';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { flexLayer } = useFlexLayer();
  const [showErrorMsg, setShowErrorMsg] = useState(null);
  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );

  const forgotPassword = async (email) => {
    setShowErrorMsg(null);
    try {
      await flexLayer('/users/forgotPassword', { email});
      dispatch(
        showNotification({
          message:
            'Email has been sent successfully to registered email address',
          type: 'success',
        }),
      );
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (err) {
      setShowErrorMsg(err?.Message || err?.error?.Message);
    }
  };
  const handleSubmit = async (value) => {
    if (value?.email) {
      try {
        await forgotPassword(value?.email);
      } catch (e) {}
    }
  };

  return (
    <>
      <div className="bg-gray-100 fch100 flex justify-center items-center py-8 px-4  grayBox">
        <div className="sm:w-400 w-full m-auto bg-white shadow p-8 pt-12 my-8 ">
          <Form onSubmit={handleSubmit}>
            <div>
              <img
                className="m-auto"
                alt={'Order Ahead Logo'}
                src={tenantLogo(tenantName)}
              />
              <h1 className="text-center my-8 font-bold text-gray-700">
                FORGOT PASSWORD
              </h1>
              <FormItems
                items={[
                  {
                    name: 'email',
                    label: 'Email',
                    type: FormType.EMAIL,
                    placeholder: 'Email',
                    required: true,
                    className: 'mb-4',
                    errors: {
                      required: 'Please enter email.',
                      pattern: 'Please enter valid email.',
                    },
                  },
                ]}
              />
              <div className="">
                {showErrorMsg ? (
                  <div className="form-msg ng-hide mt-2 mb-2">
                    <div className="rounded border border-red-400 bg-red-200 text-red-900 text-xs py-0.5 text-center">
                      {showErrorMsg}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <SubmitBtn className="rounded p-2 w-full">Submit</SubmitBtn>
            <button
              type={'button'}
              onClick={() => navigate('/login')}
              className="flex justify-center items-center mt-8 w-full "
            >
              <ArrowLeftIcon />
              <div className={`ml-1 linkText hover:text-gray-900`}>
                Back to Login
              </div>
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
