import { FormItem, FormType } from "../../../../../components/form"

export const CustomerConfig = ({
    customer,
    defaultValues,
    roles,
    addresses,
    regions,
}) => {
    const hasNoLocations = addresses.length === 0;

    const addressOptions = !hasNoLocations ? {
        options: addresses.map(address => ({ label: address.CustomerName, value: address.CustomerID })),
    } : null;

    const regionOptions = !hasNoLocations ? {
        options: regions.map(region => ({ label: region.region, value: region.region })),
    } : null;

    const convertDefaultValues = (valueArray) => {
        return valueArray?.map(value => {
            let thisAddress = addresses?.find(address => address.CustomerID == value);
            return { label: thisAddress?.CustomerName, value };
        });
    }

    const convertDefaultRegions = (valueArray) => {
        return valueArray?.map(value => {
            let thisRegion = regions?.find(region => region.region == value);
            return { label: thisRegion?.region, value };
        });
    }

    if(!addresses.length && !hasNoLocations && !defaultValues) return <></>;

    return (
        <div className="md:pl-6">
            <div className="font-bold mb-2 text-gray-600">User Permissions <span className="text-red-400">*</span></div>
            <FormItem
                formDef={{
                    name: `Tokens`,
                    label: 'User Permissions',
                    type: FormType.CHECKBOX,
                    radioOptions: roles,
                    optionsMultiSelect: true,
                    defaultValue: defaultValues?.Tokens,
                    selectAllLabel: 'All permissions',
                    selectAll: true,
                    required: true,
                    errors: {
                        required: 'Please select at least one role.',
                    },
                    className: 'grid md:grid-cols-2 gap-2 xl:grid-cols-3',
                }}
            />
            { (customer.AllowRestrictRegion || !!defaultValues?.AllowRestrictRegion) ? 
            <><div className="font-bold mb-2 text-gray-600 mt-4">Restrict Regions</div>
            <div className="locationSelect">
            <FormItem
                formDef={{
                    name: `Regions`,
                    label: '',
                    defaultValue: convertDefaultRegions(defaultValues?.Regions),
                    type: FormType.SELECT,
                    isMenuTop: false,
                    selectMultiSelect: true,
                    selectShowSelectAllFilter: true,
                    selectShowSelectAll: true,

                    selectAllValue: 'All Regions',
                    ...regionOptions,
                }}
            /></div></> :
            <><div className="font-bold mb-2 text-gray-600 mt-4">Restrict Locations</div>
            <div className="locationSelect">
            <FormItem
                formDef={{
                    name: `RestrictUserByLocationID`,
                    label: '',
                    defaultValue: convertDefaultValues(defaultValues?.RestrictUserByLocationID),
                    type: FormType.SELECT,
                    isMenuTop: false,
                    selectMultiSelect: true,
                    selectShowSelectAllFilter: true,
                    selectShowSelectAll: true,

                    selectAllValue: 'All Locations',
                    ...addressOptions,
                }}
            /></div></>} 
            <div className={`${!customer.AllowHidingUsersWebOrders && 'hidden'}`}>
                <div className="font-bold mb-2 text-gray-600 mt-4">Hide Others' Orders</div>
                <FormItem
                    formDef={{
                        name: `HideOtherUserOrders`,
                        label: `Hide auto toner, phone, and other users' web orders`,
                        type: FormType.CHECKBOX,
                        defaultValue: defaultValues?.HideOtherUserOrders,
                    }}
                />
            </div>
            <div className="font-bold mb-2 text-gray-600 mt-4 border-t pt-4">Email Subscriptions</div>
            <div className="locationSelect">
                <FormItem
                    formDef={{
                        name: `PlaceOrderAddressID`,
                        label: 'Get order confirmation emails for all locations selected below (including non-portal orders)',
                        type: FormType.SELECT,
                        defaultValue: convertDefaultValues(defaultValues?.PlaceOrderAddressID),
                        isMenuTop: true,
                        selectMultiSelect: true,
                        selectShowSelectAll: true,
                        selectShowSelectAllFilter: true,
                        selectShowCheckboxes: true,
                        ...addressOptions,

                        selectAllValue: 'All Locations',
                    }}
                />
            </div>
            <FormItem
                formDef={{
                  name: `SendMeTrackingWhenAvailable`,
                  label: 'Also send tracking for orders at locations selected above',
                  defaultValue: defaultValues?.SendMeTrackingWhenAvailable,
                  type: FormType.CHECKBOX,
                  className: 'ml-6 mt-2',
                }}
            />
            <FormItem
                formDef={{
                  name: `LetMeKnowIfOrdersGetCanceled`,
                  label: 'Also send an update if an order is canceled at locations selected above',
                  defaultValue: defaultValues?.LetMeKnowIfOrdersGetCanceled,
                  type: FormType.CHECKBOX,
                  className: 'ml-6 mt-2',
                }}
            />

            <div className="mt-4"></div>
            <div className="locationSelect">
                <FormItem
                    formDef={{
                        name: `ServiceRequestAddressID`,
                        label: 'Get service request confirmations for all locations selected below (including non-portal requests)',
                        type: FormType.SELECT,
                        isMenuTop: true,
                        defaultValue: convertDefaultValues(defaultValues?.ServiceRequestAddressID),
                        selectMultiSelect: true,
                        selectShowSelectAll: true,
                        selectShowSelectAllFilter: true,
                        selectShowCheckboxes: true,
                        ...addressOptions,

                        selectAllValue: 'All Locations',
                    }}
                />
            </div>
            <FormItem
                formDef={{
                  name: `SendCallUpdates`,
                  label: 'Also send any updates made to a request at locations selected above',
                    defaultValue: defaultValues?.SendCallUpdates,
                  type: FormType.CHECKBOX,
                  className: 'ml-6 mt-2',
                }}
            />
        </div>
    );
}
