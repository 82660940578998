import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import PackageJson from '../../../../../package.json';
import { TENANT_NAME } from '../../../../constant/constant';
const Footer = () => {
  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );
  return (
    <>
      <footer className="bg-gray-950 text-gray-400">
        <div className={'m-auto pb-4 pt-4 flex justify-center'}>
          {`${new Date().getFullYear()} © ${TENANT_NAME[tenantName]} v${
            PackageJson.version
          }`}
        </div>
      </footer>
    </>
  );
};

export default Footer;
