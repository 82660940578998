import { useRef, useState } from "react";
import { usePopup } from "../../context/PopupContext";
import { useFlexLayer } from "../../../../hooks/useFlexlayer";

export const Popup = () => {

    const { dismissPopup } = usePopup();
    const { flexLayer } = useFlexLayer();
    const ref = useRef<HTMLTextAreaElement>();

    //empty state array of 10 items
    const options = new Array(10).fill(null);

    const [ selected, setSelected ] = useState<number | null>(null);
    const [ sentFeedback, setSentFeedback ] = useState<boolean>(false);

    const sendSubmission = async () => {
        setSentFeedback(true);
        await flexLayer('/popup', {
            PopupData: {
                number: selected,
                feedback: ref.current.value,
            }
        });

        setTimeout(() => {
            dismissPopup();
        }, 3500);
    }

    const RenderNumberSelection = () => {

        return <>
            <h2 className="text-2xl font-bold pt-4">how likely are you to recommend us to a friend or colleague?</h2>

            <div className="grid grid-cols-10 gap-4 mt-4">
                {options.map((_, index) => {
                    return <div key={index} className="flex items-center justify-center bg-gray-200 p-4 rounded shadow cursor-pointer hover:-translate-y-[2px] transition-all"
                        onClick={() => setSelected(index + 1)}
                        style={{backgroundColor: selected === index + 1 ? '#BFFDD1' : 'transparent'}}
                    >
                        {index + 1}
                    </div>
                })}
            </div>

            { selected !== null && <div className="flex justify-end mt-4 flex-col">
                <label className="mb-2">How would you describe your experience with us?</label>
                <textarea 
                    ref={ref}
                    autoFocus
                    className="w-full p-2 border border-gray-300 rounded mr-4 h-20 placeholder:text-gray-300" 
                    placeholder="Enter here"
                    ></textarea>
                <button className="primaryBtn bgColor mt-4" onClick={sendSubmission}>Submit</button>
            </div>}
        </>
    }

    const RenderThankYou = () => {
        return <>
            <h2 className="text-2xl font-bold">Thank you for your feedback!</h2>
            <p>We appreciate your time and effort in helping us improve our customer experience.</p>
        </>
    }


    return <div className="fixed bottom-3 right-3 rounded bg-white p-4 px-6 shadow-lg z-50 lg:min-w-[35vw]">
        <div className="w-full h-full relative ">
            <div className="absolute -top-2 -right-3 cursor-pointer text-red-400 font-bold text-xl" onClick={dismissPopup}>
                X
            </div>

            {sentFeedback ? <RenderThankYou /> : <RenderNumberSelection />}

        </div>
    </div>
}