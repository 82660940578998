import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import useClickOutside from '../../../../hooks/use-click-outside/use-click-outside';
import { HomeIcon } from '../../../../assets/svg/home-icon';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { HeaderCartIcon } from '../../../../assets/svg/header-cart-icon';
import {
  ClientGridName,
  TENANT,
} from '../../../../constant/constant';
import {
  isIpad,
  isMobile,
  // RemoveLocalStorageKeys,
} from '../../../../utils/utils';

import { CustomerSelectionDropdown } from '../customer-selection-dropdown/customer-selection-dropdown';
import { FeedbackIcon } from '../../../../assets/svg/feedback-icon';
import useWindowSize from '../../../../hooks/useWindowSize/useWindowSize';
import FeedbackForm from '../../feedback-form/feedback-form';
import { CloseIcon } from '../../../../assets/svg/close-icon';
import {
  FilterStatePostFix,
  removeLocalStorageConfig,
} from '../../../../shared/api-table/table-helper';
import { useUser } from '../../../../context/UserContext';
import { useCart } from '../../context/CartContext';
import { CustomerTokens } from '../../../../types/Customer';

const Header = ({ setCarDialog }: any) => {
  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );

  const { customerInfo, hasPermission, logout } = useUser();
  const { count } = useCart();

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useClickOutside(() => setIsOpen(false));
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isCartShow, setCartIsShow] = useState(true);

  const feedbackFormRef = useRef(null);

  const headerRef = useClickOutside(() => setIsOpenMenu(false));

  const { width } = useWindowSize();

  const isActiveClass = (path: any) => {
    if (path === '/service/request-history') {
      if (location.pathname.includes('/service/service-request-detail/')) {
        return true;
      }
    }
    if (path === '/customer/order-history') {
      if (location.pathname.includes('/customer/order-detail/')) {
        return true;
      }
    }
    if (path === '/customer/devices') {
      if (location.pathname.includes('/customer/device-detail/')) {
        return true;
      }
      if (location.pathname.includes('/customer/devices/move-device-request')) {
        return true;
      }
      if (location.pathname.includes('/customer/devices/add-device')) {
        return true;
      }
    }
    if (path === '/customer/locations') {
      if (location.pathname.includes('/customer/locations/')) {
        return true;
      }
    }
    if (path === '/product/search') {
      if (location.pathname.includes('/product/view-all-items')) {
        return true;
      }
    }
    if (path.includes('/customer/reports')) {
      if (location.pathname.includes('/customer/reports/')) {
        return true;
      }
    }
    return location.pathname === path;
  };
  const dashboard = () => {
    return (
      <>
        <span className="menuText md:hidden block">Dashboard</span>
        <div className="menuIcon md:block hidden">
          <HomeIcon />
        </div>
      </>
    );
  };
  const menuList = [
    {
      id: 1,
      name: dashboard(),
      route: '/dashboard',
      accessToken: null,
      resetGridName: [
        ClientGridName.DashboardLocation,
        ClientGridName.DashboardOrders,
        ClientGridName.DashboardServiceRequest,
      ],
    },
    {
      id: 2,
      name: 'Order Products',
      route: '/product/search',
      accessToken: [CustomerTokens.PlaceOrder],
      resetGridName: [], // Not having an empty array here forces a page refresh? :thinking:
    },
    {
      id: 3,
      name: 'Request Service',
      route: '/service/request',
      accessToken: [CustomerTokens.PlaceServiceRequest],
      resetGridName: [],
    },
    {
      id: 4,
      name: 'Orders',
      route: '/customer/order-history',
      accessToken: [CustomerTokens.ViewPlacedOrder],
      resetGridName: [],
    },
    {
      id: 5,
      name: 'Requests',
      route: '/service/request-history',
      accessToken: [CustomerTokens.ViewServiceRequest],
      resetGridName: [ClientGridName.ServiceRequestHistory],
    },
    {
      id: 6,
      name: 'Devices',
      route: '/customer/devices',
      accessToken: null,
      resetGridName: [ClientGridName.Device],
    },
    {
      id: 7,
      name: 'Locations',
      route: '/customer/locations',
      accessToken: null,
      resetGridName: [ClientGridName.Location],
    },
  ];

  if (customerInfo.AllowReportView && hasPermission(CustomerTokens.ViewReport) ||
    tenantName === TENANT.SANDBOX
  ) {
    menuList.push({
      id: 8,
      name: 'Reports',
      route: '/customer/reports/meter-by-device',
      accessToken: null,
      resetGridName: [
        ClientGridName.MeterByReports,
        ClientGridName.ActiveOn,
        ClientGridName.RetiredDevice,
        ClientGridName.NeverReported,
        ClientGridName.StoppedReporting,
      ],
    });
  }

  const resetGridFilter = ({ resetGridName }) => {
    for (const gridName of resetGridName) {
      removeLocalStorageConfig({
        configKey: `${gridName}${FilterStatePostFix}`,
        storageKey: customerInfo?.CustomerId,
      });
    }
  };

  const redirect = (url: any) => {
    if (url.indexOf('logout') > -1) {
      logout();
    } else {
      navigate(url);
    }
    setIsOpen(false);
  };

  const dropdownMenuList = [
    {
      id: 1,
      name: 'User Management',
      route: `/customer/user/list/${customerInfo?.CustomerId}`,
      accessToken: [CustomerTokens.All],
    },
    {
      id: 2,
      name: 'Recycle toner',
      route: '/recycle-toner',
      accessToken: null,
    },
    {
      id: 3,
      name: 'My Profile',
      route: '/customer/profile',
      accessToken: null,
    },
    {
      id: 4,
      name: 'Change Password',
      route: '/customer/change-password',
      accessToken: null,
    },
    {
      id: 5,
      name: 'Logout',
      route: '/logout',
      accessToken: null,
    },
  ]

  const manageCartVisibility = () => {
    if (location.pathname.indexOf('customer/thankyou') > -1) {
      setCartIsShow(false);
      setCarDialog(false);
    } else {
      setCartIsShow(true);
    }
  };
  const openFeedBackForm = () => {
    if (feedbackFormRef?.current) {
      feedbackFormRef?.current.openFeedBackForm();
    }
  };

  useEffect(() => {
    setIsOpenMenu(false);
    setCarDialog(false);
    manageCartVisibility();
  }, [location?.pathname]);
  useEffect(() => {
    if (isOpenMenu) {
      document.body.classList.add('openMenuBody');
    } else {
      document.body.classList.remove('openMenuBody');
    }
  }, [isOpenMenu]);

  return (
    <>
      <FeedbackForm ref={feedbackFormRef} />
      <header className="bg-gray-950 text-gray-400" id="header">
        <div className={'flex justify-center'}>
          <div className="w-full headerBar">
            <div className="xl:px-8 px-4 py-3 flex justify-between">
              <div className="logo">
                {tenantName ? (
                  <img
                    src={require(`../../../../assets/images/${tenantName}-header-logo.png`)}
                    onClick={() => {
                      navigate('/dashboard');
                    }}
                  />
                ) : (
                  ''
                )}
              </div>
              <div className="flex items-center">
                <div
                  className=""
                  onClick={(e) => {
                    e.stopPropagation();
                    openFeedBackForm();
                  }}
                >
                  <span
                    className="md:hidden flex"
                    onClick={(e) => {
                      e.stopPropagation();
                      openFeedBackForm();
                    }}
                  >
                    {' '}
                    <FeedbackIcon />
                  </span>
                  <div className="md:flex hidden cursor-pointer hover:text-red-500">
                    Feedback
                  </div>
                </div>
                {width && width >= 640 ? (
                  <div className="md:px-6 md:mx-6 px-3 mx-3 border-x border-gray-700 sm:flex hidden">
                    <CustomerSelectionDropdown />
                  </div>
                ) : (
                  ''
                )}

                <div
                  ref={dropdownRef}
                  className="relative sm:pl-0 sm:ml-0 pl-3 ml-3 sm:border-0 border-l border-gray-700"
                >
                  <button onClick={() => setIsOpen(!isOpen)}>
                    <div className="flex items-center">
                      <div className="mr-2 w-8 h-8 rounded-full bgColor flex items-center justify-center text-white">
                        <span className="text-sm font-semibold">
                          {customerInfo.FirstName.charAt(0)}
                          {customerInfo.LastName.charAt(0)}
                        </span>
                      </div>
                      <div className="downArrow"></div>
                    </div>
                  </button>
                  {isOpen ? (
                    <ul className="absolute bg-gray-950 text-gray-400 rounded-b-md overflow-hidden shadow-md right-0 top-10 z-20 w-max ">
                      {dropdownMenuList.map((dropdownMenuItem, index) => {
                        const canView = dropdownMenuItem.accessToken === null || dropdownMenuItem.accessToken?.some((token) => hasPermission(token))
                        if(!canView) return null;
                        return (
                          <div key={'dropdownMenuList' + index}>
                              <li
                                key={index}
                                className="px-4 py-2 hover:bg-gray-100 hover:text-black cursor-pointer"
                                onClick={() => {
                                  redirect(`${dropdownMenuItem.route}`);
                                }}
                              >
                                {dropdownMenuItem.name}
                              </li>
                          </div>
                        )
                      })}
                    </ul>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div
              className={
                'xl:px-28 px-4 w-full flex items-center justify-between m-auto lg:pb-0 pb-4 relative'
              }
            >
              <div
                className={`hamburger mr-4 cursor-pointer lg:hidden block  ${
                  isOpenMenu ? 'is-active' : ''
                }`}
                onClick={() => setIsOpenMenu(!isOpenMenu)}
                id="hamburger-1"
              >
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </div>

              {width && width < 639 ? (
                <div className="sm:hidden flex mr-4">
                  <CustomerSelectionDropdown />
                </div>
              ) : (
                ''
              )}

              <div className="lg:pb-0 pb-4 flex w-full">
                <div
                  ref={headerRef}
                  className={`lg:relative lg:z-0 z-40 fixed overflow-y-auto top-0 h-full bg-gray-950 mainMenu lg:w-full w-60 lg:p-0 p-4 ${
                    isOpenMenu ? 'openMenu' : ''
                  } `}
                >
                  <div className="lg:hidden flex justify-between items-center mb-8">
                    <div className="logo">
                      {tenantName ? (
                        <img
                          src={require(`../../../../assets/images/${tenantName}-header-logo.png`)}
                          alt={'Logo'}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div
                      className="pr-2 pt-1 cursor-pointer"
                      onClick={() => {
                        setIsOpenMenu(!isOpenMenu);
                      }}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                  
                  <ul className="lg:flex xl:gap-x-10 gap-x-4 justify-self-end ">
                    {menuList.map((menuItem) => {
                      const canView = menuItem?.accessToken === null || hasPermission(menuItem.accessToken);
                      if (!canView) return null;
                        return (
                            <li
                              key={`desktop-li-${menuItem.id}`}
                              className={'cursor-pointer lg:mb-0 mb-2'}
                            >
                              <Link
                                key={`desktop-link-${menuItem.id}`}
                                className={`block lg:pb-3 pb-1 hover:text-white font-semibold border-b-2 border-transparent ${
                                  isActiveClass(menuItem.route)
                                    ? `text-white borderColor active`
                                    : 'text-gray-400'
                                }`}
                                onClick={() => {
                                  resetGridFilter({
                                    resetGridName: menuItem.resetGridName,
                                  });
                                }}
                                to={menuItem.route}
                              >
                                {menuItem.name}
                              </Link>
                            </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
              <div>
                <div className={'flex items-center justify-between w-full'}>
                  <div className="flex w-max items-center">
                    <div className="xl:block1 hidden1 block ">
                      {isCartShow && hasPermission(CustomerTokens.PlaceOrder) ? (
                        <div
                          className="cartIcon relative"
                          onClick={() => {
                            if (isMobile() || isIpad()) {
                              navigate('/customer/cart');
                            } else {
                              setCarDialog(true);
                            }
                          }}
                        >
                          <div className="flex items-center cursor-pointer">
                            <div>
                              <HeaderCartIcon />
                            </div>
                            <div
                              className={
                                'xl:ml-2 ml-0.5 text-white flex items-center text-sm font-semibold'
                              }
                            >
                              <span className="xl:flex hidden mr-1">Cart </span>
                              <span className="md:mt-0 -mt-1">
                                ({count})
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="bg-gray-950 text-gray-400 printHeader">
        <div className="w-full">
          <div className="xl:px-8 px-4 py-3 flex justify-between">
            <div className="logo">
              {tenantName ? (
                <img
                  src={require(`../../../../assets/images/${tenantName}-header-logo.png`)}
                  onClick={() => {
                    navigate('/dashboard');
                  }}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
