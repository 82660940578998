import React from 'react';
import { Link } from 'react-router-dom';
import { AddWhiteIcon } from '../../../assets/svg/add-white-icon';
import { UndoIcon } from '../../../assets/svg/undo-icon';
import { DragIcon } from '../../../assets/svg/drag-icon';
import { CsvIcon } from '../../../assets/svg/csv-icon';
import { ExpandIcon } from "../../../assets/svg/expand-icon";
import { CompressIcon } from "../../../assets/svg/compress-icon";
import { useUser } from '../../../context/UserContext';
import { CustomerTokens } from '../../../types/Customer';

interface WidgetHeaderProps {
  viewAllRouterLink?: string | null;
  plusRouterLink?: string | null;
  accessToken?: CustomerTokens[] | null;
  title?: string | null;
  resetGrid?: any;
  expandContractGrid?: any;
  exportCSV?: any;
  isShowExportCsvBtn?: boolean;
  isDrag?: boolean;
  isExpand?: boolean;
  gridBtnClassName?: string;
}
export const WidgetHeader = ({
  viewAllRouterLink = null,
  plusRouterLink = null,
  accessToken = [],
  title,
  resetGrid = null,
  expandContractGrid = null,
  exportCSV = null,
  isShowExportCsvBtn = true,
  isDrag = true,
  isExpand = true,
  gridBtnClassName = 'grid-export-btn',
}: WidgetHeaderProps) => {
  const createTitleForPlusIcon = (link: string) => {
    if(link.includes('/service/request')) {
      return "Request Service";
    }
    if(link.includes('/product/search')) {
      return "Order Products";
    }
    return null;
  }

  const { hasPermission } = useUser();
  const canViewLink = (hasPermission(accessToken) && plusRouterLink) ? true : false;
  return (
    <>
      <div className={`flex justify-between items-center mb-4`}>
        <div className={'flex items-center'}>
          <h3 className="text-lg font-semibold mr-4">{title} </h3>
          {viewAllRouterLink ? (
            <Link
              className={`text-xs font-normal linkText hover:text-black`}
              to={viewAllRouterLink}
            >
              View All
            </Link>
          ) : (
            ''
          )}
        </div>
        <div>
          <div className={'flex gap-3 items-center'}>
            {isDrag ? (
              <span
                className={'drag-handle cursor-move xl:flex hidden'}
                title="Move Grid"
              >
                <DragIcon />
              </span>
            ) : (
              ''
            )}
            <span
              className={'cursor-pointer hide-button-mobile xl:flex hidden'}
              title={isExpand ? 'Contract Grid' : 'Expand Grid'}
              onClick={() => {
                if (expandContractGrid) {
                  expandContractGrid();
                }
              }}
            >
                {isExpand ? <CompressIcon /> : <ExpandIcon />}

            </span>


            {resetGrid ? (
              <span
                className={'cursor-pointer'}
                title="Reset Grid"
                onClick={() => {
                  if (resetGrid) {
                    resetGrid();
                  }
                }}
              >
                <UndoIcon />
              </span>
            ) : (
              ''
            )}

            {canViewLink && (
                <Link to={plusRouterLink}>
                  <div
                    className="bgColor p-0.5 rounded"
                    title={createTitleForPlusIcon(plusRouterLink)}
                  >
                    <AddWhiteIcon />
                  </div>
                </Link>
            )}
            {exportCSV && isShowExportCsvBtn ? (
              <div>
                <button
                  className={`mt-1 ${gridBtnClassName}`}
                  title="Export CSV"
                  onClick={() => {
                    if (exportCSV) {
                      exportCSV();
                    }
                  }}
                >
                  <CsvIcon />
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};
