import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { Api } from '../../../../shared/api/api';
import {
  colorCoder,
  generateRandomString,
  getTime,
  isCheckDollerProcingNonZero,
} from '../../../../utils/utils';
import { ClientStorageKey, TENANT } from '../../../../constant/constant';
import { ReactSelectCompo } from '../../../../components/ui/react-select/react-select';
import { useSelector } from 'react-redux';
// import {
//   hideLoader,
//   showLoader,
// } from '../../../../store/loading/loading.action';
import storage from '../../../../utils/storage/storage';
import { filter } from 'lodash';
import NoRowsFound from '../../../../shared/api-table/no-rows-found/not-rows-found';
import { Pagination } from './Pagination';
import { Button, Input } from "../../../../components/ui";
import { Link } from 'react-router-dom';
import { RootState } from '../../../../store/store';
import { SearchIcon } from '../../../../components/ui/react-select/select-panel/searchIcon';
import { ComponentLoading } from '../../../../shared/component-loader/component-loading';
import { Chip } from '../../../../components/ui/Chip';
import { useUser } from '../../../../context/UserContext';
import { CustomerTokens } from '../../../../types/Customer';
import { useCart } from '../../context/CartContext';

const OrderHistory = () => {
  const requestFilterData: any = storage.getJSONItem(
    ClientStorageKey.OrderFilter,
  );
  const tenantName = useSelector(
    (states: RootState) => states.tenantState.tenantName,
  );

  const { customerInfo, hasPermission } = useUser();
  const { addToCart } = useCart();
  const [allLocationIds, setAllLocationIds] = useState(null);
  const [selectLocations, setSelectedLocations] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [timeDropdownValue, setTimeDropdownValue] = useState(
    requestFilterData && requestFilterData?.timeRange !== null
      ? requestFilterData?.timeRange
      : 1,
  );
  const [isDropDownVisible, setDropdownVisible] = useState(false);
  const [locationDropDownValue, setLocationDropDownValue] = useState(null);
  const [locationDropdownOption, setLocationDropdownOption] = useState(null);
  const [totalRows, setTotalRows] = useState(null);
  const [endDate, setEndDate] = useState(new Date().toISOString() || null);
  const [startDate, setStartDate] = useState(
    requestFilterData && requestFilterData?.timeRange !== null
      ? requestFilterData?.timeRange === 0
        ? ''
        : moment(new Date())
            .subtract(requestFilterData?.timeRange, 'months')
            .toISOString()
      : moment(new Date()).subtract(1, 'months').toISOString(),
  );

  const [orderHistoryData, setOrderHistoryData] = useState(null);
  const [searchOrderNumberValue, setSearchOrderNumberValue] = useState(
    requestFilterData && requestFilterData?.orderNumber
      ? requestFilterData?.orderNumber
      : '',
  );
  const [pageCount, setPageCount] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);

  const timeStempDropDownOptions: any = [
    {
      label: 'All Time',
      value: '0',
    },
    {
      label: 'Last Month',
      value: '1',
    },
    {
      label: 'Last 3 Months',
      value: '3',
    },
    {
      label: 'Last 6 Months',
      value: '6',
    },
  ];

  //For showing data
  const getRequestData = async () => {
    try {
      // dispatch(showLoader());
      setTotalRows(null);
      let locations = allLocationIds;
      if (selectLocations === null) {
        locations =
          customerInfo?.RestrictUserByLocationID &&
          customerInfo?.RestrictUserByLocationID?.length
            ? customerInfo?.RestrictUserByLocationID &&
              customerInfo?.RestrictUserByLocationID?.join(',')
            : null;
      }

      if (selectLocations && selectLocations?.length) {
        locations = selectLocations.join(',');
      }

      const dataQuery = {
        AllowHidingUsersWebOrders: false,
        UserId: '',
        LocationID: locations,
        CustomerId: '',
      };
      const searchQuery = {
        LocationID: [],
        OrderNumber: '',
        EmailAddress: '',
        StartDate: '',
        EndDate: '',
        pageNo: 1,
        rows: 10,
      };
      dataQuery.CustomerId = customerInfo.CustomerId;
      dataQuery.AllowHidingUsersWebOrders =
        customerInfo && customerInfo.AllowHidingUsersWebOrders
          ? customerInfo.AllowHidingUsersWebOrders
          : false;
      dataQuery.UserId = customerInfo.UserId;
      const restrictUserByLocationIDs =
        customerInfo &&
        customerInfo.RestrictUserByLocationID &&
        customerInfo.RestrictUserByLocationID.length > 0
          ? customerInfo.RestrictUserByLocationID
          : '';
      if (restrictUserByLocationIDs && restrictUserByLocationIDs.length > 0) {
        searchQuery.LocationID = customerInfo.RestrictUserByLocationID;
      }

      if (restrictUserByLocationIDs && restrictUserByLocationIDs.length > 0) {
        searchQuery.LocationID = customerInfo.RestrictUserByLocationID;
      }

      searchQuery.OrderNumber = searchOrderNumberValue
        ? searchOrderNumberValue.trim()
        : '';
      searchQuery.EmailAddress = customerInfo.EmailAddress;
      searchQuery.StartDate = startDate;
      searchQuery.EndDate = endDate;
      searchQuery.pageNo = pageNumber;
      searchQuery.rows = 10;

      const response = await Api.callAPI({
        url: `/api/v1/orders/history`,
        method: 'PUT',
        body: { ...searchQuery, ...dataQuery },
        options: {
          iaLoading: true,
          successMessage: false,
          errorMessage: 'Error while getting order history.',
        },
      });
      setPageCount(Math.ceil(response?.data?.totalRows / 10));
      setOrderHistoryData(response?.data?.data);
      setTotalRows(response?.data?.totalRows || 0);
      return response?.data;
    } catch (e) {
    } finally {
      // dispatch(hideLoader());
    }
  };

  const localFilterValue = (locationArr) => {
    if (storage.getItem(ClientStorageKey.OrderFilter)) {
      if (locationArr && locationArr.length) {
        let filterData = [];
        const requestFiltesData: any = storage.getJSONItem(
          ClientStorageKey.RequestFilters,
        );
        const locationIds = requestFiltesData?.location
          ? requestFiltesData?.location.split(',')
          : [];

        locationIds.map((locationId) => {
          const filterRecord = locationArr.filter(
            (lData) => lData.value.toString() === locationId,
          );
          if (filterRecord) {
            filterData = [...filterData, ...filterRecord];
          }
        });
        const convertDataFormDropdown: any = filterData.map((data) => {
          return {
            value: data.value,
            label: data.label,
          };
        });
        storage.removeItem(ClientStorageKey.OrderFilter);
        return {
          locations: convertDataFormDropdown,
        };
      }
    }
    return [];
  };

  // get address for dropdown search location
  const getShippingAddress = async () => {
    let locationArrIds = [];
    let locationArr = [];
    // dispatch(showLoader());
    try {
      const response = await Api.callAPI({
        url: `/api/v1/customers/${customerInfo?.CustomerId}/address`,
        method: 'get',
        body: {
          Active: true,
        },
        options: {
          isLoading: false,
        },
      });

      if (
        customerInfo?.RestrictUserByLocationID &&
        customerInfo?.RestrictUserByLocationID?.length
      ) {
        customerInfo?.RestrictUserByLocationID.forEach((location) => {
          const locations = filter(response.data, { CustomerID: location });
          locationArrIds.push(locations[0].CustomerID);
          locationArr.push(locations[0]);
        });
        setAllLocationIds(locationArrIds.join(','));
      } else {
        const customerIdArr = response?.data
          ? response?.data.map(({ CustomerID }) => CustomerID)
          : [];
        locationArr = response?.data;
        setAllLocationIds(customerIdArr.join(','));
      }

      const locationLabelValueArr = locationArr.map((data) => {
        return {
          value: data.CustomerID,
          label: data.SearchBy,
        };
      });

      setLocationDropdownOption([...locationLabelValueArr]);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const filterData: any = localFilterValue(
        locationArr.map((data) => {
          return {
            value: data.CustomerID,
            label: data.SearchBy,
          };
        }),
      );

      setLocationDropDownValue(
        filterData && filterData?.locations ? filterData?.locations : [],
      );

      setSelectedLocations(
        filterData && filterData.locations
          ? filterData.locations.map(({ value }) => value)
          : null,
      );

      setDropdownVisible(locationLabelValueArr?.length > 1);
      return {
        data: locationArr,
      };
    } catch (e) {
    } finally {
      // dispatch(hideLoader());
    }
  };


  const onSearchHandler = () => {
    setTimeDropdownValue(0);
    setStartDate('');
    setEndDate('');
    setPageNumber(1);
    setRefreshToken(generateRandomString(5));
  };

  const handlePageClick = (event) => {
    setPageNumber(event.selected + 1);
    setRefreshToken(generateRandomString(5));
  };

  useEffect(() => {
    getShippingAddress().then();
  }, []);

  useEffect(() => {
    getRequestData().then();
  }, [refreshToken]);
  return (
    <>
        <div>
          <h1 className="mb-4 text-2xl font-medium">Order history</h1>
          <div className="md:flex justify-between w-full pb-4 border-b border-gray-200">
            <div className="flex items-center  md:mb-0 mb-4">
              <div className="xl:w-96 md:w-80 w-full locationSelect">
                {isDropDownVisible &&
                locationDropDownValue &&
                locationDropdownOption ? (
                  <ReactSelectCompo
                    options={locationDropdownOption}
                    value={locationDropDownValue}
                    name={'select location '}
                    label={'Search Location'}
                    selectAllValue='All Locations'
                    hasFilterSelectAll={true}
                    overrideStrings={{ selectSomeItems: 'Search Location' }}
                    labelledBy={'location'}
                    onClose={(option) => {
                      if (option && option?.length) {
                        const locationIdsArr = option.map(({ value }) => value);
                        setSelectedLocations(locationIdsArr);
                      } else {
                        setSelectedLocations(null);
                      }
                      setPageNumber(1);
                      setRefreshToken(generateRandomString(5));
                    }}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="">
                <label className="text-sm text-gray-600 mb-2">
                  Search Order Number
                </label>
                <div className='flex items-end justify-end'>
                  <Input
                    type={'text'}
                    onEnter={onSearchHandler}
                    onChange={(e) => {
                      if(e.target.value === '') onSearchHandler();
                      setSearchOrderNumberValue(e.target.value);
                    }}
                    value={searchOrderNumberValue}
                    className='mr-3'
                    icon={<SearchIcon />}
                    clearButton={true}
                  />
                <button
                  className={`borderedBtn mr-2 linkText hover:text-black`}
                  onClick={() => onSearchHandler()}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="lg:flex items-center justify-between my-4 w-full">
            <div className="flex items-center sm:justify-start justify-between lg:mb-0 mb-6">
              <div className="mr-4">
                <span className="md:text-lg sm:text-base text-sm font-semibold">
                  {totalRows} {totalRows > 1 ? 'Orders' : 'Order'}
                </span>
              </div>
              <div>
                {timeDropdownValue !== null ? (
                  <ReactSelectCompo
                    name={'timestemp'}
                    className="w-40"
                    label={'Search Time Stemp'}
                    labelledBy={'timeStempo'}
                    disableSearch={true}
                    options={timeStempDropDownOptions}
                    value={timeStempDropDownOptions.filter(({ value }) => {
                      return value == timeDropdownValue.toString();
                    })}
                    isCheckboxVisible={false}
                    ClearSelectedIcon={null}
                    onChange={(selectedOp) => {
                      const data =
                        selectedOp && selectedOp.length ? selectedOp[0] : null;
                      const value = data?.value || timeDropdownValue;

                      let sDate,
                        eDate = new Date().toISOString();

                      if (parseInt(value) == 1) {
                        sDate = moment(eDate)
                          .subtract(1, 'months')
                          .toISOString();
                      } else if (parseInt(value) == 3) {
                        sDate = moment(eDate)
                          .subtract(3, 'months')
                          .toISOString();
                      } else if (parseInt(value) == 6) {
                        sDate = moment(eDate)
                          .subtract(6, 'months')
                          .toISOString();
                      } else {
                        sDate = '';
                        eDate = '';
                      }

                      setStartDate(sDate);
                      setEndDate(eDate);
                      setTimeDropdownValue(value);
                      setPageNumber(1);
                      setRefreshToken(generateRandomString(5));
                    }}
                    isSingleSelect={true}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
            {totalRows !== null && <Pagination
                pageNumber={pageNumber}
                pageCount={Math.ceil(pageCount)}
                handlePageClick={handlePageClick}
            /> }
          </div>
          {totalRows === null ? <div>
            <ComponentLoading isShowLoader={totalRows === null}/>
          </div> : totalRows !== 0 ? (
            <div>
              {orderHistoryData &&
                orderHistoryData.map((order: any, orderHistoryIndex: number) => {
                  return (
                    <div key={'orderHistory' + orderHistoryIndex}>
                      <div className="border border-gray-200 rounded-lg text-sm mb-8">
                        <div className="md:flex justify-between sm:flex-nowrap flex-wrap orderHeading p-6 bg-gray-100 items-start">
                          <div className="xl:w-11/12 md:w-10/12 sm:flex flex-wrap orderList ">
                            {order?.SOCode && (
                              <div className="2xl:mb-0 mb-3 pr-8 colItem">
                                <div className="text-gray-600">Order No.</div>
                                <div className="text-gray-800 font-medium xl:mt-2">
                                  <Link
                                    to={`/customer/order-detail/${
                                      order.SOID
                                    }?timeRange=${timeDropdownValue}&orderNumber=${
                                      searchOrderNumberValue
                                        ? searchOrderNumberValue
                                        : ''
                                    }`}
                                    className="text-gray-800 font-medium linkText"
                                  >
                                    {order?.SOCode}
                                  </Link>
                                </div>
                              </div>
                            )}
                            {order?.PONumber && (
                              <div className="2xl:mb-0 mb-3 pr-8 colItem">
                                <div className="text-gray-600">Customer PO</div>
                                <div className="text-gray-800 font-medium xl:mt-2">
                                  {order?.PONumber}
                                </div>
                              </div>
                            )}
                            {order?.OrderType && (
                              <div className="2xl:mb-0 mb-3 pr-8 colItem">
                                <div className="text-gray-600">Order Type</div>
                                <div className="text-gray-800 font-medium xl:mt-2">
                                  {order?.OrderType}
                                </div>
                              </div>
                            )}
                            {order?.CreatedDate && (
                              <div className="2xl:mb-0 mb-3 pr-8  colItem">
                                <div className="text-gray-600">
                                  Order Placed
                                </div>
                                <div className="text-gray-800 font-medium xl:mt-2">
                                  {getTime(order?.CreatedDate)}
                                </div>
                              </div>
                            )}

                            {order?.ShipToName && (
                              <div className="2xl:mb-0 mb-3 pr-8 colItem">
                                <div className="text-gray-600 md:mr-0 mr-4">
                                  Location Name
                                </div>
                                <div className="text-gray-800 font-medium xl:mt-2">
                                  {order?.ShipToName}
                                </div>
                              </div>
                              )}

                            {isCheckDollerProcingNonZero(
                              order?.OrderCost.toFixed(2),
                            ) ? (
                              <div className="2xl:mb-0 mb-3 pr-8 colItem">
                                <div className="text-gray-600">
                                  Total Amount
                                </div>
                                <div className="text-gray-800 font-medium xl:mt-2">
                                  ${order?.OrderCost.toFixed(2)}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            {order?.Status && (
                              <div className="2xl:mb-0 mb-2  pr-8">
                                <div className="text-gray-600">Status</div>
                                <div className="mt-2">
                                  <Chip status={order?.Status}/>
                                </div>
                              </div>
                            )}
                          </div>
                          {order.SOID && (
                            <div className="xl::w-1/12 md:w-2/12 md:mt-0 mt-6 flex md:justify-end">
                              <button className="linkText font-semibold">
                                <Link
                                  to={`/customer/order-detail/${
                                    order.SOID
                                  }?timeRange=${timeDropdownValue}&orderNumber=${
                                    searchOrderNumberValue
                                      ? searchOrderNumberValue
                                      : ''
                                  }`}
                                  className="text-gray-800 font-medium ml-1 linkText"
                                >
                                  Order Details
                                </Link>
                              </button>

                            </div>
                          )}
                        </div>
                        <div className="orderListBar">
                          {order &&
                            order?.OrderItems?.map((item: any, itemIndex: number) => {
                              return (
                                <div
                                  className="p-4 w-full border-b bordr-gray-300 orderListItem"
                                  key={`item${itemIndex}${orderHistoryIndex}`}
                                >
                                  <div className="lg:flex items-center gap-6">
                                    <div className="2xl:w-9/12 lg:w-3/5 text-sm">
                                      <div>
                                        <div className="flex">
                                          <div>
                                            <div className="w-4 mr-2 ">
                                              {tenantName === TENANT.MARIMON &&
                                              item &&
                                              item.ItemDescription &&
                                              colorCoder(
                                                item?.ItemDescription,
                                              ) ? (
                                                <div
                                                  className="w-4 h-4 rounded-full ItemDescription mt-1"
                                                  style={{
                                                    backgroundColor: colorCoder(
                                                      item?.ItemDescription,
                                                    ),
                                                  }}
                                                ></div>
                                              ) : (
                                                ''
                                              )}
                                              {tenantName !== TENANT.MARIMON &&
                                              item &&
                                              item.CatDescription &&
                                              colorCoder(
                                                item.CatDescription,
                                              ) ? (
                                                <div
                                                  className="w-4 h-4 rounded-full ItemDescription mt-1"
                                                  style={{
                                                    backgroundColor: colorCoder(
                                                      item?.CatDescription,
                                                    ),
                                                  }}
                                                ></div>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </div>
                                          <div className="text-gray-900 font-semibold break-all	">
                                            {item?.ItemDescription}
                                          </div>
                                        </div>
                                        <div className="ml-6 sm:flex text-gray-600 mt-2">
                                          {item?.Item && (
                                            <div className="mr-8">
                                              {item?.Item}
                                            </div>
                                          )}

                                          {item?.EquipmentNumber && (
                                            <div className="mr-8">
                                              EQ# {item?.EquipmentNumber}
                                            </div>
                                          )}
                                          {item?.SerialNumber && (
                                            <div className="mr-8">
                                              SER# {item?.SerialNumber}
                                            </div>
                                          )}
                                          {item?.LocationRemarks && (
                                            <div className="mr-8">
                                              {item?.LocationRemarks}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="2xl:w-3/12 lg:w-2/5 lg:mt-0 mt-4 flex lg:justify-end">
                                      <div className="sm:flex items-center gap-8 lg:justify-start justify-between">
                                        {isCheckDollerProcingNonZero(
                                          item?.Price?.toFixed(2),
                                        ) ? (
                                          <div className=" w-24 text-center lg:block flex sm:mb-0 mb-2">
                                            <div className="text-gray-900 font-semibold lg:mr-0 mr-3">
                                              Each
                                            </div>
                                            <div className="text-gray-600">
                                              ${item?.Price?.toFixed(2)}
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        {
                                          <div className="text-center lg:block flex sm:mb-0 mb-2">
                                            <div className="text-gray-900 font-semibold lg:mr-0 mr-3">
                                              Qty
                                            </div>
                                            <div className="text-gray-600">
                                              {item.Quantity}
                                            </div>
                                          </div>
                                        }
                                        {hasPermission(CustomerTokens.PlaceOrder) ? (
                                          <div>
                                            <Button
                                            className="rounded px-4 py-2"
                                              onClick={() => addToCart(item)}
                                            >
                                              Order Again
                                            </Button>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                                },
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  },
                )}
            </div>
          ) : (
            <div className="minHeight500">
              <NoRowsFound />
            </div>
          )}
          <>
            <Pagination
              pageNumber={pageNumber}
              pageCount={Math.ceil(pageCount)}
              handlePageClick={handlePageClick}
            />
          </>
        </div>
    </>
  );
};

export default OrderHistory;
