import { RadioButton } from "../../ui";
import { useForm } from "../Form";
import { FormDefinition } from "../types/FormTypes";

export const FormRadio = ({ formDef }: { formDef: FormDefinition}) => {

    const { formState, handleSelection, handleInputValidation } = useForm();
    const checkBoxState = formState[formDef.name];

    return <RadioButton 
        className={formDef.className}
        label={formDef.label}
        name={formDef.name}
        value={checkBoxState?.value || null}
        onChange={(val: any) => {
            formDef.onChange?.(val);
            return handleSelection(val, formDef.name)
        }}
        options={formDef.radioOptions}
        onBlur={() => handleInputValidation(formDef)}
        multiSelect={formDef.optionsMultiSelect}
        selected={checkBoxState?.value || []}
        helperText={formDef.helperText}
        error={checkBoxState?.error}
        row={formDef.displayRow}
        defaultValue={formDef.defaultValue}
    />
}