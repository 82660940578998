import { BaseReportGrid } from '../base-report-grid/base-report-grid';
import { ClientGridName } from '../../../../../constant/constant';
import { Api } from '../../../../../shared/api/api';
import { useRef, useState } from 'react';
import ResolutionModal from '../resolution-modal/resolution-modal';

export const RetiredDevice = () => {
  const ref = useRef(null);
  const [selectedResolutionValue, setSelectedResolutionValue] = useState(null);
  const getReportData = async (searchObj) => {
    try {
      return await Api.callAPI({
        url: `/api/v1/reports/FMAudit/retired-device/${searchObj.CustomerID}`,
        method: 'PUT',
        body: searchObj,

        options: {
          isLoading: false,
        },
      });
    } catch (e) {}
  };

  return (
    <>
      <h2 className="mb-6 text-gray-950 text-lg font-semibold">
        Retired Devices Reporting in
      </h2>
      <ResolutionModal
        entity={'RetiredDevice'}
        ref={ref}
        formValues={selectedResolutionValue}
      />
      <BaseReportGrid
        gridName={ClientGridName.RetiredDevice}
        ApiDataFunction={getReportData}
        newFields={[
          {
            headerName: 'LastAudit',
            field: 'LastAudit',
          },
          {
            headerName: 'Location Remark',
            field: 'Location',
          },
          {
            headerName: 'IP',
            field: 'IPAddress',
            width: 100,
          },
          {
            headerName: 'Resolve',
            field: 'Resolve',
            filter: null,
            width: 160,
            order: 0,
            sortable: false,
            isShowCSVfield: false,
            cellRenderer: (cell) =>
              cell.data ? (
                <a className="linkText">
                  Resolution
                </a>
              ) : (
                ''
              ),
            onCellClicked: (cell) => {
              if (!cell.data) {
                return;
              }
              setSelectedResolutionValue(cell?.data);

              setTimeout(() => {
                if (ref?.current) {
                  ref?.current.openResolutionForm(cell?.data);
                }
              }, 10);

              // this.viewPopup(cell.data);
            },
          },
        ]}
      />
    </>
  );
};
