import { useEffect, useRef, useState } from "react";
import { Input } from "./Input";
import { useTheme } from "../../context/ThemeContext";

interface TreeViewProps {
    data: TreeViewData[];
    className?: string;
    openChildrenOnSearch?: boolean;
    onClick?: (value: string | number | object) => void;
    onClickAll?: (value: string | number | object) => void;
    showTreeOnSearch?: boolean;
    hideNoFocus?: boolean;
    hideSearch?: boolean;
    placeholder?: string;
    label?: string;
    selected?: string;
}

interface NodeProps {
    node: TreeViewData;
    depth: number;
    search: boolean;
    openChildrenOnSearch?: boolean;
    onClick?: (value: string | number | object) => void;
    onClickAll?: (value: string | number | object) => void;
    selected?: string;
    setShowTree?: (value: boolean) => void;
    setSearch?: (value: string) => void;
}

export interface TreeViewData extends Partial<unknown> {
    name: string;
    value: string | number | object;
    children?: TreeViewData[];
}

//TODO: Use Font Awesome Eventually
const defaultSearchIcon = <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="currentColor" viewBox="0 0 512 512"><path d="M384 208A176 176 0 1 0 32 208a176 176 0 1 0 352 0zM343.3 366C307 397.2 259.7 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 51.7-18.8 99-50 135.3L510.5 487.9l-22.6 22.6L343.3 366z"/></svg>;
const chevDown = <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" fill="currentColor" viewBox="0 0 512 512"><path d="M239 401c9.4 9.4 24.6 9.4 33.9 0L465 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L81 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 401z"/></svg>


const RenderNode = ({ node, depth, search, ...props }: NodeProps) => {

    const autoOpen = !!search && props.openChildrenOnSearch && !!node.children;
    const [ isOpen, setIsOpen ] = useState<boolean>(() => autoOpen);
    const isFinalDepth = !node.children || node.children.length === 0;

    useEffect(() => {
        if(autoOpen) setIsOpen(true);
        return () => setIsOpen(false);
    }, [search])

    depth++
    
    const handleClick = (e) => {
        e.stopPropagation();
        
        if(node.children) setIsOpen(!isOpen);
        if(isFinalDepth && props.onClick) {
            props.onClick(node);
            if(props.setShowTree) props.setShowTree(false);
            if(props.setSearch) props.setSearch('');
        }
        if(props.onClickAll) props.onClickAll(node);
    }

    return <div
        style={{ paddingLeft: `${depth * 10}px`, paddingRight: `1rem` }}
        className={`flex flex-col cursor-pointer transition-all border-solid border-stone-400 ${isFinalDepth ? 'hover:bg-stone-700/10' : ''} ${props.selected === node.name ? 'bg-stone-700/10' : ''}`} 
        onClick={handleClick}>
        <div className="flex items-center">
            { !isFinalDepth && <div className="mr-2">{ chevDown }</div> }
            <div>{node.name}</div>
        </div>
        {( isOpen && node.children ) && node.children.map((child) => <RenderNode key={child.name} node={child} depth={depth} search={search} {...props}/>)}
    </div>
}

//Treeview search component
export const TreeView = ({ data, className, placeholder, label, ...props }: TreeViewProps) => {

    const [ search, setSearch ] = useState<string>('');
    const treeRef = useRef(null);
    const showTreeWithSearch = props.showTreeOnSearch ? !!search : true;
    const showResults = props.hideNoFocus ? false : showTreeWithSearch;
    const [ showTree, setShowTree ] = useState<boolean>(showResults);

    const { mainTheme } = useTheme();

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
          if (treeRef.current && !treeRef.current.contains(event.target)) {
            setShowTree(false);
          }
        }

        if(showTree) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [showTree]);
    



    //Check all data and return only nodes that match search, include all depths of children
    const filteredData = search ? 
        data.filter((d) => {
            const checkChildren = (children: TreeViewData[]) => {
                if(!children) return false;
                return children.some((child) => {
                    if(child.name.toLowerCase().includes(search.toLowerCase())) return true;
                    return checkChildren(child.children);
                })
            }
            //check current node
            if(d.name.toLowerCase().includes(search.toLowerCase())) return true;
            return checkChildren(d.children);
        }) : data;

    return <div className={`flex flex-col ${className} max-w-[500px] ${mainTheme.primary}`}>
            {label && <label htmlFor={label} className="text-sm text-gray-500">{label}</label>}
            <div className="min-w-fit relative">
                { !props.hideSearch && 
                <Input
                    className="w-full"
                    placeholder={placeholder || `Filter Items`}
                    onFocus={() => setShowTree(true)}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    darkPlaceHolder
                    icon={defaultSearchIcon} />
                }

                { showTree && <div ref={treeRef} id="treeView" className={`absolute left-0 shadow rounded p-2 top-10 max-h-[75vh] overflow-auto mb-20 min-w-[500px] w-fit ${mainTheme.primary} z-10`} onBlur={() => setShowTree(false)}>
                    { filteredData.map((d) => <RenderNode key={d.name} node={d} depth={1} search={!!search} setShowTree={setShowTree} setSearch={setSearch} { ...props}/>) }
                    { filteredData.length === 0 && <div className="text-center">No Results Found</div>}
                </div> }
            </div>
    </div>

}


