import { Api } from '../../../../../shared/api/api';
import React, { Ref, useEffect, useRef, useState } from 'react';
import ApiTable from '../../../../../shared/api-table/api-table';
import { ClientGridName } from '../../../../../constant/constant';

import {
  getTime, isCheckDollerProcingNonZero,
} from '../../../../../utils/utils';
import { OpenOrdersTableField } from './open-orders-table-field';
import moment from 'moment';
import { WidgetHeader } from '../../../grid-widget-header/widget-header';
import { useUser } from '../../../../../context/UserContext';

export const OpenOrders = ({
  title,
  viewAllRouterLink,
  plusRouterLink,
  accessToken = [],
  expandContractGrid,
  isExpand
}: any) => {
  const [rowData, setRowData] = useState<any>(false);
  const ref: Ref<any> = useRef(null);
  const { customerInfo } = useUser();
  const headerRef: Ref<any> = useRef(null);
  const gridRef: Ref<any> = useRef(null);
  const [isApiCallProgress, setApiCallProgress] = useState(true);
  const getOrders = async () => {
    try {
      setApiCallProgress(true);

      const dataQuery = {
        CustomerId: customerInfo.CustomerId,
        EmailAddress: customerInfo.EmailAddress,
        UserId: customerInfo.UserId,
        AllowHidingUsersWebOrders: customerInfo.AllowHidingUsersWebOrders,
      };
      const locationId =
        customerInfo.RestrictUserByLocationID &&
        customerInfo.RestrictUserByLocationID.length
          ? customerInfo.RestrictUserByLocationID.toString()
          : null;
      let startDate, endDate;
      endDate = new Date().toISOString();
      startDate = moment(endDate).subtract(3, 'months').toISOString();
      const searchQuery = {
        StartDate: startDate,
        EndDate: endDate,
        LocationID: locationId,
        EmailAddress: customerInfo.EmailAddress,
        AllUserOrderView: customerInfo.AllUserOrderView,
        showOrderForDashboard: true,
      };

      const res = await Api.callAPI({
        url: '/api/v1/orders/history',
        method: 'put',
        body: { ...searchQuery, ...dataQuery },
        options: {
          isLoading: false,
        },
      });
      const arrData = res.data.map((data) => {
        return {
          ...data,
          OrderCost: isCheckDollerProcingNonZero(data.OrderCost.toFixed(2)) ? `$${data.OrderCost.toFixed(2)}` : '-',
          CreatedDate: getTime(data.CreatedDate),
        };
      });
      setRowData(arrData || []);
    } catch (e) {
    } finally {
      setApiCallProgress(false);
      // if (ref.current) {
      //   ref.current.hideGridLoader();
      // }
    }
  };

  const resetGrid = () => {
    if (ref.current) {
      ref.current.resetGrid();
    }
  };

  useEffect(() => {
    let dheight = 0;
    if (headerRef.current) {
      dheight += parseInt(headerRef.current.clientHeight);
    }

    if (gridRef.current) {
      gridRef.current.style.height = `calc(100% -  ${dheight}px)`;
    }
  }, [headerRef?.current]);

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        (async () => {
          await getOrders();
        })();
      }, 10);
    }
  }, []);

  const columns = OpenOrdersTableField();
  return (
    <>
      <div className={'w-full h-full pb-4'}>
        <div ref={headerRef}>
          <WidgetHeader
            viewAllRouterLink={viewAllRouterLink}
            plusRouterLink={plusRouterLink}
            title={title}
            accessToken={accessToken}
            resetGrid={resetGrid}
            isShowExportCsvBtn={false}
            isExpand={isExpand}
            expandContractGrid={expandContractGrid}
          />
        </div>
        <div ref={gridRef}>
          <ApiTable
            ref={ref}
            columns={columns}
            gridName={ClientGridName.DashboardOrders}
            rowData={rowData ? rowData : []}
            isLoading={isApiCallProgress}
            gridOptions={{
              pagination: false,
            }}
          />
        </div>
      </div>
    </>
  );
};
