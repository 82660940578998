import { useModal } from '../../../../../../context/ModalConext';
import {
  Form,
  FormItems,
  FormType,
  SubmitBtn,
} from '../../../../../../components/form';
import { Api } from '../../../../../../shared/api/api';
import { AddWhiteIcon } from '../../../../../../assets/svg/add-white-icon';
import { EAST_BRANCHES } from '../../../../../../constant/constant';
import { useUser } from '../../../../../../context/UserContext';

export const AddMeterReadingModalWrap = ({
  meterDetails,
  equipmentId,
  onMeterDataUpdate,
}: any) => {
  const { openModal, closeModal } = useModal();

  const { customerInfo } = useUser();
  const onSubmitMeterReadingEditHandler = async (value) => {
    const updateMeterDataAPIs = [];
    for (const meterType in value) {
      if (value[meterType] && !isNaN(value[meterType])) {
        const metersData = meterDetails.find(
          (m) => Number(m.MeterReadingID) === Number(meterType),
        );

        const postObj = {
          EquipmentID: equipmentId,
          MeterID: metersData.MeterID,
          OldMeterDisplay: Number(metersData.Display),
          NewMeterDisplay: Number(value[meterType]),
        };
        if (
          postObj.NewMeterDisplay > 0 &&
          postObj.OldMeterDisplay <= postObj.NewMeterDisplay
        ) {
          updateMeterDataAPIs.push(postObj);
        }
      }
    }
    await Api.callAPI({
      url: `/api/v1/equipments/meter-data`,
      method: 'post',
      body: updateMeterDataAPIs,
      options: {
        successMessage: 'Meter readings have been added successfully.',
        errorMessage: 'Error while adding meter detail.',
        isLoading: true,
      },
    });
    onMeterDataUpdate();
    closeModal();
  };
  const AddMeterReadingModal = () => {
    const formItem = meterDetails.map((item) => {
      return {
        name: item.MeterReadingID,
        label: item.MeterType,
        defaultValue: Math.round(item?.Display || 0),
        type: FormType.TEXT,
        required: true,
        min: item.Display,
        pattern: '^(0|[1-9][0-9]{0,11})$',
        errors: {
          pattern: 'Please provide valid meter reading value.',
          required: 'Please provide valid meter reading value.',
          min: (
            <>
              If your meter is less than previously entered, please contact{' '}
              <a
                className="text-indigo-500 hover:text-indigo-800 hover:underline"
                href={`mailto:${
                  customerInfo &&
                  customerInfo.BranchNumber &&
                  customerInfo.BranchNumber.trim() &&
                  EAST_BRANCHES.includes(customerInfo.BranchNumber.trim())
                    ? 'Meters-FTGEast@flextg.com'
                    : 'billing-group@flextg.com'
                }`}
              >
                {customerInfo &&
                customerInfo.BranchNumber &&
                customerInfo.BranchNumber.trim() &&
                EAST_BRANCHES.includes(customerInfo.BranchNumber.trim())
                  ? 'Meters-FTGEast@flextg.com'
                  : 'billing-group@flextg.com'}
              </a>{' '}
              to have it corrected
            </>
          ),
        },
      };
    });
    return (
      <>
        <div>
          <div>
            <Form onSubmit={onSubmitMeterReadingEditHandler}>
              <FormItems className="p-6" items={formItem} />
              <div className="flex justify-end py-4 px-6 border-t border-gray-200">
                <SubmitBtn className={`commonBtn`}>Update</SubmitBtn>
              </div>
            </Form>
          </div>
        </div>
      </>
    );
  };

  return (
    <button
      onClick={() =>
        openModal({
          content: <AddMeterReadingModal />,
          options: {
            title: 'Add new meter',
          },
        })
      }
    >
      <div className="bgColor">
        <AddWhiteIcon />
      </div>
    </button>
  );
};
