import { useEffect, useState } from "react";
import { FormItem, FormType } from "../../../../../components/form"
import { Api } from "../../../../../shared/api/api";
import { Customer, CustomerTokens, CustomerTokenSelection } from "../../../../../types/Customer";

interface CustomerConfigProps {
    customer: Partial<Customer> | null;
    show: boolean;
    defaultValues: any;
    roles: CustomerTokenSelection[];
}

export const CustomerConfig: React.FC<CustomerConfigProps> = ({
    customer,
    show,
    defaultValues,
    roles,
}) => {

    const isAllCustomer = customer === null;


    const [ addresses, setAddresses ] = useState([]);
    const [ regions, setRegions ] = useState([]);

    const modiRoles = [...roles];
    
    if(customer?.AllowReportView || isAllCustomer) modiRoles.push({ name: CustomerTokens.ViewReport, label: "View reports", value: CustomerTokens.ViewReport});
    if(customer?.CanViewGLCode || isAllCustomer) modiRoles.push({ name: CustomerTokens.EditCostCenter, label: "Edit GL/cost center", value: CustomerTokens.EditCostCenter});

    if(isAllCustomer) customer = { Id: -1 }
    const isSelectAll = customer.Id === -1;

    const getCompanyAddresses = async () => {

        const [ res, customerRegions ] = await Promise.all([
            Api.callAPI({
                url: `/api/v1/customers/${customer.Id}/address`,
                method: 'get',
            }),
            customer.AllowRestrictRegion ? Api.callAPI({
                url: `/api/v1/customers/${customer.Id}/regions`,
                method: 'get',
            }) : {data: []},
        ]);

        setAddresses(res?.data);
        setRegions(customerRegions?.data);
    }

    useEffect(() => {
        if(customer && !isSelectAll) getCompanyAddresses();
    }, []);

    const addressOptions = {
        options: addresses.map(address => ({ label: address.CustomerName, value: address.CustomerID })),
    };

    const regionOptions = {
        options: regions.map(region => ({ label: region.region, value: region.region })),
    };

    const convertDefaultValues = (valueArray) => {
        return valueArray?.map(value => {
            let thisAddress = addresses?.find(address => address.CustomerID == value);
            return { label: thisAddress?.CustomerName, value };
        });
    }

    const convertDefaultRegions = (valueArray) => {
        return valueArray?.map(value => {
            let thisRegion = regions?.find(region => region.region == value);
            return { label: thisRegion?.region, value };
        });
    }

    if(!addresses.length && customer.Id !== -1) return <></>;

    return (
        <>
        <div className={`${!show && 'hidden'}`} onClick={(e) => !show && e.stopPropagation()}>
            <div className="font-bold mb-2 text-gray-600">User Permissions <span className="text-red-400">*</span></div>
            <FormItem 
                formDef={{
                    name: `${customer.Id} Tokens`,
                    label: 'User permissions',
                    selectAllLabel: 'All permissions',
                    selectAll: true,
                    type: FormType.CHECKBOX,
                    radioOptions: modiRoles,
                    optionsMultiSelect: true,
                    defaultValue: defaultValues?.Tokens,
                    required: true,
                    errors: {
                        required: 'Please select at least one role.',
                    },
                    className: 'md:grid md:grid-cols-2 gap-2 xl:grid-cols-3',
                }}
            />
            {!isSelectAll && <>
            { customer.AllowRestrictRegion ? 
            <><div className="font-bold mb-2 text-gray-600 mt-4">Restrict Regions</div>
            <FormItem
                formDef={{
                    name: `${customer.Id} Regions`,
                    label: '',
                    defaultValue: convertDefaultRegions(defaultValues?.Regions),
                    type: FormType.SELECT,
                    isMenuTop: false,
                    selectMultiSelect: true,
                    selectShowSelectAllFilter: true,
                    selectShowSelectAll: true,

                    selectAllValue: 'All Regions',
                    ...regionOptions,
                }}
            /></> :
            <><div className="font-bold mb-2 text-gray-600 mt-4">Restrict Locations</div>
            <FormItem
                formDef={{
                    name: `${customer.Id} RestrictUserByLocationID`,
                    label: '',
                    defaultValue: convertDefaultValues(defaultValues?.RestrictUserByLocationID),
                    type: FormType.SELECT,
                    isMenuTop: false,
                    selectMultiSelect: true,
                    selectShowSelectAllFilter: true,
                    selectShowSelectAll: true,

                    selectAllValue: 'All Locations',
                    ...addressOptions,
                }}
            /></>} 
            <div className={`${!customer.AllowHidingUsersWebOrders && 'hidden'}`}>
                <div className="font-bold mb-2 text-gray-600 mt-4">Hide Others' Orders</div>
                <FormItem
                    formDef={{
                        name: `${customer.Id} HideOtherUserOrders`,
                        label: `Hide auto toner, phone, and other users' web orders`,
                        type: FormType.CHECKBOX,
                        defaultValue: defaultValues?.HideOtherUserOrders,
                    }}
                />
            </div>
            <div className="font-bold mb-2 text-gray-600 mt-4 border-t pt-4">Email Subscriptions</div>
            <FormItem
                formDef={{
                    name: `${customer.Id} PlaceOrderAddressID`,
                    label: 'Get order confirmation emails for all locations selected below (including non-portal orders)',
                    type: FormType.SELECT,
                    defaultValue: convertDefaultValues(defaultValues?.PlaceOrderAddressID),
                    isMenuTop: true,
                    selectMultiSelect: true,
                    selectShowSelectAll: true,
                    selectShowSelectAllFilter: true,
                    selectShowCheckboxes: true,
                    ...addressOptions,

                    selectAllValue: 'All Locations',
                }}
            />
            <FormItem
                formDef={{
                  name: `${customer.Id} SendMeTrackingWhenAvailable`,
                  label: 'Also send tracking for orders at locations selected above',
                  defaultValue: defaultValues?.SendMeTrackingWhenAvailable,
                  type: FormType.CHECKBOX,
                  className: 'mt-2 ml-6',
                }}
            />
            <FormItem
                formDef={{
                  name: `${customer.Id} LetMeKnowIfOrdersGetCanceled`,
                  label: 'Also send an update if an order is canceled at locations selected above',
                  defaultValue: defaultValues?.LetMeKnowIfOrdersGetCanceled,
                  type: FormType.CHECKBOX,
                  className: 'mt-2 ml-6',
                }}
            />

            <div className="mt-4"></div>
            <FormItem
                formDef={{
                    name: `${customer.Id} ServiceRequestAddressID`,
                    label: 'Get service request confirmations for all locations selected below (including non-portal requests)',
                    type: FormType.SELECT,
                    isMenuTop: true,
                    defaultValue: convertDefaultValues(defaultValues?.ServiceRequestAddressID),
                    selectMultiSelect: true,
                    selectShowSelectAll: true,
                    selectShowSelectAllFilter: true,
                    selectShowCheckboxes: true,
                    ...addressOptions,

                    selectAllValue: 'All Locations',
                }}
            />
            <FormItem
                formDef={{
                  name: `${customer.Id} SendCallUpdates`,
                  label: 'Also send any updates made to a request at locations selected above',
                    defaultValue: defaultValues?.SendCallUpdates,
                  type: FormType.CHECKBOX,
                  className: 'ml-6 mt-2',
                }}
            /></>}
        </div>
        </>
    );
}