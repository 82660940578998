import { NumericFormat } from 'react-number-format';
export const NumberInput = ({ value, onChange = null, ...rest }: any) => {
  return (
    <NumericFormat
      value={value}
      onValueChange={(values, sourceInfo) => {
        if (onChange) {
          onChange(values, sourceInfo);
        }
      }}
      {...rest}
    />
  );
};
