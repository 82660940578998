import React, { useEffect, useRef, useState } from 'react';
import { Api } from '../../../shared/api/api';
import ApiTable from '../../../shared/api-table/api-table-admin';
import { AdminGridName } from '../../../constant/constant';
import { SubHeader } from '../layout/footer/sub-header/sub-header';
import { LogTableField } from './access-logs-table-field';
import { LogSelection } from './components/LogSelection';
import { Form, FormItem, FormRef, FormType, SubmitBtn } from '../../../components/form';
import { ResetIcon } from '../../../assets/svg/reset-icon';


export const AccessLogs = () => {
  const [ selection, setSelection ] = useState(null);
  const ref = useRef(null);
  const formRef: FormRef = useRef(null);
  const [queryParams, setQueryParams] = useState({} as any);
  const [ loading, setLoading ] = useState(false);

  const getLogs = async (gridData) => {
    setLoading(true);
    try {
      const response: any = await Api.callAPI({
        url: '/api/v1/accesslogs',
        method: 'get',
        queryParams: {...queryParams, ...gridData},
        options: {
          // isLoading: true,
          showErrorMessage: false,
        },
      });
      setLoading(false);
      return response?.data;
    } catch (e) {
      setLoading(false);
    }
  };

  const reset = () => {
    if (ref.current) {
      ref.current.resetGrid();
    }
  };

  //Reset the grid when query params are submitted
  useEffect(() => {
    if(!loading) return;
    reset();
  }, [queryParams])

  const onSubmit = async (data) => {
    //Convert to unix time
    setLoading(true);
    if(data.startTime) data.startTime = new Date(data.startTime).valueOf();
    if(data.endTime) data.endTime = new Date(data.endTime).valueOf();

    const logQueryParams = selection ? {...selection.value, ...data} : data;
    setQueryParams(logQueryParams);
  }


  const columns = LogTableField();

  return (
    <>
      <section>
        <SubHeader
          leftSideElement={
            <>
              <h2 className="font-semibold text-gray-600"> Access Logs</h2>
            </>
          }
          righttSideElement={<>
            <button
              className={`flex items-center borderedBtn mr-4 linkText hover:text-black`}
              onClick={reset}
            >
              <ResetIcon />
              <div className={`ml-2 text-sm`}>Reset</div>
            </button>
          </>}
        />
        <div className="flex items-start flex-wrap">
          <LogSelection onChange={(e) => setSelection(e)} selected={selection}/>
          <Form onSubmit={onSubmit} formRef={formRef} className="flex xl:ml-2 xl:mt-0 mt-2 flex-wrap md:w-full">
          <div className='flex sm:mt-0 mt-2 md:gap-0 gap-4 md:w-auto w-full' >
            <FormItem
              className="md:mr-2 lg:w-[250px] w-1/2 -mt-1"
              formDef={{
                name: 'Type',
                label: 'Type',
                type: FormType.SELECT,
                placeholder: 'Select a Type',
                selectMultiSelect: false,
                selectShowCheckboxes: false,
                className: 'rmsc-slim',
                options: [
                  { label: 'Both', value: '' },
                  { label: 'Success', value: 'success' },
                  { label: 'Error', value: 'error' },
                ]
              }}
            />
            <FormItem
              className="md:mr-2 md:w-auto w-1/2"
              formDef={{
                name: 'User',
                label: 'User',
                type: FormType.TEXT,
                placeholder: 'User',
              }}
            />
            </div>
            <div className='flex sm:mt-0 mt-2 md:gap-0 gap-4 md:w-auto w-full' >
              <FormItem
                className="md:mr-2 md:w-auto w-1/2"
                formDef={{
                  name: 'startTime',
                  label: 'From Date',
                  type: FormType.DATE,
                  placeholder: 'Select A Date',
                }}
              />
              <FormItem
                className="md:mr-2 md:w-auto w-1/2 mb-2.5"
                formDef={{
                  name: 'endTime',
                  label: 'To Date',
                  type: FormType.DATE,
                  placeholder: 'Select A Date',
                }}
              />

            </div>
            <div className='flex'>    
              <SubmitBtn className="h-fit w-full rounded self-end justify-end  mb-[10px] mt-2 px-4 py-1" loading={loading}>Submit</SubmitBtn>
            </div>
          </Form>
        </div>
        <div className="mb-4 h-[1px] bg-stone-300 w-full"></div>
      </section>
        <ApiTable
          ref={ref}
          columns={columns}
          gridName={AdminGridName.AccessLogs}
          setExportBtnVisible={true}
          gridDataService={getLogs}
        /> 
    </>
  );
};
