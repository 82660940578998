import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  tenantLogo
} from "../../../utils/utils";
import { InputSearchIcon } from '../../../assets/svg/input-search-icon';
import { ArrowLeftIcon } from '../../../assets/svg/arrow-left-icon';
import { LocationIcon } from '../../../assets/svg/location-icon';
import { ArrowRightIcon } from '../../../assets/svg/arrow-right-icon';
import { RootState } from '../../../store/store';
import NotRowsFound from '../../../shared/api-table/no-rows-found/not-rows-found';
import { ComponentLoading } from '../../../shared/component-loader/component-loading';
import { Button } from '../../../components/ui';
import { useUser } from '../../../context/UserContext';
import { useCustomer } from '../context/CustomerContext';
import { useNavigate } from 'react-router-dom';

const SelectCompany = () => {

  const { selectCustomer: select, logout } = useUser();
  const { customers, loading } = useCustomer();

  const navigate = useNavigate();

  const [ page, setPage ] = useState(1);
  const [ search, setSearch ] = useState('');

  const filteredData = customers?.filter((customer: any) => {
    return customer.CustomerName.toLowerCase().includes(search.toLowerCase());
  });

  const offset = 50;

  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  )

  const selectCustomer = async (customerId: number) => {
    await select(customerId);
    navigate('/dashboard');
  }

  const renderOption = (customer: any) => {
    return (
      <div
        className="cursor-pointer rounded-lg py-3 px-4 mb-2 w-full border border-gray-300 flex item"
        onClick={() => selectCustomer(customer.CustomerID)}
      >
        <div className="w-8 mt-1">
          <LocationIcon />
        </div>
        <div className="w-full text-gray-900 text-sm px-2">
          {customer.CustomerName}
        </div>
        <div className="w-6 mt-1">
          <ArrowRightIcon />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="fch100 flex justify-center items-center sm:py-8 py-5 px-4 h-full min-h-[75vh]">
        <div className="2xl:w-1/4 lg:w-400 sm:w-3/4 m-auto bg-white shadow-lg p-8 pt-12">
          <div className={'content-center'}>
            <img
              alt={'Order Ahead Logo'}
              className="m-auto"
              src={tenantLogo(tenantName)}
            />
            <h1 className="mt-8 mb-6 text-center font-bold text-gray-700">
              Select Company to Log In
            </h1>
            {customers?.length > 3 ? (
              <div className="relative mb-2">
                <input
                  className="py-2 px-3 rounded-md w-full border border-gray-300"
                  type="text"
                  placeholder={'Search Company'}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="absolute top-3 right-3">
                  <InputSearchIcon />
                </div>
              </div>
            ) : (
              ''
            )}

            <div className="overflow-auto lg:max-h-[30vh] max-h-[33vh]">
              {customers.length > 0 ? (
                <>
                  {filteredData.map((customer: any, index: number) => {
                    //we were rendering thousands of customers, so we add pagination, which will speed up searching.
                    if(index >= page * offset) return null;

                    return (
                      <>
                        <div key={customer.CustomerID}>
                          {renderOption(customer)}
                        </div>
                      </>
                    );
                  })}
                  { (offset * page) < filteredData.length && <div className="flex justify-center items-center mt-8 w-full">
                    <Button fullWidth onClick={() => setPage(p => p + 1)} className="rounded p-2">
                      Load More
                    </Button>
                  </div> }
                </>
              ) : (
                <>
                  { loading ? <ComponentLoading isShowLoader={true}/> : <NotRowsFound /> }
                </>
              )}
            </div>

            <button
              type={'button'}
              className="flex justify-center items-center mt-8 w-full "
              onClick={() => {
                logout();
              }}
            >
              <ArrowLeftIcon />{' '}
              <div className={`ml-1 linkText hover:text-gray-900`}>
                Back to Login
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectCompany;
