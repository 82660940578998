export const UndoIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.92737 3.38676C9.34737 3.21343 8.70737 3.1001 8.0007 3.1001C4.80737 3.1001 2.2207 5.68676 2.2207 8.8801C2.2207 12.0801 4.80737 14.6668 8.0007 14.6668C11.194 14.6668 13.7807 12.0801 13.7807 8.88676C13.7807 7.7001 13.4207 6.59343 12.8074 5.67343"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4063 3.09961L8.40625 1.09961"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4063 3.09961L8.40625 5.09961"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
