import { Ref, useEffect, useRef, useState } from 'react';
import ApiTable from '../../../../../../../shared/api-table/api-table';
import { ClientGridName } from '../../../../../../../constant/constant';
import { LocationDevicesTableFields } from './location-device-table-fields';
import { Api } from '../../../../../../../shared/api/api';
import { useParams } from 'react-router-dom';
import { WidgetHeader } from '../../../../../grid-widget-header/widget-header';
import { useUser } from '../../../../../../../context/UserContext';

export const LocationDevices = ({
  title,
  viewAllRouterLink = null,
  plusRouterLink = null,
  expandContractGrid = null,
  isExpand = false,
}: any) => {
  const ref: Ref<any> = useRef(null);
  const gridRef: Ref<any> = useRef(null);
  const headerRef: Ref<any> = useRef(null);
  const { customerInfo } = useUser();
  let { locationId } = useParams();
  const columns = LocationDevicesTableFields();
  const [isApiCallProgress, setApiCallProgress] = useState(true);
  const [rowData, setRowData] = useState<any>(false);
  const gridBtnClassName = 'grid-export-btn-location-device-grid';
  const getLocationDevice = async () => {
    try {
      setApiCallProgress(true);
      const res = await Api.callAPI({
        url: `/api/v1/equipments/location/${locationId}/${customerInfo?.CustomerId}`,
        method: 'get',
        queryParams: {
          CustomerId: customerInfo?.CustomerId,
          LocationId: locationId,
        },
      });

      setRowData(res?.data || []);
    } catch (e) {
    } finally {
      setApiCallProgress(false);
    }
  };

  const resetGrid = () => {
    if (ref.current) {
      ref.current.resetGrid();
    }
  };

  const exportCSV = () => {
    if (ref.current) {
      ref.current.exportCSV();
    }
  };

  useEffect(() => {
    let dheight = 0;
    if (headerRef.current) {
      dheight += parseInt(headerRef.current.clientHeight);
    }

    if (gridRef.current) {
      gridRef.current.style.height = `calc(100% -  ${dheight}px)`;
    }
  }, [headerRef?.current]);

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        (async () => {
          await getLocationDevice();
        })();
      }, 10);
    }
  }, []);

  return (
    <div className={'w-full h-full pb-4'}>
      <div ref={headerRef}>
        <WidgetHeader
          viewAllRouterLink={viewAllRouterLink}
          plusRouterLink={plusRouterLink}
          title={title + ` (${rowData?.length ? rowData?.length : 0})`}
          resetGrid={resetGrid}
          exportCSV={exportCSV}
          gridBtnClassName={gridBtnClassName}
          expandContractGrid={expandContractGrid}
          isExpand={isExpand}
        />
      </div>
      <div ref={gridRef}>
        <ApiTable
          ref={ref}
          columns={columns}
          gridName={ClientGridName.LocationDevice}
          rowData={rowData ? rowData : []}
          isLoading={isApiCallProgress}
          gridBtnClassName={gridBtnClassName}
          gridOptions={{
            pagination: false,
          }}
        />
      </div>
    </div>
  );
};
