import React from 'react'
import './index.scss';

export default function SSOLoginError() {
    return (
        <div className="message-container">
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <div className="message">
                Error in login, Please try again.
            </div>
        </div>

    )
}
