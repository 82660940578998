import React, { Ref, useEffect, useRef, useState } from 'react';
import { Api } from '../../../../shared/api/api';
import { DeviceTableField } from './device-table-field';
import ApiTable from '../../../../shared/api-table/api-table';
import {
  ClientGridName,
  ClientStorageKey, TENANT
} from "../../../../constant/constant";
import { ReactSelectCompo } from '../../../../components/ui/react-select/react-select';

import { ResetIcon } from '../../../../assets/svg/reset-icon';
import { ExcelIcon } from '../../../../assets/svg/excel-icon';
import { filter } from 'lodash';
import storage from '../../../../utils/storage/storage';

import { Button } from '../../../../components/ui';
import { DragIcon } from '../../../../assets/svg/drag-icon';
import { DeviceSelectionIcon } from '../../../../assets/svg/device-selection-icon';
import { LocationUpdateDialog } from './location-update-dialog/location-update-dialog';
import { LocationUpdateRemark } from './location-remark-update-dialog/location-remark-update-dialog';
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useUser } from '../../../../context/UserContext';
import { CustomerTokens } from '../../../../types/Customer';

const Device = () => {
  const ref: Ref<any> = useRef(null);
  const { customerInfo, hasPermission } = useUser();
  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );
  const [isShowExportCsvBtn, setExportCsvBtn] = useState(false);
  const [selectLocations, setSelectedLocations] = useState<any>([]);
  const [allLocationIds, setAllLocationIds] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [isDropDownVisible, setDropdownVisible] = useState(false);
  const [locationDropDownValue, setLocationDropDownValue] = useState(null);
  const [locationDropdownOption, setLocationDropdownOption] =
    useState<any>(null);
  const locationModalRef = useRef(null);
  const locationRemarkModalRef = useRef(null);

  // get device data for grid
  const getDeviceDetails = async (filterModel: any) => {
    try {
      let searchParams = {};
      let modifyQueryParamsObject: any = {};
      if (filterModel && Object.keys(filterModel).length) {
        searchParams = { ...filterModel };
        modifyQueryParamsObject.EquipmentNumber = filterModel.EquipmentNumber
          ? filterModel.EquipmentNumber
          : '';
        modifyQueryParamsObject.StartLastOrderDateBlack =
          filterModel.StartLastOrderDateBlack &&
          filterModel.StartLastOrderDateBlack
            ? filterModel.StartLastOrderDateBlack
            : '';
        modifyQueryParamsObject.EndLastOrderDateBlack =
          filterModel.EndLastOrderDateBlack && filterModel.EndLastOrderDateBlack
            ? filterModel.EndLastOrderDateBlack
            : '';
        modifyQueryParamsObject.StartLastReplaceBlack =
          filterModel.StartLastReplaceBlack && filterModel.StartLastReplaceBlack
            ? filterModel.StartLastReplaceBlack
            : '';
        modifyQueryParamsObject.EndLastReplaceBlack =
          filterModel.EndLastReplaceBlack && filterModel.EndLastReplaceBlack
            ? filterModel.EndLastReplaceBlack
            : '';
        modifyQueryParamsObject.StartLastOrderDateYellow =
          filterModel.StartLastOrderDateYellow &&
          filterModel.StartLastOrderDateYellow
            ? filterModel.StartLastOrderDateYellow
            : '';
        modifyQueryParamsObject.EndLastOrderDateYellow =
          filterModel.EndLastOrderDateYellow &&
          filterModel.EndLastOrderDateYellow
            ? filterModel.EndLastOrderDateYellow
            : '';
        modifyQueryParamsObject.StartLastReplaceCyan =
          filterModel.StartLastReplaceCyan && filterModel.StartLastReplaceCyan
            ? filterModel.StartLastReplaceCyan
            : '';
        modifyQueryParamsObject.EndLastReplaceCyan =
          filterModel.EndLastReplaceCyan && filterModel.EndLastReplaceCyan
            ? filterModel.EndLastReplaceCyan
            : '';
        modifyQueryParamsObject.StartLastOrderDateCyan =
          filterModel.StartLastOrderDateCyan &&
          filterModel.StartLastOrderDateCyan
            ? filterModel.StartLastOrderDateCyan
            : '';
        modifyQueryParamsObject.EndLastOrderDateCyan =
          filterModel.EndLastOrderDateCyan && filterModel.EndLastOrderDateCyan
            ? filterModel.EndLastOrderDateCyan
            : '';
        modifyQueryParamsObject.StartLastReplaceYellow =
          filterModel.StartLastReplaceYellow &&
          filterModel.StartLastReplaceYellow
            ? filterModel.StartLastReplaceYellow
            : '';
        modifyQueryParamsObject.EndLastReplaceYellow =
          filterModel.EndLastReplaceYellow && filterModel.EndLastReplaceYellow
            ? filterModel.EndLastReplaceYellow
            : '';
        modifyQueryParamsObject.StartLastOrderDateMagenta =
          filterModel.StartLastOrderDateMagenta &&
          filterModel.StartLastOrderDateMagenta
            ? filterModel.StartLastOrderDateMagenta
            : '';
        modifyQueryParamsObject.EndLastOrderDateMagenta =
          filterModel.EndLastOrderDateMagenta &&
          filterModel.EndLastOrderDateMagenta
            ? filterModel.EndLastOrderDateMagenta
            : '';
        modifyQueryParamsObject.StartLastReplaceMagenta =
          filterModel.StartLastReplaceMagenta &&
          filterModel.StartLastReplaceMagenta
            ? filterModel.StartLastReplaceMagenta
            : '';
        modifyQueryParamsObject.EndLastReplaceMagenta =
          filterModel.EndLastReplaceMagenta && filterModel.EndLastReplaceMagenta
            ? filterModel.EndLastReplaceMagenta
            : '';
        modifyQueryParamsObject.SerialNumber = filterModel.SerialNumber
          ? filterModel.SerialNumber
          : '';
        modifyQueryParamsObject.TrackingNumbersBlack =
          filterModel.TrackingNumbersBlack
            ? filterModel.TrackingNumbersBlack
            : '';
        modifyQueryParamsObject.TrackingNumbersYellow =
          filterModel.TrackingNumbersYellow
            ? filterModel.TrackingNumbersYellow
            : '';
        modifyQueryParamsObject.TrackingNumbersCyan =
          filterModel.TrackingNumbersCyan
            ? filterModel.TrackingNumbersCyan
            : '';
        modifyQueryParamsObject.TrackingNumbersMagenta =
          filterModel.TrackingNumbersMagenta
            ? filterModel.TrackingNumbersMagenta
            : '';

        modifyQueryParamsObject.LevelBlack = filterModel.levelBlack
          ? filterModel.levelBlack
          : '';
        modifyQueryParamsObject.LevelYellow = filterModel.levelYellow
          ? filterModel.levelYellow
          : '';
        modifyQueryParamsObject.LevelCyan = filterModel.levelCyan
          ? filterModel.levelCyan
          : '';
        modifyQueryParamsObject.LevelMagenta = filterModel.levelMagenta
          ? filterModel.levelMagenta
          : '';
        modifyQueryParamsObject.Model = filterModel.Model
          ? filterModel.Model
          : '';
        modifyQueryParamsObject.CustomerName = filterModel.CustomerName
          ? filterModel.CustomerName
          : '';
        modifyQueryParamsObject.City = filterModel.City ? filterModel.City : '';
        modifyQueryParamsObject.State = filterModel.State
          ? filterModel.State
          : '';
        modifyQueryParamsObject.IPAddress = filterModel.IPAddress
          ? filterModel.IPAddress
          : '';
        modifyQueryParamsObject.Location = filterModel.Location
          ? filterModel.Location
          : '';
        modifyQueryParamsObject.LocationRemarks = filterModel.LocationRemarks
          ? filterModel.LocationRemarks
          : '';
        modifyQueryParamsObject.CoveredOnContract =
          filterModel.CoveredOnContract ? filterModel.CoveredOnContract : '';
        modifyQueryParamsObject.Address = filterModel.ARCustomerAddress
          ? filterModel.ARCustomerAddress
          : '';
      }
      let locations: any = allLocationIds;
      if (selectLocations && selectLocations?.length) {
        locations = selectLocations.join(',');
      } else {
        locations =
          customerInfo?.RestrictUserByLocationID &&
          customerInfo?.RestrictUserByLocationID?.length
            ? customerInfo?.RestrictUserByLocationID &&
            customerInfo?.RestrictUserByLocationID?.join(',')
            : null;
      }

      searchParams = {
        CustomerID: customerInfo?.CustomerId,
        LocationID: locations,
        ...modifyQueryParamsObject,
        ...searchParams,
      };

      const response = await Api.callAPI({
        url: `/api/v1/equipments`,
        method: 'put',
        body: {
          ...searchParams,
        },
        options: {
          isLoading: false,
        },
      });
      setTotalRows(response?.data?.totalRows || 0);
      return response.data;
    } catch (e) {}
  };
  const isControlPressed = (event: any) => {
    if (!event.metaKey && event.ctrlKey) {
      event.metaKey = event.ctrlKey;
    }
    return event.metaKey;
  };

  const localFilterValue = (locationArr: any) => {
    if (storage.getItem(ClientStorageKey.DeviceFilters)) {
      if (locationArr && locationArr.length) {
        let filterData: any = [];
        const deviceFiltesData: any = storage.getJSONItem(
          ClientStorageKey.DeviceFilters,
        );
        const locationIds = deviceFiltesData?.location
          ? deviceFiltesData?.location.split(',')
          : [];

        locationIds.map((locationId: any) => {
          const filterRecord = locationArr.filter(
            (lData: any) => lData.value.toString() === locationId,
          );
          if (filterRecord) {
            filterData = [...filterData, ...filterRecord];
          }
        });
        const convertDataFormDropdown: any = filterData.map((data: any) => {
          return {
            value: data.value,
            label: data.label,
          };
        });

        return {
          locations: convertDataFormDropdown,
        };
      }
    }
    return [];
  };

  // get address fpr shipping address
  const getShippingAddress = async () => {
    let locationArrIds: any = [];
    let locationArr = [];

    try {
      const response = await Api.callAPI({
        url: `/api/v1/customers/${customerInfo?.CustomerId}/address`,
        method: 'get',
        body: {
          Active: true,
        },
        options: {
          isLoading: false,
        },
      });

      if (
        customerInfo?.RestrictUserByLocationID &&
        customerInfo?.RestrictUserByLocationID?.length
      ) {
        customerInfo?.RestrictUserByLocationID.forEach((location: any) => {
          const locations = filter(response.data, { CustomerID: location });
          locationArrIds.push(locations[0].CustomerID);
          locationArr.push(locations[0]);
        });
        setAllLocationIds(locationArrIds.join(','));
      } else {
        const customerIdArr = response?.data
          ? response?.data.map(({ CustomerID }: any) => CustomerID)
          : [];
        locationArr = response?.data;
        setAllLocationIds(customerIdArr.join(','));
      }
      const locationLabelValueArr: any = locationArr.map((data: any) => {
        return {
          value: data.CustomerID,
          label: data.SearchBy,
        };
      });

      setLocationDropdownOption([...locationLabelValueArr]);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const filterData: any = localFilterValue(
        locationArr.map((data: any) => {
          return {
            value: data.CustomerID,
            label: data.SearchBy,
          };
        }),
      );

      setLocationDropDownValue(
        filterData && filterData?.locations ? filterData?.locations : [],
      );

      setSelectedLocations(
        filterData && filterData.locations
          ? filterData.locations.map(({ value }: any) => value)
          : null,
      );

      setDropdownVisible(locationLabelValueArr?.length > 1);

      setTimeout(() => {
        storage.removeItem(ClientStorageKey.DeviceFilters);
      }, 2000);

      return {
        data: locationArr,
      };
    } catch (e) {
    } finally {
    }
  };
  const resetGrid = () => {
    if (ref.current) {
      ref.current.resetGrid();
    }
  };
  const exportCSV = async () => {
    if (ref.current) {
      await ref.current.exportCSV();
    }
  };

  const openLocationModal = (equipmentId) => {
    if (locationModalRef.current) {
      locationModalRef.current.openLocationUpdateModal(equipmentId);
    }
  };

  const openLocationRemarkModal = (equipmentId) => {
    if (locationRemarkModalRef.current) {
      locationRemarkModalRef.current.openLocationRemarkModal(equipmentId);
    }
  };

  const columns = DeviceTableField({
    isControlPressed: isControlPressed,
    selectedLocationIds: selectLocations,
    openLocationModal: openLocationModal,
    openLocationRemarkModal: openLocationRemarkModal,
  });

  const refreshGrid = () => {
    if (ref.current) {
      ref.current.refreshGrid();
    }
  };
  useEffect(() => {
    getShippingAddress().then();
  }, []);

  return (
    <>
      <div>
        <h1 className="mb-4 text-2xl font-medium">Your Devices</h1>
        <div className="flex w-full items-center pb-4 border-b border-gray-200">
          <div className="sm:w-96 w-full locationSelect">
            {isDropDownVisible &&
            locationDropDownValue &&
            locationDropdownOption ? (
              <ReactSelectCompo
                options={locationDropdownOption}
                value={locationDropDownValue}
                optionKeyMapObject={{
                  labelKey: 'SearchBy',
                  valueKey: 'CustomerID',
                }}
                name={'select location '}
                label={'Search Location'}
                selectAllValue='All Locations'
                hasFilterSelectAll={true}
                labelledBy={'location'}
                overrideStrings={{ selectSomeItems: 'Search Location' }}
                onClose={(option: any) => {
                  if (option && option?.length) {
                    const locationIdsArr = option.map(
                      ({ value }: any) => value,
                    );
                    setSelectedLocations(locationIdsArr);
                  } else {
                    setSelectedLocations(null);
                  }
                  setTimeout(() => {
                    if (ref.current) {
                      ref.current.refreshGrid();
                    }
                  }, 100);
                }}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="lg:flex items-center justify-between my-4 w-full">
          <div>
            <span className="md:text-lg text-base font-semibold">
              {totalRows} {totalRows > 1 ? 'Devices' : 'Device'}
            </span>
          </div>
          <div className="sm:flex items-center lg:mt-0 mt-2 fourButton">
            <div className="flex">
              <button
                className={`flex items-center borderedBtn lg:ml-4 linkText hover:text-black`}
                onClick={resetGrid}
              >
                <ResetIcon />
                <div className={`ml-2 text-sm`}>Reset</div>
              </button>
              {isShowExportCsvBtn ? (
                <>
                  <button
                    className={`borderedBtn flex items-center ml-4 linkText hover:text-black`}
                    onClick={exportCSV}
                  >
                    <ExcelIcon />
                    <div className="ml-2 text-sm">Export CSV</div>
                  </button>
                </>
              ) : (
                ''
              )}
            </div>
            {
              hasPermission(CustomerTokens.RemoveDeviceLocations) ? (
                <div className="flex sm:mt-0 mt-2">
                  <Button
                    ghost
                    to={'/customer/devices/add-device'}
                    className={`borderedBtn sm:ml-4 linkText hover:text-black`}
                  >
                    <DeviceSelectionIcon />
                    <div className={`ml-2 text-sm`}>Add Device</div>
                  </Button>
                  {
                    tenantName !== TENANT.MARIMON ? (<Button
                      ghost
                      to={'/customer/devices/move-device-request'}
                      className={`borderedBtn ml-4 linkText hover:text-black`}
                    >
                      <DragIcon />
                      <div className={`ml-2 text-sm`}>Device Move Request</div>
                    </Button>) : ('')
                  }

                </div>
              ) : ('')
            }
          </div>
        </div>
      </div>

      <ApiTable
        key={`DeviceGrid:${selectLocations ? selectLocations.length : 0}`}
        ref={ref}
        gridName={ClientGridName.Device}
        columns={columns}
        gridDataService={getDeviceDetails}
        setExportBtnVisible={setExportCsvBtn}
        gridOptions={{}}
        exportCSVQueryParams={{
          rows: 10000000,
        }}
      />
      <LocationUpdateDialog ref={locationModalRef} refreshGrid={refreshGrid} />
      <LocationUpdateRemark
        ref={locationRemarkModalRef}
        refreshGrid={refreshGrid}
      />
    </>
  );
};

export default Device;
