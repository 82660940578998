import React, { useRef, useState } from 'react';
import { SubHeader } from '../../layout/footer/sub-header/sub-header';

import ApiTable from '../../../../shared/api-table/api-table-admin';
import { AdminGridName } from '../../../../constant/constant';

import { Api } from '../../../../shared/api/api';
import { SearchCustomerTableField } from './search-customer-table-field';
import { Input } from "../../../../components/ui";

const SearchCustomer = () => {
  const [rowData, setRowData] = useState<any[] | null>(null);
  const ref = useRef(null);
  const [isApiCallProgress, setApiCallProgress] = useState(true);
  const [isShowGrid, setIsShowGrid] = useState(false);
  const [searchCustomerInput, setSearchCustomerInput] = useState('');
  const getCompanies = async ({ body }: any) => {
    try {
      setApiCallProgress(true)

      const [ eAutoCustomers, linkedCustomers ] = await Promise.all([
        Api.callAPI({
          url: '/api/v1/customers',
          method: 'get',
          options: {
            isLoading: true,
            showErrorMessage: false,
          },
        }),
      Api.callAPI({
        url: `/api/v1/customers/search/${body.data}`,
        method: 'get',
        options: {
          isLoading: true,
          showErrorMessage: false,
        },
      })]);

      // loop through linkedCustomers, check if the Id matches any object inside of eAutoCustomers with a corresponding CustomerId
      // if it does, remove it from linkedCustomers
      let filteredCustomers = linkedCustomers?.data.filter((linkedCustomer: any) => {
        return !eAutoCustomers?.data.some((eAutoCustomer: any) => {
          return linkedCustomer.Number === eAutoCustomer.CustomerNumber
        })
      })

      setRowData(filteredCustomers);
    } catch (e) {} finally {
      setApiCallProgress(false)
    }
  };

  const searchCustomer = async () => {
    if (searchCustomerInput.trim() !== '') {
      setIsShowGrid(true)
      await getCompanies({
        body: {
          data: searchCustomerInput,
        },
      });
    }
  };
  return (
    <>
      <SubHeader
        leftSideElement={
          <>
            <h2 className="font-semibold text-gray-600"> Search Customers</h2>
          </>
        }
        righttSideElement={
          <>
            <Input
              type={'text'}
              onEnter={searchCustomer}
              onChange={(e) => {
                setSearchCustomerInput(e.target.value);
              }}
              value={searchCustomerInput}
              placeholder='Search on Customers Name'
              className='w-96 px-2 rounded-md mr-2'
              icon={null}
            />
            <button className='borderedBtn linkText hover:text-black' onClick={searchCustomer}>Search</button>
          </>
        }
      />
      {isShowGrid ? (
        <ApiTable
          ref={ref}
          columns={SearchCustomerTableField()}
          gridName={AdminGridName.SearchCustomer}
          isLoading={isApiCallProgress}
          rowData={rowData ? rowData : []}
          isStoreFilterSortPreferance={false}
          gridOptions={{
            pagination: false,
          }}
        />
      ) : ('')}
    </>
  );
};

export default SearchCustomer;
