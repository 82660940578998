import { Button } from "../../../components/ui";
import { Card } from "../../../components/ui/Card";
import { useModal } from "../../../context/ModalConext";

export const ModalDemo = () => {
    const { openModal, closeModal } = useModal();

    const ModalContent = () => {
        return <div className="flex flex-col gap-2">
            <Button onClick={() => {}}>Click Me</Button>
        </div>
    }

    const ModalFooter = () => {
        return <div className="flex gap-2">
            <Button onClick={closeModal}>Cancel</Button>
            <Button>Save</Button>
        </div>
    }

    return <Card className="w-[50vw] mt-4">
        <Button onClick={() => openModal({
            content: <ModalContent />,
            options: {
                title: "Modal Title",
                footer: <ModalFooter />,
                onOpen: () => {},
            }
        })}>
            View Add Device In Modal
        </Button>
    </Card>
}
