export const ExpandIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 6L14 2L10 2" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2 10L2 14L6 14" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 2L9 7" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 9L3 13" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
