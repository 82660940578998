import React, { Ref, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../store/loading/loading.action';
import { Api } from '../../../shared/api/api';
import ApiTable from '../../../shared/api-table/api-table';
import { ComponentLoading } from '../../../shared/component-loader/component-loading';

export const Demo = () => {
  const dispatch = useDispatch();

  const showLoading = () => {
    dispatch(showLoader());
    setTimeout(() => {
      dispatch(hideLoader());
    }, 1000);
  };

  const getData1 = async (queryParam: any) => {
    const res = await Api.callAPI({
      url: '/api/v1/equipments',
      method: 'put',
      body: {
        rows: 15,
        pageNo: 1,
        CustomerID: '112534',
        LocationID: null,
        params: {
          startRow: 0,
          endRow: 15,
          sortModel: [],
          filterModel: {},
        },
        email: 'TEJAS.VORA@RAPIDOPS.COM',
        ...queryParam,
      },
      options: {
        showErrorMessage: false,
      },
    });

    return res;
  };

  const ref: Ref<any> = useRef(null);
  return (
    <>
      <button
        onClick={() => {
          if (ref.current) {
            ref.current.resetGrid();
          }
        }}
      >
        Reset Grid
      </button>
      <button
        onClick={() => {
          if (ref.current) {
            ref.current.exportCSV();
          }
        }}
      >
        Export
      </button>

      <button
        onClick={() => {
          if (ref.current) {
            ref.current.resetFilter();
          }
        }}
      >
        Reset Filter
      </button>

      <ApiTable
        ref={ref}
        gridOptions={{
          loadingOverlayComponent: () => {
            return ComponentLoading({ isShowLoader: true });
          },
        }}
        columns={[
          {
            headerName: 'EquipmentNumber',
            field: 'EquipmentNumber',
          },
          { headerName: 'SerialNumber', field: 'SerialNumber' },
          { headerName: 'Location', field: 'Location' },
        ]}
        gridDataService={getData1}
        gridName={'test'}
      />
      <button
        className={'btn'}
        onClick={() => {
          showLoading();
        }}
      >
        Show Loader
      </button>
    </>
  );
};
