import React, { useRef } from 'react';
import packageJson from '../../../../../package.json';
import { displayTenantNameName } from '../../../../utils/utils';
import { useSelector } from 'react-redux';
import FeedbackForm from '../../feedback-form/feedback-form';
const Footer = () => {
  
  const tenantName: any = useSelector(
    (state: any) => state.tenantState.tenantName,
  );

  const feedbackFormRef = useRef(null);

  return (
    <>
      <footer className="text-gray-600">
        <div className={'m-auto flex justify-center'}>
          <div className="md:flex w-full xl:px-28 px-4 border-t border-gray-200 py-4  ">
            <div className="md:pr-4 md:w-3/4 md:text-left text-center">
              <p className="text-sm text-gray-600">
                We’d love to hear about your experience with the portal, and anything that might help us improve it.
              </p>
              <div role="button" className="linkText text-sm" onClick={() => feedbackFormRef?.current?.openFeedBackForm()}>Please click here to send us your feedback</div>
              <FeedbackForm ref={feedbackFormRef} title='Feedback'/>
            </div>
            <div className=" md:w-1/4 flex md:justify-end justify-center md:mt-0 mt-4">
              <div className='text-sm text-gray-600'>
                {new Date().getFullYear() + ' '}
                &copy; {' ' + displayTenantNameName(tenantName) + '.'}
                {' v' + packageJson.version}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
