import React, { forwardRef, useImperativeHandle } from 'react';
import { useModal } from '../../../context/ModalConext';
import {
  Form,
  FormItem,
  FormItems,
  FormType,
  SubmitBtn,
} from '../../../components/form';
import { DATE_FORMAT } from '../../../constant/constant';
import moment from 'moment';
import { Api } from '../../../shared/api/api';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../store/loading/loading.action';
import { useUser } from '../../../context/UserContext';

const FeedbackForm = ({ from = 'feedback', title = 'Feedback' }, ref) => {
  const dispatch = useDispatch();
  const { customerInfo: customer } = useUser();
  const { openModal, closeModal } = useModal();
  const handleSubmit = async (values: any) => {
    dispatch(showLoader());
    const { ccMyself, message, about } = values;

    try {
      const feedbackData = {
        Feedback: message,
        CCMyself: ccMyself,
        UserName: customer?.FirstName,
        EmailAddress: customer?.EmailAddress,
        CustomerName: customer?.CustomerName,
        CustomerID: customer?.CustomerId,
        UserPhone: customer?.UserPhone,
        From: from,
        Date: moment(new Date().toISOString()).format(DATE_FORMAT),
        SiteUrl: window.location.href,
        About: about && about.length && about[0] ? about[0] : null,
      };

      await Api.callAPI({
        url: `/api/v1/users/feedback`,
        method: 'post',
        body: feedbackData,
        options: {
          isLoading: true,
          successMessage: 'Feedback has been sent successfully.',
          errorMessage: 'Error while getting feedback.',
        },
      });
      closeModal();
    } catch (e) {}
    dispatch(hideLoader());
  };
  const FeedbackFormModalContent = () => {
    return (
      <Form onSubmit={handleSubmit}>
        <div className="p-6">
          {from !== 'customerCareFeedback' ? (
            <FormItem
              formDef={{
                name: 'about',
                label: 'About',
                type: FormType.SELECT,
                selectShowCheckboxes: false,
                selectHideSearch: true,
                isMenuTop: false,
                required: true,
                selectMultiSelect: false,
                ClearSelectedIcon: null,
                options: [
                  { label: 'Orders', value: 'Orders' },
                  { label: 'Service', value: 'Service' },
                  { label: 'Portal Feedback', value: 'Portal' },
                  { label: 'Other', value: 'Other' },
                ],
                errors: {
                  required: 'Please select about',
                },
              }}
            ></FormItem>
          ) : (
            ''
          )}
          <FormItems
            items={[
              {
                name: 'message',
                label: '',
                type: FormType.TEXTAREA,
                placeholder: 'Write your message here...',
                required: true,
                rows: 5,
                errors: {
                  required: 'Please enter your feedback message.',
                },
                maxLength: 200,
              },
              {
                name: 'ccMyself',
                label: 'CC Myself',
                type: FormType.CHECKBOX,
                className: 'mt-6',
              },
            ]}
          />
        </div>
        <div className="flex justify-end py-4 px-6 border-t border-gray-200">
          <button className={`borderedBtn mr-2 linkText hover:text-black`} type={'button'} onClick={() => closeModal()}>
            Cancel
          </button>
          <SubmitBtn className={`commonBtn`}>Send</SubmitBtn>
        </div>
      </Form>
    );
  };

  const openFeedBackFormModal = () => {
    openModal({
      content: (
        <>
          <FeedbackFormModalContent />
        </>
      ),
      options: {
        title: title,
        footer: <></>,
      },
    });
  };
  useImperativeHandle(ref, () => ({
    openFeedBackForm() {
      openFeedBackFormModal();
    },
  }));
  return <></>;
};

export default forwardRef(FeedbackForm);
