import { createContext, useContext, useEffect, useReducer } from 'react';
import { useFlexLayer } from '../../../hooks/useFlexlayer';
import { Popup as PopupComponent } from '../layout/popup/popup';


interface PopupContextInterface {
    popup: {
        submittedOn?: Date;
    } | null;
}

interface PopupContextFunctions extends PopupContextInterface {
    dismissPopup: () => void;
}

const initalPopupContext: PopupContextInterface = {
    popup: {
        submittedOn: null        
    }
};

const PopupContext = createContext<PopupContextFunctions | undefined>(
  undefined,
);

const popupStorage = JSON.parse(localStorage.getItem('popup')) || { hide: false, date: null};
const isDismissed = popupStorage.hide && new Date(popupStorage.date).getTime() > new Date().getTime() - 30 * 24 * 60 * 60 * 1000;

// 1% chance of showing popup
const getRandomChance = Math.floor(Math.random() * 100) + 1 <= 5;

export const PopupProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer((PopupState: PopupContextInterface, update: Partial<PopupContextInterface>) => {
        return { ...PopupState, ...update };
    }, initalPopupContext);

    const { flexLayer } = useFlexLayer();

    const dismissPopup = () => {
        //add localstorage so it doesn't popup again

        localStorage.setItem('popup', JSON.stringify({
            hide: true,
            date: new Date().toISOString()
        }));

        dispatch({ popup: {submittedOn: new Date()} });
    }

    const loadPopupInfo = async () => {
        if(isDismissed) return dispatch({ popup: null });
        const { data } = await flexLayer('/popup');
        const isMoreThanAMonthAgo = new Date(data?.submittedOn).getTime() < new Date().getTime() - 30 * 24 * 60 * 60 * 1000;
        console.log({ popup: data && !isMoreThanAMonthAgo ? data : null })
        dispatch({ popup: data?.submittedOn && !isMoreThanAMonthAgo ? data : null });
    }

    useEffect(() => {
        if(getRandomChance) {
            loadPopupInfo();
        }
    }, []);


  return (
    <PopupContext.Provider
      value={{
        ...state,
        dismissPopup,
      }}
    >
        { (!isDismissed && !state.popup) &&  <PopupComponent /> }
        {children}
    </PopupContext.Provider>
  );
};

export function usePopup() {
  const context = useContext<PopupContextFunctions | undefined>(PopupContext);
  if (context === undefined) {
    throw new Error('usePopup must be used within a PopupProvider');
  }
  return context;
}
