export const FeedbackIcon = () => {
  return (
    <svg
      width="24"
      height="21"
      viewBox="0 0 32 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 21H2C1.5 21 1 20.6 1 20V2C1 1.5 1.5 1 2 1H30C30.5 1 31 1.5 31 2V20C31 20.6 30.5 21 30 21H12"
        stroke="#555"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21L7 27V21"
        stroke="#555"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.69922 12.5002L16.4992 5.7002"
        stroke="#555"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3 8.5L12.5 15.3"
        stroke="#555"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5996 5.6C17.3996 4.8 18.5997 4.8 19.3997 5.6C20.1997 6.4 20.1997 7.5998 19.3997 8.3998"
        stroke="#555"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 15.3L8 17L9.70001 12.5"
        stroke="#555"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 17H16"
        stroke="#555"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
