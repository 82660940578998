import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { TENANT } from '../../../../constant/constant';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useUser } from '../../../../context/UserContext';
import { CustomerTokens } from '../../../../types/Customer';

const Reports = () => {
  const { customerInfo, hasPermission } = useUser();
  const [isOpenDeviceReportsOptions, setIsOpenDeviceReportsOptions] =
    useState(false);
  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );
  const navigate = useNavigate();
  const [isOpenFMAuditOptions, setFMAuditOptions] = useState(false);

  if (
    !(
      customerInfo.AllowReportView &&
      hasPermission(CustomerTokens.ViewReport)
    ) ||
    tenantName === TENANT.SANDBOX
  ) {
    navigate('/customer/pageNotFound');
  }
  useEffect(() => {
    setIsOpenDeviceReportsOptions(false);
    setFMAuditOptions(false);
  }, [location.pathname]);
  const options = [
    {
      name: 'Device Reports',
      toggleFunction: (val) => setIsOpenDeviceReportsOptions(val),
      isOpen: isOpenDeviceReportsOptions,
      children: [
        {
          name: 'Meter Device',
          link: '/customer/reports/meter-by-device',
        },
      ],
    },

    {
      name: 'FMAudit Reports',
      toggleFunction: (val) => setFMAuditOptions(val),
      isOpen: isOpenFMAuditOptions,
      children: [
        {
          name: 'Active Devices',
          link: '/customer/reports/active-device',
        },
        {
          name: 'Retired Devices',
          link: '/customer/reports/retired-device',
        },
        {
          name: 'Never Reported',
          link: '/customer/reports/never-reported-device',
        },
        {
          name: 'Stopped Reporting',
          link: '/customer/reports/stopped-device',
        },
      ],
    },
  ];
  const isActiveClassName = ({
    link = null,
    index = null,
    className = 'bg-gray-100',
  }) => {
    const option = options[index];
    let activeClassName = null;
    if (link) {
      return location.pathname.indexOf(link) > -1 ? className : null;
    }
    if (index !== null) {
      if (option?.children) {
        option.children.map(({ link: childLink }) => {
          if (location.pathname.indexOf(childLink) > -1) {
            activeClassName = className;
          }
        });
      }
    }

    return activeClassName;
  };

  return (
    <>
      <div className=" border-b border-gray-200 pb-4 mb-4">
      <h1 className="text-2xl font-medium">Reports</h1>
      </div>
      <div className={'lg:flex w-full'}>
        <div className={'xl:w-1/4 lg:w-1/3 lg:pr-8 lg:mb-0 mb-6'}>
          <div className="p-6 pb-4 bg-gray-100 rounded-lg">
            <ul>
              {options.map(
                ({ name, children, isOpen, toggleFunction }, index) => {
                  return (
                    <div key={'options' + index}>
                      <li
                        onClick={() => toggleFunction(!isOpen)}
                        className={`text-sm mb-2 ${
                          isOpen || isActiveClassName({ index: index })
                            ? 'isActiveParent'
                            : ''
                        }`}
                      >
                        <div
                          className={`bg-white py-2 pl-3 border border-gray-300 rounded-md arrowBtn ${
                            isOpen || isActiveClassName({ index }) ? 'up' : ''
                          }`}
                        >
                          {name}
                        </div>
                        <ul
                          className={`bg-white py-2 px-3 mt-1 border border-gray-300 rounded-md ${
                            isOpen || isActiveClassName({ index })
                              ? ''
                              : 'hidden'
                          }`}
                        >
                          {children.map(({ name: cname, link }, childrenIndex) => {
                            return (
                              <li className="mb-1" key={'childrenIndex' + childrenIndex}>
                                <Link
                                  className={`py-2 px-3 block hover:bg-gray-100 rounded-md ${isActiveClassName(
                                    { link },
                                  )}`}
                                  to={link}
                                >
                                  {cname}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    </div>
                  );
                },
              )}
            </ul>
          </div>
        </div>
        <div className={'xl:w-3/4 lg:w-2/3 noPaginationGrid'}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Reports;
