export const DeviceLocationIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3332 7.49961V12.4996C18.3332 14.5829 17.9165 16.0413 16.9832 16.9829L11.6665 11.6663L18.1082 5.22461C18.2582 5.88294 18.3332 6.63294 18.3332 7.49961Z"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1082 5.22435L5.22483 18.1077C2.71649 17.5327 1.6665 15.7993 1.6665 12.4993V7.49935C1.6665 3.33268 3.33317 1.66602 7.49984 1.66602H12.4998C15.7998 1.66602 17.5332 2.71602 18.1082 5.22435Z"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9834 16.9827C16.0418 17.916 14.5834 18.3327 12.5001 18.3327H7.50011C6.63344 18.3327 5.88343 18.2577 5.2251 18.1077L11.6668 11.666L16.9834 16.9827Z"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.20022 6.64961C5.76689 4.20794 9.43356 4.20794 10.0002 6.64961C10.3252 8.08294 9.42522 9.29961 8.63355 10.0496C8.05855 10.5996 7.15023 10.5996 6.5669 10.0496C5.77523 9.29961 4.86689 8.08294 5.20022 6.64961Z"
        stroke="#A3A3A3"
        strokeWidth="1.5"
      />
      <path
        d="M7.57908 7.25065H7.58657"
        stroke="#A3A3A3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
