import { useEffect } from 'react';
import { Button } from '../ui/Button';
import { useForm } from './Form';
import { FormDefinition, FormType } from './types/FormTypes';
import { FormCheckBox } from './form-items/FormCheckbox';
import { FormInput } from './form-items/FormInput';
import { FormSelect } from './form-items/FormSelect';
import { FormRadio } from './form-items/FormRadio';
import { FormTextArea } from './form-items/FormTextArea';
import _ from 'lodash';

interface FormItemProps {
    formDef: FormDefinition;
    className?: string;
}

export const FormItem = ({ formDef, className }: FormItemProps) => {
  const {
    handleRequiredInputs,
    formDefinition,
    isSubmitDisabled,
    isSubmitting,
    dispatchFormDef,
    dispatch,
  } = useForm();

  function getObjectDiff(obj1, obj2) {
    const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
            result.push(key);
        } else if (_.isEqual(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key);
            result.splice(resultKeyIndex, 1);
        }
        return result;
    }, Object.keys(obj2));

    return diff;
}

  useEffect(() => {
    //Dynamic form definition updates
    if(formDefinition[formDef.name] && !_.isEqual(formDef, formDefinition[formDef.name])) {
      let diff = getObjectDiff(formDefinition[formDef.name], formDef);
      if (formDef.defaultValue && diff.includes('defaultValue')) dispatch({ [formDef.name]: { value: formDef.defaultValue } });
      dispatchFormDef({ [formDef.name]: formDef });
    }

    //Save FormDef to context
    if (formDefinition[formDef.name]) return;
    dispatchFormDef({ [formDef.name]: formDef });
    if (formDef.defaultValue) {
      dispatch({ [formDef.name]: { value: formDef.defaultValue } });
    }
  }, [formDef]);


  let FormComponent = <></>;

  const {
    name,
    label,
    type,
    disabled,
    onClick,
    icon,
    iconPosition,
    requiredInputs,
  } = formDef;

  if(!formDefinition[formDef.name]) return <></>;

  switch (type) {
    case FormType.TEXT:
    case FormType.NUMBER:
    case FormType.PASSWORD:
    case FormType.EMAIL:
    case FormType.DATE:
    case FormType.TIME:
    case FormType.DATETIME_LOCAL:
    case FormType.MONTH:
    case FormType.WEEK:
    case FormType.TEL:
    case FormType.FILE:
      FormComponent = <FormInput formDef={formDef}/>;
      break;
    case FormType.SELECT:
      FormComponent = <FormSelect formDef={formDef}/>
      break;
    case FormType.RADIO:
      FormComponent = <FormRadio formDef={formDef}/>
      break;
    case FormType.CHECKBOX:
      FormComponent = <FormCheckBox formDef={formDef}/>;
      break;
    case FormType.TEXTAREA:
      FormComponent = <FormTextArea formDef={formDef}/>
      break;
    case FormType.URL:
    case FormType.SEARCH:
    case FormType.COLOR:
    case FormType.RANGE:
    case FormType.HIDDEN:
    case FormType.IMAGE:
    case FormType.BUTTON:
      FormComponent = (
        <Button
          icon={icon}
          iconPosition={iconPosition}
          disabled={disabled}
          onClick={onClick}
        >
          {label}
        </Button>
      );
      break;
    case FormType.RESET:
    case FormType.SUBMIT:
      FormComponent = (
        <Button
          icon={icon}
          iconPosition={iconPosition}
          disabled={isSubmitDisabled}
          loading={isSubmitting}
          role="submit"
        >
          {label}
        </Button>
      );
      break;
    default:
      break;
  }

  //render input only if required inputs are filled
  if (requiredInputs && !handleRequiredInputs(formDef)) return <></>;

  return <div key={name} className={className}>
    { FormComponent }
    </div>;
}
