import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { APPLICATION_CONTEXT } from '../../utils/utils';

interface CanHavePermissionProps {
  permissions: Array<any>;
  children: React.ReactNode;
  isHide?: boolean;
}
export const CanHavePermissionAdmin = ({
  permissions,
  children,
  isHide = true,
}: CanHavePermissionProps) => {
  const { tokens }: any = useContext(APPLICATION_CONTEXT);
  const [isValid, setValid] = useState(false);
  const navigate = useNavigate();

  const checkPermission = () => {
    let valid = true;
    permissions.map((permission) => {
      if (tokens && tokens.indexOf(permission) === -1) {
        valid = false;
      }
    });
    if (isHide) {
      setValid(valid);
    } else {
      if (!valid) {
        if(window.location.href.toLowerCase().includes('/admin/')) {
          navigate('/admin/pageNotFound');
        } else {
          navigate('/customer/pageNotFound');
        }
      } else {
        setValid(true);
      }
    }
  };
  useEffect(() => {
    if (tokens && tokens.length) {
      checkPermission();
    }
  }, [tokens]);
  return <>{isValid ? <>{children}</> : ''}</>;
};