export const LocationContactIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.72488 3.83242C2.39155 0.949089 6.73322 0.949089 7.39155 3.83242C7.78322 5.52409 6.70822 6.95742 5.77488 7.84909C5.09155 8.49909 4.01655 8.49075 3.33322 7.84909C2.40822 6.95742 1.33322 5.52409 1.72488 3.83242Z"
        stroke="#A3A3A3"
        strokeWidth="1.5"
      />
      <path
        d="M12.5583 13.8324C13.225 10.9491 17.5917 10.9491 18.2583 13.8324C18.65 15.5241 17.575 16.9574 16.6333 17.8491C15.95 18.4991 14.8667 18.4908 14.1833 17.8491C13.2417 16.9574 12.1667 15.5241 12.5583 13.8324Z"
        stroke="#A3A3A3"
        strokeWidth="1.5"
      />
      <path
        d="M9.99991 4.16797H12.2332C13.7749 4.16797 14.4916 6.0763 13.3332 7.09297L6.67491 12.918C5.51658 13.9263 6.23325 15.8346 7.76658 15.8346H9.99991"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.57209 4.58464H4.58172"
        stroke="#A3A3A3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4051 14.5846H15.4147"
        stroke="#A3A3A3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
