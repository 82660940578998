import LOADING_ACTIONS from './loading.action.types';

export const showLoader = () => ({
  type:LOADING_ACTIONS.SHOW_LOADER,
  payload:{},
});

export const hideLoader = () => ({
  type:LOADING_ACTIONS.HIDE_LOADER,
  payload:{},
});
