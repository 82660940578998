
interface ServiceRequestNoteProps {
    note: string;
    isLastnote?: boolean;
}

export const ServiceRequestNote: React.FC<ServiceRequestNoteProps> = ({ note, isLastnote = true }) => {

    //We have some automated systems that add notes to the service requests. These notes are added in the following format: [time] [ note ] [name]
    const splitNote = note.split(']');
    const splitNoteCleaned = splitNote.map((el) => el.replace('[', '').trim()).filter((el) => el !== '');

    if (splitNoteCleaned.length > 1) {
        return (
            <div className={`${!isLastnote && 'border-b'} p-2 w-full mb-2 mt-2`}>
                <div className="font-bold text-gray-700">{splitNoteCleaned[0]}</div>
                <div>{splitNoteCleaned[1]}</div>
                <div className="text-gray-600 italic">{splitNoteCleaned[2]}</div>
            </div>
        )
    }

    return <div className={`${!isLastnote && 'border-b'} p-2 w-full mb-2 mt-2`}>{note}</div>

}