import { Button, ButtonProps } from "../../ui/Button";
import { useForm } from "../Form";

interface CancelProps extends ButtonProps {
    className?: string;
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const CancelBtn = ({children, className, onClick, ...rest} : CancelProps) => {
    const { clearForm } = useForm();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(onClick) onClick(event);
        clearForm();
    }

    return <Button
        className={className}
        onClick={handleClick}
        {...rest}
        >{children}</Button>;
}