import React from 'react';
// import { AES, enc } from 'crypto-js';

import { showNotification } from '../store/notification/notification.action';
import {
  ClientStorageKey,
  NOTIFICATION_TYPES,
  TENANT,
  TENANT_NAME,
  TenantKey,
} from '../constant/constant';
import moment from 'moment-timezone';
import storage from './storage/storage';
import FTGLogo from '../assets/images/flextg-logo.png';
import FlotechLogo from '../assets/images/flotech-logo.png';
import MarimonLogo from '../assets/images/marimon-logo.png';

let store: any = null;

// export const encrypt = (plainText: string) => {
//   return AES.encrypt(plainText, SECRET_PASSWORD).toString();
// };
//
// export const decrypt = (cipherText: string) => {
//   const bytes = AES.decrypt(cipherText, SECRET_PASSWORD);
//   return bytes.toString(enc.Utf8);
// };

export const setUtilStore = (storeArg: string) => {
  store = storeArg;
};
export const APPLICATION_CONTEXT: any = React.createContext(null);

export interface ClientApplicationContextType {
  isClientApp?: boolean;
  setCustomerInfo?: any;
  customerInfo?: object | null;
  tokens?: any;
  setTokens?: any;
  redirectUrl?: string;
  setRedirectUrl?: string;
  bannerMessage?: string;
  maintenanceMessage?: string;
}

export const tenantLogo = (tenantName) => {
  if (tenantName === TENANT.FLEXPRINT || tenantName === TENANT.SANDBOX) {
    return FTGLogo;
  }
  if (tenantName === TENANT.FLOTECH) {
    return FlotechLogo;
  }
  if (tenantName === TENANT.MARIMON) {
    return MarimonLogo;
  }
  return '';
};

export const doHavePermisions = ({
  tokens,
  permissions,
  url = '/admin/pageNotFound',
}: any) => {
  let valid = true;
  permissions.map((permission: any) => {
    if (tokens && tokens.indexOf(permission) === -1) {
      valid = false;
    }
  });
  if (!valid) {
    window.location.href = url;
    return false;
  }
  return true;
};

export const displayTenantNameName = (tenantName: string) => {
  return TENANT_NAME[tenantName] || '';
};

export const isMobile = () => {
  let userAgent = navigator.userAgent;
  let isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent,
    );
  return isMobileDevice;
};

export const isIpad = () => {
  let userAgent = navigator.userAgent;
  let isIpadDevice = /iPad/i.test(userAgent);
  return isIpadDevice;
};
// Check if the user is on an iPad

export function generateRandomString(length: number) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    result += characters.charAt(randomIndex);
  }

  return result.trim();
}

export interface ShowErrorMessageType {
  type: string | null;
  message: string;
}
export const showMessage = ({ type = null, message }: ShowErrorMessageType) => {
  if (store && store.dispatch) {
    store.dispatch(showNotification({ type, message }));
  }
};
export const message = {
  error: (msg: string, props = {}) =>
    showMessage({
      type: NOTIFICATION_TYPES.ERROR,
      message: msg,
      ...{ ...props, duration: 10000 },
    }),
  success: (msg: string, props = {}) =>
    showMessage({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: msg,
      ...props,
    }),
  warn: (msg: string, props = {}) =>
    showMessage({
      type: NOTIFICATION_TYPES.WARNING,
      message: msg,
      ...props,
    }),
  info: (msg: string, props = {}) =>
    showMessage({ type: NOTIFICATION_TYPES.INFO, message: msg, ...props }),
};
export const formattingTimeIntoMMDDYY = (date: any) => {
  return moment(date).format('MM-DD-YYYY');
};

export const getTime = (date: string) => {
  try {
    return moment(date)
      .tz(moment.tz.guess())
      .format(`YYYY-MM-DD hh:mm A \\[z\\] `)
      .toString();
  } catch (e) {
    return '';
  }
};
export const RemoveLocalStorageKeys = (StorageObj: any, except: any = null) => {
  const adminKey: any = Object.values(StorageObj);
  adminKey.map((val: string) => {
    if (except && except.length) {
      const isExist = except.includes(val);
      if (!isExist) {
        storage.removeItem(val);
      }
    } else {
      storage.removeItem(val);
    }
  });
};

export const colorCoder = (description: string) => {
  let cleanDescription = description.toString().toLowerCase();
  const tenant = storage.getItem(TenantKey);
  if (tenant === TENANT.MARIMON) {
    if (cleanDescription.match('cyan')) {
      return 'cyan';
    } else if (cleanDescription.match('black')) {
      return 'black';
    } else if (cleanDescription.match('magenta')) {
      return 'magenta';
    } else if (cleanDescription.match('yellow')) {
      return 'yellow';
    }
  } else {
    if (cleanDescription.match('toner cyan')) {
      return 'cyan';
    } else if (cleanDescription.match('toner black')) {
      return 'black';
    } else if (cleanDescription.match('toner magenta')) {
      return 'magenta';
    } else if (cleanDescription.match('toner yellow')) {
      return 'yellow';
    }
    if (cleanDescription.match('ink cyan')) {
      return 'cyan';
    } else if (cleanDescription.match('ink black')) {
      return 'black';
    } else if (cleanDescription.match('ink magenta')) {
      return 'magenta';
    } else if (cleanDescription.match('ink yellow')) {
      return 'yellow';
    }
  }
  return null;
};
export const isCheckDollerProcingNonZero = (value: any) => {
  const appHideZeroDollarPricing = value && parseFloat(value) === 0.0 || !value;
  const customerInfo: any = storage.getJSONItem(ClientStorageKey.customerInfo);
  return !(
    customerInfo &&
    customerInfo?.HideZeroDollarPricing &&
    appHideZeroDollarPricing
  );
};

// trim all the strings in an object recursively
 export const trimStringsFromObjects = (obj: any) => {
  const newObj = obj;

  Object.keys(newObj).forEach((key) => {
    if (typeof newObj[key] === 'string' && newObj[key] !== null){
      newObj[key] = newObj[key].trim();
    }
    if (typeof newObj[key] === 'object' && newObj[key] !== null) {
      newObj[key] = trimStringsFromObjects(newObj[key]);
    }
  });

  return newObj;
}

export function getLocalTimezone() {
  const localTimezone = moment.tz.guess();
  return localTimezone
}
