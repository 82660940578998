export const ExcelIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_782_4246)">
        <path
          d="M10.3333 1.58398H5.08333C4.92862 1.58398 4.78025 1.64544 4.67085 1.75484C4.56146 1.86424 4.5 2.01261 4.5 2.16732V5.08399L10.3333 8.00065L12.6667 8.87565L15 8.00065V5.08399L10.3333 1.58398Z"
          fill="#21A366"
        />
        <path d="M10.3333 5.0957H4.5V8.0007H10.3333V5.0957Z" fill="#107C41" />
        <path
          d="M14.9997 2.16732V5.08398H10.333V1.58398H14.4163C14.571 1.58398 14.7194 1.64544 14.8288 1.75484C14.9382 1.86423 14.9997 2.01261 14.9997 2.16732Z"
          fill="#33C481"
        />
        <path
          d="M10.3333 8H4.5V13.8333C4.5 13.988 4.56146 14.1364 4.67085 14.2458C4.78025 14.3552 4.92862 14.4167 5.08333 14.4167H14.4167C14.5714 14.4167 14.7197 14.3552 14.8291 14.2458C14.9385 14.1364 15 13.988 15 13.8333V10.9167L10.3333 8Z"
          fill="#185C37"
        />
        <path
          opacity="0.5"
          d="M9.0675 4.5H4.5V12.6667H8.93333C9.14082 12.6538 9.33695 12.5675 9.48659 12.4232C9.63623 12.2789 9.72961 12.086 9.75 11.8792V5.1825C9.74848 5.00196 9.67608 4.82925 9.54842 4.70158C9.42075 4.57392 9.24804 4.50152 9.0675 4.5Z"
          fill="black"
        />
        <path
          d="M8.48417 3.91602H1.6825C1.30557 3.91602 1 4.22158 1 4.59852V11.4002C1 11.7771 1.30557 12.0827 1.6825 12.0827H8.48417C8.8611 12.0827 9.16667 11.7771 9.16667 11.4002V4.59852C9.16667 4.22158 8.8611 3.91602 8.48417 3.91602Z"
          fill="#107C41"
        />
        <path
          d="M3.00098 10.3327L4.50014 7.99935L3.12348 5.66602H4.23764L4.99598 7.15352C5.0493 7.25543 5.09606 7.36064 5.13598 7.46852C5.18257 7.35688 5.23514 7.24783 5.29348 7.14185L6.11014 5.66602H7.13098L5.71348 7.99935L7.16598 10.3327H6.08098L5.20598 8.69935C5.15807 8.63165 5.11704 8.55935 5.08348 8.48352C5.05965 8.55751 5.02631 8.6281 4.98431 8.69351L4.09181 10.3327H3.00098Z"
          fill="white"
        />
        <path d="M14.9997 8H10.333V10.9167H14.9997V8Z" fill="#107C41" />
      </g>
      <defs>
        <clipPath id="clip0_782_4246">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
