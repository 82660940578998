export const EquipementInfoIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.6665 10.167H12.4998"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6665 13.5H10.3165"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33317 5.00033H11.6665C13.3332 5.00033 13.3332 4.16699 13.3332 3.33366C13.3332 1.66699 12.4998 1.66699 11.6665 1.66699H8.33317C7.49984 1.66699 6.6665 1.66699 6.6665 3.33366C6.6665 5.00033 7.49984 5.00033 8.33317 5.00033Z"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 3.34961C16.1083 3.49961 17.5 4.52461 17.5 8.33294V13.3329C17.5 16.6663 16.6667 18.3329 12.5 18.3329H7.5C3.33333 18.3329 2.5 16.6663 2.5 13.3329V8.33294C2.5 4.53294 3.89167 3.49961 6.66667 3.34961"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
