export const CsvIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_782_7225)">
        <path
          d="M12.9167 1.98047H6.35417C6.16078 1.98047 5.97531 2.05729 5.83857 2.19404C5.70182 2.33078 5.625 2.51625 5.625 2.70964V6.35547L12.9167 10.0013L15.8333 11.0951L18.75 10.0013V6.35547L12.9167 1.98047Z"
          fill="#21A366"
        />
        <path
          d="M12.9167 6.36914H5.625V10.0004H12.9167V6.36914Z"
          fill="#107C41"
        />
        <path
          d="M18.7503 2.70964V6.35547H12.917V1.98047H18.0212C18.2145 1.98047 18.4 2.05729 18.5368 2.19404C18.6735 2.33078 18.7503 2.51625 18.7503 2.70964Z"
          fill="#33C481"
        />
        <path
          d="M12.9167 10H5.625V17.2917C5.625 17.4851 5.70182 17.6705 5.83857 17.8073C5.97531 17.944 6.16078 18.0208 6.35417 18.0208H18.0208C18.2142 18.0208 18.3997 17.944 18.5364 17.8073C18.6732 17.6705 18.75 17.4851 18.75 17.2917V13.6458L12.9167 10Z"
          fill="#185C37"
        />
        <path
          opacity="0.5"
          d="M11.3344 5.625H5.625V15.8333H11.1667C11.426 15.8173 11.6712 15.7094 11.8582 15.529C12.0453 15.3486 12.162 15.1076 12.1875 14.849V6.47813C12.1856 6.25245 12.0951 6.03656 11.9355 5.87698C11.7759 5.7174 11.56 5.6269 11.3344 5.625Z"
          fill="black"
        />
        <path
          d="M10.6052 4.89453H2.10313C1.63196 4.89453 1.25 5.27649 1.25 5.74766V14.2497C1.25 14.7209 1.63196 15.1029 2.10313 15.1029H10.6052C11.0764 15.1029 11.4583 14.7209 11.4583 14.2497V5.74766C11.4583 5.27649 11.0764 4.89453 10.6052 4.89453Z"
          fill="#107C41"
        />
        <path
          d="M3.75098 12.9154L5.62493 9.9987L3.9041 7.08203H5.29681L6.24473 8.94141C6.31138 9.0688 6.36984 9.20031 6.41973 9.33516C6.47797 9.19561 6.54369 9.0593 6.6166 8.92682L7.63743 7.08203H8.91348L7.1416 9.9987L8.95723 12.9154H7.60098L6.50723 10.8737C6.44735 10.7891 6.39605 10.6987 6.3541 10.6039C6.32431 10.6964 6.28265 10.7846 6.23014 10.8664L5.11452 12.9154H3.75098Z"
          fill="white"
        />
        <path d="M18.7498 10H12.9165V13.6458H18.7498V10Z" fill="#107C41" />
      </g>
      <defs>
        <clipPath id="clip0_782_7225">
          <rect
            width="17.5"
            height="17.5"
            fill="white"
            transform="translate(1.25 1.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
