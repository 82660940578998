export const LocationIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 16.5H17.25"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.835 16.5071V13.1621"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8502 8.16797C13.9352 8.16797 13.2002 8.90298 13.2002 9.81798V11.5204C13.2002 12.4354 13.9352 13.1705 14.8502 13.1705C15.7652 13.1705 16.5002 12.4354 16.5002 11.5204V9.81798C16.5002 8.90298 15.7652 8.16797 14.8502 8.16797Z"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5752 16.5003V4.52283C1.5752 3.01533 2.32523 2.25781 3.81773 2.25781H8.49022C9.98272 2.25781 10.7252 3.01533 10.7252 4.52283V16.5003"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.34961 6.1875H8.06212"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.34961 9H8.06212"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.1875 16.5V13.6875"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
