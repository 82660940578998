import React, { useRef, useState } from 'react';
import {
  CancelBtn,
  Form,
  FormItem,
  FormRef,
  FormType,
  InputMask,
  SubmitBtn,
} from '../../../../../components/form';
import { Api } from '../../../../../shared/api/api';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../../../../store/notification/notification.action';
import { useUser } from '../../../../../context/UserContext';

export const AddLocation = () => {
  const { customerInfo } = useUser();
  const formRef: FormRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    let comments = data.Comments;

    delete data.Comments;
    delete data.FilePath;

    let apiBody: { Comments: any; UserInfo: any; LocationData: any; FilePath?: any} = {
      Comments: comments || "",
      UserInfo: {
        CustomerId: customerInfo.CustomerId,
        BranchID: customerInfo.BranchID,
        CustomerName: customerInfo.CustomerName,
        EmailAddress: customerInfo.EmailAddress,
        FirstName: customerInfo.FirstName,
        LastName: customerInfo.LastName,
        UserPhone: customerInfo.UserPhone,
      },
      LocationData: data,
    }

    if (!!data.FilePath) {
      let formData = new FormData();
      formData.append('files', data.FilePath.files[0]);

      const fileUploadRes = await Api.callAPI({
        url: `/api/v1/location/configReports/upload`,
        method: 'post',
        body: formData,
        options: {
          isLoading: false,
          'Content-Type': 'multipart/form-data',
        },
      });

      apiBody.FilePath = fileUploadRes.data;
    }

    const res = await Api.callAPI({
      url: `/api/v1/location`,
      method: 'post',
      body: apiBody,
      options: {
        isLoading: false,
      },
    });

    dispatch(
      showNotification({
        type: 'success',
        message: res?.data?.message || 'Your new location request has been sent to our team for review',
      }),
    );
    // formRef.clear();
    setIsSubmitting(false);
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-medium">Add Location</h1>
      <Form formRef={formRef} onSubmit={onSubmit}>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-6">
          <FormItem
            formDef={{
              name: 'LocationName',
              label: 'Location Name',
              type: FormType.TEXT,
              required: true,
            }}
          />

          <FormItem
            formDef={{
              name: 'Street',
              label: 'Street',
              type: FormType.TEXT,
              required: true,
            }}
          />

          <FormItem
            formDef={{
              name: 'City',
              label: 'City',
              type: FormType.TEXT,
              required: true,
            }}
          />

          <FormItem
            formDef={{
              name: 'State',
              label: 'State',
              type: FormType.TEXT,
              required: true,
            }}
          />

          <FormItem
            formDef={{
              name: 'Zip',
              label: 'Zip Code',
              type: FormType.TEXT,
              mask: InputMask.ZIP,
              required: true,
            }}
          />

          <FormItem
            formDef={{
              name: 'Country',
              label: 'Country',
              type: FormType.TEXT,
              defaultValue: 'USA',
              required: true,
            }}
          />

          <FormItem
            formDef={{
              name: 'ContactName',
              label: 'Contact Name',
              type: FormType.TEXT,
              required: true,
            }}
          />

          <FormItem
            formDef={{
              name: 'CompanyPhone',
              label: 'Company Phone',
              type: FormType.TEXT,
              mask: InputMask.PHONE,
              required: true,
            }}
          />

          <FormItem
            formDef={{
              name: 'Email',
              label: 'Email',
              type: FormType.EMAIL,
              required: true,
            }}
          />
        </div>

        <FormItem
          formDef={{
            name: 'FilePath',
            label: 'Attachment',
            type: FormType.FILE,
            uploadMaxSize: 25000000,
            uploadAccept: '.doc, .docx, .pdf, .xls, .xlsx',
            className: 'my-4'
          }}
        />

        <FormItem
          formDef={{
            name: 'Comments',
            label: 'Comments',
            type: FormType.TEXTAREA,
            maxLength: 200,
            className: 'mb-4'
          }}
        />
        <div className="h-[1px] bg-stone-200 w-full my-6"></div>
        <div className="flex justify-end">
          <CancelBtn className="rounded p-1 px-4 mr-4" ghost to="/customer/locations">
            Cancel
          </CancelBtn>
          <SubmitBtn className="rounded p-1 px-4" loading={isSubmitting}>
            Add New Location
          </SubmitBtn>
        </div>
      </Form>
    </div>
  );
};
