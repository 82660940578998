export enum Status {
    Processing = 'Processing',
    Pending = 'Pending',
    Cleared = 'Cleared',
    Scheduled = 'Scheduled',
    Invoiced = 'Invoiced',
    OKToInvoice = 'OK to Invoice',
    Completed = 'Completed',
    OnHold = 'On Hold',
    Dispatched = 'Dispatched',
    Canceled = 'Canceled',
    Shipped = 'Shipped',
    Delivered = 'Delivered',
    Returned = 'Returned',
}

interface ChipProps {
    status?: Status
    text?: string
}

export const Chip: React.FC<ChipProps> = ({ status = null, text }) => {
    let StatusClass = '';

    switch(status) {
        case Status.Processing:
        case Status.Pending:
        case Status.Cleared:
        case Status.Scheduled:
            StatusClass = 'processing';
            text = 'Processing';
            break;
        case Status.Invoiced:
        case Status.OKToInvoice:
        case Status.Completed:
            StatusClass = 'completed';
            text = 'Completed';
            break;
        case Status.Delivered:
            StatusClass = 'completed';
            text = 'Delivered';
            break;
        case Status.OnHold:
        case Status.Returned:
            StatusClass = 'onhold';
            text = 'On Hold';
            break;
        case Status.Dispatched:
            StatusClass = 'dispatched';
            text = 'Tech Dispatched';
            break;
        case Status.Shipped:
            StatusClass = 'dispatched';
            text = 'Shipped';
            break;
        case Status.Canceled:
            StatusClass = 'canceled';
            text = 'Canceled';
            break;
        default:
    }

    return (
        <div className={'box'}>
            <div className={'box-inner'}>
                <span className={` cursor-default btn btn-${StatusClass}`}>{text}</span>
            </div>
        </div>
    );
}