export const SupplyIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_782_7122)">
        <path
          d="M5.66687 9.16667C5.22863 9.92681 4.99813 10.7889 4.99854 11.6663C4.99894 12.5437 5.23023 13.4056 5.66918 14.1653C6.10812 14.9251 6.73926 15.5559 7.4992 15.9945C8.25914 16.4331 9.12111 16.664 9.99854 16.664C10.876 16.664 11.7379 16.4331 12.4979 15.9945C13.2578 15.5559 13.8889 14.9251 14.3279 14.1653C14.7668 13.4056 14.9981 12.5437 14.9985 11.6663C14.9989 10.7889 14.7684 9.92681 14.3302 9.16667L9.99937 2.5L5.66603 9.16667H5.66687Z"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 11.666H15"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_782_7122">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
