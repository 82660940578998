import moment from 'moment';
import { DATE_FORMAT } from '../../../../../constant/constant';
import React from 'react';
import { EditIcon } from '../../../../../assets/svg/edit-icon';
import { TrashIcon } from '../../../../../assets/svg/trash-icon';
import { LockIcon } from '../../../../../assets/svg/lock-icon';
import { Link } from "react-router-dom";

export const UsersListField = ({ onActionClick }: any) => {
  const filterParams = {
    filterOptions: ['contains'],
    defaultOption: 'contains',
    maxNumConditions: 1,
    debounceMs: 200,
  };

  return [
    {
      headerName: 'Email',
      field: 'EmailAddress',
      filterParams: filterParams,
      sortable: false,
      suppressMovable: true,
    },
    {
      headerName: 'First Name',
      field: 'FirstName',
      filterParams: filterParams,
      sortable: false,
      suppressMovable: true,
    },
    {
      headerName: 'Last Name',
      field: 'LastName',
      filterParams: filterParams,
      sortable: false,
      suppressMovable: true,
    },
    {
      headerName: 'Phone Number',
      field: 'PhoneNumber',
      filter: false,
      sortable: false,
      suppressMovable: true,
    },
    {
      headerName: 'Created Date',
      field: 'CreatedDate',
      filter: false,
      sortable: false,
      suppressMovable: true,
      cellRenderer: (options: any) => {
        return options.data
          ? moment(options.data.CreatedDate).format(DATE_FORMAT)
          : '';
      },
    },
    {
      headerName: 'Customer ID',
      field: 'CustomerId',
      sortable: false,
      suppressMovable: true,
      filter: false,
      cellRenderer: (options: any) => {
        return options?.data?.Customer?.Id || '-';
      },
    },
    {
      headerName: 'Customer Name',
      field: 'Customer',
      sortable: false,
      filter: false,
      suppressMovable: true,
      cellRenderer: (options: any) => {
        if (options?.data?.Customer?.Id) {
          return (
            <Link
              className={` linkText hover:text-gray-900`}
              to={`/admin/customerUser/${options?.data?.Customer?.Id}`}
            >
              {options?.data?.Customer?.Name}
            </Link>
          );
        }
        return '-';
      },
    },

    {
      field: 'Action',
      headerName: 'Action',
      isActionColumn: true,
      filter: null,
      cellRenderer: (cellEventData: any) => {
        return (
          <>
            <div className="flex items-center mt-3">
              <button
                title="Edit"
                className={'edit mr-3 block iconbtn'}
                onClick={() => {
                  onActionClick(cellEventData, 'edit');
                }}
              >
                <EditIcon />
              </button>
              <button
                title="Delete"
                className={'trash mr-3 block iconbtn'}
                onClick={() => {
                  onActionClick(cellEventData, 'trash');
                }}
              >
                <TrashIcon />
              </button>
            </div>
          </>
        );
      },
    },
  ];
};

export const UsersListByCustomerIDField = ({ onActionClick }: any) => {
  const filterParams = {
    filterOptions: ['contains'],
    defaultOption: 'contains',
    maxNumConditions: 1,
    debounceMs: 200,
  };
  return [
    {
      headerName: 'Email',
      field: 'EmailAddress',
      filterParams: filterParams,
      sortable: false,
      suppressMovable: true,
    },
    {
      headerName: 'First Name',
      field: 'FirstName',
      filterParams: filterParams,
      sortable: false,
      suppressMovable: true,
    },
    {
      headerName: 'Last Name',
      field: 'LastName',
      filterParams: filterParams,
      sortable: false,
      suppressMovable: true,
    },
    {
      headerName: 'Phone Number',
      field: 'PhoneNumber',
      filter: false,
      sortable: false,
      suppressMovable: true,
    },
    {
      headerName: 'Created Date',
      field: 'CreatedDate',
      filter: false,
      sortable: false,
      suppressMovable: true,
      cellRenderer: (options: any) => {
        return options.data
          ? moment(options.data.CreatedDate).format(DATE_FORMAT)
          : '';
      },
    },
    {
      field: 'Action',
      headerName: 'Action',
      isActionColumn: true,
      cellRenderer: (cellEventData: any) => {
        return (
          <>
            <div className="flex items-center mt-3">
              <button
                title="Edit"
                className={'edit mr-3 block iconbtn'}
                onClick={() => {
                  onActionClick(cellEventData, 'edit');
                }}
              >
                <EditIcon />
              </button>
              <button
                title="Delete"
                className={'trash mr-3 block iconbtn'}
                onClick={() => {
                  onActionClick(cellEventData, 'trash');
                }}
              >
                <TrashIcon />
              </button>
              <button
                title="Change Password"
                className={'lock mr-3 block iconbtn'}
                onClick={() => {
                  onActionClick(cellEventData, 'changePassword');
                }}
              >
                <LockIcon />
              </button>
            </div>
          </>
        );
      },
    },
  ];
};
