import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TENANT } from '../../../constant/constant';
import { RootState } from '../../../store/store';

export const FaviconTitleManager = () => {
  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );

  useEffect(() => {
    const favicon: HTMLAnchorElement | null =
      document.querySelector("link[rel='icon']");
    if (favicon) {
      if (tenantName === TENANT.FLOTECH) {
        document.title = 'Flo-Tech Customer Portal';
        favicon.href = '/flotech_favicon.ico';
      }
      if (tenantName === TENANT.FLEXPRINT) {
        document.title = 'FTG Client Portal';
        favicon.href = '/flexprint_favicon.ico';
      }
      if (tenantName === TENANT.MARIMON) {
        document.title = 'Marimon Client Portal';
        favicon.href = '/marimon_favicon.ico';
      }
    }
  }, [tenantName]);

  return <></>;
};
