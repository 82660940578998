/**
 * This component represents an unadorned list of SelectItem (s).
 */
import React, { useEffect, useState } from 'react';

import { useSelectContext } from '../hooks/use-select-context';
import { Option } from '../lib/interfaces';
import SelectItem from './select-item';

interface ISelectListProps {
  SelectListId?: string;
  options: Option[];
  onClick: any;
  skipIndex: number;
}

const SelectList = ({
  options,
  onClick,
  skipIndex,
  SelectListId,
}: ISelectListProps) => {
  const {
    disabled,
    value,
    onChange,
    ItemRenderer,
    isSingleSelect,
    lazyLoadOptions,
  }: any = useSelectContext();

  const pageSize =
    lazyLoadOptions && lazyLoadOptions?.perPageData
      ? lazyLoadOptions?.perPageData
      : 10;
  const [pageNot, setPageNo] = useState(1);
  const [op, setOp] = useState(
    lazyLoadOptions ? options.slice(0, pageSize * pageNot) : options,
  );
  const handleSelectionChanged = (option: Option, checked: boolean) => {
    if (disabled) return;
    if (isSingleSelect) {
      // const isSameValueSelected =
      //   value && value.length && value[0].value !== option.value;
      if (!value?.length) {
        onChange(
          checked
            ? [option]
            : value.filter((o: any) => o.value !== option.value),
        );
      } else {
        const isSameValueSelected =
          value && value.length && value[0].value !== option.value;
        if (isSameValueSelected) {
          onChange(
            checked
              ? [option]
              : value.filter((o: any) => o.value !== option.value),
          );
        }
      }
    } else {
      onChange(
        checked
          ? [...value, option]
          : value.filter((o: any) => o.value !== option.value),
      );
    }
  };

  useEffect(() => {
    if (lazyLoadOptions !== null) {
      const element = document.getElementById(SelectListId);
      const isScrollbarAtBottom = () => {
        return (
          element.scrollHeight < element.scrollTop + element.clientHeight + 20
        );
      };
      if (element) {
        element.addEventListener('scroll', () => {
          if (isScrollbarAtBottom()) {
            const pageNo = pageNot + 1;
            setOp(options.slice(0, pageSize * pageNo));
            setPageNo(pageNo);
          }
        });
      }
    }
  }, [options]);

  useEffect(() => {
    setOp(lazyLoadOptions ? options.slice(0, pageSize * pageNot) : options);
  }, [options]);
  return (
    <>
      {op.map((o: any, i) => {
        const tabIndex = i + skipIndex;

        return (
          <li key={o?.key || i}>
            <SelectItem
              tabIndex={tabIndex}
              option={o}
              onSelectionChanged={(c) => handleSelectionChanged(o, c)}
              checked={!!value.find((s: any) => s.value === o.value)}
              onClick={(e: any) => onClick(e, tabIndex)}
              itemRenderer={ItemRenderer}
              disabled={o.disabled || disabled}
            />
          </li>
        );
      })}
    </>
  );
};

export default SelectList;
