import { useEffect, useState } from "react";

interface RadioButtonProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'defaultValue' | 'onChange' | 'value'> {
    label?: string | React.ReactNode;
    className?: string;
    helperText?: string;
    border?: boolean;
    fullWidth?: boolean;
    options?: RadioButtonOptions[];
    multiSelect?: boolean;
    name?: string;
    value?: string;
    onChange?: (selected: RadioButtonOptions[]) => void;
    selected?: Array<string>;
    defaultValue?: boolean | string | Array<string> | number;
    row?: boolean;
    error?: string;
}

export interface RadioButtonOptions {
    label: string | React.ReactNode;
    name: string;
    value: string | boolean | number;
    helperText?: string;
}

const getCurrentSelected = (selected: Array<string>, options: RadioButtonOptions[]) => {   
    if(options && selected && selected.length > 0) {
        return options.filter((o: RadioButtonOptions) => selected && selected.includes(o.value.toString()));
    }

    return [];
}

export const RadioButton = ({ defaultValue, border, label, className, helperText, fullWidth, options, multiSelect, name, value, onChange, selected, row, ...rest}: RadioButtonProps) => {
    
    const currentSelected = getCurrentSelected(selected, options);
    options = options || [{ name: name, value: value || true, helperText: helperText, label: label }];

    const [ selectedOptions, setSelectedOptions ] = useState<RadioButtonOptions[]>(currentSelected);

    useEffect(() => {
        if(!!selected) {
            if(typeof selected === 'string' || typeof selected === 'number' || typeof selected === 'boolean') {
                setSelectedOptions(options.filter((o: RadioButtonOptions) => o.value === selected));
            }
            if(Array.isArray(selected) && selected.length > 0) {
                setSelectedOptions(options.filter((o: RadioButtonOptions) => selected.includes(o.value.toString())));
            }
            if(Array.isArray(selected) && selected.length == 0) {
                setSelectedOptions([]);
            }
        } else {
            setSelectedOptions([]);
        }
    }, [selected])

    const borderClass = border ? 'border border-gray-300 rounded items-center justify-start px-2' : '';
    const fullWidthClass = fullWidth ? 'w-full' : 'w-fit';

    const handleChange = (option: RadioButtonOptions) => {
        const isSelected = selectedOptions.find(selectedOption => selectedOption.value === option.value);
        let newOptionArray = isSelected ? selectedOptions.filter(selectedOption => selectedOption.value !== option.value) : [...selectedOptions, option];
    
        if(!multiSelect && newOptionArray.length > 1) newOptionArray = [option];

        setSelectedOptions(newOptionArray);
        return onChange && onChange(newOptionArray);
    }

    const renderMultipleOptions = () => {

        return <div>
            <div className={`flex ${row ? '' : 'flex-col'} ${fullWidthClass} ${className} ${borderClass}`}>
                {options.map((option) => {
                    return <div key={option.name} className={`flex items-center min-h-5 ${row ? 'mr-3' : ''}`}>
                        <input 
                            id={option.name}
                            name={option.name}
                            aria-describedby="helper-radio-text" 
                            type="radio" 
                            className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 cursor-pointer"
                            onClick={() => handleChange(option)} 
                            onChange={() => handleChange(option)}
                            onKeyDown={(e) => e.key === 'Enter' && handleChange(option)}
                            checked={selectedOptions.find(selectedOption => selectedOption.value === option.value) ? true : false}
                            {...rest} />
                        <div className="text-sm">
                            <label htmlFor={option.name} className="font-medium text-gray-700 pl-2">{option.label}</label>
                            { option.helperText && <p className="text-gray-500">{option.helperText}</p> }
                        </div>
                    </div>
                })}
            </div>
            { rest.error && <p className="text-red-500 text-xs">{rest.error}</p> }
        </div>
    }

    const renderSingleOption = () => {
        return <div className={`flex ${fullWidthClass} ${className} ${borderClass}`}>
            <div className="flex items-center min-h-5">
                <input 
                    id={name}
                    name={name}
                    aria-describedby="helper-radio-text" 
                    type="radio" 
                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 cursor-pointer"
                    onClick={() => handleChange({ name: name, value: value, helperText: helperText, label: label})} 
                    onChange={() => handleChange({ name: name, value: value, helperText: helperText, label: label})}
                    onKeyDown={(e) => e.key === 'Enter' && handleChange({ name: name, value: value, helperText: helperText, label: label})}
                    checked={selectedOptions.find(selectedOption => selectedOption.value === value) ? true : false}
                    {...rest} />
            </div>
            <div className="ml-2 text-sm">
                { label && <label htmlFor={name} className="font-medium text-gray-700">{label}</label> }
                { helperText && <p className="text-gray-500">{helperText}</p> }
                { rest.error && <p className="text-red-500 text-xs">{rest.error}</p> }
            </div>
        </div>
    }

    return options ? renderMultipleOptions() : renderSingleOption();
}