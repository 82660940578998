export const CallIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3082 15.2753C18.3082 15.5753 18.2415 15.8837 18.0998 16.1837C17.9582 16.4837 17.7748 16.767 17.5332 17.0337C17.1248 17.4837 16.6748 17.8087 16.1665 18.017C15.6665 18.2253 15.1248 18.3337 14.5415 18.3337C13.6915 18.3337 12.7832 18.1337 11.8248 17.7253C10.8665 17.317 9.90817 16.767 8.95817 16.0753C7.99984 15.3753 7.0915 14.6003 6.22484 13.742C5.3665 12.8753 4.5915 11.967 3.89984 11.017C3.2165 10.067 2.6665 9.11699 2.2665 8.17532C1.8665 7.22532 1.6665 6.31699 1.6665 5.45032C1.6665 4.88366 1.7665 4.34199 1.9665 3.84199C2.1665 3.33366 2.48317 2.86699 2.92484 2.45033C3.45817 1.92533 4.0415 1.66699 4.65817 1.66699C4.8915 1.66699 5.12484 1.71699 5.33317 1.81699C5.54984 1.91699 5.7415 2.06699 5.8915 2.28366L7.82484 5.00866C7.97484 5.21699 8.08317 5.40866 8.15817 5.59199C8.23317 5.76699 8.27484 5.94199 8.27484 6.10032C8.27484 6.30032 8.2165 6.50032 8.09984 6.69199C7.9915 6.88366 7.83317 7.08366 7.63317 7.28366L6.99984 7.94199C6.90817 8.03366 6.8665 8.14199 6.8665 8.27532C6.8665 8.34199 6.87484 8.40032 6.8915 8.46699C6.9165 8.53366 6.9415 8.58366 6.95817 8.63366C7.10817 8.90866 7.3665 9.26699 7.73317 9.70032C8.10817 10.1337 8.50817 10.5753 8.9415 11.017C9.3915 11.4587 9.82484 11.867 10.2665 12.242C10.6998 12.6087 11.0582 12.8587 11.3415 13.0087C11.3832 13.0253 11.4332 13.0503 11.4915 13.0753C11.5582 13.1003 11.6248 13.1087 11.6998 13.1087C11.8415 13.1087 11.9498 13.0587 12.0415 12.967L12.6748 12.342C12.8832 12.1337 13.0832 11.9753 13.2748 11.8753C13.4665 11.7587 13.6582 11.7003 13.8665 11.7003C14.0248 11.7003 14.1915 11.7337 14.3748 11.8087C14.5582 11.8837 14.7498 11.992 14.9582 12.1337L17.7165 14.092C17.9332 14.242 18.0832 14.417 18.1748 14.6253C18.2582 14.8337 18.3082 15.042 18.3082 15.2753Z"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
