import { Link, useNavigate } from "react-router-dom";
import { getTime } from '../../../../../../../utils/utils';
import { Chip } from "../../../../../../../components/ui/Chip";
// import { getTime } from '../../../../../../../utils/utils';

export const LocationServicesDetailsTableFields = () => {
  const navigate = useNavigate();


  const filterParams = {
    filterOptions: ['contains'],
    defaultOption: 'contains',
    maxNumConditions: 1,
    debounceMs: 200,
  };

  return [
    {
      headerName: 'Request #',
      field: 'WorkOrderNumber',
      width: 130,
      filterParams: filterParams,
      cellRenderer: (cell: any) => {
        return cell && cell.data ? (
          <Link
            className="service-request-view linkText"
            to={`/service/service-request-detail/${cell.data.WorkOrderID}`}
          >
            {cell.data.WorkOrderNumber}
          </Link>
        ) : (
          ''
        );

        // const locations =
        //   cell.selectedLocation && cell.selectedLocation.length
        //     ? cell.selectedLocation
        //         .map((location) => {
        //           return location.CustomerID;
        //         })
        //         .toString()
        //     : [];
        // return cell && cell.data
        //   ? // tslint:disable-next-line:max-line-length
        //     `<a class="service-request-view" href="/service/service-request-detail/${cell.data.WorkOrderID}?timeRange=${this.timeRange.id}&location=${locations}&viewMyCalls=${this.searchQueryForRequestHistory.onlyMyCallsRequest}">${cell.data.WorkOrderNumber}</a>`
        //   : '';
        // return (
        //   <a className="service-request-view" href="javascript:void(0)">
        //     {cell.data.WorkOrderNumber}
        //   </a>
        // );
      },
      onCellClicked: () => {
        // const locations =
        //   this.selectedLocation && this.selectedLocation.length
        //     ? this.selectedLocation
        //         .map((location) => {
        //           return location.CustomerID;
        //         })
        //         .toString()
        //     : [];
        // if (
        //   options.event.target.tagName === 'A' &&
        //   !this.isControlPressed(options.event)
        // ) {
        //   options.event.preventDefault();
        //   options.event.stopPropagation();
        //   this.router.navigate(
        //     ['/service/service-request-detail/' + options.data.WorkOrderID],
        //     {
        //       queryParams: {
        //         timeRange: this.timeRange.id,
        //         location: locations,
        //         viewMyCalls:
        //           this.searchQueryForRequestHistory.onlyMyCallsRequest,
        //       },
        //     },
        //   );
        // }
      },
    },
    {
      headerName: 'Request Placed',
      filter : null,
      field: 'Date',
      width: 230,

      cellRenderer: (cell: any) => {
        if (cell && cell.data) {
          const Date = getTime(cell.data.Date);
          return <div>{Date}</div>;
        } else {
          return '';
        }
      },
    },
    {
      headerName: 'Equipment Number',
      field: 'EquipmentNumber',
      filterParams: filterParams,
      width: 170,

      cellRenderer: (options: any) => {
        return (
          <a
            className="service-request-view linkText"
            href={`/customer/device-detail/${options.data.EquipmentID}`}
          >
            {options.data.EquipmentNumber}
          </a>
        );
      },
      onCellClicked: (options: any) => {
        navigate(`/customer/device-detail/${options.data.EquipmentID}`);
      },
    },
    {
      headerName: 'Serial Number',
      field: 'SerialNumber',
      filterParams: filterParams,
      width: 160,
    },
    {
      headerName: 'Location Remarks',
      field: 'LocationRemarks',
      filterParams: filterParams,
      width: 200,
    },
    {
      headerName: 'Problem Description',
      field: 'ProblemDescription',
      autoHeight: 1,
      valueGetter: (params: any) => {
        if (!params?.data?.ProblemDescription) {
          return null;
        }

        return params?.data?.ProblemDescription.join('\n');
      },
      cellRenderer: (cell: any) => {
        if (!cell.data.ProblemDescription) return null;
        return (
          <div
            className={`${
              cell.data.ProblemDescription.length > 0
                ? 'lineHeight22'
                : 'lineHeight48 overflow-auto'
            }`}
          >
            {cell.data.ProblemDescription.map((problem) => {
              return <div key={problem}>{problem}</div>;
            })}
          </div>
        );
      },
    },
    {
      headerName: 'Status',
      width: 160,
      filterParams: filterParams,
      field: 'Status',
      cellRenderer: (cell: any) => {
        if (!cell.data.Status) {
          return '';
        }

        return <Chip status={cell.data.Status} />;
      },
    },
    {
      headerName: 'Location',
      field: 'CustomerName',
      width: 160,
      filterParams: filterParams,
    },
    {
      headerName: 'Request Type',
      width: 130,
      field: 'CallType',
      filterParams: filterParams,
    },
    {
      headerName: 'Latest Update',
      width: 130,
      field: 'RepairRemarks',
      filterParams: filterParams,
    },
  ];
};
