import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  isCheckDollerProcingNonZero,
} from '../../../../utils/utils';

import { Api } from '../../../../shared/api/api';
import { each, isEmpty } from 'lodash';
import {
  Form,
  FormItem,
  FormRef,
  FormType,
  InputMask,
  SubmitBtn,
} from '../../../../components/form';
import {
  ClientStorageKey,
  EMAIL_REGEX,
  PAGE_COUNT,
  TENANT,
} from '../../../../constant/constant';
import storage from '../../../../utils/storage/storage';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { PushpinIcon } from '../../../../assets/svg/pushpin-icon';
import { BuildingIcon } from '../../../../assets/svg/building-icon';
import { useUser } from '../../../../context/UserContext';
import { useCart } from '../../context/CartContext';
import { CartItemWithColorClass } from '../../../../types/Product';
import { useFlexLayer } from '../../../../hooks/useFlexlayer';
import { Button } from '../../../../components/ui';
// import { Button } from '../../../../components/ui';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../../../store/store';

const OrderCheckout = () => {
  const { items, attachColorClass, clearCart } = useCart();
  const { customerInfo, customerAddresses, getCustomerAddresses } = useUser();
  const { flexLayer } = useFlexLayer();

  useEffect(() => {
    //Bandaid to whatever could possibly be happening, but this endpoint on rare occasions will 
    //return an empty array. This is a bandaid to fix that issue.
    //Need to identify the root cause of why this is happening.
    if(customerAddresses.length === 0) {
      getCustomerAddresses(+customerInfo.CustomerId);
    }
  }, [])

  const someItemsIncludeEquipmentNumber = useMemo(() => items.some(
    (item) => item.EquipmentNumber,
  ), [items]);

  // This is how we display the Color Dot next to the product name
  const itemsWithColor = items.map((item) => {
    return attachColorClass(item);
  });

  const totalCost = items.reduce((acc, item) => {
    return acc + +item.Price * item.Quantity;
  }, 0);

  const freightCharges = customerInfo.GlobalSettings.FreightCharges;
  const requiredFields = customerInfo.fieldMeta;
  const hideFields = customerInfo.HidefieldMeta;


  //Handle Shipping locations for the customer
  const shipmentAddressList = useMemo(() => customerAddresses.map((address: any) => {
    return {
      label: address?.SearchBy,
      value: address?.CustomerID,
      optionalData: address
    }
  }), [ customerAddresses ]);

  // Set the default address for the customer
  const defaultAddress = useMemo(() => {
    return shipmentAddressList.find((option) => parseInt(option.value) === parseInt(customerInfo.DefaultShippingLocationID))
  }, [shipmentAddressList, customerInfo.DefaultShippingLocationID]);

  const cartHasMultipleEquipments = useMemo(() => items.some((item) => {
    return item.Equipments && item.Equipments.length > 2;
  }), [items]);

  const cartHasAnyItemWithNoEquipment = useMemo(() => items.some((item) => {
    return !item.EquipmentID;
  }), [items]);

  const [defaultAttention, aetDefaultAttention] = useState('');


  const navigate = useNavigate();

  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );

  // const [isDefaultAddressSelected, setDefaultAddressSelected] = useState();

  const form: FormRef = useRef(null);
  const [shippingAddressObj, setShippingAddressObj] = useState<any>(null);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState<any>([]);

  const isMarimon = tenantName === TENANT.MARIMON;
  const [sendSuppliesByDeviceChecked, setsendSuppliesByDeviceChecked] =
    useState(!isMarimon);
  const [isShipSuppliesByDeviceLocation, setIsShipSuppliesByDeviceLocation] =
    useState(!isMarimon && !cartHasAnyItemWithNoEquipment);
  // const [defaultAddress, setDefaultAddress] = useState(false);


  const fetchEquipmentDetailsForAttnField = async (equipmentID: any) => {
    if(!equipmentID) return;
    try {
      const res: any = await flexLayer(`/equipments/fetchEquipmentDetailsForAttnField/${equipmentID}?EquipmentID=${equipmentID}`);
      if (res?.data) {
        let { LocationRemark: locationRemark, EquipmentNumber: equipmentNumber } = res.data;
        const maxLocationRemarkLength = 61 - (equipmentNumber?.length || 0);
        locationRemark = locationRemark?.substring(0, maxLocationRemarkLength);
        return locationRemark
          ? `${locationRemark} - ${equipmentNumber}`
          : `Manager on Duty - ${equipmentNumber}`;
      }
    } catch (e) {
      console.error(e);
    }
  }

  // Set the default attention field for the customer
  useEffect(() => {
    if(items.length == 0) return;

    if(!cartHasMultipleEquipments) {
      const equipmentIDs = items.map((item) => item.EquipmentID);
      const hasOnlyOneItem = equipmentIDs.every((e) => e === equipmentIDs[0]);

      if(hasOnlyOneItem) {
        fetchEquipmentDetailsForAttnField(equipmentIDs[0]).then((res) => {
          aetDefaultAttention(res);
        });
      }
    }
  }, [])

  const getBinId = () => {
    if (tenantName === TENANT.FLEXPRINT || tenantName === TENANT.SANDBOX) {
      return 2418;
    }

    if (tenantName === TENANT.FLOTECH) {
      return customerInfo?.WarehouseID === '803' ? '2418' : '8552';
    }
    if (tenantName === TENANT.MARIMON) {
      return 17749;
    }
    return '';
  }


  const placeOrder = async (placedOrderObj) => {
    try {
      return await Api.callAPI({
        url: `/api/v1/orders`,
        method: 'post',
        body: placedOrderObj,
        options: {
          isLoading: true,
          showErrorMessage: false,
          successMessage: 'Your order has been placed successfully.',
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  
  const handleSubmit = async (formData) => {

    try {
      const {
        UserId = '',
        HideZeroDollarPricing = '',
        CustomerId = '',
        EmailAddress = null,
        CanPrependPONumberAttentionField,
        ShippingMethod,
        FirstName = ' ',
        WarehouseID = '',
        LastName,
      } = customerInfo || {};
  
      const {
        Address = '',
        City = '',
        State = '',
        Zip = '',
        Country = '',
        CustomerName = '',
        CustomerID = '',
      } = !isShipSuppliesByDeviceLocation ? shippingAddressObj || {} : {};
  
      const {
        phone = '',
        PoNumber = '',
        ext = '',
        Email = '',
        attention = '',
        CC = '',
        onlyMyCalls = false,
        copyEmailConfirmation,
        department = '',
        floor = '',
      } = formData || {};
  
      const placedOrderData = {
        UserId,
        HideZeroDollarPricing,
        CustomerId,
        OptBillNumber: CustomerId,
        OptShipToNumber: !isShipSuppliesByDeviceLocation ? CustomerID : '',
        Phone: phone,
        PONumber: PoNumber,
        Extension: ext,
        Email,
        EmailAddress,
        ShipToATTN: CanPrependPONumberAttentionField
          ? `${PoNumber.trim()} ${attention.trim()}`
          : attention.trim(),
        ShipToStreet: Address,
        ShipToCity: City,
        ShipToState: State,
        ShipToZip: Zip,
        ShipToCountry: Country,
        ShipToName: CustomerName,
        ShipToID: CustomerID,
        ShippingMethod,
        CartItems: items.map((item) => ({ ...item, BinID: getBinId() })),
        FirstName,
        OrderCost: totalCost,
        Freight: !!totalCost ? freightCharges : 0,
        OtherEmailAddress: CC,
        WarehouseId: WarehouseID,
        IsDefaultShippingAddress: false,
        IsCopyEmailConfirmation: copyEmailConfirmation,
        IsShipSuppliesByDeviceLocation: isShipSuppliesByDeviceLocation,
        Description: `Online Order - ${FirstName} ${LastName} ${
          department.trim() ? ' -- Department:' + department.trim() : ''
        } ${floor.trim() ? ' -- Floor:' + floor.trim() : ''}`,
      };
  
      const res = await placeOrder(placedOrderData);
      clearCart(false);
      if (res && res.data) {
        let soid = res.data.map((soOrder) => soOrder.SOID).join(',');
        let localStorageCustomerInfo: any = storage.getJSONItem(
          ClientStorageKey.customerInfo,
        );
        localStorageCustomerInfo.IsDefaultShippingAddress = onlyMyCalls;
        localStorageCustomerInfo.DefaultShippingLocationID = onlyMyCalls
          ? CustomerID
          : '';
        storage.setJSONItem(
          ClientStorageKey.customerInfo,
          localStorageCustomerInfo,
        );
        navigate('/customer/thankyou/' + soid);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const checkEmails = (formDef, formState) => {
    const multipleEmailReqex = new RegExp(EMAIL_REGEX);
    const ccInputValue = formState.CC?.value;
    let isInvalidEmail = true;
    if (!isEmpty(ccInputValue)) {
      const emailArray = ccInputValue.split(',');
      each(emailArray, (email) => {
        isInvalidEmail = !multipleEmailReqex.test(email);
      });
      return !isInvalidEmail;
    } else {
      isInvalidEmail = false;
      return true;
    }
  }

  const IsPriceHeaderShow = (OrderItems: any) => {
    if(customerInfo?.HideZeroDollarPricing) {
      let flag = false;
      OrderItems?.map((item: any) => {
        if(parseInt(item.Price) > 0) {
          flag = true;
        }
      });
      return flag;
    } else {
      return true
    }
  }
  const IsTotalHeaderShow = (OrderItems: any) => {
    if(customerInfo?.HideZeroDollarPricing) {
      let flag = false;
      OrderItems?.map((item: any) => {
        if(parseInt(item.totalPriceOfOneProduct) > 0) {
          flag = true;
        }
      });
      return flag;
    } else {
      return true
    }
  }

  if(items.length === 0) {
    return (
      <div>
        <h1 className="text-2xl text-gray-800 font-medium mb-4">Checkout</h1>
        <div className="flex flex-col gap-2 h-[50vh] items-center justify-center w-full">
          <img
            className=""
            src={require('../../../../assets/images/img.png')}
          />
          <h1 className="text-2xl text-gray-800 font-medium">Your shopping cart is empty.</h1>
          <Button to={'/product/search'} className="rounded px-8 py-2">Shop Now</Button>
        </div>
      </div>
    )
  }

  return (
    <>
      <div>
        <h1 className="text-2xl text-gray-800 font-medium mb-4">Checkout</h1>
        <div className="border border-grey-200 rounded-md">
          <div className="productListing ">
            <div className="md:flex hidden bg-gray-100 rounded-md py-3 px-4">
              <div className="xl:w-9/12 md:w-2/5 w-full">Product</div>
              <div className="xl:w-1/12 md:w-1/5 w-1/3 text-center">
                {IsPriceHeaderShow(items) ? (
                  <div>Price</div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="xl:w-1/12 md:w-1/5 w-1/3 text-center">
                Quantity
              </div>
              <div className="xl:w-1/12 md:w-1/5  w-1/3 text-right">
                {IsTotalHeaderShow(items) ? (
                  <div>Total</div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div>
              {itemsWithColor ? (
                <>
                  {itemsWithColor.map((item: CartItemWithColorClass, cartItemIndex: number) => {
                    const totalPriceOfProduct = +item.Price * item.Quantity;
                    return (
                      <div key={'cartItem' + cartItemIndex}>
                        <div className="flex border-b border-gray-200 py-4 px-4 flex-wrap">
                          <div className="xl:w-9/12 md:w-2/5 w-full  md:mb-0 mb-4">
                            <div className="md:hidden block text-gray-500">
                              Product
                            </div>
                            <div className="flex">
                              <div className="w-4 mr-2 ">
                                {item?.tonerClass ? (
                                  <div
                                    className="w-4 h-4 rounded-full ItemDescription mt-1"
                                    style={{
                                      backgroundColor: item.tonerClass,
                                    }}
                                  ></div>
                                ) : (
                                  ''
                                )}
                              </div>
                              <h4 className="font-medium break-all sm:pr-6">
                                {item.ItemDescription}
                              </h4>
                            </div>
                            <div
                              className={`mt-2 flex text-sm text-gray-600 ml-6`}
                            >
                              <div>{item.Item}</div>

                              {item.EquipmentNumber ? (
                                <div className="ml-4">
                                  EQ# {item.EquipmentNumber}
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          <div className="xl:w-1/12 md:w-1/5  w-1/3 md:text-center">
                            {isCheckDollerProcingNonZero(item.Price) ? (
                              <>
                                <div className="md:hidden block text-gray-500">
                                  Price
                                </div>
                                <div>${item.Price}</div>
                              </>
                            ) : (
                              ''
                            )}
                          </div>

                          <div className="xl:w-1/12 md:w-1/5  w-1/3 text-center ">
                            {item.Quantity ? (
                              <>
                                <div className="md:hidden block text-gray-500">
                                  Quantity
                                </div>
                                <div className="qty-number">
                                  {item.Quantity}
                                </div>
                              </>
                            ) : (
                              ''
                            )}
                          </div>

                          {isCheckDollerProcingNonZero(totalPriceOfProduct) ? (
                            <div className="xl:w-1/12 md:w-1/5  w-1/3 text-right">
                              <div className="md:hidden block text-gray-500">
                                Total
                              </div>
                              <span className="font-medium">
                                ${totalPriceOfProduct}
                              </span>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                ''
              )}
            </div>
          </div>
          {(totalCost && totalCost > 0 ) ? (
            <div>
            {isCheckDollerProcingNonZero(totalCost.toFixed(2)) ? (
              <div className="flex text-right sub-total border-b border-gray-200 py-4 mb-4 px-4 font-medium">
                <div className="md:w-10/12 w-3/5">
                  Subtotal :
                </div>
                <div className="md:w-2/12 w-2/5">
                  <span className="pl-4">${totalCost.toFixed(2)}</span>
                </div>
              </div>
            ) : (
              ''
            )}

            {freightCharges ? (
                <div className="flex text-right sub-total border-b border-gray-200 pb-4 mb-4 px-4 font-medium">
                  <div className="md:w-10/12 w-3/5">
                    Shipping & Handling :
                  </div>
                  <div className="md:w-2/12 w-2/5">
                    <span className="pl-4">${freightCharges.toFixed(2)}</span>
                  </div>
                </div>
            ) : (
              ''
            )}
          </div>) : ('') }


          {(isCheckDollerProcingNonZero(totalCost.toFixed(2))) ? (
            <div className={`flex text-right sub-total pb-4 px-4 font-bold ${!totalCost ? 'pt-4' : ''}`}>
              <div className="md:w-10/12 w-3/5">Order Total : </div>
              <div className="md:w-2/12 w-2/5">
                <span className="pl-4 font-bold">${totalCost.toFixed(2)}</span>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {/*form start*/}

      <div>
          <Form
            formRef={form}
            onSubmit={handleSubmit}
            defaultValues={{
              sendSuppliesByDevice: isShipSuppliesByDeviceLocation,
              Email: customerInfo?.EmailAddress,
              orderPhone: customerInfo?.orderPhone,
              onlyMyCalls: customerInfo?.IsDefaultShippingAddress,
              address: selectedShippingAddress,
              copyEmailConfirmation: true
            }}
          >
            <div className="md:flex gap-6 my-8">
              <div className="md:w-1/2">
                <div className='flex w-full'>
                  <div className='w-6 mr-2'>
                    <PushpinIcon />
                  </div>
                  <div className='w-full'>
                    <h3 className="font-semibold mb-4">
                      Shipping Details
                    </h3>
                    <div className="flex flex-col gap-4">
                      {!hideFields?.poNumber ? (
                        <div className="mb-3">
                          <FormItem
                            formDef={{
                              name: 'PoNumber',
                              label: 'PO Number / Cost Center',
                              type: FormType.TEXT,
                              maxLength: 32,
                              required: requiredFields?.poNumber,
                            }}
                          />
                        </div>
                      ) : (
                        ''
                      )}

                      {!isMarimon && !hideFields?.address && !cartHasAnyItemWithNoEquipment ? (
                        <div className="mb-3">
                          <FormItem
                            formDef={{
                              name: 'sendSuppliesByDevice',
                              label: 'Ship supplies by device location',
                              type: FormType.CHECKBOX,
                              onChange: (checkedValue: any) => {
                                setIsShipSuppliesByDeviceLocation(!!checkedValue);
                                setsendSuppliesByDeviceChecked(checkedValue);
                              },
                            }}
                          />
                        </div>
                      ) : (
                        ''
                      )}

                        <div className="mb-3">
                          <FormItem
                            formDef={{
                              name: 'address',
                              label: 'Address',
                              className:'addressDropdown',
                              type: FormType.SELECT,
                              options: shipmentAddressList,
                              disabled: (isShipSuppliesByDeviceLocation && someItemsIncludeEquipmentNumber) && !cartHasAnyItemWithNoEquipment,
                              ClearSelectedIcon: null,
                              selectShowCheckboxes: false,
                              onChange: (selectedOp) => {
                                setSelectedShippingAddress(selectedOp)
                                if (selectedOp && selectedOp?.length > 0) {
                                  const address = shipmentAddressList.find(
                                    ({ value }) =>
                                      value === selectedOp[0].value,
                                  );
                                  setShippingAddressObj(address?.optionalData);
                                } else {
                                  setShippingAddressObj(null);
                                }
                              },
                              defaultValue: defaultAddress ? [defaultAddress.value] : [],
                              required: !cartHasAnyItemWithNoEquipment ? !sendSuppliesByDeviceChecked : true,
                              // requiredInputs: ['sendSuppliesByDevice'],
                            }}
                          />
                        </div>

                      {/* {!isShipSuppliesByDeviceLocation ? (
                        <div className="mb-3">
                        <FormItem
                          formDef={{
                            name: 'onlyMyCalls',
                            label: 'Make this my default address',
                            type: FormType.CHECKBOX,
                            disabled: !shippingAddressObj,
                          }}
                        />
                        </div>
                      ) : (
                        ''
                      )} */}

                      {shippingAddressObj ? (
                        <div className="mb-3">
                          <b>Shipping Address</b>
                          <div>
                            <div>{shippingAddressObj?.CustomerName}</div>
                            <div>{shippingAddressObj?.Address}</div>
                            <div>{shippingAddressObj?.City},{' '}
                            {shippingAddressObj?.State},{' '}
                              {shippingAddressObj?.Zip}
                            </div>
                            <div>{shippingAddressObj?.Country}</div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-1/2">
                <div className='flex w-full'>
                  <div className='w-6 mr-2'>
                    <BuildingIcon />

                  </div>
                  <div className='w-full'>
                    <h3 className="font-semibold mb-4">
                      Contact Information
                    </h3>
                    <div className="mg:grid grid-cols-2 gap-4 items-center">
                      {!hideFields?.attention ? (
                        <div className="mb-3">
                          <FormItem
                            formDef={{
                              name: 'attention',
                              label: 'Attention',
                              defaultValue: defaultAttention,
                              type: FormType.TEXT,
                              maxLength: 64,
                              required: true,
                              errors: {
                                //emptyFields['attention'].isOnlySpace && !attention.invalid
                                // todo
                              },
                            }}
                          />
                        </div>
                      ) : (
                        ''
                      )}


                        {!hideFields?.orderPhone ? (
                          <div className="mb-3">
                            <div className="flex gap-4">
                              <div className="w-3/5">
                                <FormItem
                                  formDef={{
                                    name: 'phone',
                                    label: 'Phone',
                                    type: FormType.TEXT,
                                    mask: InputMask.PHONE,
                                    required: requiredFields?.orderPhone,
                                  }}
                                />
                              </div>
                              <div className="w-2/5">
                                <FormItem
                                  formDef={{
                                    name: 'ext',
                                    label: 'Ext',
                                    type: FormType.TEXT,
                                    maxLength: 10,
                                    pattern: PAGE_COUNT,
                                    errors: {
                                      pattern: 'Please provide valid extension.',
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}

                        {!hideFields?.email ? (
                          <div className="mb-3">
                            <FormItem
                              formDef={{
                                name: 'Email',
                                label: 'Email',
                                type: FormType.EMAIL,
                                required: true,
                                errors: {
                                  required: 'Please provide email.',
                                },
                              }}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      <div className="my-6">
                        <FormItem
                          formDef={{
                            name: 'copyEmailConfirmation',
                            label: "Copy on email confirmation",
                            type: FormType.CHECKBOX,
                          }}
                        />
                      </div>

                        {!hideFields?.cc ? (
                          <div className="mb-3">
                            <FormItem
                              formDef={{
                                name: 'CC',
                                label: 'CC',
                                type: FormType.TEXT,
                                required: requiredFields?.cc,
                                errors: {
                                  required: 'Please provide email.',
                                  customValidation:
                                    'Field contains invalid email address.',
                                },
                                CustomValidation: (formDef, formState) => {
                                  return checkEmails(formDef, formState);
                                },
                              }}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      {!hideFields?.department || !hideFields?.floor ? (
                        <div className="flex gap-4">
                          <div className="w-3/5">
                            {!hideFields?.department ? (
                              <>
                                <FormItem
                                  formDef={{
                                    name: 'department',
                                    label: 'Department',
                                    maxLength: 70,
                                    type: FormType.TEXT,
                                    required: requiredFields?.department,
                                    errors: {
                                      required: 'Please enter department.',
                                    },
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="w-2/5">
                            {!hideFields?.floor ? (
                              <>
                                <FormItem
                                  formDef={{
                                    name: 'floor',
                                    label: 'Floor',
                                    maxLength: 10,
                                    type: FormType.TEXT,
                                    required: requiredFields?.floor,
                                    errors: {
                                      required: 'Please enter floor.',
                                    },
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      ) : ('')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end items-center">
              <Link
                to={'/customer/cart/'}
                className={'borderedBtn linkText hover:text-black mr-3 '}
              >
                Back to Cart
              </Link>
              <SubmitBtn className="commonBtn">Place Order</SubmitBtn>
            </div>
          </Form>
      </div>
    </>
  );
};

export default OrderCheckout;
