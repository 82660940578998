/**
 * A generic dropdown component.  It takes the children of the component
 * and hosts it in the component.  When the component is selected, it
 * drops-down the contentComponent and applies the contentProps.
 */
import React, { useEffect, useRef, useState } from 'react';

import { useDidUpdateEffect } from '../hooks/use-did-update-effect';
import { useKey } from '../hooks/use-key';
import { useSelectContext } from '../hooks/use-select-context';
import { dropdownHeaderIdPrefix, KEY } from '../lib/constants';
import SelectPanel from '../select-panel';
import { Cross } from '../select-panel/cross';
import { Arrow } from './arrow';
import { DropdownHeader } from './header';
import { Loading } from './loading';

const Dropdown = () => {
  const {
    t,
    onMenuToggle,
    ArrowRenderer,
    shouldToggleOnHover,
    isLoading,
    disabled,
    onChange,
    labelledBy,
    isSingleSelect,
    value,
    isOpen,
    defaultIsOpen,
    ClearSelectedIcon,
    closeOnChangedValue,
    isMenuTop = false,
    onClose,
    selectIsClearable,
    id,
  } = useSelectContext();

  useEffect(() => {
    //TODO: Clean up entire dropdown, littered with side effects from use of useEffect
    if (closeOnChangedValue || (isSingleSelect && value.length !== 0)) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setExpanded(false);
    }
  }, [value]);

  const [isInternalExpand, setIsInternalExpand] = useState(true);
  const [expanded, setExpanded] = useState(defaultIsOpen);
  const [hasFocus, setHasFocus] = useState(false);
  const FinalArrow = ArrowRenderer || Arrow;
  const dropdownHeaderDivId = `${dropdownHeaderIdPrefix}${id}`;
  const wrapper: any = useRef();

  useDidUpdateEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onMenuToggle && onMenuToggle(expanded);
  }, [expanded]);

  useEffect(() => {
    if (defaultIsOpen === undefined && typeof isOpen === 'boolean') {
      setIsInternalExpand(false);
      setExpanded(isOpen);
    }
  }, [isOpen]);

  const handleKeyDown = (e) => {
    // allows space and enter when focused on input/button
    if (
      ['text', 'button'].includes(e.target.type) &&
      [KEY.SPACE, KEY.ENTER].includes(e.code)
    ) {
      return;
    }

    if (isInternalExpand) {
      if (e.code === KEY.ESCAPE) {
        setExpanded(false);
        wrapper?.current?.focus();
      } else {
        setExpanded(true);
      }
    }
    e.preventDefault();
  };

  useKey([KEY.ENTER, KEY.ARROW_DOWN, KEY.SPACE, KEY.ESCAPE], handleKeyDown, {
    target: wrapper,
  });

  const handleHover = (iexpanded: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isInternalExpand && shouldToggleOnHover && setExpanded(iexpanded);
  };

  const handleFocus = () => !hasFocus && setHasFocus(true);

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget) && isInternalExpand) {
      setHasFocus(false);
      setExpanded(false);
      onClose(null);
    }
  };

  const handleMouseEnter = () => handleHover(true);

  const handleMouseLeave = () => handleHover(false);

  const toggleExpanded = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isInternalExpand && setExpanded(isLoading || disabled ? false : !expanded);

    if (expanded) {
      onClose(null);
    }
  };

  const handleClearSelected = (e) => {
    e.stopPropagation();
    onChange([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isInternalExpand && setExpanded(false);
    onClose([]);
  };

  return (
    <div
      tabIndex={0}
      className="dropdown-container"
      aria-labelledby={labelledBy as string}
      aria-expanded={expanded}
      aria-readonly={true}
      aria-disabled={disabled}
      ref={wrapper}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`dropdown-heading ${
          !isSingleSelect && value && value.length ? 'customHeight' : ''
        }`}
        onClick={toggleExpanded}
      >
        <div className="dropdown-heading-value h-24" id={dropdownHeaderDivId}>
          <DropdownHeader
            expanded={expanded}
            setExpanded={setExpanded}
            dropdownHeaderDivId={dropdownHeaderDivId}
          />
        </div>
        {isLoading && <Loading />}
        {value.length > 0 && (selectIsClearable || !isSingleSelect) && ClearSelectedIcon !== null && (
          <button
            type="button"
            className="clear-selected-button"
            onClick={handleClearSelected}
            disabled={disabled}
            aria-label={t('clearSelected')}
          >
            {ClearSelectedIcon || <Cross />}
          </button>
        )}
        <FinalArrow expanded={expanded} />
      </div>
      {expanded && (
        <div
          className={`dropdown-content ${
            isMenuTop ? 'topOption' : 'bottomOption'
          }`}
        >
          <div className="panel-content">
            <SelectPanel />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
