import React from 'react';
import { EditIcon } from '../../../../assets/svg/edit-icon';
import { TrashIcon } from '../../../../assets/svg/trash-icon';
import { FilterType } from "../../../../shared/api-table/table-helper";

export const SuperadminTableFields = ({ onActionClick }: any) => {
  const filterParams = {
    filterOptions: ['contains'],
    defaultOption: 'contains',
    maxNumConditions: 1,
    debounceMs: 200,
  };
  return [
    {
      headerName: 'User Name',
      field: 'UserName',
      sortable: false,
      width: 200,
      filterParams: filterParams,
      filter: FilterType.AgTextColumnFilter,
    },

    {
      headerName: 'Status',
      field: 'Active',
      filter: false,
      sortable: false,
      width: 200,
      cellRenderer: (options: any) => {
        return options?.data?.Active === true ? (
          <i
            className="fa fa-check"
            style={{ color: 'green' }}
            aria-hidden="true"
          ></i>
        ) : (
          <i
            className="fa fa-close"
            style={{ color: 'red' }}
            aria-hidden="true"
          ></i>
        );
      },
    },

    {
      field: 'Action',
      headerName: 'Action',
      isActionColumn: true,
      filter : null,
      cellRenderer: (cellEventData: any) => {
        return (
          <>
            <div className="flex items-center mt-3">
              <button
                title="Edit"
                className={'edit mr-3 block iconbtn'}
                onClick={() => {
                  onActionClick(cellEventData, 'edit');
                }}
              >
                <EditIcon />
              </button>
              <button
                title="Delete"
                className={'trash mr-3 block iconbtn'}
                onClick={() => {
                  onActionClick(cellEventData, 'trash');
                }}
              >
                <TrashIcon />
              </button>
            </div>
          </>
        );
      },
    },
  ];
};
