import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Layout } from './layout';
import { FaviconTitleManager } from './favicon-title-manager/favicon-title-manager';
import { Api } from '../../shared/api/api';
import storage from '../../utils/storage/storage';
import { AdminStorageKey } from '../../constant/constant';
import { APPLICATION_CONTEXT } from '../../utils/utils';
import { UserProvider } from '../../context/UserContext';

export const Admin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const withOutAuthUrl = ['/admin/login'];

  const [userInfo, setUserInfo] = useState(null);
  const [tokens, setTokens] = useState([]);

  const checkLoginUrls = (Url: string) => {
    return (
      Url.indexOf('/login') > -1 ||
      Url.indexOf('/set-password') > -1 ||
      Url.indexOf('forgot-password') > -1
    );
  };
  const checkLogin = async () => {
    try {
      const response = await Api.callAPI({
        url: '/api/v1/users/admin/checkLogin',
        method: 'post',
        options: {
          isLoading: true,
          isShowApiErrorMessage: false,
        },
      });

      const { userInfo: userExists, accessToken, refreshToken } = response?.data;

      if (userExists) {
        storage.setItem(AdminStorageKey.ACCESS_TOKEN, accessToken);
        storage.setItem(AdminStorageKey.REFRESH_TOKEN, refreshToken);
        setUserInfo(userExists);
        setTokens(userExists?.Tokens);
        if (checkLoginUrls(window.location.pathname)) {
          const customer: any = storage.getJSONItem(AdminStorageKey.userInfo);
          if (customer) {
            if (customer.Tokens.indexOf('ALL') > -1) {
              navigate('/admin/customer');
            } else if (customer.Tokens.indexOf('CAN_MANAGE_COMPANY') > -1) {
              navigate('/admin/customer');
            } else if (customer.Tokens.indexOf('CAN_VIEW_ACCESS_LOGS') > -1) {
              navigate('/admin/access-logs');
            } else if (
              customer.Tokens.indexOf('CAN_MANAGE_SUPER_ADMIN_USER') > -1
            ) {
              navigate('/adminUser/list/' + customer?._id);
            }
          } else {
            navigate('/admin/login');
          }
        }
      }
    } catch (e) {
      navigate('/admin/login');
    } finally {
    }
  };
  useEffect(() => {
    (async () => {
      await checkLogin();
    })();
  }, [location.pathname]);

  useEffect(() => {
    const htmlElement: any = document.getElementsByTagName('html');
    if (htmlElement && htmlElement.length) {
      htmlElement[0].classList.add('adminSite');
      htmlElement[0].classList.remove('clientSite');
    }
  }, [location?.pathname]);

  const renderComponent = () => {
    const isWithOutAuth = withOutAuthUrl.includes(location.pathname);
    return (
      <>
      <UserProvider isAdmin={true}>
        <APPLICATION_CONTEXT.Provider
          value={{
            isClientApp: false,
            userInfo: userInfo,
            setUserInfo: setUserInfo,
            tokens: tokens,
            setTokens: setTokens,
          }}
        >
          <FaviconTitleManager />
          {isWithOutAuth ? (
            <Outlet />
          ) : (
            <Layout>
              <Outlet />
            </Layout>
          )}
        </APPLICATION_CONTEXT.Provider>
        </UserProvider>
      </>
    );
  };

  return <>{renderComponent()}</>;
};
