import { useReducer, useState } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { InputMask } from '../form/types/FormTypes';
import { Cross } from './react-select/select-panel/cross';

interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'defaultValue'> {
  placeholder?: string;
  label?: string | React.ReactNode;
  color?: string;
  className?: string;
  fullWidth?: boolean;
  charachterLimit?: number;
  onEnter?: (e: any) => void;
  icon?: React.ReactNode;
  iconPosition?: 0 | 1;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  defaultValue?: string | number;
  mask?: InputMask;
  value?: string | number;
  uploadMultiple?: boolean;
  uploadAccept?: string;
  uploadMaxSize?: number;
  clearButton?: boolean;
  darkPlaceHolder?: boolean;
}

export interface InputDropDownOptions {
  name: string;
  value: string;
  component?: React.ReactNode;
  disabled?: boolean;
}

interface InputState {
  leftDropDownSelected: Array<InputDropDownOptions>;
  rightDropDownSelected: Array<InputDropDownOptions>;
  dropDownSelected: Array<InputDropDownOptions>;
  inputValue: string;
}

export const Input = ({
  placeholder,
  label,
  color,
  className,
  fullWidth,
  charachterLimit,
  onEnter,
  onChange,
  required,
  icon,
  iconPosition = 0,
  disabled,
  error,
  defaultValue,
  mask,
  uploadMultiple,
  uploadAccept,
  darkPlaceHolder,
  ...rest
}: InputProps) => {
  const { mainTheme } = useTheme();
  const background = color ? color : mainTheme.primary;
  const fullWidthClass = fullWidth ? 'w-full' : 'w-fit';
  const disabledClass = disabled ? mainTheme.disabled : '';
  const errorClass = error ? 'border-red-500' : '';
  const [showPassword, setShowPassword] = useState(false);

  const inputPadding =
  icon && !iconPosition ? 'pl-8' : icon && iconPosition ? 'pr-8' : 'px-2';

  const isPassword = rest.type === 'password';
  if (isPassword && showPassword) rest.type = 'text';

  if (isPassword) {
    icon = (
      <div
        className="cursor-pointer"
        onClick={() => setShowPassword(!showPassword)}
        title={showPassword ? 'Hide Password' : 'Show Password'}
      >
        {showPassword ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 text-gray-500"
            fill="currentColor"
            viewBox="0 0 576 512"
          >
            <path d="M129.1 361.4C93.6 327.2 67.7 286.9 52.5 256c15.1-30.9 41-71.2 76.6-105.4C171.8 109.5 224.9 80 288 80s116.2 29.5 158.9 70.6c35.6 34.3 61.5 74.5 76.6 105.4c-15.1 30.9-41 71.2-76.6 105.4C404.2 402.5 351.1 432 288 432s-116.2-29.5-158.9-70.6zM288 480c158.4 0 258-149.3 288-224C546 181.3 446.4 32 288 32S30 181.3 0 256c30 74.7 129.6 224 288 224zm0-144c-44.2 0-80-35.8-80-80c0-5.4 .5-10.6 1.5-15.7L288 256l-15.7-78.5c5.1-1 10.3-1.5 15.7-1.5c44.2 0 80 35.8 80 80s-35.8 80-80 80zM160 256c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-8.6 0-17 .8-25.1 2.5c-50.3 10-90 49.5-100.3 99.7l-.1 .7c-1.6 8.1-2.5 16.5-2.5 25.1z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 text-gray-500"
            fill="currentColor"
            viewBox="0 0 640 512"
          >
            <path d="M25.9 3.4C19-2 8.9-.8 3.4 6.1S-.8 23.1 6.1 28.6l608 480c6.9 5.5 17 4.3 22.5-2.6s4.3-17-2.6-22.5L25.9 3.4zM605.5 268.3c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-51.2 0-96 14.8-133.9 36.8l27.3 21.5C244.6 74.2 280.2 64 320 64c70.4 0 127.7 32 170.8 72c43.1 40 71.9 88 85.2 120c-9.2 22.1-25.9 52-49.5 81.5l25.1 19.8c25.6-32 43.7-64.4 53.9-89zM88.4 154.7c-25.6 32-43.7 64.4-53.9 89c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c51.2 0 96-14.8 133.9-36.8l-27.3-21.5C395.4 437.8 359.8 448 320 448c-70.4 0-127.7-32-170.8-72C106.1 336 77.3 288 64 256c9.2-22.1 25.9-52 49.5-81.5L88.4 154.7zM320 384c16.7 0 32.7-3.2 47.4-9.1l-30.9-24.4c-5.4 .9-10.9 1.4-16.5 1.4c-51 0-92.8-39.8-95.8-90.1l-30.9-24.4c-.9 6-1.3 12.2-1.3 18.5c0 70.7 57.3 128 128 128zM448 256c0-70.7-57.3-128-128-128c-16.7 0-32.7 3.2-47.4 9.1l30.9 24.4c5.4-.9 10.9-1.4 16.5-1.4c51 0 92.8 39.8 95.8 90.1l30.9 24.4c.9-6 1.3-12.2 1.3-18.5z" />
          </svg>
        )}
      </div>
    );
    iconPosition = 1;
  }

  //To be replaced with Font Awesome icons

  const [inputState, setInputState] = useReducer(
    (state: InputState, newState: any) => ({ ...state, ...newState }),
    {
      inputValue: defaultValue || '',
    },
  );

  const maskValue = (value: string) => {
    if (!value || !mask) return value;
    let newValue = value;
    let previousValue = inputState.inputValue;
    let thisMask = '';

    switch (mask) {
      case InputMask.PHONE:
        if (newValue.length == 18) return newValue.substring(0, 17);
        if (newValue.startsWith('+1')) newValue = newValue.substring(2);
        newValue = newValue.replace(/\D/g, '').substring(0, 10);
        previousValue = previousValue.replace(/\D/g, '');
        thisMask = '(___) ___-____';

        if (newValue.length === 10) {
          thisMask = '+1 (___) ___-____';
        }

        break;
      case InputMask.CURRENCY:
        newValue = newValue.replace(/\D\./g, '');
        newValue = newValue.replace(/(\d{2})(\d{2})/, '$1.$2');
        break;
      case InputMask.DATE:
        newValue = newValue.replace(/\D\//g, '');
        newValue = newValue.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
        break;
      case InputMask.TIME:
        newValue = newValue.replace(/\D\:/g, '');
        newValue = newValue.replace(/(\d{2})(\d{2})(\d{4})/, '$1:$2:$3');
        break;
      case InputMask.ZIP:
        newValue = newValue.replace(/\D/g, '');
        newValue = newValue.replace(/(\d{5})(\d{4})/, '$1-$2');
        break;
      case InputMask.CREDIT_CARD:
        // Remove non-digit characters
        newValue = newValue.replace(/\D/g, '');

        // Add dashes every four digits
        newValue = newValue.replace(/(\d{4})(?=\d)/g, '$1-');

        // Trim the newValue to a maximum length of 19 characters (typical credit card length)
        newValue = newValue.substring(0, 19);
        break;
      case InputMask.IP:
      case InputMask.IPV4:
        newValue = newValue
          .replace(/\D\./g, '')
          .replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, '$1.$2.$3.$4')
          .substring(0, 15);
        break;
      case InputMask.IPV6:
        newValue = newValue.replace(/\D\:/g, '');
        break;
      case InputMask.MAC:
        newValue = newValue
          .replace(/[^a-zA-Z0-9\:\-]/g, '')
          //replace newValue again, to follow mac address format A1-B2-C3-D4-E5-F6
          .replace(
            /([0-9a-zA-Z]{2})([0-9a-zA-Z]{2})([0-9a-zA-Z]{2})([0-9a-zA-Z]{2})([0-9a-zA-Z]{2})([0-9a-zA-Z]{2})/,
            '$1:$2:$3:$4:$5:$6',
          )
          .substring(0, 17);

        break;
      case InputMask.SSN:
        newValue = newValue
          .replace(/[^\d-]/g, '')
          .replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3')
          .substring(0, 11);
        break;
      default:
        break;
    }

    if (!thisMask) return newValue;

    const thisMaskArray = thisMask.split('');

    for (let i = 0; i < newValue.length; i++) {
      thisMaskArray[thisMaskArray.indexOf('_')] = newValue[i];
    }

    if (
      (newValue.length == previousValue.length &&
        previousValue.length > 1 &&
        newValue == previousValue) ||
      newValue.length < previousValue.length
    ) {
      let firstUnderscore = thisMaskArray.indexOf('_');

      if (firstUnderscore === -1) {
        thisMaskArray[thisMaskArray.length - 1] = '_';
      } else {
        let previousCharacter = thisMaskArray[firstUnderscore - 1];
        let nextPosition = firstUnderscore - 1;

        while (nextPosition > 0 && isNaN(parseInt(previousCharacter))) {
          nextPosition = nextPosition - 1;
          previousCharacter = thisMaskArray[nextPosition];
        }

        thisMaskArray[nextPosition] = '_';
      }
    }

    newValue = thisMaskArray.join('');

    if (!/[0-9]/.test(newValue)) {
      newValue = '';
    }

    return newValue;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //Remove white space from input
    let value = event.target?.value;

    error = null;

    if (rest.type === 'file') {
      setInputState({ inputValue: value || '' });
      return onChange && onChange(event);
    }

    if (rest.type === 'number') value = value.replace(/\D\./g, '');
    if (rest.type === 'date') value = value.replace(/\D\//g, '');

    //If the input is a number, remove all non-numeric characters
    event.target.value = maskValue(value);
    setInputState({ inputValue: value });
    return onChange && onChange(event);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if( rest.type === 'number' ) {
      if (event.key === 'e' || event.key === '-') {
        event.preventDefault();
      }
    }
    if (event.key === 'Enter') {
      return onEnter && onEnter(event);
    }
  };

  const RenderBottomText = () => {
    if (error) return <span className="text-xs text-red-500">{error}</span>;
    if((charachterLimit - inputState.inputValue.length) < 0) {
      return <span className="text-xs text-red-500">You have entered more than {charachterLimit} characters</span>;
    } else {
      if(charachterLimit) {
        return (
          <span className="text-xs text-gray-500">
          {charachterLimit - inputState.inputValue.length} characters remaining
        </span>
        );
      }
    }
  };


  return (
    <div
      className={`flex flex-col ${fullWidthClass} ${className} relative`}
      key={label?.toString()}
    >
      {label && (
        <label htmlFor={label.toString()} className="text-sm text-gray-500">
          {label} {required && <span className="text-red-400">*</span>}
        </label>
      )}
      <div className={`${fullWidthClass} flex relative`}>
        <div className={`relative ${fullWidthClass}`} key={`input:${label}`}>
          {icon && iconPosition === 0 && (
            <div className="absolute top-1/2 left-5 transform -translate-x-1/2 -translate-y-1/2 text-gray-400">
              {icon}
            </div>
          )}
          <input
            id={label?.toString()}
            type={rest.type || 'text'}
            className={`${background} ${disabledClass} rounded ${inputPadding} px-2 py-1 border focus:outline-none ${fullWidthClass} ${errorClass} ${ darkPlaceHolder ? 'placeholder-gray-700' : 'placeholder-gray-400' }`}
            placeholder={placeholder}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            maxLength={charachterLimit}
            disabled={disabled}
            aria-disabled={disabled}
            value={inputState.inputValue}
            multiple={uploadMultiple}
            accept={uploadAccept}
            {...rest}
          />
          {icon && iconPosition === 1 && (
            <div className="absolute top-1/2 right-1 transform -translate-x-1/2 -translate-y-1/2 text-gray-400">
              {icon}
            </div>
          )}
          { rest.clearButton && inputState.inputValue && (
            <div className="absolute top-1/2 right-1 transform -translate-x-1/2 -translate-y-1/2 text-gray-400 cursor-pointer" onClick={() => handleInputChange({ target: { value: ''}} as React.ChangeEvent<HTMLInputElement>)}>
                    <Cross />
            </div>)}
        </div>
      </div>
      <div><RenderBottomText /></div>
    </div>
  );
};
