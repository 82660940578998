import React from 'react';
import { MainRouting } from './routing';
import { Provider } from 'react-redux';
import { getStore } from './store/store';

import { ThemeProvider } from './context/ThemeContext';
import './global.scss';
import Notification from './shared/notification/notification';
import { setUtilStore } from './utils/utils';
import { ModalProvider } from './context/ModalConext';
import Loader from './shared/loader/loader';

function App() {
  const store = getStore();
  setUtilStore(store);
  return (
    <Provider store={store}>
      <ThemeProvider>
        <ModalProvider>
          <Notification />
          <Loader />
          <MainRouting />
        </ModalProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
