import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../../../context/UserContext';

export const LocationTableField = ({
  selectedLocation = [],
  isControlPressed,
}: any) => {
  const { customerInfo } = useUser();
  const filterParams = {
    filterOptions: ['contains'],
    debounceMs: 200,
    defaultOption: 'contains',
    maxNumConditions: 1,
  };
  const navigate = useNavigate();
  const columns = [
    {
      headerName: 'Location',
      field: 'CustomerName',
      width: 200,
      filterParams: filterParams,
      cellRenderer: (options: any) => {
        return (
          <Link
            className="service-request-view linkText"
            to={
              '/customer/locations/' +
              options.data.CustomerID +
              `?location=${selectedLocation ? selectedLocation.join(',') : ''}`
            }
          >
            {options.value}
          </Link>
        );
      },
      onCellClicked: (options: any) => {
        if (
          options.event.target.tagName === 'A' &&
          !isControlPressed(options.event)
        ) {
          options.event.preventDefault();
          options.event.stopPropagation();
          navigate(
            '/customer/locations/' +
              options.data.CustomerID +
              `?location=${selectedLocation ? selectedLocation.join(',') : ''}`,
          );
        }
      },
    },
    {
      headerName: 'Devices',
      field: 'TotalEquipments',
      filterParams: filterParams,
      width: 120,
    },
    {
      headerName: 'Open Orders',
      field: 'TotalOrders',
      filterParams: filterParams,
      width: 150,
    },
    {
      headerName: 'Open Requests',
      field: 'TotalServiceRequests',
      filterParams: filterParams,
      width: 170,
    },
    {
      headerName: 'Address',
      field: 'Address',
      filterParams: filterParams,
      width: 150,
    },
    {
      headerName: 'City',
      field: 'City',
      filterParams: filterParams,
      width: 100,
    },
    {
      headerName: 'State',
      field: 'State',
      filterParams: filterParams,
      width: 100,
    },
    {
      headerName: 'Zip',
      field: 'Zip',
      filterParams: filterParams,
      width: 100,
    },
  ];
  const regionField: any = {
    headerName: 'Region',
    field: 'TextVal',
    width: 120,
    filterParams: filterParams,
  };

  if (customerInfo?.ShowRegionCodeInGrid) {
    columns.splice(0, 0, regionField);
  }

  return columns;
};
