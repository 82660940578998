import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useUser } from '../../../context/UserContext';
import { useFlexLayer } from '../../../hooks/useFlexlayer';
import { CartItem, CartItemWithColorClass } from '../../../types/Product';
import { useTheme } from '../../../context/ThemeContext';
import { Tenant } from '../../../types/Tenant';
import { colorCoder } from '../../../utils/utils';
import AddCartProductExistDialog from '../product/add-cart-product-exist-dialog';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../../store/notification/notification.action';

interface CartContextFunctions {
    /** Items returned from the backend in your cart */
    items: CartItem[];
    /** Rehydrate the items state from the backend endpoint */
    rehydrate: () => void;
    /** The total count of items in the cart based on Quantity*/
    count: number;
    /** Loading state of the cart */
    loading: boolean;
    /** Modify the Quantity of items in the cart, this will optimistically update the frontend & backend */
    modifyQuantity: (item: CartItem, quantity: number) => void;
    /** Remove an item from the cart, this will optimistically update the frontend & backend */
    removeItem: (item: CartItem) => void;
    /** Attach a color class to the item based on the ItemDescription or CatDescription */
    attachColorClass: (item: CartItem) => CartItemWithColorClass;
    /** Add an item or items to the cart, watch an item to see if it's been ordered */
    addToCart: (item: CartItem | CartItem[], watchItem?: boolean) => void;
    /** Clear the cart, (optional) warn will display a pop up and call the backend to clear the cart, no warn will just clear the state. */
    clearCart: (warn?: boolean) => void;
}

const CartContext = createContext<CartContextFunctions | undefined>(
  undefined,
);

export const CartProvider = ({ children }: { children: React.ReactNode }) => {

    const { isAuthenticated, customerInfo } = useUser();
    const { flexLayer } = useFlexLayer();
    const { tenant } = useTheme();
    const openPopUpModalRef = useRef(null);
    const dispatch = useDispatch();

    const [ items, setItems ] = useState<CartItem[]>([]);
    const [ loading, setLoading ] = useState(isAuthenticated);

    const count = items.reduce((acc, item) => acc + item.Quantity, 0);

    const attachColorClass = (item: CartItem) => {
        const itemWithColorClass: CartItemWithColorClass = { ...item, tonerClass: '' };

        if(tenant === Tenant.MARIMON) {
            itemWithColorClass.tonerClass = item && item.ItemDescription && colorCoder(item.ItemDescription) || null;
        } else {
            itemWithColorClass.tonerClass = item && item.CatDescription && colorCoder(item.CatDescription) || null;
        }

        return itemWithColorClass;
    }

    const getCartItems = async () => {
        setLoading(true);
        const res = await flexLayer(`/carts/list/${customerInfo.CustomerId}?EmailAddress=${customerInfo.EmailAddress}`);
        setItems(res.data);
        setLoading(false);
    }

    const addToCart = async (item: CartItem | CartItem[], watchItem = true) => {

      setLoading(true);

      //optimistic update, add fake ._id to the item
      if(Array.isArray(item)) {
        setItems(prev => [...prev, ...item.map(i => ({ ...i, _id: Math.random().toString() }))]);
      } else {
        setItems(prev => [...prev, { ...item, _id: Math.random().toString() }]);
      }

      dispatch(
        showNotification({
          message: 'Your item has been added successfully in the cart.',
          type: 'success',
        }),
      );

      //We want to check the backend if the user had a delivery recently
      if(watchItem) {
          const checkItem = await flexLayer(`/carts/checkItems`, {
            ...item, CustomerId: customerInfo.CustomerId 
          }, { method: 'POST' });
          if(checkItem.data?.orderExist) {
              openPopUpModalRef.current.openPopUpModal({
                product: item,
                orderDetails: checkItem.data?.orderDetails,
              });
              return setLoading(false);
          }
      }

      const postObj = Array.isArray(item) ? item : {...item, CustomerId: customerInfo.CustomerId, EmailAddress: customerInfo.EmailAddress};

      await flexLayer(`/carts`, postObj, { method: 'POST' });
      await getCartItems();
      setLoading(false);
  }

    const modifyQuantity = async (item: CartItem, quantity: number) => {
        setLoading(true);

        const thisItemWithId = items.find(i => i._id == item._id);
        
        if(!item._id && !thisItemWithId) {
          await addToCart(item, false);
        } else {
          item._id = item._id || thisItemWithId._id;
        }

        let itemsWithUpdatedQuanity = items.map(i => {
          if(i._id === item._id) {
            return { ...i, Quantity: +quantity }
          }
          return i;
        });

        //optimistic update
        setItems(itemsWithUpdatedQuanity);
        const res = await flexLayer(`/carts/${item._id}`, { ...item, Quantity: +quantity }, { method: 'PUT' });
        //rehydrate
        //if res.data is different than the item we sent, we should rehydrate
        if(res.error) setItems(prev => prev.map(i => i._id === item._id ? item : i));
        setLoading(false);
    }

    const removeItem = async (item: CartItem) => {
        setLoading(true);
        if(!confirm('Are you sure you want to remove this item?')) return setLoading(false);
        //optimistic update
        setItems(prev => prev.filter(i => i._id !== item._id));
        const res = await flexLayer(`/carts/${item._id}/${customerInfo.CustomerId}?EmailAddress=${customerInfo.EmailAddress}&EquipmentID=${item.EquipmentID}`, null, { method: 'DELETE' });

        if(res.error) {
            setItems(prev => [...prev, item]);
        }
        
        setLoading(false);
    }


    const clearCart = async (warn = true) => {
        setLoading(true);
        setItems([]);
        if(warn) {
          if(!confirm('Are you sure you want to clear your cart?')) return setLoading(false);
          const res = await flexLayer(`/carts/${customerInfo.CustomerId}`, null, { method: 'DELETE' });
          setItems(res.data);
        }
        //optimistic update
        setLoading(false);
    }

    useEffect(() => {
        if(isAuthenticated) getCartItems();
    }, [isAuthenticated, customerInfo]);

  return (
    <CartContext.Provider
      value={{
        rehydrate: getCartItems,
        items,
        loading,
        count,
        modifyQuantity,
        removeItem,
        attachColorClass,
        addToCart,
        clearCart,
      }}
    >
      <AddCartProductExistDialog ref={openPopUpModalRef} />
      {children}
    </CartContext.Provider>
  );
};

/**
 * @returns CartContextFunctions
 * This hook is used to access anything related to a users cart.
 * @constant items - Items returned from the backend in your cart
 * @constant rehydrate - Rehydrate the items state from the backend endpoint
 * @constant count - The total count of items in the cart based on Quantity
 * @constant loading - Loading state of the cart
 * @function modifyQuantity - Modify the Quantity of items in the cart, this will optimistically update the frontend & backend
 * @function removeItem - Remove an item from the cart, this will optimistically update the frontend & backend
 * @function attachColorClass - Attach a color class to the item
 * @function addToCart - Add an item or items to the cart
 * @function clearCart - Clear the cart
 */
export function useCart() {
  const context = useContext<CartContextFunctions | undefined>(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
}
