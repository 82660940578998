import { Link, useNavigate } from "react-router-dom";
import { getTime, isCheckDollerProcingNonZero } from "../../../../../../../utils/utils";
import { Chip } from "../../../../../../../components/ui/Chip";

export const LocationOrderTableFields = () => {
  const navigate = useNavigate();

  const filterParams = {
    filterOptions: ['contains'],
    defaultOption: 'contains',
    maxNumConditions: 1,
    debounceMs: 200,
  };

  return [
    {
      headerName: 'Order No',
      field: 'SOCode',
      filterParams: filterParams,
      width: 150,
      cellRenderer: (options: any) => {
        return (
          <Link
            className="linkText"
            to={`/customer/order-detail/${options.data.SOID}`}
          >
            {options.data.SOCode}
          </Link>
        );
      },
      onCellClicked: (options: any) => {
        navigate('/customer/order-detail/' + options.data.SOID);
      },
    },
    {
      headerName: 'Order Placed',
      filter : null,
      renderCSVColumn: (cell: any) => {
        return cell?.CreatedDate ? getTime(cell?.CreatedDate).toString() : '';
      },
      filterParams: filterParams,
      field: 'CreatedDate',
      width: 230,
      cellRenderer: (cell: any) => {
        if (cell && cell.data) {
          const Date = getTime(cell.data.CreatedDate);
          return <div>{Date}</div>;
        } else {
          return '';
        }
      },
    },
    {
      headerName: 'Status',
      width: 150,
      filterParams: filterParams,
      field: 'Status',
      cellRenderer: (options: any) => {
        return <Chip status={options.data.Status} />;
      },
    },
    {
      headerName: 'Total Amount',
      width: 170,
      filterParams: filterParams,
      field: 'OrderCost',
      cellRenderer: (options: any) => {
        return <span>{isCheckDollerProcingNonZero(options?.data?.OrderCost?.toFixed(2)) ? `$${options?.data?.OrderCost?.toFixed(2)}` : '-'}</span>;
      },
    },
    {
      headerName: 'Order Type',
      width: 140,
      filterParams: filterParams,
      field: 'OrderType',
    },
  ];
};
