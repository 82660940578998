import { useNavigate } from "react-router-dom";
import { CancelBtn, Form, FormItem, FormType, InputMask, SubmitBtn } from "../../../../components/form";
import { Api } from "../../../../shared/api/api";
import { useEffect, useRef, useState } from "react";
import ApiTable from "../../../../shared/api-table/api-table";
import { ClientGridName } from "../../../../constant/constant";
import { DeviceRequestHistoryField } from "./device-history-table-field";
import { getLocalTimezone } from "../../../../utils/utils";

interface RequestDeviceProps {
    device: {
        EquipmentID: number | null;
        ItemID?: number;
        ModelID?: number;
        EquipmentNumber?: string;
        Description?: string;
        SerialNumber?: string;
        Model?: string;
        Location?: string;
    }
    clearSelectedDevice: () => void;
    problems: any;
    customer: any;
    oldRequest?: any;
    hasLocationContactDetail: boolean;
}


export const RequestDevice = ({device, clearSelectedDevice, problems, customer, oldRequest, hasLocationContactDetail}: RequestDeviceProps) => {
    // /api/v1/services/problems
    const [ isLoading, setIsLoading ] = useState(false);
    const [ thisDevice, setThisDevice ] = useState(null);
    const [ location, setLocation ] = useState(null);
    const [ deviceHistory, setDeviceHistory ] = useState([]);
    const navigate = useNavigate();
    const tableRef = useRef(null);

    const { ServiceRequestContactDefaultEmail: defaultEmail, ServiceRequestContactDefaultName: defaultName, ServiceRequestContactDefaultPhone: defaultPhone } = customer;
    const email = defaultEmail == 'locationContact' ? location?.Email : customer.EmailAddress;
    const phone = defaultPhone == 'locationContact' ? location?.Phone1?.trim() : customer.UserPhone;
    const name = defaultName == 'locationContact' ? location?.Attn : `${customer.FirstName} ${customer.LastName}`;
    const phoneext = defaultPhone == 'locationContact' ? location?.Phone2 : customer.UserPhoneExtension;
    
    const hasDevice = !!device.EquipmentID;

    customer.CustomerEmail = customer.EmailAddress;

    const getDeviceInfo = async () => {
        try {
            
            const thisDeviceData = await Api.callAPI({
                url: `/api/v1/equipments/${device.EquipmentID}?EquipmentId=${device.EquipmentID}`,
                method: 'GET',
            })

            setThisDevice(thisDeviceData?.data);

            if( hasLocationContactDetail && thisDeviceData.data ) {
                const locationData = await Api.callAPI({
                    url: `/api/v1/location`,
                    body: { LocationID: thisDeviceData.data.LocationId, UserId: customer.UserId, CustomerID: thisDeviceData.data.CustomerId },
                    method: 'put',
                    options: {
                      isLoading: false,
                      showErrorMessage: false,
                    },
                  })
                setLocation(locationData?.data[0]);
            }

            const openRequests = await Api.callAPI({
                url: `/api/v1/services/requestCalls`,
                method: 'PUT',
                body: {
                    //2 years ago
                    StartDate: new Date(new Date().setFullYear(new Date().getFullYear() - 5)).toISOString(),
                    EndDate:  new Date().toISOString(),
                    LocationId: null,
                    locationId: [],
                    CustomerId: customer.CustomerId,
                    rows: 150,
                    EquipmentNumber: device.EquipmentNumber.toLowerCase(),
                    pageNo: 1,
                    onlyMyCallsRequest: false,
                },
                options: {
                    isLoading: false,
                    showErrorMessage: false,
                },
            })

            setDeviceHistory(openRequests?.data?.data);


        } catch(e) {

        }
    }

    useEffect(() => {
        if( !!hasDevice ) getDeviceInfo();
    }, [device])

    const problemsValueAr = problems.map((data) => {
        return {
          value: data.ProblemCodeID,
          label: data.Description,
        };
      });

    const onSubmit = async (data) => {
        // /api/v1/services/requestCall
        setIsLoading(true);
        try {

            const deviceData = hasDevice ? {
                ...data,
                Caller: `${data.Caller} -- ${data.CallerPhone}${data.CallerPhoneEXT ? `/${data.CallerPhoneEXT}` : ""} --\n${data.Email}`,
                CustomerName: data.Caller.split(' ')[0],
                TriageOOACalls: customer.TriageOOACalls,
                TriageW2Calls: customer.TriageW2Calls,
                TechnicianNumber: thisDevice.TechnicianID,
                EmailAddress: data.Email,
                CustomerId: thisDevice.CustomerId,
                LocationID: thisDevice.LocationId,
                EquipmentNumber: thisDevice.EquipmentID,
                EquipmentAddress: thisDevice.Address,
                EquipmentCity: thisDevice.City,
                EquipmentState: thisDevice.State,
                EquipmentCountry: thisDevice.Country,
                EquipmentZip: thisDevice.Zip,
                Equipment: device.EquipmentNumber,
                EquipmentDescription: device.Description,
                ProblemCode1ID: data.ProblemCode1ID[0],
                PrimarySymptomValue: problemsValueAr.find(problem => problem.value == data.ProblemCode1ID)?.label,
                Model: device.Model,
                SerialNumber: device.SerialNumber,
                IsCopyEmailConfirmation: true, // unsure why this is defaulted to true in old fcp
                email: customer.EmailAddress,

            } : {
                ...data,
                Caller: `${data.Caller} -- ${data.CallerPhone}${data.CallerPhoneEXT ? `/${data.CallerPhoneEXT}` : ""} --\n${data.Email}`,
                ContactName: data.Caller,
                ContactNumber: `${data.CallerPhone} ${data.CallerPhoneEXT ? `/${data.CallerPhoneEXT}` : ""}`,
                BackupContactNumber: `${data.BackupContactNumber} ${data.BackupContactNumberExt ? `/${data.BackupContactNumberExt}` : ""}`,
                customerInfo: customer,
                Date: new Date(),
            };

            if( customer.AllowServiceRequestNumber && hasDevice ) {
                deviceData.AllowServiceRequestNumber = customer.AllowServiceRequestNumber;
                deviceData.WorkOrderNumber = `${deviceData.serviceRequestPrefix}${deviceData.Number}`;
                deviceData.serviceRequestPrefix = customer.Prefix.toUpperCase();
            }

            const details = await Api.callAPI({
                url: hasDevice ?
                    `/api/v1/services/requestCall` :
                    `/api/v1/services/requestWithoutDevice`,
                method: 'POST',
                body: deviceData,
                options: {
                    successMessage: hasDevice ?
                      'Your Service Request has been submitted.' :
                      'Your Service Request has been sent to our team for review, and will not be immediately visible on the portal.',
                    errorMessage: 'Unknown error occurred, please try again.',
                }
            })

            if(!!data.ExtendedNotes)  {
                const noteObj = {
                    SCCallID: details?.data?.CallID,
                    SCWorkOrderNumber: details?.data?.WorkOrderNumber,
                    Note: data.ExtendedNotes.trim(),
                    SCCallNumber: details?.data?.CallCode,
                    System: 'FCP_CUSTOMER',
                    EmailAddress: customer.CustomerEmail,
                    Type: 'EXTENDED_NOTE',
                    Timezone : getLocalTimezone(),
                    Date: new Date(),
                    successMessage: false,
                  }
                  await Api.callAPI({
                    url: `/api/v1/zendesk/ooa-portal/technician-update`,
                    method: 'post',
                    body: noteObj,
                  });
            }

            if(!hasDevice) {
                clearSelectedDevice();
            } else {
                //Navigate to thank you
                navigate(`/service/service-request-detail/${details?.data?.WorkOrderID}`)

            }
        } catch (e) {
        }
        setIsLoading(false);
    }

    const columns = DeviceRequestHistoryField();

    return <div className="w-full">
        <Form onSubmit={onSubmit}>
        { (customer.AllowServiceRequestNumber && hasDevice) && <div className="">
                <div className="font-bold text-lg">Service Request Number</div>
                <div className="flex">
                    <FormItem formDef={{
                        name: 'serviceRequestPrefix',
                        label: 'Prefix',
                        type: FormType.TEXT,
                        disabled: true,
                        defaultValue: customer.Prefix.toUpperCase(),
                        required: true,
                        className: 'w-fit pr-4'
                    }}/>
                    <FormItem formDef={{
                        name: 'Number',
                        label: 'Number',
                        type: FormType.NUMBER,
                        minLength: 4,
                        maxLength: 8,
                        required: true,
                        errors: {
                            minLength: 'Service Request Number must be atleast 4 digits.',
                            maxLength: 'Service Request Number must be less than 8 digits.',
                        }
                    }}/>
                </div>
            </div>}
            <div className="font-bold text-lg mt-4">Problem Details</div>
		    { hasDevice && <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-6">
                <FormItem formDef={{
                    name: 'ProblemCode1ID',
                    label: 'Primary Symptom',
                    required: true,
                    type: FormType.SELECT,
                    options: problemsValueAr,
                    selectShowCheckboxes: false,
                    optionKeyMapObject: {
                        labelKey: 'CustomerName',
                        valueKey: 'CustomerID',
                    },
                    className: 'rmsc-slim'
                }}/>
                <FormItem formDef={{
                    name: 'ProblemCode1Description',
                    label: 'Description',
                    defaultValue: oldRequest?.ProblemDescription[0],
                    type: FormType.TEXT,
                    maxLength: 60,
                    required: true,
                }}/>
            </div>}

            { hasDevice && <FormItem formDef={{
                name: 'Description',
                label: 'Comments/Special Requirements',
                defaultValue: oldRequest ? `${oldRequest.WorkOrderNumber} issue not resolved` : '',
                required: customer.fieldMeta?.comments,
                type: FormType.TEXTAREA,
                maxLength: 100,
                className: 'mb-4'
            }}/>}

            { !hasDevice && <>
                <FormItem formDef={{
                    name: 'Model',
                    label: 'Model',
                    type: FormType.TEXT,
                    className: 'my-4',
                    required: true,
                }}/>
                <FormItem formDef={{
                    name: 'Address',
                    label: 'Address',
                    type: FormType.TEXT,
                    className: 'mb-4',
                    required: true,
                }}/>
                <FormItem formDef={{
                    name: 'EquipmentNumber',
                    label: 'Equipment Number',
                    type: FormType.TEXT,
                    className: 'mb-4'
                }}/>
                <FormItem formDef={{
                    name: 'Description',
                    label: 'Problem Description',
                    placeholder: `Describe the nature of the problem you're experiencing…`,
                    type: FormType.TEXTAREA,
                    maxLength: 5000,
                    required: true,
                }}/>
            </>}

            { hasDevice && customer?.GlobalSettings?.EnableCommunicationFromCustomersToZendesk && <div>
                <div className="text-sm text-gray-500">Extended Notes { customer.fieldMeta?.extendedNotes && <span className="text-red-400">*</span> }</div>
                <span className="text-xs text-gray-500">
                    (NOTE: Your submission will be reviewed by our team and given to the appropriate person before it's visible here on your Service Request)
                </span>
                <FormItem formDef={{
                    name: 'ExtendedNotes',
                    label: '',
                    required: customer.fieldMeta?.extendedNotes,
                    type: FormType.TEXTAREA,
                    maxLength: 5000,
                    errors: {
                        required: 'Extended Notes are required.',
                    }
                }}/>
            </div> }

            <div className="font-bold text-lg mt-6">Contact Details</div>
            { !hasDevice && <div className="italic mt-2">Primary contact</div> }
            <div className={`grid md:grid-cols-3 grid-cols-1 gap-4 ${hasDevice ? 'mt-6' : 'mt-2'}`}>
                <FormItem formDef={{
                    name: 'Caller',
                    label: 'Contact Name',
                    defaultValue: !!oldRequest ? oldRequest?.Caller?.split('--')[0]?.trim() : name,
                    type: FormType.TEXT,
                    required: true,
                }}/>
                <FormItem formDef={{
                    name: 'Email',
                    label: 'Email',
                    defaultValue: !!oldRequest ? oldRequest?.Caller?.split('--')[2]?.trim() : email,
                    type: FormType.EMAIL,
                    required: true,
                }}/>
                <div className="flex w-full">
                    <div className="w-full">
                        <FormItem formDef={{
                            name: 'CallerPhone',
                            label: 'Phone Number',
                            defaultValue: !!oldRequest ? oldRequest.Caller.split('--')[1]?.trim() : phone,
                            type: FormType.TEXT,
                            mask: InputMask.PHONE,
                            required: true,
                        }}/>
                    </div>
                    <div className="w-full ml-4 max-w-[33%]">
                        <FormItem formDef={{
                            name: 'CallerPhoneEXT',
                            defaultValue: phoneext,
                            label: 'Ext.',
                            maxLength: 10,
                            type: FormType.TEXT,
                        }}/>
                    </div>
                </div>
                </div>

            { !hasDevice && <>
            <div className="italic mt-4">Secondary contact</div>
            <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mt-2">
                <FormItem formDef={{
                    name: 'BackupContactName',
                    label: 'Contact Name',
                    type: FormType.TEXT,
                }}/>
                <FormItem formDef={{
                    name: 'BackupContactEmail',
                    label: 'Email',
                    type: FormType.TEXT,
                }}/>
                <div className="flex w-full">
                    <div className="w-full">
                        <FormItem formDef={{
                            name: 'BackupContactNumber',
                            label: 'Phone Number',
                            type: FormType.TEXT,
                            mask: InputMask.PHONE,
                        }}/>
                    </div>
                    <div className="w-full ml-4 max-w-[33%]">
                        <FormItem formDef={{
                            name: 'BackupContactNumberExt',
                            label: 'Ext.',
                            maxLength: 10,
                            type: FormType.TEXT,
                        }}/>
                    </div>
                </div>
                </div></>}

                <div className="flex justify-between items-center mt-6 w-full">
                    <div className="flex justify-end w-full">
                        <CancelBtn className="rounded py-1 px-4 mr-4" ghost onClick={clearSelectedDevice}>Cancel</CancelBtn>
                        <SubmitBtn className="rounded py-1 px-4" disabled={isLoading} loading={false}>Submit Service Request</SubmitBtn>
                    </div>
                </div>
        </Form>

        { deviceHistory.length > 0 && <div className="mt-6">
            <div className="w-full bg-stone-200 h-[1px] my-10"></div>
        <div className="font-bold text-lg my-4">Service History for this Device</div>
        <ApiTable
          ref={tableRef}
          gridName={ClientGridName.ServiceRequestHistory}
          columns={columns}
          rowData={deviceHistory}
        />
        </div> }
    </div>
}
