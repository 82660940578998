import React, { useEffect, useRef, useState } from 'react';
import { Api } from '../../../../../shared/api/api';
import { filter } from 'lodash';
import { ReactSelectCompo } from '../../../../../components/ui/react-select/react-select';

import ApiTable from '../../../../../shared/api-table/api-table';

import { ReportColumns } from '../report-columns';
import { ResetIcon } from '../../../../../assets/svg/reset-icon';
import { ExcelIcon } from '../../../../../assets/svg/excel-icon';
import { useUser } from '../../../../../context/UserContext';

export const BaseReportGrid = ({
  ApiDataFunction,
  gridName,
  newFields = [],
}) => {
  const { customerInfo } = useUser();
  const ref = useRef(null);
  const [isApiCallProgress, setIsApiCallProgress] = useState(true);
  const [rowData, setRowData] = useState<any>(false);
  const [rowCount, setRowCount] = useState<number>(0);
  const [locationIds, setLocationIds] = useState(null);
  const [locationDropdownOption, setLocationDropdownOption] =
    useState<any>(null);
  const [isDropDownRender, setDropDownRender] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [isExportBtnVisible, setExportBtnVisible] = useState(false);
  const getReports = async (searchObj) => {
    try {
      setIsApiCallProgress(true);
      if (ref.current) {
        ref.current.showGridLoader();
      }
      const res: any = await ApiDataFunction(searchObj);
      if (res?.data) {
        setExportBtnVisible(true);
        setRowData([...res?.data]);
        setRowCount(res?.data?.length || 0)
      } else {
        setExportBtnVisible(false);
        setRowData([]);
        setRowCount(0);
      }
    } catch (e) {
    } finally {
      setIsApiCallProgress(false);
    }
  };

  const columns = ReportColumns({
    ShowRegionCodeInGrid: customerInfo?.ShowRegionCodeInGrid,
    newColumns: newFields,
  });
  const exportCSV = () => {
    if (ref.current) {
      ref.current.exportCSV();
    }
  };

  const resetGrid = () => {
    if (ref.current) {
      ref.current.resetGrid();
    }
  };

  const getShippingAddress = async () => {
    let locationArrIds: any = [];
    let locationArr = [];

    try {
      const response = await Api.callAPI({
        url: `/api/v1/customers/${customerInfo?.CustomerId}/address`,
        method: 'get',
        body: {
          Active: true,
        },
        options: {
          isLoading: false,
        },
      });

      if (
        customerInfo?.RestrictUserByLocationID &&
        customerInfo?.RestrictUserByLocationID?.length
      ) {
        setLocationIds(customerInfo?.RestrictUserByLocationID.toString());
        customerInfo?.RestrictUserByLocationID.forEach((location: any) => {
          const locations = filter(response.data, { CustomerID: location });
          locationArrIds.push(locations[0].CustomerID);
          locationArr.push(locations[0]);
        });
        const locationLabelValueArr: any = locationArr.map((data: any) => {
          return {
            value: data.CustomerID,
            label: data.SearchBy,
          };
        });

        setLocationDropdownOption([{value: 'All Locations', label: 'All Locations'}, ...locationLabelValueArr]);
        setSelectedValue([{value: 'All Locations', label: 'All Locations'}])
      } else {
        setLocationIds(null);
        locationArr = response?.data;
      }
      const locationLabelValueArr: any = locationArr.map((data: any) => {
        return {
          value: data.CustomerID,
          label: `${data.Address}, ${data.City}, ${data.State}, ${data.Zip}, ${data.Country}`,
        };
      });
      setSelectedValue([{value: 'All Locations', label: 'All Locations'}])
      setLocationDropdownOption([{value: 'All Locations', label: 'All Locations'}, ...locationLabelValueArr]);
    } catch (e) {
    } finally {
      setDropDownRender(true);
    }
  };

  useEffect(() => {
    getShippingAddress().then();
  }, []);

  useEffect(() => {
    if (isDropDownRender) {
      const searchObj = {
        CustomerID: customerInfo?.CustomerId,
        LocationID: locationIds,
      };
      (async () => {
        await getReports(searchObj);
      })();
    }
  }, [locationIds, isDropDownRender]);

  return (
    <>
      {locationDropdownOption ? (
        <div className="sm:w-80 elipsisSelectBox">
          <ReactSelectCompo
            options={locationDropdownOption}
            value={selectedValue}
            name={'select location '}
            label={'Search Location'}
            selectAllValue='All Locations'
            // disabled={!rowData?.length}
            onChange={(option: any) => {
              setSelectedValue(option);
              if (option && option?.length) {
                if(option[0].value === 'All Locations') {
                  setLocationIds(null);
                } else {
                  setLocationIds(option[0]?.value);
                }
              } else {
                setLocationIds(null);
              }
            }}
            labelledBy={'location'}
            isSingleSelect={true}
            isCheckboxVisible={false}
            // isOpen={true}
          />
        </div>
      ) : (
        ''
      )}

      <>
        <div className="sm:flex items-center justify-between my-4 w-full">
          <div>
            <span className="md:text-lg text-base font-semibold">
              {rowCount || 0} { rowCount > 1 ? 'Devices' : 'Device' }
            </span>
          </div>
          <div className="flex items-center sm:mt-0 mt-2">
            <button
              className={`flex items-center borderedBtn sm:ml-4 linkText hover:text-black`}
              onClick={resetGrid}
            >
              <ResetIcon />
              <div className={`ml-2 text-sm`}>Reset</div>
            </button>
            {/*{isExportBtnVisible ? (*/}
            {isExportBtnVisible ? (
              <>
                <button
                  className={`borderedBtn flex items-center ml-4 linkText hover:text-black`}
                  onClick={exportCSV}
                >
                  <ExcelIcon />
                  <div className="ml-2 text-sm">Export CSV</div>
                </button>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <ApiTable
          ref={ref}
          columns={columns}
          gridName={gridName}
          rowData={rowData || []}
          isLoading={isApiCallProgress}
          setExportBtnVisible={setExportBtnVisible}
          gridOptions={{
            pagination: false,
          }}
          onGridFilterChanged = {() => {
            setRowCount(ref?.current?.getDisplayedRowCount())
          }}
        />
      </>
    </>
  );
};
