import { getTime } from '../../../../utils/utils';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { Chip } from '../../../../components/ui/Chip';

export const RequestServiceTableField = ({
  selectLocations,
  onlyMyCallsRequest,
  timeDropdownValue,
}: any) => {
  const navigate = useNavigate();
  const filterParams = {
    filterOptions: ['contains'],
    debounceMs: 200,
    defaultOption: 'contains',
    maxNumConditions: 1,
  };
  return [
    {
      headerName: 'Request #',
      field: 'WorkOrderNumber',
      width: 130,
      minWidth: 130,
      filterParams: filterParams,
      sortable: false,
      cellRenderer: (cell: any) => {
        return (
          <Link
            className="service-request-view linkText"
            to={`/service/service-request-detail/${cell.data.WorkOrderID
              }?timeRange=${timeDropdownValue}&location=${selectLocations ? selectLocations.join(',') : ''
              }&viewMyCalls=${onlyMyCallsRequest}`}
          >
            {cell.data.WorkOrderNumber}
          </Link>
        );
      },
      onCellClicked: (cell: any) => {
        navigate(
          `/service/service-request-detail/${cell.data.WorkOrderID
          }?timeRange=${timeDropdownValue}&location=${selectLocations ? selectLocations.join(',') : ''
          }&viewMyCalls=${onlyMyCallsRequest}`,
        );
      },
    },
    {
      headerName: 'Request Placed',
      filter: null,
      field: 'Date',
      width: 230,
      minWidth: 140,
      sortable: false,
      renderCSVColumn: (cell: any) => {
        return cell.Date ? getTime(cell.Date) : '';
      },

      cellRenderer: (cell: any) => {
        if (cell && cell.data) {
          const Date = getTime(cell.data.Date);
          return <div>{Date}</div>;
        } else {
          return '';
        }
      },
    },
    {
      headerName: 'Equipment #',
      field: 'EquipmentNumber',
      filterParams: filterParams,
      minWidth: 150,
      sortable: false,
    },
    {
      headerName: 'Serial #',
      field: 'SerialNumber',
      filterParams: filterParams,
      sortable: false,
      width: 160,
    },
    {
      headerName: 'Location Remarks',
      field: 'LocationRemarks',
      filter : null,
      width: 200,
      sortable: false,
    },
    {
      headerName: 'Problem Description',
      field: 'ProblemDescription',
      filter: null,
      autoHeight: 1,
      sortable: false,
      renderCSVColumn: (cell: any) => {
        let data = '';
        if (cell.ProblemDescription[1] !== undefined) {
          data = cell.ProblemDescription[1];
        } else {
          data = '';
        }
        return `${cell.ProblemDescription[0] || ''},${data}`;
      },
      cellRenderer: (cell: any) => {
        if (!cell.data) {
          return '';
        }
        let data = '';
        if (cell.data.ProblemDescription[1] !== undefined) {
          data = cell.data.ProblemDescription[1];
        } else {
          data = '';
        }
        return (
          <>
            <div className={`${data.length ? 'lineHeight22' : 'lineHeight48'}`}>
              {cell.data.ProblemDescription[0] || ''}
              {data.length ? <br /> : <></>}
              {data}
            </div>
          </>
        );
      },
    },
    {
      headerName: 'Status',
      width: 150,
      filter: null,
      sortable: false,
      field: 'Status',
      cellRenderer: (cell: any) => {
        if (!cell.data) {
          return '';
        }
        let status = cell.data.Status
        return <Chip status={status} />;
      },
    },
    {
      headerName: 'Location',
      field: 'CustomerName',
      filter: null,
      width: 160,
      sortable: false,
    },
    {
      headerName: 'Request Type',
      width: 130,
      filter: null,
      field: 'CallType',
      sortable: false,
    },
    {
      headerName: 'Latest Update',
      width: 130,
      filter: null,
      sortable: false,
      field: 'RepairRemarks',
    },
  ];
};
