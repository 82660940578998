
import {
  isCheckDollerProcingNonZero,
} from '../../../../utils/utils';
// import { ComponentLoading } from '../../../../shared/component-loader/component-loading';
import { NumberInput } from '../../../../components/ui/NumberInput';
import { useNavigate } from 'react-router-dom';
import { RedTrashIcon } from '../../../../assets/svg/red-trash-icon';
import { CART_MAX_LIMIT, CART_MIN_LIMIT } from '../../../../constant/constant';
import { Button } from '../../../../components/ui';
import { useCart } from '../../context/CartContext';
import { useUser } from '../../../../context/UserContext';
// import { useNavigate } from 'react-router-dom';

const Cart = () => {
  const navigate = useNavigate();

  const { items, count, loading, removeItem, modifyQuantity } = useCart();
  const { customerInfo } = useUser();

  const totalCost = items.reduce(
    (acc, item) => acc + +item.Price * item.Quantity,
    0,
  )


  const NotItemsFoundCart = () => {
    return (
      <>
        {!items?.length && !loading ? (
          <>
            <div className={'w-full h-full flex items-center justify-center'}>
              <div className={'text-center'}>
                <img
                  className="m-auto"
                  src={require('../../../../assets/images/img.png')}
                />
                <div className="mt-4">Your shopping cart is empty.</div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </>
    );
  };
  const IsPriceHeaderShow = (OrderItems: any) => {
    if(customerInfo?.HideZeroDollarPricing) {
      let flag = false;
      OrderItems?.map((item: any) => {
        if(parseInt(item.Price) > 0) {
          flag = true;
        }
      });
      return flag;
    } else {
      return true
    }
  }
  const IsTotalHeaderShow = (OrderItems: any) => {
    if(customerInfo?.HideZeroDollarPricing) {
      let flag = false;
      OrderItems?.map((item: any) => {
        if(parseInt(item.totalPriceOfOneProduct) > 0) {
          flag = true;
        }
      });
      return flag;
    } else {
      return true
    }
  }
  return (
    <div className="flex flex-col h-full">
      {/*{isLoading ? <ComponentLoading isShowLoader={true} /> : ''}*/}
      {items ? (
        <>
          <div className="pb-4 border-b border-gray-200 mb-4 flex items-center justify-between">
            <h2 className="text-base text-gray-900">
              <span className="font-semibold">
                Shopping Cart ({count})
              </span>
            </h2>
          </div>
          <div className="border border-gray-200 rounded-md mb-8 overflow-hidden">
            <div className="flex flex-col h-full overflow-y-auto cartItemBox ">
              <div className="md:flex hidden items-center bg-gray-100 p-4">
                <div className="xl:w-4/6 md:w-1/2 proTitle text-sm font-semibold text-gray-800 pr-4">
                  Item
                </div>
                <div className="xl:w-2/6 md:w-1/2 flex">
                  <div
                    className={
                      'w-1/4 text-sm font-semibold text-gray-800 flex justify-center '
                    }
                  >
                    {IsPriceHeaderShow(items) ? (
                      'Price'
                    ) : (
                      ''
                    )}
                  </div>
                  <div className=" w-1/4 text-sm font-semibold text-gray-800 flex justify-center">
                    Quantity
                  </div>
                  <div className=" w-1/4 text-sm font-semibold text-gray-800 flex justify-center ">
                    {IsTotalHeaderShow(items) ? (
                      'Total'
                    ) : (
                      ''
                    )}
                  </div>
                  <div className=" w-1/4 text-sm font-semibold text-gray-800 flex justify-center ">
                    Remove
                  </div>
                </div>
              </div>
              {items.map(
                (
                  {
                    ItemDescription,
                    EquipmentNumber,
                    Price,
                    Quantity,
                    isDisabled = false,
                    hasClassError = false,
                    tonerClass,
                    Item,
                  }: any,
                  index: number,
                ) => {
                  const totalPriceOfOneProduct = (+Price * Quantity).toFixed(2);
                  return (
                    <div key={'cartItems' + index}>
                      <div className="cartItem">
                        <div className="md:flex p-4 border-b border-gray-200">
                          <div className="xl:w-4/6 md:w-1/2  sm:pr-4">
                            <div className="flex">
                              <div className="mt-1">
                                <div className="w-4 mr-2 ">
                                  {tonerClass ? (
                                    <div
                                      className="w-4 h-4 rounded-full ItemDescription mt-1"
                                      style={{
                                        backgroundColor: tonerClass,
                                      }}
                                    ></div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                              <div className="break-all text-sm font-semibold text-gray-900 sm:pr-0 pr-4">
                                {ItemDescription}
                              </div>
                              <button
                                id={`remove${index}`}
                                title={`Remove Cart Item`}
                                className="md:ml-2 ml-0 cursor-pointer h-5 mt-1 sm:hidden block"
                                onClick={async () => {
                                  await removeItem(items[index]);
                                }}
                              >
                                <RedTrashIcon />
                              </button>
                            </div>
                            <div
                              className={`mt-2 flex text-sm text-gray-600 ml-6`}
                            >
                              <div>{Item}</div>
                              {EquipmentNumber ? (
                                <div className="ml-4">
                                  EQ# {EquipmentNumber}
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          <div className="xl:w-2/6 md:w-1/2 md:mt-0 mt-4 flex items-center sm:justify-start justify-between ">
                            <div className="sm:w-1/4 flex md:justify-center sm:pr-0 pr-6">
                              {isCheckDollerProcingNonZero(Price) ? (
                                <>
                                  <div className="text-base text-gray-800">
                                    {' '}
                                    ${Price}
                                  </div>
                                </>
                              ) : (
                                ''
                              )}
                            </div>

                            <div className="sm:w-1/4 flex items-center md:justify-center sm:pr-0 pr-6 ">
                              <div className="-mt-2">
                                <button
                                  disabled={isDisabled}
                                  className="text-2xl text-gray-900"
                                  onClick={() => modifyQuantity(items[index], items[index].Quantity - 1)}
                                >
                                  -
                                </button>
                                <NumberInput
                                  value={Quantity}
                                  decimalScale={0}
                                  allowNegative={false}
                                  className={`${
                                    hasClassError
                                      ? 'form-control red-border no-spinners'
                                      : 'form-control no-spinners mx-2 border border-gray-400 text-gray-900 rounded-md w-10 text-center'
                                  }`}
                                  // onChange={() => setIsCallApi(true)}
                                  onBlur={async (values) => {
                                    const { floatValue, value } = values;
                                    if (value === '' || floatValue === 0) {
                                      removeItem(items[index]);
                                    } else {
                                      modifyQuantity(items[index], floatValue);
                                    }
                                  }}
                                  isAllowed={(values) => {
                                    const { floatValue, value } = values;
                                    return value === '' || (floatValue >= CART_MIN_LIMIT && floatValue <= CART_MAX_LIMIT);
                                  }}
                                  allowedDecimalSeparators={false}
                                />
                                <button
                                  disabled={isDisabled}
                                  className="text-2xl text-gray-900"
                                  id={`increment${index}`}
                                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                  onClick={() => {
                                      if (items[index].Quantity < CART_MAX_LIMIT) {
                                        modifyQuantity(items[index], items[index].Quantity + 1);
                                      }
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            {isCheckDollerProcingNonZero(
                              parseFloat(totalPriceOfOneProduct).toFixed(2),
                            ) ? (
                              <div className="sm:w-1/4 flex md:justify-center  justify-end  sm:pr-0 pr-1">
                                <div className="text-base text-gray-800 font-semibold">
                                  $
                                  {parseFloat(totalPriceOfOneProduct).toFixed(
                                    2,
                                  )}
                                </div>
                              </div>
                            ) : (
                              ''
                            )}

                            <div className="sm:w-1/4 sm:flex md:justify-center justify-end ml-auto hidden">
                              <button
                                id={`remove${index}`}
                                title={`Remove Cart Item`}
                                className="md:ml-2 ml-0 cursor-pointer"
                                onClick={async () => {
                                  await removeItem(items[index]);
                                }}
                              >
                                <RedTrashIcon />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                },
              )}
            </div>

            <div className="bg-white w-full">
              <div className="p-4 flex justify-between">
                <div className="font-semibold">{items?.length} {items?.length > 1 ? 'Items' : 'Item'}</div>
                {!(
                  customerInfo?.HideZeroDollarPricing &&
                  (parseFloat(totalCost.toFixed(2)) === 0.0 || !totalCost)
                ) ? (
                  <div className="font-bold">
                    Total : ${totalCost.toFixed(2)}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className="sm:flex justify-end">
            <button
              id='continueShopping'
              onClick={() => {
                navigate('/product/search');
              }}
              // disabled={isViewCartAndCheckoutButtonDisable}
              className={`borderedBtn inline-block sm:w-auto w-full sm:mb-0 mb-4 hover:text-black linkText`}
            >
              Continue Shopping
            </button>
            <Button
              id='checkout'
              loading={loading}
              className="px-3 py-1 rounded ml-4"
              onClick={() => navigate('/customer/checkout')}
            >
              Checkout
            </Button>
          </div>
        </>
      ) : (
        ''
      )}
      <NotItemsFoundCart />
    </div>
  );
};

export default Cart;
