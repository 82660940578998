import React from 'react';
export const Maintenance = () => {
    return (
        <>
            Admin Maintenance
        </>
    )
}


