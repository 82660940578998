import { useEffect, useRef, useState } from 'react';

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
  const isDebouncedRef = useRef(false);
  const debounceTime = 300;

  function resizeHandler() {
    if (isDebouncedRef.current) {
      return;
    }
    isDebouncedRef.current = true;
    setTimeout(() => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
      isDebouncedRef.current = false;
    }, debounceTime);
  }

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return windowSize;
}
