import { useTheme } from "../../context/ThemeContext";

interface CardProps {
    className?: string;
    children?: React.ReactNode;
    title?: string;
    titleClassName?: string;
    titleIcon?: React.ReactNode;
    titleIconClassName?: string;
    titleIconPosition?: 0 | 1;

}

export const Card = ({
    className,
    children,
}: CardProps) => {

    const { mainTheme } = useTheme();

    return <section className={`${mainTheme.primary} shadow p-2 ${className} rounded`}>
        {children}
    </section>
}