import { Input } from "../../ui";
import { useForm } from "../Form";
import { FormDefinition } from "../types/FormTypes";

export const FormInput = ({ formDef }: { formDef: FormDefinition}) => {

    const { formState, dispatch, handleInputValidation, handleSubmit } = useForm();
    const inputState = formState[formDef.name];

    return <Input 
        className={formDef.className}
        label={formDef.label}
        name={formDef.name}
        value={inputState?.value || ''}
        onChange={(event) => {
            let value = event.target.value;
            let files = event.target.files;
            formDef.onChange?.(event);
            //dont pass files if not present
            return dispatch({ [formDef.name]: { value, files } })
        }}
        type={formDef.type}
        placeholder={formDef.placeholder}
        required={formDef.required}
        disabled={formDef.disabled}
        readOnly={formDef.readOnly}
        autoFocus={formDef.autoFocus}
        min={formDef.min}
        max={formDef.max}
        icon={formDef.icon}
        iconPosition={formDef.iconPosition}
        fullWidth
        minLength={formDef.minLength}
        charachterLimit={formDef.maxLength}
        pattern={formDef.pattern}
        defaultValue={formDef.defaultValue as string | number}
        uploadAccept={formDef.uploadAccept}
        uploadMultiple={formDef.uploadMutiple}
        onBlur={(e) => {
            e.preventDefault();
            handleInputValidation(formDef);
            formDef.onBlur?.(e);
        }}
        error={inputState?.error}
        mask={formDef.mask}
        onEnter={(event) => !formState.errors && handleSubmit(event)} //Enter key will attempt to submit the form
    />
}