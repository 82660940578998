export const MeterIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_782_7169)">
        <path
          d="M4.1665 14.1673H15.8332C17.4998 14.1673 18.3332 13.334 18.3332 11.6673V8.33398C18.3332 6.66732 17.4998 5.83398 15.8332 5.83398H4.1665C2.49984 5.83398 1.6665 6.66732 1.6665 8.33398V11.6673C1.6665 13.334 2.49984 14.1673 4.1665 14.1673Z"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M15 5.83398V10.0007"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M5 5.83398V9.16732"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M8.37516 5.83398L8.3335 10.0007"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M11.6665 5.83398V8.33398"
          stroke="#A3A3A3"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_782_7169">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
