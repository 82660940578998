import { useModal } from '../../../../../../context/ModalConext';
import {
  Form,
  FormItems,
  FormType,
  SubmitBtn,
} from '../../../../../../components/form';
import { Api } from '../../../../../../shared/api/api';
import { useUser } from '../../../../../../context/UserContext';

export const DeviceReportingModal = ({ deviceDetails, equipmentId }: any) => {
  const { openModal, closeModal } = useModal();
  const { customerInfo } = useUser();
    const onSubmitSupplyLevelEditHandler = async (comment) => {
        const reqBody = {
          Comments: comment?.Comments,
          device: deviceDetails,
          userInfo: {
            CustomerId: customerInfo.CustomerId,
            CustomerName: customerInfo.CustomerName,
            EmailAddress: customerInfo.EmailAddress,
            FirstName: customerInfo.FirstName,
            LastName: customerInfo.LastName,
            UserPhone: customerInfo.UserPhone,
            BranchID: customerInfo.BranchID,
            BranchNumber: customerInfo.BranchNumber,
          }
        };

        await Api.callAPI({
          url: `/api/v1/equipments/${equipmentId}/reportDevice`,
          method: 'post',
          body: reqBody,
          options: {
            successMessage:
              'Your request has been submitted to our team for review.',
            errorMessage: 'Error while reporting device.',
          },
        });
        closeModal();
      };

  const SupplyLevelModal = () => (
    <>
      <div>
        <div className='px-6 pt-6 text-sm'>
          This device is not currently reporting to our automatic monitoring software, which is normal for non-networked devices. If you'd like our team to review and see if we can get it monitored, add any comments below and hit Submit.
        </div>
        <div>
          <Form onSubmit={onSubmitSupplyLevelEditHandler}>
            <FormItems
              className="p-6"
              items={[
                {
                  name: 'Comments',
                  label: 'Comments',
                  rows: 5,
                  type: FormType.TEXTAREA,
                  placeholder: 'Write your message here..',
                  required: true,
                  errors: {
                    required: 'Please enter Comments.',
                  },
                  maxLength: 200
                },
              ]}
            />
            <div className="flex justify-end py-4 px-6 border-t border-gray-200">
              <button type={'button'} onClick={() => closeModal()} className={`borderedBtn mr-2 linkText hover:text-black`}>
                Cancel
              </button>
              <SubmitBtn className={`commonBtn`}>Submit</SubmitBtn>
            </div>
          </Form>
        </div>
      </div>
    </>
  )


  return (
    <a className='linkText inline-block cursor-pointer' onClick={() => openModal({
        content: <SupplyLevelModal />,
        options: {
          title: 'Device Reporting',
        },
      })}>Click here</a>
  );
};
