import React, { ReactNode, useEffect, useState } from 'react';
import { ReactSelectMain, Option } from './index';
import { sortBy } from 'lodash';

export interface AsyncOptionMapping {
  labelKey: string | number;
  valueKey: string | number;
}

export interface LazyLoadParams {
  perPageData: number;
}
interface ReactProps {
  name: string;
  label: string | React.ReactNode;
  options?: Option[];
  value?: Option[];
  onChange?: any;
  valueRenderer?: (selected: Option[], options: Option[]) => ReactNode;
  ItemRenderer?: any;
  ArrowRenderer?: ({ expanded }: any) => JSX.Element;
  isLoading?: boolean;
  disabled?: boolean;
  disableSearch?: boolean;
  shouldToggleOnHover?: boolean;
  hasSelectAll?: boolean;
  hasFilterSelectAll?: boolean;
  filterOptions?: (
    options: Option[],
    filter: string,
  ) => Promise<Option[]> | Option[];
  overrideStrings?: { [key: string]: string };
  labelledBy: string;
  className?: string;
  onMenuToggle?: any;
  ClearIcon?: ReactNode;
  debounceDuration?: number;
  ClearSelectedIcon?: ReactNode;
  defaultIsOpen?: boolean;
  isOpen?: boolean;
  isCreatable?: boolean;
  closeOnChangedValue?: boolean;
  getAsyncOptions?: (queryData: {}) => Promise<any>;
  error?: string;
  optionKeyMapObject?: AsyncOptionMapping;
  isSingleSelect?: boolean;
  isCheckboxVisible?: boolean;
  isMenuTop?: boolean;
  onClose?: any;
  lazyLoadOptions?: LazyLoadParams;
  isDisplayElipsis?: boolean;
  selectAllValue?: string;
  selectIsClearable?: boolean;
}

export const ReactSelectCompo = ({
  name,
  label,
  options = [],
  onChange = () => {},
  value = [],
  optionKeyMapObject,
  isSingleSelect = false,
  isCheckboxVisible = true,
  isMenuTop = false,
  getAsyncOptions,
  overrideStrings,
  onClose = () => {},
  isDisplayElipsis = false,
  lazyLoadOptions = null,
  ...props
}: ReactProps) => {
  const [selected, setSelected] = useState<any>(
    value && value.length ? value : [],
  );
  const [selectOption, setSelectOption] = useState(
    options && options.length ? options : [],
  );
  const [prevSelectedOption, setPreSelectedOption] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (getAsyncOptions) {
        try {
          setLoading(true);
          const response = await getAsyncOptions({});

          const optionArr = response?.data;

          if (optionArr && optionArr.length && optionKeyMapObject) {
            const selectOptionArr: any = [];
            optionArr.map((op: any) => {
              selectOptionArr.push({
                label: op[optionKeyMapObject?.labelKey] || '',
                value: op[optionKeyMapObject?.valueKey] || '',
              });
            });
            setSelectOption([...selectOptionArr]);
          }
        } catch (e) {
        } finally {
          setLoading(false);
        }
      }
    })();
    // TODO: Do we need this? Line 120 already has a side effect storing this
    if (value && value.length) {
      setSelected([...value]);
    }
  }, []);

  useEffect(() => {
    if (!getAsyncOptions) {
      setSelectOption([...options]);
    }
  }, [options, getAsyncOptions])


  useEffect(() => {
    if (value && value.length) {
      setSelected([...value]);
    } else {
      setSelected([]);
    }
  }, [value]);
  const handleCloseDropdown = (selectedOp: any) => {
    let isChangeValue = false;
    if (!prevSelectedOption.length && selected.length) {
      isChangeValue = true;
    } else {
      const selecctdOption = JSON.stringify(sortBy(selected, ['value']));
      const prevSelected = JSON.stringify(
        sortBy(prevSelectedOption, ['value']),
      );
      if (selecctdOption !== prevSelected) {
        isChangeValue = true;
      }
    }

    if (selectedOp === null) {
      if (isChangeValue) {
        onClose(selected);
      }
    } else {
      onClose(selectedOp);
    }
  };

  return (
    <ReactSelectMain
      {...props}
      options={selectOption}
      isLoading={isLoading}
      value={selected}
      onChange={(val: any) => {
        setSelected([...val]);
        onChange(val);
      }}
      labelledBy={label}
      isSingleSelect={isSingleSelect}
      isMenuTop={isMenuTop}
      overrideStrings={overrideStrings}
      onMenuToggle={(isOpen: any) => {
        if (isOpen) {
          setPreSelectedOption(selected);
        }
      }}
      isDisplayElipsis={isDisplayElipsis}
      onClose={(selectedOp: any) => handleCloseDropdown(selectedOp)}
      lazyLoadOptions={lazyLoadOptions}
      isCheckboxVisible={isCheckboxVisible}
    />
  );
};
