import { TextArea } from "../../ui";
import { useForm } from "../Form";
import { FormDefinition } from "../types/FormTypes";

export const FormTextArea = ({ formDef }: { formDef: FormDefinition}) => {

    const { formState, handleInputValidation, handleChange } = useForm();
    const { name, label, placeholder, required, disabled, readOnly, autoFocus, maxLength, defaultValue, onChange, rows, className } = formDef;

    return <TextArea
        name={name}
        label={label}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        autoFocus={autoFocus}
        maxLength={maxLength}
        fullWidth
        defaultValue={defaultValue as string}
        rows={rows}
        value={formState[name]?.value || ''}
        onChange={(event) => {
            if (onChange) onChange(event);
            handleChange(event);
        }}
        className={className}
        onBlur={() => handleInputValidation(formDef)}
        error={formState[name] && formState[name].error}
    />

}