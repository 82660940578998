import {
  Form,
  FormItems,
  FormType,
  SubmitBtn,
} from '../../../../../../components/form';
import { useModal } from '../../../../../../context/ModalConext';
import { useUser } from '../../../../../../context/UserContext';
import { Api } from '../../../../../../shared/api/api';

export const EditPopModal = ({ locationData }) => {
  const { openModal, closeModal } = useModal();
  const { customerInfo } = useUser();

  const onSubmitEditHandler = async (comment) => {
    const locationDetailObj = {
      oldLocationData: { LocationID: '' },
      userInfo: {},
      Comments: '',
    };
    locationDetailObj.oldLocationData = locationData;
    locationDetailObj.userInfo = {
      CustomerId: customerInfo.CustomerId,
      EmailAddress: customerInfo.EmailAddress,
      FirstName: customerInfo.FirstName,
      LastName: customerInfo.LastName,
      UserPhone: customerInfo.UserPhone,
      BranchID: customerInfo.BranchID,
      BranchNumber: customerInfo.BranchNumber,
    };

    locationDetailObj.Comments = comment?.Comments;

    await Api.callAPI({
      url: `/api/v1/location/${locationDetailObj?.oldLocationData?.LocationID}/updateLocation`,
      method: 'put',
      body: locationDetailObj,
      options: {
        successMessage:
          'Your change request has been submitted to our team for review.',
        errorMessage: 'Error while update location.',
      },
    });
    closeModal();
  };

  const ModalContent = () => {
    return (
      <div className="flex flex-col gap-2">
        <div>
          <Form onSubmit={onSubmitEditHandler}>
            <FormItems
              className="p-6"
              items={[
                {
                  name: 'Comments',
                  label: 'Comments',
                  rows: 5,
                  type: FormType.TEXTAREA,
                  placeholder: 'Write your message here..',
                  required: true,
                  errors: {
                    required: 'Please enter note.',
                  },
                  maxLength: 200
                },
              ]}
            />
            <div className="flex justify-end py-4 px-6 border-t border-gray-200">
              <button onClick={() => closeModal()} className={`borderedBtn mr-2 linkText hover:text-black`}>
                Cancel
              </button>
              <SubmitBtn className={`commonBtn`}>Submit</SubmitBtn>
            </div>
          </Form>
        </div>
      </div>
    );
  };

  // const ModalFooter = () => {
  //   return (
  //     <div className="flex gap-2">
  //       <Button onClick={closeModal}>Cancel</Button>
  //       <Button>Save</Button>
  //     </div>
  //   );
  // };

  return (
    <>
      {locationData && (
        <button
          className="primaryBtn bgColor"
          onClick={() =>
            openModal({
              content: <ModalContent />,
              options: {
                title: 'Edit Location',
                //footer: <ModalFooter />,
              },
            })
          }
        >
          Edit
        </button>
      )}
    </>
  );
};
