import { useFlexLayer } from "../../../hooks/useFlexlayer"
import { ComponentLoading } from "../../../shared/component-loader/component-loading";

export const PopupDisplay = () => {

    const { loading, data } = useFlexLayer('/popup/getAllPopups');
    const options = new Array(10).fill(null);

    console.log(data)



    if(loading) return <ComponentLoading isShowLoader={true}/>
    return <div>
    <h2 className="text-xl font-bold flex justify-between items-center mb-4">
        <span>Feedback</span>
        <span className="italic text-xs">Amount received: { data.length }</span>
    </h2>
    
    <div className="grid grid-cols-10 w-full gap-1">
        { options.map((_, i) => {
            const feedBackCount = data.filter(feedback => feedback.PopupData.number === (i + 1)).length;
            const feedbackPercentageVsTotal = feedBackCount / data.length * 100;
            return <div key={i} className={`
                ${i + 1 <= 5 ? 'bg-red-400' : '' }
                ${i + 1 > 5 && i + 1 < 8 ? 'bg-yellow-400' : '' }
                ${i + 1 > 7 ? 'bg-green-400' : '' }
                shadow flex flex-col gap-2 items-center justify-center rounded p-2`}>
            <p className="text-lg font-bold">{i + 1}</p>
            <p>{feedBackCount}</p>
            <p>{feedbackPercentageVsTotal}%</p>
        </div>
        })}
    </div>

    <div className="flex flex-col gap-1">
        <h2 className="text-lg font-bold my-4">Comments</h2>
        <div className="max-h-[50vh] overflow-auto">
        { data.map((feedback, i) => {
            if(i > 4) return null;
            return <div key={i} className={`
            shadow p-2 flex justify-start items-center gap-2`}>
                <p
                    className={`p-2 rounded text-white font-bold
                        ${ feedback.PopupData.number <= 5 ? 'bg-red-400' : '' }
                        ${ feedback.PopupData.number > 5 && feedback.PopupData.number < 8 ? 'bg-yellow-400' : '' }
                        ${ feedback.PopupData.number > 7 ? 'bg-green-400' : '' }`}
                >{feedback.PopupData.number}</p>
                <p>{feedback.PopupData.feedback}</p>
            </div>
        })}
        </div>
    </div>

</div>

}