import { Link } from "react-router-dom";
import { useTheme } from "../../context/ThemeContext";
import { Loader } from "./Loader";
// import { Loader } from "./Loader";

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    className?: string;
    color?: string;
    ghost?: boolean;
    size?: "sm" | "md" | "lg" | "xl";
    icon?: React.ReactNode;
    loading?: boolean;
    iconPosition?: 0 | 1;
    to?: string;
    fullWidth?: boolean;
    onSubmit?: React.FormEventHandler<HTMLButtonElement>;
}

interface LinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    disabled?: boolean;
    className?: string;
    color?: string;
    ghost?: boolean;
    size?: "sm" | "md" | "lg" | "xl";
    icon?: React.ReactNode;
    loading?: boolean;
    iconPosition?: 0 | 1;
    to?: string;
    fullWidth?: boolean;
    onSubmit?: React.FormEventHandler<HTMLAnchorElement>;
}

export const Button = ({ children, onClick, disabled, className, color, ghost, size, icon, loading, iconPosition, fullWidth, to, onSubmit, ...rest}: ButtonProps | LinkProps) => {

    const { mainTheme, tenantTheme } = useTheme();
    const background = ghost ? 'transparent borderedBtn linkText' : color ? color : tenantTheme.primary;
    const fullWidthClass = fullWidth ? 'w-full' : 'w-fit';
    const isClickable = !disabled && !loading;

    const LoadingOverlay = () => {
        return <div className="absolute inset-0 bg-stone-300 flex items-center justify-center w-full h-full rounded cursor-not-allowed">
            <Loader />
        </div>
    }

    const InnerButton = () => {

        return <div className="flex items-center justify-center">
            { loading && <LoadingOverlay /> }
            {icon && iconPosition === 0 && icon}
            {children}
            {icon && iconPosition === 1 && icon}
        </div>
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement> | any) => {
        if(!isClickable) return;
        if(onClick) onClick(event);
        if(onSubmit) onSubmit(event as any);
    }

    //Return router link if to is provided
    if(to) {
        return <Link to={to} className={`${ isClickable ? background : mainTheme.disabled } ${fullWidthClass} ${className} relative hover:opacity-90 transition-all`} {...rest as LinkProps}><InnerButton /></Link>
    }

    //Return button
    return <button type="button" className={`${ isClickable ? background : mainTheme.disabled } ${fullWidthClass} ${className} relative hover:opacity-90 transition-all`} {...rest as ButtonProps} onClick={handleClick} onSubmit={handleClick} disabled={!isClickable} aria-disabled={!isClickable}><InnerButton/></button>

}