export const RedTrashIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.65454 4.04046L4.63358 13.2159C4.69471 13.7888 5.15977 14.2223 5.71335 14.2223H10.2871C10.8407 14.2223 11.3057 13.7888 11.3669 13.2159L12.3459 4.04046M3.65454 4.04046H3.11133M3.65454 4.04046H6.37059M12.3459 4.04046H12.8891M12.3459 4.04046H9.62985M6.37059 4.04046V2.90915C6.37059 2.28434 6.85699 1.77783 7.45701 1.77783H8.54343C9.14344 1.77783 9.62985 2.28434 9.62985 2.90915V4.04046M6.37059 4.04046H9.62985M9.33355 7.55561L8.00022 9.13137M8.00022 9.13137L6.66688 10.6667M8.00022 9.13137L6.66688 7.55561M8.00022 9.13137L9.33355 10.6667"
        stroke="#ff0000"
        strokeLinecap="round"
      />
    </svg>
  );
};
