import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  EXPIRED_SUBTITLE,
  EXPIRED_TITLE,
  PENDING_SUBTITLE,
  PENDING_TITLE,
} from '../../../../constant/constant';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { Api } from '../../../../shared/api/api';

const OrderTracking = () => {
  let { trackingNumber } = useParams();
  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );
  const [trackingTitle, setTrackingTitle] = useState(PENDING_TITLE);
  const [trackingSubTitle, setTrackingSubTitle] = useState(PENDING_SUBTITLE);
  const [isTrackingDetailsExists, setTrackingDetailsExist] = useState(true);
  const getAfterShipTrackingDetailsFromTrackingNumber = async () => {
    try {
      const res: any = await Api.callAPI({
        url: `/api/v1/afterShipTracking/${trackingNumber}`,
        method: 'GET',
        options: {
          isLoading: true,
          showErrorMessage: false,
          errorMessage: 'Error while getting tracking details.',
        },
      });
      if (res && res.data && res.data.isExpired) {
        setTrackingDetailsExist(false);
        setTrackingTitle(EXPIRED_TITLE);
        setTrackingSubTitle(EXPIRED_SUBTITLE);
      } else {
        if (res && res.data && res.data.tracking) {
          if (res.data.tracking.tag === 'Pending') {
            setTrackingDetailsExist(false);
            setTrackingTitle(PENDING_TITLE);
            setTrackingSubTitle(PENDING_SUBTITLE);
          } else if (res.data.tracking.tag === 'InfoReceived') {
            setTrackingDetailsExist(true);
          } else if (res.data.tracking.tag === 'InTransit') {
            setTrackingDetailsExist(true);
          } else if (res.data.tracking.tag === 'OutForDelivery') {
            setTrackingDetailsExist(true);
          } else if (res.data.tracking.tag === 'AttemptFail') {
            setTrackingDetailsExist(false);
            setTrackingTitle(PENDING_TITLE);
            setTrackingSubTitle(PENDING_SUBTITLE);
          } else if (res.data.tracking.tag === 'Delivered') {
            setTrackingDetailsExist(true);
          } else if (res.data.tracking.tag === 'AvailableForPickup') {
            setTrackingDetailsExist(true);
          } else if (res.data.tracking.tag === 'Exception') {
            setTrackingDetailsExist(true);
          } else if (res.data.tracking.tag === 'Expired') {
            setTrackingDetailsExist(false);
            setTrackingTitle(EXPIRED_TITLE);
            setTrackingSubTitle(EXPIRED_SUBTITLE);
          } else {
            setTrackingDetailsExist(false);
            setTrackingTitle(PENDING_TITLE);
            setTrackingSubTitle(PENDING_SUBTITLE);
          }
        } else {
          setTrackingTitle(PENDING_TITLE);
          setTrackingSubTitle(PENDING_SUBTITLE);
          setTrackingDetailsExist(false);
        }
      }
    } catch (e) {}
  };
  useEffect(() => {
    (async () => {
      await getAfterShipTrackingDetailsFromTrackingNumber();
    })();
  }, []);
  return (
    <>
      {/**ngIf="isTrackingDetailsExists"*/}
      {isTrackingDetailsExists ? (
        <div
          style={{ height: '600px', width: '100%', backgroundColor: '#f6f6f7' }}
        >
          <iframe
            height="100%" width="100%" 
            src={`https://mobileappadminautomi.aftership.com/iframe/${trackingNumber.trim()}?page-name=${tenantName.toLowerCase()}`}
          ></iframe>
        </div>
      ) : (
        <>
          <div className='border-2 border-gray-400 rounded-lg px-6 lg:py-40 py-20 xl:max-w-5xl max-w-full text-center m-auto xl:my-40 md:my-10'>
            <div className='text-xl font-medium'>{trackingTitle}</div>
            <div>{trackingSubTitle}</div>
          </div>
        </>
      )}
    </>
  );
};

export default OrderTracking;
