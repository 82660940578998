import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useModal } from '../../../../../context/ModalConext';
import { DeviceLocationUpdate } from '../device-detail/Modals/device-location-update-wrapper';
import { Api } from '../../../../../shared/api/api';
import { ComponentLoading } from '../../../../../shared/component-loader/component-loading';
import { useUser } from '../../../../../context/UserContext';

interface LocationUpdateDialogProps {
  refreshGrid: () => void;
}
export const LocationUpdateDialog = forwardRef(
  ({ refreshGrid }: LocationUpdateDialogProps, ref) => {
    const { openModal, closeModal } = useModal();

    const { customerInfo } = useUser();

    const ModalContent = ({ equipmentId }) => {
      const [customerAddress, setCustomerAddress] = useState([]);
      const [deviceDetails, setDeviceDetails] = useState(null);
      const getCustomerAddress = async () => {
        try {
          const response = await Api.callAPI({
            url: `/api/v1/customers/${customerInfo?.CustomerId}/address`,
            method: 'get',
            options: {
              isLoading: false,
              showErrorMessage: true,
            },
          });

          setCustomerAddress(response?.data);
        } catch (e) {}
      };

      const getEquipmentDetail = async () => {
        try {
          const searchObj = {
            CustomerID: customerInfo?.CustomerId,
            email: customerInfo?.EmailAddress,
            EquipmentID: equipmentId,
            LocationID: '',
          };
          const response = await Api.callAPI({
            url: `/api/v1/equipments/device/${equipmentId}`,
            method: 'put',
            body: searchObj,
            options: {
              isLoading: false,
              showErrorMessage: true,
            },
          });
          setDeviceDetails(response?.data);
        } catch (e) {
          // navigate('/customer/devices');
        }
      };
      const [isLoading, setLoading] = useState(true);
      useEffect(() => {
        (async () => {
          setLoading(true);
          await getCustomerAddress();
          await getEquipmentDetail();
          setLoading(false);
        })();
      }, []);
      const onDeviceLocationUpdate = () => {
        if (refreshGrid) {
          refreshGrid();
        }
        closeModal();
      };
      return (
        <>
          {isLoading ? (
            <ComponentLoading isShowLoader={true} />
          ) : (

            <DeviceLocationUpdate
              customerInfo={customerInfo}
              deviceDetails={deviceDetails}
              equipmentId={equipmentId}
              customerAddress={customerAddress}
              className={''}
              onDeviceLocationUpdate={onDeviceLocationUpdate}
              closeBtns={
                <button
                  className="borderedBtn linkText mr-4"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </button>

              }
            />

          )}
        </>
      );
    };

    useImperativeHandle(ref, () => ({
      openLocationUpdateModal(equipmentId) {
        openModal({
          content: <ModalContent equipmentId={equipmentId} />,
          options: {
            title: 'Edit Location',
            className:'visibleOptionModal',
            //   footer: <ModalFooter />,
          },
        });
      },
    }));
    return <></>;
  },
);
